import {useState} from "react";
import {Modal} from "../../../components/primitives/Modal";
import {Button} from "../../../components/primitives/Button";
import {Input} from "../../../components/primitives/Input";
import {FileUpload} from "../../../components/primitives/FileUpload";
import {Close, Spinner} from "../../../components/primitives/icons";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {marketingActions} from "../../../_store/features/marketing/marketing-slice";
import {RootState} from "../../../_store/store";
import toast from "react-hot-toast";

function ProcessContacts() {
	const [open, setOpen] = useState(false);
	const {loading} = useAppSelector((state: RootState) => state.marketing);
	const [file, setFile] = useState<File | undefined>();
	const [error, setError] = useState("");
	const [tags, setTags] = useState<string[]>([]);
	const [tag, setTag] = useState("");
	const dispatch = useAppDispatch();

	const handleAddTag = () => {
		if (!tag) return;
		setTags([...tags, tag]);
		setTag("");
		setError("");
	};

	const handleProcessContacts = async () => {
		if (!file || !tags.length) return;
		setError("");
		try {
			const response = await dispatch(marketingActions.processOdooContactTags({file, tags: tags.toString()}));
			if (response.meta.requestStatus === "rejected") throw new Error();
			toast.success("Contactos procesados correctamente");
		} catch (error) {
			setError("Error al procesar los contactos");
			toast.error("Error al procesar los contactos");
		}
	};

	return (
		<>
			<Button onClick={() => setOpen(true)} className="gap-2 px-4">
				Procesar Archivo CSV
			</Button>
			<Modal
				title="Procesar Contactos"
				open={open}
				onDismiss={() => {
					setOpen(false);
				}}
				big
			>
				<div className="flex flex-col px-6">
					<div className="flex gap-8 pt-4 xl:gap-24">
						<div className="flex w-full gap-2">
							<FileUpload
								title="Cargar CSV"
								accept=".csv"
								onChange={e => {
									const [file] = e.target.files || [];
									if (file.size > 10 * Math.pow(10, 6)) {
										return setError("El tamaño máximo es de 5MB");
									}
									setFile(file);
								}}
								dark={true}
							/>
						</div>
						<div className="flex">
							<Input
								className="h-8 w-48 rounded-r-none bg-foreground text-background"
								placeholder="Insertar Etiqueta"
								value={tag}
								onChange={e => {
									if (error) setError("");
									if (tag.length >= 20) {
										setTag(e.target.value.slice(0, 20));
										return setError("No exeder de 20 caracteres");
									}
									setTag(e.target.value);
								}}
								onKeyDown={e => {
									if (e.key === "Enter") handleAddTag();
								}}
							/>
							<Button variant="secondary" className="h-8 rounded-l-none" size={"sm"} onClick={handleAddTag}>
								Añadir
							</Button>
						</div>
					</div>
					<div className="flex justify-between pt-4">
						<div className="flex h-8 gap-4">
							{tags.map((tag, index) => (
								<div key={index} className="relative flex items-center gap-2 rounded-sm bg-slate-500 px-2 py-1 text-white">
									{tag}
									<button
										onClick={() => setTags(tags.filter((_, i) => i !== index))}
										className="absolute -right-2 -top-2 rounded-full border bg-black"
									>
										<Close className="h-4 w-4" />
									</button>
								</div>
							))}
						</div>
						{error && <div className="mr-8 w-60 text-sm text-red-500">{error}</div>}
					</div>
					<Button
						variant="secondary"
						className="mx-auto mt-6 h-8 w-48"
						onClick={handleProcessContacts}
						disabled={!file || !tags.length || loading}
					>
						{loading ? <Spinner className="w-5 animate-spin" /> : "Procesar Contactos"}
					</Button>
				</div>
			</Modal>
		</>
	);
}

export default ProcessContacts;
