import {useParams} from "react-router-dom";
import {SponsorAdViewsByAds} from "../../../../components/blocks/Stats/SponsorAdViewsByAds";
import {SponsorAdViews} from "../../../../components/blocks/Stats/SponsorAdViews";
import {SponsorGoogleWorldChart} from "../../../../components/blocks/Stats/SponsorGoogleWorldChart";
import {Card} from "../../../../components/blocks/Stats/Card";
import Select from "react-select";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {videosActions} from "../../../../_store/features/videos/videos-slice";
import {auth} from "../../../../firebase";
import {advertisementsActions} from "../../../../_store/features/advertisements/advertisements-slice";
import {selectStyles} from "../../../../../utils/SelectStyles";
import {userAuthActions} from "../../../../_store/features/user-auth/user-auth-slice";
import {UserAuth} from "../../../../data-access/user-auth";
import {DatePickerWithRange} from "../../../../components/blocks/DateRangePicker";
import {DateRange} from "react-day-picker";
import {endOfToday, formatISO9075, roundToNearestMinutes, subMonths} from "date-fns";
import {SelectOptionType} from "../../../../../utils/globalTypes";
import {Spinner} from "../../../../components/primitives/icons";

function SponsorDetail() {
	const {id, name} = useParams<{id: string; name: string}>();
	const dispatch = useAppDispatch();
	const videos = useAppSelector(state => state.videos.results);
	const advertisements = useAppSelector(state => state.advertisements);
	const [sponsorAdsData, setSponsorAdsData] = useState<Pick<UserAuth, "get_sponsor_data">>();
	const [adsList, setAdsList] = useState<SelectOptionType[]>([]);
	const [adsListLoading, setAdsListLoading] = useState(true);
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subMonths(to, 1);
		return {
			from,
			to,
		};
	});

	const [cardLoading, setCardLoading] = useState(true);
	const [adid, setAdid] = useState<string | undefined>();

	const imageAdvertisement = useMemo(() => {
		const sponsorAdds = advertisements.results.filter(advertisement => advertisement.sponsor_id === id);

		return sponsorAdds.map(advertisement => ({
			label: advertisement.text,
			value: advertisement.id,
		}));
	}, [advertisements, id]);

	const videoAdvertisement = useMemo(() => {
		return videos.map(video => ({
			label: video.title,
			value: video.uploadid,
		}));
	}, [videos]);

	useEffect(() => {
		setAdsList([]);
		const options = imageAdvertisement.concat(videoAdvertisement);
		options.unshift({label: "Todos", value: ""});
		setAdsList(options);
	}, [imageAdvertisement, videoAdvertisement]);

	useEffect(() => {
		if (!auth.currentUser) return;
		dispatch(
			videosActions.getVideosList({
				GCPUser: auth.currentUser,
				params: {page_size: 9999999, page: 0, video_type: 2, searchby: "sponsor_id", searchvalue: id},
			}),
		).finally(() => setAdsListLoading(false));
		dispatch(advertisementsActions.getAdvertisements({page_size: 99999, page: 0}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (!id) return;
		setCardLoading(true);
		dispatch(
			userAuthActions.getSponsorAdsData({
				sponsorid: id,
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
			}),
		)
			.then(({payload}) => (payload as any).get_sponsor_data && setSponsorAdsData(payload as any))
			.catch(err => console.log("🚀 ~ useEffect ~ err:", err))
			.finally(() => setCardLoading(false));
	}, [dispatch, adid, dates, id]);

	const totalImpressions = useMemo(() => {
		const bannerViews = sponsorAdsData?.get_sponsor_data?.banner_ads?.reduce((acc, ad) => acc + ad.views, 0) ?? 0;
		const videoViews = sponsorAdsData?.get_sponsor_data?.video_ads?.reduce((acc, ad) => acc + ad.views, 0) ?? 0;
		return bannerViews + videoViews;
	}, [sponsorAdsData]);

	const impressionsAvailable = useMemo(() => {
		if (!sponsorAdsData) return;
		const bannerPrints = sponsorAdsData?.get_sponsor_data?.banner_ads?.reduce((acc, ad) => acc + ad.prints, 0) ?? 0;
		const videoPrints = sponsorAdsData?.get_sponsor_data?.video_ads?.reduce((acc, ad) => acc + ad.prints, 0) ?? 0;
		return bannerPrints + videoPrints;
	}, [sponsorAdsData]);

	const adImpressions = useMemo(() => {
		if (!adid) return 0;
		const {banner_ads, video_ads} = sponsorAdsData?.get_sponsor_data || {};
		const bannerImpression = banner_ads?.find(add => add.id === adid) ?? null;
		const videoImpression = video_ads?.find(video => video.video_id === adid) ?? null;
		if (bannerImpression !== null) return bannerImpression.views;
		if (videoImpression !== null) return videoImpression.views;
		return 0;
	}, [adid, sponsorAdsData]);

	const adAvailable = useMemo(() => {
		const {banner_ads, video_ads} = sponsorAdsData?.get_sponsor_data || {};
		const bannerAmount = banner_ads?.filter(ad => ad.id === adid).reduce((acc, ad) => acc + ad.prints!, 0) ?? null;
		const videoAmount = video_ads?.filter(ad => ad.video_id === adid).reduce((acc, ad) => acc + ad.prints!, 0) ?? null;
		if (bannerAmount !== null && bannerAmount > 0) return bannerAmount;
		if (videoAmount !== null && videoAmount > 0) return videoAmount;
		return 0;
	}, [adid, sponsorAdsData]);

	const handleSelectAdvertisement = useCallback((adid: string) => (!adid ? setAdid(undefined) : setAdid(adid)), []);

	const handleDateRangeChange = useCallback((s: number, e: number) => setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)}), []);

	return (
		<div className="flex h-screen flex-col">
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Sponsor - {name}</h2>
				<Select
					className="basic-select mx-4 my-7 w-60 text-sm"
					styles={selectStyles}
					isSearchable={true}
					options={adsList}
					classNamePrefix="select"
					onChange={e => handleSelectAdvertisement(e?.value ?? "")}
					placeholder="Anuncios"
					isLoading={adsListLoading}
				/>
			</div>
			<DatePickerWithRange
				onDateRangeChange={handleDateRangeChange}
				date={dates}
				className="left-0 mt-5 w-80"
				disabled={{before: new Date('2023-10-01T00:00:00-05:00'), after: new Date()}}
			/>
			<div className="flex flex-col gap-6 px-8 py-6">
				<div className="col-span-7 grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-3">
					<Card title="Total Pagado" amount={Number(sponsorAdsData?.get_sponsor_data?.total_payments) || 0} loading={cardLoading} />
					<Card
						title="Cantidad de impresiones consumidas"
						amount={adid ? adImpressions : totalImpressions}
						isMoney={false}
						loading={cardLoading}
					/>
					<Card
						title="Cantidad de impresiones restantes"
						amount={adid ? adAvailable : impressionsAvailable}
						isMoney={false}
						loading={cardLoading}
					/>
				</div>
				<SponsorAdViews sponsorid={id} adid={adid} isCorporate date_range={dates} />
				<SponsorAdViewsByAds sponsorid={id} adid={adid} isCorporate date_range={dates} />
				{advertisements.loading ? (
					<div className="flex w-full justify-center">
						<Spinner />
					</div>
				) : (
					<SponsorGoogleWorldChart adsList={adsList} dates={dates} />
				)}
			</div>
		</div>
	);
}

export default SponsorDetail;
