import {Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {Star} from "../../../../components/primitives/icons";
import {Data} from "../../../../_store/features/feedback/types";
import {useMemo} from "react";

const backgroundColor = [
	"rgba(64, 107, 255, 0.8)",
	"rgba(125, 152, 255, 0.8)",
	"rgba(162, 182, 255, 0.8)",
	"rgba(198, 211, 255, 0.8)",
	"rgba(227, 233, 255, 0.8)",
];

const hoverBackgroundColor = [
	"rgba(227, 233, 255, 1)",
	"rgba(198, 211, 255, 1)",
	"rgba(162, 182, 255, 1)",
	"rgba(125, 152, 255, 1)",
	"rgba(64, 107, 255, 1)",
];

interface AverageRatingProps {
	data: Data | undefined;
}

export function AverageRating({data}: AverageRatingProps) {
	const prepareData = useMemo(() => {
		if (!data) return {total: 0, average: 0, stars: [0, 0, 0, 0, 0], percents: [0, 0, 0, 0, 0]};

		const total = data.currentRange.total;
		const average = data.currentRange.average;
		const stars = data.currentRange.data.reduce(
			(acc, item) => {
				for (let i = 0; i < 5; i++) {
					acc[i] += item.distribution[i];
				}
				return acc;
			},
			[0, 0, 0, 0, 0],
		);

		return {
			total,
			average,
			stars,
			percents: stars.map(star => Math.round((total > 0 ? (star / total) * 100 : 0) * 100) / 100),
		};
	}, [data]);

	return (
		<div className="shadow-xs flex-col items-center justify-center rounded-lg border border-gray-200 bg-white">
			<div className="border border-gray-200 p-2 font-bold">Calificación media y desglose</div>
			<div className="grid grid-cols-1 items-center gap-4 px-2 py-6 min-[1000px]:grid-cols-3">
				<div className="col-span-1 px-2">
					<ReviewDonutChart average={prepareData.average} total={prepareData.total} distribution={prepareData.stars} />
				</div>

				<div className="flex flex-col space-y-1 pr-4 lg:col-span-2">
					{prepareData.stars.map((star, index) => (
						<div className="flex items-center space-x-2">
							<div className="flex items-center space-x-0.5">
								<span className="w-5 text-center text-sm font-bold">{index + 1}</span>
								<Star className="h-3 w-3 text-gray-400" />
							</div>

							<div className="h-2 flex-1 overflow-hidden rounded bg-gray-50">
								<div
									className="h-full rounded"
									style={{width: `${prepareData.percents[index]}%`, backgroundColor: backgroundColor[index]}}
								></div>
							</div>

							<span className="flex w-12 text-xs font-semibold text-gray-500">
								<span className="">{star} </span>
								<span className="flex-1 text-center text-gray-400">({prepareData.percents[index]}%)</span>
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

ChartJS.register(ArcElement, Tooltip, Legend);

interface ReviewDonutChartProps {
	average: number;
	total: number;
	distribution: number[];
}

const options: ChartOptions<"doughnut"> = {
	plugins: {
		tooltip: {
			enabled: true,
		},
		legend: {
			display: false,
		},
	},
	backgroundColor: "transparent",
	color: "transparent",
	cutout: "75%",
};

const ReviewDonutChart = ({average, total, distribution}: ReviewDonutChartProps) => {
	const data = useMemo(
		() => ({
			labels: distribution.map((_, index) => `${index + 1} ⭐`),
			datasets: [
				{
					data: distribution,
					backgroundColor,
					hoverBackgroundColor,
					borderWidth: 1,
				},
			],
		}),
		[distribution],
	);

	// Chart options

	return (
		<div className="mx-auto h-full lg:w-[80px] xl:w-[100px] 2xl:w-[120px]">
			<div className="relative aspect-square ">
				<Doughnut data={data} options={options} />
				<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-center text-4xl font-bold lg:text-xl">
					<span>{average}</span>
					<div className="text-[10px] leading-none text-gray-400">{total} reseñas</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewDonutChart;
