import {useMemo} from "react";

interface CostEarnGraphProps {
	partnerPercentage: number;
	platformPercentage: number;
	partnerProfit: number;
	platformProfit: number;
	totalGrossProfit: number;
	totalProductionCost: number;
	loading?: boolean;
}

const MiniCardsSkeleton = () => <div className="mt-2 h-5 w-28 animate-pulse rounded-md bg-slate-300"></div>;
export default function CostEarnGraph({
	partnerPercentage,
	platformPercentage,
	partnerProfit,
	platformProfit,
	totalGrossProfit,
	totalProductionCost,
	loading,
}: CostEarnGraphProps) {
	const totalDataToShow = platformProfit + partnerProfit + totalProductionCost;
	const notHaveData = useMemo(() => (!totalGrossProfit && !totalProductionCost ? true : false), [totalGrossProfit, totalProductionCost]);

	const productionCostPercentage = useMemo(() => {
		if (totalProductionCost > totalGrossProfit) {
			return ((totalGrossProfit / totalProductionCost) * 100).toFixed(14);
		}
		return ((totalProductionCost / totalDataToShow) * 100).toFixed(14);
	}, [totalProductionCost, totalDataToShow, totalGrossProfit]);

	const partnerProfitPercentage = useMemo(() => {
		return ((partnerProfit / totalDataToShow) * 100).toFixed(14);
	}, [partnerProfit, totalDataToShow]);

	const platformProfitPercentage = useMemo(() => {
		if (totalProductionCost > totalGrossProfit) {
			return (100 - (totalGrossProfit / totalProductionCost) * 100).toFixed(14);
		}
		return ((platformProfit / totalDataToShow) * 100).toFixed(14);
	}, [platformProfit, totalDataToShow, totalProductionCost, totalGrossProfit]);

	return (
		<div className="col-span-7 rounded-lg border bg-card p-6 text-card-foreground shadow-sm">
			<div className="flex flex-col space-y-1.5">
				<h3 className="mb-2 text-lg font-semibold leading-none tracking-tight">Costos/Ganancias</h3>
				<p className="text-md my-1 leading-none tracking-tight">
					Partner: {!loading ? (notHaveData ? 0 : partnerPercentage.toPrecision(3)) : "..."}%
				</p>
				<p className="text-md my-1 leading-none tracking-tight">
					Plataforma: {!loading ? (notHaveData ? 0 : platformPercentage.toPrecision(3)) : "..."}%
				</p>
			</div>
			<div className="relative flex flex-col py-6">
				<div className="flex w-full flex-row justify-between">
					<div className="flex items-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className={`mt-1 h-3 w-8 rounded-sm bg-lime-600`} />
							{loading ? (
								<MiniCardsSkeleton />
							) : (
								<p className="text-center text-sm font-medium tracking-tight">{`Costo de producción $${totalProductionCost.toFixed(
									2,
								)}`}</p>
							)}
						</div>
					</div>
					<div className="flex items-center justify-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className="h-3 w-8 rounded-sm bg-[#005096]" />
							{loading ? (
								<MiniCardsSkeleton />
							) : (
								<p className="text-center text-sm font-medium tracking-tight">{`Ganancia del Partner $${partnerProfit.toFixed(
									2,
								)}`}</p>
							)}
						</div>
					</div>
					<div className="flex items-center justify-center">
						<div className="flex h-14 w-44 flex-col items-center justify-center rounded-lg border px-2">
							<div className={`h-3 w-8 rounded-sm ${platformProfit >= 0 ? "bg-[#4385BE]" : "bg-red-500"}`} />
							{loading ? (
								<MiniCardsSkeleton />
							) : (
								<p className="text-center text-sm font-medium tracking-tight">{`Ganancia de la Plataforma $${platformProfit.toFixed(
									2,
								)}`}</p>
							)}
						</div>
					</div>
				</div>
			</div>
			{loading ? (
				<div className="flex flex-row pt-0">
					<div className="min-w-1 h-14 w-full animate-pulse rounded-lg rounded-ss-lg bg-slate-300 p-5" />
				</div>
			) : (
				<>
					<div className="flex w-full flex-row overflow-hidden rounded-lg">
						<div
							className={`h-14 min-w-[40px] ${notHaveData ? "bg-red-500" : "bg-lime-600"} p-5 transition-all duration-300`}
							style={{width: productionCostPercentage + "%"}}
						/>
						{partnerProfit > 0 && (
							<div
								className={`h-14 min-w-[20px] bg-[#005096] transition-all duration-300`}
								style={{width: partnerProfitPercentage + "%"}}
							/>
						)}
						<div
							className={`h-14 ${platformProfit <= 0 ? " min-w-[40px] bg-red-500" : "100% bg-[#4385BE]"} transition-all duration-300`}
							style={{width: notHaveData ? "100%" : Number(platformProfitPercentage) + "%"}}
						/>
					</div>
				</>
			)}
		</div>
	);
}
