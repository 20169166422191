import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	deletePublicityBannerRangeData,
	editPublicityBannerRangeData,
	GetPublicityListParams,
	getPublicityRangeList,
	InsertPublicityBannerRangeBodyType,
	insertPublicityBannerRangeData,
	ListPublicityRangeResponse,
} from "../../../data-access/banners/banners-config";

const initialState: ListPublicityRangeResponse = {
	loading: false,
	results: [],
	totalResults: 0,
	current: 0,
	pageSize: 0,
	success: false,
};

const getBannersPublicityRange = createAsyncThunk("banners/list-config", async (params: GetPublicityListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getPublicityRangeList(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const insertBannersPublicityRange = createAsyncThunk("banners/insert-config", async (data: InsertPublicityBannerRangeBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await insertPublicityBannerRangeData(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem inserting banner publicity range, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("No se pudo insertar el nuevo rango, intenta nuevamente.");
	}
});

const deleteBannersPublicityRange = createAsyncThunk("banners/delete-config", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await deletePublicityBannerRangeData(token, {id});

		return id;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem deleting banners range data, try again.");
	}
});

const editBannersPublicityData = createAsyncThunk("banners/edit-config", async (data: InsertPublicityBannerRangeBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editPublicityBannerRangeData(token, data);

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem editing banners range data, try again.");
	}
});

const bannersConfigurationSlice = createSlice({
	name: "advertisements",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getBannersPublicityRange.pending, state => {
				state.loading = true;
			})
			.addCase(getBannersPublicityRange.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getBannersPublicityRange.rejected, (state, action) => {});
		builder
			.addCase(editBannersPublicityData.pending, state => {
				state.loading = true;
			})
			.addCase(editBannersPublicityData.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
			})
			.addCase(editBannersPublicityData.rejected, (state, action) => {});
		builder
			.addCase(insertBannersPublicityRange.pending, state => {
				state.loading = true;
			})
			.addCase(insertBannersPublicityRange.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertBannersPublicityRange.rejected, (state, action) => {});
		builder
			.addCase(deleteBannersPublicityRange.pending, state => {
				state.loading = true;
			})
			.addCase(deleteBannersPublicityRange.fulfilled, (state, action) => {
				state.loading = false;
				state.results = state.results.filter(el => el.id !== action.payload);
			})
			.addCase(deleteBannersPublicityRange.rejected, (state, action) => {});
	},
});

export const bannersActions = {
	...bannersConfigurationSlice.actions,
	getBannersPublicityRange,
	insertBannersPublicityRange,
	deleteBannersPublicityRange,
	editBannersPublicityData,
};

export default bannersConfigurationSlice;
