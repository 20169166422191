import {PayloadAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
	Comment,
	CustomerServiceCommentInitialBody,
	CustomerServiceCommentResponseBody,
	EditCommentBody,
	editCommentData,
	getCommentBranchData,
	GetCommentBranchParams,
	getCommentsList,
	GetCommentsListParams,
	insertCustomerServiceCommentInitial,
	insertCustomerServiceCommentResponse,
	RemoveCommentBody,
	removeCommentData,
} from "../../../data-access/comments/comments";
import {auth} from "../../../firebase";
import {createAppAsyncThunk} from "../../hooks";
import {getGlobalSetting} from "../../../data-access/settings";

export interface commentsInterface {
	totalResults: number;
	pageSize: number;
	current: number;
	loading: boolean;
	results: Comment[];
	error: string;
	success: boolean;
	rokuId: string;
}

const initialState: commentsInterface = {
	loading: false,
	results: [],
	totalResults: 0,
	pageSize: 0,
	current: 0,
	success: false,
	error: "",
	rokuId: "",
};

const getComments = createAppAsyncThunk("comments/list", async (params: GetCommentsListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		let rokuId = thunkAPI.getState().comments.rokuId;
		if (!rokuId.length) {
			const {data: settings} = await getGlobalSetting(token).catch(() => ({data: []}));
			console.log("getComments ~ settings", settings);
			rokuId = settings.find(el => el.key.trim() === "roku_community_parentid")?.value || "";
		}

		const response = await getCommentsList(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
			rokuId,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getCommentBranch = createAsyncThunk("comments/branch", async (params: GetCommentBranchParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getCommentBranchData(token, params);

		const deDupResponse: Comment[] = [];
		response.data.forEach(comment => (deDupResponse.some(el => el.id === comment.id) ? null : deDupResponse.push(comment)));

		return deDupResponse;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editComments = createAsyncThunk("comments/edit", async (data: EditCommentBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editCommentData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const removeComments = createAsyncThunk("comments/remove", async (data: RemoveCommentBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await removeCommentData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
		return {
			result: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const customerServiceCommentResponse = createAppAsyncThunk("comments/insert", async (body: CustomerServiceCommentResponseBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await insertCustomerServiceCommentResponse(token, body);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const customerServiceCommentInitial = createAppAsyncThunk("comments/initial", async (body: CustomerServiceCommentInitialBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await insertCustomerServiceCommentInitial(token, body);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const commentsSlice = createSlice({
	name: "comments",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getComments.pending, state => {
				state.loading = true;
			})
			.addCase(getComments.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...action.payload?.results!];
				state.current = action.payload?.current!;
				state.pageSize = action.payload?.pageSize!;
				state.totalResults = action.payload?.totalResults!;
				state.rokuId = action.payload?.rokuId!;
			})
			.addCase(getComments.rejected, (state, action) => {});
		builder
			.addCase(editComments.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(editComments.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editComments.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(removeComments.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(removeComments.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
			})
			.addCase(removeComments.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});
export const commentsActions = {
	...commentsSlice.actions,
	getComments,
	editComments,
	removeComments,
	getCommentBranch,
	customerServiceCommentResponse,
	customerServiceCommentInitial,
};

export default commentsSlice;
