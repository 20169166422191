import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../../components/primitives/Dialog";
import {Portal} from "@headlessui/react";
import {Checkbox} from "../../../../components/primitives/Checkbox";
import {Button} from "../../../../components/primitives/Button";
import {Close, ListPlus} from "../../../../components/primitives/icons";
import {Cost, InsertPlatformCostData, ListCostConceptResponse} from "../../../../data-access/dashboard/types";
import Select from "react-select";
import {Dispatch, SetStateAction, useState} from "react";
import {useAppDispatch} from "../../../../_store/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import toast from "react-hot-toast";
import {DayPicker} from "../../../../components/blocks/DatePicker";

interface EditModalProps {
	error?: string;
	openDialog: boolean;
	data?: InsertPlatformCostData;
	costConcepts?: {label: string; value: string}[] | undefined;
	isLoading: boolean;
	setError: (error: string) => void;
	setData: Dispatch<SetStateAction<Cost | undefined>>;
	onDismiss: () => void;
	onSubmit: () => void;
	setCostConceptsList: (costConcepts: {label: string; value: string}[]) => void;
}

export default function InsertModal({
	openDialog,
	isLoading,
	data,
	error,
	costConcepts,
	setError,
	onDismiss,
	onSubmit,
	setData,
	setCostConceptsList,
}: EditModalProps) {
	const dispatch = useAppDispatch();
	const [showTooltip, setShowTooltip] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedCostType, setSelectedCostType] = useState<{label: string; value: string} | undefined>();
	const [newCostType, setNewCostType] = useState<string>("");

	const handleAddNewType = () => {
		if (newCostType === "" || newCostType.length < 3) return;
		setLoading(true);
		dispatch(dashboardActions.insertCostConceptDataAction(newCostType))
			.then(res => {
				if (res.meta.requestStatus === "rejected") {
					setError("Ocurrió un error al intentar guardar los cambios");
					return toast.error("Error al añadir el tipo de costo");
				}
				dispatch(dashboardActions.listCostConceptDataAction({page_size: 9999})).then(res => {
					if (res.meta.requestStatus !== "fulfilled") return;
					const conceptListOptions = (res.payload as ListCostConceptResponse).results.map(c => ({label: c.description, value: c.id}));
					setCostConceptsList(conceptListOptions);
				});
				toast.success("Tipo de costo añadido correctamente");
			})
			.catch(err => {
				setError("Ocurrió un error al intentar guardar los cambios");
				console.log(err);
			})
			.finally(() => {
				setShowTooltip(false);
				setLoading(false);
			});
	};

	return (
		<Dialog
			open={openDialog}
			onOpenChange={() => {
				setShowTooltip(false);
				setSelectedCostType(undefined);
				onDismiss();
			}}
		>
			<DialogContent className="bg-neutral-700">
				<DialogHeader>
					<DialogTitle className="border-b border-border pb-2 font-medium text-white">Agregar costo</DialogTitle>
				</DialogHeader>
				{error && (
					<Portal>
						<div className="items fixed right-0 top-0 z-[700] mx-8 mt-4 flex items-center justify-between rounded bg-red-700 px-6 py-3 text-white shadow">
							{error}
							<button
								className="ml-2"
								onClick={() => {
									setShowTooltip(false);
									setSelectedCostType(undefined);
									onDismiss();
								}}
								title="Cerrar"
							>
								<Close />
							</button>
						</div>
					</Portal>
				)}
				<div className="container space-y-5 px-0.5 py-5">
					<div className="flex w-full justify-between space-x-4">
						<div className="flex items-center gap-2">
							<label className="text-white" htmlFor="cost">
								Costo:
							</label>
							<input
								id="cost"
								className="w-32 rounded-md border bg-gray-100 p-1"
								name="cost"
								defaultValue={0}
								step={1}
								type="number"
								value={data?.cost}
								// @ts-ignore
								onChange={e => setData(prev => ({...prev, cost: Number(e.target.value)}))}
							/>
						</div>
						<div className="flex items-center gap-2">
							<label className="text-white" htmlFor="units">
								Unidades:
							</label>
							<input
								id="units"
								className="w-32 rounded-md border bg-gray-100 p-1"
								name="units"
								defaultValue={0}
								step={1}
								type="number"
								value={data?.units}
								// @ts-ignore
								onChange={e => setData(prev => ({...prev, units: Number(e.target.value)}))}
							/>
						</div>
					</div>

					<div className="flex items-center gap-2">
						<label className="text-white" htmlFor="transaction_date">
							Fecha de transacción:
						</label>
						<DayPicker
							placeholder="Fecha"
							id="transaction_date"
							onDayChange={date => setData(prev => ({...prev, transaction_date: date?.toISOString()} as Cost))}
							className="flex-1 gap-0 border-0 text-white"
							formatDate="MM/yyyy"
							autoConfirm
						/>
					</div>

					<div className="flex w-full items-center justify-between">
						<div className="flex max-h-10 w-fit flex-row items-center space-x-3 space-y-0 rounded-md text-white">
							<Checkbox
								checked={data?.approved}
								// @ts-ignore
								onCheckedChange={checked => setData(prev => ({...prev, approved: checked as boolean}))}
							/>
							<div className="space-y-1 leading-none">
								<div className="text-sm">Aprobado</div>
							</div>
						</div>
						<div className="flex items-center justify-start gap-3 px-4">
							<Select
								className="basic-multi-select mt-1 rounded"
								options={costConcepts?.slice(1)}
								defaultValue={selectedCostType}
								// @ts-ignore
								onChange={e => {
									if (!e?.value) return;
									setSelectedCostType(e);
									// @ts-ignore
									setData(prev => ({...prev, cost_concept: e.value}));
								}}
								placeholder={"Tipo de costo"}
							/>
							<Button
								className="mr-2 mt-1 max-w-[100px] gap-2"
								type="button"
								variant={showTooltip ? "secondary" : "outline"}
								onClick={() => setShowTooltip(prev => !prev)}
							>
								<ListPlus />
							</Button>
						</div>
					</div>
					{showTooltip && (
						<div className="ml-auto flex w-fit gap-1">
							<input
								className="w-8/12 rounded px-2 py-1"
								placeholder="Nuevo tipo de costo"
								type="text"
								onChange={({target}) => setNewCostType(target.value)}
							/>
							<Button className="w-32 bg-black text-white" size="sm" variant="outline" onClick={handleAddNewType} disabled={loading}>
								{loading ? "Añadiendo..." : "Añadir tipo"}
							</Button>
						</div>
					)}

					<div className="flex w-full flex-col gap-2">
						<label className="text-white" htmlFor="description">
							Descripción:
						</label>
						<textarea
							className="w-full rounded bg-gray-100 p-1"
							value={data?.description ?? ""}
							// @ts-ignore
							onChange={e => setData(prev => ({...prev, description: e.target.value}))}
						/>
					</div>
				</div>

				<div className="flex flex-row justify-end">
					<Button className="mr-2 w-32 text-white" size="sm" variant="outline" onClick={onDismiss}>
						Cancelar
					</Button>
					<Button className="w-32 bg-black text-white" size="sm" variant="outline" onClick={onSubmit} disabled={isLoading}>
						Completar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
