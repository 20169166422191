import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, ChartData} from "chart.js";
import {useEffect, useMemo, useRef} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {useAppDispatch} from "../../../../_store/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {DateRange} from "react-day-picker";
import {Spinner} from "../../../../components/primitives/icons";
import {formatISO9075} from "date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface UsersDemographicChartProps {
	dates: DateRange;
}

export function UsersDemographicChart({dates}: UsersDemographicChartProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {age_range: string; male?: number; female?: number; other?: number}[]>>(null);
	const {data: statistics, isLoading} = useDashboardByNameQuery("usersDemographicData");
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			dashboardActions.getAllUsersDemographicData({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
			}),
		);
	}, [dates, dispatch]);

	const data: ChartData<"bar", {age_range: string; male?: number; female?: number; other?: number}[]> = useMemo(() => {
		if (!statistics) {
			return {
				datasets: [
					{
						label: "Hombres",
						data: [
							{
								age_range: "0",
								males: 0,
							},
						],
						backgroundColor: "#088395",
						borderRadius: 4,
						parsing: {
							xAxisKey: "age_range",
							yAxisKey: "males",
						},
					},
				],
			};
		}

		return {
			datasets: [
				{
					label: "Hombres",
					data: statistics?.map(item => ({
						age_range: item.age_range,
						male: item.male,
					})),
					backgroundColor: "#229fdc",
					borderRadius: 4,
					parsing: {
						xAxisKey: "age_range",
						yAxisKey: "male",
					},
				},
				{
					label: "Mujeres",
					data: statistics?.map(item => ({
						age_range: item.age_range,
						female: item.female,
					})),
					backgroundColor: "#ff626f",
					borderRadius: 4,
					parsing: {
						xAxisKey: "age_range",
						yAxisKey: "female",
					},
				},
				{
					label: "Otros",
					data: statistics?.map(item => ({
						age_range: item.age_range,
						other: item.other,
					})),
					backgroundColor: "#606e86",
					borderRadius: 4,
					parsing: {
						xAxisKey: "age_range",
						yAxisKey: "other",
					},
				},
			],
		};
	}, [statistics]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 py-6 pt-0 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Sexo y edad de los usuarios</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Bar
							ref={chartRef}
							height={350}
							options={{
								plugins: {
									legend: {
										position: "top",
									},
									tooltip: {
										callbacks: {
											title(tooltipItems) {
												if (!tooltipItems[0].label.includes("other")) return tooltipItems[0].label + " años";
												return "Otros";
											},
										},
									},
								},
								responsive: true,
								maintainAspectRatio: false,
								interaction: {
									mode: "index" as const,
									intersect: false,
								},
								scales: {
									x: {
										grid: {
											color: "transparent",
										},
										ticks: {
											callback: function (value) {
												switch (value) {
													case 0:
														return "17";
													case 1:
														return "18 - 24";
													case 2:
														return "25 - 34";
													case 3:
														return "35 - 44";
													case 4:
														return "45 - 54";
													case 5:
														return "55 - 64";
													case 6:
														return "más de 65";
													default:
														return "otros";
												}
											},
										},
									},
									y: {
										ticks: {
											precision: 0,
										},
									},
								},
							}}
							data={data}
							id="demographic"
						/>
					</div>
				)}
			</div>
		</div>
	);
}
