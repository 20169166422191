import {NotificationSettings} from "../app/data-access/notifications/notifications";

const REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const validateEmail = (email: string) => {
	return REGEX.test(email);
};

export const addDisplayEmojiToCustomNotification = (notificationsList: (NotificationSettings & {active?: boolean})[]) => {
	return notificationsList.map(notification => {
		let emoji = "";
		if (notification.display === "in_app") emoji = "📱";
		if (notification.display === "push") emoji = "📥";
		if (notification.display === "email") emoji = "📧";

		return {
			...notification,
			label: `${emoji !== "" ? `${emoji} ` : ""}${notification.label}`,
		};
	});
};

export const removeEmojisOfString = (str: string) => {
	const regex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
	return str.replace(regex, "");
};

export const addOrRemoveStringSuffix = (str: string, suffix: string) => {
	if (str.endsWith(suffix)) return str.replace(suffix, "");
	return `${str}${suffix}`;
};

export const urlRegex = new RegExp(
	"^(https?:\\/\\/)?" + // protocol
		"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
		"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
		"(\\:\\d+)?" + // port
		"(\\/[-a-z\\d%_.~+]*)*" + // path
		"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
		"(\\#[-a-z\\d_]*)?$",
	"i", // fragment locator
);
