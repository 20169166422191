import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
	dashboardActions,
	selectedPartnerSerieContentSelector,
	selectedPartnerSerieSelector,
} from "../../../../_store/features/dashboard/dashboard-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {LikesAndFavorites, MembershipUserViews, TopViews, ViewsTimeSeries} from "../../../../components/blocks/Stats";
import {PartnerSerieContentSelect} from "../../_subcomponents/PartnerSerieContentSelect";

export function ContentStatistics() {
	const selectedPartnerSerie = useAppSelector(selectedPartnerSerieSelector);
	const selectedPartnerSerieContent = useAppSelector(selectedPartnerSerieContentSelector);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!selectedPartnerSerie) {
			navigate("/partner/contents");
		}
	}, [navigate, selectedPartnerSerie]);

	const handleClickOnContent = (contentId: string) => {
		dispatch(dashboardActions.setSelectedContentId(contentId));
		navigate(`/partner/contents/${selectedPartnerSerie?.serieid}/${contentId}`);
	};

	return (
		<>
			<div className="mb-6 flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">{selectedPartnerSerie?.title}</h2>
				<PartnerSerieContentSelect />
			</div>
			<ViewsTimeSeries
				serieid={selectedPartnerSerie?.serieid}
				contentid={selectedPartnerSerieContent?.contentid}
				title="Cantidad de vistas diarias"
			/>
			{selectedPartnerSerie?.serieid && (
				<LikesAndFavorites seriesIds={selectedPartnerSerie?.serieid} contentId={selectedPartnerSerieContent?.contentid} />
			)}
			<div className="grid gap-6 px-8 pb-6 xl:grid-cols-[min-content,auto]">
				<div>
					<MembershipUserViews serieid={selectedPartnerSerie?.serieid} contentid={selectedPartnerSerieContent?.contentid} />
				</div>
				<div>
					<TopViews type="contents" serieid={selectedPartnerSerie?.serieid} onClickContent={handleClickOnContent} />
				</div>
			</div>
		</>
	);
}
