import {zodResolver} from "@hookform/resolvers/zod";
import {useEffect, useState, useMemo} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-hot-toast";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {Input} from "../../../components/primitives/Input";
import {Spinner} from "../../../components/primitives/icons";
import {useNavigate} from "react-router-dom";
import {
	InsertPublicityBannerRangeBodySchema,
	InsertPublicityBannerRangeBodyType,
	PublicityRange,
	getPublicityRangeList,
} from "../../../data-access/banners/banners-config";
import {bannersActions} from "../../../_store/features/banners-add-rotation/banners-configuration-slice";
import Select, {SingleValue} from "react-select";
import {selectGlobalStyles} from "../../../../utils/selectGlobalStyles";
import {SelectOptionType} from "../../../../utils/globalTypes";
import {serieContentsActions} from "../../../_store/features/serie-content/serie-content-slice";
import {useDebouncedValue} from "../../../components/hooks/useDebounce";
import {Button} from "../../../components/primitives/Button";
import {auth} from "../../../firebase";
import {getSerieContent} from "../../../data-access/series/content";
import {CONTENT} from "../../../constants";

const initialState: InsertPublicityBannerRangeBodyType = {
	content_id: "",
	start_time: 0,
	rotation_interval: 0,
	end_time: 0,
};

function NewVisibilityConfig() {
	const dispatch = useAppDispatch();
	const contents = useAppSelector(state => state.serieContent);
	const navigate = useNavigate();
	const [search, setSearch] = useState<string>("");
	const debouncedSearchValue = useDebouncedValue(search);
	const [contentList, setContentList] = useState<SelectOptionType[]>([]);
	const [bannersConfig, setBannersConfig] = useState<PublicityRange[]>([]);
	const [selectedContent, setSelectedContent] = useState<SelectOptionType | null>(null);
	const [videoDuration, setVideoDuration] = useState<string>();
	const [page, setPage] = useState({
		page: 0,
		page_size: 10,
	});

	const {
		register,
		handleSubmit,
		control,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<InsertPublicityBannerRangeBodyType>({
		resolver: zodResolver(InsertPublicityBannerRangeBodySchema),
		defaultValues: initialState,
	});

	const handleUpdateVisibility: SubmitHandler<InsertPublicityBannerRangeBodyType> = data => {
		if (data.id) {
			if (!data.content_id || !data.id) {
				toast.error("Asegúrese que estén todos los datos correctamente antes de continuar.");
				return;
			}
		}

		const validInput = validateInput(data.start_time, data.end_time, data.rotation_interval);
		if (!validInput) {
			return;
		}

		return dispatch(bannersActions.insertBannersPublicityRange(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Configuración de Visibilidad insertada correctamente.");
				setTimeout(() => {
					navigate(-1);
				}, 2000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleFindContents = () => {
		dispatch(
			serieContentsActions.listSeriesContent({
				page: page.page,
				searchby: "title",
				searchvalue: debouncedSearchValue,
				page_size: page.page_size,
			}),
		);
	};

	function handleSelectUser(newValue: SingleValue<SelectOptionType>) {
		if (newValue) {
			getSelectedContent(newValue.value);
			handlePreviousConfiguration(newValue.value);
		}
	}

	const handleOnScrollEnd = () => {
		if (contentList.length !== contents.totalResults) {
			setPage(prev => ({...prev, page: prev.page + 1}));
		}
	};

	useEffect(() => {
		setPage({page: 0, page_size: 10});
		setContentList([]);
		handleFindContents();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchValue]);

	useEffect(() => {
		if (debouncedSearchValue !== "") return;
		handleFindContents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.page]);

	useMemo(() => {
		if (!contents.results.length) return;
		const includesIds = contentList.some(cont => contents.results.map(cont => cont.id).includes(cont.value));
		if (includesIds) return;
		const filteredContents = contents.results.filter(content => content.content_type !== CONTENT.EXCLUSIVE_ROOM);
		setContentList(prev => {
			return [
				...prev,
				...filteredContents.map(cont => {
					return {
						label: cont.title,
						value: cont.id,
					};
				}),
			];
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contents.results]);

	const getSelectedContent = async (id: string) => {
		const token = await auth.currentUser?.getIdToken();
		if (!token || !id) return;

		const response = await getSerieContent(token, {
			page: 0,
			page_size: 1,
			searchby: "id",
			searchvalue: id,
		});

		if (response.status === 200) {
			setSelectedContent({
				label: response.data.results[0].title,
				value: response.data.results[0].id,
			});
			setVideoDuration(response.data.results[0].duration);
		}
	};

	const handlePreviousConfiguration = async (id: string) => {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getPublicityRangeList(token, {page_size: 100, page: 0, searchby: "content_id", searchvalue: id});
		if (response.status === 200 && response.data.results.length > 0) {
			setBannersConfig(response.data.results);
		}
	};

	function validateTimeOverlap(startTime: number, endTime: number): boolean {
		for (const banner of bannersConfig) {
			if (startTime < banner.end_time && endTime > banner.start_time) {
				// There is an overlap
				return true;
			}
		}
		return false;
	}

	const validateInput = (start: number, end: number, interval: number): boolean => {
		if (start > end) {
			toast.error("El tiempo de inicio no puede ser mayor que el tiempo de fin.");
			return false;
		}

		if (interval > end - start) {
			toast.error("El intervalo de tiempo no puede ser mayor que el tiempo del contenido.");
			return false;
		}

		if (end > Number(videoDuration)) {
			toast.error("El tiempo de fin no puede ser mayor que la duración del contenido.");
			return false;
		}

		if (validateTimeOverlap(start, end)) {
			toast.error("Ya existe una configuración previa para este contenido en estos intervalos de tiempo.");
			return false;
		}

		return true;
	};

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Insertar Configuración de Visibilidad</h2>
				<span className="text-base text-muted-foreground lg:text-lg">
					Permite insertar una nueva configuración para la visibilidad de los anuncios en un contenido específico.
				</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(handleUpdateVisibility)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-gray-100">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Contenido</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="mt-1 flex flex-row items-center text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Controller
											name="content_id"
											control={control}
											render={({field}) => (
												<Select
													className="basic-multi-select mr-2 min-w-[200px] rounded"
													styles={selectGlobalStyles}
													options={contentList}
													onInputChange={e => setSearch(e)}
													defaultValue={selectedContent}
													onChange={selectedOption => {
														handleSelectUser(selectedOption);
														field.onChange(selectedOption?.value);
													}}
													onMenuScrollToBottom={handleOnScrollEnd}
													placeholder="Vincula un contenido"
													isLoading={contents.loading}
												/>
											)}
										/>
									</div>
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Duración del video <span className="text-xs font-bold text-destructive"> *segundos</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input placeholder="Duración del video" disabled defaultValue={videoDuration} />
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Inicio de la ventana<span className="text-xs font-bold text-destructive"> *segundos</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										placeholder="Inicio de la ventana de anuncios"
										min={0}
										{...register("start_time", {min: 0, valueAsNumber: true})}
									/>
									{errors?.start_time?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.start_time.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Intervalo de rotación <span className="text-xs font-bold text-destructive"> *segundos</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										placeholder="Intervalo de rotacion"
										min={0}
										{...register("rotation_interval", {min: 0, valueAsNumber: true})}
									/>
									{errors?.rotation_interval?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.rotation_interval.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Fin de la ventana <span className="text-xs font-bold text-destructive"> *segundos</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										placeholder="Fin de la ventana de anuncios"
										min={0}
										{...register("end_time", {min: 0, valueAsNumber: true})}
									/>
									{errors?.end_time?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.end_time.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								navigate(-1);
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Insertar
						</Button>
					</div>
				</form>
			</div>
		</section>
	);
}

export default NewVisibilityConfig;
