import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {toast} from "react-hot-toast";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {Listbox, Transition} from "@headlessui/react";
import {Button} from "../../../../../../components/primitives/Button";
import {ScrollArea} from "../../../../../../components/primitives/ScrollArea";
import {Sheet, SheetClose, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../../../../components/primitives/Sheet";
import {Textarea} from "../../../../../../components/primitives/Textarea";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {formDbActions} from "../../../../../../_store/features/forms/customer-service-slice";
import {Chevron, Spinner} from "../../../../../../components/primitives/icons";
import {CreateSubjectChatBodyRequest, GetChatsBySubjectIdResponse, Subjects} from "../../../../../../data-access/fetch-form-data";

interface ReplySheetProps extends SheetProps {
	contactData: Subjects;
	onSubmit?: () => void;
}

const STATUS = [
	{display: "Iniciado", value: "open"},
	{display: "Mensaje de usuario", value: "customer_reply"},
	{display: "Mensaje de soporte", value: "pending"},
	{display: "Cerrado", value: "closed"},
];

export default function MessageReplySheet(props: ReplySheetProps) {
	const {contactData, onSubmit, ...rest} = props;

	const [selectedStatus, setSelectedStatus] = useState(STATUS[0]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [statusLoading, setStatusLoading] = useState<boolean>(false);
	const bottomEl = useRef<HTMLDivElement>(null);

	const [commentsThread, setCommentsThread] = useState<Subjects[]>([]);

	const [content, setContent] = useState<string>("");
	const dispatch = useAppDispatch();

	const scrollToBottom = useCallback(() => {
		bottomEl.current?.scrollIntoView({behavior: "smooth"});
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => scrollToBottom(), [commentsThread]);

	useEffect(() => {
		const defaultValue = contactData?.status ?? "open";
		const defaultOption = STATUS.find(option => option.value === defaultValue);
		defaultOption && setSelectedStatus(defaultOption);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactData?.status]);

	useEffect(() => {
		if (!contactData?.subject_id) return;
		setIsLoading(true);
		dispatch(formDbActions.getChatsBySubjectIdAction({subject_id: contactData.subject_id}))
			.then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					const chats = res.payload as GetChatsBySubjectIdResponse;
					setCommentsThread(chats.results);
				}
				if (res.meta.requestStatus === "rejected") {
					toast.error("Error al actualizar la información.", {position: "bottom-left"});
				}
			})
			.catch(() => toast.error("Error al actualizar la información.", {position: "bottom-left"}))
			.finally(() => setIsLoading(false));
		return () => {
			setContent("");
			setCommentsThread([]);
			setIsLoading(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactData?.subject_id]);

	const handleInsertReply = (data: CreateSubjectChatBodyRequest) => {
		if (!contactData.subject_id) {
			toast.error("Error al enviar la información.", {position: "bottom-left"});
			return;
		}
		dispatch(formDbActions.createSubjectChatAction(data))
			.then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					toast.success("Se actualizaron los datos correctamente.", {position: "bottom-left"});
				}
				if (res.meta.requestStatus === "rejected") {
					toast.error("Error al enviar la información.", {position: "bottom-left"});
				}
			})
			.catch(() => toast.error("Error al enviar la información.", {position: "bottom-left"}));
	};

	const handleChangeData = (value?: string) => setContent(value ?? "");

	const handleStatusChange = (value?: string) => {
		const status = STATUS.find(status => status.value === value) ?? STATUS[0];
		if (!contactData.subject_id || !status.value) {
			toast.error("Error al enviar la información.", {position: "bottom-left"});
			return;
		}
		setStatusLoading(true);
		setSelectedStatus(status);
		dispatch(formDbActions.updateChatStatusAction({subject_id: contactData.subject_id, status: status.value}))
			.then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					toast.success("Estado actualizado correctamente.", {position: "bottom-left"});
				}
				if (res.meta.requestStatus === "rejected") {
					toast.error("Error al actualizar el estado.", {position: "bottom-left"});
				}
			})
			.catch(() => toast.error("Error al actualizar el estado.", {position: "bottom-left"}))
			.finally(() => setStatusLoading(false));
		if (onSubmit) onSubmit();
	};

	return (
		<Sheet {...rest}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>Detalles del mensaje</SheetTitle>
					<SheetDescription>Deja una nota sobre la respuesta que se le dió a este usuario.</SheetDescription>
				</SheetHeader>
				<div className="flex flex-row gap-2 justify-between">
					<div className="flex flex-row">
						<span className="justify-center self-center font-medium">Usuario:</span>
						<div className="flex flex-col">
							<span className="ml-2 text-sm">{contactData?.author !== "" ? contactData?.author : "Anónimo"}</span>
							<span className="ml-2 text-xs">{contactData?.email ?? "Sin definir"}</span>
						</div>
					</div>

					<div className="flex flex-row">
						<span className="mr-2 justify-center self-center font-medium">Estado:</span>
						<Listbox value={selectedStatus} disabled={statusLoading} onChange={value => handleStatusChange(value.value)}>
							<div className="relative">
								<Listbox.Button className="relative h-full w-full cursor-default rounded-sm bg-neutral-900 py-2 pl-3 pr-10 text-left text-white shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
									<span className="block truncate">{selectedStatus.display}</span>
									<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
										{statusLoading ? (
											<Spinner style={{width: 20, height: 20}} />
										) : (
											<Chevron className="h-5 w-5 rotate-180 text-gray-400" aria-hidden="true" />
										)}
									</span>
								</Listbox.Button>
								<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
									<Listbox.Options className="absolute right-0 z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
										{STATUS.map((option, personIdx) => (
											<Listbox.Option
												key={personIdx}
												className="relative cursor-default select-none px-4 py-2 hover:bg-neutral-300"
												value={option}
											>
												{({selected}) => (
													<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
														{option.display}
													</span>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</Listbox>
					</div>
				</div>

				<ScrollArea className="relative h-full w-full rounded-md border px-4 pb-0 text-sm" contentEditable={false}>
					{isLoading ? (
						<div className="absolute right-1/2 top-1/2">
							<Spinner />
						</div>
					) : commentsThread.length !== 0 ? (
						<>
							{commentsThread.map(item => {
								if (!item?.created_at) return "Sin definir";
								const date = new Date(item.created_at);
								return (
									<div className="flex w-full flex-col items-start">
										<div
											className={`my-4 flex w-fit flex-col gap-1 ${
												item.type === 2 ? "self-end bg-slate-300" : "bg-slate-200"
											} rounded-lg p-3`}
										>
											<p className="font-semibold">{format(date, "dd/MMM/yyyy - h:mm:ss aa", {locale: es})}</p>
											<p className="text-left">{item?.content ?? ""}</p>
										</div>
									</div>
								);
							})}
							<div ref={bottomEl} />
						</>
					) : (
						<p className="text-center text-lg">No hay comentarios disponibles</p>
					)}
				</ScrollArea>

				<div>
					<Textarea placeholder={"Insertar una respuesta..."} rows={5} onChange={e => handleChangeData(e.target.value)} />
				</div>
				<SheetClose asChild>
					<Button
						type="button"
						className="mt-4"
						onClick={() => {
							handleInsertReply({subject_id: contactData.subject_id, content});
							onSubmit && onSubmit();
						}}
						disabled={isLoading || content === ""}
					>
						Enviar
					</Button>
				</SheetClose>
			</SheetContent>
		</Sheet>
	);
}
