import {useState} from "react";
import {Modal} from "../../../components/primitives/Modal";
import {Button} from "../../../components/primitives/Button";
import {Input} from "../../../components/primitives/Input";
import {Email, QuestionMarkCircle, Spinner} from "../../../components/primitives/icons";
import {EmailSendInformationType, getEmailSendInformation} from "../../../data-access/email-details/email-details";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../components/primitives/ToolTip";

function EmailSendInformation() {
	const [open, setOpen] = useState(false);
	const [data, setData] = useState<EmailSendInformationType>();
	const [loading, setLoading] = useState(false);

	const [searchValue, setSearchValue] = useState("");

	const handleSearch = async () => {
		if (!searchValue || searchValue.length < 4) return;
		setLoading(true);
		try {
			const response = await getEmailSendInformation({email: searchValue});
			setData(response.data);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Button onClick={() => setOpen(true)} className="gap-2 bg-slate-500 px-4">
				<Email />
				Envío de correos
			</Button>
			<Modal
				title="Información de Envío de Correos"
				open={open}
				onDismiss={() => {
					setOpen(false);
				}}
				big
			>
				<div className="overflow-x mt-4 flex min-w-[70vw] flex-col gap-6 rounded-sm bg-white p-4">
					<div className="sticky top-8 flex max-w-[300px] bg-white">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							onKeyDown={e => {
								if (e.key === "Enter") handleSearch();
							}}
							value={searchValue}
							placeholder="Inserte el correo..."
						/>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch} disabled={loading}>
							Buscar
						</Button>
					</div>

					<table className="w-full text-left">
						<thead className="flex w-full bg-slate-200">
							<tr className="flex w-full">
								<th className="w-1/6 p-2">Fecha</th>
								<th className="w-2/6 p-2">Remitente</th>
								<th className="w-2/6 p-2">Destinatario</th>
								<th className="mr-4 w-1/6 p-2 text-center">Status</th>
							</tr>
						</thead>

						<tbody className="bg-grey-light flex h-[50vh] w-full flex-col items-center overflow-y-scroll">
							{loading ? (
								<tr>
									<td className="h-[50vh]">
										<Spinner className="m-auto w-4 animate-spin" />
									</td>
								</tr>
							) : data && Object.keys(data).length > 0 ? (
								Object.keys(data).map((item, index) => {
									const date = data[item][0].split("hermes")[0];
									let sender = "";
									let receiver = "";
									let status = "";
									let details = "";

									data[item].forEach(info => {
										if (info.includes("from=") && sender === "") {
											sender = info.split("from=<")[1].split(">")[0];
										}
										if (info.includes("to=") && receiver === "") {
											receiver = info.split("to=<")[1].split(">")[0];
										}
										if (info.includes("status=") && status === "") {
											status = info.split("status=")[1].split(" ")[0];
											if (status === "sent") status = "Enviado";
											if (status === "deferred") status = "Diferido";
											if (status === "bounced") status = "Rebotado";
											details = info.split(" (")[1];
										}
									});

									return (
										<tr className="flex w-full" key={index}>
											<td className="w-1/6 overflow-hidden p-2">{date}</td>
											<td className="w-2/6 overflow-hidden p-2">{sender}</td>
											<td className="w-2/6 overflow-hidden p-2">{receiver}</td>
											<td className="w-1/6 overflow-hidden p-2 text-center">
												{status === "Enviado" ? (
													"Enviado"
												) : (
													<TooltipProvider>
														<Tooltip>
															<TooltipTrigger asChild>
																<div className="flex justify-center">
																	<QuestionMarkCircle color="red" className="my-auto mr-2 h-5 w-5" />
																	{status}
																</div>
															</TooltipTrigger>
															<TooltipContent>
																<p className="w-96">{details}</p>
															</TooltipContent>
														</Tooltip>
													</TooltipProvider>
												)}
											</td>
										</tr>
									);
								})
							) : (
								<tr className="mb-4 flex w-full">
									<td className="w-full p-2">No hay información</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</Modal>
		</>
	);
}

export default EmailSendInformation;
