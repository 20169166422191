import {Tab} from "@headlessui/react";
import {useEffect, useMemo} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {cn} from "../../../../../utils/classNames";
import {useAppDispatch} from "../../../../_store/hooks";
// import Memberships from "./Tabs/Memberships";
import PasswordManagement from "./Tabs/PasswordManagement";
import UserData from "./Tabs/UserData";
import UserNotifications from "./Tabs/UserNotifications";
import Subscriptions from "./Tabs/Subscriptions/Subscriptions";
import {vendorActions} from "../../../../_store/features/vendor/slice";

const tabsCls = ({selected}: {selected: boolean}) =>
	cn(
		"w-full py-4 text-xs font-medium uppercase leading-tight ",
		"focus:outline-none ",
		selected ? "border-b-2 border-b-black bg-white text-black shadow" : "text-neutral-500",
	);

export default function CustomerEdit() {
	let {id} = useParams();
	const [searchParams] = useSearchParams();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (id) dispatch(vendorActions.fetchCustomer(id as string));
		return () => {
			dispatch(vendorActions.resetToInitialState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const tabIndex = useMemo(() => {
		const tabIndex = searchParams.get("tab");
		if (tabIndex) return parseInt(tabIndex);
	}, [searchParams]);

	if (!id) {
		return <div>No Params Id</div>;
	}

	return (
		<Tab.Group defaultIndex={tabIndex}>
			<Tab.List className="flex items-center justify-center space-x-1 border-b border-gray-200">
				<Tab className={tabsCls}>Editar Usuario</Tab>
				<Tab className={tabsCls}>Manejo de Contraseña</Tab>
				<Tab className={tabsCls}>Suscripciones</Tab>
				<Tab className={tabsCls}>Notificaciones</Tab>
			</Tab.List>
			<Tab.Panels>
				<Tab.Panel>
					<UserData id={id} />
				</Tab.Panel>
				<Tab.Panel>
					<PasswordManagement />
				</Tab.Panel>
				<Tab.Panel>
					<Subscriptions />
				</Tab.Panel>
				<Tab.Panel>
					<UserNotifications />
				</Tab.Panel>
			</Tab.Panels>
		</Tab.Group>
	);
}
