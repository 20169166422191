export enum COMMENTS_TYPES {
	TEXT = 0,
	STICKER = 1,
	LIKE = 2,
	DISLIKE = 3,
	EDITED = 4,
	REQUESTED_HIDDEN = -1,
	REQUESTED_HIDDEN_AUTHOR = -2,
	AUTHOR_BANNED = -3,
	AUTHOR_DELETED = -4,
	DELETED = -5,
	EXCLUSIVE_ROOM_REACTION = 101,
	TEXT_COMMENT = 100,
}
