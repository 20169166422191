import {useMemo, useState} from "react";
import {GoogleMap, InfoWindowF, MarkerF, useLoadScript} from "@react-google-maps/api";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import Loader from "../../../../components/blocks/Stats/SponsorAdViews/Loader";
import {getRadio} from "../../../../../utils/getMapMarkerRadio";

interface LocationInterface {
	latitude: string;
	location: string;
	longitude: string;
	view_count: number;
}
export default function AppInstallationsWorldMap() {
	const appInstallations = useDashboardByNameQuery("appInstallationsByLocation");
	const [activeMarker, setActiveMarker] = useState<LocationInterface | null>(null);

	const {isLoaded} = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
	});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const center = useMemo(() => ({lat: 0, lng: 0}), []);

	return (
		<div className="mb-5 flex flex-1 flex-col rounded-lg border bg-card">
			<div className="flex flex-row items-center justify-between">
				<h3 className="p-8 text-left text-lg font-semibold leading-none tracking-tight">Instalaciones por Países.</h3>
			</div>
			<div className="flex items-center justify-between border-b border-border" />

			{!isLoaded || appInstallations.isLoading ? (
				<Loader title="" />
			) : (
				<GoogleMap
					center={center}
					zoom={3}
					mapContainerStyle={{height: 700, width: "100%"}}
					options={{
						mapTypeControl: false,
						streetViewControl: false,
						zoomControlOptions: {
							position: 9,
						},
						minZoom: 3,
						maxZoom: 5,
					}}
				>
					{appInstallations.data?.installations_by_location.map((item, index: number) => {
						return (
							<MarkerF
								key={index}
								position={{lat: Number(item.latitude), lng: Number(item.longitude)}}
								cursor="default"
								icon={{
									path: "M 0,0 m -2,0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0",
									fillColor: "#FF0000",
									fillOpacity: 0.4,
									strokeWeight: 0,
									scale: getRadio(item.view_count, 3500),
								}}
								onClick={() => setActiveMarker(item)}
							>
								{activeMarker && activeMarker === item ? (
									<InfoWindowF onCloseClick={() => setActiveMarker(null)}>
										<div className="my-2 flex flex-col gap-1">
											<p className="text-start ">
												<b>Region:</b> {activeMarker.location}
											</p>
											<p className="text-start">
												<b>Installations:</b> {activeMarker.view_count}
											</p>
										</div>
									</InfoWindowF>
								) : null}
							</MarkerF>
						);
					})}
				</GoogleMap>
			)}
		</div>
	);
}
