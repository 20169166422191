import {useEffect, useMemo, useState} from "react";
import {BarsPlus} from "../../../components/primitives/icons";
import {DatePickerWithRange} from "../../../components/blocks/DateRangePicker";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {cn} from "../../../../utils/classNames";
import {Button} from "../../../components/primitives/Button";
import {Link} from "react-router-dom";
import {DataTable} from "../../../components/blocks/DataTable";
import {RankedContent} from "../../../data-access/series/content";
import {DateRange} from "react-day-picker";
import {endOfToday, formatISO9075, roundToNearestMinutes, subMonths} from "date-fns";
import {contentRankingActions, contentRankingSelectors} from "../../../_store/features/content-ad/content-ranking-slice";
import Select, {ActionMeta, SingleValue} from "react-select";
import {selectStyles} from "../../../../utils/SelectStyles";
import {SelectOptionType} from "../../../../utils/globalTypes";
import {seriesActions} from "../../../_store/features/series/series-slice";
import {CONTENT_TYPES} from "../../../constants";

const columnHelper = createColumnHelper<RankedContent>();

export default function RankedContents() {
	const dispatch = useAppDispatch();
	const {results: data, loading: isLoading, totalResults, pageSize} = useAppSelector(state => state.contentsRanking);
	const scoreData = useAppSelector(contentRankingSelectors.contentScoreSelector);
	const {results: series, loading: seriesLoading} = useAppSelector(state => state.series);
	const [serieId, setSerieId] = useState<string | undefined>();
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subMonths(to, 1);
		return {
			from,
			to,
		};
	});
	const [contentType, setContentType] = useState<number | undefined>();

	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	function getRankingResume() {
		dispatch(
			contentRankingActions.getRankedContents({
				final_date: formatISO9075(dates?.to!),
				initial_date: formatISO9075(dates?.from!),
				page: page.pageIndex,
				page_size: page.pageSize,
				serie_id: serieId,
				content_type: contentType,
			}),
		);
	}

	useEffect(() => {
		if (!page.pageSize) return;
		getRankingResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, serieId, page.pageSize, page.pageIndex, contentType]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page: 0, page_size: 99999999}}));
	}, [dispatch]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const ORDER_COL_WIDTH = 50;
		const LOGO_COL_WIDTH = 60;
		const CONTROL_COL_WIDTH = 80;
		if (tableWidth > 0) tableWidth = tableWidth - ORDER_COL_WIDTH - LOGO_COL_WIDTH - CONTROL_COL_WIDTH;

		return [
			columnHelper.accessor("rank_number", {
				id: "Orden",
				header: ({column}) => <DataTableColumnHeader title="Orden" column={column} />,
				cell: info => page.pageIndex * page.pageSize + info.row.index + 1,
				size: ORDER_COL_WIDTH,
				enableSorting: false,
				enableResizing: false,
			}),
			columnHelper.accessor("photo_url", {
				id: "Logo",
				header: undefined,
				cell: info => (
					<img
						alt="Banner"
						src={info.getValue()}
						className={cn(
							"relative mx-auto aspect-square w-full object-contain text-center",
							"before:absolute before:flex before:h-full before:w-full before:items-center before:justify-center before:border before:border-border before:bg-background before:text-xs before:font-medium before:content-['404']",
						)}
					/>
				),
				size: LOGO_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("name", {
				id: "Contenido",
				header: ({column}) => <DataTableColumnHeader title="Contenido" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis pl-3 text-left text-neutral-500">{info.row.original.name}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.14),
			}),
			columnHelper.accessor("rank_number", {
				id: "Puntos",
				header: ({column}) => (
					<DataTableColumnHeader title={`Puntos`} altTitle={serieId ? scoreData.total.toString() : undefined} column={column} />
				),
				cell: info => <span className="mr-auto truncate pl-3 font-medium">{info.getValue()}</span>,
				size: Math.floor(tableWidth * 0.11),
			}),
			columnHelper.accessor("views", {
				id: "Views",
				header: ({column}) => (
					<DataTableColumnHeader title="Views" altTitle={serieId ? scoreData.totalViewsByContents.toString() : undefined} column={column} />
				),
				cell: info => <span className="mr-auto truncate pl-3 font-medium">{info.row.original.views}</span>,
				size: Math.floor(tableWidth * 0.13),
			}),
			columnHelper.accessor("likes", {
				id: "Likes",
				header: ({column}) => (
					<DataTableColumnHeader title="Likes" altTitle={serieId ? scoreData.totalLikesByContents.toString() : undefined} column={column} />
				),
				cell: info => <span className="mr-auto truncate pl-3 font-medium">{info.row.original.likes}</span>,
				size: Math.floor(tableWidth * 0.11),
			}),
			columnHelper.accessor("follows", {
				id: "Follow",
				header: ({column}) => (
					<DataTableColumnHeader
						title="Follows"
						altTitle={serieId ? scoreData.totalFollowsByContents.toString() : undefined}
						column={column}
					/>
				),
				cell: info => <span className="mr-auto truncate pl-3 font-medium">{info.row.original.follows}</span>,
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.accessor("comments", {
				id: "Comments",
				header: ({column}) => (
					<DataTableColumnHeader
						title="Comments"
						altTitle={serieId ? scoreData.totalCommentsByContents.toString() : undefined}
						column={column}
					/>
				),
				cell: info => <span className="mr-auto truncate pl-3 font-medium">{info.row.original.comments}</span>,
				size: Math.floor(tableWidth * 0.13),
			}),
			columnHelper.accessor("donations", {
				id: "Donations",
				header: ({column}) => (
					<DataTableColumnHeader
						title="Donations"
						altTitle={serieId ? scoreData.totalDonationsByContents.toString() : undefined}
						column={column}
					/>
				),
				cell: info => <span className="mr-auto truncate pl-3 font-medium">{info.row.original.donations}</span>,
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.accessor("stickers", {
				id: "Stickers",
				header: ({column}) => (
					<DataTableColumnHeader
						title="Stickers"
						altTitle={serieId ? scoreData.totalStickersByContents.toString() : undefined}
						column={column}
					/>
				),
				cell: info => <span className="mr-auto truncate pl-3 font-medium">{info.row.original.stickers}</span>,
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<Button size={"sm"} disabled variant={"outline"} asChild>
						<Link
							to={
								"/corporate/content-detail/" +
								info.row.original.series_name +
								"/" +
								info.row.original.series_id +
								"/" +
								info.row.original.name +
								"/" +
								info.row.original.id
							}
						>
							<BarsPlus className="h-4 w-4" />
						</Link>
					</Button>
				),
				size: CONTROL_COL_WIDTH,
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableRef, serieId, scoreData]);

	const handleDateRangeChange = (s: number, e: number) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	const seriesOptions: SelectOptionType[] = useMemo(() => {
		const options = series.map(serie => ({label: serie.title, value: serie.id}));
		options.unshift({label: "Todos", value: ""});
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [series.length]);

	const seriesContentOptions: SelectOptionType[] = useMemo(() => {
		// The filter function remove 'Stream' content filter option.
		const options = CONTENT_TYPES.filter(c => c.value !== 4).map(content => ({label: content.label, value: content.value.toString()}));
		options.unshift({label: "Todos", value: ""});
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleSelectSerie(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (!newValue) return;
		if (newValue.value !== "") {
			setSerieId(newValue.value);
			return;
		}
		setSerieId(undefined);
	}

	function handleSelectContent(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (!newValue) return;
		if (newValue.value !== "") {
			setContentType(parseInt(newValue.value));
			return;
		}
		setContentType(undefined);
	}

	return (
		<div className="flex h-screen flex-col">
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Ranking de Contenidos</h2>
				<Button size={"sm"} variant={"outline"} asChild>
					<Link to={"/corporate/ranked-series"}>Ranking de Series</Link>
				</Button>
			</div>
			<div className="flex w-full flex-row items-center justify-between px-8 pt-3">
				<DatePickerWithRange
					onDateRangeChange={handleDateRangeChange}
					date={dates}
					disabled={{before: new Date('2023-10-01T00:00:00-05:00'), after: new Date()}}
				/>
				<div className="flex gap-6">
					<Select
						className="basic-select w-60 text-sm"
						styles={selectStyles}
						isSearchable={true}
						options={seriesContentOptions}
						classNamePrefix="select"
						onChange={handleSelectContent}
						placeholder="Tipo de Contenido"
					/>
					<Select
						className="basic-select w-60 text-sm"
						styles={selectStyles}
						isSearchable={true}
						options={seriesOptions}
						classNamePrefix="select"
						onChange={handleSelectSerie}
						placeholder="Serie"
						isLoading={seriesLoading}
						isDisabled={seriesLoading}
					/>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={data || []}
					pageCount={Math.ceil(totalResults / pageSize)}
					pagination={page}
					loading={isLoading}
					onPaginationChange={setPage}
					withDynamicPageSize
					rowHeight={70}
					showPagination={false}
				/>
			</div>
		</div>
	);
}
