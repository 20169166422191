import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../../../../../components/primitives/Select";
import {CONTENT_TYPES} from "../../../../../constants";

interface ContentTypeSelectProps {
	selectedContentType: string;
	onSelectedContentTypeChange: (v: string) => void;
	contentToExclude?: number[];
}

export default function ContentTypeSelect({selectedContentType, onSelectedContentTypeChange, contentToExclude}: ContentTypeSelectProps) {
	const contentToRender = contentToExclude ? CONTENT_TYPES.filter(exclude => !contentToExclude.includes(exclude.value)) : CONTENT_TYPES;

	return (
		<Select value={selectedContentType} onValueChange={onSelectedContentTypeChange}>
			<SelectTrigger className="w-[180px]">
				<SelectValue placeholder="Selecciona un tipo de Contenido" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Tipos de Contenido</SelectLabel>
					{contentToRender.map(contentType => (
						<SelectItem value={contentType.value.toString()}>{contentType.label}</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}
