import * as AvatarPrimitive from "@radix-ui/react-avatar";
import {forwardRef} from "react";
import {cn} from "../../../../utils/classNames";

const Avatar = forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>>(
	({className, ...props}, ref) => (
		<AvatarPrimitive.Root
			ref={ref}
			className={cn("relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full border border-border", className)}
			{...props}
		/>
	),
);

const AvatarImage = forwardRef<React.ElementRef<typeof AvatarPrimitive.Image>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>>(
	({className, ...props}, ref) => <AvatarPrimitive.Image ref={ref} className={cn("aspect-square h-full w-full", className)} {...props} />,
);

const AvatarFallback = forwardRef<React.ElementRef<typeof AvatarPrimitive.Fallback>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>>(
	({className, ...props}, ref) => (
		<AvatarPrimitive.Fallback
			ref={ref}
			className={cn("flex h-full w-full items-center justify-center rounded-full bg-muted uppercase", className)}
			{...props}
		/>
	),
);

export {Avatar, AvatarImage, AvatarFallback};
