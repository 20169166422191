import axios, {AxiosResponse} from "axios";
import {GetSubscriptionSlotsVariationsResponse, getSubscriptionSlotsVariationsParams, GetGeneralIncomeAndProfitResponseV2} from "./types";
import {GetGeneralIncomeAndProfitParams} from "../dashboard/types";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export async function getSubscriptionSlotsVariation(
	token: string,
	params?: getSubscriptionSlotsVariationsParams,
): Promise<AxiosResponse<GetSubscriptionSlotsVariationsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_subscriptions_slots_variations", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getGeneralIncomeAndProfit2(
	token: string,
	params?: GetGeneralIncomeAndProfitParams,
): Promise<AxiosResponse<GetGeneralIncomeAndProfitResponseV2>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_corporate_profit2", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
