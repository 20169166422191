import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface Sticker {
	id: string;
	description: string;
	coin: number;
	seriesid: string;
	serie_title: string;
	url: string;
	active: boolean;
	sticker_type: number;
	render_type: number;
	extra_url: string | null;
}

/*INSERT*/
export const InsertStickerBodySchema = z.object(
	{
		description: z.string().nonempty("Este campo no puede estar vacío."),
		coin: z.number({invalid_type_error: "Este campo solo acepta valores numéricos."}).min(0),
		url: z.string({required_error: "Selecciona una imagen para este sticker."}).url().nonempty("Debes seleccionar una imagen para este sticker."),
		seriesid: z.string().optional(),
		active: z.boolean(),
		sticker_type: z.number().optional(),
		render_type: z.number(),
		extra_url: z.string().nullable(),
	},
	{required_error: "Este campo es requerido."},
);

export type InsertStickerBodyType = z.infer<typeof InsertStickerBodySchema>;

interface InsertStickerResponse {
	id: string;
	description: string;
	coin: number;
	seriesid: string;
	serie_title: string;
	url: string;
	active: boolean;
	sticker_type: number;
	render_type: number;
	extra_url: string | null;
}

export async function insertStickerData(token: string, data: InsertStickerBodyType): Promise<AxiosResponse<InsertStickerResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/insert_sticker_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*UPDATE*/
export const EditStickerBodySchema = z.object(
	{
		id: z.string().nonempty(),
		description: z.string().nonempty("Este campo no puede estar vacío."),
		coin: z.number({invalid_type_error: "Este campo solo acepta valores numéricos."}).min(0),
		url: z.string({required_error: "Selecciona una imagen para este sticker."}).url().nonempty("Debes seleccionar una imagen para este sticker."),
		seriesid: z.string().nullable(),
		active: z.boolean(),
		sticker_type: z.number().optional(),
		render_type: z.number(),
		extra_url: z.string().nullable(),
	},
	{required_error: "Este campo es requerido."},
);
export type EditStickerBodyType = z.infer<typeof InsertStickerBodySchema>;

interface EditStickerResponse {
	id: string;
	description: string;
	coin: number;
	seriesid: string;
	serie_title: string;
	url: string;
	active: boolean;
	sticker_type: number;
	render_type: number;
	extra_url: string | null;
}

export async function editStickerData(token: string, data: EditStickerBodyType): Promise<AxiosResponse<EditStickerResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_sticker_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*LIST*/
export interface GetStickersListParams {
	page: number;
	page_size: number;
	searchby?: string;
	searchvalue?: string;
	active?: boolean;
	sticker_type?: number;
}
interface GetStickersListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Sticker[];
}
export async function getStickersList(token: string, params?: GetStickersListParams): Promise<AxiosResponse<GetStickersListResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/admin_list_sticker_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetStickerParams {
	id: string;
}

export async function getObjectSticker(token: string, params?: GetStickerParams): Promise<AxiosResponse<Sticker>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/admin_get_sticker_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params: params,
	});
}

export async function uploadStickersImage(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_sticker_image", data, {
		headers: {
			Authorization: idToken,
		},
	});
}
