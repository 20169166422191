import axios, {AxiosResponse} from "axios";
import {
	EditCostConceptData,
	EditPlatformCostDataParams,
	EditPlatformCostDataResponse,
	GetActiveUsersParams,
	GetActiveUsersResponse,
	GetAppInstallationsByLocationParams,
	GetAppInstallationsByLocationResponse,
	GetAppInstallationsDataParams,
	GetAppInstallationsDataResponse,
	GetAssignedFreeSubscriptionsParams,
	GetAssignedFreeSubscriptionsResponse,
	GetContentLikesAndFavoritesParams,
	GetCostAndEarningsDataParams,
	GetCostAndEarningsDataResponse,
	GetDailyVerificationsDataParams,
	GetDailyVerificationsDataResponse,
	GetGeneralIncomeAndProfitParams,
	GetGeneralIncomeAndProfitResponse,
	GetInfluencerAndPartnerTransactionsParams,
	GetInfluencerAndPartnerTransactionsResponse,
	GetInfluencerProfitParams,
	GetInfluencerProfitResponse,
	GetPlatformCostDataResponse,
	GetProfitCorporateParams,
	GetProfitCorporateResponse,
	GetSerieContentEarnings,
	GetSerieIncomeAndProfitByRentalParams,
	GetSerieIncomeAndProfitByRentalResponse,
	GetSerieIncomeAndProfitParams,
	GetSerieIncomeAndProfitResponse,
	GetSerieLikesAndFavoritesParams,
	GetSeriesAndContentInteractionResponse,
	GetSeriesStatsParams,
	GetSeriesStatsResponse,
	GetSponsorAdViewParams,
	GetSponsorAdViewResponse,
	GetSponsorWorldViewsParams,
	GetSponsorWorldViewsResponse,
	GetSubscribersLocationDataResponse,
	GetUsersDemographicDataParams,
	GetUsersDemographicDataResponse,
	GetUsersVerificationData,
	GetUsersVerificationDataParams,
	InfluencersBySeriesProfitParams,
	InfluencersListProfitResponse,
	InsertPlatformCostData,
	ListCostConceptDataParams,
	ListCostConceptResponse,
	ListPlatformCostDataParams,
	SeriesContentEarningsResponse,
} from "./types";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export async function getGeneralIncomeAndProfit(
	token: string,
	params?: GetGeneralIncomeAndProfitParams,
): Promise<AxiosResponse<GetGeneralIncomeAndProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_corporate_profit", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSerieIncomeAndProfit(
	token: string,
	params?: GetSerieIncomeAndProfitParams,
): Promise<AxiosResponse<GetSerieIncomeAndProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_partner_profit", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSerieIncomeAndProfitByRent(
	token: string,
	params?: GetSerieIncomeAndProfitByRentalParams,
): Promise<AxiosResponse<GetSerieIncomeAndProfitByRentalResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_rentals_details", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSeriesLikesAndFavorites(
	token: string,
	params?: GetSerieLikesAndFavoritesParams,
): Promise<AxiosResponse<GetSeriesAndContentInteractionResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_likes_favorite", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getContentsLikesAndFavorites(
	token: string,
	params?: GetContentLikesAndFavoritesParams,
): Promise<AxiosResponse<GetSeriesAndContentInteractionResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_content_likes_favorite", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSponsorAdViewsData(token: string, params?: GetSponsorAdViewParams): Promise<AxiosResponse<GetSponsorAdViewResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_sponsor_ad_views", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSponsorAdViewsByAdsData(token: string, params?: GetSponsorAdViewParams): Promise<AxiosResponse<GetSponsorAdViewResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_sponsor_ad_views_by_ads", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function gGetInfluencerProfitData(
	token: string,
	params?: GetInfluencerProfitParams,
): Promise<AxiosResponse<GetInfluencerProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_influencer_profit", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSeriesStatsData(token: string, params?: GetSeriesStatsParams): Promise<AxiosResponse<GetSeriesStatsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_stats_series_list", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getLatestTransactionsDetails(
	token: string,
	params?: GetInfluencerAndPartnerTransactionsParams,
): Promise<AxiosResponse<GetInfluencerAndPartnerTransactionsResponse[]>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_membership_list_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getProfitCorporateListData(
	token: string,
	params?: GetProfitCorporateParams,
): Promise<AxiosResponse<GetProfitCorporateResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_profit_corporate_list_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSeriesContentEarnings(
	token: string,
	params?: GetSerieContentEarnings,
): Promise<AxiosResponse<{result: SeriesContentEarningsResponse[]}>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_series_content_coins", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getInfluencerProfit(
	token: string,
	params: InfluencersBySeriesProfitParams | undefined,
): Promise<AxiosResponse<InfluencersListProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_influencer_profit", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getUsersVerificationData(
	token: string,
	params: GetUsersVerificationDataParams | undefined,
): Promise<AxiosResponse<GetUsersVerificationData[]>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_verifications_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getUsersActiveData(token: string, params: GetActiveUsersParams | undefined): Promise<AxiosResponse<GetActiveUsersResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_daily_enabled_users", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSponsorWorldViewsData(
	token: string,
	params: GetSponsorWorldViewsParams | undefined,
): Promise<AxiosResponse<GetSponsorWorldViewsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_sponsors_views_by_location", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getDailyVerificationsData(
	token: string,
	params: GetDailyVerificationsDataParams | undefined,
): Promise<AxiosResponse<GetDailyVerificationsDataResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_daily_verifications_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getUsersDemographicData(
	token: string,
	params: GetUsersDemographicDataParams | undefined,
): Promise<AxiosResponse<GetUsersDemographicDataResponse[]>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_users_age_ranges", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getAppInstallationsData(
	token: string,
	params: GetAppInstallationsDataParams | undefined,
): Promise<AxiosResponse<GetAppInstallationsDataResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_installations_by_day", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getAppInstallationsByLocationData(
	token: string,
	params: GetAppInstallationsByLocationParams | undefined,
): Promise<AxiosResponse<GetAppInstallationsByLocationResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_installations_by_location", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSubscribersLocationData(token: string, params: undefined): Promise<AxiosResponse<GetSubscribersLocationDataResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_subscribers_locations", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetRentalsLocationsDataResponse {
	latitude: string;
	longitude: string;
	cant: number;
}

export async function getRentalsLocationsData(token: string, params: undefined): Promise<AxiosResponse<GetRentalsLocationsDataResponse[]>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_rentals_locations", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getAssignedFreeSubscriptionsData(
	token: string,
	params: GetAssignedFreeSubscriptionsParams | undefined,
): Promise<AxiosResponse<GetAssignedFreeSubscriptionsResponse[]>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_free_user_subscriptions", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function listPlatformCostData(
	token: string,
	params: ListPlatformCostDataParams | undefined,
): Promise<AxiosResponse<GetPlatformCostDataResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_platform_cost_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function editPlatformCostData(
	token: string,
	data: EditPlatformCostDataParams | undefined,
): Promise<AxiosResponse<EditPlatformCostDataResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit-platform-cost-data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function editCostConceptData(token: string, data: EditCostConceptData | undefined): Promise<AxiosResponse<EditCostConceptData>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_cost_concept_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function listCostConceptData(
	token: string,
	params: ListCostConceptDataParams | undefined,
): Promise<AxiosResponse<ListCostConceptResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_cost_concept_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function insertCostConceptData(token: string, description: string): Promise<AxiosResponse<EditCostConceptData>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post(
		"/insert_cost_concept_data",
		{description},
		{
			headers: {
				Accept: "*/*",
				Authorization: idToken,
			},
		},
	);
}

export async function insertPlatformCostData(token: string, data: InsertPlatformCostData): Promise<AxiosResponse<ListCostConceptResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert-platform-cost-data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function getCostAndEarningsData(
	token: string,
	params: GetCostAndEarningsDataParams,
): Promise<AxiosResponse<GetCostAndEarningsDataResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_cost_and_earnings", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
