import {Listbox, Transition} from "@headlessui/react";
import classNames from "classnames";
import {Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Check, Chevron, PlusSmall} from "../../../../components/primitives/icons";
import {CheckCircle} from "../../../../components/primitives/icons/CheckCircle";
import {Spinner} from "../../../../components/primitives/icons/Spinner";
import ToolTip from "../../../../components/primitives/ToolTip-toDeprecate";
import {getUserMemberships, SerieWithMembership, setSeriesDataInteraction} from "../../../../data-access/series/user-memberships";
import {auth} from "../../../../firebase";
import {seriesActions} from "../../../../_store/features/series/series-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {dbUser} from "../../../../_store/features/user-db/user-db-slice";
import {Checkbox} from "../../../../components/primitives/Checkbox";
import {editUserInitialState} from "./UserData";
import Select, {ActionMeta, MultiValue} from "react-select";
import {selectGlobalStyles} from "../../../../../utils/selectGlobalStyles";
import {Button} from "../../../../components/primitives/Button";
import {MEMBERSHIP_TYPES} from "../../../../constants";
import {editDbUserData} from "../../../../_store/features/user-db/user-db-actions";
import {toast} from "react-hot-toast";
import {associatesActions} from "../../../../_store/features/associates/associates-slice";
import {SelectOptionType} from "../../../../../utils/globalTypes";
import {CheckedState} from "@radix-ui/react-checkbox";
import useCurrentUserRole from "../../../../components/hooks/useCurrentUserRole";

const memberships = [
	{value: 0, name: "Gratis"},
	{value: 1, name: "Miembro"},
	{value: 2, name: "Miembro VIP"},
];

const rolesToCheck: (keyof dbUser)[] = [
	"admin",
	"editor",
	"master_editor",
	"partner",
	"sponsor",
	"influencer",
	"customer_service",
	"marketing",
	"corporate",
];

function Memberships() {
	const {id} = useParams();
	const dispatch = useAppDispatch();
	const series = useAppSelector(state => state.series);
	const user = useAppSelector(state => state.user.userInfo);
	const seriesAssociates = useAppSelector(state => state.associates.results).filter(user => user.userid === id);
	const initialUser = useAppSelector(state => state.dbuser.results.find(el => el.id === id));
	const {isCSL1} = useCurrentUserRole();

	const [listMemberships, setListMemberships] = useState<SelectOptionType[]>([]);
	const [listSeries, setListSeries] = useState<SelectOptionType[]>([]);
	const [associatedSeries, setAssociatedSeries] = useState<SelectOptionType[] | null>(null);
	const [seriesidList, setSeriesIdList] = useState<string>("");
	const [serieId, setSerieId] = useState<string>("");

	const [userMemberships, setUserMemberships] = useState<SerieWithMembership[]>([]);
	const [loading, setLoading] = useState(true);
	const [addingMembershipActionLoading, setAddingMembershipActionLoading] = useState(false);
	const [modifyMembershipActionLoading, setModifyMembershipActionLoading] = useState(false);
	const [associatesLoading, setAssociatesLoading] = useState(false);
	const [editUser, setEditUser] = useState<dbUser>(editUserInitialState);
	const [isUserAdmin, setIsUserAdmin] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!id) return;
		dispatch(
			seriesActions.getSeriesList({
				params: {page_size: 9999},
			}),
		);
		dispatch(associatesActions.getUserAssociatesSeries({page_size: 99999}));

		auth.currentUser
			?.getIdToken()
			.then(token => {
				getUserMemberships(token, {userid: id!, page_size: 9999})
					.then(res => {
						setUserMemberships(res.data.results);
						setLoading(false);
					})
					.catch(err => {
						console.log(err);
						setError(true);
					});
			})
			.catch(err => console.log(err));
	}, [dispatch, id]);

	useEffect(() => {
		if (!initialUser) return;
		setEditUser(initialUser);
		setIsUserAdmin(rolesToCheck.some(role => initialUser[role] === true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialUser]);

	useEffect(() => {
		if (!seriesAssociates) return;
		setAssociatedSeries(
			seriesAssociates.map(serie => ({
				label: serie.title || "",
				value: serie.seriesid || "",
			})),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seriesAssociates.length]);

	useMemo(() => {
		if (!userMemberships?.length) {
			setListMemberships(
				series.results.map(serie => ({
					label: serie.title || "",
					value: serie.id || "",
				})),
			);
		} else {
			const remaining = series.results.filter(serie => !userMemberships.some(el => el.seriesid === serie.id));
			setListMemberships(
				remaining.map(serie => ({
					label: serie.title || "",
					value: serie.id || "",
				})),
			);
		}
		if (series.results) {
			setListSeries(
				series.results.map(serie => ({
					label: serie.title || "",
					value: serie.id || "",
				})),
			);
		}
	}, [series.results, userMemberships]);

	const handleAddSerieMembership = (serieId: string) => {
		if (serieId === "") return;

		auth.currentUser
			?.getIdToken()
			.then(token => {
				setAddingMembershipActionLoading(true);
				setSeriesDataInteraction(token, {seriesid: serieId, userid: id!, membership: MEMBERSHIP_TYPES[0].value})
					.then(res => {
						const serie = series.results.find(el => res.data.seriesid === el.id);
						const isSerieAdded = userMemberships.some(el => el.id === res.data.seriesid);

						if (isSerieAdded) {
							return setUserMemberships(prev =>
								prev.map(el => {
									if (el.id === res.data.seriesid) {
										return {...el, ...res.data};
									}
									return el;
								}),
							);
						}

						return setUserMemberships(prev => prev.concat([{...serie, ...res.data} as SerieWithMembership]));
					})
					.catch(err => console.log(err))
					.finally(() => {
						setAddingMembershipActionLoading(false);
					});
			})
			.catch(err => console.log(err));
	};

	const handleModifySerieMembership = (data: {serieId: string; membership: number}) => {
		if (modifyMembershipActionLoading) return;

		auth.currentUser
			?.getIdToken()
			.then(token => {
				setModifyMembershipActionLoading(true);
				setSeriesDataInteraction(token, {seriesid: data.serieId, userid: id!, membership: data.membership})
					.then(res => {
						return setUserMemberships(prev => {
							return prev.map(el => {
								if (el.seriesid === res.data.seriesid) {
									return {...el, ...res.data};
								}
								return el;
							});
						});
					})
					.catch(err => console.log(err))
					.finally(() => {
						setModifyMembershipActionLoading(false);
					});
			})
			.catch(err => console.log(err));
	};

	function handleMultiSelectChange(newValue: MultiValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue.length === 0) {
			setSeriesIdList("");
			setAssociatedSeries(null);
		} else {
			setSeriesIdList(newValue.map(series => series.value).join(","));
			setAssociatedSeries([...newValue]);
		}
	}

	const handleAssociateUserSerie = () => {
		setAssociatesLoading(true);
		dispatch(associatesActions.insertUsersAssociatesSeries({userid: id!, seriesid: seriesidList, active: true})).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Series asociadas al usuario con éxito");
				dispatch(associatesActions.getUserAssociatesSeries({}));
			} else {
				toast.error("Error al asociar series al usuario");
			}
			setAssociatesLoading(false);
		});
	};

	const onAssociateUser = (value: CheckedState) => {
		if (editUser.associate && seriesidList !== "") return;
		setEditUser(prevState => {
			return {...prevState, ...{associate: !editUser.associate}};
		});
		dispatch(editDbUserData({data: {...editUser, associate: value as boolean}, admin: isUserAdmin}))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") {
					setEditUser(prevState => {
						return {...prevState, ...{associate: editUser.associate}};
					});
					return toast.error("Error al actualizar la asociación");
				}
				toast.success("Asociación actualizada correctamente!");
			})
			.catch(err => {
				console.error(err);
				setEditUser(prevState => {
					return {...prevState, ...{associate: editUser.associate}};
				});
				toast.error("Error al actualizar la asociación");
			});
	};

	if (error) return <div className="mx-auto mt-4 flex items-center justify-center">{error}</div>;

	if (loading)
		return (
			<div className="mx-auto mt-4 flex items-center justify-center">
				<Spinner />
			</div>
		);

	return (
		<>
			<div className="px-4 py-5 sm:px-6">
				<h2 className="text-base font-semibold leading-6 text-gray-900">Detalles de Membresías</h2>
				<p className="mt-1 max-w-2xl text-sm text-gray-500">Actualiza las membresías de los usuarios y agrega nuevas.</p>
			</div>

			{!isUserAdmin && user?.admin && (
				<>
					<div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
						<div className="text-base font-semibold leading-6 text-gray-900">Asociado:</div>
						<div className="ml-2 mr-auto flex items-center">
							<Checkbox
								checked={editUser.associate || false}
								onCheckedChange={onAssociateUser}
								disabled={seriesAssociates.length > 0}
							/>
							<label className="select-none pl-2 text-gray-900" htmlFor="associated">
								Es un usuario asociado a la plataforma?
							</label>
						</div>
					</div>

					<div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
						<h3 className="text-base font-semibold leading-6 text-gray-900">Asociar Series:</h3>
						<div className="mt-1 flex flex-row items-center text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
							<Select
								className="basic-multi-select mr-2 w-full rounded"
								isMulti={true}
								styles={selectGlobalStyles}
								isDisabled={!editUser.associate}
								options={listSeries}
								defaultValue={associatedSeries === null ? listSeries : associatedSeries}
								onChange={handleMultiSelectChange}
								placeholder="Vincula series a este usuario"
							/>
							{associatesLoading ? (
								<Spinner />
							) : (
								<Button variant="outline" onClick={handleAssociateUserSerie} className="h-9">
									<Check />
								</Button>
							)}
						</div>
					</div>
				</>
			)}

			<div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
				<h3 className="text-base font-semibold leading-6 text-gray-900">Añadir nueva membresía:</h3>
				<div className="mt-1 items-center text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{addingMembershipActionLoading ? (
						<Spinner />
					) : !listMemberships.length ? (
						<span className="text-sm italic text-gray-600">Todas han sido añadidas</span>
					) : (
						<div className="flex flex-row">
							<Select
								className="basic-multi-select mr-2 w-full rounded"
								options={listMemberships || []}
								styles={selectGlobalStyles}
								placeholder="Agrega una membresía"
								isDisabled={isCSL1}
								onChange={option => {
									setSerieId(option?.value || "");
								}}
							/>
							<Button
								variant="outline"
								disabled={isCSL1}
								onClick={() => {
									handleAddSerieMembership(serieId);
								}}
								className="h-9"
							>
								<PlusSmall />
							</Button>
						</div>
					)}
				</div>
			</div>

			<div className="relative border-t border-gray-200">
				<Transition
					show={modifyMembershipActionLoading}
					enter="transition-opacity duration-75"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="absolute left-1/2 top-6 rounded-full bg-white p-2 shadow-lg ring ring-gray-200">
						<Spinner />
					</div>
				</Transition>
				<dl>
					{userMemberships.map(el => (
						<Item key={el.id} data={el} onSetMembership={handleModifySerieMembership} />
					))}
				</dl>
			</div>
		</>
	);
}

interface ItemProps {
	data: SerieWithMembership;
	onSetMembership: (data: {serieId: string; membership: number}) => void;
}
const Item = ({data, onSetMembership}: ItemProps) => {
	const [selectedMembership, setSelectedMembership] = useState(memberships.find(el => el.value === data.membership) ?? memberships[0]);
	const {isCSL1} = useCurrentUserRole();

	const handleSetMembership = () => {
		const membershipData = {serieId: data.seriesid, membership: selectedMembership.value};
		onSetMembership(membershipData);
	};

	const isSetButtonDisabled = useMemo(() => selectedMembership.value === data.membership, [selectedMembership, data.membership]);

	return (
		<div className="items-center bg-gray-50 px-4 py-5 odd:bg-gray-100 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" key={data.id}>
			<img src={data.img_logo} alt={`Logo de la serie ${data.title}`} className="h-20 w-20 rounded bg-neutral-900 object-contain p-2" />
			<h3 className="text-sm font-medium text-gray-500">{data.title}</h3>
			<div className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0">
				<MemberShipsListBox selected={selectedMembership} setSelected={setSelectedMembership} />
				<span>
					<ToolTip text={"Establecer"}>
						<Button
							type="button"
							className="ml-2 inline-flex h-9 items-center rounded-lg bg-green-600 p-1.5 text-sm text-gray-100 disabled:cursor-not-allowed disabled:bg-gray-200 hover:bg-green-500 disabled:hover:bg-gray-200"
							onClick={handleSetMembership}
							disabled={isSetButtonDisabled || isCSL1}
						>
							<CheckCircle
								className={classNames("h-6 w-6 text-white", {"text-gray-900": selectedMembership.value === data.membership})}
							/>
						</Button>
					</ToolTip>
				</span>
			</div>
		</div>
	);
};

interface MemberShipsListBoxProps {
	selected: (typeof memberships)[0];
	setSelected: Dispatch<SetStateAction<(typeof memberships)[0]>>;
}
const MemberShipsListBox = ({selected, setSelected}: MemberShipsListBoxProps) => {
	const {isCSL1} = useCurrentUserRole();
	return (
		<div className="w-full">
			<Listbox value={selected} onChange={setSelected} disabled={isCSL1}>
				<div className="relative">
					<Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
						<span className="block truncate">{selected.name}</span>
						<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
							<Chevron className="h-5 w-5 text-gray-400" aria-hidden="true" />
						</span>
					</Listbox.Button>
					<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
						<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{memberships.map(membership => (
								<Listbox.Option
									key={membership.value}
									className={({active}) =>
										`relative cursor-default select-none py-2 pl-10 pr-4 ${
											active ? "bg-amber-100 text-amber-900" : "text-gray-900"
										}`
									}
									value={membership}
								>
									{({selected}) => (
										<>
											<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{membership.name}</span>
											{selected ? (
												<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
													<CheckCircle className="h-5 w-5" aria-hidden="true" />
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			</Listbox>
		</div>
	);
};

export default Memberships;
