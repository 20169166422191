import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export const SerieInfluencerSchema = z.object({
	active: z.boolean(),
	serie_id: z.string(),
	influencer_id: z.string(),
	profit_percentage: z.number(),
	audit_created: z.string().optional(),
	audit_updated: z.string().optional(),
});
export type SerieInfluencerType = z.infer<typeof SerieInfluencerSchema>;

/***************
 * LIST CONFIGS
 ***************/
export interface GetInfluencerConfigParams {
	page_size?: number;
	page?: number;
	searchby?: "influencer_id" | "serie_id";
	searchvalue?: string;
	active?: boolean;
}
export interface GetInfluencerConfigResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: SerieInfluencerType[];
}

export async function getInfluencerConfigData(token: string, params: GetInfluencerConfigParams): Promise<AxiosResponse<GetInfluencerConfigResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_influencer_series_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

/***************
 * POST CONFIG
 ***************/
export async function insertInfluencerConfigData(token: string, data: SerieInfluencerType): Promise<AxiosResponse<SerieInfluencerType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/create_influencer_series_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/***************
 * UPDATE CONFIG
 ***************/
export async function updateInfluencerConfigData(token: string, data: SerieInfluencerType): Promise<AxiosResponse<SerieInfluencerType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_influencer_series_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export const InfluencerLinkSchema = z.object({
	id: z.string().optional(),
	influencer_id: z.string(),
	link: z.string(),
	referral_code: z.string(),
	audit_created: z.string().optional(),
	audit_updated: z.string().optional(),
	count_users: z.number().optional(),
	active: z.boolean().optional(),
	audit_author_id: z.string().optional(),
	annual_subscription_commission: z.number().optional(),
	month_subscription_commission: z.number().optional(),
});

export type InfluencerLinkType = z.infer<typeof InfluencerLinkSchema>;

export async function updateInfluencerSubscriptionData(
	token: string,
	data: Pick<InfluencerLinkType, "annual_subscription_commission" | "month_subscription_commission" | "influencer_id">,
): Promise<AxiosResponse<InfluencerLinkType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_influencer_profit_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/***************
 * LIST LINK
 ***************/
export interface GetInfluencerLinkParams {
	page_size?: number;
	page?: number;
	searchby?: "id" | "link" | "influencer_id" | "referal_code";
	searchvalue?: string;
	active?: boolean;
}
export interface GetInfluencerLinkResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: InfluencerLinkType[];
}

export async function getInfluencerLinkData(token: string, params: GetInfluencerLinkParams): Promise<AxiosResponse<GetInfluencerLinkResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_influencer_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

/***************
 * POST LINK
 ***************/
export async function insertInfluencerLinkData(token: string, data: InfluencerLinkType): Promise<AxiosResponse<InfluencerLinkType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/create_influencer_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/***************
 * UPDATE LINK
 ***************/
export async function updateInfluencerLinkData(token: string, data: InfluencerLinkType): Promise<AxiosResponse<InfluencerLinkType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_influencer_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
