import {formatDistance} from "date-fns";
import {useGetRecentsFeedbacksQuery} from "../../../../_store/features/feedback/api";
import {Avatar, AvatarFallback, AvatarImage} from "../../../../components/primitives/Avatar";
import {es} from "date-fns/locale";
import {Link} from "react-router-dom";
import {Star} from "../../../../components/primitives/icons";
import {cn} from "../../../../../utils/classNames";

const StarReview = ({rating}: {rating: number}) => {
	const stars = Array.from({length: 5}, (_, i) => {
		return <Star className={cn("h-2 w-2 text-gray-300", {"text-orange-300": i < rating})} />;
	});

	return <div className="flex gap-1">{stars}</div>;
};

export function RecentsFeedback() {
	const {data} = useGetRecentsFeedbacksQuery();

	return (
		<div className="shadow-xs col-span-1 flex-col rounded-lg border border-gray-200 bg-white">
			<div className="border border-gray-200 p-2 font-bold">Reseñas recientes</div>
			<div className=" divide-y-2 divide-gray-100 p-2">
				{data?.data.map((feedback, index) => (
					<div key={index} className="flex gap-2 p-2">
						<Avatar className="h-8 w-8">
							<AvatarImage src={feedback?.photo_url + "?" + Date.now()} alt="User Profile" />
							<AvatarFallback className="bg-navigation text-xs text-navigation-foreground">
								{feedback.email?.slice(0, 2)}
							</AvatarFallback>
						</Avatar>
						<div className="flex flex-col gap-1">
							<StarReview rating={feedback.stars} />
							<div className="flex items-end gap-2">
								<div className="text-[10px] font-medium">{feedback.name}</div>
								<div className="text-[10px] text-gray-500">
									{formatDistance(new Date(feedback.created_at), new Date(), {addSuffix: true, locale: es})}
								</div>
							</div>
							<div className="font-bond text-xs">{feedback.reason}</div>
						</div>
					</div>
				))}
				<Link to="/reviews/list" className="p-2 text-xs font-bold text-indigo-500 text-primary">
					Ver todas las valoraciones y reseñas &rarr;
				</Link>
			</div>
		</div>
	);
}
