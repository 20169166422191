type NotificationType = {
	value: string;
	label: string;
	display: "push" | "in_app" | "email";
};
export const PRESET_NOTIFICATIONS_TYPES: NotificationType[] = [
	{
		value: "episode_release",
		label: "Estreno de capítulo",
		display: "push",
	},
	{
		value: "global_notification",
		label: "Notificación global",
		display: "push",
	},
	{
		value: "live_started_notification",
		label: "Inicio de live",
		display: "push",
	},
	{
		value: "live_finished_notification",
		label: "Terminación de live",
		display: "push",
	},
	{
		value: "live_chapter_notification",
		label: "Notificación de nuevo live",
		display: "push",
	},
	{
		value: "view_update",
		label: "Actualización de views",
		display: "push",
	},
	{
		value: "daily_coin_summary",
		label: "Resumen de coins diarios",
		display: "push",
	},
	{
		value: "coins_received",
		label: "Coins recibidos",
		display: "push",
	},
	{
		value: "membership_status",
		label: "Estado de membresía",
		display: "push",
	},
	{
		value: "free_membership_status",
		label: "Estado de membresía free",
		display: "push",
	},
	{
		value: "payment_status",
		label: "Estado de pago",
		display: "push",
	},
	{
		value: "comment_reply",
		label: "Reacción a comentario",
		display: "push",
	},
	{
		value: "comment_response_notification",
		label: "Respuesta a comentario de contenido (custom)",
		display: "push",
	},
	{
		value: "admin_update",
		label: "Actualización desde la administración",
		display: "push",
	},
	{
		value: "free_coins_notification",
		label: "Coins free (custom)",
		display: "in_app",
	},
	{
		value: "new_chapter_notification",
		label: "Nuevo capítulo (custom)",
		display: "push",
	},
	{
		value: "new_series_notification",
		label: "Nueva serie (custom)",
		display: "push",
	},
	{
		value: "new_trailer_notification",
		label: "Nuevo trailer (custom)",
		display: "push",
	},
	{
		value: "inactive_user_7_notification",
		label: "Usuario inactivo 7 días (custom)",
		display: "push",
	},
	{
		value: "inactive_user_15_notification",
		label: "Usuario inactivo 15 días (custom)",
		display: "push",
	},
	{
		value: "inactive_user_30_notification",
		label: "Usuario inactivo 30 días (custom)",
		display: "push",
	},
	{
		value: "like_action_notification",
		label: "Notificación de like (custom)",
		display: "push",
	},
	{
		value: "new_short_notification",
		label: "Nuevo short (custom)",
		display: "push",
	},
	{
		value: "chat_reply",
		label: "Reacción a chat",
		display: "push",
	},
	{
		value: "subscription_status",
		label: "Estado de suscripción",
		display: "push",
	},
	{
		value: "welcome_email_notification",
		label: "Bienvenida por email (custom)",
		display: "email",
	},
];

export const NOTIFICATIONS_TYPES_DISPLAY: {label: string; value: "push" | "in_app" | "email"}[] = [
	{label: "Push", value: "push"},
	{label: "In-app", value: "in_app"},
	{label: "Email", value: "email"},
];
