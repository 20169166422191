import {useAppDispatch, useAppSelector} from "../../../../../../_store/hooks";
import {Modal} from "../../../../../../components/primitives/Modal";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {Controller, SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {cn} from "../../../../../../../utils/classNames";
import {Checkbox} from "../../../../../../components/primitives/Checkbox";
import {Pencil, Trash} from "../../../../../../components/primitives/icons";
import {Post, PostType, Question} from "../../../../../../data-access/exclusive-room/types";
import {Button} from "../../../../../../components/primitives/Button";
import {exclusiveRoomActions} from "../../../../../../_store/features/exclusive-room/exclusive-room-slice";
import {toast} from "react-hot-toast";
import {useParams} from "react-router-dom";
import MuxVideoCombobox from "../../../subcomponents/MuxVideoCombobox";
import ImageSelector from "./image-selector";
import {InsertContentType} from "../../../../../../data-access/series/content";
import {useState} from "react";
import {galleryActions} from "../../../../../../_store/features/gallery/gallery-slice";
import {FOLDERS} from "../../../../../../data-access/multimedia/gallery";
import MultimediaImagePickerModal from "../../../../../../components/blocks/MultimediaImagePickerModal";
import {auth} from "../../../../../../firebase";
import {uploadExclusiveRoomImage} from "../../../../../../data-access/comments/comments";

interface EditPostModalProps {
	onDismiss: () => void;
	open: boolean;
}
const FOLDERS_NAMES: {name: string; value: (typeof FOLDERS)[number]}[] = [{name: "Fotos de Sala Exclusiva", value: "exclusive_image"}];

interface Payload {
	title: string;
	text: string;
	video_url?: string;
	image_url?: string;
	questions?: Question[];
}

const QuestionSchema: z.ZodType<Question> = z.object({
	id: z.coerce.number(),
	text: z.string(),
	votes: z.number(),
});

type ImageFieldsTypes = keyof Pick<InsertContentType, "lowres_image" | "square_image" | "rectangular_image" | "preview_image">;

const EditPostSchema = z.object({
	title: z.string().nonempty("El título es requerido"),
	description: z.string().nonempty("La descripción es requerida"),
	questions: z.optional(z.array(QuestionSchema)),
	published: z.boolean(),
	image_url: z.string().optional(),
	video_url: z.string().optional(),
});

type EditPostSchemaType = z.infer<typeof EditPostSchema>;

const EditPostModal = ({onDismiss, open}: EditPostModalProps) => {
	const post = useAppSelector(state => state.exclusiveRoom.activePost);
	const postType = post?.content.payload.subtype;
	const [loading, setLoading] = useState(false);

	const dispatch = useAppDispatch();
	const {
		register,
		control,
		formState: {errors},
		handleSubmit,
		setValue,
		watch,
	} = useForm<EditPostSchemaType>({
		resolver: zodResolver(EditPostSchema),
		values: {
			title: post?.content.payload.content.title || "",
			description: post?.content.payload.content.text || "",
			image_url: post?.content.payload.content.image_url,
			video_url: post?.content.payload.content.video_url,
			published: post?.published ?? true,
			questions: post?.content.payload.content.questions?.length ? post.content.payload.content.questions : undefined,
		},
	});
	const {fields, append, remove} = useFieldArray({control, name: "questions"});
	const {id: seriesid} = useParams();
	const image = watch("image_url");
	const [openDialog, setOpenDialog] = useState(false);
	const [newQuestion, setNewQuestion] = useState("");

	const onSubmit: SubmitHandler<EditPostSchemaType> = data => {
		if (!post) return;

		let content: Payload = {
			text: data.description,
			title: data.title,
		};

		if (postType === PostType.IMAGE) {
			content = {
				...content,
				image_url: data.image_url,
			};
		} else if (postType === PostType.VIDEO) {
			content = {
				...content,
				video_url: data.video_url,
			};
		} else if (postType === PostType.POLL && data.questions) {
			content = {
				...content,
				questions: data.questions,
			};
		}

		let request: Post = {
			...post,
			published: data.published,
			content: {
				...post.content,
				payload: {
					...post.content.payload,
					content,
				},
			},
		};

		dispatch(exclusiveRoomActions.updatePost(request)).then(thunk => {
			if (thunk.meta.requestStatus === "rejected") {
				toast.error("Error al editar la publicación");
				return;
			}
			toast.success("Publicación editada con éxito");
			dispatch(exclusiveRoomActions.getExclusiveRoomListBySerie({id: seriesid!}));
			onDismiss();
		});
	};

	const handleSelectImage = () => {
		setOpenDialog(true);
	};

	const handleUploadImage = (file: File, _type: ImageFieldsTypes) => {
		setLoading(true);
		const body = new FormData();

		body.append("file", file);
		body.append("exclusive_room_imageid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadExclusiveRoomImage(token, body)
					.then(res => {
						setValue("image_url", res.data.result);
					})
					.catch(err => {
						console.log({err});
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(err => {
				setLoading(false);
				console.log(err);
			});
	};

	return (
		<Modal open={open} onDismiss={onDismiss} big>
			<form className="grid scroll-py-2 grid-cols-2 flex-col gap-2 px-1 pb-0.5 pt-4" onSubmit={handleSubmit(onSubmit)}>
				<div className="flex h-full flex-col space-y-2 pb-4">
					<div className="flex flex-col">
						<label className="mb-1 text-sm text-white" htmlFor="title">
							Título
						</label>
						<input
							id="title"
							className={cn(
								"form-control flex-1 rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
								{"border-red-500": !!errors.title},
							)}
							type="text"
							placeholder="Título de la publicación"
							{...register("title")}
						/>
					</div>
					<div className="flex flex-1 flex-col">
						<label className="mb-1 text-sm text-white" htmlFor="description">
							Descripción
						</label>
						<textarea
							id="description"
							className={cn(
								"form-control min-h-[100px] w-[450px] flex-1 scroll-smooth rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
								{"border-red-500": !!errors.description},
							)}
							placeholder="Título de la publicación"
							{...register("description")}
						/>
					</div>
					<div className="flex max-h-10 flex-row items-center space-x-3 space-y-0 rounded-md text-black">
						<Controller
							control={control}
							name="published"
							render={({field: {onChange, onBlur, value, ref}}) => (
								<Checkbox
									defaultChecked={value}
									checked={value}
									onCheckedChange={onChange}
									className="bg-white text-black data-[state=checked]:bg-white data-[state=checked]:text-black"
								/>
							)}
						/>

						<div className="space-y-1 leading-none">
							<div className="text-sm text-white">Mostrar publicación</div>
						</div>
					</div>
				</div>

				<div className="flex flex-col space-y-2">
					{postType === PostType.POLL && (
						<>
							<h3 className="border-b pb-2 text-sm font-semibold text-white "> Respuestas </h3>
							{fields.map((field, index) => (
								<div key={field.id} className="flex flex-row items-center gap-2">
									<input
										type="text"
										{...register(`questions.${index}.text` as const)}
										defaultValue={field.text}
										className={cn(
											"form-control flex-1 rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
											{"border-red-500": !!errors.title},
										)}
									/>
									<button onClick={() => remove(index)}>
										<Trash className="h-4 w-4 text-white" />
									</button>
								</div>
							))}

							<div className="flex items-center gap-2">
								<input
									type="text"
									value={newQuestion}
									onChange={e => setNewQuestion(e.target.value)}
									className={cn(
										"form-control flex-1  rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
									)}
								/>
								<button
									type="button"
									onClick={() => {
										if (!newQuestion.length) return;
										append({id: fields.length + 1, text: newQuestion, votes: 0});
										setNewQuestion("");
									}}
									className="flex items-center justify-center space-x-2 rounded-sm border-2 bg-white px-4 py-1 text-center text-sm tracking-wider text-black transition-all duration-300 hover:bg-white hover:text-black"
								>
									Agregar respuesta
								</button>
							</div>
						</>
					)}
					{postType === PostType.VIDEO && (
						<>
							<h3 className="border-b pb-2 text-sm font-semibold text-white "> Video </h3>
							<MuxVideoCombobox
								className={cn(
									"form-control w-full flex-1 rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
									{"border-red-500": errors.video_url},
								)}
								id="video_content_id"
								name="video_id"
								defaultValue={post?.content.payload.content.video_url ?? ""}
								onChangeSelected={video => setValue("video_url", video.playbackid)}
								video_type={5}
								placeholder="Título del video"
							/>
							<div className="flex aspect-square h-full w-full items-center justify-center bg-neutral-500 text-neutral-400">
								Reproductor no disponible
							</div>
						</>
					)}
					{postType === PostType.IMAGE && (
						<>
							<h3 className="border-b pb-2 text-sm font-semibold text-white "> Imagen </h3>
							<ImageSelector src={image || ""} loading={loading} onOpenGallery={handleSelectImage} onUploadImage={handleUploadImage} />
						</>
					)}
				</div>

				<Button
					variant="outline"
					type="submit"
					className="col-span-2 flex w-full items-center justify-center space-x-2 rounded-sm border-2 bg-white px-4 py-3 text-center tracking-wider text-black transition-all duration-300 hover:bg-white hover:text-black"
				>
					<span className="text-center">Editar publicación</span>
					<Pencil className="h-6 w-6" />
				</Button>
			</form>

			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={FOLDERS.indexOf("exclusive_image")}
				folderNames={FOLDERS_NAMES}
				onSuccess={url => {
					setValue("image_url", url);
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				key={`exclusive_image-image-picker-modal-${openDialog}`}
			/>
		</Modal>
	);
};

export default EditPostModal;
