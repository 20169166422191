import {IconProps} from "./type";

const Tag = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" {...props}>
			<g clipPath="url(#a)">
				<path fill="none" d="M0 0h24v24H0z" />
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="m9.169 21.9 10.267-10.267a1 1 0 0 0 .287-.818l-.566-5.091a1 1 0 0 0-.884-.883l-5.09-.566a1 1 0 0 0-.818.287L2.098 14.828a1 1 0 0 0 0 1.415L7.755 21.9a1 1 0 0 0 1.414 0Z"
				/>
				<circle cx={14.12} cy={9.881} r={2} stroke="currentColor" strokeLinejoin="round" transform="rotate(45 14.12 9.88)" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default Tag;
