import * as React from "react";
import {format, getUnixTime, startOfDay, endOfDay, subMonths, roundToNearestMinutes, endOfToday, subDays} from "date-fns";
import {DateRange, Matcher} from "react-day-picker";
import {Button} from "../../primitives/Button";
import {Popover, PopoverContent, PopoverTrigger} from "../../primitives/Popover/popover";
import {cn} from "../../../../utils/classNames";
import {Calendar as CalendarIcon, Chevron} from "../../primitives/icons";
import {Calendar} from "../../primitives/Calendar/calendar";
import {zonedTimeToUtc} from "date-fns-tz";

interface AdvancedDatePickerWithRangeProps extends React.HTMLAttributes<HTMLDivElement> {
	onDateRangeChange?: (startDate: number, endDate: number, date: DateRange | undefined) => void;
	disabled?: Matcher | Matcher[];
	date?: DateRange | undefined;
	disableDate: boolean;
}

type Option = {
	label: string;
	value: number;
	isdisabled: boolean;
};

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const INITIAL_DATE = undefined;

const DatesInterval = (days: number) => {
	const to = roundToNearestMinutes(endOfToday());
	const from = startOfDay(subDays(to, days));
	return {
		from: zonedTimeToUtc(from, timeZone),
		to: zonedTimeToUtc(to, timeZone),
	};
};

const options: Option[] = [
	{label: "Hoy", value: 1, isdisabled: false},
	{label: "Últimos 3 Días", value: 3, isdisabled: false},
	{label: "Últimos 5 Días", value: 5, isdisabled: false},
	{label: "Última Semana", value: 7, isdisabled: false},
	{label: "Último Mes", value: 30, isdisabled: false},
	{label: "Últimos 90 Días", value: 90, isdisabled: false},
	{label: "Últimos 6 Meses", value: 180, isdisabled: false},
	{label: "Último Año", value: 360, isdisabled: false},
	{label: "Personalizado", value: 0, isdisabled: false},
];

export function AdvancedDatePickerWithRange({className, disabled, onDateRangeChange, date}: AdvancedDatePickerWithRangeProps) {
	const [open, setOpen] = React.useState(false);
	const [localDate, setLocalDate] = React.useState<DateRange | undefined>(date);
	const [selectedOption, setSelectedOption] = React.useState<Option | undefined>(date && options[options.length - 1]);

	React.useEffect(() => {
		if (selectedOption && selectedOption.value !== 0) {
			setLocalDate(DatesInterval(selectedOption?.value));
		}
	}, [selectedOption]);

	const handleConfirm = () => {
		if (localDate && localDate?.from) {
			const from = zonedTimeToUtc(startOfDay(localDate.from), timeZone);
			let to = localDate?.to ?? from;
			to = zonedTimeToUtc(endOfDay(to), timeZone);

			if (typeof onDateRangeChange === "function") {
				onDateRangeChange(getUnixTime(from), Number(getUnixTime(to)), localDate);
				setOpen(false);
			}
		}
	};

	const resetLocalDate = React.useCallback(() => {
		setLocalDate(date);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date?.to, date?.from]);

	const clearLocalDate = () => {
		setLocalDate(INITIAL_DATE);
		setSelectedOption(options[0]);
	};

	const setDate = (date: DateRange | undefined) => {
		setSelectedOption(options[options.length - 1]);
		setLocalDate(date);
	};

	return (
		<div className={cn(className)}>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild disabled={!!disabled}>
					<Button
						id="date"
						variant={"outline"}
						className={cn("m-w-[300px] mx-auto flex w-full justify-start text-center font-normal", !date && "text-muted-foreground")}
					>
						<div className="flex flex-1 items-center text-center text-xs">
							<CalendarIcon className="mr-2 h-4 w-4" />

							{selectedOption && selectedOption.value !== 0 ? (
								selectedOption?.label
							) : date?.from ? (
								date.to ? (
									<>
										{format(date.from, "LLL dd, y")} - {format(date.to, "LLL dd, y")}
									</>
								) : (
									format(date.from, "LLL dd, y")
								)
							) : (
								<span>Seleccione un rango de fecha</span>
							)}
						</div>
						<div className="flex border-l border-gray-200 pl-2">
							<Chevron color="gray" className="size-4 rotate-180" />
						</div>
					</Button>
				</PopoverTrigger>
				<PopoverContent className="flex w-auto p-2" align="end" onInteractOutside={resetLocalDate}>
					<div className="flex min-w-48 flex-col space-y-2 px-2 py-4">
						{options.map(option => (
							<button
								disabled={option.isdisabled}
								onClick={() => setSelectedOption(option)}
								className={cn(
									"group flex space-x-2 rounded-sm px-2 py-2 text-xs transition-colors hover:bg-gray-100",
									option.value === selectedOption?.value && "bg-gray-200 hover:bg-gray-200",
									option.isdisabled && "text-gray-500 hover:bg-transparent",
								)}
								key={option.label}
							>
								<CalendarIcon className="mr-2 h-4 w-4" />
								{option.label}
							</button>
						))}
					</div>
					<div>
						<Calendar
							showOutsideDays={false}
							mode="range"
							defaultMonth={subMonths(new Date(), 1)}
							selected={localDate}
							today={new Date()}
							onSelect={setDate}
							onDayClick={() => setSelectedOption(options[options.length - 1])}
							onWeekNumberClick={() => setSelectedOption(options[options.length - 1])}
							onNextClick={() => setSelectedOption(options[options.length - 1])}
							onPrevClick={() => setSelectedOption(options[options.length - 1])}
							numberOfMonths={2}
							disabled={disabled}
						/>
						<div className="flex items-center justify-center space-x-4">
							<Button onClick={clearLocalDate} className="mt-2 flex flex-col justify-center" variant="outline" disabled={!localDate}>
								Reiniciar selección
								{localDate?.from ? (
									<span className="text-[10px]">
										{localDate.to ? (
											<>
												{format(localDate.from, "LLL dd, y")} - {format(localDate.to, "LLL dd, y")}
											</>
										) : (
											format(localDate.from, "LLL dd, y")
										)}
									</span>
								) : null}
							</Button>
							<Button onClick={handleConfirm} className="mt-2">
								Confirmar
							</Button>
						</div>
					</div>
				</PopoverContent>
			</Popover>
		</div>
	);
}
