import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {useCallback, useEffect, useMemo, useState} from "react";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {DataTable} from "../../../components/blocks/DataTable";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {
	CustomNotification,
	listCustomNotifications,
	NotificationEvent,
	NotificationSettings,
	NotificationSettingsListResult,
	UpdateCustomNotificationBody,
} from "../../../data-access/notifications/notifications";
import {notificationsActions} from "../../../_store/features/notify/notify-slice";
import {Button} from "../../../components/primitives/Button";
import {AdjustmentsHorizontal, CancelCircleFilled, CheckCircleFilled, Clipboard, ListPlus, Pencil, Trash} from "../../../components/primitives/icons";
import CustomNotificationModal from "../_subcomponents/CustomNotificationModal";
import toast from "react-hot-toast";
import {format} from "date-fns";
import ActionConfirmModal from "../../../components/blocks/ActionConfirmModal";
import NotificationTypeModal from "../_subcomponents/NotificationTypeModal";
import {PRESET_NOTIFICATIONS_TYPES} from "../../../constants/notifications";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../components/primitives/ToolTip";
import {auth} from "../../../firebase";
import {addDisplayEmojiToCustomNotification, addOrRemoveStringSuffix, removeEmojisOfString} from "../../../../utils/validators";

type MergedNotificationSettingsType = CustomNotification & Pick<NotificationSettings, "enabled">;
const columnHelper = createColumnHelper<MergedNotificationSettingsType>();

export default function CustomMarketingNotifications() {
	const dispatch = useAppDispatch();
	const {loading, results, totalResults} = useAppSelector(state => state.notifications);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [showNewModal, setShowNewModal] = useState(false);
	const [notificationSettings, setNotificationSettings] = useState<NotificationSettings[]>([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showNewNotificationTypeModal, setShowNewNotificationTypeModal] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [selectedNotification, setSelectedNotification] = useState<string | undefined>(undefined);
	const [editableNotification, setEditableNotification] = useState<CustomNotification | undefined>();
	const [events, setEvents] = useState<NotificationEvent[]>([]);

	const fetchNotifications = () =>
		dispatch(
			notificationsActions.getAllCustomNotifications({
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
			}),
		);

	const fetchAllCustomNotifications = useCallback(async () => {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return [];
		const allNotifications = await listCustomNotifications(token, {page_size: 9999});
		if (allNotifications.status !== 200) return [];
		return allNotifications.data.results || [];
	}, []);

	const fetchNotificationsSettings = () => {
		dispatch(notificationsActions.getAllNotificationsSettings())
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") return;
				const notificationsList = (res.payload as NotificationSettingsListResult).results;
				const parsedNotifications: (NotificationSettings & {active?: boolean})[] = notificationsList.map(notification => {
					const presetSetting = PRESET_NOTIFICATIONS_TYPES.find(({value}) => value === notification.notification_type);
					return {
						...notification,
						label: presetSetting?.label ?? notification.label,
						display: presetSetting?.display ?? notification.display,
					};
				});
				const notificationsWithEmoji = addDisplayEmojiToCustomNotification(parsedNotifications);
				fetchAllCustomNotifications().then(res => {
					const formattedSettings = notificationsWithEmoji.map(setting => {
						const isCreated = res.some(notification => notification.type === setting.notification_type);
						if (isCreated) return {...setting, active: true};
						return {...setting, active: false};
					});

					setNotificationSettings(formattedSettings);
				});
			})
			.catch(err => console.log(err));
	};

	const fetchNotificationsEvents = () => {
		dispatch(notificationsActions.listNotificationsEvents())
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") return;
				const data = res.payload as NotificationEvent[];
				setEvents(data);
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		if (!notificationSettings.length) fetchNotificationsSettings();
		if (!events.length) fetchNotificationsEvents();
		fetchNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination.pageSize, pagination.pageIndex]);

	const onCreateCustomNotification = (data: Pick<UpdateCustomNotificationBody, "type" | "navigation" | "text" | "subject">) => {
		setIsLoading(true);
		dispatch(notificationsActions.newCustomNotification({...data}))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") {
					toast.error("Ya existe una notificación del mismo tipo");
					return;
				}
				toast.success("Notificación creada correntamente.");
			})
			.catch(err => {
				console.log(err);
				toast.error("Ocurrió un error al crear la notificación");
			})
			.finally(() => {
				fetchNotifications();
				setShowNewModal(false);
				setIsLoading(false);
			});
	};

	const onDeleteCustomNotification = () => {
		if (!selectedNotification) return;
		dispatch(notificationsActions.removeCustomNotification(selectedNotification))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") return;
				toast.success("Notificación eliminada exitosamente");
			})
			.catch(err => {
				console.log(err);
				toast.error("Error al eliminar la notificación");
			})
			.finally(() => {
				setSelectedNotification(undefined);
				setShowDeleteModal(false);
				fetchNotifications();
			});
	};

	const onEditCustomNotification = (data: Pick<UpdateCustomNotificationBody, "id" | "navigation" | "text" | "subject">) => {
		setIsLoading(true);
		dispatch(notificationsActions.editCustomNotification({subject: data.subject, text: data.text, id: data.id, navigation: data.navigation}))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") {
					toast.error("Ya existe una notificación del mismo tipo");
					return;
				}
				toast.success("Notificación actualizada correctamente");
			})
			.catch(err => {
				console.log(err);
				toast.error("Error al actualizar la notificación");
			})
			.finally(() => {
				fetchNotifications();
				setShowNewModal(false);
				setEditableNotification(undefined);
				setIsLoading(false);
			});
	};

	const onSelectForEdit = (notification: CustomNotification, duplicateAction?: boolean) => {
		setEditableNotification({
			...notification,
			type: !duplicateAction ? notification.type : "",
		});

		setShowNewModal(true);
	};

	const onCreateNotificationType = (data: Pick<NotificationSettings, "label" | "enabled" | "audience" | "display" | "events">) => {
		setIsLoading(true);
		dispatch(
			notificationsActions.newCustomNotificationType({...data, label: addOrRemoveStringSuffix(removeEmojisOfString(data.label), "(custom)")}),
		)
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") {
					toast.error("Error al crear el tipo de notificación");
					return;
				}
				toast.success("Tipo de notificación creado correctamente");
			})
			.catch(err => {
				console.log(err);
				toast.error("Error al crear el tipo de notificación");
			})
			.finally(() => {
				setIsLoading(false);
				fetchNotificationsSettings();
			});
	};

	const onDeleteNotificationType = (id: string) => {
		setIsLoading(true);
		dispatch(notificationsActions.removeNotificationSetting(id))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") {
					toast.error("Error al eliminar la configuración");
					return;
				}
				toast.success("Configuración eliminada correctamente");
			})
			.catch(err => {
				console.log(err);
				toast.error("Error al eliminar la configuración");
			})
			.finally(() => {
				fetchNotificationsSettings();
				setIsLoading(false);
			});
	};

	const onEditNotificationType = (data: NotificationSettings) => {
		setIsLoading(true);
		dispatch(notificationsActions.editNotificationSetting({...data, label: removeEmojisOfString(data.label)}))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") {
					toast.error("Error al editar la configuración");
					return;
				}
				toast.success("Configuración editada correctamente");
			})
			.catch(err => {
				console.log(err);
				toast.error("Error al editar la configuración");
			})
			.finally(() => {
				fetchNotificationsSettings();
				setIsLoading(false);
			});
	};

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("enabled", {
				id: "Notificación habilitada",
				header: ({column}) => <DataTableColumnHeader title="Habilitada" column={column} />,
				cell: ({row: {original}}) => {
					const isEnabledSetting = notificationSettings.find(setting => setting.notification_type === original.type)?.enabled;
					return (
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(!isEnabledSetting ? " text-red-700" : " text-green-700")
							}
						>
							{!isEnabledSetting ? <CancelCircleFilled /> : <CheckCircleFilled />}
						</span>
					);
				},
				size: Math.floor(tableWidth * 0.1),
				enableResizing: false,
			}),
			columnHelper.accessor("type", {
				id: "Tipo",
				header: ({column}) => <DataTableColumnHeader title="Tipo" column={column} />,
				cell: ({getValue}) => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">
							{notificationSettings.find(setting => setting.notification_type === getValue())?.label ?? getValue()}
						</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.15),
				enableResizing: true,
			}),
			columnHelper.accessor("subject", {
				id: "Asunto",
				header: ({column}) => <DataTableColumnHeader title="Asunto" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.subject}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("text", {
				id: "Mensajes",
				header: ({column}) => <DataTableColumnHeader title="Mensajes" column={column} />,
				cell: info => (
					<div className="mr-auto flex flex-col  overflow-hidden">
						{info.getValue().length > 0
							? info
									.getValue()
									.slice(0, 3)
									.map((text, index) => (
										<span key={index} className="ml-2 truncate text-left">
											{text}
										</span>
									))
							: null}
					</div>
				),
				size: Math.floor(tableWidth * 0.25),
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha de creacón",
				header: ({column}) => <DataTableColumnHeader title="Fecha de creacón" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">
							{format(new Date(info.row.original.created_at), "d-MMMM-y")}
						</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.15),
			}),
			columnHelper.accessor("id", {
				id: "Controles",
				header: ({column}) => <DataTableColumnHeader title="Controles" column={column} />,
				cell: info => (
					<TooltipProvider>
						<div className="relative flex gap-2">
							<Tooltip>
								<TooltipTrigger>
									<Button variant="blueBtn" size={"sm"} onClick={() => onSelectForEdit(info.row.original, true)}>
										<Clipboard className="h-4 w-4" />
									</Button>
									<TooltipContent>Duplicar contenido de notificación personalizada</TooltipContent>
								</TooltipTrigger>
							</Tooltip>
							<Tooltip>
								<TooltipTrigger>
									<Button size={"sm"} onClick={() => onSelectForEdit(info.row.original)}>
										<Pencil className="h-4 w-4" />
									</Button>
								</TooltipTrigger>
								<TooltipContent>Editar</TooltipContent>
							</Tooltip>
							<Tooltip>
								<TooltipTrigger>
									<Button
										size={"sm"}
										variant="destructive"
										onClick={() => {
											setSelectedNotification(info.getValue());
											setShowDeleteModal(true);
										}}
									>
										<Trash className="h-4 w-4" />
									</Button>
								</TooltipTrigger>
								<TooltipContent>Eliminar notificación personalizada</TooltipContent>
							</Tooltip>
						</div>
					</TooltipProvider>
				),
				size: Math.floor(tableWidth * 0.15),
				enableResizing: false,
			}),
		];
	}, [tableRef, notificationSettings]);

	return (
		<div className="flex h-screen flex-col">
			<div className="flex flex-col">
				<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
					<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Notificaciones Personalizadas</h2>
				</div>
				<div className="my-4 mr-4 flex gap-4 self-end">
					<Button className="gap-2" onClick={() => setShowNewNotificationTypeModal(true)}>
						<AdjustmentsHorizontal />
						Tipos de notificaciones
					</Button>
					<Button className="gap-2" onClick={() => setShowNewModal(true)}>
						<ListPlus />
						Agregar nueva notificación
					</Button>
				</div>
			</div>

			<div className="flex h-full grow-0 flex-col px-4 pb-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={(results as MergedNotificationSettingsType[]) ?? []}
					pageCount={Math.ceil(totalResults / pagination.pageSize)}
					pagination={pagination}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={67}
					showPagination={false}
					loading={loading}
				/>
			</div>
			<ActionConfirmModal
				title="Eliminar la notificación"
				description="¿Estás seguro que deseas eliminar esta notificación?"
				open={showDeleteModal}
				onCancel={() => {
					setShowDeleteModal(false);
					setSelectedNotification(undefined);
				}}
				onAccept={onDeleteCustomNotification}
			/>
			<CustomNotificationModal
				notificationsSettingsList={notificationSettings}
				openDialog={showNewModal}
				isLoading={isLoading}
				onDismiss={() => {
					setEditableNotification(undefined);
					setShowNewModal(false);
				}}
				onSubmit={editableNotification && editableNotification.type !== "" ? onEditCustomNotification : onCreateCustomNotification}
				editableData={editableNotification}
			/>
			<NotificationTypeModal
				openDialog={showNewNotificationTypeModal}
				onDismiss={() => setShowNewNotificationTypeModal(false)}
				onSubmit={onCreateNotificationType}
				isLoading={isLoading}
				notificationsSettingsList={notificationSettings}
				onDelete={onDeleteNotificationType}
				onEdit={onEditNotificationType}
				eventsList={events}
			/>
		</div>
	);
}
