import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {ReportResponse} from "../../../../data-access/report/types";
import PerformanceChart from "./performance-chart";
import GeneralStats from "./general-stats/general-stats";
import StreamingChart from "./streaming-chart";
import CostTable from "./cost-table";
import GenerateReportButton from "./generate-report-button";

interface ReportProps {
	data: ReportResponse | undefined | null;
	isCorporate?: boolean;
	partner?: string;
}

const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const Report = (props: ReportProps) => {
	const {data} = props;
	if (!data) return <NoReportAvailable {...props} />;

	const {report, creation_date} = data;

	const date = new Date(creation_date);

	return (
		<section className="flex flex-col p-6 py-4">
			<div className="flex items-center justify-between">
				<h1 className="text-sm font-light tracking-tight">Reporte {months[date.getMonth()]}</h1>
				<GenerateReportButton />
			</div>

			<GeneralStats report={report} />

			<StreamingChart source={data} />

			<PerformanceChart source={data} />

			<CostTable source={data} />
		</section>
	);
};

interface NoReportAvailableProps {
	isCorporate?: boolean;
	partner?: string;
}

const NoReportAvailable = (props: NoReportAvailableProps) => {
	const text = props.isCorporate
		? `No se ha generado un reporte aún`
		: `Por favor contacta con un administrador o espera a ser añadido el reporte solicitado.`;

	return (
		<div className="mx-auto my-10 flex h-full max-w-lg flex-col items-center justify-center text-center">
			<p className="mb-4 rounded-full p-3 text-sm font-medium text-secondary">
				<InformationCircle className="h-10 w-10 text-black" />
			</p>
			<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-4xl">Reporte no disponible aún.</h1>
			<p className="mb-4 leading-7 [&:not(:first-child)]:mt-6">{text}</p>
			<GenerateReportButton {...props} />
		</div>
	);
};

export default Report;
