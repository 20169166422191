import {Modal} from "../../../../../../components/primitives/Modal";
import {useAppSelector} from "../../../../../../_store/hooks";
import {Question} from "../../../../../../data-access/exclusive-room/types";

interface PreviewPostModalProps {
	onDismiss: () => void;
	open: boolean;
}

const PreviewPostModal = ({onDismiss, open}: PreviewPostModalProps) => {
	const post = useAppSelector(state => state.exclusiveRoom.activePost);

	const reactions = groupBy(post?.content.payload.reactions ?? [], "reacted")
		.sort((a: any, b: any) => b.occurrences - a.occurrences)
		.map((item: any) => ({[item.reacted]: item.occurrences}));

	return (
		<Modal open={open} onDismiss={onDismiss} big>
			<div className="grid w-[800px] grid-cols-2 p-4">
				<div>
					<div>
						<h2 className="text-lg font-semibold text-white">{post?.content.payload.content.title || "Sin titulo"}</h2>
						{post?.created_at && <p className="text-sm text-neutral-500">{new Date(post?.created_at).toLocaleDateString()}</p>}
						<p className="text-sm text-white">{post?.content.payload.content.text}</p>
						<div className="mt-2 flex flex-row gap-0.5 text-white">
							{reactions.map((reaction: any, index) => (
								<Reaction key={index} reaction={reaction} />
							))}
						</div>
					</div>
				</div>
				<div className="col-span-1">
					{post?.content.payload.content.image_url && (
						<img src={post?.content.payload.content.image_url} alt="Post" className="aspect-square h-full w-full" />
					)}
					{post?.content.payload.content.video_url && (
						<div className="flex aspect-square h-full w-full items-center justify-center bg-neutral-500 text-neutral-400">
							Reproductor no disponible
						</div>
					)}
					{post?.content.payload.content.questions?.length && <QuestionsList questions={post?.content.payload.content.questions} />}
				</div>
			</div>
		</Modal>
	);
};

const groupBy = function (xs: any[], key: any) {
	return Object.values(
		xs.reduce(function (rv, x) {
			const keyValue = x[key];
			rv[keyValue] = {[key]: keyValue, occurrences: (rv[keyValue]?.occurrences || 0) + 1};
			return rv;
		}, {}),
	);
};

const Reaction = ({reaction}: {reaction: any}) => {
	const data = Object.entries(reaction);
	const emoji = data[0][0];
	const occurrences = data[0][1] as number;

	return (
		<div className="inline rounded bg-neutral-700 px-1 py-0.5 text-xs font-semibold">
			{emoji} {occurrences}
		</div>
	);
};

const QuestionsList = ({questions}: {questions: Question[]}) => {
	return (
		<div className="">
			<h3 className="text-lg font-semibold text-white">Respuestas</h3>
			{questions.map((question, index) => (
				<div key={index} className="mt-2 flex flex-row justify-between">
					<p className="text-sm text-white">{question.text}</p>
					<p className="text-sm text-neutral-500">{question.votes}</p>
				</div>
			))}
		</div>
	);
};

export default PreviewPostModal;
