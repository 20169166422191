import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, ChartOptions, LinearScale} from "chart.js";
import "chartjs-adapter-date-fns";
import {differenceInDays, format, parse} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useAppDispatch} from "../../../../_store/hooks";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {ArrowRightLong, Spinner} from "../../../primitives/icons";
import {DateRange} from "react-day-picker";
import {getChartData, getGroupedByDay, getMonthlyProfitData, oneMonth} from "../../../../../utils/barGraphTransformData";
import {es} from "date-fns/locale";
import {Link} from "react-router-dom";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

export const options: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					if (tooltipItems[0].label.includes("T")) {
						return format(new Date(tooltipItems[0].label), "EE, dd MMMM");
					}
				},
				label: function (context) {
					let label = context.dataset.label || "";

					if (label) {
						label += ": ";
					}
					if (context.parsed.y !== null) {
						label += new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(context.parsed.y);
					}
					return label;
				},
			},
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d").toLowerCase();
				},
			},
			grid: {color: "transparent"},
			stacked: true,
		},
		y: {
			ticks: {
				callback(tickValue) {
					return new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(Number(tickValue) ?? 0);
				},
			},
			stacked: true,
		},
	},
};

interface SerieIncomeAndProfitProps {
	serieId: string | undefined;
	dates: DateRange | undefined;
}

export function PeriodIncome({serieId, dates}: SerieIncomeAndProfitProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; profit: number}[]>>(null);
	const {data: statistics, isLoading} = useDashboardByNameQuery("generalIncome");
	const [showByMonths, setShowByMonths] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const durationInMilliseconds = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(durationInMilliseconds > oneMonth);
	}, [dates, dispatch, serieId]);

	const totalMembershipsProfit = useMemo(
		() => statistics?.daily_profit_memberships?.reduce((acc, curr) => acc + curr.profit * 0.01, 0) ?? 0,
		[statistics],
	);
	const totalRentalsProfit = useMemo(() => statistics?.daily_profit_rentals?.reduce((acc, curr) => acc + curr.profit * 0.01, 0) ?? 0, [statistics]);
	const totalProfit = useMemo(() => totalMembershipsProfit + totalRentalsProfit, [totalMembershipsProfit, totalRentalsProfit]);

	const data: ChartData<"bar", {date: string; profit: number}[]> = useMemo(() => {
		const startDate = new Date(dates?.from ?? 0);
		const endDate = new Date(dates?.to ?? Date.now());
		const diff_days = differenceInDays(endDate, startDate) + 1;

		//Transforms the data returned from big query
		const membershipsData =
			statistics?.daily_profit_memberships?.map(({date, profit, fee}) => ({
				date: new Date(date).toISOString(),
				profit: profit * 0.01,
			})) ?? [];
		const rentalsData =
			statistics?.daily_profit_rentals?.map(({date, profit, fee}) => ({
				date: new Date(date).toISOString(),
				profit: profit * 0.01,
			})) ?? [];

		// Group elements by day
		const membershipsDataGrouped = getGroupedByDay(membershipsData);
		const rentalsDataGrouped = getGroupedByDay(rentalsData);

		//Combined data from statistics and filled.
		const membershipsChartData = getChartData(membershipsDataGrouped, diff_days, endDate);
		const rentalsChartData = getChartData(rentalsDataGrouped, diff_days, endDate);

		const membershipsMonthlyProfitData = getMonthlyProfitData(membershipsDataGrouped);
		const rentalsMonthlyProfitData = getMonthlyProfitData(rentalsDataGrouped);

		const currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1;
			const monthString = month < 10 ? `0${month}` : `${month}`;
			const key = `${year}-${monthString}`;
			!membershipsMonthlyProfitData[key] && (membershipsMonthlyProfitData[key] = {date: `${year}-${monthString}`, profit: 0});
			!rentalsMonthlyProfitData[key] && (rentalsMonthlyProfitData[key] = {date: `${year}-${monthString}`, profit: 0});
			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		const membershipsMonthlyDataArray = Object.values(membershipsMonthlyProfitData);
		const rentalsMonthlyDataArray = Object.values(rentalsMonthlyProfitData);
		membershipsMonthlyDataArray.sort((a, b) => parse(a.date, "yyyy-MM", new Date()).getTime() - parse(b.date, "yyyy-MM", new Date()).getTime());
		rentalsMonthlyDataArray.sort((a, b) => parse(a.date, "yyyy-MM", new Date()).getTime() - parse(b.date, "yyyy-MM", new Date()).getTime());
		const membershipsSelectedData = showByMonths ? membershipsMonthlyDataArray : membershipsChartData;
		const rentalsSelectedData = showByMonths ? rentalsMonthlyDataArray : rentalsChartData;

		return {
			datasets: [
				{
					label: "Membresías",
					data: membershipsSelectedData,
					backgroundColor: "#005096",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "profit",
					},
				},
				{
					label: "Rentas",
					data: rentalsSelectedData,
					backgroundColor: "#f5ab00",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "profit",
					},
				},
			],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, statistics, showByMonths]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-5 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Ingreso del período.</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Bar
							height={350}
							ref={chartRef}
							options={{
								...options,
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));
												// Format the date based on the available information
												return showByMonths
													? format(new Date(date.getFullYear(), date.getMonth() + 1), "yyyy - MMM", {
															locale: es,
													  }).toLowerCase()
													: format(date, "MMM d", {locale: es}).toLowerCase();
											},
										},
										grid: {color: "transparent"},
										stacked: true,
									},
									y: {stacked: true},
								},
							}}
							data={data}
						/>
					</div>
				)}
			</div>
			<div className="col-span-2 flex h-fit flex-col items-start justify-start gap-6 rounded-lg border p-4">
				<h3 className="text-center text-2xl font-semibold">Distribución de ingresos</h3>
				<div className="flex w-full flex-col justify-start gap-3">
					<div className="flex justify-start gap-10 border-b">
						<p className="w-3/6 text-lg">Membresías</p>
						<p className="text-start text-lg">$ {totalMembershipsProfit.toFixed(2)}</p>
					</div>
					<div className="flex justify-start gap-10 border-b">
						<p className="w-3/6 text-lg">Renta</p>
						<p className="text-start text-lg">$ {totalRentalsProfit.toFixed(2)}</p>
					</div>
				</div>
				<div className="mt-3 flex w-full justify-start gap-10">
					<p className="w-3/6 text-2xl font-semibold">Total:</p>
					<p className="text-lg">$ {totalProfit.toFixed(2)}</p>
				</div>
				<Link to={"/corporate/purchases"} className="ml-auto flex items-center justify-end gap-2">
					<p className="text-md truncate text-blue-500 hover:underline">Ver todos los ingresos</p>
					<ArrowRightLong className="fill-blue-500" width={26} height={26} />
				</Link>
			</div>
		</div>
	);
}
