import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {Separator} from "../../../components/primitives/Separator";
import {uploadShortsImage} from "../../../data-access/shorts/shorts";
import {zodResolver} from "@hookform/resolvers/zod";
import {SubmitHandler, useForm, Controller} from "react-hook-form";
import {toast} from "react-hot-toast";
import {auth} from "../../../firebase";
import {Input} from "../../../components/primitives/Input";
import {Checkbox} from "../../../components/primitives/Checkbox";
import MuxVideoCombobox from "../../series/Edit/subcomponents/MuxVideoCombobox";
import {Textarea} from "../../../components/primitives/Textarea";
import {Spinner} from "../../../components/primitives/icons";
import {Button} from "../../../components/primitives/Button";
import {Image as ImageIcon} from "../../../components/primitives/icons";
import MultimediaImagePickerModal from "../../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../../_store/features/gallery/gallery-slice";
import Select from "react-select";
import {selectGlobalStyles} from "../../../../utils/selectGlobalStyles";
import {FOLDERS_NAMES_CONTENT} from "../../../../utils/folderNames";
import {InsertContentSchema, InsertContentType} from "../../../data-access/series/content";
import {MEMBERSHIP_TYPES} from "../../../constants";
import {serieContentsActions} from "../../../_store/features/serie-content/serie-content-slice";
import {DayPicker} from "../../../components/blocks/DatePicker";

type ImageFieldsTypes = keyof Pick<InsertContentType, "lowres_image" | "square_image" | "rectangular_image">;

function LiveEdit() {
	let {id} = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const liveData = useAppSelector(state => state.serieContent.results.find(el => el.id === id));
	const [openDialog, setOpenDialog] = useState(false);
	const [serieId, setSerieId] = useState();
	const [type, setType] = useState<ImageFieldsTypes>("lowres_image");
	const [selectedMembership, setSelectedMembership] = useState(MEMBERSHIP_TYPES[0]);

	const initialState: InsertContentType = {
		seriesid: liveData?.serieid || "",
		title: liveData?.title || "",
		order: liveData?.order || 0,
		estreno: liveData?.estreno || false,
		new: liveData?.new || false,
		published: liveData?.published || false,
		slugs: liveData?.slugs || "",
		membership_type: liveData?.membership_type || 0,
		content_type: liveData?.content_type || 0,
		short_description: liveData?.short_description || "",
		long_description: liveData?.long_description || "",
		video_id: liveData?.video_id || "",
		duration: liveData?.duration ? liveData?.duration.toString() : "0",
		lowres_image: liveData?.lowres_image || "",
		square_image: liveData?.square_image || "",
		rectangular_image: liveData?.rectangular_image || "",
		release_date: liveData?.release_date || null,
		whitelist_release_date: liveData?.whitelist_release_date || null,
		premiere_type: 0,
		display_release_date: false,
		subscription_required: false,
		preview_image: liveData?.preview_image || "",
	};

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		control,
		reset,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<InsertContentType>({
		resolver: zodResolver(InsertContentSchema),
		defaultValues: initialState,
	});
	const [fieldLoading, setFieldLoading] = useState<string[]>([]);

	useEffect(() => {
		if (!liveData) {
			return navigate(-1);
		}
		setSelectedMembership(MEMBERSHIP_TYPES.find(el => el.value === liveData.membership_type)!);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, liveData]);

	useEffect(() => {
		if (location.state !== null) {
			setSerieId(location.state.param);
		}
	}, [location.state]);

	const onSubmit: SubmitHandler<InsertContentType> = data => {
		if (!data) return;
		const params = {
			...data,
			id: liveData?.id,
			estreno: data.new,
		};

		return dispatch(serieContentsActions.editSerieContent(params)).then(async res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Live actualizado correctamente");
				reset();

				if (serieId) {
					setTimeout(() => {
						navigate("/series/edit/" + serieId, {state: {param: "contents"}});
					}, 3000);
				} else {
					setTimeout(() => {
						navigate(-1);
					}, 3000);
				}
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);

		body.append("file", file);
		body.append("type", type);
		body.append("serieid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadShortsImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	return (
		<>
			<div>
				<div className="space-y-0.5 px-6 pt-4">
					<h2 className="text-2xl font-bold tracking-tight">Editar Live</h2>
					<p className="text-muted-foreground">Edita los datos de un Live de la plataforma.</p>
				</div>
				<div className="mx-6">
					<Separator className="mb-4 mt-2" />
				</div>

				<div className="px-4">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="mt-6">
							<dl className="divide-y divide-border">
								<div className="px-4 py-6 pt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Título<span className="text-xs font-bold text-destructive">*</span>
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="text" placeholder="Insertar texto del anuncio..." {...register("title")} />
										{errors?.title?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.title?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Slug<span className="text-xs font-bold text-destructive">*</span>
									</dt>
									<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="text" placeholder="Insertar slug del live..." {...register("slugs")} />
										{errors?.slugs?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.slugs.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Orden</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="number" placeholder="Orden de los lives..." {...register("order", {valueAsNumber: true})} />
										{errors?.order?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.order?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Público</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="items-top flex space-x-2">
											<Controller
												name="published"
												control={control}
												render={({field}) => (
													<Checkbox id="terms1" checked={field.value} onCheckedChange={c => field.onChange(c)} />
												)}
											></Controller>
											<div className="grid gap-1.5 leading-none">
												<label
													htmlFor="terms1"
													className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
												>
													Marcar el Live como público.
												</label>
												<p className="text-sm text-muted-foreground">
													Al marcar el Live como público, se comenzara a mostrar en los espacios determinados de las
													plataformas.
												</p>
											</div>
										</div>
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Estreno</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="items-top flex space-x-2">
											<Controller
												name="new"
												control={control}
												render={({field}) => (
													<Checkbox id="terms1" checked={field.value} onCheckedChange={c => field.onChange(c)} />
												)}
											></Controller>
											<div className="grid gap-1.5 leading-none">
												<label
													htmlFor="terms1"
													className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
												>
													Marcar el Live como estreno.
												</label>
											</div>
										</div>
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Video ID</dt>
									<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<MuxVideoCombobox
											id="video_content_id"
											name="video_id"
											defaultValue={getValues("video_id") ?? ""}
											onChangeSelected={selected => {
												setValue("video_id", selected.playbackid);
											}}
											video_type={3}
										/>
										{errors?.video_id?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.video_id.message}</span>
										)}
									</dd>
								</div>

								<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Fecha de release</dt>
									<dd className="mt-1 w-full justify-start self-start">
										<Controller
											name="release_date"
											control={control}
											render={({field}) => (
												<DayPicker
													selectedDay={liveData?.release_date ? new Date(liveData?.release_date) : undefined}
													{...field}
													onDayChange={date => {
														const dateFormatted = date?.toISOString();
														field.onChange(dateFormatted);
													}}
													className="min-w-[150px] justify-start self-start"
												/>
											)}
										/>
										{errors?.release_date?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.release_date?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Descripción corta</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="text" placeholder="Insertar descripción corta del Live..." {...register("short_description")} />
										{errors?.short_description?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.short_description?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Descripción larga</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Textarea rows={5} placeholder="Descripción del Live.." {...register("long_description")} />
										{errors?.long_description?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.long_description?.message}</span>
										)}
									</dd>
								</div>

								<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Membresía</dt>
									<Controller
										name="membership_type"
										control={control}
										render={({field}) => (
											<Select
												className="mt-1 w-full rounded ring-1"
												styles={selectGlobalStyles}
												{...field}
												options={MEMBERSHIP_TYPES}
												value={selectedMembership}
												onChange={selectedOption => {
													setSelectedMembership(selectedOption!);
													field.onChange(selectedOption?.value);
												}}
											/>
										)}
									/>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Imagen del Live</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="grid w-full max-w-sm items-center gap-1.5">
											<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
												{!getValues("rectangular_image") ? (
													fieldLoading.includes("rectangular_image") ? (
														<Spinner />
													) : (
														<ImageIcon className="h-6 w-6 text-slate-400" />
													)
												) : (
													<img src={getValues("rectangular_image")} className="w-full object-contain" alt="Thumbnail" />
												)}
											</div>
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "rectangular_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="gap-2 whitespace-nowrap"
												onClick={() => {
													handleSelectImage("rectangular_image");
												}}
											>
												<ImageIcon className="h-4 w-4" />
												Seleccionar de multimedia
											</Button>
										</div>
										{errors?.rectangular_image?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.rectangular_image.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Thumbnail</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="grid w-full max-w-sm items-center gap-1.5">
											<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
												{!getValues("lowres_image") ? (
													fieldLoading.includes("lowres_image") ? (
														<Spinner />
													) : (
														<ImageIcon className="h-6 w-6 text-slate-400" />
													)
												) : (
													<img src={getValues("lowres_image")} className="w-full object-contain" alt="Thumbnail" />
												)}
											</div>
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "lowres_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="gap-2 whitespace-nowrap"
												onClick={() => {
													handleSelectImage("lowres_image");
												}}
											>
												<ImageIcon className="h-4 w-4" />
												Seleccionar de multimedia
											</Button>
										</div>
										{errors?.lowres_image?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.lowres_image.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Cuadrada</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="grid w-full max-w-sm items-center gap-1.5">
											<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
												{!getValues("square_image") ? (
													fieldLoading.includes("square_image") ? (
														<Spinner />
													) : (
														<ImageIcon className="h-6 w-6 text-slate-400" />
													)
												) : (
													<img src={getValues("square_image")} className="w-full object-contain" alt="Thumbnail" />
												)}
											</div>
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "square_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="gap-2 whitespace-nowrap"
												onClick={() => {
													handleSelectImage("square_image");
												}}
											>
												<ImageIcon className="h-4 w-4" />
												Seleccionar de multimedia
											</Button>
										</div>
										{errors?.square_image?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.square_image.message}</span>
										)}
									</dd>
								</div>
							</dl>
						</div>

						<div className="flex justify-end gap-2 pb-4">
							<Button
								type="button"
								variant="outline"
								onClick={() => {
									reset();
									if (serieId) {
										navigate("/series/edit/" + serieId, {state: {param: "contents"}});
										setSerieId(undefined);
									} else {
										navigate(-1);
										setSerieId(undefined);
									}
								}}
							>
								Cancelar
							</Button>

							<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
								{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
								Guardar cambios
							</Button>
						</div>
					</form>
					<MultimediaImagePickerModal
						open={openDialog}
						onDismiss={() => {
							setOpenDialog(false);
							navigate(-1);
							dispatch(galleryActions.resetToInitial());
						}}
						tabIndex={1}
						folderNames={FOLDERS_NAMES_CONTENT}
						onSuccess={url => {
							setValue(type, url);
							setOpenDialog(false);
							navigate(-1);
							dispatch(galleryActions.resetToInitial());
						}}
					/>
				</div>
			</div>
		</>
	);
}

export default LiveEdit;
