import {Contact} from "../../../../primitives/icons";
import NavLink from "../NavLink";

export default function ContactLink() {
	return (
		<div className="py-2">
			<NavLink to="/customer-service/contact" label="Contacto" icon={<Contact />} />
		</div>
	);
}
