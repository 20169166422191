import React from "react";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import App from "./app/App";
import store from "./app/_store/store";
import Toaster from "./app/components/blocks/Toaster";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY!}
			language="es"
			useRecaptchaNet={false}
			useEnterprise={true}
			scriptProps={{
				async: false,
				defer: false,
				appendTo: "body",
				nonce: "head",
			}}
			container={{
				parameters: {
					badge: "bottomright",
					theme: "dark",
				},
			}}
		>
			<Provider store={store}>
				<App />
			</Provider>
			<Toaster />
		</GoogleReCaptchaProvider>
	</React.StrictMode>,
);
