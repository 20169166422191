import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import Select from "react-select";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {serieProductsActions} from "../../_store/features/products/serie-products-slice";
import {seriesActions} from "../../_store/features/series/series-slice";
import {CreateUpdateMembershipDialog} from "./subcomponents/CreateUpdateMembershipDialog";
import {Input} from "../../components/primitives/Input";
import {Textarea} from "../../components/primitives/Textarea";
import {selectStyles} from "./SelectStyles";
import {Button} from "../../components/primitives/Button";
import {toast} from "react-hot-toast";
import {Checkbox} from "../../components/primitives/Checkbox";

export interface SerieIFormInputs {
	id: string;
	name: string;
	description: string;
	serieid: string;
	membership_type: number;
	stripe_price: number;
	stripe_productid: string | null;
	stripe_priceid: string | null;
	google_price: number;
	google_id: string | null;
	apple_price: number;
	apple_id: string | null;
	active: boolean;
	author: string | null;
	stripe_price_initial: number;
	google_price_initial: number;
	apple_price_initial: number;
	serie_title: string | null;
	serie?: {label: string; value: string};
	membership?: {label: string; value: number};
	status?: {label: string; value: boolean};
	coins_price: number;
	coins_price_initial: number;
}

const initialState: SerieIFormInputs = {
	id: "",
	name: "",
	description: "",
	serieid: "",
	serie: {
		label: "",
		value: "",
	},
	membership: {
		label: "",
		value: 0,
	},
	status: {
		label: "",
		value: false,
	},
	membership_type: 1,
	stripe_price: 1,
	stripe_price_initial: 1,
	stripe_productid: null,
	google_price: 1,
	google_price_initial: 1,
	google_id: null,
	apple_price: 1,
	apple_price_initial: 1,
	apple_id: null,
	active: false,
	author: null,
	serie_title: null,
	stripe_priceid: null,
	coins_price: 1,
	coins_price_initial: 1,
};

interface SelectMembershipOption {
	label: string;
	value: number;
}

interface SelectStatusOption {
	label: string;
	value: boolean;
}

const selectMemberships: SelectMembershipOption[] = [
	{label: "Simple", value: 1},
	{label: "Vip", value: 2},
	{label: "Descuento", value: 3},
];

const selectStatus = [
	{label: "Activo", value: true},
	{label: "Inactivo", value: false},
];

type buttonVariant = "success" | "link" | "default" | "blueBtn" | "destructive" | "outline" | "secondary" | "ghost" | null | undefined;

interface ButtonVariantInterface {
	apple: buttonVariant;
	google: buttonVariant;
	stripe: buttonVariant;
}

export function SerieProductsEdit() {
	let {id} = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const serieProductData = useAppSelector(state => state.serieProducts.results.find(el => el.id === id));

	const [productData, setProductData] = useState(initialState);

	const [selectedStatus, setSelectedStatus] = useState<SelectStatusOption | null>(null);
	const [selectedMembership, setSelectedMembership] = useState<SelectMembershipOption | null>(null);

	const series = useAppSelector(state => state.series);
	const isLoading = useAppSelector(state => state.serieProducts.loading);
	const [selectedSeries, setSelectedSeries] = useState([{label: "", value: ""}]);
	const [editNameEnabled, setEditNameEnabled] = useState(false);

	const [productType, setProductType] = useState<"apple" | "google" | "stripe">("google");
	const [isDialogActive, setIsDialogActive] = useState(false);
	const [buttonVariant, setButtonVariant] = useState<ButtonVariantInterface>({
		apple: "destructive",
		google: "default",
		stripe: "default",
	});

	const {
		register,
		formState: {errors},
		handleSubmit,
		control,
		reset,
		setValue,
	} = useForm<SerieIFormInputs>({
		defaultValues: initialState,
	});

	const handleUpdateSync = () => {
		if (!serieProductData) {
			return navigate("/products/serie-list");
		}
		setProductData(existingValues => ({
			...existingValues,
			stripe_productid: serieProductData.stripe_productid ? serieProductData.stripe_productid : null,
			google_id: serieProductData.google_id ? serieProductData.google_id : null,
			apple_id: serieProductData.apple_id ? serieProductData.apple_id : null,
		}));
	};

	useEffect(() => {
		handleUpdateSync();

		if (serieProductData) {
			setProductData(serieProductData);
			reset(serieProductData);
			setButtonVariant({
				apple: "destructive",
				google: serieProductData.google_id ? "destructive" : "default",
				stripe: serieProductData.stripe_productid ? "destructive" : "default",
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serieProductData]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page_size: 150}}));
	}, [dispatch]);

	useEffect(() => {
		if (productData?.active !== null) {
			const activeStatus = productData.active ? selectStatus[0] : selectStatus[1];
			let activeMembership;
			switch (productData.membership_type) {
				case 1:
					activeMembership = selectMemberships[0];
					break;
				case 2:
					activeMembership = selectMemberships[1];
					break;
				default:
					activeMembership = selectMemberships[2];
			}

			if (series.results.length) {
				const options = series.results.map(serie => ({
					label: serie.title,
					value: serie.id,
				}));
				setSelectedSeries(options);
				if (serieProductData) setValue("serie", options.find(el => el.value === serieProductData?.serieid)!);
			}

			setSelectedStatus(activeStatus);
			setSelectedMembership(activeMembership);
		}
	}, [productData, serieProductData, series.results, setValue]);

	const onSubmit: SubmitHandler<SerieIFormInputs> = data => {
		if (
			data.apple_price !== serieProductData?.apple_price ||
			data.google_price !== serieProductData?.google_price ||
			data.stripe_price !== serieProductData?.stripe_price
		) {
			toast.error("Los precios de Stripe, Apple y Google solo se pueden modificar desde su botón particular");
			return;
		}
		data.active = selectedStatus?.value ? selectedStatus?.value : data.status?.value!;
		dispatch(serieProductsActions.editSerieProducts({...data, productid: data.id})).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				dispatch(serieProductsActions.setSuccess(false));
				toast.success("Producto actualizado correctamente!");
				navigate(-1);
			} else {
				toast.error("ERROR: " + res.payload);
			}
		});
	};

	return (
		<>
			<div className="px-5 py-4">
				<div className="flex items-center justify-between border-b border-b-border bg-background pb-5">
					<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Editar Producto de Series</h2>
				</div>

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Nombre<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mt-1 flex w-full max-w-2xl flex-col justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
							<Input
								type="text"
								placeholder="Nombre del producto"
								{...register("name", {required: "Campo requerido"})}
								disabled={!editNameEnabled}
							/>
							<div className="items-top mt-4 flex space-x-2">
								<Checkbox id="terms1" checked={editNameEnabled} onCheckedChange={() => setEditNameEnabled(!editNameEnabled)} />

								<div className="grid gap-1.5 leading-none">
									<label
										htmlFor="terms1"
										className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
									>
										Editar el nombre de la membresía.
									</label>
									<p className="text-sm text-muted-foreground">
										Al editar el nombre de la membresía debes cambiarlo también en todas las pasarelas de pago existentes: Stripe,
										Apple y Google.
									</p>
								</div>
							</div>
							{errors.name && <span className="text-red-500">{errors.name.message}</span>}
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Descripción<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mt-1 flex w-full max-w-2xl justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
							<Textarea rows={3} placeholder="Descripción del producto" {...register("description", {required: "Campo requerido"})} />
							{errors?.description?.message && (
								<span className="text-sm font-medium text-destructive">{errors?.description?.message}</span>
							)}
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">Serie</div>
						<div className="mb-3 w-full max-w-2xl">
							<Controller
								name="serie"
								control={control}
								render={({field}) => (
									<Select
										className="mt-1 w-full rounded ring-1"
										styles={selectStyles}
										{...field}
										options={selectedSeries}
										isDisabled
									/>
								)}
							/>
							{errors.serie && <span className="text-red-500">{errors.serie.message}</span>}
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">Membresía</div>
						<div className="mb-3 w-full max-w-2xl">
							<Controller
								name="membership"
								control={control}
								render={({field}) => (
									<Select
										className="mt-1 w-full rounded ring-1"
										styles={selectStyles}
										{...field}
										options={selectMemberships}
										value={selectedMembership}
										isDisabled
									/>
								)}
							/>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Estado<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mb-3 w-full max-w-2xl">
							<Controller
								name="status"
								control={control}
								render={({field}) => (
									<Select
										className="mt-1 w-full rounded ring-1"
										styles={selectStyles}
										{...field}
										options={selectStatus as any}
										value={selectedStatus}
										onChange={selectedOption => {
											setSelectedStatus(selectedOption);
											field.onChange(selectedOption);
										}}
									/>
								)}
							/>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio en Coins<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("coins_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											coins_price: Number(e.target.value),
										});
									}}
								/>
								{errors.coins_price && <span className="mr-2 w-full text-red-500">{errors.coins_price.message}</span>}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Inicial en Coins<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("coins_price_initial", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											coins_price_initial: Number(e.target.value),
										});
									}}
								/>
								{errors.coins_price_initial && <span className="mr-2 w-full text-red-500">{errors.coins_price_initial.message}</span>}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio en Stripe (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("stripe_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											stripe_price: Number(e.target.value),
										});
									}}
								/>
								{errors.stripe_price && <span className="mr-2 w-full text-red-500">{errors.stripe_price.message}</span>}
							</div>
							<Button
								type="button"
								variant={buttonVariant.stripe}
								className={"flex min-w-fit text-xs uppercase"}
								onClick={() => {
									setProductType("stripe");
									setIsDialogActive(true);
								}}
							>
								{!productData.stripe_productid ? "Crear en Stripe" : "Actualizar precio"}
							</Button>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Inicial Stripe (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("stripe_price_initial", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											stripe_price_initial: Number(e.target.value),
										});
									}}
								/>
								{errors.stripe_price_initial && (
									<span className="mr-2 w-full text-red-500">{errors.stripe_price_initial.message}</span>
								)}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio en Google (valor en centavos)
							<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("google_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											google_price: Number(e.target.value),
										});
									}}
								/>
								{errors.google_price && <span className="text-red-500">{errors.google_price.message}</span>}
							</div>

							<Button
								type="button"
								variant={buttonVariant.google}
								className={"flex min-w-fit text-xs uppercase"}
								onClick={() => {
									setProductType("google");
									setIsDialogActive(true);
								}}
							>
								{!productData.google_id ? "Crear en Google" : "Actualizar precio"}
							</Button>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Inicial Google (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("google_price_initial", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											google_price_initial: Number(e.target.value),
										});
									}}
								/>
								{errors.google_price_initial && (
									<span className="mr-2 w-full text-red-500">{errors.google_price_initial.message}</span>
								)}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio en Apple (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("apple_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											apple_price: Number(e.target.value),
										});
									}}
								/>
								{errors.apple_price && <span className="text-red-500">{errors.apple_price.message}</span>}
							</div>
							<Button
								type="button"
								variant={buttonVariant.apple}
								className={"flex min-w-fit text-xs uppercase"}
								onClick={() => {
									setProductType("apple");
									setIsDialogActive(true);
								}}
							>
								Actualizar precio
							</Button>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Inicial Apple (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("apple_price_initial", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											apple_price_initial: Number(e.target.value),
										});
									}}
								/>
								{errors.apple_price_initial && <span className="mr-2 w-full text-red-500">{errors.apple_price_initial.message}</span>}
							</div>
						</div>
					</div>
					<div className="mt-4 flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								navigate("/products/serie-list");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading}>
							{isLoading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Editar producto
						</Button>
					</div>
				</form>
			</div>
			<CreateUpdateMembershipDialog
				onDismiss={() => {
					setIsDialogActive(false);
				}}
				open={isDialogActive}
				data={productData}
				onSuccess={product => {
					setButtonVariant(previous => {
						return {
							...previous,
							[product]: "destructive",
						};
					});
					setIsDialogActive(false);
					toast.success("Acción realizada con éxito!");
				}}
				productType={productType}
			/>
		</>
	);
}
