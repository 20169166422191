import {RefObject, useEffect, useMemo, useState} from "react";
import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {addDays, differenceInDays, endOfDay, format, isSameDay, parse} from "date-fns";
import {Bar} from "react-chartjs-2";
import {DateRange} from "react-day-picker";
import {Daily} from "../../../../data-access/series/content";
import {oneMonth} from "../../../../../utils/barGraphTransformData";
import {Card} from "../../../../components/blocks/Stats/Card";
import {Donations, GiftBox, Spinner} from "../../../../components/primitives/icons";
import {es} from "date-fns/locale";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

interface MonthlyData {
	[key: string]: {
		date: string;
		value: number;
	};
}

interface CoinsCollectedChartProps {
	chartRef: RefObject<
		ChartJSOrUndefined<
			"bar",
			{
				date: string;
				value: number;
			}[]
		>
	>;
	dates: DateRange | undefined;
	statistics: {
		daily_stickers: Daily[];
		daily_donations: Daily[];
		stickers: number;
		donations: number;
	};
	loading?: boolean;
}
export default function CoinsCollectedChart({dates, statistics, chartRef, loading}: CoinsCollectedChartProps) {
	const [showByMonths, setShowByMonths] = useState(false);

	useEffect(() => {
		const duration = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(duration > oneMonth);
	}, [dates, statistics.daily_donations.length, statistics.daily_stickers.length]);

	const data: ChartData<"bar", {date: string; value: number}[]> | null = useMemo(() => {
		const startDate = new Date(dates?.from ?? 0);
		const endDate = new Date(dates?.to ?? Date.now());
		const diff_days = differenceInDays(endDate, startDate) + 1;

		//Transforms the data returned from big query
		const dailyStickers =
			statistics?.daily_stickers?.map(({day, month, year, coin}) => ({
				date: new Date(year, month - 1, day).toISOString(),
				value: coin,
			})) ?? [];
		const dailyDonations =
			statistics?.daily_donations?.map(({day, month, year, coin}) => ({
				date: new Date(year, month - 1, day).toISOString(),
				value: coin,
			})) ?? [];

		// Group elements by day in dailyProfit
		const getGroupedDaily = (data: {date: string; value: number}[]) =>
			data.reduce((acc: {date: string; value: number}[], curr) => {
				const existingDayData = acc.find(el => isSameDay(new Date(el.date), new Date(curr.date)));
				if (existingDayData) {
					existingDayData.value += curr.value;
				} else {
					acc.push(curr);
				}
				return acc;
			}, []);
		const groupedDailyStickers = getGroupedDaily(dailyStickers);
		const groupedDailyDonations = getGroupedDaily(dailyDonations);

		//Creates a template data to be filled by the statistics
		const fillerData = Array(diff_days)
			.fill(0)
			.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), value: 0}));

		//Combined data from statistics and filled.
		const getDailyData = (data: {date: string; value: number}[]) =>
			fillerData
				.map(fillEl => {
					const el = data.find(statEl => {
						return isSameDay(new Date(fillEl.date), new Date(statEl.date));
					});

					if (el) return {...el, date: fillEl.date};

					return fillEl;
				})
				.reverse();

		const dailyStickersData = getDailyData(groupedDailyStickers);
		const dailyDonationsData = getDailyData(groupedDailyDonations);

		const getMonthlyData = (data: {date: string; value: number}[]) =>
			data.reduce((acc: MonthlyData, dataPoint) => {
				const date = new Date(dataPoint.date);
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const monthString = month < 10 ? `0${month}` : `${month}`;
				const key = `${year}-${monthString}`;

				if (!acc[key]) {
					acc[key] = {
						date: `${year}-${monthString}`,
						value: 0,
					};
				}

				acc[key].value += dataPoint.value;

				return acc;
			}, {});

		const monthlyStickersData = getMonthlyData(groupedDailyStickers);
		const monthlyDonationsData = getMonthlyData(groupedDailyDonations);

		const currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1;
			const monthString = month < 10 ? `0${month}` : `${month}`;
			const key = `${year}-${monthString}`;

			if (!monthlyStickersData[key]) {
				monthlyStickersData[key] = {
					date: `${year}-${monthString}`,
					value: 0,
				};
			}
			if (!monthlyDonationsData[key]) {
				monthlyDonationsData[key] = {
					date: `${year}-${monthString}`,
					value: 0,
				};
			}

			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		const monthlyStickersDataArray = Object.values(monthlyStickersData);
		const monthlyDonationsDataArray = Object.values(monthlyDonationsData);

		monthlyStickersDataArray.sort((a, b) => {
			const dateA = parse(a.date, "yyyy-MM", new Date());
			const dateB = parse(b.date, "yyyy-MM", new Date());

			return dateA.getTime() - dateB.getTime();
		});

		monthlyDonationsDataArray.sort((a, b) => {
			const dateA = parse(a.date, "yyyy-MM", new Date());
			const dateB = parse(b.date, "yyyy-MM", new Date());

			return dateA.getTime() - dateB.getTime();
		});

		const selectedStickersData = showByMonths ? monthlyStickersDataArray : dailyStickersData;
		const selectedDonationsData = showByMonths ? monthlyDonationsDataArray : dailyDonationsData;

		return {
			datasets: [
				{
					label: "Stickers",
					data: selectedStickersData,
					backgroundColor: "rgb(54,162,235)",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "value",
					},
				},
				{
					label: "Donaciones",
					data: selectedDonationsData,
					backgroundColor: "rgb(255,99,132)",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "value",
					},
				},
			],
		};
	}, [dates?.from, dates?.to, statistics.daily_stickers, statistics.daily_donations, showByMonths]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 py-6 pt-0 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-6 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="mb-4 flex items-center justify-between pl-6 pt-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Coins donados en el contenido</h3>
				</div>
				<div>
					{loading ? (
						<Spinner />
					) : (
						<Bar
							height={350}
							ref={chartRef}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								plugins: {
									tooltip: {
										callbacks: {
											title(tooltipItems) {
												const date = new Date(tooltipItems[0].label);
												if (showByMonths) {
													return format(date, "yyyy, MMM", {locale: es}).toLowerCase();
												} else {
													return format(date, "EE, dd MMMM", {locale: es}).toLowerCase();
												}
											},
											label: function (context) {
												let label = context.dataset.label || "";

												if (label) {
													label += ": ";
												}
												if (context.parsed.y !== null) {
													label += context.parsed.y;
												}
												return label;
											},
										},
									},
								},
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));

												// Format the date based on the available information
												const formattedDate = showByMonths
													? format(date, "MMM - yyyy", {locale: es}).toLowerCase()
													: format(date, "MMM d", {locale: es}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
										stacked: true,
									},
									y: {
										stacked: true,
									},
								},
							}}
							data={data}
						/>
					)}
				</div>
			</div>
			<div className="grid-cols-1">
				<Card
					title="Total"
					amount={0}
					isMoney={false}
					loading={loading}
					titleSeparator
					customValues={[
						{
							amount: statistics.stickers,
							text: "Stickers",
							icon: <Donations className="opacity-50" width={20} height={20} />,
						},
						{
							amount: statistics.donations,
							icon: <GiftBox className="opacity-50" width={20} height={20} />,
							text: "Donaciones",
						},
					]}
				/>
			</div>
		</div>
	);
}
