import {useEffect, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import type {Identifier, XYCoord} from "dnd-core";
import {cn} from "../../../../../../../utils/classNames";
import {CancelCircle, CheckCircle, DragIndicator, Pencil, Trash} from "../../../../../../components/primitives/icons";
import {Button} from "../../../../../../components/primitives/Button";
import {auth} from "../../../../../../firebase";
import {deleteCreditCategory, deleteCreditData, editCreditCategory, insertCredit, orderCredits} from "../../../../../../data-access/series/credits";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {seriesActions} from "../../../../../../_store/features/series/series-slice";
import {useParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import {Input} from "../../../../../../components/primitives/Input";
import StaffItem from "./Staff";
import {StaffSelect} from "../../../../../../components/blocks/StaffSelect";
import {Staff} from "../../../../../../data-access/series/staff";

interface CategoryProps {
	id: string;
	name: string;
	data: {
		creditId: string;
		staffFullName: string;
		staffRanking: number;
	}[];
	ranking: number;
	index: number;
	creditId: string;
	onMoveCategory: (dragIndex: number, hoverIndex: number) => void;
	onDropCategory: (dragIndex: number, hoverIndex: number) => void;
}

export interface CardProps {
	id: any;
	text: string;
	index: number;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
	index: number;
	id: string;
	type: string;
}

export default function Category(props: CategoryProps) {
	const {id, name, data, index, ranking, creditId, onMoveCategory, onDropCategory} = props;
	const {id: serieId} = useParams();
	const [editingSection, setEditingSection] = useState(false);
	const [newCategoryName, setNewCategoryName] = useState(name);
	const [staffRanking, setStaffRanking] = useState(0);

	const ref = useRef<HTMLDivElement>(null);
	const dispatch = useAppDispatch();
	const [{handlerId}, drop] = useDrop<DragItem, void, {handlerId: Identifier | null}>({
		accept: "category",
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			onMoveCategory(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
		drop(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Time to actually perform the action
			onDropCategory(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{isDragging}, drag] = useDrag({
		type: "category",
		item: () => {
			return {id, index};
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	drag(drop(ref));

	const handleCategoryDelete = () => {
		auth.currentUser?.getIdToken().then(token => {
			deleteCreditCategory(token, {id})
				.then(() => {
					dispatch(
						seriesActions.removeSeriesCategory({
							serieId: serieId!,
							creditCategoryId: id,
						}),
					);
					toast.success("Categoría eliminada correctamente.");
				})
				.catch(() => {
					toast.error("No se logro eliminar la categoría.");
				});
		});
	};

	const handleCategoryChange = () => {
		auth.currentUser?.getIdToken().then(token => {
			editCreditCategory(token, {id, category: newCategoryName, ranking: "0"})
				.then(() => {
					dispatch(
						seriesActions.updateSeriesCategoryName({
							serieId: serieId!,
							creditCategoryId: id,
							creditCategoryName: newCategoryName,
						}),
					);
					toast.success("Categoría actualizada correctamente");
					setEditingSection(false);
				})
				.catch(() => {
					toast.error("Error al actualizar categoría.");
				});
		});
	};

	const handleStaffDelete = (staff: {creditId: string; staffFullName: string}) => {
		auth.currentUser?.getIdToken().then(token => {
			const promise = deleteCreditData(token, {categoryid: id, staffid: staff.creditId})
				.then(() => {
					dispatch(
						seriesActions.removeCategoryStaff({
							serieId: serieId!,
							creditId: staff.creditId,
						}),
					);
				})
				.catch(err => {
					console.log(err);
				});
			toast.promise(promise, {
				loading: "Eliminando Staff",
				error: "Error al eliminar staff",
				success: `Staff removido de la sección "${name}".`,
			});
		});
	};

	const handleAddStaff = (staff: Staff) => {
		auth.currentUser?.getIdToken().then(token => {
			const rankingStaff = staffRanking + 1;
			insertCredit(token, {categoryid: id, seriesid: serieId!, staffid: staff.id, ranking: rankingStaff}).then(() => {
				dispatch(
					seriesActions.getCreditStaffByName({
						GCPUser: auth.currentUser!,
						params: {page: 0, searchby: "fullname", searchvalue: staff.fullname},
					}),
				).then((res: any) => {
					dispatch(
						seriesActions.insertInlineAddedIntoSerie({
							serieId: serieId!,
							staffData: {
								category_ranking: ranking,
								credit_category: name,
								credit_fullname: res.payload.results[0].fullname,
								staff_ranking: 0,
								creditcategory_id: id,
								credit_id: creditId,
								creditstaff_id: res.payload.results[0].id,
							},
						}),
					);
				});
			});
		});
	};

	const handleOrderUpStaff = (creditId: string) => {
		const currentIndex = data.findIndex(el => el.creditId === creditId);
		const previousIndex = currentIndex - 1;
		if (previousIndex === -1) return;
		const previousRank = data[previousIndex];
		const order = previousRank.staffRanking;

		const ordered_data = [
			{id: creditId, order: order},
			{id: previousRank.creditId, order: order + 1},
		];

		auth.currentUser?.getIdToken().then(token => {
			const promise = orderCredits(token, {
				ordered_data,
			})
				.then(() => {
					dispatch(
						seriesActions.updateCategoryStaffOrder({
							ordered_data,
							serieId: serieId!,
						}),
					);
				})
				.catch(err => {
					console.log(err);
				});
			toast.promise(promise, {
				loading: "Ordenando Staff",
				error: "Error al ordenar staff",
				success: "Staff ordenado correctamente",
			});
		});
	};

	const handleOrderDownStaff = (creditId: string) => {
		const currentIndex = data.findIndex(el => el.creditId === creditId);
		const nextIndex = currentIndex + 1;
		const nextRank = data[nextIndex];
		if (!nextRank) return;
		const order = nextRank.staffRanking;

		const ordered_data = [
			{id: creditId, order: order},
			{id: nextRank.creditId, order: order - 1},
		];

		auth.currentUser?.getIdToken().then(token => {
			const promise = orderCredits(token, {
				ordered_data,
			})
				.then(() => {
					dispatch(
						seriesActions.updateCategoryStaffOrder({
							ordered_data,
							serieId: serieId!,
						}),
					);
				})
				.catch(err => {
					console.log(err);
				});
			toast.promise(promise, {
				loading: "Ordenando Staff",
				error: "Error al ordenar staff",
				success: "Staff ordenado correctamente",
			});
		});
	};

	useEffect(() => {
		setStaffRanking(data.length);
	}, [data]);

	return (
		<div
			ref={ref}
			data-handler-id={handlerId}
			className={cn(
				"group relative grid grid-cols-3 overflow-hidden rounded-md border border-transparent p-4 pl-12 transition-all hover:border-border",
				{
					"border-border bg-background hover:border-transparent": isDragging,
				},
			)}
		>
			<div
				className={cn(
					"absolute bottom-0 left-0 top-0 flex items-center justify-center border-r border-border bg-background px-2 opacity-0 transition-all group-hover:opacity-100",
					{
						"opacity-100": isDragging,
					},
				)}
			>
				<DragIndicator className="h-4 w-4" />
			</div>
			<div className="border-r border-border pr-4 text-end font-bold">
				{editingSection ? (
					<div className="flex">
						<Input
							value={newCategoryName}
							onChange={e => {
								setNewCategoryName(e.target.value);
							}}
							className="h-9"
						/>
						<Button size="sm" variant="outline" className="px-2.5" onClick={handleCategoryChange}>
							<CheckCircle />
						</Button>
						<Button
							size="sm"
							variant="outline"
							className="px-2.5"
							onClick={() => {
								setEditingSection(false);
							}}
						>
							<CancelCircle />
						</Button>
					</div>
				) : (
					name
				)}
			</div>
			<div className="col-span-2 pl-4">
				{data.map(staff => (
					<StaffItem
						key={staff.creditId}
						data={staff}
						onStaffDelete={handleStaffDelete}
						onStaffOrderUp={handleOrderUpStaff}
						onStaffOrderDown={handleOrderDownStaff}
					/>
				))}
				<StaffSelect
					onStaffSelect={handleAddStaff}
					selectedStaff={data.map(el => ({fullname: el.staffFullName, id: el.creditId, professions: []}))}
				/>
			</div>
			<div className="absolute right-0 top-0 opacity-0 transition-opacity group-hover:opacity-100">
				<Button
					size="sm"
					variant="outline"
					className="rounded-r-none border-r-0 px-2.5"
					onClick={() => {
						setEditingSection(prev => !prev);
					}}
				>
					<Pencil className="h-4 w-4" />
				</Button>
				<Button size="sm" variant="outline" className="rounded-l-none px-2.5" onClick={handleCategoryDelete}>
					<Trash className="h-4 w-4" />
				</Button>
			</div>
		</div>
	);
}
