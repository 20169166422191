import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface CustomNotificationNavigation {
	internal: boolean;
	address: string;
	id?: string | null;
}

export interface CustomNotification {
	id: string;
	type: string;
	subject: string;
	text: string[];
	navigation: CustomNotificationNavigation | null;
	created_at: string;
	updated_at: string;
}

export interface CustomNotificationsListResult {
	totalResults: number;
	pageSize: number;
	current: number;
	results: CustomNotification[];
}

export interface ListCustomNotificationsParams {
	page?: number;
	page_size?: number;
	searchby?: "type";
	searchvalue?: string;
}

export const listCustomNotifications = async (
	token: string,
	params: ListCustomNotificationsParams | undefined,
): Promise<AxiosResponse<CustomNotificationsListResult>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.get("/list_custom_notifications", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});

	return response;
};

export interface UpdateCustomNotificationBody {
	id?: string;
	type: string;
	subject: string;
	text: string[];
	navigation?: CustomNotificationNavigation | null;
}

export const updateCustomNotification = async (
	token: string,
	data: Pick<UpdateCustomNotificationBody, "id" | "navigation" | "text" | "subject">,
): Promise<AxiosResponse<CustomNotification>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.patch(`/update_custom_notification`, data, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});

	return response.data;
};

export const createCustomNotification = async (
	token: string,
	data: Pick<UpdateCustomNotificationBody, "type" | "navigation" | "text" | "subject">,
): Promise<AxiosResponse<CustomNotification>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.post(`/create_custom_notification`, data, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});

	return response.data;
};

export const deleteCustomNotification = async (token: string, id: string): Promise<AxiosResponse<CustomNotification>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.delete(`/delete_custom_notification`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params: {id},
	});

	return response.data;
};

// NOTIFICATIONS SETTINGS

export interface NotificationSettings {
	id?: string;
	notification_type: string;
	enabled: boolean;
	audience: string[] | null;
	display?: "in_app" | "push" | "email";
	label: string;
	events?: string[];
}

export interface NotificationSettingsListResult {
	totalResults: number;
	results: NotificationSettings[];
	current: number;
	pageSize: number;
}

export const listAllNotificationsSettings = async (token: string): Promise<AxiosResponse<NotificationSettingsListResult>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.get("/list_notification_settings", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});

	return response.data;
};

export const createCustomNotificationsSettings = async (
	token: string,
	data: Pick<NotificationSettings, "label" | "enabled" | "audience" | "display">,
): Promise<AxiosResponse<NotificationSettings>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.post("/create_notification_settings", data, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});

	return response.data;
};

export interface SendCustomNotificationBody {
	type: string;
	name?: string;
	number?: number;
	content_id?: string;
}

export interface SendCustomNotificationResponse {}

export const sendCustomNotification = async (
	token: string,
	data: SendCustomNotificationBody,
): Promise<AxiosResponse<SendCustomNotificationResponse>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.post("/send_custom_notification", data, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});

	return response.data;
};

export const deleteNotificationSetting = async (token: string, id: string): Promise<AxiosResponse<NotificationSettings>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.delete("/delete_notification_settings", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params: {id},
	});

	return response.data;
};

export const updateNotificationSetting = async (
	token: string,
	data: Pick<NotificationSettings, "id" | "notification_type" | "enabled">,
): Promise<AxiosResponse<NotificationSettings>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.patch("/update_notification_settings", data, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});

	return response.data;
};

export interface NotificationEvent {
	name: string;
	method: string;
	path: string;
}

export const getAllNotificationEvents = async (token: string): Promise<AxiosResponse<NotificationEvent[]>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.get("/get_events_list", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});

	return response.data;
};
