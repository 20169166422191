import "chartjs-adapter-date-fns";
import {useMemo} from "react";
import {useDashboardCorporateByNameQuery} from "../../../../_store/features/dashboard-corporate/hooks";
import {Card} from "../Card";
import {Spinner} from "../../../primitives/icons";
import CostEarnGraphic from "../CostEarnGraphic";
import {DateRange} from "react-day-picker";

interface GeneralIncomeProps {
	serieId: string | undefined;
	dates: DateRange | undefined;
}

export function GeneralIncome({serieId, dates}: GeneralIncomeProps) {
	const {data: statistics, isLoading: isStatisticsLoading} = useDashboardCorporateByNameQuery("getGeneralIncomeAndProfitV2");

	const range_profit = useMemo(
		() =>
			serieId
				? (statistics?.range_profit_memberships ?? 0) + (statistics?.range_profit_rentals ?? 0)
				: (statistics?.range_profit_coins ?? 0) +
				  (statistics?.range_profit_memberships ?? 0) +
				  (statistics?.range_profit_subscriptions ?? 0) +
				  (statistics?.range_profit_rentals ?? 0) +
				  (statistics?.range_profit_subscriptions_renewal ?? 0) +
				  (statistics?.range_profit_slots ?? 0),
		[serieId, statistics],
	);
	const total_profit = useMemo(
		() =>
			serieId
				? (statistics?.total_profit_memberships ?? 0) + (statistics?.total_profit_rentals ?? 0)
				: (statistics?.total_profit_coins ?? 0) +
				  (statistics?.total_profit_memberships ?? 0) +
				  (statistics?.total_profit_subscriptions ?? 0) +
				  (statistics?.total_profit_rentals ?? 0),
		[serieId, statistics],
	);

	const earnings = useMemo(
		() => (range_profit > statistics?.production_cost! ? (range_profit - statistics?.production_cost!) * 0.01 : 0),
		[statistics, range_profit],
	);

	const totalProfit = useMemo(() => {
		const rangeFeeCoins = !serieId ? statistics?.range_fee_coins ?? 0 : 0;
		const rangeFeeSubscriptions = !serieId ? statistics?.range_fee_subscriptions ?? 0 : 0;
		const rangeFeeSubscriptionRenewal = statistics?.range_fee_subscriptions_renewal ?? 0;
		const rangeFeeSlots = !serieId ? statistics?.range_fee_slots ?? 0 : 0;
		const rangeFeeRentals = statistics?.range_fee_rentals ?? 0;
		const totalFee =
			rangeFeeCoins +
			rangeFeeSubscriptions +
			rangeFeeSubscriptionRenewal +
			rangeFeeSlots +
			rangeFeeRentals +
			(statistics?.range_fee_memberships ?? 0);
		return earnings - totalFee * 0.01;
	}, [statistics, earnings, serieId]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-3 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-7 grid grid-cols-2 gap-3 overflow-y-auto xl:grid-cols-4">
				<Card
					title="Total de costo de Producción"
					amount={statistics?.production_cost ? statistics?.production_cost / 100 : 0}
					tooltip="Total de costo de producción del contenido."
					loading={isStatisticsLoading}
				/>
				<Card
					title="Total de ingresos Bruto"
					amount={range_profit ? range_profit / 100 : 0}
					tooltip="Total de ingresos de la plataforma."
					loading={isStatisticsLoading}
				/>
				<Card
					title="Total de ganancia neta"
					amount={totalProfit > 0 ? totalProfit : 0}
					tooltip="Total de ganancias neta de la plataforma."
					loading={isStatisticsLoading}
				/>
				<Card title="Total de ganancias" amount={earnings} tooltip="Total de ganancias de la plataforma." loading={isStatisticsLoading} />
			</div>
			{serieId && (
				<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
					<div className="flex flex-col space-y-1.5 p-6">
						<h3 className="text-lg font-semibold leading-none tracking-tight">Costos/Ganancias</h3>
					</div>
					{isStatisticsLoading ? (
						<div className="flex h-[400px] items-center justify-center">
							<Spinner />
						</div>
					) : (
						statistics && <CostEarnGraphic statistics={{...statistics, range_profit, total_profit}} />
					)}
				</div>
			)}
		</div>
	);
}
