import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	adminGlobalNotificationData,
	Notification,
	InsertGlobalNotificationBody,
	InsertUserNotificationBody,
	adminUserNotificationData,
	SendUserByViewsNotification,
	sendUserByViewsNotification,
	sendMarketingNotification,
	SendMarketingNotificationParams,
} from "../../../data-access/notify";
import {
	createCustomNotification,
	createCustomNotificationsSettings,
	CustomNotification,
	CustomNotificationsListResult,
	deleteCustomNotification,
	deleteNotificationSetting,
	getAllNotificationEvents,
	listAllNotificationsSettings,
	listCustomNotifications,
	ListCustomNotificationsParams,
	NotificationSettings,
	sendCustomNotification,
	SendCustomNotificationBody,
	updateCustomNotification,
	UpdateCustomNotificationBody,
	updateNotificationSetting,
} from "../../../data-access/notifications/notifications";

export interface notificationsInterface {
	loading: boolean;
	results: Notification[] | CustomNotification[];
	success: boolean;
	error: string;
	totalResults: number;
	current: number;
	pageSize: number;
}

const initialState: notificationsInterface = {
	loading: false,
	results: [],
	success: false,
	error: "",
	current: 0,
	pageSize: 0,
	totalResults: 0,
};

const globalNotifications = createAsyncThunk("notifications/global", async (data: InsertGlobalNotificationBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await adminGlobalNotificationData(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem sending notification, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const userNotifications = createAsyncThunk("notifications/user", async (data: InsertUserNotificationBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await adminUserNotificationData(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem sending notification, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const sendUserByViewsNotificationsAction = createAsyncThunk(
	"notifications/send-users-by-views-notifications",
	async (data: SendUserByViewsNotification, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const res = await sendUserByViewsNotification(token, data);

			if (!res.data) return thunkAPI.rejectWithValue("Problem sending notification, try again.");

			return {
				item: res.data,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem sending notifications");
		}
	},
);

const sendMarketingNotificationsAction = createAsyncThunk(
	"notifications/send-marketing-role-notifications",
	async (data: SendMarketingNotificationParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const res = await sendMarketingNotification(token, data);

			if (!res.data) return thunkAPI.rejectWithValue("Problem sending notification, try again.");

			return {
				item: res.data,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem sending notifications");
		}
	},
);

// *** CUSTOM NOTIFICATIONS ***
const getAllCustomNotifications = createAsyncThunk(
	"notifications/get-all-custom-notifications",
	async (params: ListCustomNotificationsParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await listCustomNotifications(token, params);

			return response;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching custom notifications");
		}
	},
);

const newCustomNotification = createAsyncThunk(
	"notifications/create-custom-notifications",
	async (data: Pick<UpdateCustomNotificationBody, "type" | "navigation" | "text" | "subject">, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await createCustomNotification(token, data);
			return response;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue(error?.response?.data ?? "Problem creating custom notification");
		}
	},
);

const removeCustomNotification = createAsyncThunk("notifications/remove-custom-notifications", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await deleteCustomNotification(token, id);
		return response;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem removing custom notification");
	}
});

const editCustomNotification = createAsyncThunk(
	"notifications/update-custom-notifications",
	async (data: Pick<UpdateCustomNotificationBody, "id" | "navigation" | "text" | "subject">, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await updateCustomNotification(token, data);
			return response;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue(error?.response?.data ?? "Problem updating custom notification");
		}
	},
);

const sendCustomNotifications = createAsyncThunk("notifications/send-custom-notifications", async (data: SendCustomNotificationBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await sendCustomNotification(token, data);

		return response;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem sending short notifications");
	}
});

// *** NOTIFICATIONS SETTINGS ***
const getAllNotificationsSettings = createAsyncThunk("notifications/get-all-notifications-settings", async (_, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await listAllNotificationsSettings(token);

		return response;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching notifications settings");
	}
});

const newCustomNotificationType = createAsyncThunk(
	"notifications/create-notification-type",
	async (data: Pick<NotificationSettings, "label" | "enabled" | "audience" | "display">, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await createCustomNotificationsSettings(token, data);

			return response;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem creating notification type");
		}
	},
);

const editNotificationSetting = createAsyncThunk(
	"notifications/update-notification-setting",
	async (data: Pick<NotificationSettings, "id" | "notification_type" | "enabled" | "label" | "audience" | "events">, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await updateNotificationSetting(token, data);

			return response;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem updating notification type");
		}
	},
);

const removeNotificationSetting = createAsyncThunk("notifications/remove-notification-setting", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await deleteNotificationSetting(token, id);

		return response;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem removing notification type");
	}
});

const listNotificationsEvents = createAsyncThunk("notifications/list-notifications-events", async (_, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getAllNotificationEvents(token);

		return response;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching notifications events");
	}
});

const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(globalNotifications.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(globalNotifications.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!] as Notification[];
				state.success = true;
			})
			.addCase(globalNotifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(userNotifications.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(userNotifications.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!] as Notification[];
				state.success = true;
			})
			.addCase(userNotifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(sendUserByViewsNotificationsAction.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(sendUserByViewsNotificationsAction.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!] as Notification[];
				state.success = true;
			})
			.addCase(sendUserByViewsNotificationsAction.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(sendMarketingNotificationsAction.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(sendMarketingNotificationsAction.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!] as Notification[];
				state.success = true;
			})
			.addCase(sendMarketingNotificationsAction.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(getAllCustomNotifications.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(getAllCustomNotifications.fulfilled, (state, action) => {
				state.loading = false;
				state.results = (action.payload?.data as CustomNotificationsListResult).results as CustomNotification[];
				state.success = true;
				state.current = action.payload?.data.current ?? 0;
				state.pageSize = action.payload?.data.pageSize ?? 0;
				state.totalResults = action.payload?.data.totalResults ?? 0;
			})
			.addCase(getAllCustomNotifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});

export const notificationsActions = {
	...notificationsSlice.actions,
	globalNotifications,
	userNotifications,
	sendUserByViewsNotificationsAction,
	sendMarketingNotificationsAction,
	getAllCustomNotifications,
	newCustomNotification,
	removeCustomNotification,
	editCustomNotification,
	getAllNotificationsSettings,
	newCustomNotificationType,
	sendCustomNotifications,
	editNotificationSetting,
	removeNotificationSetting,
	listNotificationsEvents,
};

export default notificationsSlice;
