import axios from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

interface GetCreditCategoryParams {
	page: number;
	searchby: undefined | "name" | "description";
	searchvalue: string;
}
export async function getCreditCategoryData(token: string, params: GetCreditCategoryParams) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_creditcategory_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface GetCreditStaffParams {
	page: number;
	searchby: undefined | "fullname" | "description";
	searchvalue: string;
}
export async function getCreditStaffData(token: string, params: GetCreditStaffParams) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_creditstaff_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

interface InsertCreditData {
	seriesid: string;
	categoryid: string;
	staffid: string;
	ranking: number;
}
export async function insertCredit(token: string, data: InsertCreditData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_credit_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface InsertCreditCategoryData {
	category: string;
	ranking: string;
}
export async function insertCreditCategory(token: string, data: InsertCreditCategoryData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_creditcategory_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface InsertCreditStaffData {
	fullname: string;
	ranking: number;
}
export async function insertCreditStaff(token: string, data: InsertCreditStaffData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_creditstaff_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface EditCreditCategoryData {
	id: string;
	category: string;
	ranking: string;
}

export async function editCreditCategory(token: string, data: EditCreditCategoryData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_creditcategory_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface EditCreditStaff {
	id: string;
	fullname: string;
}

export async function editCreditStaff(token: string, data: EditCreditStaff) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_creditstaff_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface DeleteCategoryData {
	id: string;
}

export async function deleteCreditCategory(token: string, data: DeleteCategoryData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_creditcategory_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

interface DeleteCreditStaff {
	id: string;
}

export async function deleteCreditStaff(token: string, data: DeleteCreditStaff) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_creditstaff_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

interface DeleteCredit {
	staffid: string;
	categoryid: string;
}

export async function deleteCreditData(token: string, data: DeleteCredit) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_credit_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

interface OrderCredits {
	ordered_data: {
		id: string;
		order: number;
	}[];
}
export async function orderCredits(token: string, data: OrderCredits) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.put("/order_credits_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface OrderCreditsRankingData {
	ordered_data: {
		id: string;
		order: number;
	}[];
}
export async function orderCreditsRankingData(token: string, data: OrderCreditsRankingData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.put("/order_credits_ranking_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
