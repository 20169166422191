import {useRef, useEffect, useState, PropsWithChildren} from "react";
import {createPortal} from "react-dom";

interface ClientOnlyPortalProps extends PropsWithChildren {}
export default function ClientOnlyPortal({children}: ClientOnlyPortalProps) {
	const ref = useRef<HTMLBodyElement>();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		ref.current = document.querySelector("body")!;
		setMounted(true);
	}, []);

	return mounted ? createPortal(children, ref.current!) : null;
}
