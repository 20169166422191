import {IconProps} from "./type";
export const BarsPlus = (props: IconProps) => {
	return (
		<svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M15.7778 12V4.66667M1 4.66667H11.5556M1 1H11.5556M1 8.33333H7.33333M11.5556 8.33333H20"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
