/* eslint-disable jsx-a11y/aria-props */
import {useEffect, useState} from "react";
import {RadioGroup} from "@headlessui/react";
import toast from "react-hot-toast";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../../../../components/primitives/Sheet";
import {useAppDispatch, useAppSelector} from "../../../../../../_store/hooks";
import {subscriptionActions} from "../../../../../../_store/features/subscriptions/subscriptions-slice";
import {cn} from "../../../../../../../utils/classNames";
import {CheckCircle, Coins} from "../../../../../../components/primitives/icons";
import {Button} from "../../../../../../components/primitives/Button";
import {activeCoinProducts, coinProductsActions} from "../../../../../../_store/features/products/coin-products-slice";
import {CoinProduct} from "../../../../../../data-access/products/coin-products";
import {convertCurrencyToDouble} from "../../../../../../../utils/money";

interface SellCoinsSheetProps extends SheetProps {
	onRefresh: () => void;
}

export default function SellCoinsSheet(props: SellCoinsSheetProps) {
	const user = useAppSelector(state => state.vendor.customer);
	const [selected, setSelected] = useState<CoinProduct>();

	const coinPlans = useAppSelector(activeCoinProducts);

	const dispatch = useAppDispatch();
	const {...rest} = props;

	useEffect(() => {
		dispatch(coinProductsActions.getCoinProducts({page: 0, page_size: 1000}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSellCoinProduct = async () => {
		if (!selected) {
			toast.success("Selecciona una plan de coins");
			return;
		}

		if (!selected?.vendor_price) {
			toast.error("No se ha encontrado el precio de venta");
			return;
		}

		if (!user) {
			toast.error("No se ha encontrado el usuario");
			return;
		}

		if (window.confirm(`¿Estás seguro de vender ${selected?.coins} coins por ${convertCurrencyToDouble(selected?.vendor_price / 100)}?`)) {
			const thunk = await dispatch(subscriptionActions.createVendorSubscription({customerid: user?.id!, coinproductsid: selected?.id}));

			if (thunk.meta.requestStatus === "fulfilled") {
				props.onRefresh();
				props.onOpenChange?.(false);
			} else {
				toast.error("No se pudo realizar la venta de coins");
			}
		}
	};

	return (
		<Sheet {...rest}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>Vender coins</SheetTitle>
					<SheetDescription>
						Vende coins a <b>{user?.name}</b>
					</SheetDescription>
				</SheetHeader>

				<div className="mx-auto w-full max-w-md scroll-m-0">
					<RadioGroup value={selected} onChange={setSelected}>
						<RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
						<div className="grid grid-cols-2 gap-2">
							{coinPlans.map(plan => (
								<RadioGroup.Option
									key={plan.id}
									value={plan}
									className={({active, checked}) => {
										return cn(
											"relative flex min-h-12 cursor-pointer rounded-lg bg-white px-5 py-4 shadow-md focus:outline-none",
											{
												"ring-2 ring-primary ring-offset-2 ring-offset-primary": active,
												"bg-primary text-white": checked,
											},
										);
									}}
								>
									{({checked}) => (
										<>
											<div className="flex w-full items-center justify-between">
												<div className="flex items-center pr-8">
													<div className="text-sm">
														<RadioGroup.Label
															as="p"
															className={cn("flex items-center space-x-1 font-medium text-gray-900", {
																"text-white": checked,
															})}
														>
															<Coins className="h-5 w-5" />
															<span>{plan.coins}</span>
														</RadioGroup.Label>
														<RadioGroup.Description
															as="span"
															className={cn("inline text-gray-500", {"text-sky-100": checked})}
														>
															<span>{convertCurrencyToDouble(plan.vendor_price / 100)} </span>
														</RadioGroup.Description>
													</div>
												</div>
												{checked && (
													<div className="absolute right-1 top-1 text-white">
														<CheckCircle className="b h-6 w-6" />
													</div>
												)}
											</div>
										</>
									)}
								</RadioGroup.Option>
							))}
						</div>
					</RadioGroup>
				</div>

				<Button className="mt-4 uppercase" onClick={handleSellCoinProduct}>
					Vender plan de coins
				</Button>
			</SheetContent>
		</Sheet>
	);
}
