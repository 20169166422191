import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {useEffect, useState} from "react";
import {serieContentsActions} from "../../_store/features/serie-content/serie-content-slice";
import {Button} from "../../components/primitives/Button";
import {Clipboard, Key, Spinner} from "../../components/primitives/icons";
import {auth} from "../../firebase";
import {CreateLiveChapterResponse, adminFinishLiveChapter, createLiveChapter} from "../../data-access/mux";
import toast from "react-hot-toast";
import LiveStream from "../../components/primitives/icons/LiveStream";
import {editContent} from "../../data-access/series/content";
import {sendLiveChapterNotification} from "../../data-access/notify";
import {Stop} from "../../components/primitives/icons/Stop";
import {add} from "date-fns";
import {Input} from "../../components/primitives/Input";
import {Label} from "../../components/primitives/Label";

export default function LivePremiere() {
	const {id} = useParams();
	const [liveChapterData, setLiveChapterData] = useState<CreateLiveChapterResponse | null>(null);
	const [liveChapterStarted, setLiveChapterStarted] = useState(false);
	const [releaseWaitTime, setReleaseWaitTime] = useState(30);
	const content = useAppSelector(state => state.serieContent.results.find(el => el.id === id));
	const contentLoading = useAppSelector(state => state.serieContent.loading);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!content) {
			dispatch(serieContentsActions.listSeriesContent({searchby: "id", searchvalue: id}));
		}
	}, [content, dispatch, id]);

	useEffect(() => {
		const data = localStorage.getItem(`${id}.stream_keys`);
		if (data) {
			setLiveChapterData(JSON.parse(data));
		}

		const isLiveData = localStorage.getItem(`${id}.stream_keys.isLive`);
		if (isLiveData) {
			setLiveChapterStarted(JSON.parse(isLiveData));
		}
	}, [id]);

	const handleGenerateKeys = async () => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token || !content) return;

			const response = await createLiveChapter(token);
			if (response.status >= 200 && response.status < 300) {
				setLiveChapterData(response.data);
				localStorage.setItem(`${id}.stream_keys`, JSON.stringify(response.data));
			}

			const responseContent = await editContent(token, {
				...content,
				seriesid: content.serieid,
				video_id: response.data?.stream_playback_id,
				subscription_required: false,
			});
			if (responseContent.status >= 200 && responseContent.status < 300) {
				dispatch(serieContentsActions.listSeriesContent({searchby: "id", searchvalue: id}));
				toast.success("Contenido actualizado y listo para estreno.");
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleClipboardStreamKey = async () => {
		if (!liveChapterData || !liveChapterData.stream_key) return;
		try {
			navigator.clipboard.writeText(liveChapterData?.stream_key).then(
				function () {
					toast.success("Copiado al clipboard.");
					console.log("Async: Copying to clipboard was successful!");
				},
				function (err) {
					toast.success("Ocurrio un error copiando al clipboard.");
					console.error("Async: Could not copy text: ", err);
				},
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleStartPremiere = async () => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token || !content || !id) return;

			const response = await editContent(token, {
				...content,
				seriesid: content.serieid,
				release_date: new Date().toISOString(),
				whitelist_release_date: new Date().toISOString(),
				subscription_required: false,
			});

			if (response.status >= 200 && response.status < 300) {
				dispatch(serieContentsActions.listSeriesContent({searchby: "id", searchvalue: id}));
				setTimeout(() => {
					sendLiveChapterNotification(token, {
						contentid: id,
						seriesid: content.serieid,
						title: `El estreno de ${content.title} esta a punto de comenzar.`,
					});
					localStorage.setItem(`${id}.stream_keys.isLive`, JSON.stringify(true));
				}, 5000);
				setLiveChapterStarted(true);
			}
		} catch (error) {
			toast.error("Ocurrió un error no se puede comenzar el estreno.");
		}
	};

	const handleEndPremiere = async () => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token || !content || !id || !liveChapterData?.stream_id) return;

			const response = await editContent(token, {
				...content,
				seriesid: content.serieid,
				release_date: add(new Date(), {minutes: releaseWaitTime}).toISOString(),
				whitelist_release_date: add(new Date(), {minutes: releaseWaitTime}).toISOString(),
				premiere_type: 0,
				membership_type: 0,
				subscription_required: false,
			});

			const finishLiveChapterPromise = adminFinishLiveChapter(token, {
				streamid: liveChapterData?.stream_id,
				contentid: content.id,
			});
			await toast.promise(finishLiveChapterPromise, {
				loading: "Cerrando el estreno...",
				success: "Estreno terminado.",
				error: "No se logro cerrar el estreno",
			});

			if (response.status >= 200 && response.status < 300) {
				dispatch(serieContentsActions.listSeriesContent({searchby: "id", searchvalue: id}));
				toast.success("Estreno terminado.");
				setLiveChapterStarted(false);
				localStorage.removeItem(`${id}.stream_keys`);
				localStorage.removeItem(`${id}.stream_keys.isLive`);
			}
		} catch (error) {
			toast.error("Ocurrió un error no se puede terminar el estreno.");
		}
	};

	return (
		<div>
			<div className="border-b border-border px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Estreno en Vivo</h2>
				<span className="flex items-center text-base text-muted-foreground lg:text-lg">
					Contenido a estrenar: {content?.title} {contentLoading && <Spinner className="ml-2 h-4 w-4 animate-spin" />}
				</span>
			</div>
			<div className="mt-8 pl-12 pr-8">
				<ol className="relative border-l border-gray-200 dark:border-gray-700">
					<li className="mb-10 ml-8">
						<span className="absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white dark:bg-blue-900 dark:ring-gray-900">
							<span className="font-bold text-secondary">1</span>
						</span>
						<h3 className="mb-1 flex items-center text-lg font-semibold">Crear llaves para stream</h3>
						<p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
							Genera la llave que te permitirá transmitir en directo desde OBS Studio&#174;.
						</p>

						{liveChapterData !== null ? (
							<div className="flex">
								<div className="rounded-md rounded-r-none border border-border px-3 py-2">{liveChapterData.stream_key}</div>
								<Button variant="outline" className="h-full rounded-l-none" onClick={handleClipboardStreamKey}>
									<Clipboard />
								</Button>
							</div>
						) : (
							<Button className="gap-1.5" onClick={handleGenerateKeys} disabled={contentLoading}>
								<Key className="h-4 w-4" /> Generar llaves.
							</Button>
						)}
					</li>
					<li className="mb-10 ml-8">
						<span className="absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white dark:bg-blue-900 dark:ring-gray-900">
							<span className="font-bold text-secondary">2</span>
						</span>
						<h3 className="mb-1 flex items-center text-lg font-semibold">Comenzar estreno.</h3>
						<p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
							Al comenzar el estreno el contenido quedara automáticamente disponible a todos los usuarios en la plataforma. Antes de
							comenzar asegúrate de ya haber comenzado el stream desde OBS Studio&#174;.
						</p>
						<Button className="gap-1.5" onClick={handleStartPremiere} disabled={liveChapterStarted || contentLoading}>
							<LiveStream /> Comenzar Estreno
						</Button>
					</li>
					<li className="mb-10 ml-8">
						<span className="absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white dark:bg-blue-900 dark:ring-gray-900">
							<span className="font-bold text-secondary">3</span>
						</span>
						<h3 className="mb-1 flex items-center text-lg font-semibold">Terminar estreno.</h3>
						<p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
							Al terminar el estreno el contenido queda oculto y debe ser activado como publico por el administrador con el video que
							desea que quede disponible. Este proceso debe ejecutarse en el panel de series.
						</p>
						<Label className="mb-2">Tiempo de espera release del video (en minutos):</Label>
						<Input
							className="max-w-xs"
							placeholder="Tiempo de espera en minutos para el release del video fijo..."
							value={releaseWaitTime}
							type="number"
							min={0}
							onChange={e => {
								setReleaseWaitTime(Number(e.target.value));
							}}
						/>
						<Button className="mt-2 gap-1.5" onClick={handleEndPremiere} disabled={!liveChapterStarted || contentLoading}>
							<Stop className="h-4 w-4" /> Terminar estreno.
						</Button>
					</li>
				</ol>
			</div>
			<div></div>
		</div>
	);
}
