import {useDashboardByNameQuery} from "../../../../../_store/features/dashboard/hooks";
import {Card} from "../../../../../components/blocks/Stats/Card";

const IncomeRentalsCards = () => {
	const {data} = useDashboardByNameQuery("rentalsIncomeEarning");

	return (
		<div className="grid grid-flow-row grid-cols-2 gap-3  xl:grid-cols-4 2xl:grid-cols-5">
			<Card
				title="Ganancias Personales"
				amount={data?.totalEarnings ?? 0.0}
				tooltip="Ganancias personales del período seleccionado, este corresponde a un valor estimado basado en las compras de rentas y el porciento relacionado a sus ganancias."
				loading={false}
			/>
			<Card
				title="Ingreso Por Rentas"
				amount={data?.totalEarnings ?? 0.0}
				tooltip="Ingreso total de rentas del período seleccionado."
				loading={false}
			/>
			<Card
				title="Rentas Totales"
				amount={data?.totalRentals ?? 0}
				tooltip="Ganancias personales del período seleccionado, este corresponde a un valor estimado basado en las compras de rentas."
				loading={false}
				isMoney={false}
			/>
		</div>
	);
};

export default IncomeRentalsCards;
