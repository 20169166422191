import {User} from "../../../../primitives/icons";
// import {Settings} from "../../../../primitives/icons/Settings";
import NavGroup from "../../subcomponents/NavGroup";
import NavLink from "../../subcomponents/NavLink";

export function VendorNavigationSection() {
	return (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			<NavGroup title="Clientes" route="/vendor/customers" icon={<User className="ml-2 h-6 w-6" />}>
				<NavLink to="vendor/customers/list" label="Listado de clientes" />
				<NavLink to="vendor/customers/new" label="Nuevo cliente" />
			</NavGroup>
			{/* <NavLink to="vendor/setting" label="Configuración" icon={<Settings className="h-6 w-6" />} /> */}
		</div>
	);
}
