import {useEffect} from "react";
import {dashboardActions, selectedInfluencerSeries} from "../../../../_store/features/dashboard/dashboard-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {SerieStatistics} from "../../../influencer-pages";

export function InfluencerDashboard() {
	const influencerSeries = useAppSelector(selectedInfluencerSeries);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(dashboardActions.getTopSeries());
	}, [dispatch]);

	useEffect(() => {
		if (influencerSeries?.length === 1) {
			dispatch(dashboardActions.setSelectedSerieId(influencerSeries[0].serie_id));
		}
	}, [dispatch, influencerSeries?.length, influencerSeries]);

	if (!influencerSeries)
		return (
			<div className="mx-auto flex h-full max-w-lg flex-col items-center justify-center text-center">
				<p className="mb-4 rounded-full bg-card-foreground p-3 text-sm font-medium text-secondary">
					<InformationCircle />
				</p>
				<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">No hay series.</h1>
				<p className="leading-7 [&:not(:first-child)]:mt-6">
					No perteneces a ninguna serie, por favor contacta con un administrador o espera a ser añadido a una.
				</p>
			</div>
		);

	return <SerieStatistics disableRedirect />;
}
