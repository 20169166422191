import {format} from "date-fns";
import {Button} from "../../../../../../components/primitives/Button";
import {CancelCircleFilled, CheckCircleFilled, Pencil, Spinner, Refresh, Lock} from "../../../../../../components/primitives/icons";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../../../../components/primitives/Table";
import Tooltip from "../../../../../../components/primitives/ToolTip-toDeprecate";
import {ListProductSubscriptionResponse, Slots, UserSubscription} from "../../../../../../data-access/subscriptions/subscriptions";
import {useEffect, useMemo, useState} from "react";
import {auth} from "../../../../../../firebase";
import {fetchDbUsers} from "../../../../../../data-access/fetch-db-users";
import {dbUser} from "../../../../../../_store/features/user-db/user-db-slice";
import toast from "react-hot-toast";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {subscriptionActions} from "../../../../../../_store/features/subscriptions/subscriptions-slice";
import useCurrentUserRole from "../../../../../../components/hooks/useCurrentUserRole";
import {Switch} from "../../../../../../components/primitives/Switch";
import {useParams} from "react-router-dom";

export interface SubscriptionsTableProps {
	data?: UserSubscription[];
	isLoading?: boolean;
	onSelectRow?: (row: UserSubscription) => void;
	owner: boolean;
	vendor?: boolean;
	onUpdate?: () => void;
}

export type ExtendedUserSubscription = UserSubscription & {isNew?: boolean; userid?: string; productid?: string};

export default function SubscriptionsTable({data, isLoading, owner, vendor = false, onSelectRow, onUpdate}: SubscriptionsTableProps) {
	const {id: userid} = useParams();
	const {isCSL1} = useCurrentUserRole();
	const [subscriber, setSubscriber] = useState<dbUser | null>(null);
	const [isSubscriberLoading, setIsLoading] = useState(false);

	const slot = useMemo(() => {
		if (!data?.length) return undefined;

		const firstDataItem = data[0];
		const slots = firstDataItem?.slots as Slots[] | undefined;
		if (!slots) return undefined;

		return slots.filter(Boolean).find(s => s.buddy_id === userid);
	}, [data, userid]);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const fetchUsersById = async (id: string) => {
			try {
				setIsLoading(true);
				const token = await auth.currentUser?.getIdToken();
				if (!token) return;

				const response = await fetchDbUsers({idToken: token, searchby: "id", searchvalue: id, page: 0, admin: false});
				if (!response.data) return;

				setSubscriber(response.data.results[0]);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};

		if (slot?.subscriber_id) fetchUsersById(slot?.subscriber_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slot?.subscriber_id]);

	const onRemoveUser = async () => {
		if (!slot) {
			return toast.error("No se ha seleccionado un cupo");
		}

		if (window.confirm("¿Estás seguro de eliminar el usuario del cupo?")) {
			const updatedSlot = {
				...slot,
				buddy_id: null,
				buddy_name: "",
				buddy_email: "",
				state: "available",
				limited_time: false,
				time_lock: true,
			} as Slots;
			const thunk = await dispatch(subscriptionActions.manageAdminBuddyPlan(updatedSlot));

			if (thunk.meta.requestStatus === "rejected") {
				toast.error("Error al eliminar el usuario del cupo", {position: "top-right"});
				return;
			}

			toast.success("Usuario eliminado del cupo correctamente", {position: "top-right"});
			onUpdate?.();
		}
	};

	const onResetSlot = async () => {
		if (!slot) {
			return toast.error("No se ha seleccionado un cupo");
		}

		if (window.confirm("¿Estás seguro de resetear el cupo?")) {
			const updatedSlot = {
				...slot,
				buddy_id: null,
				buddy_name: "",
				buddy_email: "",
				state: "available",
				audit_accepted: null,
				time_lock: false,
			} as Slots;
			// id, subscriber_id, buddy_id, state, limited_time, audit_accepted;
			const thunk = await dispatch(subscriptionActions.manageAdminBuddyPlan(updatedSlot));

			if (thunk.meta.requestStatus === "rejected") {
				toast.error("Error al resetear el cupo", {position: "top-right"});
				return;
			}

			toast.success("Cupo reseteado correctamente.", {position: "top-right"});
			onUpdate?.();
		}
	};

	const onToggleSubscription = async (active: boolean, data: ExtendedUserSubscription) => {
		try {
			const thunkSubscraptions = await dispatch(subscriptionActions.getSubscriptions({searchby: "description", searchvalue: "mensual"}));

			if (thunkSubscraptions.meta.requestStatus === "rejected") {
				toast.error("Error al obtener las suscripciones", {position: "top-right"});
				return;
			}

			const selectedSubscription = (thunkSubscraptions.payload as ListProductSubscriptionResponse)?.results.find(
				s => s.subscription_type === data.subscription_type,
			);

			if (!selectedSubscription) return toast.error("Suscripción no encontrada");

			const thunk = await dispatch(
				subscriptionActions.editUserSubscription({
					...data,
					productid: selectedSubscription.id,
					userid: userid!,
					active,
				}),
			);

			if (thunk.meta.requestStatus === "rejected") {
				toast.error("Error al actualizar la suscripción", {position: "top-right"});
				return;
			}

			toast.success("Suscripción actualizada correctamente", {position: "top-right"});
			onUpdate?.();
		} catch (error) {
			toast.error("Error al actualizar la suscripción", {position: "top-right"});
		}
	};

	return (
		<Table className="h-40">
			<TableHeader>
				<TableRow>
					<TableHead>Activa</TableHead>
					<TableHead>Suscripción</TableHead>
					<TableHead>Fecha de terminación</TableHead>
					{owner ? (
						<>
							<TableHead>Auto renovación</TableHead>
							<TableHead>Plataforma</TableHead>
							<TableHead>Controles</TableHead>
						</>
					) : (
						<>
							<TableHead>Plan familiar</TableHead>
							<TableHead>Controles</TableHead>
						</>
					)}
				</TableRow>
			</TableHeader>
			<TableBody>
				{isLoading ? (
					<TableCell colSpan={6}>
						<Spinner className="mx-auto my-5 h-6 w-6 animate-spin" />
					</TableCell>
				) : !data?.length ? (
					<TableCell colSpan={6}>
						<span className="text-lg font-semibold text-amber-600">Usuario sin suscripción</span>
					</TableCell>
				) : (
					data?.map((subscription, idx) => {
						const unformattedDate = new Date(subscription.termination_date);
						const date = format(unformattedDate, "yyyy/MM/dd");

						return (
							<TableRow className="odd:bg-accent/40">
								<TableCell>
									<div className="flex w-full items-center">
										<span
											className={
												"relative inline-block px-3 font-semibold leading-tight" +
												(!subscription.active ? " text-red-700" : " text-green-700")
											}
										>
											<Tooltip
												text={!subscription.active ? "Desactivada" : "Activada"}
												placement={idx === 0 ? "bottom" : "top"}
											>
												{!subscription.active ? <CancelCircleFilled /> : <CheckCircleFilled />}
											</Tooltip>
										</span>
									</div>
								</TableCell>
								<TableCell>{subscription.name}</TableCell>
								<TableCell>{date || "Sin definir"}</TableCell>
								{owner ? (
									<>
										<TableCell>
											<div className="flex w-full items-center">
												<span
													className={
														"relative inline-block px-3 font-semibold leading-tight" +
														(!subscription.subscription_renewal ? " text-red-700" : " text-green-700")
													}
												>
													<Tooltip
														text={subscription.subscription_renewal ? "Autorrenovación" : "Sin Autorrenovación"}
														placement={idx === 0 ? "bottom" : "top"}
													>
														{!subscription.subscription_renewal ? <CancelCircleFilled /> : <CheckCircleFilled />}
													</Tooltip>
												</span>
											</div>
										</TableCell>
										<TableCell className="capitalize">
											{subscription.prospay_subscriptionid && subscription.last_payment_platform !== "stripe"
												? "Prospay"
												: subscription.last_payment_platform}
										</TableCell>
										{vendor ? (
											<TableCell>
												<Tooltip text="Manejar suscripción" placement="top">
													<Switch
														defaultChecked={subscription.active}
														onCheckedChange={value => onToggleSubscription(value, subscription)}
													/>
												</Tooltip>
											</TableCell>
										) : (
											<TableCell>
												<Button size={"sm"} variant={"outline"} onClick={() => onSelectRow?.(subscription)} disabled={isCSL1}>
													<Pencil className="h-4 w-4" />
												</Button>
											</TableCell>
										)}
									</>
								) : (
									<>
										<TableCell>
											{isSubscriberLoading ? (
												<Spinner className="mx-auto my-5 h-6 w-6 animate-spin" />
											) : (
												<>
													<p>{subscriber?.email || "(no email)"}</p>
													{!!subscriber?.id && <p>{subscriber?.id}</p>}
												</>
											)}
										</TableCell>
										{!vendor && (
											<TableCell>
												<div className="flex space-x-2">
													<Tooltip text="Eliminar usuario del cupo (bloquear cupo)" placement="top">
														<Button size={"sm"} variant={"outline"} onClick={onRemoveUser} disabled={isCSL1}>
															<Lock className="h-4 w-4" />
														</Button>
													</Tooltip>

													<Tooltip text="Resetear cupo" placement="top">
														<Button size={"sm"} variant={"outline"} onClick={onResetSlot} disabled={isCSL1}>
															<Refresh className="h-4 w-4" />
														</Button>
													</Tooltip>
												</div>
											</TableCell>
										)}
									</>
								)}
							</TableRow>
						);
					})
				)}
			</TableBody>
		</Table>
	);
}
