import {Settings} from "../../../../primitives/icons/Settings";
import NavGroup from "../../subcomponents/NavGroup";
import NavLink from "../../subcomponents/NavLink";

export function CorporateNavigationSection() {
	return (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			<NavLink to="/corporate/users" label="Usuarios" />
			<NavLink to="/corporate/influencer" label="Asociados" />
			<NavLink to="/corporate/partner" label="Partners" />
			<NavLink to="/corporate/sponsor" label="Sponsors" />
			<NavGroup title="Marketing" route="/corporate/marketing">
				<NavLink to="corporate/marketing" label="Dashboard" />
				<NavLink to="corporate/marketing-notifications" label="Notificaciones de Contenidos" />
				<NavLink to="marketing-custom-notifications" label="Notificaciones Personalizadas" />
				<NavLink to="/users/odoo" label="Odoo" />
			</NavGroup>
			<NavGroup title="Customer Service" route="/corporate/customer-service">
				<NavLink to="/corporate/customer-service/contact" label="Contacto" />
				<NavLink to="/corporate/customer-service/comments/list" label="Comentarios" />
				<NavLink to="/corporate/customer-service/users/list" label="Usuarios" />
				<NavLink to="/customer-service/user-chat" label="Chat de Usuario" />
			</NavGroup>
			<NavGroup title="Reseñas" route="/reviews">
				<NavLink to="/reviews/overview" label="Análisis de reseñas" />
				<NavLink to="/reviews/list" label="Reseñas" />
			</NavGroup>
			<NavGroup title="Estadísticas" route="/corporate/">
				<NavLink to="/corporate/performance" label="Performance" />
				<NavLink to="/corporate/expenses" label="Gastos" />
			</NavGroup>
			<NavGroup title="Ajustes" route="/settings" icon={<Settings height={20} />}>
				<NavLink to="/settings/programmed-tasks" label="Listar Tareas" />
			</NavGroup>
		</div>
	);
}
