import {useMemo} from "react";
import {useAppSelector} from "../../_store/hooks";
import {ROLES} from "../../data-access/fetch-db-users";
import {USER_ROLES} from "../../constants";

export default function useCurrentUserRole() {
	const currentUserInfo = useAppSelector(state => state.user.userInfo);

	const value = useMemo(() => {
		let activeRole: ROLES | null = null;

		if (!currentUserInfo) {
			return {activeRole, isCSL1: false};
		}

		const availableRoles = USER_ROLES.reduce((o, key) => {
			return Object.assign(o, {[key]: currentUserInfo[key]});
		}, {} as {[key in ROLES]?: boolean});

		for (let [role, status] of Object.entries(availableRoles)) {
			if (status) {
				activeRole = role as ROLES;
			}
		}

		return {activeRole, isCSL1: activeRole === "customerservice_l1"};
	}, [currentUserInfo]);

	return value;
}
