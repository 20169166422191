import {AuthErrorCodes} from "@firebase/auth";

export const ERRORS: Record<string, string> = {
	[AuthErrorCodes.INVALID_EMAIL]: "The email provided is invalid",
	[AuthErrorCodes.USER_DELETED]: "The provided user cannot be found",
	[AuthErrorCodes.EMAIL_EXISTS]: "The provided email is already in use",
	[AuthErrorCodes.INVALID_PASSWORD]: "The provided password is wrong",
	"auth/email-already-exists": "El email introducido ya se encuentra en uso por otro usuario.",
	"auth/invalid-profile-attribute": "The username cannot be longer than 60 characters",
	default: "An error occurred please try again",
};
