import {useMemo} from "react";
import {ROLES} from "../../data-access/fetch-db-users";
import {dbUser} from "../../_store/features/user-db/user-db-slice";
import {USER_ROLES} from "../../constants";

export default function useUserRole(userInfo?: dbUser) {
	const value = useMemo(() => {
		let activeRole: ROLES | null = null;

		if (!userInfo) {
			return activeRole;
		}

		const availableRoles = USER_ROLES.reduce((o, key) => {
			return Object.assign(o, {[key]: userInfo[key]});
		}, {} as {[key in ROLES]?: boolean});

		for (let [role, status] of Object.entries(availableRoles)) {
			if (status) {
				activeRole = role as ROLES;
			}
		}

		return activeRole;
	}, [userInfo]);

	return value;
}
