import {
	ColumnDef,
	ColumnFiltersState,
	OnChangeFn,
	PaginationState,
	SortingState,
	flexRender,
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import {ForwardedRef, forwardRef, useLayoutEffect, useMemo, useRef, useState} from "react";
import {cn} from "../../../../utils/classNames";
import {DataTablePagination, DataTableViewOptions} from "../../primitives/DataTable";
import {Spinner} from "../../primitives/icons/Spinner";

declare module "react" {
	function forwardRef<T, P = {}>(
		render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
	): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

interface TableProps<T> {
	columns: ColumnDef<T, any>[];
	dataset: T[];
	alternativeNoContentText?: string;
	pageCount: number;
	pagination?: PaginationState;
	onPaginationChange?: OnChangeFn<PaginationState>;
	loading?: boolean;
	withDynamicPageSize?: boolean;
	rowHeight?: number;
	manualPagination?: boolean;
	showPagination?: boolean;
	totalItems?: number;
	/**
	 * @deprecated The method should not be used
	 */
	pageSizes?: number[];
	tableAvailableHeight?: number;
}

export const DataTable = forwardRef(<T,>(props: TableProps<T>, ref?: ForwardedRef<HTMLDivElement | null>) => {
	const {
		dataset,
		columns,
		pagination,
		pageCount,
		loading,
		onPaginationChange,
		withDynamicPageSize,
		rowHeight,
		totalItems,
		showPagination = true,
		manualPagination = true,
		tableAvailableHeight,
		alternativeNoContentText,
	} = props;

	const data = useMemo(() => dataset, [dataset]);
	const memoPagination = useMemo(() => pagination, [pagination]);

	const tableBodyRef = useRef<HTMLDivElement>(null);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const table = useReactTable({
		data,
		columns,
		columnResizeMode: "onChange",
		pageCount: pageCount,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		state: {
			sorting,
			columnFilters,
			pagination: memoPagination,
		},
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onPaginationChange: onPaginationChange,
		manualPagination,
	});

	useLayoutEffect(() => {
		if (!tableBodyRef.current) return;

		if (withDynamicPageSize) {
			if (!rowHeight) throw Error("Tables withDynamicPageSize depend on the `rowHeight` property");

			if (!tableAvailableHeight) {
				const {height} = tableBodyRef.current.getBoundingClientRect();
				table.setPageSize(Math.floor(height / rowHeight));
			} else {
				table.setPageSize(Math.floor(tableAvailableHeight / rowHeight));
			}
		}
	}, [rowHeight, table, tableAvailableHeight, withDynamicPageSize]);

	return (
		<div ref={ref} className="flex h-4/5 grow flex-col space-y-4">
			<div className="flex h-full flex-col overflow-x-auto overflow-y-hidden rounded-md border bg-background scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
				<div className="border-b" style={{width: table.getTotalSize(), minWidth: "100%"}}>
					{table.getHeaderGroups().map(headerGroup => (
						<div className="flex h-10" key={headerGroup.id}>
							{headerGroup.headers.map(header => (
								<div
									key={header.id}
									style={{
										width: header.getSize(),
									}}
									className="relative flex h-full items-center overflow-hidden text-ellipsis whitespace-nowrap px-2"
								>
									<div className="flex text-sm font-medium">
										{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
									</div>
									{header.column.getCanResize() ? (
										<div
											onMouseDown={header.getResizeHandler()}
											onTouchStart={header.getResizeHandler()}
											className={cn(
												"absolute right-0 h-1/2 w-1 cursor-col-resize rounded-full",
												"touch-none select-none",
												"bg-foreground opacity-10 transition-all duration-200 hover:opacity-100",
												{
													"opacity-100": header.column.getIsResizing(),
												},
											)}
										/>
									) : null}
								</div>
							))}
						</div>
					))}
				</div>
				<div
					id="table-body"
					ref={tableBodyRef}
					className="h-full w-fit overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500"
					style={{width: table.getTotalSize(), minWidth: "100%"}}
				>
					{loading ? (
						<span className="flex h-full items-center  justify-center">
							<Spinner />
						</span>
					) : data.length === 0 ? (
						<div className="flex h-full w-full flex-col items-center justify-center">
							{alternativeNoContentText ? (
								<div className="w-80 text-left text-lg font-bold">{alternativeNoContentText}</div>
							) : (
								<>
									<div className="w-80 text-left text-lg font-bold">No hay datos</div>
									<span className="w-80 text-justify">
										No encontramos ningún dato. Por favor cambia los términos de búsqueda. Si por el contrario piensas que esto es
										un error contacta con soporte.
									</span>{" "}
								</>
							)}
						</div>
					) : (
						table.getRowModel().rows.map(row => (
							<div key={row.id} className="row flex items-center border-b bg-accent/10 odd:bg-accent">
								{row.getVisibleCells().map(cell => (
									<div
										key={cell.id}
										style={{
											width: cell.column.getSize(),
											height: rowHeight ?? "auto",
										}}
										className="flex justify-center p-2 text-center text-sm "
									>
										{typeof cell.getValue() === "string" ? (
											<span className="w-full truncate">{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
										) : (
											flexRender(cell.column.columnDef.cell, cell.getContext())
										)}
									</div>
								))}
							</div>
						))
					)}
				</div>
			</div>
			<div className="flex justify-between">
				<DataTableViewOptions table={table} />
				<DataTablePagination table={table} showPagination={showPagination} totalItems={totalItems} />
			</div>
		</div>
	);
});
