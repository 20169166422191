import {DateRange} from "react-day-picker";
import {useRefreshViewMutation} from "../../../../_store/features/feedback/api";
import {Button} from "../../../../components/primitives/Button";
import {Refresh, Spinner} from "../../../../components/primitives/icons";
import {Period} from "../../types";
import {useEffect} from "react";
import toast from "react-hot-toast";

interface RefreshViewProps {
	dates: DateRange | undefined;
	period: Period;
}

export function RefreshView({dates, period: group_by}: RefreshViewProps) {
	const [updateView, {isError, isLoading}] = useRefreshViewMutation();

	const start_date = dates?.from?.toISOString().split("T")[0];
	const end_date = dates?.to?.toISOString().split("T")[0];

	useEffect(() => {
		if (isError) {
			toast.error("Error al refrescar los datos");
		}
	}, [isError]);

	return (
		<Button onClick={() => updateView({start_date, end_date, group_by})} variant={"blueBtn"} size={"sm"} type={"button"} disabled={isLoading}>
			<div className="flex items-center space-x-2">
				{isLoading ? <Spinner /> : <Refresh className="h-4 w-4" />}
				<span className="flex items-center text-sm font-medium">Actualizar</span>
			</div>
		</Button>
	);
}
