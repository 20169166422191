import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {MultiValue} from "react-select";
import {useEffect, useMemo, useRef, useState} from "react";
import {UsersBySeriesViews, getSeriesViewsByUser} from "../../../data-access/marketing/marketing";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {Checkbox} from "../../../components/primitives/Checkbox";
import {Button} from "../../../components/primitives/Button";
import {DownloadList, Lock, Refresh} from "../../../components/primitives/icons";
import {Unlock} from "../../../components/primitives/icons/Unlock";
import {DataTable} from "../../../components/blocks/DataTable";
import UncheckVerifiedDialog from "../../dashboard/role-based/marketing/_subcomponents/UncheckVerifiedDialog";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {seriesActions} from "../../../_store/features/series/series-slice";
import {SelectOptionType} from "../../../../utils/globalTypes";
import {Content, GetContentsResponse} from "../../../data-access/series/content";
import {serieContentsActions} from "../../../_store/features/serie-content/serie-content-slice";
import SelectContentBySerie from "./_subcomponents/SelectContentBySerie";
import MarketingNotificationModal from "./_subcomponents/MarketingNotificationModal";
import {marketingActions} from "../../../_store/features/marketing/marketing-slice";
import {auth} from "../../../firebase";
import toast from "react-hot-toast";
import {downloadFile} from "../../../../utils/downloadXLSX";

const columnHelper = createColumnHelper<UsersBySeriesViews>();

export default function MarketingNotifications() {
	const dispatch = useAppDispatch();
	const users = useAppSelector(state => state.marketing);
	const series = useAppSelector(state => state.series);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLocked, setIsLocked] = useState(true);
	const [open, setOpen] = useState(false);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const selectInputRefs = useRef<any[]>([]);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [firstSelectedSerie, setFirstSelectedSerie] = useState<MultiValue<SelectOptionType> | undefined>(undefined);
	const [secondSelectedSerie, setSecondSelectedSerie] = useState<MultiValue<SelectOptionType> | undefined>(undefined);
	const [firstSelectedContent, setFirstSelectedContent] = useState<MultiValue<SelectOptionType> | undefined>(undefined);
	const [secondSelectedContent, setSecondSelectedContent] = useState<MultiValue<SelectOptionType> | undefined>(undefined);
	const [firstSerieContents, setFirstSerieContents] = useState<Content[] | undefined>(undefined);
	const [secondSerieContents, setSecondSerieContents] = useState<Content[] | undefined>(undefined);
	const [isPhoneCheck, setIsPhoneCheck] = useState(true);
	const [isEmailCheck, setIsEmailCheck] = useState(true);
	const [verificationFilters, setVerificationFilters] = useState<{phone: boolean; email: boolean}>({
		email: true,
		phone: true,
	});

	const [isContentsLoading, setIsContentsLoading] = useState(false);

	const fetchUsersData = (reset?: boolean) => {
		const firstContent = firstSelectedContent?.map(i => i.value).join(",");
		const secondContent = secondSelectedContent?.map(i => i.value).join(",");
		if (reset) {
			dispatch(
				marketingActions.getSeriesViewsByUserAction({
					email_verified: true,
					phone_verified: true,
					page: pagination.pageIndex,
					page_size: pagination.pageSize,
				}),
			);
			return;
		}
		dispatch(
			marketingActions.getSeriesViewsByUserAction({
				content_id_list: firstContent,
				not_content_id_list: secondContent,
				email_verified: isLocked || verificationFilters.email ? isEmailCheck : undefined,
				phone_verified: isLocked || verificationFilters.phone ? isPhoneCheck : undefined,
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				final_date: undefined,
				initial_date: undefined,
			}),
		);
	};

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page_size: 999999}}));
	}, [dispatch]);

	useEffect(() => {
		if (!pagination.pageSize) return;
		fetchUsersData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination.pageSize, pagination.pageIndex]);

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 3);

		return [
			columnHelper.accessor("name", {
				id: "Usuario",
				header: ({column}) => <DataTableColumnHeader title="Usuario" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("email", {
				id: "Correo",
				header: ({column}) => <DataTableColumnHeader title="Correo" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.email}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("phone", {
				id: "Teléfono",
				header: ({column}) => <DataTableColumnHeader title="Teléfono" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.phone}</span>
					</div>
				),
				size: colWidth,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const handleCheckPhone = () => {
		setIsPhoneCheck(val => !val);
		setVerificationFilters({...verificationFilters, phone: true});
	};
	const handleCheckEmail = () => {
		setIsEmailCheck(val => !val);
		setVerificationFilters({...verificationFilters, email: true});
	};
	const handleIsLocked = () => {
		setIsLocked(val => !val);
	};

	const handleIsOpen = () => setOpen(val => !val);
	const handleUnlockCheckbox = () => {
		if (isLocked) {
			setOpen(!open);
		} else {
			setIsLocked(!isLocked);
			setIsEmailCheck(true);
			setIsPhoneCheck(true);
		}
	};

	const onRefresh = () => {
		selectInputRefs.current.forEach(ref => ref && ref.clearValue());
		setFirstSerieContents(undefined);
		setSecondSerieContents(undefined);
		setFirstSelectedSerie(undefined);
		setSecondSelectedSerie(undefined);
		setFirstSelectedContent(undefined);
		setSecondSelectedContent(undefined);
		fetchUsersData(true);
	};

	const onApplyFilter = () => {
		if (firstSelectedContent && secondSelectedContent) {
			const error = firstSelectedContent?.filter(o => secondSelectedContent?.some(({value}) => o.value === value));
			if (error?.length !== 0) {
				toast.error("No puede comparar las vistas de un mismo contenido");
				return;
			}
		}
		fetchUsersData();
	};

	const seriesOptions: SelectOptionType[] = useMemo(() => {
		const options = series.results.map(serie => ({label: serie.title, value: serie.id}));
		const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));
		return sortedOptions;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [series.results.length]);

	const firstSeriesContentsOptions: SelectOptionType[] = useMemo(() => {
		if (!firstSerieContents) return [];
		const options = firstSerieContents.filter(i => i.content_type === 0).map(serie => ({label: serie.title, value: serie.id}));
		return options.reverse();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstSelectedSerie, firstSerieContents]);

	const secondSeriesContentsOptions: SelectOptionType[] = useMemo(() => {
		if (!secondSerieContents) return [];
		const options = secondSerieContents.filter(i => i.content_type === 0).map(serie => ({label: serie.title, value: serie.id}));
		return options.reverse();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [secondSelectedSerie, secondSerieContents]);

	const handleSelectSerie = (newValue: MultiValue<SelectOptionType>, selectFor: "first_serie" | "second_serie") => {
		if (!newValue) return;
		setIsContentsLoading(true);
		if (newValue.length === 0) {
			if (selectFor === "first_serie") {
				setFirstSelectedSerie(undefined);
			} else {
				setSecondSelectedSerie(undefined);
			}
		}
		const seriesids = newValue.map(el => el.value).join(",");
		if (seriesids.length === 0) return setIsContentsLoading(false);
		dispatch(serieContentsActions.listSeriesContent({serieid_list: seriesids, page_size: 999999}))
			.then(res => {
				if (res.meta.requestStatus === "rejected" || !res.payload) return;
				const data = res.payload as GetContentsResponse;
				if (selectFor === "first_serie") {
					setFirstSelectedSerie(newValue);
					setFirstSerieContents(data.results);
					return;
				}
				setSecondSelectedSerie(newValue);
				setSecondSerieContents(data.results);
			})
			.finally(() => setIsContentsLoading(false));
	};

	const handleSelectContent = (newValue: MultiValue<SelectOptionType>, selectFor: "first_serie" | "second_serie") => {
		if (!newValue) return;
		if (selectFor === "first_serie") {
			setFirstSelectedContent(newValue);
			return;
		}
		setSecondSelectedContent(newValue);
	};

	const isDisableApplyButton =
		isLocked && (!firstSelectedContent || firstSelectedContent.length === 0 || !secondSelectedContent || secondSelectedContent.length === 0);

	const handleExcelDownload = () => {
		auth.currentUser
			?.getIdToken()
			.then(token => {
				getSeriesViewsByUser(
					token,
					{
						content_id_list: firstSelectedContent?.map(i => i.value).join(","),
						not_content_id_list: secondSelectedContent?.map(i => i.value).join(","),
						email_verified: isLocked || verificationFilters.email ? isEmailCheck : undefined,
						phone_verified: isLocked || verificationFilters.phone ? isPhoneCheck : undefined,
						page: 0,
						page_size: 999999,
						output_excel: true,
					},
					true,
				)
					.then(res => {
						downloadFile(res.data, "MarketingDashboard");
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="flex flex-col">
				<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
					<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Notificaciones</h2>
				</div>
				<div className="flex w-full justify-between space-x-40 px-4 py-7">
					<div className="flex w-full max-w-md flex-col gap-2">
						<h3 className="text-start text-xl font-semibold leading-none tracking-tight">Usuarios que han visto:</h3>
						<SelectContentBySerie
							series={seriesOptions}
							contents={firstSeriesContentsOptions ?? []}
							isSerieLoading={series.loading}
							isContentsLoading={isContentsLoading}
							disableSelectContent={!firstSelectedSerie}
							handleSerieSelectRef={ref => {
								selectInputRefs.current[0] = ref;
							}}
							handleContentSelectRef={ref => {
								selectInputRefs.current[1] = ref;
							}}
							handleSelectSerie={prop => handleSelectSerie(prop, "first_serie")}
							handleSelectContent={prop => handleSelectContent(prop, "first_serie")}
						/>
					</div>
					<div className="flex w-full max-w-md flex-col gap-2">
						<h3 className="text-start text-xl font-semibold leading-none tracking-tight">No han visto:</h3>
						<SelectContentBySerie
							series={seriesOptions}
							contents={secondSeriesContentsOptions ?? []}
							isSerieLoading={series.loading}
							isContentsLoading={isContentsLoading}
							disableSelectContent={!secondSelectedSerie}
							handleSerieSelectRef={ref => {
								selectInputRefs.current[2] = ref;
							}}
							handleContentSelectRef={ref => {
								selectInputRefs.current[3] = ref;
							}}
							handleSelectSerie={prop => handleSelectSerie(prop, "second_serie")}
							handleSelectContent={prop => handleSelectContent(prop, "second_serie")}
						/>
					</div>
					<div className="flex flex-col gap-5">
						<div className="flex gap-5">
							<Button
								className="h-9 w-32 bg-black text-white"
								size={"sm"}
								variant={"outline"}
								onClick={onApplyFilter}
								disabled={isDisableApplyButton}
							>
								Aplicar
							</Button>
							<Button className="h-9" size={"sm"} variant={"outline"} onClick={onRefresh}>
								<Refresh className="h-4 w-4" />
							</Button>
						</div>
						<MarketingNotificationModal
							disabled={isDisableApplyButton}
							series={seriesOptions}
							data={{
								email_verified: isEmailCheck,
								phone_verified: isPhoneCheck,
								content_id_list: firstSelectedContent?.map(i => i.value).join(",") || undefined,
								not_content_id_list: secondSelectedContent?.map(i => i.value).join(",") || undefined,
							}}
							open={isModalOpen}
							setOpen={setIsModalOpen}
						/>
						<Button variant="outline" size="sm" className="h-8 whitespace-nowrap font-normal lg:flex" onClick={handleExcelDownload}>
							<DownloadList className="mr-2 h-4 w-4" />
							Exportar Excel
						</Button>
					</div>
				</div>
				<div className="flex flex-row justify-between">
					<div className="flex flex-row">
						<div className="relative mx-4">
							{verificationFilters.phone || isLocked ? (
								<div className="flex max-h-9 flex-row items-center space-x-3 space-y-0 rounded-md border p-3">
									{!isLocked && (
										<Button
											variant="outline"
											className="absolute -right-3 -top-3 h-6 w-6 rounded-full border bg-white px-1"
											onClick={() => setVerificationFilters({...verificationFilters, phone: false})}
										>
											&#x2715;
										</Button>
									)}
									<Checkbox checked={!isLocked ? isPhoneCheck : isLocked} onCheckedChange={handleCheckPhone} disabled={isLocked} />
									<div className="space-y-1 leading-none">
										<div className="text-sm">Teléfono validado</div>
									</div>
								</div>
							) : (
								<Button
									variant="outline"
									className="flex h-9 flex-row items-center space-x-3 space-y-0 rounded-md border p-3"
									onClick={() => {
										setIsPhoneCheck(true);
										setVerificationFilters({...verificationFilters, phone: true});
									}}
									disabled={isLocked}
								>
									<div className="flex h-6 w-6 rotate-45 items-center justify-center rounded-full border">&#x2715;</div>
									<div className="space-y-1 leading-none">
										<div className="text-sm">Añadir teléfono</div>
									</div>
								</Button>
							)}
						</div>
						<div className="relative mx-4">
							{verificationFilters.email || isLocked ? (
								<div className="flex max-h-9 flex-row items-center space-x-3 space-y-0 rounded-md border p-3">
									{!isLocked && (
										<Button
											variant="outline"
											className="absolute -right-3 -top-3 h-6 w-6 rounded-full border bg-white px-1"
											onClick={() => setVerificationFilters({...verificationFilters, email: false})}
										>
											&#x2715;
										</Button>
									)}
									<Checkbox checked={!isLocked ? isEmailCheck : isLocked} disabled={isLocked} onCheckedChange={handleCheckEmail} />
									<div className="space-y-1 leading-none">
										<div className="text-sm">Correo validado</div>
									</div>
								</div>
							) : (
								<Button
									variant="outline"
									className="flex h-9 flex-row items-center space-x-3 space-y-0 rounded-md border p-3"
									onClick={() => {
										setIsEmailCheck(true);
										setVerificationFilters({...verificationFilters, email: true});
									}}
									disabled={isLocked}
								>
									<div className="flex h-6 w-6 rotate-45 items-center justify-center rounded-full border">&#x2715;</div>
									<div className="space-y-1 leading-none">
										<div className="text-sm">Añadir correo</div>
									</div>
								</Button>
							)}
						</div>
						<Button className="mx-4 h-9" size={"sm"} variant={"outline"} onClick={handleUnlockCheckbox}>
							{isLocked ? <Lock className="h-5 w-5" /> : <Unlock />}
						</Button>

						{!isLocked && (
							<span className="self-center text-center text-sm font-semibold uppercase text-red-700">
								Opción desbloqueada no recomendada
							</span>
						)}
					</div>
				</div>
				<div className="flex items-center justify-between border-b border-b-border bg-background p-4"></div>

				<div className="mt-7 flex flex-row justify-between">
					<div className="mx-4 flex flex-row items-start">
						<span className="text-ellipsis text-center font-bold uppercase">Total de registros: </span>
						<span className="ml-2 text-ellipsis text-center font-bold uppercase">{users.totalResults}</span>
					</div>
				</div>
			</div>

			<div className="flex h-full  grow-0 flex-col p-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={(users.results as UsersBySeriesViews[]) ?? []}
					pageCount={Math.ceil(users.totalResults / users.pageSize)}
					pagination={pagination}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={40}
					showPagination={false}
					loading={users.loading}
				/>
			</div>
			<UncheckVerifiedDialog
				open={open}
				onDismiss={handleIsOpen}
				onSuccess={() => {
					handleIsLocked();
					handleIsOpen();
				}}
			/>
		</div>
	);
}
