import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {SeriesRankingParams, getSeriesRanking} from "../../../data-access/series/series";
import {auth} from "../../../firebase";

export interface seriesRankingInterface {
	loading: boolean;
	totalResults: number;
	pageSize: number;
	current: number;
	results: SeriesRanking[];
}

export interface SeriesRanking {
	id: string;
	name: string;
	views: number;
	likes: number;
	follows: number;
	memberships: number;
	rank_number: number;
	photo_url: string;
}

const initialState: seriesRankingInterface = {
	loading: false,
	totalResults: 0,
	pageSize: 0,
	current: 0,
	results: [],
};

const getSeriesListRanking = createAsyncThunk("series-ranking/list", async (params: SeriesRankingParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");
		const response = await getSeriesRanking(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const seriesRankingSlice = createSlice({
	name: "series",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getSeriesListRanking.pending, state => {
				state.loading = true;
			})
			.addCase(getSeriesListRanking.fulfilled, (state, action) => {
				state.loading = false;
				state.current = action.payload.current;
				state.pageSize = action.payload.pageSize;
				state.totalResults = action.payload.totalResults;
				state.results = [...action.payload.results];
			})
			.addCase(getSeriesListRanking.rejected, (state, action) => {});
	},
});

export const seriesRankingActions = {...seriesRankingSlice.actions, getSeriesListRanking};

export default seriesRankingSlice;
