import {getApp, getApps, initializeApp} from "firebase/app";
import {initializeAuth, browserLocalPersistence, getAuth} from "firebase/auth";

if (getApps().length < 1) {
	const app = initializeApp({
		apiKey: process.env.REACT_APP_API_KEY,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	});
	initializeAuth(app, {persistence: browserLocalPersistence});
}

export const app = getApp();
export const auth = getAuth(app);
auth.tenantId = process.env.REACT_APP_TENANT_ID_ADMIN!;
