import {Button} from "../../primitives/Button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../../primitives/Command/command";
import {Popover, PopoverContent, PopoverTrigger} from "../../primitives/Popover/popover";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";

import {addMilliseconds, isAfter} from "date-fns";
import {crewActions} from "../../../_store/features/crew/crew-slice";
import {PlusSmall, Spinner} from "../../primitives/icons";
import {Staff} from "../../../data-access/series/staff";
import {useEffect, useState} from "react";

const DEBOUNCE_TIME = 1000;

interface ProfessionSelectProps {
	onStaffSelect: (profession: Staff) => void;
	selectedStaff?: Staff[];
	disabled?: boolean;
	profession?: string;
}

export function StaffSelect({onStaffSelect, selectedStaff, disabled, profession}: ProfessionSelectProps) {
	const isStaffLoading = useAppSelector(state => state.crew.staffLoading);
	const staffLastFetch = useAppSelector(state => state.crew.staffLastFetch);
	const staff = useAppSelector(state => state.crew.staffResults);
	const [updatedStaff, setUpdatedStaff] = useState<Staff[]>([]);

	const dispatch = useAppDispatch();

	const handleOpen = () => {
		if (!isStaffLoading && isAfter(Date.now(), addMilliseconds(staffLastFetch ?? 0, DEBOUNCE_TIME))) {
			dispatch(crewActions.getStaffList({params: {page: 0, page_size: 99999999}}));
		}
	};

	useEffect(() => {
		const membersInProfession = staff.filter(category => category.professions?.find(p => p.profession === profession));
		const staffWithoutProfession = staff.filter(category => !membersInProfession.includes(category));

		const updatedStaff = [...membersInProfession, ...staffWithoutProfession];
		setUpdatedStaff(updatedStaff);
	}, [staff, profession]);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button variant="outline" onClick={handleOpen}>
					<PlusSmall className="mr-2 h-5 w-5" />
					Añadir staff
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[200px] p-0" align="start">
				<Command>
					<CommandInput placeholder={"Filtrar..."} />
					<CommandList>
						{isStaffLoading && (
							<CommandItem className="flex items-center justify-center">
								<Spinner />
							</CommandItem>
						)}
						<CommandEmpty>No hay staff.</CommandEmpty>
						<CommandGroup>
							{updatedStaff
								.filter(p => !selectedStaff?.some(sp => sp.id === p.id))
								.map(staff => {
									return (
										<CommandItem key={staff.id} onSelect={() => onStaffSelect(staff)} disabled={disabled}>
											<div className="flex w-full flex-col">
												<div>{staff.fullname}</div>
												<div className="truncate text-xs text-muted-foreground">{staff.id}</div>
											</div>
										</CommandItem>
									);
								})}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
