import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {adminNotifyViaEmail} from "../../../../data-access/notify";
import {auth} from "../../../../firebase";
import {
	adminMigrateToSuperTokens,
	adminUpdatePasswordData,
	createAdminPasswordRequestData,
	fetchDbUsersData,
} from "../../../../_store/features/user-db/user-db-actions";
import {dbUser, editInitialState, userDbActions} from "../../../../_store/features/user-db/user-db-slice";
import {useAppSelector, useAppDispatch} from "../../../../_store/hooks";
import {ReCaptchaComponent} from "../../../../components/primitives/ReCaptcha";
import {captchaVisibility} from "../../../../../utils/captchaVisibility";
import useUserRole from "../../../../components/hooks/useUserRole";
import toast from "react-hot-toast";
import {USER_ROLES} from "../../../../constants";
import {ROLES} from "../../../../data-access/fetch-db-users";

const RECAPTCHA_ACTION = "PasswordForm";

function PasswordManagement() {
	let {id} = useParams();
	const usersDb = useAppSelector(state => state.dbuser);
	const initialUser = useAppSelector(state => state.dbuser.results.find(el => el.id === id));
	const emailSended = useAppSelector(state => state.dbuser.sendingPasswordEmail);
	const migrating = useAppSelector(state => state.dbuser.migrating);
	const isSuccess = useAppSelector(state => state.dbuser.success);
	const successMessage = useAppSelector(state => state.dbuser.successMessage);
	const error = useAppSelector(state => state.dbuser.error);
	const selectedUserRole = useUserRole(initialUser);
	const [editUser, setEditUser] = useState<dbUser>(editInitialState);
	const [password, setPassword] = useState<string>("");
	const [notifyPasswordChange, setNotifyPasswordChange] = useState(false);
	const [recaptchaToken, setRecaptchaToken] = useState<string>();
	const dispatch = useAppDispatch();

	useEffect(() => {
		setEditUser(initialUser!);
	}, [initialUser]);

	useEffect(() => {
		return () => {
			captchaVisibility(false);
		};
	}, [dispatch]);

	useEffect(() => {
		if (!emailSended && isSuccess && !successMessage) {
			toast.success("Correo enviado satisfactoriamente!");
		} else if (error) {
			toast.error(error);
		} else if (successMessage) {
			toast.success(successMessage);
		}
		return () => {
			dispatch(userDbActions.setSuccessMessage(undefined));
			dispatch(userDbActions.setSuccess(false));
			dispatch(userDbActions.setError(undefined));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailSended, error, isSuccess, successMessage]);

	const handleSendRequest = async () => {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return toast.error("No hay token de autenticación");
		if (!editUser) return toast.error("Usuario no válido");

		recaptchaToken &&
			dispatch(
				createAdminPasswordRequestData(
					`Bearer ${token}`,
					editUser.email,
					recaptchaToken,
					RECAPTCHA_ACTION,
					selectedUserRole ? USER_ROLES.includes(selectedUserRole as ROLES) : undefined,
				),
			);
	};

	const handleResetPassword = () => {
		if (!/.*[A-Z].*/.test(password) || !/.*[\W_].*/.test(password) || password.length < 6) {
			return toast.error("La contraseña debe tener al menos 6 caracteres, incluir una letra mayúscula y un carácter especial.");
		}

		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(adminUpdatePasswordData(idToken, editUser.id, password, selectedUserRole ? USER_ROLES.includes(selectedUserRole) : false));
			if (notifyPasswordChange) {
				adminNotifyViaEmail(idToken, {
					recipient: editUser.email,
					subject: "Contraseña cambiada forzosamente.",
					notification: "Notificación de cambio de contraseña, tu nueva contraseña es: " + password,
				});
			}
			setPassword("");
		});
	};

	const handleMigrateToSupertokens = async (firebaseid: string) => {
		await auth.currentUser?.getIdToken().then(idToken => {
			dispatch(adminMigrateToSuperTokens({idToken, firebaseid})).then(() => {
				dispatch(fetchDbUsersData({idToken, page: 0, page_size: 1, searchby: "id", searchvalue: editUser.id, admin: false}));
			});
		});
	};

	return (
		<div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-60 text-right font-semibold text-gray-900">Correo Electrónico:</div>
				<span className={"font-semibold" + (editUser?.disabled ? " text-red-500" : " text-green-500")}>{editUser?.email}</span>
			</div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-60 text-right font-semibold text-gray-900">Correo restablecer contraseña:</div>
				<button
					data-modal-toggle="defaultModal"
					type="button"
					disabled={usersDb.sendingPasswordEmail}
					className="rounded-lg bg-green-600 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-green-800 disabled:opacity-30 hover:bg-green-700 disabled:hover:bg-green-600"
					onClick={handleSendRequest}
				>
					Enviar
				</button>
			</div>
			<div className="border-b border-gray-200 py-3">
				<div className="flex items-center">
					<div className="mx-3 inline w-60 text-right font-semibold text-gray-900">Forzar Contraseña:</div>
					<input
						type="password"
						value={password}
						className="mr-2 w-96 flex-1 rounded-lg border-2 border-gray-600 px-3 py-2 outline-0"
						placeholder="Contraseña"
						onChange={event => {
							setPassword(event.target.value);
						}}
					/>
					<div>
						<button
							data-modal-toggle="defaultModal"
							type="button"
							disabled={password.length < 6 || usersDb.editing}
							className="mr-3 rounded-lg bg-green-600 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-green-800 disabled:opacity-30 hover:bg-green-700 disabled:hover:bg-green-600"
							onClick={handleResetPassword}
						>
							Establecer
						</button>
					</div>
				</div>
				<div className="ml-60 flex items-center pl-6">
					<input
						id="published"
						name="published"
						type="checkbox"
						className="h-4 w-4"
						checked={notifyPasswordChange}
						onChange={e => setNotifyPasswordChange(prev => !prev)}
					/>
					<label className="select-none pl-2" htmlFor="published">
						Enviar notificación via Email
					</label>
				</div>
			</div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-60 text-right font-semibold text-gray-900">Provider:</div>
				<p>
					{editUser?.firebaseid && "Firebase"} {editUser?.supertokenid && " Supertokens"}
				</p>
				{editUser?.firebaseid && !selectedUserRole && (
					<button
						data-modal-toggle="defaultModal"
						type="button"
						disabled={migrating}
						className="ml-4 rounded-lg bg-green-600 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-green-800 disabled:opacity-30 hover:bg-green-700 disabled:hover:bg-green-600"
						onClick={() => !!editUser.firebaseid && handleMigrateToSupertokens(editUser.firebaseid)}
					>
						{migrating ? "Migración en curso" : "Migrar a Supertokens"}
					</button>
				)}
			</div>
			<ReCaptchaComponent setToken={setRecaptchaToken} action={RECAPTCHA_ACTION} />
		</div>
	);
}

export default PasswordManagement;
