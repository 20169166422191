import {signInWithEmailAndPassword} from "firebase/auth";
import {useState, FormEvent} from "react";
import {Link, useNavigate} from "react-router-dom";
import {validateEmail} from "../../../utils/validators";
import {Close, Lock, User} from "../../components/primitives/icons";
import {auth} from "../../firebase";

interface FormFields {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

function SignIn() {
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const {email, password} = e.currentTarget.elements as unknown as FormFields;

		if (email.value === "") {
			return setError("Email cannot be empty");
		}

		if (!validateEmail(email.value)) {
			return setError("Email is Invalid");
		}

		if (password.value === "") {
			return setError("Password cannot be empty");
		}

		if (password.value.length < 6) {
			return setError("Password must be above 6 characters long");
		}

		signInWithEmailAndPassword(auth, email.value, password.value)
			.then(() => {
				navigate("/");
			})
			.catch((error: any) => {
				setError(error.code);
			});

		setError("");
		e.currentTarget.reset();
	};

	return (
		<div className="flex h-screen w-screen items-center justify-center bg-neutral-200">
			<div className="grid grid-cols-2 overflow-hidden rounded-md bg-white">
				<div className="flex flex-col p-10">
					{error !== "" && (
						<div className="flex w-full flex-grow-0 items-center justify-between rounded border border-red-400 bg-red-200 px-4 py-2 text-sm">
							<span>{error}</span>
							<button onClick={() => setError("")}>
								<Close className="h-4 w-4" />
							</button>
						</div>
					)}
					<form className="mt-10 flex h-full flex-col" onSubmit={handleSubmit}>
						<div className="mb-4 flex items-center overflow-hidden rounded-[4px] border border-solid border-neutral-400 focus-within:outline">
							<div className="flex aspect-square h-10 w-10 flex-shrink-0 items-center justify-center border-r border-neutral-400 bg-neutral-300">
								<User className="h-4 w-4" />
							</div>
							<input
								className="form-control w-full px-3 py-2 focus:outline-none"
								type="email"
								name="email"
								placeholder="Correo electrónico"
								required
							/>
						</div>
						<div className="mb-4 flex items-center overflow-hidden rounded-[4px] border border-solid border-neutral-400 focus-within:outline">
							<div className="flex aspect-square h-10 w-10 flex-shrink-0 items-center justify-center border-r border-neutral-400 bg-neutral-300">
								<Lock className="h-4 w-4" />
							</div>
							<input
								className="form-control w-full px-3 py-2 focus:outline-none"
								type="password"
								name="password"
								placeholder="Contraseña"
								required
							/>
						</div>
						<div className="mt-auto grid grid-cols-2 place-items-center">
							<div className="place-self-start">
								<button
									className="rounded border border-indigo-800 bg-indigo-600 px-6 py-2 text-white transition-colors duration-300 hover:bg-indigo-400"
									type="submit"
								>
									Ingresar
								</button>
							</div>
							<div>
								<Link to="/recovery-password" className="underline" type="button">
									¿Olvidó su contraseña?
								</Link>
							</div>
						</div>
					</form>
				</div>
				<div className="flex flex-col items-center justify-center bg-neutral-900">
					<img src="/logo-big.png" alt="PRONYR Logo" className="mb-4 mt-24 w-32" />
					<p className="mb-24 text-white">v2.1.0</p>
				</div>
			</div>
		</div>
	);
}

export default SignIn;
