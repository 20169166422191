import {useState} from "react";
import {useAppDispatch} from "../../../../_store/hooks";
import {tagsActions} from "../../../../_store/features/shorts/tags-slice";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../../components/primitives/Dialog";
import {Input} from "../../../../components/primitives/Input";
import {Button} from "../../../../components/primitives/Button";
import {Spinner} from "../../../../components/primitives/icons";

interface AddNewTagModalProps {
	onDismiss: () => void;
	onSuccess?: () => void;
	open: boolean;
}

export function AddNewTagModal({open, onDismiss, onSuccess}: AddNewTagModalProps) {
	const [newTag, setNewTag] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useAppDispatch();

	async function handleSubmit() {
		setIsLoading(true);
		await Promise.all([dispatch(tagsActions.createTags({tag: newTag})), dispatch(tagsActions.listTags({params: {page_size: 999999}}))]);
		setIsLoading(false);
		resetStates();
		handleSuccess();
	}

	function handleSuccess() {
		onSuccess?.();
	}

	function resetStates() {
		setNewTag("");
	}

	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				onDismiss?.();
				resetStates();
			}}
		>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="font-medium text-white">Agregar nuevo tag</DialogTitle>
				</DialogHeader>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-3 flex w-full">
							<div className="flex w-full">
								<label className="my-4 text-white" htmlFor="exampleFormControlInput1">
									*Tag:
								</label>
								<Input
									className="form-control m-2 w-80 rounded bg-black px-3 py-2 text-white"
									type="text"
									name="title"
									placeholder="Escribe la etiqueta"
									value={newTag}
									onChange={e => setNewTag(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-row justify-end">
					<Button
						className="mr-2 w-32 text-white"
						size="sm"
						variant="outline"
						onClick={() => {
							resetStates();
							onDismiss?.();
						}}
					>
						Cancelar
					</Button>
					<Button className="w-32 bg-black text-white" size="sm" variant="outline" onClick={handleSubmit}>
						{isLoading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
						Guardar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
