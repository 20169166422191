import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {ERRORS} from "../../../constants/errors";
import {
	FetchDbUsersParams,
	NewInternalUserBodyType,
	NewUserBodyType,
	adminCreateInternalUser,
	adminCreateUser,
	adminMigrateOneUserSuperTokens,
	adminUpdatePassword,
	createAdminPasswordRequest,
	execPasswordReset,
	fetchDbInternalUsers,
	fetchDbUsers,
	updateDbUsers,
} from "../../../data-access/fetch-db-users";
import {auth} from "../../../firebase";
import {AppThunk} from "../../store";
import {dbUser, userDbActions} from "./user-db-slice";
import {
	GetPartnerConfigParams,
	PartnerRequestBody,
	SeriePartnershipType,
	getPartnerConfigData,
	insertPartnerConfigData,
	updatePartnerConfigData,
} from "../../../data-access/role/configuration/partner";
import {createAppAsyncThunk} from "../../hooks";
import {
	CreateSponsorPaymentRequest,
	GetSponsorConfigParams,
	GetSponsorPaymentsParams,
	SerieSponsorshipType,
	createSponsorPayment,
	editSponsorPayment,
	getSoponsorPayments,
	getSponsorConfigData,
	insertSponsorConfigData,
	removeSponsorPayment,
	updateSponsorConfigData,
} from "../../../data-access/role/configuration/sponsor";
import {
	GetInfluencerConfigParams,
	GetInfluencerLinkParams,
	InfluencerLinkType,
	SerieInfluencerType,
	getInfluencerConfigData,
	getInfluencerLinkData,
	insertInfluencerConfigData,
	insertInfluencerLinkData,
	updateInfluencerConfigData,
	updateInfluencerLinkData,
	updateInfluencerSubscriptionData,
} from "../../../data-access/role/configuration/influencer";

export const fetchDbUsersData = (props: FetchDbUsersParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(userDbActions.setLoading(true));
			const response = await fetchDbUsers(props);
			dispatch(userDbActions.loadDbUserData(response.data));
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(userDbActions.setLoading(false));
		}
	};
};

export const fetchDbInternalUsersData = ({
	idToken,
	page,
	page_size,
	searchvalue,
}: {
	idToken: string;
	page: number;
	page_size?: number;
	searchvalue?: string;
}): AppThunk => {
	return async dispatch => {
		try {
			dispatch(userDbActions.setLoading(true));
			const response = await fetchDbInternalUsers({idToken, page, page_size, searchvalue});
			dispatch(userDbActions.loadDbUserData(response.data));
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(userDbActions.setLoading(false));
		}
	};
};

export const updateDbUsersData = (idToken: string, userData: dbUser, admin: boolean): AppThunk => {
	return async dispatch => {
		try {
			dispatch(userDbActions.setEditing(true));
			await updateDbUsers(idToken, userData, admin);
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(userDbActions.setEditing(false));
		}
	};
};

//
export const createAdminPasswordRequestData = (
	idToken: string | undefined,
	email: string,
	recaptchaToken: string,
	action: string,
	admin: boolean = false,
): AppThunk => {
	return async dispatch => {
		try {
			dispatch(userDbActions.setSendingPasswordEmail(true));
			await createAdminPasswordRequest(idToken, email, recaptchaToken, action, admin);
		} catch (err) {
			dispatch(userDbActions.setError("Error al enviar correo"));
			dispatch(userDbActions.setSendingPasswordEmail(false));
			dispatch(userDbActions.setSuccess(false));
		} finally {
			dispatch(userDbActions.setSuccess(true));
			dispatch(userDbActions.setSendingPasswordEmail(false));
		}
	};
};

export const execPasswordResetData = (hash: string, password: string, admin: boolean = false): AppThunk => {
	return async dispatch => {
		try {
			dispatch(userDbActions.setEditing(true));
			await execPasswordReset(hash, password, admin);
		} catch (err) {
			dispatch(userDbActions.setError("Error cambiar la contraseña"));
			dispatch(userDbActions.setSuccess(false));
		} finally {
			dispatch(userDbActions.setSuccess(true));
			dispatch(userDbActions.setEditing(false));
		}
	};
};

export const adminUpdatePasswordData = (idToken: string, userid: string, password: string, admin: boolean): AppThunk => {
	return async dispatch => {
		try {
			dispatch(userDbActions.setEditing(true));
			await adminUpdatePassword(idToken, userid, password, admin);
		} catch (err) {
			dispatch(userDbActions.setError("Error cambiar la contraseña"));
			dispatch(userDbActions.setSuccess(false));
		} finally {
			dispatch(userDbActions.setSuccess(true));
			dispatch(userDbActions.setSuccessMessage("Contraseña cambiada correctamente"));
			dispatch(userDbActions.setEditing(false));
		}
	};
};

export const adminMigrateToSuperTokens = createAsyncThunk("dbuser/migrate-user", async (data: {idToken: string; firebaseid: string}, thunkAPI) => {
	try {
		const response = await adminMigrateOneUserSuperTokens(data.idToken, data.firebaseid);
		if (response.status === 200) {
			thunkAPI.dispatch(userDbActions.setSuccess(true));
			thunkAPI.dispatch(userDbActions.setSuccessMessage("Usuario migrado correctamente"));
		} else {
			thunkAPI.dispatch(userDbActions.setSuccess(false));
			thunkAPI.dispatch(userDbActions.setError("Error al migrar"));
		}
	} catch (error: any) {
		console.log(error);
		thunkAPI.dispatch(userDbActions.setSuccess(false));
		return thunkAPI.rejectWithValue("Problem fetching user");
	} finally {
		thunkAPI.dispatch(userDbActions.setMigrating(false));
	}
});

export const adminCreateUserData = createAsyncThunk("dbuser/create-user", async (data: NewUserBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await adminCreateUser(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		if (axios.isAxiosError(error)) {
			if (error.response?.data?.result?.code) {
				return thunkAPI.rejectWithValue(ERRORS[error.response.data.result.code] ?? ERRORS.default);
			}
		}
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

export const adminCreateInternalUserData = createAsyncThunk("dbuser/create-internal-user", async (data: NewInternalUserBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await adminCreateInternalUser(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		if (axios.isAxiosError(error)) {
			if (error.response?.data?.result?.code) {
				return thunkAPI.rejectWithValue(ERRORS[error.response.data.result.code] ?? ERRORS.default);
			}
		}
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

export const editDbUserData = createAsyncThunk("dbuser/edit", async ({data, admin}: {data: dbUser; admin: boolean}, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await updateDbUsers(token, data, admin);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

// Partner Config

export const getPartnerConfig = createAppAsyncThunk("dbuser/configuration/partner-list", async (params: GetPartnerConfigParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await getPartnerConfigData(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

export const insertPartnerConfig = createAppAsyncThunk("dbuser/configuration/partner-insert", async (data: SeriePartnershipType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await insertPartnerConfigData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

export const updatePartnerConfig = createAppAsyncThunk("dbuser/configuration/partner-update", async (data: PartnerRequestBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await updatePartnerConfigData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

// Sponsor Config

export const getSponsorConfig = createAppAsyncThunk("dbuser/configuration/sponsor-list", async (params: GetSponsorConfigParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await getSponsorConfigData(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

export const getSponsorPaymentsAction = createAppAsyncThunk(
	"dbuser/configuration/sponsor-payments",
	async (params: GetSponsorPaymentsParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) {
				return thunkAPI.rejectWithValue("No auth token present are you logged in?");
			}

			const response = await getSoponsorPayments(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching sponsor payments");
		}
	},
);

export const createSponsorPaymentAction = createAppAsyncThunk(
	"dbuser/configuration/create-sponsor-payments",
	async (data: CreateSponsorPaymentRequest, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) {
				return thunkAPI.rejectWithValue("No auth token present are you logged in?");
			}

			const response = await createSponsorPayment(token, data);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem creating sponsor payments");
		}
	},
);

export const removeSponsorPaymentAction = createAppAsyncThunk("dbuser/configuration/remove-sponsor-payments", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await removeSponsorPayment(token, id);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem removing sponsor payments");
	}
});

export const editSponsorPaymentAction = createAppAsyncThunk(
	"dbuser/configuration/edit-sponsor-payments",
	async (data: CreateSponsorPaymentRequest, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) {
				return thunkAPI.rejectWithValue("No auth token present are you logged in?");
			}

			const response = await editSponsorPayment(token, data);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem updating sponsor payments");
		}
	},
);

export const insertSponsorConfig = createAppAsyncThunk("dbuser/configuration/sponsor-insert", async (data: SerieSponsorshipType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await insertSponsorConfigData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

export const updateSponsorConfig = createAppAsyncThunk("dbuser/configuration/sponsor-update", async (data: SerieSponsorshipType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await updateSponsorConfigData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

// Influencer Config

export const getInfluencerConfig = createAppAsyncThunk(
	"dbuser/configuration/influencer-list",
	async (params: GetInfluencerConfigParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) {
				return thunkAPI.rejectWithValue("No auth token present are you logged in?");
			}

			const response = await getInfluencerConfigData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching user");
		}
	},
);

export const insertInfluencerConfig = createAppAsyncThunk("dbuser/configuration/influencer-insert", async (data: SerieInfluencerType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await insertInfluencerConfigData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

export const updateInfluencerConfig = createAppAsyncThunk("dbuser/configuration/influencer-update", async (data: SerieInfluencerType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await updateInfluencerConfigData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

export const updateInfluencerSubscriptionProfits = createAppAsyncThunk(
	"dbuser/configuration/influencer-subscriptions-profit-update",
	async (data: Pick<InfluencerLinkType, "annual_subscription_commission" | "month_subscription_commission" | "influencer_id">, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) {
				return thunkAPI.rejectWithValue("No auth token present are you logged in?");
			}

			const response = await updateInfluencerSubscriptionData(token, data);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching user");
		}
	},
);

// Influencer Config

export const getInfluencerLink = createAppAsyncThunk(
	"dbuser/configuration/influencer-list-link",
	async (params: GetInfluencerLinkParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) {
				return thunkAPI.rejectWithValue("No auth token present are you logged in?");
			}

			const response = await getInfluencerLinkData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching user");
		}
	},
);

export const insertInfluencerLink = createAppAsyncThunk("dbuser/configuration/influencer-insert-link", async (data: InfluencerLinkType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await insertInfluencerLinkData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

export const updateInfluencerLink = createAppAsyncThunk("dbuser/configuration/influencer-update-link", async (data: InfluencerLinkType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkAPI.rejectWithValue("No auth token present are you logged in?");
		}

		const response = await updateInfluencerLinkData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});
