import {useState, ReactElement, useEffect} from "react";
import {FileUpload} from "../../../components/primitives/FileUpload";
import {Spinner} from "../../../components/primitives/icons/Spinner";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {galleryActions} from "../../../_store/features/gallery/gallery-slice";
import {Close} from "../../../components/primitives/icons";
import {CheckCircle} from "../../../components/primitives/icons/CheckCircle";
import {Transition} from "@headlessui/react";

interface ImageGalleryUploadProps {
	tab: string;
}
export default function ImageGalleryUpload(props: ImageGalleryUploadProps): ReactElement {
	const {tab} = props;
	const [file, setFile] = useState();
	const dispatch = useAppDispatch();
	const isUploading = useAppSelector(state => state.gallery.uploading);
	const uploadError = useAppSelector(state => state.gallery.uploadError);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);

	const handleUpload = async () => {
		if (!file) {
			return;
		}

		dispatch(galleryActions.insertGalleryMedia({file, tab, onSuccess: () => setSuccess(true)}));
	};

	useEffect(() => {
		if (success) {
			setTimeout(() => {
				setSuccess(false);
			}, 3000);
		}
	}, [success]);

	return (
		<div className="bg-gray-50 px-4 py-5 sm:px-6">
			<div className="text-sm">Subir imagen:</div>
			<div className="flex items-center">
				<FileUpload
					onChange={e => {
						const [file] = e.target.files as any;
						if (file.size > 5 * Math.pow(10, 6)) {
							return setError("El tamaño máximo es de 5MB");
						}

						setFile(file);
					}}
					accept="image/*"
				/>
				<button
					type="button"
					className="ml-2 flex w-20 items-center justify-center rounded border-2 px-2 py-1 text-sm font-bold transition-all duration-300 disabled:cursor-not-allowed disabled:opacity-50 hover:bg-white hover:text-black"
					onClick={handleUpload}
					disabled={!file || error || uploadError}
				>
					{isUploading ? <Spinner className="h-4 w-4 animate-spin" /> : "Enviar"}
				</button>
				<Transition
					show={success}
					enter="transition-opacity duration-75"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<span className="flex items-center px-2 text-sm text-green-700">
						<CheckCircle />
					</span>
				</Transition>
			</div>
			{(uploadError || error) && (
				<div className="items fixed right-0 top-0 mx-8 mt-4 flex items-center justify-between rounded bg-red-700 px-6 py-3 text-white shadow">
					{uploadError || error}
					<button className="ml-2" onClick={() => dispatch(galleryActions.setError(""))}>
						<Close />
					</button>
				</div>
			)}
		</div>
	);
}
