import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface ProgrammedTaskResponseInterface {
	tasks: Task[];
	task?: Task[];
}

export interface Task {
	id: string;
	dateObject: string;
	task: string;
}

export async function adminListProgrammedTask(token: string): Promise<AxiosResponse<ProgrammedTaskResponseInterface>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_one_time_task", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface DeleteTaskResponse {
	task: string;
}

export async function adminDeleteProgrammedTask(id: string, token: string): Promise<AxiosResponse<DeleteTaskResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/remove_one_time_task", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		data: {id},
	});
}

export interface PostNewTaskBody {
	name: string;
	url: string;
	scheduleTime: string;
	body: TaskBody;
}

export interface TaskBody {}

export const adminPostNewTask = async (token: string, data: PostNewTaskBody): Promise<AxiosResponse<Task>> => {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.post("/one_time_scheduler", data, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});

	return response.data;
};
