import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../../components/primitives/Dialog";
import {Portal} from "@headlessui/react";
import {Checkbox} from "../../../../components/primitives/Checkbox";
import {Button} from "../../../../components/primitives/Button";
import {Close} from "../../../../components/primitives/icons";
import {Cost} from "../../../../data-access/dashboard/types";
import {DayPicker} from "../../../../components/blocks/DatePicker";
import {Dispatch, SetStateAction} from "react";
import {toDate} from "date-fns";

interface EditModalProps {
	error?: string;
	openDialog: boolean;
	data?: {approved: boolean; description: string; transaction_date: string};
	isLoading: boolean;
	setError: (error: string) => void;
	setData: Dispatch<SetStateAction<Cost | undefined>>;
	setOpenDialog: (openDialog: boolean) => void;
	onDismiss: () => void;
	onSubmit: () => void;
}

export default function EditModal({openDialog, isLoading, data, error, setError, onDismiss, onSubmit, setData}: EditModalProps) {
	return (
		<Dialog open={openDialog} onOpenChange={onDismiss}>
			<DialogContent className="bg-neutral-700">
				<DialogHeader>
					<DialogTitle className="border-b border-border pb-2 font-medium text-white">{"Editar Suscripción"}</DialogTitle>
				</DialogHeader>
				{error && (
					<Portal>
						<div className="items fixed right-0 top-0 z-[700] mx-8 mt-4 flex items-center justify-between rounded bg-red-700 px-6 py-3 text-white shadow">
							{error}
							<button className="ml-2" onClick={() => setError("")} title="Cerrar">
								<Close />
							</button>
						</div>
					</Portal>
				)}
				<div className="container space-y-5 px-0.5 py-5">
					<div className="flex max-h-10 w-56 flex-row items-center space-x-3 space-y-0 rounded-md border p-3 text-white">
						<Checkbox
							checked={data?.approved}
							// @ts-ignore
							onCheckedChange={checked => setData(prev => ({...prev, approved: checked as boolean}))}
						/>
						<div className="space-y-1 leading-none">
							<div className="text-sm">Aprobado</div>
						</div>
					</div>
					<textarea
						className="w-full rounded bg-gray-100 p-1"
						value={data?.description ?? ""}
						// @ts-ignore
						onChange={e => setData(prev => ({...prev, description: e.target.value}))}
					/>
					<div className="flex items-center gap-2">
						<label className="text-white" htmlFor="transaction_date">
							Fecha de transacción:
						</label>
						<DayPicker
							placeholder="Fecha"
							id="transaction_date"
							onDayChange={date => {
								const dateFormatted = date?.toISOString();
								return setData(prev => ({...prev, transaction_date: dateFormatted} as Cost));
							}}
							selectedDay={data?.transaction_date ? toDate(Date.parse(data.transaction_date)) : undefined}
							className="flex-1 gap-0 border-0 text-white"
							formatDate="MM/yyyy"
							autoConfirm
						/>
					</div>
				</div>

				<div className="flex flex-row justify-end">
					<Button className="mr-2 w-32 text-white" size="sm" variant="outline" onClick={onDismiss}>
						Cancelar
					</Button>
					<Button className="w-32 bg-black text-white" size="sm" variant="outline" onClick={onSubmit} disabled={isLoading}>
						Completar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
