import {Button} from "../../../../../../components/primitives/Button";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../../../../components/primitives/Sheet";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {RentalBody, RentalBodySchema} from "../../../../../../data-access/series/user-rentals";
import {useParams} from "react-router-dom";
import {Switch} from "../../../../../../components/primitives/Switch";
import {DayPicker} from "../../../../../../components/blocks/DatePicker";
import {add, toDate} from "date-fns";
import {useCallback, useState} from "react";
import {rentalActions} from "../../../../../../_store/features/rentals/slice";
import toast from "react-hot-toast";

interface CreateSheetProps extends SheetProps {
	serie_rentalid: string;
}

export default function CreateRentalSheet(props: CreateSheetProps) {
	const {onOpenChange, ...rest} = props;
	const {id} = useParams<{id: string}>();
	const dispatch = useAppDispatch();
	const [creating, setCreating] = useState(false);

	const {handleSubmit, setValue, control, reset} = useForm<RentalBody>({
		resolver: zodResolver(RentalBodySchema),
		defaultValues: {
			active: false,
			first_use_date: null,
			termination_date: add(new Date(), {days: 60}).toISOString(),
			series_rentalid: props.serie_rentalid,
			userid: id,
		},
	});

	const handleInsertReply: SubmitHandler<RentalBody> = async data => {
		setCreating(true);
		const res = await dispatch(rentalActions.createRental(data));
		if (res.meta.requestStatus === "fulfilled") {
			toast.success("Renta creada correctamente.", {position: "bottom-left"});
		}
		if (res.meta.requestStatus === "rejected") {
			toast.error("Error al crear la renta.", {position: "bottom-left"});
		}
		setCreating(false);
		props.onOpenChange?.(false);
	};

	const handleOnOpenChange = useCallback(
		(open: boolean) => {
			if (open) {
				reset();
			}
			onOpenChange?.(open);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<Sheet {...rest} onOpenChange={handleOnOpenChange}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>Crear Renta</SheetTitle>
					<SheetDescription>Crear una renta para un usuario</SheetDescription>
				</SheetHeader>

				<form onSubmit={handleSubmit(handleInsertReply)} className="flex flex-col space-y-4">
					<div>
						<label className="mb-2 block text-sm font-medium text-gray-600">Activa:</label>
						<Controller
							name="active"
							control={control}
							rules={{required: true}}
							render={({field}) => <Switch checked={field.value} onCheckedChange={c => field.onChange(c)} />}
						/>
					</div>

					<div>
						<label className="mb-2 block text-sm font-medium text-gray-600">Fecha de primer Uso:</label>

						<Controller
							name="first_use_date"
							control={control}
							rules={{required: true}}
							render={({field}) => (
								<DayPicker
									placeholder="Fecha"
									id="first_use_date"
									onDayChange={date => {
										const dateFormatted = date?.toISOString() ?? null;
										setValue("first_use_date", dateFormatted);
									}}
									selectedDay={field?.value ? toDate(Date.parse(field?.value)) : undefined}
									className="justify-start self-start"
									formatDate="dd/MM/yyyy"
									autoConfirm
									clearable
								/>
							)}
						/>
					</div>

					<div>
						<label className="mb-2 block text-sm font-medium text-gray-600">Fecha de terminación:</label>
						<Controller
							name="termination_date"
							control={control}
							render={({field}) => (
								<DayPicker
									placeholder="Fecha"
									id="termination_date"
									onDayChange={date => {
										const dateFormatted = date?.toISOString() ?? null;
										setValue("termination_date", dateFormatted);
									}}
									selectedDay={field?.value ? toDate(Date.parse(field?.value)) : undefined}
									className="justify-start self-start"
									formatDate="dd/MM/yyyy"
									autoConfirm
								/>
							)}
						/>
					</div>

					<Button type="submit" className="mt-4" variant="success" disabled={creating}>
						<span className="font-bold">Crear</span>
					</Button>
				</form>
			</SheetContent>
		</Sheet>
	);
}
