import {forwardRef, useMemo} from "react";
import Select, {Props} from "react-select";
import {useAppSelector} from "../../../_store/hooks";
import {selectGlobalStyles} from "../../../../utils/selectGlobalStyles";

interface OptionType {
	label: string;
	value: string;
}

export const SeriesSelect = forwardRef<any, Props<OptionType, false>>(({className, value, ...props}, ref) => {
	const series = useAppSelector(state => state.series.results);

	const options: OptionType[] = useMemo(() => {
		return series.map(serie => ({label: serie.title, value: serie.id}));
	}, [series]);

	return <Select options={options} isLoading={!options.length ? true : false} ref={ref} {...props} styles={selectGlobalStyles} />;
});

export default SeriesSelect;
