import {Dispatch, SetStateAction, useState} from "react";
import {PeriodSelector, Card} from "./ui";
import {Period, Stats} from "../types";
import {AverageChart, RequestsChart, StartsChart, TotalReviewsChart} from "./charts";
import {Data} from "../../../_store/features/feedback/types";
import {DateRange} from "react-day-picker";

interface OverviewChartsProps {
	dates: DateRange | undefined;
	setPeriod: Dispatch<SetStateAction<Period>>;
	period: Period;
	data: Data | undefined;
}

export default function OverviewCharts({dates, data, period, setPeriod}: OverviewChartsProps) {
	const [activeStat, setActiveStat] = useState<Stats>("average");

	return (
		<div className="grid grid-cols-1 bg-slate-100 p-2">
			<div className="flex-1 bg-white">
				{/* Cards */}
				<div className="flex flex-col lg:flex-row">
					<dl className="grid flex-1 grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2 md:divide-x md:divide-y-0 lg:grid-cols-4">
						<Card
							currentTab={activeStat}
							label="Calificación media"
							value={`${data?.currentRange.average || 0}`}
							increse={(data?.currentRange.average_percent_change || 0) >= 0}
							change={`${data?.currentRange.average_percent_change?.toFixed(2) || "0.00"}%`}
							tab="average"
							onPress={() => setActiveStat("average")}
						/>

						<Card
							currentTab={activeStat}
							label="Reseñas totales"
							value={`${data?.currentRange.total || 0}`}
							increse={(data?.currentRange.total_percent_change || 0) >= 0}
							change={`${data?.currentRange.total_percent_change || 0}%`}
							tab="total"
							onPress={() => setActiveStat("total")}
						/>

						<Card
							currentTab={activeStat}
							label="Solicitudes totales"
							value={`${data?.currentRange.requests || 0}`}
							increse={(data?.currentRange.request_percent_change || 0) >= 0}
							change={`${data?.currentRange.request_percent_change || 0}%`}
							tab="requests"
							onPress={() => setActiveStat("requests")}
						/>

						<Card
							currentTab={activeStat}
							label="Tasa de respuesta"
							value={`${data?.currentRange.response_rate?.toFixed(2) || "0.00"} %`}
							increse={(data?.currentRange.response_rate_percent_change || 0) >= 0}
							change={`${data?.currentRange.response_rate_percent_change?.toFixed(2) || "0.00"}%`}
							tab="rate_response"
							onPress={() => setActiveStat("rate_response")}
						/>
					</dl>
					<PeriodSelector setPeriod={setPeriod} period={period} dates={dates} />
				</div>

				<main className="relative mt-1 flex min-h-[500px] max-w-full overflow-hidden bg-gray-50">
					{/* Charts */}
					{activeStat === "average" && (
						<div className="w-full flex-1">
							<AverageChart data={data} />
						</div>
					)}
					{activeStat === "total" && (
						<div className="w-full flex-1">
							<TotalReviewsChart data={data} />
						</div>
					)}
					{activeStat === "requests" && (
						<div className="w-full flex-1">
							<RequestsChart data={data} />
						</div>
					)}
					{activeStat === "rate_response" && (
						<div className="w-full flex-1">
							<StartsChart data={data} />
						</div>
					)}
				</main>
			</div>
		</div>
	);
}
