import {Portal} from "@headlessui/react";
import {Close} from "../../../../../../components/primitives/icons";
import Select from "react-select";
import {BuddyPlanBodySchema, BuddyPlanBodyType} from "../../../../../../data-access/subscriptions/subscriptions";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../../../../components/primitives/Dialog";
import {Button} from "../../../../../../components/primitives/Button";
import {Checkbox} from "../../../../../../components/primitives/Checkbox";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Input} from "../../../../../../components/primitives/Input";
import {SLOTS_STATES} from "../../../../../../constants";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {subscriptionActions} from "../../../../../../_store/features/subscriptions/subscriptions-slice";
import toast from "react-hot-toast";
import {DayPicker} from "../../../../../../components/blocks/DatePicker";
import {toDate} from "date-fns";

export type ExtendedBuddySubscription = BuddyPlanBodyType & {};

interface EditBuddyModalProps {
	onDismiss: () => void;
	open: boolean;
	buddyData: ExtendedBuddySubscription;
	loadingSubmit?: boolean;
	onUpdate?: () => void;
}

const objectNonEmpty = (obj: any) => {
	return obj && Object.keys(obj).length > 0;
};

const StatesOptions = [...SLOTS_STATES, {value: "eliminated", label: "Eliminado"}];

export function EditBuddyModal({open, onDismiss, buddyData, onUpdate}: EditBuddyModalProps) {
	const {
		register,
		handleSubmit,
		reset,
		control,
		clearErrors,
		setValue,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<BuddyPlanBodyType>({
		resolver: zodResolver(BuddyPlanBodySchema),
		defaultValues: {
			buddy_id: buddyData?.buddy_id || null,
			id: buddyData?.id || "",
			limited_time: buddyData?.limited_time || false,
			state: buddyData?.state || "",
			subscriber_id: buddyData?.subscriber_id || "",
			audit_accepted: buddyData?.audit_accepted || null,
		},
	});

	const dispatch = useAppDispatch();

	const onSubmit: SubmitHandler<BuddyPlanBodyType> = async (data: BuddyPlanBodyType) => {
		const thunk = await dispatch(subscriptionActions.manageAdminBuddyPlan({...data, buddy_id: data.buddy_id || null}));

		if (thunk.meta.requestStatus === "rejected") {
			toast.error("Error al editar el cupo", {position: "top-right"});
			return;
		}

		toast.success("Cupo editado correctamente", {position: "top-right"});
		reset();
		onDismiss();
		onUpdate?.();
	};

	const handleDismiss = () => {
		reset();
		onDismiss();
	};

	return (
		<Dialog open={open} onOpenChange={handleDismiss}>
			<DialogContent className="bg-neutral-700">
				<DialogHeader>
					<DialogTitle className="border-b border-border pb-2 font-medium text-white">{"Editar Cupo"}</DialogTitle>
				</DialogHeader>
				{objectNonEmpty(errors) && (
					<Portal>
						<div className="items fixed right-0 top-0 z-[700] mx-8 mt-4 flex items-center justify-between rounded bg-red-700 px-6 py-3 text-white shadow">
							<ul>
								{Object.entries(errors).map(([key, error]) => (
									<li>
										{key} - {error.message}
									</li>
								))}
							</ul>
							<button className="ml-2" onClick={() => clearErrors()} title="Cerrar">
								<Close />
							</button>
						</div>
					</Portal>
				)}

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="container space-y-5 px-0.5 py-5">
						<div className="mb-3 flex w-full items-center">
							<div className="mr-3 flex w-full flex-row justify-between">
								<label className="w-48 self-center text-white" htmlFor="exampleFormControlInput1">
									Usuario ID (miembro del plan)
								</label>
								<Input {...register("buddy_id")} type="text" className="w-56 text-white" />
							</div>
						</div>

						<div className="mb-3 flex w-full items-center">
							<div className="mr-3 flex w-full flex-row justify-between">
								<label className="w-48 self-center text-white" htmlFor="exampleFormControlInput1">
									Bloqueado
								</label>

								<Controller
									name="limited_time"
									control={control}
									render={({field}) => (
										<Checkbox
											id="limited_time"
											checked={field.value}
											onCheckedChange={c => field.onChange(c)}
											className="border-white text-white"
										/>
									)}
								></Controller>
							</div>
						</div>

						<div className="mb-3 flex w-full items-center">
							<div className="mr-3 flex w-full flex-row justify-between">
								<label className="w-48 self-center text-white" htmlFor="exampleFormControlInput1">
									Estado
								</label>

								<Controller
									name="state"
									control={control}
									render={({field}) => (
										<Select
											placeholder="Selecciona el estad"
											className="w-60"
											defaultValue={StatesOptions.find(s => s.value === field.value)}
											options={StatesOptions}
											noOptionsMessage={() => "No hay mensajes disponibles"}
											onChange={c => {
												if (c) field.onChange(c.value);
											}}
										/>
									)}
								></Controller>
							</div>
						</div>

						<div className="mb-3 flex w-full items-center">
							<div className="mr-3 flex w-full flex-row justify-between">
								<label className="w-48 self-center text-white" htmlFor="exampleFormControlInput1">
									Fecha de aceptación
								</label>

								<DayPicker
									placeholder="Fecha"
									id="transaction_date"
									onDayChange={date => {
										const dateFormatted = date?.toISOString();
										if (dateFormatted) setValue("audit_accepted", dateFormatted);
									}}
									selectedDay={buddyData.audit_accepted ? toDate(Date.parse(buddyData.audit_accepted)) : undefined}
									className="flex-1 gap-0 border-0 text-white"
									formatDate="MM/yyyy"
									autoConfirm
								/>
							</div>
						</div>
					</div>

					<div className="flex flex-row justify-end">
						<Button className="mr-2 w-32 text-white" type="button" size="sm" variant="outline" onClick={handleDismiss}>
							Cancelar
						</Button>
						<Button className="w-32 bg-black text-white" size="sm" variant="outline" disabled={isLoading || isSubmitting}>
							Completar
						</Button>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	);
}
