import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface Variant {
	id: string;
	description: string;
	clicks: number;
	likes: number;
	video_id: string;
	duration: string;
	order: number;
}

export interface Content {
	id: string;
	serieid: string;
	serie_title: string;
	title: string;
	slugs: string;
	order: number;
	ranking: number;
	new: boolean;
	published: boolean;
	membership_type: number;
	content_type: number;
	short_description: string;
	long_description: string;
	video_id: string;
	duration: string;
	likes: number;
	favorite: number;
	lowres_image: string;
	square_image: string;
	rectangular_image: string;
	audit_created: string;
	audit_modified: string;
	release_date: string | null;
	whitelist_release_date: string | null;
	estreno: boolean;
	premiere_type: number;
	display_release_date: boolean;
	subscription_required: boolean;
	preview_image: string;
	variants?: Variant[];
	answer_time?: string;
	free_membership_slots?: number;
	total_likes?: number;
}
export interface GetContentsParams {
	page_size?: number;
	page?: number;
	searchby?: undefined | "title" | "short_description" | "long_description" | "id" | "serieid" | "content_title";
	searchvalue?: string;
	published?: boolean;
	estreno?: boolean;
	new?: boolean;
	content_type?: string;
	serieid_list?: string;
}

export interface GetContentsResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Content[];
}

export async function getSerieContent(token: string, params?: GetContentsParams): Promise<AxiosResponse<GetContentsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_content_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const InsertContentSchema = z.object(
	{
		seriesid: z.string().nonempty(),
		title: z.string().nonempty("El titulo es requerido"),
		order: z.number(),
		estreno: z.boolean(),
		new: z.boolean(),
		published: z.boolean(),
		membership_type: z.number(),
		content_type: z.number(),
		short_description: z.string(),
		long_description: z.string(),
		video_id: z.string(),
		duration: z.string(),
		lowres_image: z.string(),
		preview_image: z.string(),
		square_image: z.string(),
		rectangular_image: z.string(),
		release_date: z.string().nullable(),
		whitelist_release_date: z.string().nullable(),
		premiere_type: z.number(),
		ranking: z.number().optional(),
		slugs: z
			.string()
			.nonempty("El slug es requerido")
			.refine(
				val => {
					const slugs = val.split(",");
					const slugsSet = new Set(slugs);
					return slugsSet.size === slugs.length;
				},
				{message: "No puedes repetir slug"},
			),
		display_release_date: z.boolean(),
		subscription_required: z.boolean(),
		video_id_alternative: z.string().optional(),
		ending_description_primary: z.string().optional(),
		ending_description_secondary: z.string().optional(),
		answer_time: z.string().optional(),
		duration_alternative: z.string().optional(),
		free_membership_slots: z.number().optional(),
		total_likes: z.coerce.number().optional(),
	},
	{required_error: "Este campo es requerido."},
);
export type InsertContentType = z.infer<typeof InsertContentSchema>;

export async function insertContent(token: string, data: InsertContentType) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_content_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function uploadContentImage(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_content_image", data, {
		headers: {
			Authorization: idToken,
		},
	});
}

export async function editContent(token: string, data: InsertContentType) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_content_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface OrderContentType {
	ordered_data: {
		id: string;
		order: number;
	}[];
}

export async function orderContent(token: string, data: OrderContentType) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.put("/order_content_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function deleteContent(token: string, data: {contentid: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_content_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export interface StopLiveParams {
	contentId: string;
}

export async function stopLiveStream(token: string, data: StopLiveParams) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/admin_disable_live_stream", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface ContentRankingParams {
	initial_date?: string;
	final_date?: string;
	content_id?: string;
	serie_id?: string;
	page?: number;
	page_size?: number;
	content_type?: number;
}

export interface ContentRankingResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: RankedContent[];
}

export interface RankedContent {
	id?: string;
	rank_number: number;
	name: string;
	photo_url?: string;
	views: number;
	likes: number;
	follows: number;
	comments: number;
	donations: number;
	stickers: number;
	series_name: string;
	content_type: number;
	series_id: string;
}

export async function getContentsRanking(token: string, params?: ContentRankingParams): Promise<AxiosResponse<ContentRankingResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_content_ranking", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetContentCoinsParams {
	contentid?: string;
	initial_date?: string;
	final_date?: string;
}

export interface Daily {
	day: number;
	coin: number;
	year: number;
	month: number;
}

export interface GetContentCoinsResponse {
	stickers: number;
	donations: number;
	stickers_count: number;
	donations_count: number;
	daily_stickers: Daily[];
	daily_donations: Daily[];
	total_views: number;
}

export async function getContentCoins(token: string, params?: GetContentCoinsParams): Promise<AxiosResponse<GetContentCoinsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_content_coins", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetContentViewsParams {
	content_id?: string;
	series_id?: string;
	initial_date?: string;
	final_date?: string;
}

export interface GetContentViewsResponse {
	likes: {
		likes: number;
		date: string;
	}[];
	dislike: {
		dislikes: number;
		date: string;
	}[];
	favorites: {
		favorites: number;
		date: string;
	}[];
	views: {
		views: number;
		date: string;
	}[];
}

export async function getContentViews(token: string, params?: GetContentViewsParams): Promise<AxiosResponse<GetContentViewsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_content_stats", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function editContentVariant(token: string, data: Variant) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_content_variant", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
