import {cn} from "../../../../../utils/classNames";
import {ArrowDown, ArrowUp} from "../../../../components/primitives/icons";

interface CardProps {
	label: string;
	value: string;
	increse: boolean;
	change: string;
	tab: string;
	currentTab: string;
	onPress: () => void;
}

export function Card({label, change, increse, value, currentTab, tab, onPress}: CardProps) {
	return (
		<div className={cn("relative  px-4 py-5 sm:p-6", {"bg-slate-50": currentTab === tab})} onClick={onPress}>
			{currentTab === tab && <div className="absolute left-0 right-0 top-0 h-0.5 w-full bg-indigo-500" />}
			<dt className="text-xs font-normal text-gray-700">{label}</dt>
			<dd className="mt-2 flex items-baseline space-x-2 md:block lg:flex">
				<div className="flex items-baseline text-3xl font-black text-black">{value}</div>

				<div
					className={cn(
						increse ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800",
						"inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0",
					)}
				>
					{increse ? (
						<ArrowUp aria-hidden="true" className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 rotate-45 self-center text-green-500" />
					) : (
						<ArrowDown aria-hidden="true" className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 -rotate-45 self-center text-red-500" />
					)}
					<span className="sr-only"> {increse ? "Increased" : "Decreased"} by </span>
					{change}
				</div>
			</dd>
		</div>
	);
}
