import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

interface GetStaffResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Staff[];
}
export interface Staff {
	id: string;
	fullname: string;
	professions: Profession[] | null;
	series?: StaffSeries[];
}

export interface Profession {
	id: string;
	profession: string;
}

export interface StaffSeries {
	jsonb_build_object: JsonbBuildObject;
}

export interface JsonbBuildObject {
	id: string;
	title: string;
	categories: Category[];
}

export interface Category {
	category: string;
}

export interface GetStaffParams {
	page?: number;
	page_size?: number;
	searchby?: undefined | "fullname";
	searchvalue?: string;
}

export async function listStaffMembers(token: string, params?: GetStaffParams): Promise<AxiosResponse<GetStaffResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_creditstaff_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const InsertStaffBodySchema = z.object({
	fullname: z.string(),
});

export type InsertStaffBodyType = z.infer<typeof InsertStaffBodySchema>;

export async function insertStaffMember(token: string, data: InsertStaffBodyType): Promise<AxiosResponse<Staff>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_creditstaff_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export const UpdateStaffBodySchema = z.object({
	id: z.string().nonempty(),
	fullname: z.string(),
});

export type UpdateStaffBodyType = z.infer<typeof UpdateStaffBodySchema>;

export async function updateStaffMember(token: string, data: UpdateStaffBodyType): Promise<AxiosResponse<Staff>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_creditstaff_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface DeleteStaffData {
	id: string;
}
export async function deleteStaffMember(token: string, data: DeleteStaffData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_creditstaff_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export interface StaffProfession {
	id: string;
	profession: string;
}

export interface GetStaffProfessionParams {
	page?: number;
	page_size?: number;
	searchby?: undefined | "profession";
	searchvalue?: string;
}

interface GetStaffProfessionResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: StaffProfession[];
}

export async function listStaffProfessions(token: string, params?: GetStaffProfessionParams): Promise<AxiosResponse<GetStaffProfessionResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_staff_profession_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const InsertStaffProfessionBodySchema = z.object({
	profession: z.string(),
});

export type InsertStaffProfessionBodyType = z.infer<typeof InsertStaffProfessionBodySchema>;

export async function insertStaffProfession(token: string, data: InsertStaffProfessionBodyType): Promise<AxiosResponse<StaffProfession>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_staff_profession_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export const UpdateStaffProfessionBodySchema = z.object({
	id: z.string().nonempty(),
	profession: z.string(),
});

export type UpdateStaffProfessionBodyType = z.infer<typeof UpdateStaffProfessionBodySchema>;

export async function updateStaffProfession(token: string, data: UpdateStaffProfessionBodyType): Promise<AxiosResponse<StaffProfession>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_staff_profession_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface DeleteStaffProfessionData {
	id: string;
}
export async function deleteStaffProfession(token: string, data: DeleteStaffProfessionData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_staff_profession_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

interface StaffProfessionRelation {
	id: string;
	profession_id: string;
	staff_id: string;
}

export const InsertStaffProfessionRelationBodySchema = z.object({
	profession_id: z.string({}),
	staff_id: z.string({}),
});

export type InsertStaffProfessionRelationBodyType = z.infer<typeof InsertStaffProfessionRelationBodySchema>;

export async function insertStaffMemberProfessionRelation(
	token: string,
	data: InsertStaffProfessionRelationBodyType,
): Promise<AxiosResponse<StaffProfessionRelation>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_credit_profession_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface DeleteStaffMemberProfessionRelationData {
	id: string;
}
export async function deleteStaffMemberProfessionRelation(token: string, data: DeleteStaffMemberProfessionRelationData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_credit_profession_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}
