import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	GetCastParams,
	InsertCastMemberBodyType,
	UpdateCastMemberBodyType,
	insertCastMember,
	listCastMembers,
	updateCastMember,
	Cast,
} from "../../../data-access/series/cast";
import {
	GetStaffParams,
	GetStaffProfessionParams,
	InsertStaffBodyType,
	InsertStaffProfessionBodyType,
	Staff,
	StaffProfession,
	UpdateStaffBodyType,
	UpdateStaffProfessionBodyType,
	insertStaffMember,
	insertStaffProfession,
	listStaffMembers,
	listStaffProfessions,
	updateStaffMember,
	updateStaffProfession,
} from "../../../data-access/series/staff";

export interface crewInterface {
	castsLoading: boolean;
	castsTotalResults: number;
	castsPageSize: number;
	castCurrentPage: number;
	castResults: Cast[];
	castLastFetch: number | null;
	staffLoading: boolean;
	staffTotalResults: number;
	staffPageSize: number;
	staffCurrentPage: number;
	staffResults: Staff[];
	staffLastFetch: number | null;
	staffProfessionsLoading: boolean;
	staffProfessionsCurrentPage: number;
	staffProfessionsPageSize: number;
	staffProfessionsTotalResults: number;
	staffProfessionsResults: StaffProfession[];
	staffProfessionsLastFetch: number | null;
}

const initialState: crewInterface = {
	castsLoading: false,
	castsTotalResults: 0,
	castsPageSize: 0,
	castCurrentPage: 0,
	castResults: [],
	staffLoading: false,
	staffTotalResults: 0,
	staffPageSize: 0,
	staffCurrentPage: 0,
	staffResults: [],
	staffProfessionsLoading: false,
	staffProfessionsResults: [],
	staffProfessionsLastFetch: null,
	staffProfessionsCurrentPage: 0,
	staffProfessionsPageSize: 0,
	staffProfessionsTotalResults: 0,
	castLastFetch: null,
	staffLastFetch: null,
};

const getCastsList = createAsyncThunk("crew/cast/list", async ({params}: {params?: GetCastParams}, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await listCastMembers(token, params);

		const castData: Cast[] = response.data.results.map(el => {
			const {images, ...rest} = el;
			let normalizedEl: Cast = {
				images: [],
				...rest,
			};

			if (images) {
				normalizedEl.images = images.split(",");
			}

			return normalizedEl;
		});

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: castData,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const getStaffList = createAsyncThunk("crew/staff/list", async ({params}: {params?: GetStaffParams}, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await listStaffMembers(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const getStaffProfessionList = createAsyncThunk("crew/staff-professions/list", async (params: GetStaffProfessionParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await listStaffProfessions(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const insertCast = createAsyncThunk("crew/cast/new", async (data: InsertCastMemberBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await insertCastMember(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const insertStaff = createAsyncThunk("crew/staff/new", async (data: InsertStaffBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await insertStaffMember(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const insertProfession = createAsyncThunk("crew/profession/new", async (data: InsertStaffProfessionBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await insertStaffProfession(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const updateCast = createAsyncThunk("crew/cast/update", async (data: UpdateCastMemberBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await updateCastMember(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const updateStaff = createAsyncThunk("crew/staff/update", async (data: UpdateStaffBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await updateStaffMember(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const updateProfession = createAsyncThunk("crew/profession/update", async (data: UpdateStaffProfessionBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await updateStaffProfession(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const crewSlice = createSlice({
	name: "crew",
	initialState,
	reducers: {
		resetToInitial: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getCastsList.pending, state => {
				state.castsLoading = true;
			})
			.addCase(getCastsList.fulfilled, (state, action) => {
				state.castsLoading = false;
				state.castCurrentPage = action.payload.current;
				state.castsPageSize = action.payload.pageSize;
				state.castsTotalResults = action.payload.totalResults;
				state.castResults = action.payload.results;
				state.castLastFetch = Date.now();
			})
			.addCase(getCastsList.rejected, (state, action) => {
				state.castsLoading = false;
			});
		builder
			.addCase(getStaffList.pending, state => {
				state.staffLoading = true;
			})
			.addCase(getStaffList.fulfilled, (state, action) => {
				state.staffLoading = false;
				state.staffCurrentPage = action.payload.current;
				state.staffPageSize = action.payload.pageSize;
				state.staffTotalResults = action.payload.totalResults;
				state.staffResults = action.payload.results;
				state.staffLastFetch = Date.now();
			})
			.addCase(getStaffList.rejected, (state, action) => {
				state.staffLoading = false;
			});
		builder
			.addCase(getStaffProfessionList.pending, state => {
				state.staffProfessionsLoading = true;
			})
			.addCase(getStaffProfessionList.fulfilled, (state, action) => {
				state.staffProfessionsLoading = false;
				state.staffProfessionsCurrentPage = action.payload.current;
				state.staffProfessionsPageSize = action.payload.pageSize;
				state.staffProfessionsTotalResults = action.payload.totalResults;
				state.staffProfessionsResults = action.payload.results;
				state.staffProfessionsLastFetch = Date.now();
			})
			.addCase(getStaffProfessionList.rejected, (state, action) => {
				state.staffProfessionsLoading = false;
			});
	},
});

export const crewActions = {
	...crewSlice.actions,
	getCastsList,
	getStaffList,
	getStaffProfessionList,
	insertCast,
	insertStaff,
	insertProfession,
	updateCast,
	updateStaff,
	updateProfession,
};

export default crewSlice;
