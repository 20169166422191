import {CSSObjectWithLabel} from "react-select";

export const selectStyles = {
	control: (baseStyles: CSSObjectWithLabel, {isDisabled}: any) => ({
		...baseStyles,
		backgroundColor: isDisabled ? "hsl(var(--accent))" : "transparent",
		borderColor: "hsl(var(--border))",
	}),
	placeholder: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		fontSize: "14px",
	}),
	input: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		color: "hsl(var(--text))",
	}),
	singleValue: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		color: "hsl(var(--text))",
	}),
	menu: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		backgroundColor: "hsl(var(--popover))",
		borderColor: "hsl(var(--border))",
		color: "hsl(var(--text))",
	}),
	menuList: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		backgroundColor: "hsl(var(--popover))",
	}),
	option: (baseStyles: CSSObjectWithLabel, {isFocused}: any) => ({
		...baseStyles,
		color: "hsl(var(--text))",
		backgroundColor: isFocused ? "hsl(var(--accent))" : "hsl(var(--popover))",
	}),
};
