import {useMemo} from "react";
import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";
import Loader from "../../../../../components/blocks/Stats/SponsorAdViews/Loader";
import {useDashboardByNameQuery} from "../../../../../_store/features/dashboard/hooks";

export default function RentalsLocationMap() {
	const rentalsLocations = useDashboardByNameQuery("rentalsLocations");

	const {isLoaded} = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
	});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const center = useMemo(() => ({lat: 0, lng: 0}), []);

	return (
		<div className="mb-5 flex flex-1 flex-col rounded-lg border bg-card">
			<div className="flex flex-row items-center justify-between">
				<h3 className="p-8 text-left text-lg font-semibold leading-none tracking-tight">Compras por Países.</h3>
			</div>
			<div className="flex items-center justify-between border-b border-border" />

			{!isLoaded || rentalsLocations.isLoading ? (
				<Loader title="" />
			) : (
				<GoogleMap
					center={center}
					zoom={3}
					mapContainerStyle={{height: 700, width: "100%"}}
					options={{
						mapTypeControl: false,
						streetViewControl: false,
						zoomControlOptions: {
							position: 9,
						},
						minZoom: 3,
						maxZoom: 5,
					}}
				>
					{rentalsLocations.data?.map((item, index: number) => {
						let size = Math.pow(item.cant, 1 / 2.3);

						return (
							<MarkerF
								key={index}
								position={{lat: Number(item.latitude), lng: Number(item.longitude)}}
								cursor="default"
								icon={{
									path: "M 0,0 m -2,0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0",
									fillColor: "#FF0000",
									fillOpacity: 0.4,
									strokeWeight: 0,
									scale: size <= 4 ? 4 : size >= 10 ? 10 : size,
								}}
							></MarkerF>
						);
					})}
				</GoogleMap>
			)}
		</div>
	);
}
