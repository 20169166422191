import {Button} from "../../../../../../components/primitives/Button";
import {SortAsc, SortDesc, Trash} from "../../../../../../components/primitives/icons";

interface StaffProps {
	data: {creditId: string; staffFullName: string; staffRanking?: number};
	onStaffDelete: (staff: {creditId: string; staffFullName: string}) => void;
	onStaffOrderUp: (creditId: string) => void;
	onStaffOrderDown: (creditId: string) => void;
}

export default function Staff(props: StaffProps) {
	const {data, onStaffDelete, onStaffOrderUp, onStaffOrderDown} = props;

	return (
		<div className="group/item flex items-center gap-2">
			{data.staffFullName}
			<Button
				className={`h-auto p-1 opacity-0 transition-opacity group-hover/item:opacity-100`}
				onClick={() => onStaffOrderUp(data.creditId)}
				variant={"outline"}
			>
				<SortAsc className="h-3 w-3" />
			</Button>
			<Button
				className={`h-auto p-1 opacity-0 transition-opacity group-hover/item:opacity-100`}
				onClick={() => onStaffOrderDown(data.creditId)}
				variant={"outline"}
			>
				<SortDesc className="h-3 w-3" />
			</Button>
			<Button className={`h-auto p-1 opacity-0 transition-opacity group-hover/item:opacity-100`} onClick={() => onStaffDelete(data)}>
				<Trash className="h-3 w-3" />
			</Button>
		</div>
	);
}
