import classNames from "classnames";
import {useCallback, useEffect, useRef} from "react";
import {useSearchParams} from "react-router-dom";
import {Folder, FolderOpen, Trash} from "../../components/primitives/icons";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {FOLDERS} from "../../data-access/multimedia/gallery";
import {galleryActions} from "../../_store/features/gallery/gallery-slice";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import ImageGalleryUpload from "./subcomponents/ImageGalleryUpload";
import {DotLottiePlayer as LottiePlayer} from "@dotlottie/react-player";
import {getUrlwithSize} from "../../../utils/picturesManage";

const FOLDERS_NAMES: {name: string; value: (typeof FOLDERS)[number]}[] = [
	{name: "Fotos Actores", value: "cast"},
	{name: "Anuncios", value: "publicity"},
	{name: "Series Banners", value: "serie/banners"},
	{name: "Series Logos", value: "serie/logos"},
	{name: "Series Thumbnails", value: "serie/thumbnails"},
	{name: "Fotos de Contenidos", value: "content"},
	{name: "Stickers", value: "stickers"},
	{name: "Sala exclusiva", value: "exclusive_image"},
];

function MultimediaList() {
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const nextPageToken = useAppSelector(state => state.gallery.queryPageToken);
	const folderData = useAppSelector(state => state.gallery.results);
	const totalResult = useAppSelector(state => state.gallery.totalFiles);
	const loading = useAppSelector(state => state.gallery.loading);
	const firstRender = useRef(true);

	const observer = useRef<IntersectionObserver>();

	useEffect(() => {
		if (firstRender.current) {
			if (observer.current) observer.current.disconnect();
			const tabName = searchParams.get("tab");
			if (!tabName) {
				return setSearchParams({tab: FOLDERS[0]});
			}

			if (!FOLDERS.some(el => el === tabName)) {
				return setSearchParams({tab: FOLDERS[0]});
			}
			dispatch(galleryActions.getGalleryMedia({folder: tabName}));

			firstRender.current = false;
		}
	}, [dispatch, searchParams, setSearchParams]);

	const lastBookElementRef = useCallback(
		(node: HTMLDivElement) => {
			if (loading) return;
			if (observer.current) observer.current.disconnect();
			const tabName = searchParams.get("tab");
			if (!tabName) {
				return setSearchParams({tab: FOLDERS[0]});
			}

			if (!FOLDERS.some(el => el === tabName)) {
				return setSearchParams({tab: FOLDERS[0]});
			}
			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting && folderData.length < totalResult - 1) {
					dispatch(galleryActions.getGalleryMedia({folder: tabName, next_page_token: nextPageToken}));
				}
			});
			if (node) observer.current.observe(node);
		},
		[dispatch, folderData.length, loading, nextPageToken, searchParams, setSearchParams, totalResult],
	);
	return (
		<div>
			<div className="bg-neutral-50 px-4 py-5 sm:px-6">
				<h3 className="text-base font-semibold leading-6 text-gray-900">Galería de Imágenes</h3>
				<p className="mt-1 max-w-2xl text-sm text-gray-500">Consulta las diferentes categorías de imágenes y reutiliza las que necesites.</p>
			</div>
			<ul className="sticky top-0 z-10 flex overflow-auto bg-white text-center text-sm font-medium text-gray-500">
				{FOLDERS_NAMES.map((folder, idx) => (
					<li className={classNames(searchParams.get("tab") === folder.value ? "" : "border-b border-black")} key={idx}>
						<button
							onClick={() => {
								firstRender.current = true;
								dispatch(galleryActions.resetToInitial());
								setSearchParams({tab: folder.value});
							}}
							aria-current="page"
							className={classNames(
								"capitalize",
								searchParams.get("tab") === folder.value
									? "relative block border border-b-0 border-black bg-gray-100 p-4 text-black"
									: "block p-4 hover:bg-gray-50 hover:text-gray-600",
							)}
						>
							<div className="flex flex-shrink-0 items-center gap-1 whitespace-nowrap">
								{searchParams.get("tab") === folder.value ? <FolderOpen className="h-4 w-4" /> : <Folder className="h-4 w-4" />}
								<span>{folder.name}</span>
							</div>
						</button>
					</li>
				))}
				<div className="w-full border-b border-black" />
			</ul>
			<ImageGalleryUpload tab={searchParams.get("tab")!} />
			<div className="flex flex-wrap gap-4 p-4">
				{folderData?.map((el, index) => (
					<div
						className="relative aspect-square h-36 w-36 overflow-hidden rounded border border-neutral-300 bg-neutral-100 p-2 last-of-type:mr-auto"
						ref={lastBookElementRef}
						key={el.name + index}
					>
						<button className="absolute right-0 top-0 rounded-bl border-b border-l border-neutral-300 bg-white p-0.5">
							<Trash className="h-5 w-5" />
						</button>
						{el.url?.endsWith(".json") || el.url?.endsWith(".lottie") ? (
							<LottiePlayer autoplay loop src={el.url} className="mx-auto flex h-full w-fit" />
						) : (
							<img
								src={getUrlwithSize({url: el.url, size: "200"})}
								className="block aspect-square overflow-hidden object-contain"
								alt=""
							/>
						)}
					</div>
				))}
			</div>
			{loading && (
				<div className="flex w-full items-center justify-center py-2">
					<span className="mr-4 italic text-neutral-700">Cargando...</span>
					<Spinner />
				</div>
			)}
			{!loading && !folderData.length && (
				<div className="flex w-full items-center justify-center py-2">
					<span className="mr-4 italic text-neutral-700">No hay mas archivos</span>
				</div>
			)}
		</div>
	);
}

export default MultimediaList;
