import {useEffect, useRef, useState} from "react";
import AvatarEditor from "react-avatar-editor";
import {uploadImageFile} from "../../../data-access/user-image";
import {auth} from "../../../firebase";
import {userAuthActions} from "../../../_store/features/user-auth/user-auth-slice";
import {FileUpload} from "../../../components/primitives/FileUpload";
import {Modal} from "../../../components/primitives/Modal";
import {Slider} from "../../../components/primitives/Slider";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";

interface AvatarImagePickerProps {
	open: boolean;
	onDismiss: () => void;
}
function AvatarImagePicker({open, onDismiss}: AvatarImagePickerProps) {
	const userInfo = useAppSelector(state => state.user.userInfo);
	const [image, setImage] = useState<string | File>("");
	const [rotation, setRotation] = useState(0);
	const [scale, setScale] = useState(5000);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const editorRef = useRef<AvatarEditor>(null);
	const dispatch = useAppDispatch();

	const handleSave = () => {
		var body = new FormData();

		editorRef.current?.getImageScaledToCanvas().toBlob((blob: any) => {
			body.append("file", blob!);

			setLoading(true);
			auth.currentUser?.getIdToken().then(token => {
				uploadImageFile(token, body)
					.then(res => {
						setLoading(false);
						setSuccess(true);

						dispatch(userAuthActions.setUser({...userInfo, photo_url: res.data.result}));
					})
					.catch(err => {
						console.log(err);
						setLoading(false);
						setError("Error Uploading Image.");
					});
			});
		}, "image/png");
	};

	useEffect(() => {
		if (!userInfo?.photo_url) return;
		setImage(userInfo?.photo_url + "?" + Date.now());
		setRotation(0);
		setScale(5000);
	}, [userInfo]);

	useEffect(() => {
		if (!success) return;

		const handle = setTimeout(() => {
			setSuccess(false);
			onDismiss?.();
		}, 3000);

		return () => {
			clearTimeout(handle);
		};
	}, [onDismiss, success]);

	return (
		<Modal title="Seleccionar Imagen" open={open} onDismiss={onDismiss}>
			<div className="flex flex-col gap-6 sm:flex-row">
				<div className="mx-auto h-fit w-fit flex-shrink-0 flex-grow-0 overflow-hidden rounded">
					<AvatarEditor
						ref={editorRef}
						image={image}
						border={25}
						color={[255, 255, 255, 0.2]} // RGBA
						scale={scale / 5000}
						rotate={rotation}
						width={200}
						height={200}
						crossOrigin="anonymous"
					/>
				</div>
				<div className="flex flex-grow-0 flex-col overflow-hidden">
					{!!error && <div className="bg-palenque-red-highlight my-4 rounded-md px-4 py-2">{error}</div>}
					{success && (
						<div className="my-4 rounded-md bg-green-700 px-4 py-2 text-white">
							Foto Cambiada Correctamente
							<div className="h-1 w-full shrink rounded-xl bg-green-300 transition-all" />
						</div>
					)}
					<div className="mt-4">
						<div className="mb-2 w-full overflow-hidden text-white">
							<label htmlFor="photo" className="block">
								Foto
							</label>
							<FileUpload
								name="photo"
								onChange={e => {
									if (e.target.files?.[0]) {
										setImage(e.target.files[0]);
									}
								}}
								accept="image/png, image/jpeg"
							/>
						</div>
						<label htmlFor="rotation" className="text-white">
							Rotación
						</label>
						<div className="flex items-center">
							<button
								className="mr-2 flex h-6 w-8 cursor-pointer select-none items-center justify-center rounded font-black leading-none text-white disabled:cursor-not-allowed hover:bg-neutral-700 hover:text-neutral-200"
								disabled={rotation <= -360 || !image || loading || success}
								onClick={() => {
									setRotation(prev => prev - 1);
								}}
							>
								-
							</button>
							<Slider
								name="rotation"
								value={rotation}
								min={-360}
								disabled={!image || loading || success}
								max={360}
								onChange={e => {
									setRotation(Number(e.target.value));
								}}
							/>
							<button
								className="ml-2 flex h-6 w-8 cursor-pointer select-none items-center justify-center rounded font-black leading-none text-white disabled:cursor-not-allowed hover:bg-neutral-700 hover:text-neutral-200"
								disabled={rotation >= 360 || !image || loading || success}
								onClick={() => {
									setRotation(prev => prev + 1);
								}}
							>
								+
							</button>
							<div className="w-[6ch] text-end text-white">{rotation}&deg;</div>
						</div>
						<label htmlFor="scale" className="text-white">
							Zoom
						</label>
						<div className="flex items-center">
							<button
								className="mr-2 flex h-6 w-8 cursor-pointer select-none items-center justify-center rounded font-black leading-none text-white disabled:cursor-not-allowed hover:bg-neutral-700 hover:text-neutral-200"
								disabled={scale <= 0 || !image || loading || success}
								onClick={() => {
									setScale(prev => prev - 100);
								}}
							>
								-
							</button>
							<Slider
								name="scale"
								value={scale}
								min={5000}
								max={20000}
								disabled={!image || loading || success}
								onChange={e => {
									setScale(Number(e.target.value));
								}}
							/>
							<button
								className="ml-2 flex h-6 w-8 cursor-pointer select-none items-center justify-center rounded font-black leading-none text-white disabled:cursor-not-allowed hover:bg-neutral-700 hover:text-neutral-200"
								disabled={scale >= 20000 || !image || loading || success}
								onClick={() => {
									setScale(prev => prev + 100);
								}}
							>
								+
							</button>
							<div className="w-[6ch] text-end text-white">{(scale / 5000).toFixed(2)}x</div>
						</div>
					</div>
				</div>
			</div>
			<button
				className="text-md  mt-4 flex w-full items-center justify-center rounded-xl border-2 bg-white bg-opacity-10 px-4 py-3 font-bold uppercase tracking-wider text-white transition-colors duration-300 disabled:cursor-not-allowed disabled:border-neutral-800 disabled:bg-neutral-700 disabled:text-neutral-300 hover:bg-white hover:text-black"
				type="submit"
				disabled={!image || loading || success}
				onClick={handleSave}
			>
				Aceptar
			</button>
		</Modal>
	);
}

export default AvatarImagePicker;
