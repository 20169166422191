export const formattedDayAndHour = (timeString: string) => {
	const time = new Date(timeString);
	const month = time.getMonth();
	const day = time.getDate();
	const year = time.getFullYear();
	const hours = time.getHours();
	const minutes = time.getMinutes();
	const amOrPm = hours >= 12 ? "PM" : "AM";
	const formattedHours = hours % 12 || 12;
	const formattedMinutes = minutes.toString().padStart(2, "0");
	const formattedTime = `${day}/${month + 1}/${year} - ${formattedHours}:${formattedMinutes} ${amOrPm}`;

	return formattedTime;
};
