import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export const SerieSponsorshipSchema = z.object({
	active: z.boolean(),
	serie_id: z.string(),
	sponsor_id: z.string(),
	name: z.string().optional(),
	photo_url: z.string().optional(),
	sponsored_series: z
		.array(
			z.object({
				id: z.string().optional(),
				name: z.string().optional(),
				total_payment: z.number().optional(),
				active: z.boolean().optional(),
			}),
		)
		.optional(),
	userid: z.string().optional(),
	id: z.string().optional(),
	audit_created: z.string().optional(),
	audit_updated: z.string().optional(),
	total_payments: z.string().optional(),
});
export type SerieSponsorshipType = z.infer<typeof SerieSponsorshipSchema>;

/***************
 * LIST CONFIGS
 ***************/
export interface GetSponsorConfigParams {
	page_size?: number;
	page?: number;
	searchby?: "sponsor_id" | "serie_id" | "serie_name" | "sponsor_name";
	searchvalue?: string;
	active?: boolean;
	order?: "asc" | "desc";
	orderby?: "name" | "profits";
}
export interface GetSponsorConfigResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: SerieSponsorshipType[];
}

export async function getSponsorConfigData(token: string, params: GetSponsorConfigParams): Promise<AxiosResponse<GetSponsorConfigResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_sponsor_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

/***************
 * POST CONFIG
 ***************/
export async function insertSponsorConfigData(token: string, data: SerieSponsorshipType): Promise<AxiosResponse<SerieSponsorshipType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/create_sponsor_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/***************
 * UPDATE CONFIG
 ***************/
export async function updateSponsorConfigData(token: string, data: SerieSponsorshipType): Promise<AxiosResponse<SerieSponsorshipType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_sponsor_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/***************
 * PAYMENTS CONFIG
 ***************/

export interface GetSponsorPaymentsParams {
	page?: number;
	page_size?: number;
	searchby?: string;
	searchvalue?: string;
	orderby?: string;
	order?: string;
	user_active?: boolean;
	is_profit?: boolean;
	initial_date?: string;
	final_date?: string;
}

export interface SponsorPaymentsData {
	id: string;
	userid: string;
	sponsor_id: string;
	amount: number;
	name: string;
	created_at: string;
	updated_at: string;
	is_profit: boolean;
}

export interface SponsorPaymentsResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: SponsorPaymentsData[];
}

export async function getSoponsorPayments(token: string, params: GetSponsorPaymentsParams): Promise<AxiosResponse<SponsorPaymentsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_sponsor_payment", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface CreateSponsorPaymentRequest {
	sponsor_id?: string;
	is_profit: boolean;
	amount: number;
	payment_id?: string;
}

export async function createSponsorPayment(token: string, data: CreateSponsorPaymentRequest): Promise<AxiosResponse<SponsorPaymentsData>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/create_sponsor_payment", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function removeSponsorPayment(token: string, id: string) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_sponsor_payment", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params: {payment_id: id},
	});
}

export async function editSponsorPayment(token: string, data: CreateSponsorPaymentRequest): Promise<AxiosResponse<SponsorPaymentsData>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_sponsor_payment", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
