import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {Fragment, useEffect, useState} from "react";
import {GetUserSubscriptionsResponse, Slots} from "../../../../../../data-access/subscriptions/subscriptions";
import {subscriptionActions} from "../../../../../../_store/features/subscriptions/subscriptions-slice";
import {ListPlus} from "../../../../../../components/primitives/icons";
import {Button} from "../../../../../../components/primitives/Button";
import NewSubscriptionSheet from "../components/NewSubscriptionSheet";
import SubscriptionsTable from "../../../../../users/edit/Tabs/Subscriptions/_subcomponents/SubscriptionsTable";

export default function Subscriptions() {
	const {id: userid} = useParams();

	if (!userid) return null;

	return (
		<div className="flex h-[90vh] flex-col">
			<SubscriptionPayments userid={userid} />
		</div>
	);
}

interface SubscriptionPaymentsProps {
	userid: string;
}

const SubscriptionPayments = ({userid}: SubscriptionPaymentsProps) => {
	const [openSubscription, setOpenSubscription] = useState(false);
	const dispatch = useAppDispatch();
	const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
	const [data, setData] = useState<GetUserSubscriptionsResponse | undefined>(undefined);

	const fetchUsersSubscriptions = () => {
		setLoadingSubscriptions(true);
		dispatch(subscriptionActions.getAllUserSubscriptions({userid, get_monthly_subscriptions: true}))
			.then(res => {
				if (res.meta.requestStatus === "rejected" || !res.payload) return;
				const subscriptionsData = res.payload as GetUserSubscriptionsResponse;
				setData(subscriptionsData);
			})
			.finally(() => setLoadingSubscriptions(false));
	};

	useEffect(() => {
		fetchUsersSubscriptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userid]);

	const handleAddSubscription = () => {
		setOpenSubscription(true);
	};

	const isNotMemberOfBuddyPlan =
		data?.results?.[0]?.slots?.length && (data?.results?.[0]?.slots as Slots[])?.every(slot => slot?.buddy_id !== userid);

	return (
		<Fragment>
			<div className="mx-6 flex items-center justify-between pb-2 pt-4">
				<h2 className="text-xl font-bold tracking-tight">Suscripciones</h2>

				<Button className="gap-2" onClick={handleAddSubscription}>
					<ListPlus />
					Nueva suscripción
				</Button>
			</div>
			<div className="px-6 pt-2">
				<SubscriptionsTable
					data={data?.results}
					isLoading={loadingSubscriptions}
					owner={!!isNotMemberOfBuddyPlan}
					onUpdate={fetchUsersSubscriptions}
					vendor
				/>
			</div>

			<NewSubscriptionSheet open={openSubscription} onOpenChange={setOpenSubscription} onRefresh={fetchUsersSubscriptions} />
		</Fragment>
	);
};
