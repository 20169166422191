import {IconProps} from "./type";

export function Spinner(props: IconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" className={"h-6 w-6 animate-spin"} {...props}>
			<g fill="none" fillRule="evenodd">
				<circle cx={7} cy={7} r={6} stroke="currentColor" strokeOpacity={0.3} strokeWidth={2} />
				<path fill="currentColor" fillOpacity={0.9} fillRule="nonzero" d="M7 0a7 7 0 0 1 7 7h-2a5 5 0 0 0-5-5V0z" />
			</g>
		</svg>
	);
}
