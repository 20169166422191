import {PresentationChart} from "../../../../primitives/icons";
import NavLink from "../NavLink";

export default function DashboardLink() {
	return (
		<div className="py-2">
			<NavLink to="/" label="Dashboard" icon={<PresentationChart />} />
		</div>
	);
}
