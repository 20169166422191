import {AuthErrorCodes, User} from "@firebase/auth";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UpdateProfileBody, updateDbUsers, updateProfile} from "../../../data-access/fetch-db-users";
import {auth} from "../../../firebase";
import {dbUser} from "../user-db/user-db-slice";
import {GetSponsorDataParams, UserAuth, getAdminUserData, getSponsorData} from "../../../data-access/user-auth";

export interface userAuthInterface {
	loading: boolean;
	userInfo: Partial<UserAuth> | null;
	error: string;
	success: boolean;
}

const initialState: userAuthInterface = {
	loading: false,
	userInfo: null,
	error: "",
	success: false,
};

const initGetUserData = createAsyncThunk("user/init", async (GCPUser: User, thunkAPI) => {
	try {
		//Signs Up User with google
		const token = await GCPUser.getIdToken();

		const response = await getAdminUserData(token);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

const getSponsorAdsData = createAsyncThunk("sponsor/data", async (data: GetSponsorDataParams, thunkAPI) => {
	try {
		if (!auth.currentUser) {
			return thunkAPI.rejectWithValue("Error no user is logged in");
		}

		const token = await auth.currentUser.getIdToken();

		const response = await getSponsorData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching user");
	}
});

const updateUser = createAsyncThunk("user/update", async (data: dbUser, thunkAPI) => {
	try {
		if (!auth.currentUser) {
			return thunkAPI.rejectWithValue("Error no user is logged in");
		}

		const token = await auth.currentUser.getIdToken();

		await updateDbUsers(token, data, true);

		await thunkAPI.dispatch(initGetUserData(auth.currentUser));
	} catch (error: any) {
		console.log(error);
		if (error?.response?.data?.result?.code === AuthErrorCodes.INVALID_PHONE_NUMBER) {
			return thunkAPI.rejectWithValue(error?.response?.data?.result?.message);
		}
		return thunkAPI.rejectWithValue("Problem updating data");
	}
});

const updateOwnProfile = createAsyncThunk("profile/update", async (data: UpdateProfileBody, thunkAPI) => {
	try {
		if (!auth.currentUser) {
			return thunkAPI.rejectWithValue("Error no user is logged in");
		}

		const token = await auth.currentUser.getIdToken();

		await updateProfile(token, data);
		await thunkAPI.dispatch(initGetUserData(auth.currentUser));
	} catch (error: any) {
		console.log(error);
		if (error?.response?.data?.result?.code === AuthErrorCodes.INVALID_PHONE_NUMBER) {
			return thunkAPI.rejectWithValue(error?.response?.data?.result?.message);
		}
		return thunkAPI.rejectWithValue("Problem updating data");
	}
});

const userAuthSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) {
			if (state.loading !== action.payload) state.loading = action.payload;
		},
		setUser(state, action: PayloadAction<any>) {
			state.userInfo = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(initGetUserData.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(initGetUserData.fulfilled, (state, action) => {
				state.loading = false;
				state.error = "";
				state.userInfo = action.payload;
				state.success = true;
			})
			.addCase(initGetUserData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(updateUser.pending, state => {
				state.loading = true;
				state.error = "";
				state.success = false;
			})
			.addCase(updateUser.fulfilled, state => {
				state.loading = false;
				state.error = "";
				state.success = true;
			})
			.addCase(updateUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(updateOwnProfile.pending, state => {
				state.loading = true;
				state.error = "";
				state.success = false;
			})
			.addCase(updateOwnProfile.fulfilled, state => {
				state.loading = false;
				state.error = "";
				state.success = true;
			})
			.addCase(updateOwnProfile.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});

export const userAuthActions = {...userAuthSlice.actions, initGetUserData, updateUser, updateOwnProfile, getSponsorAdsData};

export default userAuthSlice;
