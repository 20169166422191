import {Button} from "../../primitives/Button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../../primitives/Command/command";
import {Popover, PopoverContent, PopoverTrigger} from "../../primitives/Popover/popover";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";

import {addMilliseconds, isAfter} from "date-fns";
import {crewActions} from "../../../_store/features/crew/crew-slice";
import {ListPlus, PlusSmall, Spinner} from "../../primitives/icons";
import {StaffProfession} from "../../../data-access/series/staff";
import {useNavigate} from "react-router-dom";

const DEBOUNCE_TIME = 1000;

interface ProfessionSelectProps {
	onProfessionSelect: (profession: StaffProfession) => void;
	selectedProfessions?: StaffProfession[];
	disabled?: boolean;
}

export function ProfessionSelect({onProfessionSelect, selectedProfessions, disabled}: ProfessionSelectProps) {
	const isProfessionsLoading = useAppSelector(state => state.crew.staffProfessionsLoading);
	const professionsLastFetch = useAppSelector(state => state.crew.staffProfessionsLastFetch);
	const professions = useAppSelector(state => state.crew.staffProfessionsResults);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handleOpen = () => {
		if (!isProfessionsLoading && isAfter(Date.now(), addMilliseconds(professionsLastFetch ?? 0, DEBOUNCE_TIME))) {
			dispatch(crewActions.getStaffProfessionList({page: 0, page_size: 99999999}));
		}
	};

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button variant="outline" onClick={handleOpen}>
					<PlusSmall className="mr-2 h-5 w-5" />
					Añadir profesión
				</Button>
			</PopoverTrigger>
			<Button
				variant={"default"}
				onClick={() => {
					navigate("/crew/professions/new");
				}}
			>
				<ListPlus />
				Nueva profesión
			</Button>
			<PopoverContent className="w-[200px] p-0" align="start">
				<Command>
					<CommandInput placeholder={"Filtrar..."} />
					<CommandList>
						{isProfessionsLoading && (
							<CommandItem className="flex items-center justify-center">
								<Spinner />
							</CommandItem>
						)}
						<CommandEmpty>No hay profesiones.</CommandEmpty>
						<CommandGroup>
							{professions
								.filter(p => !selectedProfessions?.some(sp => sp.id === p.id))
								.map(profession => {
									return (
										<CommandItem key={profession.id} onSelect={() => onProfessionSelect(profession)} disabled={disabled}>
											<span>{profession.profession}</span>
										</CommandItem>
									);
								})}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
