import {IconProps} from "./type";
export const Versions = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6" viewBox="0 0 21 21" {...props}>
			<g strokeLinecap="round" strokeLinejoin="round" fill="none">
				<path d="m2.5 12.5 8 4 8.017-4M2.5 8.657l8.008 3.843 8.009-3.843L10.508 4.5z" />
			</g>
		</svg>
	);
};
