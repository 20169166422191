import {useNavigate} from "react-router-dom";
import {dashboardActions, selectedPartnerSerieSelector, selectedPartnerSeries} from "../../../_store/features/dashboard/dashboard-slice";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {useEffect} from "react";
import {useDashboardByNameQuery} from "../../../_store/features/dashboard/hooks";
import {Button} from "../../../components/primitives/Button";
import {Crown, HandThumbUpFilled, HeartFilled} from "../../../components/primitives/icons";
import Loader from "./Loader";

export function ProductsPartner() {
	const selectedPartnerSerie = useAppSelector(selectedPartnerSerieSelector);
	const partnerSeries = useAppSelector(selectedPartnerSeries);
	const {isLoading, isSuccess, isUninitialized} = useDashboardByNameQuery("topSeries");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isSuccess && isUninitialized) {
			dispatch(dashboardActions.getTopSeries());
		}
	}, [dispatch, isSuccess, isUninitialized]);

	useEffect(() => {
		if (!selectedPartnerSerie) {
			const activeSeriesPartnership = partnerSeries?.filter(el => el.active);
			if (!!activeSeriesPartnership && activeSeriesPartnership.length === 1) {
				dispatch(dashboardActions.setSelectedSerieId(activeSeriesPartnership[0].serieid));
				navigate(`/partner/series/${activeSeriesPartnership[0].serieid}`);
			}
		}
	}, [dispatch, navigate, partnerSeries, selectedPartnerSerie]);

	if (isLoading) return <Loader />;

	return (
		<>
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Audiovisuales</h2>
			</div>
			<div className="m-auto grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] items-center justify-center gap-4 px-8 py-6">
				{partnerSeries
					?.filter(el => el.active && !el.pronyr_original)
					?.map(el => (
						<div
							key={el.serieid + el.contentid}
							className="max-w-[250px] overflow-hidden rounded-md border border-border bg-card shadow-sm"
						>
							<img src={el?.img_thumbnail} alt="" className="aspect-video w-full" />
							<h3 className="scroll-m-20 overflow-hidden text-ellipsis whitespace-nowrap border-b border-border p-4 text-lg font-semibold tracking-tight">
								{el.title}
							</h3>
							<div className="p-6 py-4 text-sm">
								<div className="flex items-center gap-2">
									<HandThumbUpFilled className="h-4 w-4" />
									<span>Like:</span>
									<span>{el.likes}</span>
								</div>
								<div className="flex items-center gap-2">
									<HeartFilled className="h-4 w-4" />
									<span>Favoritos:</span>
									<span>{el.favorites}</span>
								</div>
								<div className="flex items-center gap-2">
									<Crown className="h-4 w-4" />
									<span>Miembros:</span>
									<span>{el.members}</span>
								</div>
							</div>
							<Button
								variant="ghost"
								onClick={() => {
									dispatch(dashboardActions.setSelectedSerieId(el.serieid));
									if (el.pronyr_original) {
										navigate(`/partner/series/${el.serieid}`);
									} else {
										navigate(`${el.serieid}`);
									}
								}}
								className="w-full border-t border-border"
							>
								Ver Detalles
							</Button>
						</div>
					))}
			</div>
		</>
	);
}
