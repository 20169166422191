import {Fragment} from "react";
import Select, {MultiValue} from "react-select";
import {selectStyles} from "../../../../../utils/SelectStyles";
import {SelectOptionType} from "../../../../../utils/globalTypes";

interface SelectContentBySerieProps {
	series: SelectOptionType[];
	contents: SelectOptionType[];
	isSerieLoading?: boolean;
	isContentsLoading?: boolean;
	disableSelectContent?: boolean;
	handleSerieSelectRef?: (ref: any) => void;
	handleContentSelectRef?: (ref: any) => void;
	handleSelectSerie: (newValue: MultiValue<SelectOptionType>) => void;
	handleSelectContent: (newValue: MultiValue<SelectOptionType>) => void;
}

export default function SelectContentBySerie({
	series,
	contents,
	isSerieLoading,
	isContentsLoading,
	disableSelectContent,
	handleSerieSelectRef,
	handleContentSelectRef,
	handleSelectSerie,
	handleSelectContent,
}: SelectContentBySerieProps) {
	return (
		<Fragment>
			<Select
				ref={handleSerieSelectRef}
				className="basic-select w-full text-sm"
				styles={selectStyles}
				isSearchable={true}
				options={series ?? []}
				classNamePrefix="select"
				onChange={prop => {
					handleSelectSerie(prop);
				}}
				placeholder="Serie"
				isLoading={isSerieLoading}
				isDisabled={isSerieLoading}
				isMulti
			/>
			<Select
				ref={handleContentSelectRef}
				className="basic-select w-full text-sm"
				styles={selectStyles}
				isSearchable={true}
				options={contents ?? []}
				classNamePrefix="select"
				onChange={prop => {
					handleSelectContent(prop);
				}}
				placeholder="Contenido"
				isDisabled={isContentsLoading || disableSelectContent}
				isLoading={isContentsLoading}
				isMulti
			/>
		</Fragment>
	);
}
