import {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {dashboardActions, selectedPartnerSerieSelector} from "../../../../_store/features/dashboard/dashboard-slice";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {createColumnHelper} from "@tanstack/react-table";
import {LastTenPurchase} from "../../../../data-access/dashboard/types";
import {RENTALS_TYPES} from "../../../../constants";
import {DataTable} from "../../../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {formattedDayAndHour} from "../../../../../utils/formattedDayAndHour";
import {DatePickerWithRange} from "../../../../components/blocks/DateRangePicker";
import {endOfToday, formatISO9075, roundToNearestMinutes, startOfDay, subDays} from "date-fns";
import {DateRange} from "react-day-picker";
import IncomeRentalsCards from "./_subcomponents/income-cards";
import RentalsChart from "./_subcomponents/rentals-chart";

const columnHelper = createColumnHelper<LastTenPurchase>();

export function ProductStatistics({disableRedirect = false}: {disableRedirect?: boolean}) {
	const {id} = useParams();
	const selectedPartnerSerie = useAppSelector(selectedPartnerSerieSelector);
	const {isLoading, data} = useDashboardByNameQuery("rentalsIncomeEarning");
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = startOfDay(subDays(Date.now(), 30));
		return {
			from,
			to,
		};
	});

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!selectedPartnerSerie) {
			dispatch(dashboardActions.setSelectedSerieId(id));
		}
		if (id !== "") {
			dispatch(
				dashboardActions.getPartnersSeriesProfitByRent({
					seriesid: id,
					initial_date: formatISO9075(dates?.from!),
					final_date: formatISO9075(dates?.to!),
				}),
			);
		}
	}, [dates, dispatch, id, selectedPartnerSerie]);

	useEffect(() => {
		if (!selectedPartnerSerie && !disableRedirect) navigate("/partner/series");
	}, [disableRedirect, navigate, selectedPartnerSerie]);

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 5);

		return [
			columnHelper.accessor("rental_type", {
				id: "rental_type",
				header: ({column}) => <DataTableColumnHeader title="Tipo de renta" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">
							{RENTALS_TYPES.find(rt => rt.value === info.row.original.rental_type)?.label ?? ""}
						</span>
					</div>
				),
				size: colWidth / 2,
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha",
				header: ({column}) => <DataTableColumnHeader title="Fecha" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col ">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("platform", {
				id: "platform",
				header: ({column}) => <DataTableColumnHeader title="Plataforma de pago" column={column} />,
				cell: ({row: {original}}) => {
					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{original.platform}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("stripe_price", {
				id: "stripe_price",
				header: ({column}) => <DataTableColumnHeader title="Precio" column={column} />,
				cell: info => {
					const price = (info.row.original.stripe_price / 100).toLocaleString("en-US", {style: "currency", currency: "USD"});

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{price}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("fee", {
				id: "fee",
				header: ({column}) => <DataTableColumnHeader title="Impuestos" column={column} />,
				cell: info => {
					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">
								{info.row.original.fee.toLocaleString("en-US", {style: "currency", currency: "USD"})}
							</span>
						</div>
					);
				},
				size: colWidth - 20,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const handleDateRangeChange = (s: number, e: number, _: any) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	return (
		<>
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<div className="flex items-center justify-between bg-background">
					<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">{selectedPartnerSerie?.title || ""}</h2>
				</div>

				<DatePickerWithRange
					onDateRangeChange={handleDateRangeChange}
					disabled={{before: new Date('2023-10-01T00:00:00-05:00'), after: new Date()}}
					date={dates}
				/>
			</div>

			<section className="flex flex-col p-6 py-4">
				<IncomeRentalsCards />
				<RentalsChart dates={dates} />
				<div className="flex h-full flex-col space-y-4">
					<div className="flex items-center justify-between">
						<h2 className="scroll-m-20 text-lg font-extrabold tracking-tight">Últimas compras</h2>
					</div>
					<DataTable
						ref={ref => setTableRef(ref)}
						columns={columns}
						dataset={data?.lastTenPurchases ?? []}
						tableAvailableHeight={1000}
						pagination={{pageIndex: 0, pageSize: 10}}
						pageCount={1}
						withDynamicPageSize
						loading={isLoading}
						rowHeight={53}
						showPagination={false}
					/>
				</div>
			</section>
		</>
	);
}
