import axios, {AxiosResponse} from "axios";
import {z} from "zod";
const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface ListPublicityRangeResponse {
	loading: boolean;
	totalResults: number;
	pageSize: number;
	current: number;
	results: PublicityRange[];
	success: boolean;
}

export interface PublicityRange {
	id: string;
	content_id: string;
	title: string;
	start_time: number;
	rotation_interval: number;
	end_time: number;
}

export interface GetPublicityListParams {
	page: number;
	page_size: number;
	searchby?: "id" | "content_id";
	searchvalue?: string;
}

export async function getPublicityRangeList(token: string, params?: GetPublicityListParams): Promise<AxiosResponse<ListPublicityRangeResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_publicity_range", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const InsertPublicityBannerRangeBodySchema = z.object(
	{
		id: z.string().optional(),
		content_id: z.string().nonempty("El texto del anuncio no puede estar vacío."),
		start_time: z.number({invalid_type_error: "Este campo requiere un numero."}),
		rotation_interval: z.number({invalid_type_error: "Este campo requiere un numero."}),
		end_time: z.number({invalid_type_error: "Este campo requiere un numero."}),
	},
	{required_error: "Este campo es requerido."},
);
export type InsertPublicityBannerRangeBodyType = z.infer<typeof InsertPublicityBannerRangeBodySchema>;

export interface InsertPublicityBannerRangeResponse {
	id: string;
	content_id: string;
	start_time: number;
	rotation_interval: number;
	end_time: number;
	title: string;
}

export async function insertPublicityBannerRangeData(
	token: string,
	data: InsertPublicityBannerRangeBodyType,
): Promise<AxiosResponse<InsertPublicityBannerRangeResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/admin_create_publicity_range", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface DeletePublicityBannerRangeBody {
	id: string;
}

interface EditDeletePublicityBannerRangeResponse {}

export async function deletePublicityBannerRangeData(
	token: string,
	data?: DeletePublicityBannerRangeBody,
): Promise<AxiosResponse<EditDeletePublicityBannerRangeResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete(`/admin_delete_publicity_range?id=${data?.id}`, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function editPublicityBannerRangeData(
	token: string,
	data: InsertPublicityBannerRangeBodyType,
): Promise<AxiosResponse<EditDeletePublicityBannerRangeResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/admin_edit_publicity_range", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
