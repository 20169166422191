import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface getTagsParams {
	page?: number;
	page_size?: number;
	searchby?: string;
	searchvalue?: string;
}
export interface getTagsResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: TagsResult[];
}

export interface TagsResult {
	id: string;
	tag: string;
}

export async function getTags(token: string, params?: getTagsParams) {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/list_tags_metadata_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface InsertTagsParams {
	tag: string;
}

export interface InsertTagsResponse {
	id: string;
	tag: string;
}

export async function insertTags(token: string, data: InsertTagsParams): Promise<AxiosResponse<InsertTagsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_tags_metadata_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface insertVideoTagsParams {
	tag_id: string;
	short_video_id: string;
}

export interface InsertVideoTagsResponse {
	short_videos_tags_id: string;
	id: string;
	tag: string;
}

export async function insertVideoTags(token: string, data: insertVideoTagsParams): Promise<AxiosResponse<InsertVideoTagsResponse[]>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_short_videos_tags_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface deleteTagsParams {
	id: string;
}

interface deleteTagsResponse {
	result: string;
}

export async function deleteTags(token: string, data: deleteTagsParams): Promise<AxiosResponse<deleteTagsResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.delete("/delete_tags_metadata_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export async function editTags(token: string, data: InsertTagsResponse): Promise<AxiosResponse<InsertTagsResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.patch("/edit_tags_metadata_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface InsertTagsToVideoParams {
	tag_ids: null | string;
	short_videos_id: string;
}

export interface InsertTagsToVideosResponse {
	short_videos_tags_id: string;
	id: string;
	tag: string;
}

export async function insertTagsToVideo(token: string, data: InsertTagsToVideoParams): Promise<AxiosResponse<InsertTagsToVideosResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/insert_short_videos_tags_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
