import axios, {AxiosResponse} from "axios";
import {dbUser, userDbInterface} from "../_store/features/user-db/user-db-slice";
import {z} from "zod";
import {USER_ROLES} from "../constants";

const axiosHandler = axios.create({baseURL: process.env.REACT_APP_APP_DOMAIN});

export type FetchDbUsersParams = {
	idToken: string;
	page: number;
	page_size?: number;
	admin: boolean;
	searchby?: "id" | "name" | "email" | "phone" | "provider" | "referral_code" | "device_id";
	searchvalue?: string;
	role?: string;
	active?: boolean;
	associate?: boolean;
};

export const fetchDbUsers = async (props: FetchDbUsersParams): Promise<AxiosResponse<userDbInterface>> => {
	return await axios.get(`${process.env.REACT_APP_APP_DOMAIN as string}/list_db_users`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${props.idToken}`,
		},
		params: {
			admin: props.admin,
			page: props.page,
			searchby: props.searchby,
			searchvalue: props.searchvalue,
			role: props.role,
			active: props.active,
			page_size: props.page_size,
			associate: props.associate,
		},
	});
};

export const fetchDbInternalUsers = async ({
	idToken,
	page,
	page_size,
	searchvalue,
}: {
	idToken: string;
	page: number;
	page_size?: number;
	searchvalue?: string;
}) => {
	return await axios.get(`${process.env.REACT_APP_APP_DOMAIN as string}/admin_list_customer_service`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${idToken}`,
		},
		params: {
			page: page,
			q: searchvalue,
			page_size,
		},
	});
};

export const updateDbUsers = async (idToken: string, userData: dbUser, admin: boolean) => {
	return await axios.patch(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_update_user_data`,
		{
			...userData,
			admin: admin,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: `Bearer ${idToken}`,
			},
		},
	);
};

export interface UpdateProfileBody {
	email: string;
	email_verified?: boolean;
	password?: string;
	disabled?: boolean;
	name: string;
	phone: string;
	phone_verified?: boolean;
	coin?: number;
	provider?: string;
	referral_code?: string;
}

export const updateProfile = async (idToken: string, data: UpdateProfileBody) => {
	return await axios.patch(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_update_profile_data`,
		{
			...data,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: `Bearer ${idToken}`,
			},
		},
	);
};

export const createAdminPasswordRequest = async (
	token: string | undefined,
	email: string,
	recaptchaToken: string,
	action: string,
	admin: boolean = false,
) => {
	return await axios.post(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_password_email_request`,
		{
			email: email,
			recaptchatoken: recaptchaToken,
			action: action,
			admin: admin,
		},
		{
			headers: {
				Authorization: token,
				"Content-Type": "application/json",
				Accept: "*/*",
			},
		},
	);
};

export const adminUpdatePassword = async (idToken: string, userid: string, password: string, admin: boolean) => {
	return await axios.patch(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_update_password`,
		{
			userid: userid,
			password: password,
			admin: admin,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: `Bearer ${idToken}`,
			},
		},
	);
};

export const adminMigrateOneUserSuperTokens = async (idToken: string, firebaseid: string) => {
	return await axios.post(
		`${process.env.REACT_APP_APP_DOMAIN as string}/migrate_one_userid_identity_to_supertokens`,
		{
			firebaseid: firebaseid,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: `Bearer ${idToken}`,
			},
		},
	);
};

export const execPasswordReset = async (hash: string, password: string, admin: boolean = false) => {
	return await axios.post(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_password_email_reset`,
		{hash, password, admin},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
			},
		},
	);
};

export const NewUserBodySchema = z.object(
	{
		email: z.string().email({message: "El email es invalido."}),
		email_verified: z.boolean(),
		password: z.string().min(6, "La contraseña debe tener un mínimo de 6 caracteres."),
		disabled: z.boolean(),
		name: z.string().nonempty("El nombre de usuario es requerido."),
		phone: z.string(),
		phone_verified: z.boolean(),
		coin: z.number(),
		admin: z.boolean(),
		master_editor: z.boolean(),
		editor: z.boolean(),
		partner: z.boolean(),
		sponsor: z.boolean(),
		influencer: z.boolean(),
		customer_service: z.boolean(),
		marketing: z.boolean(),
		associate: z.boolean(),
		corporate: z.boolean(),
		customerservice_l1: z.boolean(),
		vendor: z.boolean(),
	},
	{required_error: "Este campo es requerido."},
);
export type NewUserBodyType = z.infer<typeof NewUserBodySchema>;
export type ROLES = keyof Pick<
	NewUserBodyType,
	| "admin"
	| "master_editor"
	| "editor"
	| "partner"
	| "sponsor"
	| "influencer"
	| "customer_service"
	| "marketing"
	| "corporate"
	| "customerservice_l1"
	| "vendor"
>;

export const NewInternalUserBodySchema = z.object(
	{
		name: z.string().nonempty("El nombre de usuario es requerido."),
		nickname: z.string().nonempty("El nombre de usuario es requerido."),
	},
	{required_error: "Este campo es requerido."},
);
export type NewInternalUserBodyType = z.infer<typeof NewInternalUserBodySchema>;

interface NewUserBodyResponse {
	coin: number;
	name: string;
	email: string;
	phone: string;
	userid: string;
	disabled: boolean;
	provider: string;
	photo_url: string;
	verify_hash: string;
	audit_created: string;
	audit_updated: string;
	password_hash: string;
	email_verified: boolean;
	phone_verified: boolean;
	admin: boolean;
	master_editor: boolean;
	editor: boolean;
	partner: boolean;
	sponsor: boolean;
	influencer: boolean;
	customer_service: boolean;
	marketing: boolean;
	corporate: boolean;
	customerservice_l1: boolean;
	vendor: boolean;
}

export const adminCreateUser = async (token: string, data: NewUserBodyType): Promise<AxiosResponse<NewUserBodyResponse>> => {
	const isAdminRoles = Object.keys(data).some(function (k) {
		return USER_ROLES.includes(k as unknown as ROLES) && data[k as ROLES] === true;
	});

	return await axiosHandler.post(
		"/admin_create_user",
		{...data, authentication: isAdminRoles ? "firebase" : "supertokens"},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
};

export const adminCreateInternalUser = async (token: string, data: NewInternalUserBodyType): Promise<AxiosResponse<NewUserBodyResponse>> => {
	return await axiosHandler.post("/admin_create_internal_customer_service", data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

interface ExportUserListParams {
	email: undefined | boolean;
	name: undefined | boolean;
	phone: undefined | boolean;
	coin: undefined | boolean;
	disabled: undefined | boolean;
	email_verified: undefined | boolean;
	phone_verified: undefined | boolean;
}

export const exportUserList = async (token: string, params: ExportUserListParams) => {
	return await axios.get(`${process.env.REACT_APP_APP_DOMAIN as string}/export_user_list`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${token}`,
		},
		params,
	});
};

interface ExportAdminListParams {
	email: undefined | boolean;
	name: undefined | boolean;
	phone: undefined | boolean;
	coin: undefined | boolean;
	disabled: undefined | boolean;
	email_verified: undefined | boolean;
	phone_verified: undefined | boolean;
}

export const exportAdminList = async (token: string, params: ExportAdminListParams) => {
	return await axios.get(`${process.env.REACT_APP_APP_DOMAIN as string}/export_admin_list`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${token}`,
		},
		params,
	});
};

export const sponsorsList = async (token: string, params: null) => {
	return await axios.get(`${process.env.REACT_APP_APP_DOMAIN as string}/list_db_users_sponsor`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${token}`,
		},
		params,
	});
};
