import React from "react";
import {partnerReportsActions} from "../../../../../_store/features/reports/report-slice";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {Button} from "../../../../../components/primitives/Button";
import {Spinner, Report as ReportIcon} from "../../../../../components/primitives/icons";

interface GenerateReportButtonProps {
	force?: boolean;
	isCorporate?: boolean;
	partner?: string;
}

const GenerateReportButton = (props: GenerateReportButtonProps) => {
	const {force = false, isCorporate, partner} = props;
	const dispatch = useAppDispatch();
	const {generatingReport} = useAppSelector(state => state.reports);

	const text = force ? "Volver a generar" : "Generar reporte";

	const handleOnGenerateReport = () => {
		console.log("Generando reporte");
		dispatch(partnerReportsActions.generateReport({force, partner}));
	};

	if (!isCorporate) return null;

	return (
		<div className="flex items-center justify-center">
			<Button onClick={handleOnGenerateReport} variant={"blueBtn"} size={"sm"} type={"button"} disabled={generatingReport}>
				<div className="flex items-center space-x-2">
					{generatingReport ? <Spinner /> : <ReportIcon className="h-4 w-4" />}
					<span className="flex items-center text-sm font-medium">{text}</span>
				</div>
			</Button>
		</div>
	);
};

export default GenerateReportButton;
