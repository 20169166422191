import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {ContentRankingParams, RankedContent, getContentsRanking} from "../../../data-access/series/content";

interface ScoreByContents {
	rank_number: number;
	comments: number;
	donations: number;
	follows: number;
	likes: number;
	stickers: number;
	views: number;
}
export interface contentsRankingInterface {
	loading: boolean;
	totalResults: number;
	pageSize: number;
	current: number;
	results: RankedContent[];
	listOfScoreByContent: ScoreByContents[];
}

const initialState: contentsRankingInterface = {
	loading: false,
	totalResults: 0,
	pageSize: 0,
	current: 0,
	results: [],
	listOfScoreByContent: [],
};

const getRankedContents = createAsyncThunk("contents-ranking/list", async (params: ContentRankingParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");
		const response = await getContentsRanking(token, params);
		let scoreList: ScoreByContents[] = [];
		if (params.serie_id || params.content_type !== undefined) {
			const allSeries = await getContentsRanking(token, {
				content_type: params.content_type,
				serie_id: params.serie_id,
				page: 0,
				page_size: 9999,
			});
			scoreList = allSeries.data.results.length
				? allSeries.data.results.map(({rank_number, comments, donations, follows, likes, stickers, views}) => ({
						rank_number,
						comments,
						donations,
						follows,
						likes,
						stickers,
						views,
				  }))
				: [];
		}

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
			listOfScoreByContent: scoreList,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const contentRankingSlice = createSlice({
	name: "contents",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getRankedContents.pending, state => {
				state.loading = true;
			})
			.addCase(getRankedContents.fulfilled, (state, action) => {
				state.loading = false;
				state.current = action.payload.current;
				state.pageSize = action.payload.pageSize;
				state.totalResults = action.payload.totalResults;
				state.results = [...action.payload.results];
				state.listOfScoreByContent = [...action.payload.listOfScoreByContent];
			})
			.addCase(getRankedContents.rejected, (state, action) => {});
	},
});

const contentScoreSelector = createSelector(
	(state: {contentsRanking: contentsRankingInterface}) => state.contentsRanking.listOfScoreByContent,
	scoreList => {
		const initialAcc = {
			total: 0,
			totalViewsByContents: 0,
			totalLikesByContents: 0,
			totalFollowsByContents: 0,
			totalCommentsByContents: 0,
			totalDonationsByContents: 0,
			totalStickersByContents: 0,
		};
		const updatedAcc = scoreList.reduce((acc, item) => {
			return {
				total: acc.total + item.rank_number,
				totalViewsByContents: acc.totalViewsByContents + item.views,
				totalLikesByContents: acc.totalLikesByContents + item.likes,
				totalFollowsByContents: acc.totalFollowsByContents + item.follows,
				totalCommentsByContents: acc.totalCommentsByContents + item.comments,
				totalDonationsByContents: acc.totalDonationsByContents + item.donations,
				totalStickersByContents: acc.totalStickersByContents + item.stickers,
			};
		}, initialAcc);
		return updatedAcc;
	},
);

export const contentRankingSelectors = {contentScoreSelector};

export const contentRankingActions = {...contentRankingSlice.actions, getRankedContents};

export default contentRankingSlice;
