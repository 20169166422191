import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import ActionConfirmModal from "../../components/blocks/ActionConfirmModal";
import {Eye, ListPlus, Pencil, Trash} from "../../components/primitives/icons";
import {Refresh} from "../../components/primitives/icons/Refresh";
import {Advertisement} from "../../data-access/advertisers";
import {advertisementsActions} from "../../_store/features/advertisements/advertisements-slice";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {DataTable} from "../../components/blocks/DataTable";
import {Button} from "../../components/primitives/Button";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import {Badge} from "../../components/primitives/Badge";

const searchOptions = [
	{name: "Texto Anuncio", value: "text"},
	{name: "Texto Sponsor", value: "sponsor"},
	{name: "Id", value: "id"},
];

const publishedOptions = [
	{name: "Todos", value: undefined},
	{name: "Activo", value: true},
	{name: "Inactivo", value: false},
];

const columnHelper = createColumnHelper<Advertisement>();

function AdvertisersLists() {
	const dispatch = useAppDispatch();
	const advertisements = useAppSelector(state => state.advertisements);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedPublished, setSelectedPublished] = useState(publishedOptions[0]);
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [deleteAdvertisementID, setDeleteAdvertisementID] = useState("");
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const handleSearch = () => {
		const params: Record<string, any> = {};
		params.published = selectedPublished.value;
		//Tipo General
		params.publicity_type = 0;

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		if (!searchValue && !selectedPublished) return;
		if (page.pageSize === 0) return;

		dispatch(
			advertisementsActions.getAdvertisements({
				page_size: page.pageSize,
				page: page.pageIndex,
				...params,
			}),
		);
	};

	useEffect(() => {
		if (!page.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("order", {
				id: "Orden",
				header: ({column}) => <DataTableColumnHeader title="Orden" column={column} />,
				cell: info => <div className="ml-2 flex w-full text-left">{info.getValue()}</div>,
				size: Math.round(tableWidth * 0.075),
				enableResizing: false,
				enableSorting: false,
			}),
			columnHelper.accessor("img_web", {
				id: "Imagen",
				header: ({column}) => <DataTableColumnHeader title="Imagen" column={column} />,
				cell: info => <img alt="Banner" src={info.getValue()} className="ml-2 h-14 object-contain" />,
				size: Math.round(tableWidth * 0.15),
			}),
			columnHelper.accessor("text", {
				id: "Texto Anuncio / Id",
				header: ({column}) => <DataTableColumnHeader title="Texto Anuncio / Id" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.text}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.round(tableWidth * 0.3),
			}),
			columnHelper.accessor("type_publicity", {
				id: "Tipo",
				header: ({column}) => <DataTableColumnHeader title="Tipo" column={column} />,
				cell: info =>
					info.row.original.type_publicity === 0 ? (
						<div className="ml-2 flex w-full gap-2 text-left">General</div>
					) : (
						<div className="ml-2 flex w-full gap-2 text-left">Sponsor</div>
					),
				size: Math.round(tableWidth * 0.1),
			}),
			columnHelper.accessor("sponsor", {
				id: "Texto Sponsor",
				header: ({column}) => <DataTableColumnHeader title="Texto Sponsor" column={column} />,
				cell: info => (
					<div className="ml-2 w-full text-left">
						<span className="overflow-hidden text-ellipsis">{info.row.original.sponsor}</span>
					</div>
				),
				size: Math.round(tableWidth * 0.15),
			}),
			columnHelper.accessor("published", {
				id: "Publicado",
				header: ({column}) => <DataTableColumnHeader title="Publicado" column={column} />,
				cell: info => (
					<div className="flex w-full">
						{info.getValue() ? (
							<Badge variant={"success"} className="ml-2 flex max-h-[20px] text-left">
								Activo
							</Badge>
						) : (
							<Badge variant={"destructive"} className="ml-2 flex max-h-[20px] text-left">
								Inactivo
							</Badge>
						)}
					</div>
				),
				size: Math.round(tableWidth * 0.1),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="justify-left flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/advertisements/edit/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/advertisements/visibility/" + info.row.original.id + "/banner"}>
								<Eye className="h-4 w-4" />
							</Link>
						</Button>
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setDeleteAdvertisementID(info.row.original.id);
								setDeleteConfirmationModalOpen(true);
							}}
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
				size: Math.round(tableWidth * 0.1),
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const refresh = () => {
		setSelectedPublished(publishedOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		setPage({
			...page,
			pageIndex: 0,
		});
		dispatch(
			advertisementsActions.getAdvertisements({
				page_size: page.pageSize,
				page: page.pageIndex,
				publicity_type: 0,
			}),
		);
	};

	const handleVisibilityFilter = (value: string) => {
		const status = publishedOptions.find(el => el.name === value) ?? publishedOptions[0];
		setSelectedPublished(status);

		dispatch(
			advertisementsActions.getAdvertisements({
				page_size: page.pageSize,
				page: page.pageIndex,
				published: status.value,
				publicity_type: 0,
			}),
		);
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Anuncios Generales</h2>
				<Button className="gap-2" asChild>
					<Link to="/advertisements/new">
						<ListPlus />
						Nuevo Anuncio
					</Link>
				</Button>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>

					<Select onValueChange={handleVisibilityFilter} value={selectedPublished.name}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{publishedOptions.map((pOption, idx) => (
									<SelectItem key={idx} value={`${pOption.name}`}>
										{pOption.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={advertisements.results}
					pageCount={Math.ceil(advertisements.totalResults / advertisements.pageSize)}
					pagination={page}
					loading={advertisements.loading}
					onPaginationChange={setPage}
					withDynamicPageSize
					rowHeight={53}
					showPagination={false}
				/>
			</div>
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={() => {
					dispatch(advertisementsActions.deleteAdvertisement(deleteAdvertisementID));

					setDeleteConfirmationModalOpen(false);
					setDeleteAdvertisementID("");
				}}
				title={"Eliminar Anuncio"}
				description={<span className="text-white">Al confirmar eliminaras el anuncio, este proceso no se puede revertir.</span>}
			/>
		</div>
	);
}

export default AdvertisersLists;
