import {IconProps} from "./type";

export const DayNight = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Icons" viewBox="0 0 32 32" className="h-6 w-6" {...props}>
			<style>{".st0{fill:none;strokeWidth:2;strokeLinecap:round;strokeLinejoin:round;stroke-miterlimit:10}"}</style>
			<path
				d="M16 3v26M16 23c-3.87 0-7-3.13-7-7s3.13-7 7-7M6.81 6.81l2.12 2.12M3 16h3M6.81 25.19l2.12-2.12M16 12.55A6.992 6.992 0 0 1 22.09 9c.16 0 .31.01.47.02a5.25 5.25 0 0 0 2.45 9.89c1.6 0 3.03-.72 3.99-1.85-.52 3.37-3.41 5.94-6.91 5.94-2.61 0-4.89-1.43-6.09-3.55"
				className="st0"
				stroke="currentColor"
			/>
		</svg>
	);
};
