import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ListPlus} from "../../../../../components/primitives/icons";
import {Button} from "../../../../../components/primitives/Button";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {subscriptionActions} from "../../../../../_store/features/subscriptions/subscriptions-slice";
import {GetUserSubscriptionsResponse, Slots, UserSubscription} from "../../../../../data-access/subscriptions/subscriptions";
import toast from "react-hot-toast";
import {EditSubscriptionModal, ExtendedUserSubscription, subscriptionsInitialState} from "./_subcomponents/EditSubscriptionModal";
import SubscriptionsTable from "./_subcomponents/SubscriptionsTable";
import SlotsTable from "./_subcomponents/SlotsTable";
import useCurrentUserRole from "../../../../../components/hooks/useCurrentUserRole";

export default function Subscriptions() {
	const {id: userid} = useParams();
	const subscriptions = useAppSelector(state => state.subscription);
	const dispatch = useAppDispatch();
	const {isCSL1} = useCurrentUserRole();
	const [isNew, setIsNew] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedSubscription, setSelectedSubscription] = useState<ExtendedUserSubscription>(subscriptionsInitialState);
	const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [data, setData] = useState<GetUserSubscriptionsResponse | undefined>(undefined);

	const fetchUsersSubscriptions = () => {
		setLoadingSubscriptions(true);
		dispatch(subscriptionActions.getAllUserSubscriptions({userid}))
			.then(res => {
				if (res.meta.requestStatus === "rejected" || !res.payload) return;
				const subscriptionsData = res.payload as GetUserSubscriptionsResponse;
				setData(subscriptionsData);
			})
			.finally(() => setLoadingSubscriptions(false));
	};

	useEffect(() => {
		fetchUsersSubscriptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userid]);

	useEffect(() => {
		dispatch(subscriptionActions.getSubscriptions({}));
		return () => {
			dispatch(subscriptionActions.reset());
		};
	}, [userid, dispatch]);

	const handleSubscriptionChange = (data: ExtendedUserSubscription) => {
		const selectedSubscription = subscriptions.results.find(s => s.subscription_type === data.subscription_type);
		if (!selectedSubscription) return toast.error("Suscripción no encontrada");

		setLoadingSubmit(true);
		if (data.isNew) {
			dispatch(
				subscriptionActions.createUserSubscription({
					...data,
					productid: selectedSubscription.id,
					userid: userid!,
				}),
			)
				.then(() => toast.success("Suscripción creada correctamente"))
				.catch(() => toast.error("Ha ocurrido un problema al crear la suscripción"))
				.finally(() => {
					fetchUsersSubscriptions();
					setIsEditModalOpen(false);
					setLoadingSubmit(false);
				});
			return;
		}
		dispatch(
			subscriptionActions.editUserSubscription({
				...data,
				productid: selectedSubscription.id,
				userid: userid!,
			}),
		)
			.then(() => toast.success("Suscripción actualizada correctamente"))
			.catch(() => toast.error("Ha ocurrido un problema al actualizar la suscripción"))
			.finally(() => {
				fetchUsersSubscriptions();
				setIsEditModalOpen(false);
				setLoadingSubmit(false);
			});
	};

	const handleAddSubscription = () => {
		setIsNew(true);
		setIsEditModalOpen(true);
	};

	const onSelectSubscription = (subscription: UserSubscription) => {
		setSelectedSubscription(subscription);
		setIsNew(false);
		setIsEditModalOpen(true);
	};

	const hasSubscriptions = !!data?.results?.length;
	const isNotMemberOfBuddyPlan =
		data?.results?.[0]?.slots?.length && (data?.results?.[0]?.slots as Slots[])?.every(slot => slot?.buddy_id !== userid);

	return (
		<div className="flex h-[90vh] flex-col">
			<div className="mx-6 flex items-center justify-between pb-2 pt-4">
				<h2 className="text-xl font-bold tracking-tight">Suscripciones</h2>
				{(!!isNotMemberOfBuddyPlan || !hasSubscriptions) && !isCSL1 && (
					<Button className="gap-2" onClick={handleAddSubscription}>
						<ListPlus />
						Nueva suscripción
					</Button>
				)}
			</div>
			<div className="px-6 pt-2">
				<SubscriptionsTable
					data={data?.results}
					onSelectRow={onSelectSubscription}
					isLoading={loadingSubscriptions}
					owner={!!isNotMemberOfBuddyPlan}
					onUpdate={fetchUsersSubscriptions}
				/>
			</div>
			{!!isNotMemberOfBuddyPlan && (
				<div className="border-t px-6 pt-8">
					<h3 className="font-semibold">Listado de cupos del Plan Amigos</h3>
					<SlotsTable
						data={data?.results}
						onSelectRow={onSelectSubscription}
						isLoading={loadingSubscriptions}
						onUpdate={fetchUsersSubscriptions}
					/>
				</div>
			)}
			<EditSubscriptionModal
				subscription={selectedSubscription}
				onDismiss={() => setIsEditModalOpen(false)}
				open={isEditModalOpen}
				onSubmit={handleSubscriptionChange}
				activeSubscriptions={subscriptions}
				isNew={isNew}
				loadingSubmit={loadingSubmit}
			/>
		</div>
	);
}
