import Logo from "../../primitives/Logo";
import {cn} from "../../../../utils/classNames";
import DashboardLink from "./subcomponents/DashboardLink";
import {RoleBasedNavigation} from "./subcomponents/RoleBasedNavigation";
import UserProfileLink from "./subcomponents/UserProfileLink";
import {Button} from "../../primitives/Button";
import {DayNight} from "../../primitives/icons";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";
import ContactLink from "./subcomponents/ContactLink";

function Navigation() {
	const {activeRole: currentUserRole} = useCurrentUserRole();
	const toggleTheme = () => {
		if (document.documentElement.classList.contains("dark")) {
			return document.documentElement.classList.remove("dark");
		}

		document.documentElement.classList.add("dark");
	};

	return (
		<nav
			className={cn(
				"fixed bottom-0 left-0 top-0 flex h-full w-64 shrink-0 flex-col overflow-hidden",
				"border-r border-border bg-background transition-all duration-200 ease-out",
				"bg-navigation text-navigation-foreground",
			)}
		>
			<div className="ml-4 w-40">
				<Logo />
			</div>
			<div className="flex items-center justify-between border-y border-navigation-foreground/10 px-2 py-3 pl-5 text-sm font-bold uppercase">
				<div>Administración</div>
				<Button onClick={toggleTheme} variant={"outline"} className={cn("border-navigation-foreground/10")} size={"sm"}>
					<DayNight />
				</Button>
			</div>
			{currentUserRole === null ? <></> : <>{currentUserRole === "customer_service" ? <ContactLink /> : <DashboardLink />}</>}
			<RoleBasedNavigation />
			<UserProfileLink />
		</nav>
	);
}

export default Navigation;
