import {PropsWithChildren, useEffect, useMemo, useState} from "react";
import Down from "../../../primitives/icons/Down";
import {TopStat} from "../../../../data-access/mux";
import {auth} from "../../../../firebase";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../primitives/Table";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {useAppDispatch} from "../../../../_store/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {Skeleton} from "../../../primitives/Skeleton";

interface TopViewsProps {
	type: "series" | "contents";
	serieid?: string;
	onClickContent?: (contentId: string) => void;
}

type SortBy = "views" | "likes" | "favorites";

const SortByMapLabel = new Map<SortBy, string>([
	["views", "Vistas"],
	["likes", "Me gusta"],
	["favorites", "Favoritos"],
]);

function sort(data: TopStat[], sortBy: SortBy) {
	switch (sortBy) {
		case "likes":
			return data.sort((a, b) => b.likes - a.likes);
		case "favorites":
			return data.sort((a, b) => b.favorites - a.favorites);
		default:
			return data.sort((a, b) => b.views - a.views);
	}
}

interface LinkComponentProps {
	contentId?: string;
	onClickContent?: (contentId: string) => void;
}

function LinkComponent({children, onClickContent, contentId}: PropsWithChildren<LinkComponentProps>) {
	if (typeof onClickContent === "function" && contentId) {
		return (
			<button onClick={() => onClickContent(contentId)} className="text-left">
				{children}
			</button>
		);
	}
	return <>{children}</>;
}

interface ContentsRowsProps {
	data: TopStat[];
	sortBy: SortBy;
	type?: "series" | "contents";
	onClickContent?: (contentId: string) => void;
}
function ContentsRows({data, sortBy, type = "contents", onClickContent}: ContentsRowsProps) {
	return (
		<>
			{sort(data, sortBy)
				.slice(0, 5)
				.map(item => {
					return (
						<TableRow className="text-center odd:bg-accent/40" key={`${item.contentid} + ${item.seriesid}`}>
							<TableCell className="w-36 px-2">
								<LinkComponent onClickContent={onClickContent} contentId={item.contentid}>
									<img
										className="aspect-video max-h-16 rounded-sm object-cover"
										src={type === "contents" ? item?.rectangular_image : item.img_thumbnail}
										alt=""
									/>
								</LinkComponent>
							</TableCell>
							<TableCell className="text-left">
								<LinkComponent onClickContent={onClickContent} contentId={item.contentid}>
									{item.title}
								</LinkComponent>
							</TableCell>
							<TableCell>{item.views}</TableCell>
							<TableCell>{item.likes}</TableCell>
							<TableCell>{item.favorites}</TableCell>
						</TableRow>
					);
				})}
		</>
	);
}

function TopViews({type, serieid, onClickContent}: TopViewsProps) {
	const {data, isLoading} = useDashboardByNameQuery(type === "contents" ? "topSeriesContents" : "topSeries");
	const [sortBy, setSortBy] = useState<SortBy>("views");
	const dispatch = useAppDispatch();

	const title = useMemo(() => (type === "contents" ? "Top Capítulos" : "Top Series"), [type]);

	useEffect(() => {
		async function loadData() {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			if (type === "contents") {
				if (!serieid) {
					return;
				}
				dispatch(dashboardActions.getTopSeriesContents(serieid));
			} else {
				dispatch(dashboardActions.getTopSeries());
			}
		}

		loadData();
	}, [dispatch, serieid, type]);

	return (
		<div className="rounded-lg border bg-card">
			<div className="flex flex-col border-b border-border p-6">
				<h3 className="text-lg font-semibold leading-none tracking-tight">{title}</h3>
				<p className="text-sm text-muted-foreground">Mayor cantidad: {SortByMapLabel.get(sortBy)}</p>
			</div>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className="w-36"></TableHead>
						<TableHead>Titulo</TableHead>
						<TableHead className={sortBy === "views" ? "text-accent-foreground" : "text-muted-foreground"}>
							<button className="mx-auto flex items-center space-x-1 whitespace-nowrap" onClick={() => setSortBy("views")}>
								<span>Vistas</span>
								<Down className="h-3 w-3" />
							</button>
						</TableHead>
						<TableHead className={sortBy === "likes" ? "text-accent-foreground" : "text-muted-foreground"}>
							<button className="mx-auto flex items-center space-x-1 whitespace-nowrap" onClick={() => setSortBy("likes")}>
								<span>Me gusta</span>
								<Down className="h-3 w-3" />
							</button>
						</TableHead>
						<TableHead className={sortBy === "favorites" ? "text-accent-foreground" : "text-muted-foreground"}>
							<button className="mx-auto flex items-center space-x-1 whitespace-nowrap" onClick={() => setSortBy("favorites")}>
								<span>Favoritos</span>
								<Down className="h-3 w-3" />
							</button>
						</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{isLoading ? (
						<TableRow className="text-center odd:bg-accent/40">
							<TableCell colSpan={7}>
								<Skeleton className="h-20 w-full bg-slate-300" />
							</TableCell>
						</TableRow>
					) : (
						<ContentsRows data={Array.from(data ?? [])} sortBy={sortBy} type={type} onClickContent={onClickContent} />
					)}
				</TableBody>
			</Table>
		</div>
	);
}

export default TopViews;
