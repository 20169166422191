import {PropsWithChildren, useEffect, useMemo, useState} from "react";
import {Line} from "react-chartjs-2";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartOptions} from "chart.js";
import {
	addDays,
	differenceInDays,
	differenceInMonths,
	endOfDay,
	endOfToday,
	format,
	formatISO9075,
	roundToNearestMinutes,
	startOfDay,
	subDays,
} from "date-fns";
import {Eye, Spinner} from "../../../primitives/icons";
import {DatePickerWithRange} from "../../DateRangePicker";
import {DateRange} from "react-day-picker";
import {es} from "date-fns/locale";
import {GetContentViewsResponse} from "../../../../data-access/series/content";
import {useAppDispatch} from "../../../../_store/hooks";
import {getSeriesContentViews} from "../../../../_store/features/content-ad/content-actions-slice";
import {getViewsChartData, groupArrayByDay, oneMonth, transformedData} from "../../../../../utils/barGraphTransformData";
import {Card} from "../Card";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface PartnerSeriesViewsProps {
	serieid?: string;
	contentid?: string;
	codeReferral?: string;
	title?: string;
	loading?: boolean;
	showCard?: boolean;
}

const options: ChartOptions<"line"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {position: "top" as const},
		tooltip: {
			callbacks: {
				title: items => (items[0].label.includes("T") ? format(new Date(items[0].label), "EE, dd MMMM", {locale: es}) : undefined),
			},
		},
	},
};

export default function PartnerSeriesViews(props: PropsWithChildren<PartnerSeriesViewsProps>) {
	const {serieid, contentid, codeReferral, title = "Visualizaciones diarias", children, showCard} = props;
	const dispatch = useAppDispatch();
	const [error, setError] = useState("");
	const [dates, setSelectedDate] = useState<DateRange>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = startOfDay(subDays(Date.now(), 30));
		return {
			from,
			to,
		};
	});
	const [timeSerie, setTimesSerie] = useState<{views: number; date: string}[]>();
	const [innerLoading, setInnerLoading] = useState(false);
	const startDate = new Date(dates?.from ?? 0);
	const endDate = new Date(dates?.to ?? Date.now());
	const diff_days = differenceInDays(endDate, startDate) + 1;
	const diff_months = differenceInMonths(endDate, startDate) + 1;
	const [showByMonths, setShowByMonths] = useState(false);

	const data = useMemo(() => {
		// Grouped data by day
		const seriesViews = groupArrayByDay(timeSerie || []);
		const fillerData = Array(diff_days)
			.fill(0)
			.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), views: 0}));
		const monthArray = Array(diff_months)
			.fill(0)
			.map((_, idx) => ({date: format(addDays(endOfDay(endDate), -idx * 30), "yyyy-MM-dd", {locale: es}), views: 0}));
		// Combined data from statistics and filled.
		const dailySeriesViews = getViewsChartData(fillerData, seriesViews, showByMonths);

		return {
			datasets: [
				{
					label: "Visualizaciones totales",
					data: transformedData(fillerData, dailySeriesViews, showByMonths, monthArray),
					borderColor: "rgba(242, 116, 116, 1)",
					backgroundColor: "rgba(242, 116, 116, 0.5)",
					parsing: {xAxisKey: "date", yAxisKey: "views"},
				},
			],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeSerie, dates, showByMonths]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	useEffect(() => {
		async function getAsyncViews() {
			setError("");
			setInnerLoading(true);

			dispatch(
				getSeriesContentViews({
					series_id: serieid,
					initial_date: formatISO9075(dates.from!),
					final_date: formatISO9075(dates.to!),
				}),
			)
				.then(res => {
					if (res.meta.requestStatus !== "fulfilled") return setError("Error cargando las estadísticas");
					setTimesSerie((res.payload as GetContentViewsResponse)?.views || []);
				})
				.catch(err => setError("Error cargando las estadísticas " + err))
				.finally(() => setInnerLoading(false));
		}

		getAsyncViews();
		const duration = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(duration > oneMonth);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeReferral, contentid, serieid, dates]);

	const totalViews = useMemo(() => {
		const {datasets} = data;
		return datasets[0]?.data.reduce((acc: number, curr: any) => acc + curr.views, 0) ?? 0;
	}, [data]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className={`${showCard ? "col-span-6" : "col-span-7"} rounded-lg border bg-card text-card-foreground shadow-sm`}>
				{!!error && <div className="rounded-sm bg-red-500 p-2 text-red-700">{error}</div>}
				<div className="mb-4 flex items-center justify-between pl-6 pt-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">{title}</h3>
					<div className="w-80 flex-row justify-end">
						<DatePickerWithRange
							onDateRangeChange={handleDateRangeChange}
							disabled={{before: new Date('2023-10-01T00:00:00-05:00'), after: new Date()}}
							date={dates}
						/>
					</div>
					{!!children && children}
				</div>
				{props.loading || innerLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="p-6 pt-0">
						<Line
							height={350}
							options={{
								...options,
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));
												const newMonthlyDate = new Date(date.getFullYear(), date.getMonth() + 1);
												// Format the date based on the available information
												return showByMonths
													? format(newMonthlyDate, "MMM", {locale: es}).toLowerCase()
													: format(date, "MMM d", {locale: es}).toLowerCase();
											},
										},
									},
									y: {ticks: {precision: 0}, beginAtZero: true},
								},
							}}
							data={data}
						/>
					</div>
				)}
			</div>
			{showCard && (
				<div className="col-span-1 flex h-fit">
					<Card
						beforeIcon={<Eye className="mr-10 opacity-50" width={28} height={28} />}
						title="Total de vistas"
						titleSeparator
						amount={totalViews}
						isMoney={false}
						loading={props.loading || innerLoading}
					/>
				</div>
			)}
		</div>
	);
}
