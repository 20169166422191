interface ProgressType {
	width: number;
	progress: number;
}

function ProgressCircle(props: ProgressType) {
	const {width, progress} = props;
	return (
		<svg style={{transform: "rotate(90deg)", overflow: "visible", margin: "auto"}} height="100%" viewBox="0 0 110 110" width={width}>
			<circle cx="50%" cy="50%" fill="none" strokeWidth="20" r="40" stroke="#D4D4D8" strokeDasharray="1.4137,1.4137"></circle>
			<circle
				className="progress-bar"
				cx="50%"
				cy="50%"
				fill="none"
				strokeWidth="20"
				r="40"
				stroke="#19C558"
				style={{strokeDashoffset: 251.3274 - (251.3274 * progress) / 100, strokeDasharray: 251.3274}}
			></circle>
			<circle cx="50%" cy="50%" fill="none" strokeWidth="1.5" r="27.5" stroke="#fff"></circle>
			<circle cx="50%" cy="50%" fill="none" strokeWidth="1.5" r="52.5" stroke="#fff"></circle>
			<g className="success" style={{display: progress === 100 ? "block" : "none"}}>
				<circle cx="50%" cy="50%" fill="#19C558" r="50"></circle>
				<polyline points="52 74, 65 60, 40 36" stroke="#fff" fill="none" strokeWidth="10" />
			</g>
		</svg>
	);
}
export default ProgressCircle;
