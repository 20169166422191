import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface GetSeriesParamsList {
	page?: number;
	page_size?: number;
	searchby?: "title" | "slug" | "description" | "id";
	searchvalue?: string;
	published?: boolean;
	pronyr_original?: boolean;
}

export async function getSeries(token: string, params?: GetSeriesParamsList) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_series_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface SeriesRankingParams {
	initial_date?: string;
	final_date?: string;
	page?: number;
	page_size?: number;
}

export async function getSeriesRanking(token: string, params?: SeriesRankingParams) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_series_ranking", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function deleteSeries(token: string, data: {id: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_series_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export const InsertSeriesDataBodySchema = z.object({
	title: z.string().nonempty("El titulo no puede estar vacío."),
	slug: z.string().nonempty("El slug no puede estar vacío."),
	description: z.string().optional(),
	short_description: z.string().nullable(),
	ranking: z.number().optional(),
	published: z.boolean().optional(),
	total_likes: z.number().optional(),
	total_favorite: z.number().optional(),
	video_mux_id: z.string().optional(),
	img_banner_web_desktop: z.string().optional(),
	img_banner_web_mobile: z.string().optional(),
	img_logo: z.string().optional(),
	img_thumbnail: z.string().optional(),
	position_order: z.number().optional(),
	img_square_thumbnail: z.string().optional(),
	tags: z.string().optional(),
	membership_title: z.string().optional(),
	production_cost: z.number().optional(),
	release_date: z.string().nullable(),
	whitelist_release_date: z.string().nullable(),
	display_release_date: z.boolean(),
	pronyr_original: z.boolean(),
	horizontal_mobile_banner: z.string().optional(),
	horizontal_tv_banner: z.string().optional(),
});

export type InsertSeriesDataBodyType = z.infer<typeof InsertSeriesDataBodySchema>;

export interface InsertSeriesDataReturnType {
	id: string;
	title: string;
	slug: string;
	description: string;
	ranking: number;
	published: true;
	total_likes: number;
	total_dislikes: number;
	total_favorite: number;
	video_mux_id: string;
	img_banner_web_desktop: string;
	img_banner_web_mobile: string;
	img_logo: string;
	img_thumbnail: string;
	audit_created: string;
	audit_updated: string;
	release_date: string | null;
	whitelist_release_date: string | null;
	position_order: string;
	img_square_thumbnail: string;
	tags: string;
	membership_title: string;
	link: string;
	production_cost: string;
	display_release_date: boolean;
	pronyr_original: boolean;
	horizontal_mobile_banner: string;
}

export async function insertSeries(token: string, data: InsertSeriesDataBodyType): Promise<AxiosResponse<InsertSeriesDataReturnType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_series_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function uploadSeriesImage(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_series_image", data, {
		headers: {
			Authorization: idToken,
		},
	});
}

export const EditSeriesDataBodySchema = z.object({
	id: z.string(),
	title: z.string(),
	slug: z.string(),
	description: z.string(),
	short_description: z.string().nullable(),
	ranking: z.number(),
	published: z.boolean(),
	total_likes: z.coerce.number().gte(0),
	total_favorite: z.number(),
	total_dislikes: z.number(),
	video_mux_id: z.string(),
	img_banner_web_desktop: z.string(),
	img_banner_web_mobile: z.string(),
	img_logo: z.string(),
	img_thumbnail: z.string(),
	position_order: z.number(),
	img_square_thumbnail: z.string(),
	tags: z.string(),
	membership_title: z.string(),
	production_cost: z.string().or(z.number()),
	account: z.string().nullable(),
	link: z.string().nullable(),
	release_date: z.string().nullable(),
	whitelist_release_date: z.string().nullable(),
	display_release_date: z.boolean(),
	pronyr_original: z.boolean(),
	horizontal_mobile_banner: z.string().optional(),
	horizontal_tv_banner: z.string().optional(),
});

export type EditSeriesDataBodyType = z.infer<typeof EditSeriesDataBodySchema>;

interface EditSeriesDataResponse {
	id: string;
	title: string;
	slug: string;
	description: string;
	ranking: number;
	published: boolean;
	total_likes: number;
	total_dislikes: number;
	total_favorite: number;
	video_mux_id: string;
	img_banner_web_desktop: string;
	img_banner_web_mobile: string;
	img_logo: string;
	img_thumbnail: string;
	audit_created: string;
	audit_updated: string;
	position_order: string;
	img_square_thumbnail: string;
	tags: string;
	membership_title: string;
	link: string;
	production_cost: string;
	account: string;
	display_release_date: boolean;
	pronyr_original: boolean;
	horizontal_mobile_banner: string;
	horizontal_tv_banner: string;
}

export async function editSeries(token: string, data: EditSeriesDataBodyType): Promise<AxiosResponse<EditSeriesDataResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_series_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface InsertSerieTagsBody {
	tag_ids: string | null;
	seriesid: string;
}

export interface InsertSerieTagsResponse {
	tag_id: string;
	seriesid: string;
}

export async function insertSerieTags(token: string, data: InsertSerieTagsBody): Promise<AxiosResponse<InsertSerieTagsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_series_tags_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
