import {formatISO9075} from "date-fns";
import {GROUPING} from "../app/constants";

type GroupingRange = {
	grouping: GROUPING;
	initial_date: string;
	final_date: string;
};

export const maxGroupingRange = 100;

// This function is used to select the grouping range based on the difference between the initial and final date.
// Can be added a maxRange parameter to set the maximum range for the grouping that should be the
// maximum data available to display on charts...

const selectGroupingRange = (from: number, to: number, maxRange = maxGroupingRange): GroupingRange => {
	if (to - from < maxRange * datesOnSeconds.oneDay * 1000) {
		return {
			grouping: GROUPING.DAILY,
			initial_date: formatISO9075(new Date(from)),
			final_date: formatISO9075(new Date(to)),
		};
	} else if (to - from < maxRange * datesOnSeconds.oneWeek * 1000) {
		return {
			grouping: GROUPING.WEEKLY,
			initial_date: formatISO9075(new Date(from)),
			final_date: formatISO9075(new Date(to )),
		};
	} else {
		return {
			grouping: GROUPING.MONTHLY,
			initial_date: formatISO9075(new Date(from)),
			final_date: formatISO9075(new Date(to)),
		};
	}
};

export const datesOnSeconds = {
	oneDay: 86400,
	oneWeek: 604800,
	oneMonth: 2592000,
};

export default selectGroupingRange;
