import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "../../hooks";
import {auth} from "../../../firebase";
import {getGeneralIncomeAndProfit2, getSubscriptionSlotsVariation} from "../../../data-access/dashboard-corporate/statistics";
import {
	GetGeneralIncomeAndProfitResponseV2,
	GetSubscriptionSlotsVariationsResponse,
	getSubscriptionSlotsVariationsParams,
} from "../../../data-access/dashboard-corporate/types";
import {GROUPING} from "../../../constants";
import {GetGeneralIncomeAndProfitParams} from "../../../data-access/dashboard/types";

type RequestState = "pending" | "fulfilled" | "rejected";
export type DashboardCorporateQueries = "getSubscriptionSlotsVariations" | "getGeneralIncomeAndProfitV2";

export type DataByName = {
	getSubscriptionSlotsVariations: GetSubscriptionSlotsVariationsResponse;
	getGeneralIncomeAndProfitV2: GetGeneralIncomeAndProfitResponseV2;
};

// Some interface are defined to mantain the same structure as the other slices
// It wil be used in the future migration from other slices
export interface dashboardCorporateInterface {
	loading: boolean;
	selectedSerieId: string | undefined;
	selectedContentId: string | undefined;
	statusByName: Record<DashboardCorporateQueries, RequestState | undefined>;
	dataByName: {
		[k in keyof DataByName]: DataByName[k] | undefined;
	};
	range: {
		from: string;
		to: string;
	};
	grouping: GROUPING; // to future grouping use
}

const initialState: dashboardCorporateInterface = {
	loading: false,
	selectedSerieId: undefined,
	selectedContentId: undefined,
	statusByName: {
		getSubscriptionSlotsVariations: undefined,
		getGeneralIncomeAndProfitV2: undefined,
	},
	dataByName: {
		getSubscriptionSlotsVariations: undefined,
		getGeneralIncomeAndProfitV2: undefined,
	},
	range: {
		from: "",
		to: "",
	},
	grouping: 1,
};

const getSubscriptionSlotsVariations = createAppAsyncThunk(
	"dashboard/get-subscription-slots-variations",
	async (params: getSubscriptionSlotsVariationsParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const res = await getSubscriptionSlotsVariation(token, {
				...params,
			});

			return res.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getGeneralIncomeAndProfitV2 = createAppAsyncThunk(
	"dashboard/get-general-income",
	async (params: GetGeneralIncomeAndProfitParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;
			const response = await getGeneralIncomeAndProfit2(token, params);
			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const dashboardCorporateSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setSelectedSerieId(state, action: PayloadAction<string | undefined>) {
			state.selectedSerieId = action.payload;
		},
		setSelectedContentId(state, action: PayloadAction<string | undefined>) {
			state.selectedContentId = action.payload;
		},
		setRange(state, action: PayloadAction<{from: string; to: string}>) {
			state.range = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getSubscriptionSlotsVariations.pending, (state, action) => {
				state.statusByName["getSubscriptionSlotsVariations"] = "pending";
			})
			.addCase(getSubscriptionSlotsVariations.fulfilled, (state, action) => {
				state.statusByName["getSubscriptionSlotsVariations"] = "fulfilled";
				state.dataByName["getSubscriptionSlotsVariations"] = action.payload ?? undefined;
			})
			.addCase(getSubscriptionSlotsVariations.rejected, (state, action) => {
				state.statusByName["getSubscriptionSlotsVariations"] = "rejected";
			});
		builder
			.addCase(getGeneralIncomeAndProfitV2.pending, (state, action) => {
				state.statusByName["getGeneralIncomeAndProfitV2"] = "pending";
			})
			.addCase(getGeneralIncomeAndProfitV2.fulfilled, (state, action) => {
				state.statusByName["getGeneralIncomeAndProfitV2"] = "fulfilled";
				state.dataByName["getGeneralIncomeAndProfitV2"] = action.payload ?? undefined;
			})
			.addCase(getGeneralIncomeAndProfitV2.rejected, (state, action) => {
				state.statusByName["getGeneralIncomeAndProfitV2"] = "rejected";
			});
	},
});

export const dashboardCorporateActions = {
	...dashboardCorporateSlice.actions,
	getSubscriptionSlotsVariations,
	getGeneralIncomeAndProfitV2,
};

export default dashboardCorporateSlice;
