import {ROLES} from "../../data-access/fetch-db-users";
import useCurrentUserRole from "../../components/hooks/useCurrentUserRole";
import {Navigate, Outlet} from "react-router-dom";

interface ProtectedRouteProps {
	requiredRole: ROLES[];
}

export default function ProtectedRoute({requiredRole}: ProtectedRouteProps) {
	const {activeRole: currentUserRole} = useCurrentUserRole();

	return !requiredRole.includes(currentUserRole!) ? <Navigate to={"/"} replace /> : <Outlet />;
}
