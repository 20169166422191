import {useState} from "react";
import {Card} from "../../../../../components/blocks/Stats/Card";
import {Report} from "../../../../../data-access/report/types";

interface GeneralStatsProps {
	report: Report;
}

const GeneralStats = ({report}: GeneralStatsProps) => {
	const [personalEarningsPanel, setPersonalEarningsPanel] = useState(false);
	const [contentEarningsPanel, setContentEarningsPanel] = useState(false);

	const partner_time_percent = !!report.total_time_seconds ? report.total_partner_time / report.total_time_seconds : 0;
	const net_earnings = report.total_earnings - report.total_costs;
	const content_earnings = partner_time_percent * net_earnings;

	return (
		<>
			<div className="flex gap-4">
				<Card
					title="Ingreso Personal"
					amount={report.total_partner_earnings_by_seconds_percent}
					tooltip="Ganancias personales del período seleccionado, este corresponde a un valor estimado basado en el consumo de los contenidos."
					className="w-80"
					expanse
					onExpanse={expanded => setPersonalEarningsPanel(expanded)}
				/>
				<Card
					title="Ingresos de los Contenidos"
					amount={content_earnings}
					tooltip="Ingreso generado por la(s) serie luego de deducidos los costos de operación y los porciento de consumo de los contenidos."
					className="w-80"
					expanse
					onExpanse={expanded => setContentEarningsPanel(expanded)}
				/>
				<Card
					title="Ingresos Totales"
					amount={report.total_earnings}
					tooltip="Ingresos de la plataforma por concepto de subscripción correspondiente al período seleccionado."
					className="w-80"
				/>
				<Card
					title="Fees"
					amount={report.total_costs}
					tooltip="Impuestos/gastos de la plataforma en el período seleccionado."
					className="w-80"
				/>
			</div>
			<div className={`${personalEarningsPanel ? "max-h-[100vh]" : "h-0 opacity-0"} easy-in-out transition duration-500`}>
				<table className="mt-4 w-full max-w-6xl table-auto border-separate text-right">
					<thead className="bg-slate-100">
						<tr className="text-right">
							<th className="text-left">Nombre</th>
							<th>Ingreso del contenido</th>
							<th>Parte Acordada</th>
							<th>Ganancia personal</th>
						</tr>
					</thead>
					<tbody>
						{report.series
							.filter(s => s.isPartner)
							.map((serie, index) => (
								<tr key={index} className="text-right">
									<td className="border-b border-slate-200 text-left">{serie.title}</td>
									<td className="border-b border-slate-200">
										$
										{(
											((report?.series.find(s => s.seriesid === serie.seriesid)?.partner_time_seconds_percent ?? 0) / 100) *
											(report?.total_earnings - report.total_costs ?? 0)
										).toFixed(2) ?? 0}
									</td>
									<td className="border-b border-slate-200">{serie.profitPercentage}%</td>
									<td className="border-b border-slate-200">
										$
										{(
											((report?.series.find(s => s.seriesid === serie.seriesid)?.partner_time_seconds_percent ?? 0) *
												serie.profitPercentage *
												(report.total_earnings - report.total_costs) ?? 0) /
											100 /
											100
										)?.toFixed(2) ?? 0}
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
			<div className={`${contentEarningsPanel ? "max-h-[100vh]" : "h-0 opacity-0"} easy-in-out transition duration-500`}>
				<table className="mt-4 w-full max-w-6xl table-auto border-separate text-right">
					<thead className="bg-slate-100">
						<tr className="text-right">
							<th className="text-left">Nombre</th>
							<th>Tiempo de Streaming</th>
							<th>Parte del Total</th>
							<th>Ganancia / Consumo</th>
						</tr>
					</thead>
					<tbody>
						{report.series.map((serie, index) => {
							if (serie.partner_earnings_by_seconds) {
								return (
									<tr key={index}>
										<td className="border-b border-slate-200 text-left">{serie.title}</td>
										<td className="border-b border-slate-200">
											{((serie.total_series_time_seconds ?? 0) / 3600).toFixed(2)} horas
										</td>
										<td className="border-b border-slate-200">{serie.partner_time_seconds_percent?.toFixed(1)}%</td>
										<td className="border-b border-slate-200">
											$
											{(
												((report?.series.find(s => s.seriesid === serie.seriesid)?.total_series_time_seconds ?? 0) /
													(report?.total_time_seconds ? report?.total_time_seconds : 1)) *
												(report?.total_earnings - report?.total_costs ?? 0)
											)?.toFixed(2) ?? 0}
										</td>
									</tr>
								);
							}
							return null;
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default GeneralStats;
