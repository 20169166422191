import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../../../../../../components/primitives/Dropdown";
import {Button} from "../../../../../../components/primitives/Button";
import {Image, PlusSmall, ChartBar, VideoCamera} from "../../../../../../components/primitives/icons";

interface CreatePostDropdownMenuProps {
	onCreateImagePost: () => void;
	onCreateVideoPost: () => void;
	onCreatePollPost: () => void;
}

const CreatePostDropdownMenu = ({onCreateVideoPost, onCreatePollPost, onCreateImagePost}: CreatePostDropdownMenuProps) => {
	return (
		<div className="flex items-center space-x-2">
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button className="col-start-2 ml-4 flex items-center justify-center rounded bg-neutral-900 px-4 py-2 text-white transition-colors duration-300 data-[state=open]:bg-neutral-700 hover:bg-neutral-700">
						<PlusSmall />
						<span>Nueva publicación</span>
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start">
					<DropdownMenuItem onClick={onCreateImagePost}>
						<Image className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
						Imagen
					</DropdownMenuItem>
					<DropdownMenuItem onClick={onCreateVideoPost}>
						<VideoCamera className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
						Video
					</DropdownMenuItem>
					<DropdownMenuSeparator />
					<DropdownMenuItem onClick={onCreatePollPost}>
						<ChartBar className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
						Encuesta
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
};

export default CreatePostDropdownMenu;
