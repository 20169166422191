import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {editDbUserData} from "./user-db-actions";
import {removeUserDevice, RemoveUserDeviceBody} from "../../../data-access/user-auth";
import {auth} from "../../../firebase";

export interface Address {
	audit_created?: string;
	audit_updated?: string;
	city?: string;
	country?: string;
	device_latitude?: number;
	devide_longitude?: number;
	state_province_region?: string;
	street_number?: string;
	suite_apartment?: string;
	user_id?: string;
	zip_code?: string;
}

export interface PersonalData {
	birth_date: string | undefined;
	gender: string | null;
}

export interface dbUser {
	id: string;
	nickname: string;
	userid?: string;
	audit_created: string;
	audit_updated?: string;
	email: string;
	name: string;
	address: Address | null;
	personal_data: PersonalData;
	phone: string;
	coin: number;
	photo_url?: string;
	disabled: boolean;
	email_verified: boolean;
	verify_hash: string;
	password_hash: string;
	phone_verified: boolean;
	provider: string;
	removed: boolean;
	admin: boolean | null;
	editor: boolean | null;
	master_editor: boolean | null;
	partner: boolean | null;
	sponsor: boolean | null;
	influencer: boolean | null;
	customer_service: boolean | null;
	customerservice_l1: boolean | null;
	vendor: boolean | null;
	marketing: boolean | null;
	associate?: boolean;
	corporate?: boolean | null;
	customer_active: boolean | null;
	vendor_customer_id?: string | null;
	vendorid?: string | null;
	supertokenid?: string;
	firebaseid?: string;
	user_devices_info:
		| {
				id: string;
				brand: string;
				userid: string;
				base_os: string;
				uniqueid: string;
				created_at: string;
				updated_at: string | null;
		  }[]
		| null;
}

export const editInitialState: dbUser = {
	audit_created: "",
	audit_updated: undefined,
	coin: 0,
	disabled: false,
	email: "",
	email_verified: false,
	name: "",
	nickname: "",
	address: {
		audit_created: "",
		audit_updated: "",
		city: "",
		country: "",
		device_latitude: 0,
		devide_longitude: 0,
		state_province_region: "",
		street_number: "",
		suite_apartment: "",
		user_id: "",
		zip_code: "",
	},
	personal_data: {
		birth_date: undefined,
		gender: null,
	},
	phone: "",
	phone_verified: false,
	photo_url: undefined,
	id: "",
	userid: "",
	provider: "",
	verify_hash: "",
	password_hash: "",
	removed: false,
	admin: null,
	editor: null,
	master_editor: null,
	partner: null,
	sponsor: null,
	influencer: null,
	customer_service: null,
	customerservice_l1: null,
	vendor: null,
	marketing: null,
	associate: false,
	corporate: false,
	user_devices_info: null,
	supertokenid: "",
	firebaseid: "",
	customer_active: null,
	vendorid: "",
};

export interface userDbInterface {
	loading: boolean;
	editing: boolean;
	sendingPasswordEmail: boolean;
	migrating: boolean;
	error: string | undefined;
	success: boolean;
	successMessage: string | undefined;
	totalResults: number;
	pageSize: number;
	current: number;
	results: dbUser[];
}

const initialState: userDbInterface = {
	loading: false,
	editing: false,
	sendingPasswordEmail: false,
	migrating: false,
	error: undefined,
	success: false,
	successMessage: undefined,
	totalResults: 0,
	pageSize: 0,
	current: 0,
	results: [],
};

const deleteUserDevice = createAsyncThunk("user/devices-remove", async (data: RemoveUserDeviceBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await removeUserDevice(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
		return {
			result: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const userDbSlice = createSlice({
	name: "dbuser",
	initialState,
	reducers: {
		loadDbUserData(state, action: PayloadAction<userDbInterface>) {
			state.totalResults = action.payload.totalResults;
			state.pageSize = action.payload.pageSize;
			state.current = action.payload.current;
			state.results = action.payload.results;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			if (state.loading !== action.payload) state.loading = action.payload;
		},
		setEditing(state, action: PayloadAction<boolean>) {
			if (state.editing !== action.payload) state.editing = action.payload;
		},
		setSuccess(state, action: PayloadAction<boolean>) {
			if (state.success !== action.payload) state.success = action.payload;
		},
		setSuccessMessage(state, action: PayloadAction<string | undefined>) {
			if (state.successMessage !== action.payload) state.successMessage = action.payload;
		},
		setSendingPasswordEmail(state, action: PayloadAction<boolean>) {
			if (state.sendingPasswordEmail !== action.payload) state.sendingPasswordEmail = action.payload;
		},
		setMigrating(state, action: PayloadAction<boolean>) {
			if (state.migrating !== action.payload) state.migrating = action.payload;
		},
		setError(state, action: PayloadAction<string | undefined>) {
			if (state.error !== action.payload) state.error = action.payload;
		},
		resetState() {
			return {...initialState};
		},
	},
	extraReducers(builder) {
		builder
			.addCase(editDbUserData.pending, state => {
				state.loading = true;
			})
			.addCase(editDbUserData.fulfilled, (state, action) => {
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload.id) {
						return action.payload;
					}

					return el;
				});
			})
			.addCase(editDbUserData.rejected, (state, action) => {});
	},
});

export const userDbActions = {...userDbSlice.actions, deleteUserDevice};

export default userDbSlice;
