import {User} from "@firebase/auth";
import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getCreditStaffData, GetCreditStaffParams} from "../../../data-access/series/credits";
import {
	editSeries,
	EditSeriesDataBodyType,
	getSeries,
	GetSeriesParamsList,
	insertSeries,
	InsertSeriesDataBodyType,
	insertSerieTags,
	InsertSerieTagsBody,
} from "../../../data-access/series/series";
import {auth} from "../../../firebase";
import {RootState} from "../../store";

export type Serie = {
	id: string;
	title: string;
	slug: string;
	description: string;
	ranking: number;
	published: boolean;
	total_likes: number;
	total_favourite: number;
	video_mux_id: string;
	img_banner_web_desktop: string;
	img_banner_web_mobile: string;
	img_logo: string;
	img_thumbnail: string;
	audit_created: string;
	audit_updated: string | null;
	release_date: string | null;
	whitelist_release_date: string | null;
	position_order: number;
	get_credits_serieid: {
		creditstaff_id: string;
		credit_fullname: string;
		staff_ranking: number;
		category_ranking: number;
		creditcategory_id: string;
		credit_category: string;
		credit_id: string;
	}[];
	get_cast_serieid: {
		cast_member_id: string;
		cast_id: string;
		cast_name: string;
		cast_img: string;
		cast_descript: string;
		cast_ranking: number;
	}[];
	production_cost: string;
	link: string | null;
	account: string | null;
	pronyr_original: boolean;
	horizontal_tv_banner: string;
	tags_list: {
		tag_id: string;
		seriesid: string;
		series_tag_id: string;
	}[];
};

export interface seriesInterface {
	loading: boolean;
	actionLoading: boolean;
	totalResults: number;
	pageSize: number;
	current: number;
	results: Serie[];
	newSeries: any;
}

const initialState: seriesInterface = {
	loading: false,
	actionLoading: false,
	totalResults: 0,
	pageSize: 0,
	current: 0,
	results: [],
	newSeries: {},
};

const getSeriesList = createAsyncThunk("series/list", async ({params}: {params?: GetSeriesParamsList}, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await getSeries(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const getCreditStaffByName = createAsyncThunk("series/staff", async ({GCPUser, params}: {GCPUser: User; params: GetCreditStaffParams}, thunkAPI) => {
	try {
		const token = await GCPUser.getIdToken();

		const response = await getCreditStaffData(token, params);

		return {
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const getSeriesCredit = createAsyncThunk("series/credit", async ({GCPUser, params}: {GCPUser: User; params: GetSeriesParamsList}, thunkAPI) => {
	try {
		const token = await GCPUser.getIdToken();

		const response = await getSeries(token, params);

		return {id: response.data.results[0].id, credits: response.data.results[0].get_credits_serieid};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const getSeriesCast = createAsyncThunk("series/cast", async ({GCPUser, params}: {GCPUser: User; params: GetSeriesParamsList}, thunkAPI) => {
	try {
		const token = await GCPUser.getIdToken();

		const response = await getSeries(token, params);

		return {id: response.data.results[0].id, cast: response.data.results[0].get_cast_serieid};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const insertSerie = createAsyncThunk("series/insert", async (params: InsertSeriesDataBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await insertSeries(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const updateSerie = createAsyncThunk("series/edit", async (params: EditSeriesDataBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await editSeries(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const insertSerieTagsAction = createAsyncThunk("series/insertTags", async (params: InsertSerieTagsBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await insertSerieTags(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem on insert serie tags");
	}
});

const seriesSlice = createSlice({
	name: "series",
	initialState,
	reducers: {
		setActionLoading(state, action: PayloadAction<boolean>) {
			state.actionLoading = action.payload;
		},
		updateSeriesCategoryName(state, action: PayloadAction<{serieId: string; creditCategoryId: string; creditCategoryName: string}>) {
			state.results
				.find(serie => serie.id === action.payload.serieId)
				?.get_credits_serieid.map(credit => {
					if (credit.creditcategory_id === action.payload.creditCategoryId) {
						credit.credit_category = action.payload.creditCategoryName;
					}
					return credit;
				});
		},
		updateCategoryStaffName(state, action: PayloadAction<{serieId: string; staffId: string; staffName: string}>) {
			state.results
				.find(serie => serie.id === action.payload.serieId)
				?.get_credits_serieid.map(credit => {
					if (credit.creditstaff_id === action.payload.staffId) {
						credit.credit_fullname = action.payload.staffName;
					}
					return credit;
				});
		},
		removeSeriesCategory(state, action: PayloadAction<{serieId: string; creditCategoryId: string}>) {
			const indexOfSerie = state.results.findIndex(serie => serie.id === action.payload.serieId);
			state.results[indexOfSerie].get_credits_serieid = state.results[indexOfSerie].get_credits_serieid.filter(
				credit => credit.creditcategory_id !== action.payload.creditCategoryId,
			);
		},
		removeCategoryStaff(state, action: PayloadAction<{serieId: string; creditId: string}>) {
			const indexOfSerie = state.results.findIndex(serie => serie.id === action.payload.serieId);
			state.results[indexOfSerie].get_credits_serieid = state.results[indexOfSerie].get_credits_serieid.filter(
				credit => credit.credit_id !== action.payload.creditId,
			);
		},
		updateCategoryStaffOrder(state, action: PayloadAction<{ordered_data: {id: string; order: number}[]; serieId: string}>) {
			const indexOfSerie = state.results.findIndex(serie => serie.id === action.payload.serieId);
			const updatedCredits = state.results[indexOfSerie].get_credits_serieid.map(credit => {
				const orderedData1 = action.payload.ordered_data[0];
				const orderedData2 = action.payload.ordered_data[1];
				if (credit.credit_id === orderedData1.id) {
					return {
						...credit,
						staff_ranking: orderedData1.order,
					};
				} else if (credit.credit_id === orderedData2.id) {
					return {
						...credit,
						staff_ranking: orderedData2.order,
					};
				}
				return credit;
			});
			state.results[indexOfSerie].get_credits_serieid = updatedCredits;
		},
		insertInlineAddedIntoSerie(
			state,
			action: PayloadAction<{
				serieId: string;
				staffData: {
					creditstaff_id: string;
					credit_fullname: string;
					staff_ranking: number;
					creditcategory_id: string;
					credit_category: string;
					category_ranking: number;
					credit_id: string;
				};
			}>,
		) {
			const indexOfSerie = state.results.findIndex(serie => serie.id === action.payload.serieId);
			state.results[indexOfSerie].get_credits_serieid = [...state.results[indexOfSerie].get_credits_serieid, action.payload.staffData];
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getSeriesList.pending, state => {
				state.loading = true;
			})
			.addCase(getSeriesList.fulfilled, (state, action) => {
				const {results} = action.payload;
				const newResults = results.map((serie: Serie) => ({
					...serie,
					horizontal_tv_banner: serie.horizontal_tv_banner || "",
				}));
				state.loading = false;
				state.current = action.payload.current;
				state.pageSize = action.payload.pageSize;
				state.totalResults = action.payload.totalResults;
				state.results = [...newResults];
			})
			.addCase(getSeriesList.rejected, (state, action) => {});
		builder
			.addCase(getSeriesCredit.pending, state => {
				state.actionLoading = true;
			})
			.addCase(getSeriesCredit.fulfilled, (state, action) => {
				const indexOfSerie = state.results.findIndex(serie => serie.id === action.payload.id);
				state.results[indexOfSerie].get_credits_serieid = action.payload.credits;
				state.actionLoading = false;
			})
			.addCase(getSeriesCredit.rejected, (state, action) => {
				state.actionLoading = false;
			});
		builder
			.addCase(getSeriesCast.pending, state => {
				state.actionLoading = true;
			})
			.addCase(getSeriesCast.fulfilled, (state, action) => {
				const indexOfSerie = state.results.findIndex(serie => serie.id === action.payload.id);
				state.results[indexOfSerie].get_cast_serieid = action.payload.cast;
				state.actionLoading = false;
			})
			.addCase(getSeriesCast.rejected, (state, action) => {
				state.actionLoading = false;
			});
	},
});

export const seriesActions = {
	...seriesSlice.actions,
	getSeriesList,
	getCreditStaffByName,
	getSeriesCredit,
	getSeriesCast,
	insertSerie,
	updateSerie,
	insertSerieTagsAction,
};

export const selectedRentalSerieSelector = createSelector(
	(state: RootState) => state.series,
	series => series.results?.filter(serie => !serie.pronyr_original && serie.published),
);

export default seriesSlice;
