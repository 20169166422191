import {Button} from "../../../../../components/primitives/Button";
import {Dialog, DialogDescription, DialogHeader, DialogContent, DialogTitle} from "../../../../../components/primitives/Dialog";

interface UncheckVerifiedDialogProps {
	open?: boolean;
	onDismiss?: () => void;
	onSuccess: () => void;
}

function UncheckVerifiedDialog({open, onSuccess, onDismiss}: UncheckVerifiedDialogProps) {
	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				onDismiss?.();
			}}
		>
			<DialogContent>
				<DialogHeader>
					<DialogTitle className="font-medium">Esta es una operación de sensible</DialogTitle>
					<DialogDescription className="font-medium">
						Si desbloquea esta opción podrá desactivar los filtros de validación lo cual NO está recomendado. Está seguro que desea
						continuar?
					</DialogDescription>
				</DialogHeader>

				<div className="flex flex-row justify-end">
					<Button className="mr-2 " variant="outline" onClick={onSuccess}>
						Aceptar
					</Button>
					<Button
						onClick={() => {
							onDismiss?.();
						}}
					>
						Cerrar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default UncheckVerifiedDialog;
