import {Line} from "react-chartjs-2";
import {Data} from "../../../../_store/features/feedback/types";
import {ChartData, ChartOptions} from "chart.js";
import {useMemo} from "react";

interface RequestsChartProps {
	data: Data | undefined;
}

const options: ChartOptions<"line"> = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			beginAtZero: true,
		},
	},
	showLine: true,
	plugins: {
		legend: {
			display: false,
		},
		colors: {},
		tooltip: {
			filter: function (tooltipItem) {
				return tooltipItem.datasetIndex === 0;
			},
		},
	},
};

export function RequestsChart({data}: RequestsChartProps) {
	const preparedData: ChartData<"line", number[], string> = useMemo(() => {
		if (!data) return {labels: [], datasets: [{data: []}]};

		const labels = data.currentRange.data.map(item => item.label);

		return {
			labels,
			datasets: [
				{
					data: data.currentRange.data.map(item => item.request),
					fill: true,
					borderColor: "rgba(100, 150, 229, 0.8)",
					backgroundColor: ["rgba(100, 150, 229, 0.4)"],
					borderCapStyle: "square",
					borderDash: [1],
					borderRadius: 4,
				},
				{
					data: data.previousRange.data.map(item => item.request),
					fill: false,
					borderColor: "rgb(75, 192, 192)",
					borderDash: [5, 5],
					borderWidth: 1,
				},
			],
		};
	}, [data]);

	return (
		<div>
			<Line height={500} options={options} data={preparedData} />
		</div>
	);
}
