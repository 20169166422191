import {useGetFeedbackEventsQuery} from "../../../../_store/features/feedback/api";
import {QuestionMarkCircle} from "../../../../components/primitives/icons";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../../components/primitives/ToolTip";

export function TooltipEvents() {
	const {data} = useGetFeedbackEventsQuery();
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<div>
						<QuestionMarkCircle className="h-4 w-4" />
					</div>
				</TooltipTrigger>
				<TooltipContent>
					<div>
						<p className="mb-4 mr-4 max-w-xl text-justify font-normal">
							Las interacciones representan todos los eventos y acciones que la aplicación realiza al comunicarse con la API de
							feedbacks. Estas interacciones registran cada paso del proceso de solicitud y recepción de retroalimentación por parte del
							usuario, así como cualquier error o resultado relevante. A continuación, se detallan los tipos de eventos incluidos:
						</p>
						<ul className="font-light">
							{data?.results.map(event => (
								<li>
									<b className="font-bold">{event.label}:</b> {event.description}
								</li>
							))}
						</ul>
					</div>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
}
