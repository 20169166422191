import {CheckCircle, Chevron} from "../../../../components/primitives/icons";

import {cn} from "../../../../../utils/classNames";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem} from "../../../../components/primitives/Command/command";
import {Popover, PopoverContent, PopoverTrigger} from "../../../../components/primitives/Popover/popover";
import {Button} from "../../../../components/primitives/Button";
import {useState} from "react";
import {ROLES} from "../../../../data-access/fetch-db-users";
import useCurrentUserRole from "../../../../components/hooks/useCurrentUserRole";

const roles: {label: string; value: ROLES | "user"; canBeCreatedBy: ROLES[]}[] = [
	{
		value: "admin",
		label: "Administrator",
		canBeCreatedBy: ["admin"],
	},
	{
		value: "master_editor",
		label: "Master Editor",
		canBeCreatedBy: ["admin", "corporate"],
	},
	{
		value: "corporate",
		label: "Corporate",
		canBeCreatedBy: ["admin", "master_editor", "corporate"],
	},
	{
		value: "editor",
		label: "Editor",
		canBeCreatedBy: ["admin", "master_editor", "corporate"],
	},
	{
		value: "influencer",
		label: "Influencer",
		canBeCreatedBy: ["admin", "master_editor", "corporate", "marketing"],
	},
	{
		value: "sponsor",
		label: "Sponsor",
		canBeCreatedBy: ["admin", "master_editor", "corporate"],
	},
	{
		value: "partner",
		label: "Partner",
		canBeCreatedBy: ["admin", "master_editor", "corporate"],
	},
	{
		value: "customer_service",
		label: "Customer Service",
		canBeCreatedBy: ["admin", "master_editor", "corporate"],
	},
	{
		value: "customerservice_l1",
		label: "Customer Service L1",
		canBeCreatedBy: ["admin"],
	},
	{
		value: "marketing",
		label: "Marketing",
		canBeCreatedBy: ["admin", "master_editor", "corporate"],
	},
	{
		value: "vendor",
		label: "Vendor",
		canBeCreatedBy: ["admin"],
	},
	{
		value: "user",
		label: "Usuario",
		canBeCreatedBy: ["admin", "master_editor", "corporate"],
	},
];

interface RolePickerComboboxProps {
	onChangeSelected: (rol: (typeof roles)[number] | null) => void;
}

export function RolePickerCombobox({onChangeSelected}: RolePickerComboboxProps) {
	const {activeRole} = useCurrentUserRole();
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState(activeRole === "marketing" ? "influencer" : "user");

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button variant="outline" role="combobox" aria-expanded={open} className="w-[350px] justify-between">
					{value ? roles.find(role => role.value === value)?.label : "Buscar rol..."}
					<Chevron className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[350px] p-0">
				<Command>
					<CommandInput placeholder="Buscar rol..." />
					<CommandEmpty>No hay roles.</CommandEmpty>
					<CommandGroup>
						{roles.map(role => (
							<CommandItem
								key={role.value}
								onSelect={currentValue => {
									setValue(role.value);
									onChangeSelected(role);
									setOpen(false);
								}}
								disabled={!!activeRole ? !role.canBeCreatedBy.includes(activeRole) : false}
							>
								<CheckCircle className={cn("mr-2 h-4 w-4", value === role.value ? "opacity-100" : "opacity-0")} />
								{role.label}
							</CommandItem>
						))}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
