import {Comment} from "../comments/comments";

export interface Reaction {
	userid: string;
	reacted: string;
}

export interface Question {
	id: number;
	text: string;
	votes: number;
}

export const PostType = {
	IMAGE: 0,
	VIDEO: 1,
	POLL: 2,
} as const;

export interface PostPayload {
	subtype: (typeof PostType)[keyof typeof PostType];
	content: {
		title: string;
		text: string;
		video_url?: string;
		image_url?: string;
		questions?: Question[];
	};
	reactions: Reaction[];
}

export interface Post extends Omit<Comment, "content"> {
	content: {
		type: number;
		payload: PostPayload;
	};
}

export type PostTypeOrdinal = (typeof PostType)[keyof typeof PostType];

export interface GetPostsListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Post[];
}

export interface GetPostsListParams {
	pagelength?: number;
	page?: number;
	subtype?: string;
	id: string;
}

export interface CreatePostBody {
	seriesid: string;
	content: {
		type: 100;
		payload: PostPayload;
	};
	published: boolean;
}
