import "chartjs-adapter-date-fns";
import {formatISO9075, roundToNearestMinutes, startOfDay, startOfToday, subDays} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useAppDispatch} from "../../../../_store/hooks";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {Card} from "../Card";
import {Spinner} from "../../../primitives/icons";
import {DatePickerWithRange} from "../../DateRangePicker";
import {DateRange} from "react-day-picker";
import InfluencerBarChart from "../InfluencerBarChart";
import {oneMonth} from "../../../../../utils/barGraphTransformData";

interface SerieIncomeAndProfitProps {
	influencerSeries: any;
	influencerReferralCode: string | undefined;
}

export function InfluencerIncome({influencerSeries, influencerReferralCode}: SerieIncomeAndProfitProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; value: number}[]>>(null);
	const {data: statistics, isLoading: isStatisticsLoading} = useDashboardByNameQuery("influencerIncome");

	const [showByMonths, setShowByMonths] = useState(false);
	const seriesid = influencerSeries.map((series: {seriesid: any}) => series.seriesid).join(",");

	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(startOfToday());
		const from = startOfDay(subDays(Date.now(), 30));
		return {
			from,
			to,
		};
	});

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!influencerReferralCode) return;
		const durationInMilliseconds = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(durationInMilliseconds > oneMonth);

		if (seriesid !== "") {
			dispatch(
				dashboardActions.getInfluencerSerieIncomeAndProfit({
					seriesid_list: seriesid,
					referral_code: influencerReferralCode,
					initial_date: formatISO9075(dates?.from!),
					final_date: formatISO9075(dates?.to!),
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates]);

	const percentage = useMemo(() => {
		const weightedPercentage = statistics?.weighted_percentage ? statistics.weighted_percentage * 0.01 : 0.5;
		return weightedPercentage;
	}, [statistics]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	const total_profit = useMemo(() => {
		const membershipProfit = statistics?.historic_membership_profit ? statistics.historic_membership_profit : 0;
		const subscriptionProfit = statistics?.historic_subscription_profit ? statistics.historic_subscription_profit : 0;
		const total = membershipProfit * 0.01 + subscriptionProfit * 0.01;

		return total;
	}, [statistics]);

	const range_profit = useMemo(() => {
		const membershipProfit = statistics?.range_membership_profit ? statistics.range_membership_profit : 0;
		const subscriptionProfit = statistics?.range_subscription_profit ? statistics.range_subscription_profit : 0;
		const total = membershipProfit * 0.01 + subscriptionProfit * 0.01;
		return total;
	}, [statistics]);

	const range_personal_profit = useMemo(() => {
		const membershipProfit = statistics?.range_membership_personal_profit ? statistics.range_membership_personal_profit * 0.01 : 0;
		const subscriptionProfit = statistics?.range_subscription_personal_profit ? statistics.range_subscription_personal_profit * 0.01 : 0;

		const total = membershipProfit + subscriptionProfit;
		return total;
	}, [statistics]);

	const historic_membership_profit = useMemo(() => {
		const membershipProfit = statistics?.historic_membership_profit ? statistics.historic_membership_profit : 0;
		const membershipFee = statistics?.historic_membership_fee ? statistics.historic_membership_fee : 0;
		const total = membershipProfit * 0.01 - membershipFee * 0.01;

		return total;
	}, [statistics]);

	const historic_subscription_profit = useMemo(() => {
		const subscriptionProfit = statistics?.historic_subscription_personal_profit ? statistics.historic_subscription_personal_profit * 0.01 : 0;

		return subscriptionProfit;
	}, [statistics]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 md:grid-cols-2 lg:grid-cols-7">
			<div className="flex w-80 flex-row">
				<DatePickerWithRange
					onDateRangeChange={handleDateRangeChange}
					disabled={{before: new Date('2023-10-01T00:00:00-05:00'), after: new Date()}}
					date={dates}
				/>
			</div>
			<div className="col-span-7 grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-4">
				<Card
					title="Ganancia personal"
					amount={historic_subscription_profit + historic_membership_profit * percentage}
					tooltip="Ganancia total del asociado por pagos de sus referidos y suscripciones."
					loading={isStatisticsLoading}
				/>
				<Card
					title="Total bruto por referidos"
					amount={total_profit}
					tooltip="Ingresos totales obtenidos por referidos y suscripciones sin descontar fees de las tiendas."
					loading={isStatisticsLoading}
				/>
				<Card
					title="Ganancia ult. 30 días"
					amount={range_personal_profit}
					tooltip="Ganancias netas del asociado en los últimos 30 días."
					loading={isStatisticsLoading}
				/>
				<Card
					title="Total bruto ult. 30 días"
					amount={range_profit}
					tooltip="Ingresos totales obtenidos por referidos y suscripciones en los últimos 30 días."
					loading={isStatisticsLoading}
				/>
			</div>
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Ganancias por compras de los referidos.</h3>
				</div>
				{isStatisticsLoading ? (
					<div className="flex h-[400px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<InfluencerBarChart chartRef={chartRef} dates={dates} showByMonths={showByMonths} percentage={percentage} />
				)}
			</div>
		</div>
	);
}
