import {createUserRental, getListProductsRental, getUserRentals, RentalBody, updateUserRental} from "../../../data-access/series/user-rentals";
import {auth} from "../../../firebase";
import {createAppAsyncThunk} from "../../hooks";
import {rentalActions} from "./slice";

export const loadRentalsByUserId = createAppAsyncThunk("rentals/loadRentalByUserId", async (userid: string, thunkApi) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkApi.rejectWithValue("No token");

		thunkApi.dispatch(rentalActions.loadProductRentals());
		const {
			data: {results: rentals},
		} = await getUserRentals(token, {userid, page: 0, page_size: 10000});

		return rentals;
	} catch (error: any) {
		thunkApi.rejectWithValue(error.message);
	}
});

export const loadProductRentals = createAppAsyncThunk("rentals/loadProductRental", async (_, thunkApi) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkApi.rejectWithValue("No token");

		const {
			data: {results: rentals},
		} = await getListProductsRental(token, {page: 0, page_size: 10000});

		return rentals;
	} catch (error: any) {
		thunkApi.rejectWithValue(error.message);
	}
});

export const updateRental = createAppAsyncThunk("rentals/updateRental", async (data: RentalBody, thunkApi) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkApi.rejectWithValue("No token");

		const response = await updateUserRental(token, data);

		thunkApi.dispatch(rentalActions.clearCurrentRental());
		thunkApi.dispatch(rentalActions.loadRentalsByUserId(data.userid));

		return response.data;
	} catch (error: any) {
		thunkApi.rejectWithValue(error.message);
	}
});

export const createRental = createAppAsyncThunk("rentals/createRental", async (data: RentalBody, thunkApi) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkApi.rejectWithValue("No token");

		const response = await createUserRental(token, data);

		thunkApi.dispatch(rentalActions.clearCurrentRental());
		thunkApi.dispatch(rentalActions.loadRentalsByUserId(data.userid));

		return response.data;
	} catch (error: any) {
		thunkApi.rejectWithValue(error.message);
	}
});
