import {useEffect, useMemo, useState} from "react";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../components/primitives/Dialog";
import {Button} from "../../../components/primitives/Button";
import Select, {SingleValue} from "react-select";
import {
	CustomNotification,
	CustomNotificationNavigation,
	NotificationSettings,
	UpdateCustomNotificationBody,
} from "../../../data-access/notifications/notifications";
import {ListPlus, Pencil, Save, Trash} from "../../../components/primitives/icons";
import toast from "react-hot-toast";
import {Switch} from "../../../components/primitives/Switch";
import {Checkbox} from "../../../components/primitives/Checkbox";
import {CheckedState} from "@radix-ui/react-checkbox";
import {COMMON_APP_SCREENS} from "../../../constants";
import {urlRegex} from "../../../../utils/validators";

interface CustomNotificationModalProps {
	openDialog: boolean;
	isLoading: boolean;
	notificationsSettingsList?: (NotificationSettings & {label?: string; active?: boolean})[];
	editableData?: CustomNotification;
	onDismiss: () => void;
	onSubmit: (data: Pick<UpdateCustomNotificationBody, "id" | "type" | "navigation" | "text" | "subject">) => void;
}

export default function CustomNotificationModal({
	openDialog,
	notificationsSettingsList,
	isLoading,
	editableData,
	onDismiss,
	onSubmit,
}: CustomNotificationModalProps) {
	const [textList, setTextList] = useState<{text: string; active: boolean; id: number}[]>(
		editableData
			? editableData.text.map((i, idx) => ({
					active: false,
					id: idx,
					text: i,
			  }))
			: [],
	);
	const [currentTextFeature, setCurrentTextFeature] = useState("");
	const [data, setData] = useState<{
		subject: string;
		type: string;
	}>({subject: editableData ? editableData.subject : "", type: editableData ? editableData.type : ""});
	const [enableNavigation, setEnableNavigation] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	const [cta, setCta] = useState<CustomNotificationNavigation>(() =>
		editableData?.navigation ? editableData.navigation : {internal: true, address: "", id: null},
	);

	useEffect(() => {
		if (editableData !== undefined) {
			setTextList(editableData.text.map((text, index) => ({text, active: false, id: index})));
			setData({subject: editableData.subject, type: editableData.type});
			setEnableNavigation(editableData.navigation !== null);
			setCta(editableData.navigation ? editableData.navigation : {internal: true, address: "", id: null});
		}
	}, [editableData]);

	const notificationsSettingOptions = useMemo(() => {
		if (!notificationsSettingsList) return [];
		if (!editableData || !editableData.type) {
			return notificationsSettingsList
				.filter(s => s.active === false)
				.map(setting => ({
					label: setting?.label,
					value: setting.notification_type,
				}));
		}
		return notificationsSettingsList.map(setting => ({
			label: setting?.label,
			value: setting.notification_type,
		}));
	}, [notificationsSettingsList, editableData]);

	const onAddNewText = () => {
		if (!currentTextFeature || currentTextFeature.trim() === "") return;
		setTextList(prev => [...prev, {text: currentTextFeature, active: false, id: textList.length > 0 ? textList[textList.length - 1].id + 1 : 0}]);
		setCurrentTextFeature("");
	};
	const handleCurrentText = (e: React.ChangeEvent<HTMLInputElement>) => {
		const activeItem = textList.find(item => item.active);
		if (activeItem) {
			setTextList(prev => prev.map(item => (item.id === activeItem.id ? {...item, text: e.target.value} : item)));
			return;
		}
		setCurrentTextFeature(e.target.value);
	};
	const onEditText = (value: number) => {
		const item = textList.find(item => item.id === value);
		if (!item) return;
		if (item.active) setTextList(prev => prev.map(item => (item.id === value ? {...item, active: false} : item)));
		else setTextList(prev => prev.map(item => (item.id === value ? {...item, active: true} : item)));
		setCurrentTextFeature("");
	};
	const onRemoveText = (value: number) => {
		setTextList(prev => prev.filter(item => item.id !== value));
	};
	const handleSubmit = () => {
		if (enableNavigation && !cta.internal && !urlRegex.test(cta.address)) return toast.error("URL de redirección inválida");
		if (!textList.length) return toast.error("Debes agregar al menos un mensaje a la notificación");
		if (!data.subject) return toast.error("Debes agregar un título a la notificación");
		if (!data.type) return toast.error("Debes seleccionar un tipo de notificación");
		const dataToSend: Pick<UpdateCustomNotificationBody, "id" | "type" | "navigation" | "text" | "subject"> = {
			...data,
			id: editableData?.id,
			text: textList.map(i => i.text),
			navigation: enableNavigation ? cta : null,
		};
		onSubmit?.(dataToSend);
		handleDismiss();
	};

	const handleDismiss = () => {
		setCurrentTextFeature("");
		setData({subject: "", type: ""});
		setTextList([]);
		setEnableNavigation(false);
		setCta({internal: true, address: "", id: null});
		onDismiss();
	};
	const handleEnableNavigation = (checked: CheckedState) => setEnableNavigation(checked as boolean);
	const handleNavigationType = (checked: CheckedState) => setCta(prev => ({...prev, internal: checked as boolean}));
	const handleCtaAddressChange = (newValue: SingleValue<{label: string; value: string}>) => {
		if (!newValue?.value) return;
		setCta(prev => ({...prev, address: newValue.value}));
	};
	const handleExternalAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => setCta(prev => ({...prev, address: e.target.value}));

	const modalTitle = useMemo(
		() => (!editableData || editableData.type === "" ? "Agregar nueva notificación" : "Editar notificación"),
		[editableData],
	);

	const handleSelectNotificationType = (newValue: SingleValue<{label: string; value: string | undefined}>) => {
		if (!newValue?.value) return;
		const selectedSetting = notificationsSettingsList?.find(setting => setting.notification_type === newValue.value);
		setData(prev => ({...prev, type: selectedSetting?.notification_type ?? ""}));
	};

	return (
		<Dialog open={openDialog} onOpenChange={handleDismiss} modal>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="border-b border-border pb-2 font-medium text-white">{modalTitle}</DialogTitle>
				</DialogHeader>
				<div className="container space-y-5 px-0.5 py-5">
					<div className="flex justify-between gap-5">
						<div className="flex w-1/2 flex-col gap-5">
							<Select
								placeholder="Selecciona el tipo de notificación"
								options={notificationsSettingOptions}
								onChange={handleSelectNotificationType}
								defaultValue={notificationsSettingOptions.find(option => option.value === editableData?.type)}
								isDisabled={!!editableData && editableData.type !== ""}
							/>
							<div className="flex w-full gap-3">
								<input
									className="w-full rounded bg-black px-3 py-2 text-white"
									type="text"
									name="subject"
									onChange={e => setData(prev => ({...prev, subject: e.target.value}))}
									placeholder="Titulo"
									defaultValue={editableData?.subject}
								/>
							</div>
							<div className="relative flex flex-col">
								{isFocused && (
									<div className="absolute -left-0.5 -top-32 flex items-center gap-3 rounded bg-slate-800 p-1 shadow-lg shadow-slate-950">
										<span className="text-3xl text-amber-400">⚠️</span>
										<ul className="relative text-sm text-white">
											<p>Utilice los siguientes patrones dentro del mensaje:</p>{" "}
											<li className="ml-4 list-disc">
												<span className="font-semibold text-amber-400">{"<user_name>"}</span>: Se reemplazará por el nombre
												del <b>usuario</b> al enviar notificación.
											</li>
											<li className="ml-4 list-disc">
												<span className="font-semibold text-amber-400">{"<content_name>"}</span>: Se reemplazará por el nombre
												del <b>contenido</b> al enviar notificación.
											</li>
											<div className="absolute -bottom-3 left-0 h-0 w-0 -rotate-45 border-l-[20px] border-t-[20px] border-l-slate-800 border-t-transparent shadow-lg shadow-slate-950" />
										</ul>
									</div>
								)}
								<div className="flex gap-3">
									<input
										className="w-full rounded bg-black px-2 py-2 text-white"
										type="text"
										name="notification-text"
										placeholder="Texto de la notificación"
										value={currentTextFeature}
										onChange={handleCurrentText}
										onFocus={() => setIsFocused(true)}
										onBlur={() => setIsFocused(false)}
									/>
									<Button onClick={onAddNewText}>
										<ListPlus />
									</Button>
								</div>
							</div>
						</div>
						<div className="flex w-1/2 flex-col justify-between gap-5">
							<div className="mt-2 flex items-center gap-3">
								<Checkbox defaultChecked={!!editableData?.navigation} className="h-5 w-5" onCheckedChange={handleEnableNavigation} />
								<span className="whitespace-nowrap text-white">¿Navegar desde la notificación?</span>
							</div>
							<div className="flex items-center gap-3">
								<div className="flex items-center gap-2">
									<span className="whitespace-nowrap text-white">Enlace interno?</span>
									<Switch
										defaultChecked={editableData?.navigation?.internal ?? true}
										onCheckedChange={handleNavigationType}
										disabled={!enableNavigation}
									/>
								</div>
								{cta.internal ? (
									<Select
										className="h-10 w-full"
										placeholder="Seleccione pantalla"
										options={COMMON_APP_SCREENS}
										onChange={handleCtaAddressChange}
										isDisabled={!enableNavigation}
										defaultValue={COMMON_APP_SCREENS.find(option => option.value === editableData?.navigation?.address)}
									/>
								) : (
									<input
										className="w-full rounded bg-black px-2 py-2 text-white disabled:bg-opacity-10"
										type="url"
										name="redirect-url"
										placeholder="URL de redirección"
										onChange={handleExternalAddressChange}
										disabled={!enableNavigation}
										value={cta.address}
									/>
								)}
							</div>
						</div>
					</div>
					{textList?.length > 0 && (
						<div className="scrollbar-thumb-gray-00 flex h-[200px] w-full flex-col overflow-y-scroll rounded-md border scrollbar-thin scrollbar-track-gray-300">
							{textList.map(item => (
								<div key={item.id} className="flex items-center gap-3 p-2">
									<input
										className="mr-2 w-full rounded bg-black px-2 py-2 text-white disabled:bg-opacity-10"
										type="text"
										name="text"
										placeholder="Texto de la notificación"
										value={item.text}
										disabled={!item.active}
										onChange={handleCurrentText}
									/>
									<div className="flex gap-1">
										<Button onClick={() => onEditText(item.id)}>{item.active ? <Save /> : <Pencil />}</Button>
										<Button variant="destructive" onClick={() => onRemoveText(item.id)}>
											<Trash />
										</Button>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
				<div className="flex flex-row justify-end">
					<Button className="mr-2 w-32 text-white" size="sm" variant="outline" onClick={handleDismiss}>
						Cancelar
					</Button>
					<Button className="w-32 bg-black text-white" size="sm" variant="outline" onClick={handleSubmit} disabled={isLoading}>
						Completar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
