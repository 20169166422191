import {IconProps} from "./type";

const Down = (props: IconProps) => (
	<svg width={13} height={13} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M4.625 6.96875L6.5 8.84375M6.5 8.84375L8.375 6.96875M6.5 8.84375V4.15625M12.125 6.5C12.125 7.23869 11.9795 7.97014 11.6968 8.65259C11.4141 9.33505 10.9998 9.95515 10.4775 10.4775C9.95515 10.9998 9.33505 11.4141 8.65259 11.6968C7.97014 11.9795 7.23869 12.125 6.5 12.125C5.76131 12.125 5.02986 11.9795 4.34741 11.6968C3.66495 11.4141 3.04485 10.9998 2.52252 10.4775C2.00019 9.95515 1.58586 9.33505 1.30318 8.65259C1.02049 7.97014 0.875 7.23869 0.875 6.5C0.875 5.00816 1.46763 3.57742 2.52252 2.52252C3.57742 1.46763 5.00816 0.875 6.5 0.875C7.99184 0.875 9.42258 1.46763 10.4775 2.52252C11.5324 3.57742 12.125 5.00816 12.125 6.5Z"
			stroke="currentColor"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default Down;
