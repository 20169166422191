import {useEffect, useMemo, useState} from "react";
import {Lock, Pencil, Refresh} from "../../../../../../components/primitives/icons";
import {BuddyPlanBodyType, Slots, UserSubscription} from "../../../../../../data-access/subscriptions/subscriptions";
import {SLOTS_STATES} from "../../../../../../constants";
import {auth} from "../../../../../../firebase";
import {fetchDbUsers} from "../../../../../../data-access/fetch-db-users";
import {dbUser} from "../../../../../../_store/features/user-db/user-db-slice";
import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {DataTableColumnHeader} from "../../../../../../components/primitives/DataTable";
import {Button} from "../../../../../../components/primitives/Button";
import {DataTable} from "../../../../../../components/blocks/DataTable";
import {EditBuddyModal} from "./EditBuddyModal";
import Tooltip from "../../../../../../components/primitives/ToolTip-toDeprecate";
import {subscriptionActions} from "../../../../../../_store/features/subscriptions/subscriptions-slice";
import {useAppDispatch} from "../../../../../../_store/hooks";
import toast from "react-hot-toast";
import useCurrentUserRole from "../../../../../../components/hooks/useCurrentUserRole";

export interface SlotsTableProps {
	data?: UserSubscription[];
	isLoading?: boolean;
	onSelectRow?: (row: UserSubscription) => void;
	onUpdate?: () => void;
}

type BuddyUser = {id: string; name: string; email: string};

interface SlotRow extends Slots {
	index: number;
	buddy_name: string;
	buddy_email: string;
}

const columnHelper = createColumnHelper<SlotRow>();

export default function SlotsTable({data = [], isLoading, onUpdate}: SlotsTableProps) {
	const [loadingUsers, setloadingUsers] = useState(false);
	const {isCSL1} = useCurrentUserRole();
	const [buddyUsersList, setBuddyUsersList] = useState<BuddyUser[]>([]);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const [openModal, setOpenModal] = useState(false);
	const [currentSlot, setCurrentSlot] = useState<BuddyPlanBodyType | null>(null);

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const dispatch = useAppDispatch();

	useEffect(() => {
		const fetchUsersById = async () => {
			if (data.length === 0) return;

			const slots = data[0].slots as Slots[];
			if (!slots?.length) return;

			const filteredData = slots.filter(el => el !== null && el.buddy_id !== null);
			setloadingUsers(true);

			const token = await auth.currentUser?.getIdToken();

			if (!token) return;

			Promise.allSettled(
				filteredData.map(slot =>
					fetchDbUsers({idToken: token, searchby: "id", searchvalue: slot!.buddy_id as string, page: 0, admin: false}),
				),
			)
				.then(res => {
					const userData = res
						.map(el => (el.status === "fulfilled" ? (el.value.data.results[0] as dbUser) : null))
						.filter(user => user !== null);

					const users = userData.map(user => ({id: user?.id ?? "", name: user?.name ?? "", email: user?.email ?? ""}));

					setBuddyUsersList(prev => {
						return [...prev, ...users];
					});
				})
				.finally(() => setloadingUsers(false));
		};

		if (data.length) fetchUsersById();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const tableData = useMemo(() => {
		if (data.length === 0) return [];

		const slots = data[0].slots as Slots[];
		return (
			slots
				?.filter(el => el !== null)
				.map((slot, index) => ({
					...slot,
					index,
					buddy_name: buddyUsersList.find(user => user.id === slot.buddy_id)?.name ?? "",
					buddy_email: buddyUsersList.find(user => user.id === slot.buddy_id)?.email ?? "",
				})) ?? ([] as SlotRow[])
		);
	}, [buddyUsersList, data]);

	const pageCount = pagination.pageSize ? Math.ceil(tableData.length / pagination.pageSize) : 1;

	const handleEditSlot = (row: SlotRow) => {
		setCurrentSlot(row);
		setOpenModal(true);
	};

	const onRemoveUser = async (slot: Slots) => {
		if (!slot) {
			return toast.error("No se ha seleccionado un cupo");
		}

		if (window.confirm("¿Estás seguro de eliminar el usuario del cupo?")) {
			const updatedSlot = {
				...slot,
				buddy_id: null,
				buddy_name: "",
				buddy_email: "",
				state: "available",
				limited_time: false,
				time_lock: true,
			} as Slots;
			const thunk = await dispatch(subscriptionActions.manageAdminBuddyPlan(updatedSlot));

			if (thunk.meta.requestStatus === "rejected") {
				toast.error("Error al eliminar el usuario del cupo", {position: "top-right"});
				return;
			}

			toast.success("Usuario eliminado del cupo correctamente", {position: "top-right"});
			onUpdate?.();
		}
	};

	const onResetSlot = async (slot: Slots) => {
		if (!slot) {
			return toast.error("No se ha seleccionado un cupo");
		}

		if (window.confirm("¿Estás seguro de resetear el cupo?")) {
			const updatedSlot = {
				...slot,
				buddy_id: null,
				buddy_name: "",
				buddy_email: "",
				state: "available",
				audit_accepted: null,
				time_lock: false,
			} as Slots;
			// id, subscriber_id, buddy_id, state, limited_time, audit_accepted;
			const thunk = await dispatch(subscriptionActions.manageAdminBuddyPlan(updatedSlot));

			if (thunk.meta.requestStatus === "rejected") {
				toast.error("Error al resetear el cupo.", {position: "top-right"});
				return;
			}

			toast.success("Cupo reseteado correctamente.", {position: "top-right"});
			onUpdate?.();
		}
	};

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("index", {
				id: "index",
				header: ({column}) => <DataTableColumnHeader title="#" column={column} />,
				cell: info => <div className="items-left justify-left ml-2 flex w-full text-left">{info.getValue() + 1}</div>,
				size: Math.round(tableWidth * 0.075),
				enableResizing: false,
				enableSorting: false,
			}),
			columnHelper.accessor("state", {
				id: "state",
				header: ({column}) => <DataTableColumnHeader title="Estado" column={column} className="ml-2 flex" />,
				cell: info => {
					const status = info.row.original?.time_lock
						? "Bloqueado"
						: SLOTS_STATES.find(state => state.value === info.row.original?.state)?.label ?? "";
					return <div className="items-left justify-left ml-2 flex w-full text-left">{status}</div>;
				},
				size: Math.round(tableWidth * 0.1),
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("buddy_name", {
				id: "buddy_name",
				header: ({column}) => <DataTableColumnHeader title="Nombre" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.getValue() || "--"}</span>
					</div>
				),
				size: Math.round(tableWidth * 0.2),
			}),
			columnHelper.accessor("buddy_email", {
				id: "buddy_email",
				header: ({column}) => <DataTableColumnHeader title="Correo eléctronico" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.getValue() || "--"}</span>
					</div>
				),
				size: Math.round(tableWidth * 0.2),
			}),
			columnHelper.accessor("buddy_id", {
				id: "buddy_id",
				header: ({column}) => <DataTableColumnHeader title="ID.Usuario" column={column} />,
				cell: info => <div className="items-left justify-left ml-2 flex w-full text-left">{info.getValue() || "--"}</div>,
				size: Math.round(tableWidth * 0.295),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => {
					return (
						<div className="justify-left flex w-full gap-2">
							<Tooltip text="Editar cupo">
								<Button
									size={"sm"}
									variant={"outline"}
									type="button"
									onClick={() => handleEditSlot(info.row.original)}
									disabled={isCSL1}
								>
									<Pencil className="h-4 w-4" />
								</Button>
							</Tooltip>

							{!!info.row.original.buddy_id && (
								<Tooltip text="Eliminar usuario del cupo (bloquear cupo)" placement="top">
									<Button size={"sm"} variant={"outline"} onClick={() => onRemoveUser(info.row.original)} disabled={isCSL1}>
										<Lock className="h-4 w-4" />
									</Button>
								</Tooltip>
							)}

							<Tooltip text="Resetear cupo" placement="top">
								<Button size={"sm"} variant={"outline"} onClick={() => onResetSlot(info.row.original)} disabled={isCSL1}>
									<Refresh className="h-4 w-4" />
								</Button>
							</Tooltip>
						</div>
					);
				},
				size: Math.round(tableWidth * 0.1),
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableRef]);

	return (
		<div>
			<DataTable
				ref={ref => setTableRef(ref)}
				columns={columns}
				dataset={tableData}
				pageCount={pageCount}
				pagination={pagination}
				loading={isLoading || loadingUsers}
				onPaginationChange={setPagination}
				withDynamicPageSize
				rowHeight={70}
				showPagination={false}
			/>

			{currentSlot && (
				<EditBuddyModal
					open={openModal}
					buddyData={currentSlot}
					onDismiss={() => {
						setOpenModal(false);
						setCurrentSlot(null);
					}}
					onUpdate={onUpdate}
				/>
			)}
		</div>
	);
}
