import {useCallback, useEffect, useReducer} from "react";
import {deleteGlobalSetting, getGlobalSetting} from "../../../data-access/settings";
import {auth} from "../../../firebase";
import NewItemSheet from "./subcomponent/NewItemSheet";
import {Button} from "../../../components/primitives/Button";
import {PlusSmall} from "../../../components/primitives/icons";
import toast from "react-hot-toast";

interface Setting {
	id: string;
	key: string;
	value: string;
}

interface SettingState {
	settings: Setting[];
	isNewItemSheetOpen: boolean;
	defaultValues: Setting | null;
}

type SettingAction =
	| {
			type: "TOGGLE_IS_NEW_ITEM_SHEET_OPEN";
	  }
	| {
			type: "SET_SETTINGS";
			payload: Setting[];
	  }
	| {
			type: "UPDATE_SETTING";
			payload: Setting;
	  };

const reducer = (state: SettingState, action: SettingAction) => {
	switch (action.type) {
		case "TOGGLE_IS_NEW_ITEM_SHEET_OPEN":
			return {...state, isNewItemSheetOpen: !state.isNewItemSheetOpen, defaultValues: state.isNewItemSheetOpen ? null : state.defaultValues};
		case "SET_SETTINGS":
			return {...state, settings: action.payload, isNewItemSheetOpen: false};
		case "UPDATE_SETTING":
			return {...state, defaultValues: action.payload, isNewItemSheetOpen: true};
		default:
			return state;
	}
};

const loadSettings = async () => {
	const token = await auth.currentUser?.getIdToken();

	if (!token) {
		return [];
	}

	const response = await getGlobalSetting(token);
	return response.data;
};

function GloblalSetting() {
	const [{settings, isNewItemSheetOpen, defaultValues}, dispatch] = useReducer(reducer, {
		settings: [] as Setting[],
		isNewItemSheetOpen: false,
		defaultValues: null,
	});

	const onSettingsUpdate = useCallback(async () => {
		loadSettings().then(data => {
			dispatch({type: "SET_SETTINGS", payload: data});
		});
	}, []);

	const onSettingUpdate = useCallback((setting: Setting) => {
		dispatch({type: "UPDATE_SETTING", payload: setting});
	}, []);

	const onSettingDelete = useCallback(async (setting: Setting) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) {
				return toast.error("No se pudo obtener el token de autenticación");
			}

			if (window.confirm("¿Estás seguro de eliminar este item?")) {
				await deleteGlobalSetting(token, setting.id);
				onSettingsUpdate();
				toast.success("Item eliminado correctamente");
			}
		} catch (error) {
			toast.error("Ocurrió un error al eliminar el item");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onSettingsUpdate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex h-screen flex-col">
			<div className="flex flex-row items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Opciones globales</h2>

				<div className="flex gap-2">
					<Button className="gap-2" onClick={() => dispatch({type: "TOGGLE_IS_NEW_ITEM_SHEET_OPEN"})}>
						<PlusSmall />
						Agregar item
					</Button>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<table>
					{settings.map(setting => (
						<tr key={setting.id} className="bg-background-alt flex flex-row items-center justify-between border-b border-b-border p-4">
							<td className="wrap w-96 flex-wrap text-lg">{setting.key}</td>
							<td className="flex-1 text-lg">{setting.value}</td>

							<td className="flex gap-2">
								<Button className="gap-2" onClick={() => onSettingUpdate(setting)}>
									Editar
								</Button>
								<Button className="gap-2" onClick={() => onSettingDelete(setting)}>
									Eliminar
								</Button>
							</td>
						</tr>
					))}
				</table>
			</div>

			<NewItemSheet
				key={defaultValues?.id}
				open={isNewItemSheetOpen}
				onOpenChange={_ => {
					dispatch({type: "TOGGLE_IS_NEW_ITEM_SHEET_OPEN"});
				}}
				onUpdate={onSettingsUpdate}
				defaultValues={defaultValues}
			/>
		</div>
	);
}

export default GloblalSetting;
