import {useRef} from "react";
import {cn} from "../../../../../utils/classNames";
import {Comment} from "../../../../data-access/comments/comments";

export default function LimitedComment({comment, isSelected}: {comment: Comment; isSelected: boolean}) {
	const nestingLevel = useRef(comment.path?.split(".").length ?? 0).current;

	return (
		<div
			key={comment.id}
			className={cn({
				"md xs:pl-xl text-14 before:border-tone-4 xs:before:left-[16.5px] relative": nestingLevel > 0,
			})}
			style={{paddingLeft: nestingLevel * 24}}
		>
			<div
				className={cn(
					"relative m-1 rounded-md border border-border bg-accent p-4",
					[
						"before:absolute",
						"before:left-[-12px]",
						"before:top-[-6px]",
						"before:h-[50%]",
						"before:w-[12px]",
						"before:border-y-0",
						"before:border-l-[1px]",
						"before:border-r-[0px]",
						"before:border-b-[1px]",
						"before:rounded-bl-md",
						"before:border-solid",
						"before:border-l-2",
						"before:border-b-2",
						"before:content-['']",
					],
					{
						"border-foreground/50 before:border-foreground/50": isSelected,
					},
				)}
			>
				<div className="mt-2 text-center text-sm italic">Comentario con visibilidad reducida.</div>
				<div className="mt-2 text-xs text-muted-foreground">
					<span>ID Comentario:</span> {comment.id}
				</div>
			</div>
		</div>
	);
}
