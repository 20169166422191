import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {partnerReportsActions} from "../../../../_store/features/reports/report-slice";
import {useAppDispatch} from "../../../../_store/hooks";

const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

interface SelectMonthProps {
	onChangeMonth: Dispatch<SetStateAction<any>>;
	partner: string | undefined;
}

const SelectMonth = ({partner}: SelectMonthProps) => {
	const dispatch = useAppDispatch();

	const [month, setMonth] = useState<number>(currentMonth);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [year, setYear] = useState<number>(new Date().getFullYear());

	const queryDate = useMemo(() => `${year}-${month.toString().padStart(2, "0")}-01 12:00:00`, [month, year]);

	useEffect(() => {
		dispatch(partnerReportsActions.loadReportsByMonth({date: queryDate, partner}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryDate]);

	const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setMonth(Number(e.target.value));
	};

	return (
		<div className="flex items-center gap-4">
			<div className="flex items-center gap-2">
				<select className="rounded-md border border-border p-2">
					<option>2024</option>
				</select>
				<select className="rounded-md border border-border p-2" onChange={handleOnChange} value={month}>
					{months.slice(0, currentMonth).map((month, index) => (
						<option key={`${month}`} value={index + 1}>
							{month}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default SelectMonth;
