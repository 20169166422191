import {Skeleton} from "../../../primitives/Skeleton";
import {Spinner} from "../../../primitives/icons";

export default function Loader({title = "Loading"}: {title?: string}) {
	return (
		<div className={`grid h-[440px] grid-rows-[auto] md:grid-cols-2 lg:grid-cols-7`}>
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<Skeleton className={`h-[440px] duration-1000"`} />
				<div className="relative top-[-200px] ml-[47%] h-[0px] w-[100%]">
					<Spinner />
				</div>
				<h3 className={`relative top-[-416px] ml-6 h-[0px] text-lg font-semibold leading-none tracking-tight`}>{title}</h3>
			</div>
		</div>
	);
}
