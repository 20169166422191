import {CreatePostBody, GetPostsListParams, Post} from "./types";
import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export const getExclusiveRoomPosts = (token: string, params: GetPostsListParams): Promise<AxiosResponse<Post[]>> => {
	const idToken = `Bearer ${token}`;
	return axiosHandler.get(`/admin_get_exclusive_room_list`, {
		params,
		headers: {
			Authorization: idToken,
		},
	});
};

export const createPost = async (token: string, data: CreatePostBody): Promise<AxiosResponse<any>> => {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/create_exclusive_room", data, {
		headers: {
			Authorization: idToken,
		},
	});
};

export const updatePostService = async (token: string, data: Post): Promise<AxiosResponse<any>> => {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/admin_update_comment", data, {
		headers: {
			Authorization: idToken,
		},
	});
};
