import toast from "react-hot-toast";
import {adminCreateInternalUserData} from "../../../_store/features/user-db/user-db-actions";
import {useAppDispatch} from "../../../_store/hooks";
import {Button} from "../../../components/primitives/Button";
import {Input} from "../../../components/primitives/Input";
import {Spinner} from "../../../components/primitives/icons/Spinner";
import {NewInternalUserBodySchema, NewInternalUserBodyType} from "../../../data-access/fetch-db-users";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useNavigate} from "react-router-dom";

const initialState: NewInternalUserBodyType = {
	name: "",
	nickname: "",
};

function UsersInternalNew() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		reset,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<NewInternalUserBodyType>({
		resolver: zodResolver(NewInternalUserBodySchema),
		defaultValues: initialState,
	});

	const onSubmit: SubmitHandler<NewInternalUserBodyType> = data => {
		return dispatch(adminCreateInternalUserData(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Usuario Creado Correctamente");
				reset();
				navigate(-1);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Nuevo Usuario</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite crear un nuevo usuario de customer service interno.</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-gray-100">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Nombre<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar nombre de usuario..." {...register("name")} />
									{errors?.name?.message && <span className="text-sm font-medium text-destructive">{errors?.name?.message}</span>}
								</dd>
							</div>
						</dl>
					</div>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-gray-100">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Alias<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar nombre de usuario..." {...register("nickname")} />
									{errors?.nickname?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.nickname?.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								reset();
								navigate(-1);
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{isLoading || (isSubmitting && <Spinner className="mr-2 h-4 w-4 animate-spin" />)}
							Crear Usuario
						</Button>
					</div>
				</form>
			</div>
		</section>
	);
}

export default UsersInternalNew;
