import {useMemo, useRef, useState} from "react";
import {useDashboardPartnerByNameQuery} from "../../../../../../_store/features/dashboard-partner/hooks";
import {Spinner} from "../../../../../../components/primitives/icons";
import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, ChartOptions, LinearScale} from "chart.js";
import "chartjs-adapter-date-fns";
import {format, getISOWeek, getWeek, parse} from "date-fns";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {es} from "date-fns/locale";
import {useAppDispatch, useAppSelector} from "../../../../../../_store/hooks";
import {GROUPING} from "../../../../../../constants";
import {dashboardPartnerActions} from "../../../../../../_store/features/dashboard-partner/dashboard-partner-slice";
import {maxGroupingRange} from "../../../../../../../utils/selectGroupingRange";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

const RentalsChart = () => {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; count: number}[]>>(null);
	const dispatch = useAppDispatch();
	const {data: statistics, isLoading} = useDashboardPartnerByNameQuery("getPartnerGeneralIncomeAndProfit");
	const {range, grouping} = useAppSelector(state => state.dashboardPartner);
	const [groupEnables, setGroupEnables] = useState([GROUPING.DAILY]);

	const data: ChartData<"bar", {date: string; count: number}[]> = useMemo(() => {
		if (!statistics) return {datasets: []};
		let rangeLenght: number;

		if (grouping === GROUPING.DAILY) {
			rangeLenght = Math.ceil((new Date(range.to).getTime() - new Date(range.from).getTime()) / 1000 / 86400);
			if (rangeLenght < 7) setGroupEnables([GROUPING.DAILY]);
			else if (rangeLenght < 30) setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY]);
			else setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY, GROUPING.MONTHLY]);
		} else if (grouping === GROUPING.WEEKLY) {
			let first = getISOWeek(new Date(range.from));
			let last = getISOWeek(new Date(range.to));
			rangeLenght = last - first + 1;
			if (rangeLenght < 4) setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY]);
			else if (rangeLenght > maxGroupingRange / 7) setGroupEnables([GROUPING.WEEKLY, GROUPING.MONTHLY]);
			else setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY, GROUPING.MONTHLY]);
		} else if (grouping === GROUPING.MONTHLY) {
			let first = new Date(range.from).getMonth();
			let last = new Date(range.to).getMonth();
			rangeLenght = last - first + 1;
			if (rangeLenght > maxGroupingRange / 30) setGroupEnables([GROUPING.WEEKLY, GROUPING.MONTHLY]);
			else setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY, GROUPING.MONTHLY]);
		} else {
			rangeLenght = 0;
		}

		const selectedData: {date: string; count: number}[] = Array.from({length: rangeLenght}, (_, i) => {
			var date = new Date(range.from);
			if (grouping === GROUPING.DAILY) date.setDate(date.getDate() + i);
			else if (grouping === GROUPING.WEEKLY) {
				const week = new Date().setTime(date.getTime() + i * 7 * 24 * 60 * 60 * 1000);
				const par = date.getFullYear() + "-" + getWeek(week);
				const newDate = parse(par, "YYYY-wo", new Date(), {
					useAdditionalWeekYearTokens: true,
				});
				date = new Date(newDate);
			} else if (grouping === GROUPING.MONTHLY) {
				date.setMonth(date.getMonth() + i);
			}
			return {date: date.toLocaleDateString(), count: 0};
		});

		const seriesDatasets = statistics.series
			.filter(serie => serie.rental_content)
			.map(serie => {
				if (!serie.isPartner || serie.views.length === 0) return null;
				const serieSelectedData = selectedData.map(data => ({...data}));
				serie.views.map(view => {
					if (grouping === GROUPING.DAILY) {
						const date = new Date(view.year + "-" + view.month + "-" + view.day);
						const index = selectedData.findIndex(data => data.date === date.toLocaleDateString());
						serieSelectedData[index].count += view.rentals_count;
						return null;
					} else if (grouping === GROUPING.WEEKLY) {
						const date = view.year + "-" + (view.week! + 1);
						const weekDay = parse(date, "YYYY-wo", new Date(), {useAdditionalWeekYearTokens: true});
						const index = selectedData.findIndex(data => {
							return data.date === weekDay.toLocaleDateString();
						});
						if (index !== -1) serieSelectedData[index].count += view.rentals_count;
						return null;
					} else if (grouping === GROUPING.MONTHLY) {
						const date = new Date(view.year + "-" + view.month!);
						const index = selectedData.findIndex(data => data.date === date.toLocaleDateString());
						if (index !== -1) serieSelectedData[index].count += view.rentals_count;
						return null;
					} else return null;
				});
				return {
					label: serie.title + " - $" + serie.total_series_rentals_earnings?.toFixed(2),
					data: serieSelectedData,
					backgroundColor: serie.color,
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "count",
					},
				};
			})
			.filter(serie => serie !== null) as {
			label: string;
			data: {date: string; count: number}[];
			backgroundColor: string;
			borderRadius: number;
			parsing: {xAxisKey: string; yAxisKey: string};
		}[];

		return {
			labels: selectedData.map(data => data.date),
			datasets: seriesDatasets,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statistics]);

	const handleGroupingChange = (grouping: GROUPING) => {
		dispatch(dashboardPartnerActions.getPartnerGeneralIncomeAndProfit({initial_date: range.from, final_date: range.to, grouping}));
	};

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="relative col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Rentas</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<>
						<div className="relative p-6 pt-0">
							<Bar
								height={350}
								ref={chartRef}
								options={{
									...options,
									scales: {
										x: {
											ticks: {
												callback(tickValue) {
													let value = this.getLabelForValue(tickValue as any);
													const date: Date = new Date(value);
													let formattedDate: string;
													// Format the date based on the available information
													if (grouping === GROUPING.MONTHLY) {
														formattedDate = format(date, "MMM", {locale: es});
													} else {
														formattedDate = format(date, "MMM d", {locale: es}).toLowerCase();
													}
													return formattedDate;
												},
											},
											grid: {
												color: "transparent",
											},
											stacked: true,
										},
										y: {
											ticks: {
												precision: 1,
											},
											stacked: true,
										},
									},
								}}
								data={data}
							/>
						</div>
						<div className="absolute right-0 top-0 flex justify-center space-x-2 p-4">
							<button
								onClick={() => {
									handleGroupingChange(GROUPING.DAILY);
								}}
								className={`${grouping === GROUPING.DAILY ? "pointer-events-none bg-transparent shadow" : "bg-slate-200"} ${
									groupEnables.includes(GROUPING.DAILY) ? "" : "pointer-events-none opacity-40"
								} rounded border px-4 py-1`}
							>
								Diario
							</button>
							<button
								onClick={() => {
									handleGroupingChange(GROUPING.WEEKLY);
								}}
								className={`${grouping === GROUPING.WEEKLY ? "pointer-events-none bg-transparent shadow" : "bg-slate-200"} ${
									groupEnables.includes(GROUPING.WEEKLY) ? "" : "pointer-events-none opacity-40"
								} rounded border px-4 py-1`}
							>
								Semanal
							</button>
							<button
								onClick={() => {
									handleGroupingChange(GROUPING.MONTHLY);
								}}
								className={`${grouping === GROUPING.MONTHLY ? "pointer-events-none bg-transparent shadow" : "bg-slate-200"} ${
									groupEnables.includes(GROUPING.MONTHLY) ? "" : "pointer-events-none opacity-40"
								} rounded border px-4 py-1`}
							>
								Mensual
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default RentalsChart;

const options: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					return tooltipItems[0].label + " - Número de rentas";
				},
				label(context) {
					let label = context.dataset.label || "";
					label = label.slice(0, label.indexOf("-") - 1);
					if (context.parsed.y !== null) {
						label += ": " + context.parsed.y.toFixed(0) + " rentas";
					}
					return label;
				},
			},
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d", {locale: es}).toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
	},
};
