import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import Select from "react-select";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {seriesActions} from "../../_store/features/series/series-slice";
import {Input} from "../../components/primitives/Input";
import {Textarea} from "../../components/primitives/Textarea";
import {selectStyles} from "./SelectStyles";
import {Button} from "../../components/primitives/Button";
import {ProductRental, getSerieRentalsProductsBySeriesList} from "../../data-access/products/serie-rental-products";
import {auth} from "../../firebase";

export interface SerieIFormInput {
	productid: string;
	stripe_fee_pct: number;
	google_fee_pct: number;
	apple_fee_pct: number;
	stripe_fee_cnt: number;
	google_fee_cnt: number;
	apple_fee_cnt: number;
	stripe_price_initial?: number;
	google_price_initial?: number;
	apple_price_initial?: number;
	serie: {
		label: string;
		value: string;
	};
}

const initialState: SerieIFormInput = {
	productid: "",
	apple_fee_cnt: 0,
	apple_fee_pct: 0,
	google_fee_cnt: 0,
	google_fee_pct: 0,
	stripe_fee_cnt: 0,
	stripe_fee_pct: 0,
	stripe_price_initial: 0,
	apple_price_initial: 0,
	google_price_initial: 0,
	serie: {
		label: "",
		value: "",
	},
};

interface SelectStatusOption {
	label: string;
	value: boolean;
}

const selectStatus = [
	{label: "Activo", value: true},
	{label: "Inactivo", value: false},
];

export default function SerieRentalEdit() {
	let {id} = useParams();
	const dispatch = useAppDispatch();
	const rentalProduct = useAppSelector(state => (state.serieRentalProducts.results as ProductRental[]).find(el => el.id === id));
	const series = useAppSelector(state => state.series);
	const isLoading = useAppSelector(state => state.serieProducts.loading);
	const navigate = useNavigate();

	const [productData, setProductData] = useState(rentalProduct);
	const [selectedStatus, setSelectedStatus] = useState<SelectStatusOption | null>(null);
	const [selectedSeries, setSelectedSeries] = useState<{label: string; value: string}[]>([]);
	const [seriesLoading, setSeriesLoading] = useState(false);

	useEffect(() => {
		if (!rentalProduct) return;
		setSeriesLoading(true);
		auth.currentUser?.getIdToken().then(token => {
			if (!token) return;
			getSerieRentalsProductsBySeriesList(token, {searchby: "pr.rental_type", searchvalue: String(rentalProduct?.rental_type)})
				.then(res => {
					if (res.data.results) {
						const formattedSeries: {label: string; value: string}[] = res.data.results.map(serie => ({
							label: serie.s_title,
							value: serie.s_id,
						}));
						setSelectedSeries(formattedSeries);
					}
				})
				.finally(() => setSeriesLoading(false));
		});
	}, [rentalProduct]);

	const {
		register,
		formState: {errors},
		handleSubmit,
		control,
		reset,
		setValue,
	} = useForm<SerieIFormInput & ProductRental>({
		defaultValues: initialState,
	});

	const handleUpdateSync = () => {
		if (!rentalProduct) {
			return navigate("/products/rental-list");
		}
		setProductData(existingValues => ({
			...existingValues,
			...rentalProduct,
		}));
	};

	useEffect(() => {
		handleUpdateSync();

		if (rentalProduct) {
			setProductData(rentalProduct);
			reset(rentalProduct);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rentalProduct]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page_size: 150}}));
	}, [dispatch]);

	useEffect(() => {
		if (productData?.active !== null) {
			const activeStatus = productData?.active ? selectStatus[0] : selectStatus[1];
			setSelectedStatus(activeStatus);
		}
	}, [productData, rentalProduct, series.results, setValue]);

	const onSubmit: SubmitHandler<SerieIFormInput & ProductRental> = data => {
		// NOTE: This is the body request for the updateSerieRentalProducts action
		// this form only return to the products rentals list view by now
		// this implementation maybe can be useful for future features
		navigate("/products/rental-list");
	};

	return (
		<>
			<div className="px-5 py-4">
				<div className="flex items-center justify-between border-b border-b-border bg-background pb-5">
					<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Editar Producto de Renta</h2>
				</div>

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Nombre<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mt-1 flex w-full max-w-2xl flex-col justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
							<Input type="text" placeholder="Nombre del producto" {...register("name", {required: "Campo requerido"})} disabled />
							{errors.name && <span className="text-red-500">{errors.name.message}</span>}
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Descripción<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mt-1 flex w-full max-w-2xl justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
							<Textarea
								disabled
								rows={3}
								placeholder="Descripción del producto"
								{...register("description", {required: "Campo requerido"})}
							/>
							{errors?.description?.message && (
								<span className="text-sm font-medium text-destructive">{errors?.description?.message}</span>
							)}
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">Serie</div>
						<div className="mb-3 w-full max-w-2xl">
							<Controller
								name="serie"
								control={control}
								render={({field}) => (
									<Select
										className="mt-1 w-full rounded ring-1"
										styles={selectStyles}
										{...field}
										value={selectedSeries}
										options={selectedSeries}
										isLoading={seriesLoading}
										isDisabled
										isMulti
									/>
								)}
							/>
							{errors.serie && <span className="text-red-500">{errors.serie.message}</span>}
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Estado<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mb-3 w-full max-w-2xl">
							<Controller
								name="active"
								control={control}
								render={({field}) => (
									<Select
										className="mt-1 w-full rounded ring-1"
										styles={selectStyles}
										{...field}
										isDisabled
										options={selectStatus as any}
										value={selectedStatus}
										onChange={selectedOption => {
											setSelectedStatus(selectedOption);
											field.onChange(selectedOption);
										}}
									/>
								)}
							/>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />

					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio en Stripe (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									disabled
									type="number"
									{...register("stripe_price")}
									onChange={e => {
										// @ts-ignore
										setProductData({
											...productData,
											stripe_price: Number(e.target.value),
										});
									}}
								/>
								{errors.stripe_price && <span className="mr-2 w-full text-red-500">{errors.stripe_price.message}</span>}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />

					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Inicial Stripe (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									disabled
									type="number"
									{...register("stripe_price_initial")}
									onChange={e => {
										// @ts-ignore
										setProductData({
											...productData,
											stripe_price_initial: Number(e.target.value),
										});
									}}
								/>
								{errors.stripe_price_initial && (
									<span className="mr-2 w-full text-red-500">{errors.stripe_price_initial.message}</span>
								)}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />

					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio en Google (valor en centavos)
							<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									disabled
									type="number"
									{...register("google_price")}
									onChange={e => {
										// @ts-ignore
										setProductData({
											...productData,
											google_price: Number(e.target.value),
										});
									}}
								/>
								{errors.google_price && <span className="text-red-500">{errors.google_price.message}</span>}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />

					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Inicial Google (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									disabled
									type="number"
									{...register("google_price_initial")}
									onChange={e => {
										// @ts-ignore
										setProductData({
											...productData,
											google_price_initial: Number(e.target.value),
										});
									}}
								/>
								{errors.google_price_initial && (
									<span className="mr-2 w-full text-red-500">{errors.google_price_initial.message}</span>
								)}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />

					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio en Apple (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									disabled
									type="number"
									{...register("apple_price")}
									onChange={e => {
										// @ts-ignore
										setProductData({
											...productData,
											apple_price: Number(e.target.value),
										});
									}}
								/>
								{errors.apple_price && <span className="text-red-500">{errors.apple_price.message}</span>}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />

					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Inicial Apple (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									disabled
									type="number"
									{...register("apple_price_initial")}
									onChange={e => {
										// @ts-ignore
										setProductData({
											...productData,
											apple_price_initial: Number(e.target.value),
										});
									}}
								/>
								{errors.apple_price_initial && <span className="mr-2 w-full text-red-500">{errors.apple_price_initial.message}</span>}
							</div>
						</div>
					</div>

					<div className="mt-4 flex justify-end gap-2 pb-4">
						<Button type="submit" variant="blueBtn" disabled={isLoading}>
							{isLoading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Ir a Lista de Productos de Renta
						</Button>
					</div>
				</form>
			</div>
		</>
	);
}
