import {useState} from "react";
import {auth} from "../../../firebase";
import {createDirectUpload, cancelDirectUpload} from "../../../data-access/videos/mux-videos";
import * as UpChunk from "@mux/upchunk";
import ProgressBar from "./ProgressCircle";
import {videosActions} from "../../../_store/features/videos/videos-slice";
import {useAppDispatch} from "../../../_store/hooks";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../components/primitives/Dialog";
import {Button} from "../../../components/primitives/Button";
import {Input} from "../../../components/primitives/Input";
import Select, {CSSObjectWithLabel} from "react-select";

interface NewVideoModalProps {
	onDismiss: () => void;
	onSuccess?: () => void;
	open: boolean;
	videoType?: "content" | "advertising" | "short" | "exclusive_room";
}

interface InsertVideoType {
	signed: true;
	title: string;
	upload_url: string;
	upload_id: string;
	video_type: number;
}

const initialUploadVideoState: InsertVideoType = {
	signed: true,
	title: "",
	upload_url: "",
	upload_id: "",
	video_type: 0,
};

interface CreateUploadType {
	cors_origin: string;
	playback_policy: "signed" | "public";
}

const initialUploadState: CreateUploadType = {
	cors_origin: "pronyr.com",
	playback_policy: "signed",
};

interface PolicySelectOption {
	label: string;
	value: "signed" | "public";
}

const policySelectStatus: PolicySelectOption[] = [
	{label: "Signed", value: "signed"},
	{label: "Public", value: "public"},
];

interface VideoTypeSelectOption {
	label: string;
	value: number;
}

const videoSelectStatus: VideoTypeSelectOption[] = [
	{label: "Contenido", value: 0},
	{label: "Publicidad", value: 2},
	{label: "Short", value: 3},
	{label: "Sala Exclusiva", value: 5},
];

const selectStyles = {
	control: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		backgroundColor: "black",
		borderColor: "hsl(var(--border))",
	}),
	input: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		color: "white",
	}),
	singleValue: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		color: "white",
	}),
	menu: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		backgroundColor: "white",
		borderColor: "hsl(var(--border))",
		color: "white",
	}),
	menuList: (baseStyles: CSSObjectWithLabel) => ({
		...baseStyles,
		backgroundColor: "black",
	}),
	option: (baseStyles: CSSObjectWithLabel, {isFocused}: any) => ({
		...baseStyles,
		color: "hsl(var(--text))",
		backgroundColor: isFocused ? "grey" : "black",
	}),
};

export function NewVideoModal({open, onDismiss, onSuccess, videoType = "content"}: NewVideoModalProps) {
	const dispatch = useAppDispatch();
	const [data, setData] = useState<InsertVideoType>(initialUploadVideoState);
	const [error, setError] = useState<string>("");
	const [progress, setProgress] = useState<number>(0);
	const [isUploading, setIsUploading] = useState(false);
	const [createData, setCreateData] = useState<CreateUploadType>(initialUploadState);
	const [selectedFile, setSelectedFile] = useState<any>();
	const [policySelectedStatus, setPolicySelectedStatus] = useState<PolicySelectOption | null>(
		videoType === "content" ? policySelectStatus[0] : policySelectStatus[1],
	);
	const [videoSelectedStatus, setVideoSelectedStatus] = useState<VideoTypeSelectOption | null>(() => {
		switch (videoType) {
			case "short":
				return videoSelectStatus[2];
			case "advertising":
				return videoSelectStatus[1];
			case "exclusive_room":
				return videoSelectStatus[3];
			default:
				return videoSelectStatus[0];
		}
	});

	async function handleSubmit() {
		if (data.title !== "") {
			if (selectedFile) {
				setIsUploading(true);
				const token = await auth.currentUser?.getIdToken();
				if (!token) return;
				createData.playback_policy = policySelectedStatus?.value!; // Check this

				const videoUpload = await createDirectUpload(token, createData);
				if (!videoUpload.data.url && !videoUpload.data.id) {
					setError("Se ha producido un error al agregar el video");
					setIsUploading(false);
					return;
				}
				setData({...data, upload_url: videoUpload.data.url, upload_id: videoUpload.data.id});

				const upload = UpChunk.createUpload({
					endpoint: videoUpload.data.url,
					file: selectedFile[0],
					chunkSize: 5120, // Uploads the file in ~5mb chunks
				});

				// subscribe to events
				upload.on("error", err => {
					//manage error
					setError("Se ha producido un error al subir el video");
					setIsUploading(false);
					console.error("💥 🙀", err.detail);
				});

				upload.on("progress", progress => {
					setProgress(progress.detail);
				});

				// subscribe to events
				upload.on("success", err => {
					setData(initialUploadVideoState);
					setIsUploading(false);
					handleSuccess(videoUpload.data.id);
					setCreateData(initialUploadState);
				});
			} else {
				setError("Seleccione un archivo mp4 para continuar");
			}
		} else {
			setError("Complete el Título del video");
		}
	}

	async function handleSuccess(videoId: string) {
		if (!auth.currentUser) return;
		dispatch(
			videosActions.editVideoData({
				GCPUser: auth.currentUser,
				params: {title: data.title, uploadid: videoId, video_type: videoSelectedStatus?.value},
			}),
		);
		onSuccess?.();
	}

	async function handleDismiss() {
		if (data.upload_id !== "") {
			setError("Cancelled");
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;
			await cancelDirectUpload(token, {upload_id: data.upload_id});
		}
		setProgress(0);
		setCreateData(initialUploadState);
		setData(initialUploadVideoState);
		onDismiss?.();
	}

	return (
		<Dialog open={open} onOpenChange={handleDismiss}>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="font-medium text-white">Nuevo Video</DialogTitle>
				</DialogHeader>
				<div className="container px-0.5 pr-4">
					{progress !== 0 ? (
						<div className="flex">
							<ProgressBar width={180} progress={progress} />
						</div>
					) : (
						<>
							<div className="flex">
								<div className="w-1/2">
									<div className="mb-3 flex w-full">
										<div className="flex w-full">
											<label className="my-4 text-white" htmlFor="exampleFormControlInput1">
												*Título:
											</label>
											<Input
												className="form-control m-2 w-80 rounded bg-black px-3 py-2 text-white"
												type="text"
												name="title"
												placeholder="Título del video"
												value={data.title}
												onChange={e => {
													setData(prev => ({...prev, [e.target.name]: e.target.value}));
													setError("");
												}}
											/>
										</div>
									</div>
								</div>
								<div className="mb-3 ml-3 flex w-1/2 flex-row">
									<div className="flex w-2/5">
										<label className="my-4 text-white" htmlFor="exampleFormControlInput1">
											*Tipo:
										</label>
										<Select
											className="ml-2 mt-2 w-full"
											styles={selectStyles}
											options={policySelectStatus as any}
											onChange={selectedOption => {
												setPolicySelectedStatus(selectedOption);
												setError("");
											}}
											value={policySelectedStatus}
										/>
									</div>
									<div className="flex w-3/5">
										<label className="my-4 ml-2 w-full text-white" htmlFor="exampleFormControlInput1">
											*Tipo Video:
										</label>
										<Select
											className="mt-2 w-full"
											styles={selectStyles}
											options={videoSelectStatus as any}
											onChange={selectedOption => {
												setVideoSelectedStatus(selectedOption);
												setError("");
											}}
											value={videoSelectedStatus}
										/>
									</div>
								</div>
							</div>
							<div className="mb-6 w-full">
								<p className="mb-2 text-white">*Seleccione el Archivo:</p>
								<Input
									type="file"
									accept=".mp4,.mov"
									onChange={e => setSelectedFile(e.currentTarget.files)}
									className="form-control w-full rounded bg-black py-1 text-white file:mt-1 file:bg-white"
								/>
							</div>
						</>
					)}
					<div className=" float-right flex w-full flex-1 justify-end">
						{error === "Cancelled" ? (
							<>
								<button
									className="col-start-2 mx-4 flex items-center justify-center rounded bg-neutral-50 px-4 py-2 text-black transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
									type="reset"
									onClick={() => {
										setData(initialUploadVideoState);
										setProgress(0);
										setCreateData(initialUploadState);
										setError("");
									}}
								>
									Reiniciar
								</button>
							</>
						) : (
							<>
								<p className="mr-20 text-red-500">{error}</p>
							</>
						)}
						{progress === 0 ? (
							<>
								<Button className="mr-2 w-32 text-white" size="sm" variant="outline" type="reset" onClick={handleDismiss}>
									Cancelar
								</Button>
								<Button
									className="w-32 bg-black text-white"
									size="sm"
									variant="outline"
									type="submit"
									onClick={handleSubmit}
									disabled={isUploading}
								>
									Guardar
								</Button>
							</>
						) : progress === 100 && data.upload_id === "" ? (
							<>
								<p className="mr-2 self-center text-white">Completada!</p>

								<Button className="w-32 bg-black text-white" size="sm" variant="outline" type="submit" onClick={handleDismiss}>
									Volver
								</Button>
							</>
						) : (
							<p className="text-white">Progreso: {Math.round(progress * 100) / 100}</p>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
