import {SVGProps} from "react";

interface IconProps extends SVGProps<SVGSVGElement> {}
export const UserFilled = (props: IconProps) => (
	<svg width={800} height={800} viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#a)">
			<path
				d="M520.886 556h-240.5a226 226 0 0 0-225 225 116.999 116.999 0 0 0 0 19h689c.515-6.323.515-12.677 0-19a225.502 225.502 0 0 0-223.5-225Zm-115.5-62a197.008 197.008 0 0 0 109.448-33.2 197.01 197.01 0 0 0 72.557-88.411A197.007 197.007 0 0 0 544.686 157.7a196.998 196.998 0 0 0-303.099 29.853A196.995 196.995 0 0 0 266.086 436.3a197 197 0 0 0 139.3 57.7Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);
