import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {auth} from "../../../firebase";
import {ListResponse} from "../../../../utils/globalTypes";
import {dbUser} from "../user-db/user-db-slice";

const apiBaseUrl = process.env.REACT_APP_APP_DOMAIN;

type VendorParams = {
	page: number;
	page_size?: number;
	admin: boolean;
	searchby?: "id" | "name" | "email" | "phone" | "provider" | "referral_code" | "device_id";
	searchvalue?: string;
	role: "vendor";
	active?: boolean;
};

export const vendorApi = createApi({
	reducerPath: "vendorApi",
	baseQuery: fetchBaseQuery({
		baseUrl: apiBaseUrl,
		async prepareHeaders(headers) {
			const token = await auth.currentUser?.getIdToken();
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			headers.set("Content-Type", "application/json");
			headers.set("Accept", "*/*");
			return headers;
		},
	}),
	endpoints: builder => ({
		getVendorList: builder.query<ListResponse<dbUser>, VendorParams>({
			query: params => ({
				url: "/list_db_users",
				params,
			}),
			transformResponse: (response: any) => {
				return response;
			},
		}),
	}),
});

export const {useGetVendorListQuery, useLazyGetVendorListQuery} = vendorApi;
