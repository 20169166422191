import {createAsyncThunk} from "@reduxjs/toolkit";
import {GetContentCoinsParams, GetContentViewsParams, getContentCoins, getContentViews} from "../../../data-access/series/content";
import {auth} from "../../../firebase";

export const getSeriesContentCoins = createAsyncThunk("content/coins", async (params: GetContentCoinsParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");
		const response = await getContentCoins(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching content coins");
	}
});

export const getSeriesContentViews = createAsyncThunk("content/views", async (params: GetContentViewsParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");
		const response = await getContentViews(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching content views");
	}
});
