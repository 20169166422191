import {useState, useEffect} from "react";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../components/primitives/Dialog";
import {useAppDispatch} from "../../../_store/hooks";
import {Button} from "../../../components/primitives/Button";
import {Input} from "../../../components/primitives/Input";
import {TagsResult} from "../../../data-access/shorts/tags";
import {tagsActions} from "../../../_store/features/shorts/tags-slice";

interface EditTagModalProps {
	onDismiss: () => void;
	onSuccess?: () => void;
	open: boolean;
	data: TagsResult;
}

export function EditTagModal({open, onDismiss, data, onSuccess}: EditTagModalProps) {
	const [tag, setTag] = useState(data.tag);
	const [id, setId] = useState(data.id);

	const dispatch = useAppDispatch();

	async function handleSubmit() {
		await dispatch(
			tagsActions.updateTags({
				id,
				tag,
			}),
		);
		handleSuccess();
	}

	useEffect(() => {
		setId(data.id);
		setTag(data.tag);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.id]);

	function handleSuccess() {
		onSuccess?.();
	}

	function resetStates() {
		setTag("");
		setId("");
	}

	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				onDismiss?.();
				resetStates();
			}}
		>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="font-medium text-white">Editar Tag</DialogTitle>
				</DialogHeader>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-3 flex w-full">
							<div className="flex w-full">
								<label className="my-4 text-white" htmlFor="exampleFormControlInput1">
									*Tag:
								</label>
								<Input
									className="form-control m-2 w-80 rounded bg-black px-3 py-2 text-white"
									type="text"
									name="title"
									placeholder="Texto del Tag"
									value={tag}
									onChange={e => setTag(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>

				<p className="text-white">
					<b>Tag ID:</b> {data.id}
				</p>

				<div className="flex flex-row justify-end">
					<Button
						className="mr-2 w-32 text-white"
						size="sm"
						variant="outline"
						onClick={() => {
							onDismiss?.();
						}}
					>
						Cancelar
					</Button>
					<Button className="w-32 bg-black text-white" size="sm" variant="outline" onClick={handleSubmit}>
						Guardar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
