import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface SerieProduct {
	id: string;
	name: string;
	description: string;
	serieid: string;
	membership_type: number;
	stripe_price: number;
	stripe_productid: string | null;
	stripe_priceid: string | null;
	google_price: number;
	google_id: string | null;
	apple_price: number;
	apple_id: string | null;
	active: boolean;
	author: string | null;
	stripe_price_initial: number;
	google_price_initial: number;
	apple_price_initial: number;
	serie_title: string | null;
	coins_price: number;
	coins_price_initial: number;
}

/*INSERT*/
export interface InsertSerieProductBody {
	name: string;
	description: string;
	serieid: string;
	membership_type: number;
	stripe_price: number;
	stripe_price_initial: number;
	coins_price: number;
	coins_price_initial: number;
	google_price: number;
	google_price_initial: number;
	apple_price: number;
	apple_price_initial: number;
	active: boolean;
}

export async function insertSerieProductData(token: string, data: InsertSerieProductBody): Promise<AxiosResponse<SerieProduct>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/create_database_product_series", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*UPDATE*/
export interface EditSerieProductBody {
	productid: string;
	name: string;
	description: string;
	serieid: string;
	membership_type: number;
	stripe_price: number;
	stripe_price_initial: number;
	google_price: number;
	google_price_initial: number;
	apple_price: number;
	apple_price_initial: number;
	active: boolean;
}

export async function editSerieProductData(token: string, data: EditSerieProductBody): Promise<AxiosResponse<SerieProduct>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/update_database_product_series", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*LIST*/
export interface GetSerieProductListParams {
	page: number;
	page_size: number;
	searchby?: string;
	searchvalue?: string;
	active?: boolean;
}
interface GetSerieProductListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: SerieProduct[];
}
export async function getSerieProductsList(token: string, params?: GetSerieProductListParams): Promise<AxiosResponse<GetSerieProductListResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/admin_list_products_series", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface UpdateStripeProductParams {
	stripe_productid: string;
	stripe_priceid: string;
	serieid: string;
	name: string;
	description: string;
	images?: string[];
	productprice: number;
}

export interface UpdateStripeResponse {
	result: string;
}

export async function updateStripeProductSerie(token: string, data: UpdateStripeProductParams): Promise<AxiosResponse<UpdateStripeResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/update_stripe_product_series", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
