import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import useCurrentUserRole from "../../../components/hooks/useCurrentUserRole";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {Avatar} from "@radix-ui/react-avatar";
import {AvatarFallback, AvatarImage} from "../../../components/primitives/Avatar";
import {Pencil, Refresh} from "../../../components/primitives/icons";
import {Button} from "../../../components/primitives/Button";
import {Input} from "../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../components/primitives/Select";
import {DataTable} from "../../../components/blocks/DataTable";
import {Subjects} from "../../../data-access/fetch-form-data";
import {formDbActions} from "../../../_store/features/forms/customer-service-slice";

interface HandleSearchProps {
	status?: "open" | "customer_reply" | "pending" | "closed";
	type?: 0 | 1 | 2;
}

const searchOptions = [
	{display: "ID", searchby: "id"},
	{display: "Por ID de Autor", searchby: "author_id"},
];

const statusOptions = [
	{display: "Todos", value: undefined},
	{display: "Iniciado", value: "open"},
	{display: "Mensaje de usuario", value: "customer_reply"},
	{display: "Mensaje de soporte", value: "pending"},
	{display: "Cerrado", value: "closed"},
];

type SearchByType = "id" | "author_id" | "content" | "subject_id";

const columnHelper = createColumnHelper<Subjects>();

export default function UserChat() {
	const {activeRole: userRole} = useCurrentUserRole();
	const dbChatSubjects = useAppSelector(({formData}) => formData);
	const dispatch = useAppDispatch();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
	const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const isAuthorized = useMemo(() => {
		switch (userRole) {
			case "admin":
			case "master_editor":
			case "corporate":
			case "customer_service":
				return true;
			default:
				return false;
		}
	}, [userRole]);

	const handleSearch = ({status, type}: HandleSearchProps) => {
		if (pagination.pageSize === 0) return;
		dispatch(
			formDbActions.getAllChatSubjectsAction({
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				searchvalue: searchValue,
				searchby: searchValue && searchValue !== "" ? (selectedSearchBy.searchby as SearchByType) : undefined,
				status: status,
				type: type,
			}),
		);
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		handleSearch({});

		return () => {
			dispatch(formDbActions.resetState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const AVATAR_COL_WIDTH = 60;

		if (tableWidth > 0) {
			tableWidth = tableWidth - AVATAR_COL_WIDTH;
		}

		return [
			columnHelper.accessor("avatar", {
				id: "Avatar",
				header: ({column}) => <DataTableColumnHeader title="Avatar" column={column} />,
				cell: info => {
					return (
						<Avatar>
							<AvatarImage className="rounded-full" src={info.row.original.avatar} alt="User profile" />
							<AvatarFallback>{info.row.original?.email?.slice(0, 2)}</AvatarFallback>
						</Avatar>
					);
				},
				size: AVATAR_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("author", {
				id: "Nombre  / UUID",
				header: "Nombre / UUID",
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.author}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.22),
			}),
			columnHelper.accessor("email", {
				id: "Email",
				header: "Email",
				cell: info => <div className="ml-2 w-full text-left">{info.getValue()}</div>,
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("phone", {
				id: "No. Teléfono",
				header: "No. Teléfono",
				cell: ({row: {original}}) => <div className="ml-2 w-full text-left">{original.phone || "-"}</div>,
				size: Math.floor(tableWidth * 0.13),
			}),
			columnHelper.accessor("content", {
				id: "Último comentario",
				header: "Último comentario",
				cell: ({row: {original}}) => (
					<div className="flex w-full items-start">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{original.content}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.23),
			}),
			columnHelper.accessor("status", {
				id: "Estado",
				header: "Estado",
				cell: ({row: {original}}) => {
					const status: () => string = () => {
						switch (original.status) {
							case "closed":
								return "Cerrado";
							case "customer_reply":
								return "Mensaje de usuario";
							case "open":
								return "Iniciado";
							case "pending":
								return "Mensaje de soporte";
							default:
								return "Sin definir";
						}
					};
					return <span className="inline-block w-full px-3 py-1  text-left font-semibold leading-tight">{status()}</span>;
				},
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild disabled={!isAuthorized}>
							<Link to={isAuthorized ? `/users/edit/${info.row.original.author_id}?tab=5` : ""}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.08),
				enableResizing: false,
			}),
		];
	}, [tableRef, isAuthorized]);

	const refresh = () => {
		setSelectedStatus(statusOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(
			formDbActions.getAllChatSubjectsAction({
				page: 0,
				page_size: pagination.pageSize,
				searchvalue: undefined,
				searchby: undefined,
				status: undefined,
				type: undefined,
			}),
		);
	};

	const handleStatusFilter = (value: string) => {
		const status = statusOptions.find(el => el.display === value) ?? statusOptions[0];
		setSelectedStatus(status);
		handleSearch({status: status.value as "open" | "customer_reply" | "pending" | "closed"});
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.searchby === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Lista de Chats por Usuario</h2>
				<div className="mx-4 flex flex-row items-start">
					<span className="text-ellipsis text-center text-2xl font-bold">Total: </span>
					<span className="ml-2 text-ellipsis text-center text-2xl font-bold">{dbChatSubjects.totalResults}</span>
				</div>
			</div>
			<div className="flex gap-8 px-6 pt-4 xl:gap-24">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.searchby}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.searchby}`}>
											{sOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={() => handleSearch({})}>
							Buscar
						</Button>
					</div>
					<Select onValueChange={handleStatusFilter} value={selectedStatus.display}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Estado:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{statusOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.display}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={dbChatSubjects.results as Subjects[]}
					pageCount={Math.ceil(dbChatSubjects.totalResults / dbChatSubjects.pageSize)}
					pagination={pagination}
					loading={dbChatSubjects.loading}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={57}
					showPagination={false}
				/>
			</div>
		</div>
	);
}
