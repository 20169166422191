import axios, {AxiosResponse} from "axios";
import {GetPartnerGeneralIncomeAndProfitResponse, getPartnerGeneralIncomeAndProfitParams} from "./types";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export async function getPartnerGeneralMuxIncomeAndProfit_old(
	token: string,
	params?: getPartnerGeneralIncomeAndProfitParams,
): Promise<AxiosResponse<GetPartnerGeneralIncomeAndProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_partner_mux_view_percents", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getPartnerGeneralMuxIncomeAndProfit(
	token: string,
	params?: getPartnerGeneralIncomeAndProfitParams,
): Promise<AxiosResponse<GetPartnerGeneralIncomeAndProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_partner_mux_view_rentals_percents", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
