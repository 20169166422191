import {useState, useEffect, FormEvent} from "react";
import {validateEmail} from "../../../utils/validators";
import {Close, User} from "../../components/primitives/icons";
import {createAdminPasswordRequestData} from "../../_store/features/user-db/user-db-actions";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {ReCaptchaComponent} from "../../components/primitives/ReCaptcha";
import {captchaVisibility} from "../../../utils/captchaVisibility";
import {Link} from "react-router-dom";
interface FormFields {
	email: HTMLInputElement;
}
const RECAPTCHA_ACTION = "PasswordForm";

function RecoveryPassword() {
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const reduxDbUsers = useAppSelector(state => state.dbuser);
	const [recaptchaToken, setRecaptchaToken] = useState<string>();

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		const {email} = e.currentTarget.elements as unknown as FormFields;

		if (email.value === "") {
			return setError("Email cannot be empty");
		}

		if (!validateEmail(email.value)) {
			return setError("Email is Invalid");
		}

		recaptchaToken && dispatch(createAdminPasswordRequestData(undefined, email.value, recaptchaToken, RECAPTCHA_ACTION, true));
		e.currentTarget.reset();
	};

	useEffect(() => {
		if (reduxDbUsers.sendingPasswordEmail === false && loading === true) {
			if (reduxDbUsers.success === true) {
				setError("");
				setSuccess(true);
			} else {
				setError("Error en la solicitud, intente más tarde");
				setLoading(false);
			}
			setLoading(false);
		}
	}, [reduxDbUsers, loading]);

	useEffect(() => {
		return () => {
			captchaVisibility(false);
		};
	}, []);

	return (
		<div className="flex h-screen w-screen items-center justify-center bg-neutral-200">
			<div className="grid grid-cols-2 overflow-hidden rounded-md bg-white">
				<div className="flex flex-col p-10">
					<form className="mt-10 flex h-full flex-col" onSubmit={handleSubmit}>
						<p className="mb-4">
							Inserte su dirección de correo electrónico y se le <br></br> enviará un email con las instrucciones.
						</p>
						{error !== "" && (
							<div className="flex w-full flex-grow-0 items-center justify-between rounded border border-red-400 bg-red-200 px-4 py-2 text-sm">
								<span>{error}</span>
								<button onClick={() => setError("")}>
									<Close className="h-4 w-4" />
								</button>
							</div>
						)}
						{!success ? (
							<>
								<div className="mb-4 flex items-center overflow-hidden rounded-[4px] border border-solid border-neutral-400 focus-within:outline">
									<div className="flex aspect-square h-10 w-10 flex-shrink-0 items-center justify-center border-r border-neutral-400 bg-neutral-300">
										<User className="h-4 w-4" />
									</div>
									<input
										className="form-control w-full px-3 py-2 focus:outline-none"
										type="email"
										name="email"
										placeholder="Correo electrónico"
										required
									/>
								</div>
								<div className="mt-auto grid grid-cols-2 place-items-center">
									<div className="place-self-start">
										<button
											className="rounded border border-indigo-800 bg-indigo-600 px-6 py-2 text-white transition-colors duration-300 hover:bg-indigo-400"
											type="submit"
										>
											{reduxDbUsers.editing === true ? "Loading" : "Enviar correo"}
										</button>
									</div>
									<div>
										<Link to="/sign-in" className="underline" type="button">
											Regresar al login
										</Link>
									</div>
								</div>
							</>
						) : (
							<>
								<p>El mensaje ha sido enviado correctamente</p>
								<div className="mt-auto grid grid-cols-2 place-items-center">
									<div className="place-self-start">
										<Link
											to="/sign-in"
											className="rounded border border-indigo-800 bg-indigo-600 px-6 py-2 text-white transition-colors duration-300 hover:bg-indigo-400"
										>
											Regresar al login
										</Link>
									</div>
								</div>
							</>
						)}
					</form>
				</div>
				<div className="flex flex-col items-center justify-center bg-neutral-900">
					<img src="/logo-big.png" alt="PRONYR Logo" className="mb-4 mt-24 w-32" />
					<p className="mb-24 text-white">v2.1.0</p>
				</div>
				<ReCaptchaComponent setToken={setRecaptchaToken} action={RECAPTCHA_ACTION} />
			</div>
		</div>
	);
}

export default RecoveryPassword;
