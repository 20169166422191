import Select, {SingleValue} from "react-select";
import {selectStyles} from "../../../../utils/SelectStyles";
import {DatePickerWithRange} from "../../../components/blocks/DateRangePicker";
import {endOfToday, formatISO9075, roundToNearestMinutes, subMonths} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {DateRange} from "react-day-picker";
import {Card} from "../../../components/blocks/Stats/Card";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {SelectOptionType} from "../../../../utils/globalTypes";
import {DollarSign, Donations, GiftBox} from "../../../components/primitives/icons";
import {getSeriesContentCoins} from "../../../_store/features/content-ad/content-actions-slice";
import {GetContentCoinsResponse} from "../../../data-access/series/content";
import ViewsChart from "./_subcomponents/ViewsChart";
import ContentRankingResume from "../../dashboard/role-based/corporate/subcomponents/ContentRankingResume";
import {LikesAndFavorites} from "../../../components/blocks/Stats";
import {serieContentsActions} from "../../../_store/features/serie-content/serie-content-slice";
import CoinsCollectedChart from "./_subcomponents/CoinsCollectedChart";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {CONTENT} from "../../../constants";

interface ContentMeta {
	serieName: string;
	contentName: string;
}

export default function ContentDetail() {
	const {
		id,
		name: contentName,
		serie: serieName,
		serie_id,
	} = useParams<{
		id: string;
		name: string;
		serie: string;
		serie_id: string;
	}>();
	const dispatch = useAppDispatch();
	const {results: seriesContent, loading: seriesContentLoading} = useAppSelector(state => state.serieContent);
	const {results} = useAppSelector(state => state.contentsRanking);
	const [contentId, setContentId] = useState<string | undefined>(id);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [contentMeta, setContentMeta] = useState<ContentMeta>({
		serieName: serieName || "",
		contentName: contentName || "",
	});

	const [contentData, setContentData] = useState<GetContentCoinsResponse | undefined>();

	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subMonths(to, 1);
		return {from, to};
	});
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; value: number}[]>>(null);

	const getContentCoins = () => {
		setIsLoading(true);
		dispatch(getSeriesContentCoins({contentid: contentId, initial_date: formatISO9075(dates?.from!), final_date: formatISO9075(dates?.to!)}))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled" || !res.payload) return;
				setContentData(res.payload as GetContentCoinsResponse);
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		getContentCoins();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, contentId, dates]);
	useEffect(() => {
		dispatch(serieContentsActions.listSeriesContent({searchby: "serieid", searchvalue: serie_id || "", page_size: 9999}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serie_id, contentId, id]);

	const seriesContentOptions: SelectOptionType[] = useMemo(() => {
		return seriesContent
			.filter(c => c.content_type !== CONTENT.EXCLUSIVE_ROOM)
			.map(serie => ({label: serie.title, value: serie.id}))
			.reverse();
	}, [seriesContent]);

	function handleSelectContent(newValue: SingleValue<SelectOptionType>) {
		if (!newValue) return;
		if (newValue.value !== "") {
			const serieName =
				results.find(i => i.id === newValue.value)?.series_name! || seriesContent.find(i => i.id === newValue.value)?.serie_title!;
			setContentId(newValue.value);
			setContentMeta({contentName: newValue.label, serieName});
			return;
		}
		setContentId(undefined);
	}

	const handleDateRangeChange = (s: number, e: number) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">
					{contentMeta.serieName} - {contentMeta.contentName}
				</h2>
				<Select
					className="basic-select mx-4 my-7 w-60 text-sm"
					styles={selectStyles}
					isSearchable={true}
					options={seriesContentOptions}
					value={{
						label: contentMeta.contentName,
						value: contentId as string,
					}}
					classNamePrefix="select"
					onChange={handleSelectContent}
					placeholder="Selecciona un contenido"
					isLoading={seriesContentLoading}
					isDisabled={seriesContentLoading}
				/>
			</div>
			<DatePickerWithRange
				onDateRangeChange={handleDateRangeChange}
				date={dates}
				className="left-0 mt-5 w-80"
				disabled={{before: new Date('2023-10-01T00:00:00-05:00'), after: new Date()}}
			/>
			<div className="flex flex-col gap-6 px-8 py-6">
				<div className="col-span-7 grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-3">
					<div className="relative">
						<Card
							title="Total de coins recaudados"
							amount={contentData ? contentData?.donations + contentData?.stickers : 0}
							isMoney={false}
							loading={isLoading}
						/>
						<DollarSign className="absolute right-3 top-3 opacity-50" width={28} height={28} />
					</div>
					<div className="relative">
						<Card title="Coins por donaciones" amount={contentData ? contentData?.donations : 0} isMoney={false} loading={isLoading} />
						<Donations className="absolute right-3 top-3 opacity-50" width={28} height={28} />
					</div>
					<div className="relative">
						<Card title="Coins por stickers" amount={contentData ? contentData?.stickers : 0} isMoney={false} loading={isLoading} />
						<GiftBox className="absolute right-3 top-3 opacity-50" width={28} height={28} />
					</div>
				</div>
				<ViewsChart contentid={contentId} />
				<CoinsCollectedChart
					dates={dates}
					chartRef={chartRef}
					statistics={{
						daily_donations: contentData?.daily_donations || [],
						daily_stickers: contentData?.daily_stickers || [],
						donations: contentData?.donations || 0,
						stickers: contentData?.stickers || 0,
					}}
					loading={isLoading}
				/>
				<LikesAndFavorites contentId={contentId} dates={dates} isCorporate showTotalCard />
				<ContentRankingResume dates={dates} serieId={serie_id} fullWidth onSelectItem={handleSelectContent} />
			</div>
		</div>
	);
}
