import {Button} from "../../../components/primitives/Button";
import {Sheet, SheetClose, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../components/primitives/Sheet";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {useEffect, useMemo, useState} from "react";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {Pencil, Refresh, Spinner, Trash} from "../../../components/primitives/icons";
import {DataTable} from "../../../components/blocks/DataTable";
import {Input} from "../../../components/primitives/Input";
import {TagsResult} from "../../../data-access/shorts/tags";
import {tagsActions} from "../../../_store/features/shorts/tags-slice";
import {toast} from "react-hot-toast";
import {EditTagModal} from "../subcomponents/EditTagModal";

const columnHelper = createColumnHelper<TagsResult>();

const tagInitialState = {
	id: "",
	tag: "",
};

export default function TagsSheet(props: SheetProps) {
	const {...rest} = props;
	const dispatch = useAppDispatch();
	const tags = useAppSelector(state => state.tags);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [newTag, setNewTag] = useState("");
	const [loadingNewTag, setLoadingNewTag] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [selectedTag, setSelectedTag] = useState<TagsResult>(tagInitialState);

	const handleSearch = () => {
		dispatch(
			tagsActions.listTags({
				params: {page_size: pagination.pageSize, page: pagination.pageIndex, searchvalue: searchValue, searchby: "tag"},
			}),
		);
	};
	useEffect(() => {
		if (!pagination.pageSize) return;
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	useEffect(() => {
		resetStates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rest.onOpenChange]);

	const refresh = () => {
		resetStates();
		dispatch(
			tagsActions.listTags({
				params: {page_size: pagination.pageSize, page: pagination.pageIndex},
			}),
		);
	};

	const handleNewTag = async () => {
		setLoadingNewTag(true);
		await dispatch(tagsActions.createTags({tag: newTag}));
		toast.success("Tag creado correctamente");
		setLoadingNewTag(false);
		refresh();
	};

	const handleDeleteTag = async (id: string) => {
		await dispatch(tagsActions.deleteTag(id)).then(response => {
			if (typeof response.payload === "object") {
				toast.success("Tag eliminado correctamente");
			} else {
				toast.error("No se puede eliminar un tag en uso");
			}
		});

		refresh();
	};

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("tag", {
				id: "Tag / Id",
				header: ({column}) => <DataTableColumnHeader title="Tag / Id" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">{info.row.original.tag}</span>
						<span className="overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.65),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full justify-start gap-3">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setIsEdit(true);
								setSelectedTag(info.row.original);
							}}
						>
							<Pencil className="h-4 w-4" />
						</Button>
						<Button size={"sm"} variant={"outline"} onClick={() => handleDeleteTag(info.row.original.id)}>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.35),
				enableResizing: false,
			}),
		];

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableRef]);

	const resetStates = () => {
		setSearchValue("");
		setNewTag("");
		setSelectedTag(tagInitialState);
	};

	return (
		<>
			<Sheet {...rest}>
				<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
					<SheetHeader>
						<SheetTitle>Tags de los shorts</SheetTitle>
						<SheetDescription>Agrega, edita o elimina los tags de los shorts</SheetDescription>
					</SheetHeader>
					<div className="flex w-full gap-2">
						<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
							<Refresh className="h-4 w-4" />
						</Button>
						<div className="flex w-full">
							<Input
								className="h-8 rounded-r-none"
								type="text"
								onChange={e => setSearchValue(e.target.value)}
								value={searchValue}
								placeholder="Buscar..."
							/>
							<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
								Buscar
							</Button>
						</div>
					</div>
					<DataTable
						ref={ref => setTableRef(ref)}
						columns={columns}
						dataset={tags.results}
						pageCount={Math.ceil(tags.totalResults / pagination.pageSize)}
						pagination={pagination}
						loading={tags.loading}
						onPaginationChange={setPagination}
						rowHeight={57}
						withDynamicPageSize
						showPagination={false}
					/>
					<div className="flex flex-row justify-around ">
						<Input
							type="text"
							placeholder="Inserta un tag nuevo..."
							onChange={tag => {
								setNewTag(tag.target.value);
							}}
							value={newTag}
						/>
						<Button
							className="ml-2"
							onClick={() => {
								handleNewTag();
								resetStates();
							}}
						>
							{loadingNewTag && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Guardar
						</Button>
						<SheetClose asChild>
							<Button className="ml-2" variant={"secondary"}>
								Salir
							</Button>
						</SheetClose>
					</div>
				</SheetContent>
			</Sheet>
			<EditTagModal
				onDismiss={() => {
					setIsEdit(false);
					setSelectedTag(tagInitialState);
				}}
				onSuccess={() => {
					setIsEdit(false);
					toast.success("Tag editado correctamente");
					refresh();
				}}
				open={isEdit}
				data={selectedTag}
			/>
		</>
	);
}
