import {useState} from "react";
import {Button} from "../../primitives/Button";
import {DownloadList, Spinner} from "../../primitives/icons";
import axios from "axios";
import {auth} from "../../../firebase";
import toast from "react-hot-toast";
import {downloadFile} from "../../../../utils/downloadXLSX";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

interface DownloadButtonProps {
	text?: string;
	link: string;
}

function DownloadButton({text = "Descargar Excel", link}: DownloadButtonProps) {
	const [loading, setLoading] = useState(false);

	const handleClick = async () => {
		if (loading) return;
		setLoading(true);

		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) throw new Error("Token not found");

			const data = await axiosHandler
				.get(link, {
					responseType: "blob",
					headers: {
						Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
						"Accept-Encoding": "gzip, deflate, br, zstd",
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data);
			downloadFile(data, "user-retention.xlsx");
		} catch (e) {
			console.error(e);
			toast.error("Error al descargar el archivo");
		} finally {
			setLoading(false);
		}
	};

	return (
		<Button variant="outline" size="sm" className="h-8 whitespace-nowrap font-normal lg:flex" onClick={handleClick}>
			<DownloadList className="mr-2 h-4 w-4" />
			{!loading ? (
				text
			) : (
				<>
					<p className="relative text-transparent">{text}</p>
					<Spinner className="absolute mr-2 h-4 w-4 animate-spin" />
				</>
			)}
		</Button>
	);
}

export default DownloadButton;
