import {RootState} from "../../../store";
import {useAppSelector} from "../../../hooks";
import {DashboardPartnerQueries, DataByName} from "../dashboard-partner-slice";

interface useDashboardPartnerByNameQueryResponse<T extends DashboardPartnerQueries> {
	data?: DataByName[T];
	isUninitialized: boolean;
	isLoading: boolean;
	isError: boolean;
	isSuccess: boolean;
}

export function useDashboardPartnerByNameQuery<T extends DashboardPartnerQueries>(name: T): useDashboardPartnerByNameQueryResponse<T> {
	const status = useAppSelector((state: RootState) => state.dashboardPartner.statusByName[name]);
	const data = useAppSelector((state: RootState) => state.dashboardPartner.dataByName[name]);

	const isUninitialized = status === undefined;
	const isLoading = status === "pending" || status === undefined;
	const isError = status === "rejected";
	const isSuccess = status === "fulfilled";

	return {data, isUninitialized, isLoading, isError, isSuccess};
}
