import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {useState, useEffect, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {DataTable} from "../../../components/blocks/DataTable";
import {cn} from "../../../../utils/classNames";
import {Button} from "../../../components/primitives/Button";
import {Link} from "react-router-dom";
import {ListPlus, Pencil, Refresh, Trash} from "../../../components/primitives/icons";
import {Input} from "../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../components/primitives/Select";
import ActionConfirmModal from "../../../components/blocks/ActionConfirmModal";
import {auth} from "../../../firebase";
import {crewActions} from "../../../_store/features/crew/crew-slice";
import {Cast, deleteCastMember, SeriesInvolved} from "../../../data-access/series/cast";
import {toast} from "react-hot-toast";

const columnHelper = createColumnHelper<Cast>();

const searchOptions = [
	{name: "Nombre", value: "name"},
	{name: "Description", value: "description"},
];

export function CastList() {
	const dispatch = useAppDispatch();
	const crew = useAppSelector(state => state.crew);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [deleteCastID, setDeleteCastID] = useState("");
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const handleSearch = () => {
		const params: Record<string, any> = {};

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		dispatch(crewActions.getCastsList({params: {page: page.pageIndex, page_size: page.pageSize, ...params}}));
	};

	const refresh = () => {
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(crewActions.getCastsList({params: {page: page.pageIndex, page_size: page.pageSize}}));
	};

	useEffect(() => {
		if (!page.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const LOGO_COL_WIDTH = 64;
		const CONTROL_COL_WIDTH = 150;

		if (tableWidth > 0) {
			tableWidth = tableWidth - LOGO_COL_WIDTH - CONTROL_COL_WIDTH;
		}

		return [
			columnHelper.accessor("images", {
				id: "Imagen",
				header: ({column}) => <DataTableColumnHeader title="Imagen" column={column} />,
				cell: info => (
					<img
						alt="Banner"
						src={info.getValue()?.[0]}
						className={cn(
							"relative mx-auto aspect-square w-full object-contain text-center",
							"before:absolute before:flex before:h-full before:w-full before:items-center before:justify-center before:border before:border-border before:bg-background before:text-xs before:font-medium before:content-['404']",
						)}
					/>
				),
				size: LOGO_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("name", {
				id: "Nombre",
				header: ({column}) => <DataTableColumnHeader title="Nombre / Id" column={column} />,
				cell: info => (
					<div className="ml-2 flex flex-col text-left">
						<span className="truncate font-medium">{info.row.original.name}</span>
						<span className="truncate text-muted-foreground">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.25),
			}),
			columnHelper.accessor("descript", {
				id: "Descripción",
				header: ({column}) => <DataTableColumnHeader title="Descripción" column={column} />,
				cell: info => (
					<div className="ml-2 flex w-full flex-col text-left">
						<span className="truncate">{info.row.original.descript}</span>
					</div>
				),

				size: Math.floor(tableWidth * 0.45),
			}),
			columnHelper.accessor("series_involved", {
				id: "Series",
				header: ({column}) => <DataTableColumnHeader title="Series" column={column} />,
				cell: info => (
					<div className="ml-2 flex w-full flex-col text-left">
						{!info.row.original.series_involved ? (
							<span>Ninguna serie vinculada</span>
						) : (
							info.row.original.series_involved
								.slice(-3)
								.map((series: SeriesInvolved, index: number) => <span key={index}>{series.series_name}</span>)
						)}
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full justify-center gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/crew/cast/edit/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setDeleteCastID(info.row.original.id);
								setDeleteConfirmationModalOpen(true);
							}}
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
				size: CONTROL_COL_WIDTH,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Lista de Actores</h2>
				<Button className="gap-2" asChild>
					<Link to="/crew/cast/new">
						<ListPlus />
						Nuevo Actor
					</Link>
				</Button>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={crew.castResults}
					pageCount={Math.ceil(crew.castsTotalResults / crew.castsPageSize)}
					pagination={page}
					loading={crew.castsLoading}
					onPaginationChange={setPage}
					rowHeight={53}
					withDynamicPageSize
					showPagination={false}
				/>
			</div>
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={() => {
					auth.currentUser
						?.getIdToken()
						.then(token => {
							deleteCastMember(token, {id: deleteCastID})
								.then(() => {
									setDeleteConfirmationModalOpen(false);
									setDeleteCastID("");
									if (!auth.currentUser) return;

									const params: Record<string, any> = {};

									if (searchValue) {
										params.searchvalue = searchValue;
										params.searchby = selectedSearchBy.value;
									}

									dispatch(crewActions.getCastsList({params: {page: page.pageIndex, page_size: page.pageSize, ...params}}));
								})
								.catch(err => {
									toast.error("No se puede eliminar el actor si tiene relaciones con algún contenido.");
									console.log(err);
								});
						})
						.catch(() => {});
				}}
				title={"Eliminar Actor"}
				description={<span className="text-white">Al confirmar eliminaras el actor, este proceso no se puede revertir.</span>}
			/>
		</div>
	);
}
