import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../../../../components/primitives/Select";
import {PUBLICITY_TYPES} from "../../../../constants";

interface PublicityTypeSelectProps {
	selectedPublicityType: string;
	onPublicityTypeChange: (v: string) => void;
}

export default function PublicityTypeSelect({selectedPublicityType, onPublicityTypeChange}: PublicityTypeSelectProps) {
	return (
		<Select value={selectedPublicityType} onValueChange={onPublicityTypeChange}>
			<SelectTrigger className="w-[180px]">
				<SelectValue placeholder="Selecciona un tipo de Publicidad" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Tipos de Anuncio</SelectLabel>
					{PUBLICITY_TYPES.map((contentType, index) => (
						<SelectItem key={index} value={contentType.value.toString()}>
							{contentType.label}
						</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}
