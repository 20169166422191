import {RootState} from "../../../store";
import {useAppSelector} from "../../../hooks";
import {DashboardCorporateQueries, DataByName} from "../dashboard-corporate-slice";

interface useDashboardCorporateByNameQueryResponse<T extends DashboardCorporateQueries> {
	data?: DataByName[T];
	isUninitialized: boolean;
	isLoading: boolean;
	isError: boolean;
	isSuccess: boolean;
}

export function useDashboardCorporateByNameQuery<T extends DashboardCorporateQueries>(name: T): useDashboardCorporateByNameQueryResponse<T> {
	const status = useAppSelector((state: RootState) => state.dashboardCorporate.statusByName[name]);
	const data = useAppSelector((state: RootState) => state.dashboardCorporate.dataByName[name]);

	const isUninitialized = status === undefined;
	const isLoading = status === "pending" || status === undefined;
	const isError = status === "rejected";
	const isSuccess = status === "fulfilled";

	return {data, isUninitialized, isLoading, isError, isSuccess};
}
