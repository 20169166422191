import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {useEffect, useMemo, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {crewActions} from "../../../../_store/features/crew/crew-slice";
import {toast} from "react-hot-toast";
import {Input} from "../../../../components/primitives/Input";
import {Button} from "../../../../components/primitives/Button";
import {CancelCircleFilled, Spinner} from "../../../../components/primitives/icons";
import {
	Staff,
	StaffProfession,
	UpdateStaffBodySchema,
	UpdateStaffBodyType,
	deleteStaffMemberProfessionRelation,
	insertStaffMemberProfessionRelation,
} from "../../../../data-access/series/staff";
import {ProfessionSelect} from "../../../../components/blocks/ProfessionSelect";
import {Badge} from "../../../../components/primitives/Badge";
import {auth} from "../../../../firebase";
import {HttpStatusCode} from "axios";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {DataTable} from "../../../../components/blocks/DataTable";

const initialState: UpdateStaffBodyType = {
	id: "",
	fullname: "",
};

interface categoryItem {
	jsonb_build_object: {
		category: string;
		series: {
			id: string;
			title: string;
		}[];
	};
}

const columnHelper = createColumnHelper<categoryItem>();

function StaffEdit() {
	const {id} = useParams();
	const staffProfessions = useAppSelector(state => state.crew.staffProfessionsResults);
	const staffData = useAppSelector(state => state.crew.staffResults.find(el => el.id === id));

	const [selectedProfessions, setSelectedProfessions] = useState<StaffProfession[]>(staffData?.professions ?? []);
	const [professionRelationLoading, setProfessionRelationLoading] = useState(false);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const [mutatedProfessions, setMutatedProfessions] = useState<categoryItem[] | undefined>([]);
	const [synced, setSynced] = useState(false);

	const categoriesNotInStaffData = mutatedProfessions
		?.filter(categoryItem => {
			const categoryName = categoryItem.jsonb_build_object.category;
			return !staffData?.professions?.some(profession => profession.profession === categoryName);
		})
		.map(categoryItem => categoryItem.jsonb_build_object.category);

	const professionsId = staffProfessions
		?.filter(profession => categoriesNotInStaffData?.includes(profession.profession))
		.map(profession => profession.id);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		reset,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<UpdateStaffBodyType>({
		resolver: zodResolver(UpdateStaffBodySchema),
		defaultValues: initialState,
	});

	function transformToTableData(response: Staff) {
		const tableData: categoryItem[] = [];
		const categoryMap: Map<string, categoryItem> = new Map();

		if (!response.series) return tableData;

		response.series.forEach(staff => {
			if (staff.jsonb_build_object.categories.length > 0) {
				staff.jsonb_build_object.categories.forEach(seriesItem => {
					const categoryId = seriesItem.category;
					const seriesData = {
						id: staff.jsonb_build_object.id,
						title: staff.jsonb_build_object.title,
					};

					if (categoryMap.has(categoryId)) {
						categoryMap.get(categoryId)?.jsonb_build_object.series.push(seriesData);
					} else {
						const categoryItem = {
							jsonb_build_object: {
								category: categoryId,
								series: [seriesData],
							},
						};
						categoryMap.set(categoryId, categoryItem);
					}
				});
			}
		});

		tableData.push(...categoryMap.values());

		return tableData;
	}

	useEffect(() => {
		dispatch(crewActions.getStaffProfessionList({page: 0, page_size: 99999999}));
	}, [dispatch]);

	useEffect(() => {
		if (professionsId.length >= 1 && !synced) {
			syncProfessions();
			setSynced(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [professionsId.length]);

	useEffect(() => {
		if (staffData) {
			const newData = transformToTableData(staffData);
			setMutatedProfessions(newData);
		}
	}, [staffData]);

	const syncProfessions = async () => {
		if (!staffData) return;
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		setProfessionRelationLoading(true);
		professionsId.forEach(async (professionId, index) => {
			const response = await insertStaffMemberProfessionRelation(token, {profession_id: professionId, staff_id: staffData?.id});
			if (response.data.profession_id === professionId) {
				setSelectedProfessions(prev => prev.concat({profession: categoriesNotInStaffData![index], id: response.data.id}));
			}
		});
		setProfessionRelationLoading(false);
	};

	const onSubmit: SubmitHandler<UpdateStaffBodyType> = data => {
		return dispatch(crewActions.updateStaff(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Staff Editado Correctamente");
				setTimeout(() => {
					navigate("/crew/staff");
				}, 3000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleAddProfession = async (profession: StaffProfession) => {
		try {
			if (!staffData) return;
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			setProfessionRelationLoading(true);
			const response = await insertStaffMemberProfessionRelation(token, {profession_id: profession.id, staff_id: staffData?.id});

			if (response.data.profession_id === profession.id) {
				setSelectedProfessions(prev => prev.concat({...profession, id: response.data.id}));
				setProfessionRelationLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleRemoveProfession = async (profession: StaffProfession) => {
		try {
			if (!staffData) return;
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			setProfessionRelationLoading(true);
			const response = await deleteStaffMemberProfessionRelation(token, {id: profession.id});

			if (response.status === HttpStatusCode.Ok) {
				setSelectedProfessions(prev => prev.filter(el => el.id !== profession.id));
				setProfessionRelationLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (!staffData) {
			return navigate("/crew/staff");
		}

		reset(staffData);
	}, [staffData, navigate, reset]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("jsonb_build_object.category", {
				id: "Profesiones",
				header: ({column}) => <DataTableColumnHeader title="Profesiones" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<p className="overflow-hidden text-ellipsis text-left">{info.row.original.jsonb_build_object.category}</p>
					</div>
				),
				size: Math.round(tableWidth * 0.5),
			}),
			columnHelper.accessor("jsonb_build_object.category", {
				id: "Series",
				header: ({column}) => <DataTableColumnHeader title="Series" column={column} />,
				cell: info => {
					const professions = info.row.original.jsonb_build_object.series;

					return (
						<div className="ml-2 flex w-full flex-col text-left">
							<ul>
								{professions.map((serie, index) => (
									<li key={index}>{serie.title}</li>
								))}
							</ul>
						</div>
					);
				},
				size: Math.floor(tableWidth * 0.45),
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, tableRef]);

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Editar staff</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite editar staff que se encuentran en la plataforma.</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-border">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Nombre completo<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar nombre del staff..." {...register("fullname")} />
									{errors?.fullname?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.fullname?.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Profesiones</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="flex items-center gap-2">
										<ProfessionSelect
											selectedProfessions={selectedProfessions}
											onProfessionSelect={handleAddProfession}
											disabled={professionRelationLoading}
										/>
										{professionRelationLoading && <Spinner />}
									</div>
									<div className="mt-2 flex flex-wrap rounded-sm border border-border p-4">
										{selectedProfessions.length === 0 && (
											<span className="w-full pt-0.5 text-center text-muted-foreground">No hay profesiones seleccionadas.</span>
										)}
										{selectedProfessions.map((category, index) => (
											<Badge className="mr-2 h-8 items-center rounded-md pl-1.5 pr-0 text-sm" key={index}>
												{category.profession}
												<Button
													type="button"
													variant={"link"}
													className="mx-1 text-white transition-all duration-100 enabled:hover:text-destructive"
													onClick={() => handleRemoveProfession(category)}
												>
													<CancelCircleFilled className="h-4 w-4" />
												</Button>
											</Badge>
										))}
									</div>
								</dd>
							</div>
							{staffData?.series && (
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium text-gray-900">Participación en contenidos</dt>
									<div className="mt-1 h-fit sm:col-span-2 sm:mt-0">
										<DataTable
											ref={ref => setTableRef(ref)}
											columns={columns}
											dataset={mutatedProfessions ?? []}
											pageCount={staffData?.series ? Math.ceil(staffData.series.length / page.pageSize) : 1}
											pagination={page}
											onPaginationChange={setPage}
											rowHeight={65}
											showPagination={false}
										/>
									</div>
								</div>
							)}
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								reset();
								navigate("/crew/staff");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Editar staff
						</Button>
					</div>
				</form>
			</div>
		</section>
	);
}

export default StaffEdit;
