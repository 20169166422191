import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, ChartData} from "chart.js";
import {addDays, differenceInDays, differenceInMonths, endOfDay, format, formatISO9075, isSameDay, isSameMonth} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {useAppDispatch} from "../../../../_store/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {HandThumbUpOutline, HeartOutline, Spinner} from "../../../primitives/icons";
import {DateRange} from "react-day-picker";
import {groupArrayByDay, oneMonth, transformedData} from "../../../../../utils/barGraphTransformData";
import {Card} from "../Card";
import {es} from "date-fns/locale";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface LikesAndFavoritesProps {
	seriesIds?: string;
	contentId?: string;
	isCorporate?: boolean;
	dates?: DateRange;
	showTotalCard?: boolean;
}

interface dataInterface {
	date: string;
	views: number;
}

const baseChartDataObject = {
	borderRadius: 4,
	parsing: {
		xAxisKey: "date",
		yAxisKey: "views",
	},
};

export function LikesAndFavorites({seriesIds, contentId, showTotalCard, isCorporate = false, dates}: LikesAndFavoritesProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; views: number}[]>>(null);
	const {data: statistics, isLoading} = useDashboardByNameQuery(contentId ? "contentLastMonthLikesAndFav" : "serieLastMonthLikesAndFav");
	const dispatch = useAppDispatch();

	const [showByMonths, setShowByMonths] = useState(false);
	const [totalValues, setTotalValues] = useState({
		totalLikes: 0,
		totalDislikes: 0,
		totalFavorites: 0,
	});

	useEffect(() => {
		if (contentId) {
			dispatch(
				dashboardActions.getContentLastMonthLikeAndFavorite({
					contentid: contentId,
					initial_date: isCorporate && dates ? formatISO9075(dates?.from!) : formatISO9075(addDays(Date.now(), -30)),
					final_date: isCorporate && dates ? formatISO9075(dates?.to!) : formatISO9075(endOfDay(new Date())),
				}),
			);
		}
		if (seriesIds) {
			dispatch(
				dashboardActions.getSerieLastMonthLikeAndFavorite({
					seriesid_list: seriesIds,
					initial_date: isCorporate && dates ? formatISO9075(dates?.from!) : formatISO9075(addDays(Date.now(), -30)),
					final_date: isCorporate && dates ? formatISO9075(dates?.to!) : formatISO9075(endOfDay(new Date())),
				}),
			);
		}
		const duration = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(duration > oneMonth);
	}, [contentId, dispatch, seriesIds, dates, isCorporate]);

	function chartData(dataArray: dataInterface[], groupedArray: dataInterface[], showMonthly?: boolean) {
		return dataArray
			.map(fillEl => {
				const el = groupedArray.find(statEl => {
					return showMonthly
						? isSameMonth(new Date(fillEl.date), new Date(statEl.date))
						: isSameDay(new Date(fillEl.date), new Date(statEl.date));
				});

				if (el) return {...el, date: fillEl.date};

				return fillEl;
			})
			.reverse();
	}

	const data: ChartData<"bar", {date: string; views: number}[]> = useMemo(() => {
		const startDate = dates ? new Date(dates?.from!) : addDays(Date.now(), -30);
		const endDate = dates ? new Date(dates?.to!) : new Date();
		const diff_days = differenceInDays(endDate, startDate) + 1;
		const diff_months = differenceInMonths(endDate, startDate) + 1;

		const fillerData = Array(diff_days)
			.fill(0)
			.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), views: 0}));

		const monthArray = Array(diff_months)
			.fill(0)
			.map((_, idx) => ({date: format(addDays(endOfDay(endDate), -idx * 30), "yyyy-MM-dd", {locale: es}), views: 0}));

		// Transforms the data returned from big query
		// views in this case represents the values of likes, favs, and dislikes.

		const dailyLikes =
			statistics?.grouped_likes?.map(({date, total_day_likes}) => ({
				date: new Date(date).toISOString(),
				views: total_day_likes,
			})) ?? [];

		const dailyDislikes =
			statistics?.grouped_dislikes?.map(({date, total_day_dislikes}) => ({
				date: new Date(date).toISOString(),
				views: total_day_dislikes,
			})) ?? [];

		const dailyFavs =
			statistics?.grouped_favorite?.map(({date, total_day_favorite}) => ({
				date: new Date(date).toISOString(),
				views: total_day_favorite,
			})) ?? [];

		// Group the dat by day
		const dailyDislikesGrouped = groupArrayByDay(dailyDislikes);
		const dailyLikesGrouped = groupArrayByDay(dailyLikes);
		const dailyFavsGrouped = groupArrayByDay(dailyFavs);

		// Combined data from statistics and filled.
		const dailyLikesData = chartData(fillerData, dailyLikesGrouped, showByMonths);
		const dailyDislikesData = chartData(fillerData, dailyDislikesGrouped, showByMonths);
		const dailyFavsData = chartData(fillerData, dailyFavsGrouped, showByMonths);

		const totalLikes = transformedData(fillerData, dailyLikesData, showByMonths, monthArray).reduce((acc, curr) => acc + curr.views, 0);
		const totalDislikes = transformedData(fillerData, dailyDislikesData, showByMonths, monthArray).reduce((acc, curr) => acc + curr.views, 0);
		const totalFavorites = transformedData(fillerData, dailyFavsData, showByMonths, monthArray).reduce((acc, curr) => acc + curr.views, 0);
		setTotalValues({totalLikes, totalDislikes, totalFavorites});

		return {
			datasets: [
				{
					label: "Likes",
					data: transformedData(fillerData, dailyLikesData, showByMonths, monthArray),
					backgroundColor: "#088395",
					...baseChartDataObject,
				},
				{
					label: "Dislikes",
					data: transformedData(fillerData, dailyDislikesData, showByMonths, monthArray),
					backgroundColor: "#B30000",
					...baseChartDataObject,
				},
				{
					label: "Follow",
					data: transformedData(fillerData, dailyFavsData, showByMonths, monthArray),
					backgroundColor: "#FF6969",
					...baseChartDataObject,
				},
			],
		};
	}, [statistics, dates, showByMonths]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 py-6 pt-0 md:grid-cols-2 lg:grid-cols-7">
			<div className={`${showTotalCard ? "col-span-6" : "col-span-7"} rounded-lg border bg-card text-card-foreground shadow-sm`}>
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">
						{isCorporate ? "Likes, Dislikes y Follows." : "Likes, Dislikes y Follows últimos 30 días."}
					</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Bar
							ref={chartRef}
							height={350}
							options={{
								plugins: {
									legend: {
										position: "top",
									},
									tooltip: {
										callbacks: {
											title(tooltipItems) {
												const date = new Date(tooltipItems[0].label);
												if (showByMonths) {
													return format(date, "MMM - yyyy", {locale: es}).toLowerCase();
												} else {
													return format(date, "EE, dd MMMM", {locale: es}).toLowerCase();
												}
											},
										},
									},
								},
								responsive: true,
								maintainAspectRatio: false,
								interaction: {
									mode: "index" as const,
									intersect: false,
								},
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));

												// Format the date based on the available information
												const formattedDate = showByMonths
													? format(date, "MMM", {locale: es}).toLowerCase()
													: format(date, "MMM d", {locale: es}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
									},
									y: {
										ticks: {
											precision: 0,
										},
									},
								},
							}}
							data={data}
							id="likes"
						/>
					</div>
				)}
			</div>
			{showTotalCard && (
				<div className="grid-cols-1">
					<Card
						title="Total"
						amount={0}
						isMoney={false}
						loading={isLoading}
						customValues={[
							{
								amount: totalValues.totalLikes,
								text: "Like",
								icon: <HandThumbUpOutline className="opacity-50" width={28} height={28} />,
							},
							{
								amount: totalValues.totalDislikes,
								icon: <HandThumbUpOutline className="rotate-180 opacity-50" width={28} height={28} />,
								text: "Dislikes",
							},
							{
								amount: totalValues.totalFavorites,
								text: "Follow",
								icon: <HeartOutline className="opacity-50" width={28} height={28} />,
							},
						]}
					/>
				</div>
			)}
		</div>
	);
}
