import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {DataTable} from "../../../components/blocks/DataTable";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {Task} from "../../../data-access/programmed-task";
import {programmedTaskActions} from "../../../_store/features/programmed-task/programmed-task";
import {Button} from "../../../components/primitives/Button";
import {Trash} from "../../../components/primitives/icons";
import ActionConfirmModal from "../../../components/blocks/ActionConfirmModal";
import toast from "react-hot-toast";
import {PRESET_NOTIFICATIONS_TYPES} from "../../../constants/notifications";

const columnHelper = createColumnHelper<Task>();

function ProgrammedTasksList() {
	const taskList = useAppSelector(state => state.programmedTask);
	const dispatch = useAppDispatch();
	const {loading, task} = taskList;
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [idToDelete, setIdToDelete] = useState<string | null>(null);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

	useEffect(() => {
		dispatch(programmedTaskActions.listAllTask());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 2);

		return [
			columnHelper.accessor("id", {
				id: "ID",
				header: ({column}) => <DataTableColumnHeader title="ID" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.id}</span>
					</div>
				),
				size: 0.1 * colWidth,
			}),
			columnHelper.accessor("dateObject", {
				id: "Fecha de ejecución",
				header: ({column}) => <DataTableColumnHeader title="Fecha de ejecución" column={column} />,
				cell: info => {
					const date = new Date(info.row.original.dateObject);
					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">
								{date.toLocaleString("es-US", {timeZone: "America/New_York"})}
							</span>
						</div>
					);
				},
				size: 0.5 * colWidth,
			}),
			columnHelper.accessor("task", {
				id: "Tarea",
				header: ({column}) => <DataTableColumnHeader title="Tarea" column={column} />,
				cell: info => {
					let taskDetails = {
						name: info.row.original.task,
						task: "",
					};

					const regex = /-d '([^']+)'/;
					const match = info.row.original.task.match(regex);

					if (match && match.length > 1) {
						const payloadString = match[1];
						const payloadObject = JSON.parse(payloadString);

						taskDetails = {
							name: payloadObject.name,
							task: PRESET_NOTIFICATIONS_TYPES.find(el => el.value === payloadObject.type)?.label ?? payloadObject.type,
						};
					} else if (info.row.original.id) {
						const jsonString = info.row.original.task.slice(info.row.original.task.indexOf("{"), info.row.original.task.length);
						const payloadObject = JSON.parse(jsonString);
						taskDetails = {
							name: payloadObject.name,
							task: PRESET_NOTIFICATIONS_TYPES.find(el => el.value === payloadObject.type)?.label ?? payloadObject.type,
						};
					}

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">Nombre: {taskDetails.name}</span>
							<span className="ml-2 overflow-hidden text-ellipsis text-left">Tipo: {taskDetails.task}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full gap-2">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setIdToDelete(info.row.original.id);
								setConfirmDeleteModal(true);
							}}
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
				size: 0.2 * colWidth,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	return (
		<div className="flex h-screen flex-col">
			<div className="flex flex-row items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Lista de tareas programadas en el servidor</h2>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={task}
					pageCount={Math.ceil((task?.length ?? 0) / pagination.pageSize)}
					pagination={pagination}
					onPaginationChange={setPagination}
					manualPagination={false}
					showPagination={false}
					loading={loading}
				/>
			</div>

			<ActionConfirmModal
				open={confirmDeleteModal}
				onCancel={() => {
					setConfirmDeleteModal(false);
				}}
				onAccept={() => {
					idToDelete &&
						dispatch(programmedTaskActions.deleteTask(idToDelete))
							.then(res => {
								if (res.meta.requestStatus === "fulfilled") {
									toast.success("Tarea programada eliminada correctamente");
									return;
								}
								toast.error("Ocurrió un error al eliminar la tarea programada.");
							})
							.catch(err => {
								toast.error(err.message);
							});

					setConfirmDeleteModal(false);
					setIdToDelete(null);
				}}
				title={"Eliminar Tarea"}
				description={
					<span className="text-white">
						Al confirmar eliminaras la tarea programada y la acción correspondiente no se producirá, este proceso no se puede revertir.
					</span>
				}
			/>
		</div>
	);
}

export default ProgrammedTasksList;
