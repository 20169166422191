import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import Select from "react-select";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {seriesActions} from "../../_store/features/series/series-slice";
import {auth} from "../../firebase";
import {EditStickerBodySchema, EditStickerBodyType, uploadStickersImage} from "../../data-access/stickers";
import {stickersActions} from "../../_store/features/stickers/stickers-slice";
import {Input} from "../../components/primitives/Input";
import {Textarea} from "../../components/primitives/Textarea";
import {Image as ImageIcon} from "../../components/primitives/icons";
import {Button} from "../../components/primitives/Button";
import MultimediaImagePickerModal from "../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../_store/features/gallery/gallery-slice";
import {FOLDERS_NAMES_STICKERS} from "../../../utils/folderNames";
import {toast} from "react-hot-toast";
import {selectGlobalStyles} from "../../../utils/selectGlobalStyles";
import {zodResolver} from "@hookform/resolvers/zod";
import SeriesSelect from "../../components/blocks/SeriesSelect";
import {Skeleton} from "../../components/primitives/Skeleton";
import {STICKERS_TYPES, STICKER_RENDER_TYPES} from "../../constants/stickers";
import StickerRenderer from "../../components/blocks/StickerRenderer";

type ImageFieldsTypes = keyof Pick<EditStickerBodyType, "extra_url" | "url">;

interface SelectOption {
	label: string;
	value: number;
}

interface SerieSelectOption {
	label: string;
	value: string;
}

const selectStatus = [
	{label: "Activo", value: true},
	{label: "Inactivo", value: false},
];
type selectStatusType = (typeof selectStatus)[number] | null;

const selectVisibilityStatus: SelectOption[] = [
	{label: "Global", value: 1},
	{label: "Serie", value: 2},
];

const selectType: SelectOption[] = [
	{label: "Estático", value: STICKER_RENDER_TYPES.STATIC},
	{label: "Animado", value: STICKER_RENDER_TYPES.ANIMATED},
];

export function StickersEdit() {
	const {id} = useParams();
	const stickerData = useAppSelector(state => state.stickers.results.find(el => el.id === id));
	const series = useAppSelector(state => state.series.results);
	const [isImageUploading, setIsImageUploading] = useState(false);
	const [isExtraImageUploading, setIsExtraImageUploading] = useState(false);
	const [selectedVisibilityStatus, setSelectedVisibilityStatus] = useState<SelectOption | null>(selectVisibilityStatus[0]);
	const [selectedType, setSelectedType] = useState<SelectOption | null>(selectType[0]);
	const [imageType, setImageType] = useState<ImageFieldsTypes>("url");
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState<selectStatusType>(null);
	const [selectedSerie, setSelectedSerie] = useState<SerieSelectOption>();
	const [isLoadingInitials, setIsLoadingInitials] = useState(true);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		control,
		clearErrors,
		setError,
		getValues,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<EditStickerBodyType>({
		resolver: zodResolver(EditStickerBodySchema),
		defaultValues: {
			sticker_type: selectedVisibilityStatus?.value,
			seriesid: selectedSerie?.value,
			extra_url: null,
			render_type: selectType[0].value,
		},
	});

	console.log(getValues(), errors);

	useEffect(() => {
		reset();
	}, [reset]);

	const onSubmit: SubmitHandler<EditStickerBodyType> = data => {
		if (selectedVisibilityStatus?.value === STICKERS_TYPES.SERIES && !data.seriesid) {
			setError("seriesid", {
				type: "manual",
				message: "Este campo no puede estar vacío.",
			});
		} else {
			return dispatch(stickersActions.editStickers(data)).then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					toast.success("Sticker Editado Correctamente");
					setTimeout(() => {
						navigate(-1);
					}, 3000);
				}
				if (res.meta.requestStatus === "rejected") {
					if (res.meta.rejectedWithValue && typeof res.payload === "string") {
						toast.error(res.payload);
					}
				}
			});
		}
	};

	useEffect(() => {
		if (!stickerData) {
			return navigate(-1);
		}

		const activeStatus = stickerData.active ? selectStatus[0] : selectStatus[1];
		const renderType = selectType.find(renderType => renderType.value === stickerData.render_type) ?? selectType[0];
		const type = stickerData.sticker_type
			? selectVisibilityStatus.find(type => type.value === stickerData.sticker_type)
			: selectVisibilityStatus[0];

		setSelectedStatus(activeStatus);
		setSelectedVisibilityStatus(type!);
		setSelectedType(renderType);

		if (series && stickerData.seriesid !== null) {
			const seriesData = series.find(serie => serie.id === stickerData.seriesid);
			if (seriesData) {
				setSelectedSerie({
					label: seriesData.title,
					value: seriesData.id,
				});
				setTimeout(() => {
					setIsLoadingInitials(false);
				}, 1500);
			}
		} else if (series && stickerData.seriesid === null) {
			setTimeout(() => {
				setIsLoadingInitials(false);
			}, 1500);
		}

		reset(stickerData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stickerData, series]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page: 0, page_size: 99999999}}));
	}, [dispatch]);

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();

		if (type === "url") setIsImageUploading(true);
		if (type === "extra_url") setIsExtraImageUploading(true);

		body.append("file", file);
		body.append("stickerid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadStickersImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						clearErrors(imageType);
						setIsImageUploading(false);
						setIsExtraImageUploading(false);
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setImageType(type);
		setOpenDialog(true);
	};

	return (
		<div className="px-5 py-4">
			<div className="flex items-center justify-between border-b border-b-border bg-background pb-5">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Editar Stickers</h2>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} className="divide-y divide-border">
				<div className="ml-5 justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
					<div className="text-sm font-medium leading-6 text-gray-900">Visibilidad de sticker</div>
					<Controller
						name="sticker_type"
						control={control}
						render={({field}) => (
							<Select
								className="mt-1 w-full rounded ring-1"
								styles={selectGlobalStyles}
								{...field}
								options={selectVisibilityStatus}
								value={selectedVisibilityStatus}
								onChange={selectedOption => {
									setSelectedVisibilityStatus(selectedOption);
									field.onChange(selectedOption?.value);
								}}
								isDisabled={isLoadingInitials}
							/>
						)}
					/>
				</div>
				{selectedVisibilityStatus?.value === STICKERS_TYPES.SERIES && (
					<>
						<div className="ml-5 justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<div className="text-sm font-medium leading-6 text-gray-900">
								Serie<span className="text-xs font-bold text-destructive">*</span>
							</div>

							{isLoadingInitials ? (
								<Skeleton className="mt-1 h-10 w-full bg-slate-300" />
							) : (
								<div className="mt-1 w-full rounded ring-1">
									<Controller
										name="seriesid"
										control={control}
										render={({field}) => (
											<SeriesSelect
												onChange={newValue => {
													setSelectedSerie(newValue as any);
													field.onChange(newValue?.value);
												}}
												defaultValue={selectedSerie}
											/>
										)}
									/>
									{errors.seriesid && (
										<span className="ml-5 text-sm font-medium text-destructive">*{errors?.seriesid?.message}</span>
									)}
								</div>
							)}
						</div>
					</>
				)}
				<div className="ml-5 justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
					<div className="text-sm font-medium leading-6 text-gray-900">
						Estado<span className="text-xs font-bold text-destructive">*</span>
					</div>
					<div className="mb-3 max-w-2xl basis-1/2">
						<Controller
							name="active"
							control={control}
							render={({field}) => (
								<Select
									className="mt-1 w-full rounded ring-1"
									styles={selectGlobalStyles}
									{...field}
									options={selectStatus as any}
									value={selectedStatus}
									onChange={selectedOption => {
										setSelectedStatus(selectedOption);
										field.onChange(selectedOption?.value);
									}}
								/>
							)}
						/>
					</div>
				</div>
				<div className="ml-5 justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
					<dt className="text-sm font-medium leading-6 text-gray-900">Tipo de sticker</dt>
					<Controller
						name="render_type"
						control={control}
						render={({field}) => (
							<Select
								className="mt-1 w-full rounded ring-1"
								styles={selectGlobalStyles}
								{...field}
								options={selectType}
								value={selectedType}
								onChange={selectedOption => {
									setSelectedType(selectedOption);
									field.onChange(Number(selectedOption?.value));
								}}
							/>
						)}
					/>
				</div>
				<div className="ml-5 justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
					<div className="text-sm font-medium leading-6 text-gray-900">
						Monedas<span className="text-xs font-bold text-destructive">*</span>
					</div>
					<div className="mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
						<Input
							type="number"
							placeholder="Insertar valor a pagar por el sticker..."
							min={0}
							{...register("coin", {min: 0, valueAsNumber: true})}
						/>
						{errors?.coin?.message && <span className="text-sm font-medium text-destructive">{errors?.coin.message}</span>}
					</div>
				</div>
				<div className="ml-5 justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
					<div className="text-sm font-medium leading-6 text-gray-900">
						Descripción<span className="text-xs font-bold text-destructive">*</span>
					</div>
					<div className="mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
						<Textarea rows={3} placeholder="Descripción del sticker" {...register("description", {required: "Campo requerido"})} />
						{errors.description && <span className="text-red-500">{errors.description.message}</span>}
					</div>
				</div>

				<div className="ml-5 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
					<div className="text-sm font-medium leading-6 text-gray-900">
						Imagen Web<span className="text-xs font-bold text-destructive">*</span>
					</div>
					<div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
						<div className="grid w-full max-w-sm items-center gap-1.5">
							<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
								{!getValues("url") ? (
									isImageUploading ? (
										<Spinner />
									) : (
										<ImageIcon className="h-6 w-6 text-slate-400" />
									)
								) : (
									<StickerRenderer url={getValues("url")} />
								)}
							</div>
							<div className="flex w-full flex-col">
								<Input
									type="file"
									accept={selectedType?.value === STICKER_RENDER_TYPES.ANIMATED ? ".lottie,.json" : "image/*"}
									onChange={e => {
										const [file] = e.target.files as any;
										handleUploadImage(file, "url");
									}}
									placeholder="Elige un Archivo"
								/>
								<Button
									variant="outline"
									type="button"
									className="mt-2 gap-2 whitespace-nowrap"
									onClick={() => {
										handleSelectImage("url");
									}}
								>
									<ImageIcon className="h-4 w-4" />
									Seleccionar de multimedia
								</Button>
							</div>
						</div>
						{errors.url && <span className="text-red-500">{errors.url.message}</span>}
					</div>
				</div>
				<div className="ml-5 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
					<dt>
						<span className="text-sm font-medium leading-6 text-gray-900">Extra Imagen</span>
						<p className="mt-1 max-w-md text-sm leading-6 text-gray-500">
							Imagen extra a utilizar en el sticker, usualmente es utilizado para la animación de pantalla completa en los stickers
							animados.
						</p>
					</dt>
					<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
						<div className="grid w-full max-w-sm items-center gap-1.5">
							<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
								{!getValues("extra_url") ? (
									isExtraImageUploading ? (
										<Spinner />
									) : (
										<ImageIcon className="h-6 w-6 text-slate-400" />
									)
								) : (
									<StickerRenderer url={getValues("extra_url")!} />
								)}
							</div>
							<Input
								type="file"
								accept={selectedType?.value === STICKER_RENDER_TYPES.ANIMATED ? ".lottie,.json" : "image/*"}
								onChange={e => {
									const [file] = e.target.files as any;
									handleUploadImage(file, "extra_url");
								}}
								placeholder="Elige un Archivo"
							/>
							<Button
								variant="outline"
								type="button"
								className="gap-2 whitespace-nowrap"
								onClick={() => {
									handleSelectImage("extra_url");
								}}
							>
								<ImageIcon className="h-4 w-4" />
								Seleccionar de multimedia
							</Button>
						</div>
						{errors?.extra_url?.message && <span className="text-sm font-medium text-destructive">{errors?.extra_url.message}</span>}
					</dd>
				</div>
				<div className="flex justify-end gap-2 pb-4 pt-4">
					<Button
						type="button"
						variant="secondary"
						onClick={() => {
							navigate(-1);
						}}
					>
						Cancelar
					</Button>
					<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
						{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
						Editar producto
					</Button>
				</div>
			</form>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					navigate(-1);
					dispatch(galleryActions.resetToInitial());
				}}
				acceptExtensions={selectedType?.value === STICKER_RENDER_TYPES.ANIMATED ? [".lottie", ".json"] : [".png", ".jpeg", ".jpg", ".gif"]}
				tabIndex={9}
				folderNames={FOLDERS_NAMES_STICKERS}
				onSuccess={url => {
					setValue(imageType, url);
					clearErrors(imageType);
					setOpenDialog(false);
					navigate(-1);
					dispatch(galleryActions.resetToInitial());
				}}
			/>
		</div>
	);
}
