import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
	dashboardActions,
	selectedInfluencerSerieContentSelector,
	selectedInfluencerSerieSelector,
} from "../../../../_store/features/dashboard/dashboard-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {LikesAndFavorites, MembershipUserViews, TopViews, ViewsTimeSeries} from "../../../../components/blocks/Stats";
import {PartnerSerieContentSelect} from "../../_subcomponents/PartnerSerieContentSelect";

export function ContentStatistics() {
	const selectedInfluencerSerie = useAppSelector(selectedInfluencerSerieSelector);
	const selectedInfluencerSerieContent = useAppSelector(selectedInfluencerSerieContentSelector);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!selectedInfluencerSerie) {
			navigate("/influencer/contents");
		}
	}, [navigate, selectedInfluencerSerie]);

	const handleClickOnContent = (contentId: string) => {
		dispatch(dashboardActions.setSelectedContentId(contentId));
		navigate(`/influencer/contents/${selectedInfluencerSerie?.serie_id}/${contentId}`);
	};

	return (
		<>
			<div className="mb-6 flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">{selectedInfluencerSerie?.title}</h2>
				<PartnerSerieContentSelect />
			</div>
			<ViewsTimeSeries
				serieid={selectedInfluencerSerie?.serie_id}
				contentid={selectedInfluencerSerieContent?.contentid}
				title="Cantidad de vistas diarias"
			/>

			<LikesAndFavorites seriesIds={selectedInfluencerSerie?.serie_id!} contentId={selectedInfluencerSerieContent?.contentid} />
			<div className="grid gap-6 px-8 pb-6 xl:grid-cols-[min-content,auto]">
				<div>
					<MembershipUserViews serieid={selectedInfluencerSerie?.serie_id} contentid={selectedInfluencerSerieContent?.contentid} />
				</div>
				<div>
					<TopViews type="contents" serieid={selectedInfluencerSerie?.serie_id} onClickContent={handleClickOnContent} />
				</div>
			</div>
		</>
	);
}
