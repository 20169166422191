import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {FC, useEffect, useMemo, useState} from "react";
import {Eye, Pencil, Spinner, Trash} from "../../../../../components/primitives/icons";
import {HTML5Backend} from "react-dnd-html5-backend";
import {createColumnHelper, flexRender, getCoreRowModel, Row, useReactTable} from "@tanstack/react-table";
import {DndProvider} from "react-dnd";
import {Badge} from "../../../../../components/primitives/Badge";
import {Button} from "../../../../../components/primitives/Button";
import {GetPostsListParams, Post, PostType, PostTypeOrdinal} from "../../../../../data-access/exclusive-room/types";
import NewPostModal from "./subcomponents/new-post-modal";
import CreatePostDropdownMenu from "./subcomponents/create-post-dropdown-menu";
import {exclusiveRoomActions} from "../../../../../_store/features/exclusive-room/exclusive-room-slice";
import {PostsTypeLabels} from "../../../../../constants/posts";
import ActionConfirmModal from "../../../../../components/blocks/ActionConfirmModal";
import {toast} from "react-hot-toast";
import {commentsActions} from "../../../../../_store/features/comments/comments-slice";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../../../components/primitives/Select";
import CreateSurveysModal from "./subcomponents/create-surveys";
import PreviewPostModal from "./subcomponents/preview-post-modal";
import EditPostModal from "./subcomponents/edit-post-modal";

const columnHelper = createColumnHelper<Post>();

const statusOptions = [
	{name: "Todos", value: undefined},
	{name: "Imagen", value: 0},
	{name: "Video", value: 1},
	{name: "Encuestas", value: 2},
];

export default function ExclusiveRoomSection() {
	let {id: seriesid} = useParams();
	const dispatch = useAppDispatch();
	const {results: exclusiveRoomData, loading: isLoading} = useAppSelector(state => state.exclusiveRoom);
	const [postType, setPostType] = useState<PostTypeOrdinal>(PostType.IMAGE);
	const [openNewPostModal, setOpenNewPostModal] = useState(false);
	const [openNewSurveyModal, setOpenNewSurveyModal] = useState(false);
	const [openPreviewPostModal, setOpenPreviewPostModal] = useState(false);
	const [openEditPostModal, setOpenEditPostModal] = useState(false);
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [idToDelete, setIdToDelete] = useState("");
	const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);

	useEffect(() => {
		if (!seriesid) return;
		dispatch(exclusiveRoomActions.getExclusiveRoomListBySerie({id: seriesid}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seriesid]);

	const handleCloseNewPostModal = () => setOpenNewPostModal(false);
	const handleCloseSurveyModal = () => setOpenNewSurveyModal(false);
	const handlePreviewPostModal = () => {
		setOpenPreviewPostModal(false);
		dispatch(exclusiveRoomActions.clearActivePost());
	};
	const handleEditPostModal = () => {
		dispatch(exclusiveRoomActions.clearActivePost());
		setOpenEditPostModal(false);
	};
	const handleOpenNewPostModal = (type: PostTypeOrdinal) => {
		setPostType(type);
		setOpenNewPostModal(true);
	};
	const handleOpenNewSurveyModal = () => {
		setPostType(PostType.POLL);
		setOpenNewSurveyModal(true);
	};

	const columns = useMemo(
		() => [
			columnHelper.accessor("content.payload.content.title", {
				header: "Título",
				id: "title",
				cell: info => info.getValue(),
				size: 64,
			}),
			columnHelper.accessor("created_at", {
				header: "Fecha de publicación",
				cell: info => (
					<span className="ml-2 overflow-hidden text-ellipsis text-neutral-500">
						{new Date(info.row.original.created_at).toLocaleDateString()}
					</span>
				),
				size: 350,
			}),
			columnHelper.accessor("content.payload.subtype", {
				header: "Tipo",
				cell: info => <div className="flex flex-1 justify-center">{PostsTypeLabels[info.getValue()]}</div>,
				size: 75,
				enableResizing: false,
				enableColumnFilter: false,
			}),
			columnHelper.accessor("content.payload.content.text", {
				header: "Descripción",
				cell: info => <div className="flex flex-1 justify-center">{info.getValue()}</div>,
				size: 75,
				enableResizing: false,
				enableColumnFilter: false,
			}),
			columnHelper.accessor("published", {
				header: "Publicado",
				cell: info =>
					info.getValue() ? (
						<div className="flex flex-1 justify-center">
							<Badge variant={"success"}>Activo</Badge>
						</div>
					) : (
						<div className="flex flex-1 justify-center">
							<Badge variant={"destructive"}>Inactivo</Badge>
						</div>
					),
				size: 75,
				enableResizing: false,
				enableColumnFilter: false,
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full justify-center gap-2">
						<Button
							className="rounded bg-neutral-200 p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
							onClick={() => {
								dispatch(exclusiveRoomActions.setActivePost(info.row.original.id));
								setOpenEditPostModal(true);
							}}
							variant="ghost"
						>
							<Pencil className="h-4 w-4" />
						</Button>
						<Button
							className="rounded bg-neutral-200 p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
							onClick={() => {
								dispatch(exclusiveRoomActions.setActivePost(info.row.original.id));
								setOpenPreviewPostModal(true);
							}}
							variant="ghost"
						>
							<Eye className="h-4 w-4" />
						</Button>
						<Button
							className="rounded bg-neutral-200 p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
							onClick={() => {
								setIdToDelete(info.row.original.id);
								setDeleteConfirmationModalOpen(true);
							}}
							variant="ghost"
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
			}),
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const filteredPostsData = useMemo(() => {
		return [...exclusiveRoomData].sort((a, b) => {
			return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
		});
	}, [exclusiveRoomData]);

	const table = useReactTable({
		data: filteredPostsData,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getRowId: row => row.id,
	});

	const handleDeletePost = async (id: string) => {
		try {
			dispatch(commentsActions.removeComments({commentid: id})).then(thunk => {
				if (thunk.meta.requestStatus === "fulfilled") {
					toast.success("Publicación eliminada satisfactoriamente!");
					dispatch(exclusiveRoomActions.getExclusiveRoomListBySerie({id: seriesid!}));
				} else {
					toast.error("Ocurrió un error al eliminar la publicación.");
				}
			});
		} catch (error) {
			toast.error("Ocurrió un error al eliminar la publicación.");
			console.error("Ocurrió un error:", error);
		}
	};

	const handleStatusFilter = (value: string) => {
		const status = statusOptions.find(el => el.name === value) ?? statusOptions[0];
		setSelectedStatus(status);
		const params: GetPostsListParams = {
			id: seriesid!,
		};
		if (status.value !== undefined) {
			params["subtype"] = status.value.toString();
		}

		dispatch(exclusiveRoomActions.getExclusiveRoomListBySerie(params));
	};

	return (
		<>
			<div className="flex flex-col">
				<div className="flex items-center justify-end py-2">
					{isLoading && <Spinner />}
					<CreatePostDropdownMenu
						onCreateImagePost={() => handleOpenNewPostModal(PostType.IMAGE)}
						onCreateVideoPost={() => handleOpenNewPostModal(PostType.VIDEO)}
						onCreatePollPost={() => handleOpenNewSurveyModal()}
					/>
					<Select onValueChange={handleStatusFilter} value={selectedStatus.name}>
						<SelectTrigger className="ml-4 flex w-48 items-center justify-center space-x-2 rounded bg-neutral-900 px-4 py-2 text-white transition-colors duration-300 data-[state=open]:bg-neutral-700 hover:bg-neutral-700">
							<span>Mostrar: </span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{statusOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={sOption.name}>
										{sOption.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>

				<DndProvider backend={HTML5Backend}>
					<table className="overflow-hidden rounded-tr bg-neutral-50">
						<thead>
							{table.getHeaderGroups().map(headerGroup => (
								<tr key={headerGroup.id} className="bg-neutral-900 text-white">
									{headerGroup.headers.map(header => (
										<th className="px-4 py-2" key={header.id} colSpan={header.colSpan}>
											{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map(row => (
								<RowData key={row.id} row={row} />
							))}
						</tbody>
					</table>
					{!filteredPostsData.length && <div className="text-center text-xl font-light italic text-neutral-400">No hay datos</div>}
				</DndProvider>
			</div>
			<NewPostModal onDismiss={handleCloseNewPostModal} open={openNewPostModal} type={postType} />
			<CreateSurveysModal onDismiss={handleCloseSurveyModal} open={openNewSurveyModal} />
			<EditPostModal onDismiss={handleEditPostModal} open={openEditPostModal} />
			<PreviewPostModal onDismiss={handlePreviewPostModal} open={openPreviewPostModal} />
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setIdToDelete("");
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={async () => {
					handleDeletePost(idToDelete);
					setDeleteConfirmationModalOpen(false);
					setIdToDelete("");
				}}
				title={"Eliminar publicación de la sala exclusiva de la serie"}
				description={<span className="text-white">Al confirmar eliminarás la publicación, este proceso no se puede revertir.</span>}
			/>
		</>
	);
}

const RowData: FC<{row: Row<Post>}> = ({row}) => {
	return (
		<tr className="overflow-hidden bg-neutral-100 odd:bg-neutral-200">
			{row.getVisibleCells().map(cell => (
				<td key={cell.id} className="py-2 text-center">
					{flexRender(cell.column.columnDef.cell, cell.getContext())}
				</td>
			))}
		</tr>
	);
};
