import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	AddVisibilityDataInterface,
	Advertisement,
	AdvertisementsAvailableLocationsResponse,
	deleteAdvertisementData,
	DeleteAdvertisementVisibilityParams,
	deleteAdvertisementVisibilityRange,
	EditAdvertisementBodyType,
	editAdvertisementData,
	EditAdvertisementPrintBodyType,
	editAdvertisementPrintData,
	GetAddVisibilityListParams,
	getAdvertisementsAvailableLocations,
	getAdvertisementsList,
	GetAdvertisementsListParams,
	getAdvertisementsVisibilityRange,
	InsertAdvertisementBodyType,
	insertAdvertisementData,
	insertAdvertisementVisibilityRange,
	VisibilityRangeParamsInterface,
} from "../../../data-access/advertisers";
import {auth} from "../../../firebase";

export interface advertisementsInterface {
	loading: boolean;
	loadingVisibility: boolean;
	results: Advertisement[];
	adsVisibilityResults: AddVisibilityDataInterface[];
	adsLocations: AdvertisementsAvailableLocationsResponse;
	totalResults: number;
	current: number;
	pageSize: number;
	success: boolean;
}

const adsLocationsInitialState: AdvertisementsAvailableLocationsResponse = {
	totalResults: 0,
	pageSize: 0,
	current: 0,
	results: [],
};

const initialState: advertisementsInterface = {
	loading: false,
	loadingVisibility: false,
	results: [],
	adsVisibilityResults: [],
	adsLocations: adsLocationsInitialState,
	totalResults: 0,
	current: 0,
	pageSize: 0,
	success: false,
};

const getAdvertisements = createAsyncThunk("advertisements/list", async (params: GetAdvertisementsListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getAdvertisementsList(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getAdvertisementsLocations = createAsyncThunk(
	"advertisements/list-available-locations",
	async (params: Pick<GetAdvertisementsListParams, "page" | "page_size">, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getAdvertisementsAvailableLocations(token, params);

			return {
				adsLocations: response.data,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const insertAdvertisement = createAsyncThunk("advertisements/insert", async (data: InsertAdvertisementBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await insertAdvertisementData(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem inserting advertisement, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("No se pudo insertar el anuncio, intenta nuevamente.");
	}
});

const deleteAdvertisement = createAsyncThunk("advertisements/delete", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await deleteAdvertisementData(token, {publicityid: id});

		return id;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editAdvertisement = createAsyncThunk("advertisements/edit", async (data: EditAdvertisementBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editAdvertisementData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editAdvertisementPrint = createAsyncThunk("advertisements/edit-print", async (data: EditAdvertisementPrintBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editAdvertisementPrintData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const insertVisibilityRange = createAsyncThunk("advertisements/insert-visibility-range", async (data: VisibilityRangeParamsInterface, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await insertAdvertisementVisibilityRange(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem inserting advertisement, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("No se pudo insertar el anuncio, intenta nuevamente.");
	}
});

const deleteVisibilityRange = createAsyncThunk(
	"advertisements/delete-visibility-range",
	async ({ad_location_id, is_banner}: DeleteAdvertisementVisibilityParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			await deleteAdvertisementVisibilityRange(token, {ad_location_id: ad_location_id, is_banner: is_banner});

			return {ad_location_id: ad_location_id, is_banner: is_banner};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getVisibilityRange = createAsyncThunk("advertisements/list-visibility-range", async (params: GetAddVisibilityListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getAdvertisementsVisibilityRange(token, params);

		return {
			adsVisibilityResults: response.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const advertisementsSlice = createSlice({
	name: "advertisements",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getAdvertisements.pending, state => {
				state.loading = true;
			})
			.addCase(getAdvertisements.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getAdvertisements.rejected, (state, action) => {});
		builder
			.addCase(getAdvertisementsLocations.pending, state => {
				state.loading = true;
			})
			.addCase(getAdvertisementsLocations.fulfilled, (state, action) => {
				state.loading = false;
				state.adsLocations = action.payload?.adsLocations ?? adsLocationsInitialState;
			})
			.addCase(getAdvertisementsLocations.rejected, (state, action) => {});
		builder
			.addCase(editAdvertisement.pending, state => {
				state.loading = true;
			})
			.addCase(editAdvertisement.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editAdvertisement.rejected, (state, action) => {});
		builder
			.addCase(editAdvertisementPrint.pending, state => {
				state.loading = true;
			})
			.addCase(editAdvertisementPrint.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editAdvertisementPrint.rejected, (state, action) => {});
		builder
			.addCase(insertAdvertisement.pending, state => {
				state.loading = true;
			})
			.addCase(insertAdvertisement.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertAdvertisement.rejected, (state, action) => {});
		builder
			.addCase(deleteAdvertisement.pending, state => {
				state.loading = true;
			})
			.addCase(deleteAdvertisement.fulfilled, (state, action) => {
				state.loading = false;
				state.results = state.results.filter(el => el.id !== action.payload);
			})
			.addCase(deleteAdvertisement.rejected, (state, action) => {});
		builder
			.addCase(getVisibilityRange.pending, state => {
				state.loadingVisibility = true;
			})
			.addCase(getVisibilityRange.fulfilled, (state, action) => {
				state.loadingVisibility = false;
				state.adsVisibilityResults = action.payload?.adsVisibilityResults ?? [];
			})
			.addCase(getVisibilityRange.rejected, (state, action) => {});
		builder
			.addCase(insertVisibilityRange.pending, state => {
				state.loadingVisibility = true;
			})
			.addCase(insertVisibilityRange.fulfilled, (state, action) => {
				state.loadingVisibility = false;
				state.adsVisibilityResults = [...state.adsVisibilityResults, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertVisibilityRange.rejected, (state, action) => {});
		builder
			.addCase(deleteVisibilityRange.pending, state => {
				state.loadingVisibility = true;
			})
			.addCase(deleteVisibilityRange.fulfilled, (state, action) => {
				state.loadingVisibility = false;
				state.adsVisibilityResults = state.adsVisibilityResults.filter(el => el.id !== action.payload?.ad_location_id);
			})
			.addCase(deleteVisibilityRange.rejected, (state, action) => {});
	},
});

export const advertisementsActions = {
	...advertisementsSlice.actions,
	getAdvertisements,
	insertAdvertisement,
	deleteAdvertisement,
	editAdvertisement,
	editAdvertisementPrint,
	insertVisibilityRange,
	deleteVisibilityRange,
	getVisibilityRange,
	getAdvertisementsLocations,
};

export default advertisementsSlice;
