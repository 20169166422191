import {useMemo, useRef, useState} from "react";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../components/primitives/Dialog";
import {Button} from "../../../components/primitives/Button";
import {Checkbox} from "../../../components/primitives/Checkbox";
import Select, {MultiValue, SingleValue} from "react-select";
import {NotificationEvent, NotificationSettings} from "../../../data-access/notifications/notifications";
import {Chevron, PlusSmall} from "../../../components/primitives/icons";
import toast from "react-hot-toast";
import {AUDIENCE_TYPES} from "../../../constants";
import {NOTIFICATIONS_TYPES_DISPLAY} from "../../../constants/notifications";

interface NotificationTypeModalProps {
	openDialog: boolean;
	isLoading: boolean;
	notificationsSettingsList?: (NotificationSettings & {label?: string})[];
	eventsList?: NotificationEvent[];
	onDismiss: () => void;
	onSubmit: (data: Pick<NotificationSettings, "label" | "enabled" | "audience" | "display" | "events">) => void;
	onDelete?: (id: string) => void;
	onEdit?: (data: NotificationSettings) => void;
}

const initialState: NotificationSettings = {
	label: "",
	notification_type: "",
	enabled: false,
	audience: null,
	display: "push",
};

export default function NotificationTypeModal({
	openDialog,
	isLoading,
	notificationsSettingsList,
	eventsList,
	onDismiss,
	onSubmit,
	onDelete,
	onEdit,
}: NotificationTypeModalProps) {
	const [data, setData] = useState<NotificationSettings>(initialState);
	const [showNew, setShowNew] = useState(false);
	const [selectedNotificationSetting, setSelectedNotificationSetting] = useState<NotificationSettings>();
	const [isDeleteNotificationFocused, setIsDeleteNotificationFocused] = useState(false);
	const notificationTypeRef = useRef<any>(null);

	const notificationsSettingOptions = useMemo(() => {
		if (!notificationsSettingsList) return [];
		return notificationsSettingsList.map(setting => ({
			label: setting?.label,
			value: setting.id,
		}));
	}, [notificationsSettingsList]);

	const eventsOptions = useMemo(() => {
		if (!eventsList) return [];
		return eventsList.map(event => ({
			label: event?.name,
			value: event.name,
		}));
	}, [eventsList]);

	const handleSubmit = () => {
		if (!data.label || data.label.trim() === "" || data.label.length < 3) {
			return toast.error("El nombre del tipo de notificación debe tener al menos 3 caracteres", {duration: 5000});
		}
		const variant = NOTIFICATIONS_TYPES_DISPLAY.find(el => el.value === data.display);
		if (!variant) return toast.error("Debes seleccionar una variante", {duration: 5000});
		if (variant.value === "in_app") return toast.error("La variante seleccionada no esta disponible", {duration: 5000});

		onSubmit?.({
			audience: data.audience,
			enabled: data.enabled,
			label: data.label as string,
			display: data.display,
			events: data.events,
		});
		setShowNew(false);
		setData(initialState);
	};

	const handleDismiss = () => {
		setData(initialState);
		setSelectedNotificationSetting(undefined);
		setShowNew(false);
		setIsDeleteNotificationFocused(false);
		onDismiss();
	};

	const handleSelectNotificationSetting = (newValue: SingleValue<{label: string; value: string | undefined}>) => {
		if (!newValue?.value) return;
		const selectedSetting = notificationsSettingsList?.find(setting => setting.id === newValue.value);
		if (!selectedSetting) return;
		setSelectedNotificationSetting(selectedSetting);
	};

	const handleSelectAudience = (newValue: MultiValue<{label: string; value: string | null}>, isCreatingAction?: boolean) => {
		const audienceList = newValue?.map(audience => audience.value) ?? null;
		return isCreatingAction
			? setData(prev => ({...prev, audience: audienceList.length > 0 ? audienceList : null} as NotificationSettings))
			: setSelectedNotificationSetting(prev => ({...prev, audience: audienceList.length > 0 ? audienceList : null} as NotificationSettings));
	};

	const handleSelectDisplay = (newValue: SingleValue<{label: string; value: "push" | "email" | "in_app"}>) => {
		if (!newValue?.value) return;
		setData(prev => ({...prev, display: newValue.value}));
	};

	const handleSelectEvent = (newValue: MultiValue<{label: string; value: string}>, isCreatingAction?: boolean) => {
		const eventsList = newValue?.map(event => event.value) ?? null;
		return isCreatingAction
			? setData(prev => ({...prev, events: eventsList} as NotificationSettings))
			: setSelectedNotificationSetting(prev => ({...prev, events: eventsList} as NotificationSettings));
	};
	const typesList = () => (
		<div className="flex justify-between gap-2">
			<div className="relative flex h-fit w-1/2 flex-col gap-6 rounded-md border p-2">
				<span className="absolute -top-3 left-3 bg-neutral-700 px-2 text-sm text-white">Lista de tipos de notificaciones</span>
				<Select
					ref={ref => {
						notificationTypeRef.current = ref;
					}}
					placeholder="Tipo de notificación"
					options={notificationsSettingOptions}
					onChange={handleSelectNotificationSetting}
					isDisabled={!notificationsSettingsList || isLoading}
					isLoading={isLoading}
				/>
				<div className="flex items-center justify-between gap-3">
					<div className="flex items-center gap-3">
						<Checkbox
							checked={selectedNotificationSetting?.enabled ?? false}
							disabled={!selectedNotificationSetting || isLoading}
							onCheckedChange={checked => {
								setSelectedNotificationSetting(prev => (prev ? {...prev, enabled: checked as boolean} : undefined));
								selectedNotificationSetting && onEdit?.({...selectedNotificationSetting, enabled: checked as boolean});
							}}
						/>
						<p className="text-white">Habilitado</p>
						{isLoading && <div className="h-3 w-3 animate-spin rounded-full border-b-2 border-slate-400"></div>}
					</div>
					{selectedNotificationSetting?.display && (
						<p className="text-white">
							Variante: {NOTIFICATIONS_TYPES_DISPLAY.find(el => el.value === selectedNotificationSetting?.display)?.label ?? ""}
						</p>
					)}
				</div>
			</div>
			<div className="relative flex w-1/2 flex-col gap-3 rounded-md border p-2">
				<span className="absolute -top-3 left-3 bg-neutral-700 px-2 text-sm text-white">Audiencia</span>
				<Select
					placeholder={"Seleccionar audiencia"}
					options={AUDIENCE_TYPES}
					onChange={value => handleSelectAudience(value, false)}
					isDisabled={!selectedNotificationSetting?.notification_type || isLoading}
					isLoading={isLoading}
					value={AUDIENCE_TYPES.filter(audience => selectedNotificationSetting?.audience?.some(aud => aud === audience.value))}
					isMulti
				/>
				<Button
					className="self-start bg-black text-white"
					size="sm"
					variant="outline"
					onClick={() =>
						selectedNotificationSetting &&
						onEdit?.({
							...selectedNotificationSetting,
							audience: selectedNotificationSetting.audience?.length ? selectedNotificationSetting.audience : null,
						})
					}
					disabled={!selectedNotificationSetting?.notification_type || isLoading}
				>
					Aplicar filtro por audiencias
				</Button>
			</div>
		</div>
	);

	const newNotificationForm = () => (
		<>
			<Button onClick={() => setShowNew(false)}>
				<Chevron className="h-6 w-6 -rotate-90 stroke-white" />
				<span className="text-white">Ir atrás</span>
			</Button>
			<div className="relative flex flex-col gap-4 rounded-md border px-2 pb-2 pt-4 transition-transform">
				<div className="absolute -top-3 left-2 bg-neutral-700 px-2 text-sm text-white">Nuevo tipo de notificación</div>
				<div className="flex justify-between gap-2">
					<div className="flex w-1/2 flex-col gap-3">
						<input
							className="rounded bg-black px-3 py-2 text-white"
							type="text"
							name="label"
							onChange={e => setData(prev => ({...prev, label: e.target.value}))}
							placeholder="Nombre del tipo de notificación..."
						/>
						<Select
							value={NOTIFICATIONS_TYPES_DISPLAY.find(n => n.value === data.display)}
							placeholder="Variante"
							options={NOTIFICATIONS_TYPES_DISPLAY.filter(el => !["in_app", "email"].includes(el.value))}
							onChange={handleSelectDisplay}
							isDisabled={(data.label && data?.label?.trim().length < 3) || isLoading}
							isLoading={isLoading}
						/>
					</div>
					<div className="flex w-1/2 flex-col gap-3">
						<Select
							className="max-w-[472px]"
							placeholder="Seleccionar audiencia"
							options={AUDIENCE_TYPES}
							onChange={value => handleSelectAudience(value, true)}
							isDisabled={(data.label && data?.label?.trim().length < 3) || isLoading}
							isLoading={isLoading}
							isMulti
						/>
						<Select
							placeholder="Seleccionar eventos"
							options={eventsOptions}
							onChange={value => handleSelectEvent(value, true)}
							isMulti
						/>
					</div>
				</div>
				<div className="flex items-center gap-3">
					<Checkbox checked={data.enabled} onCheckedChange={checked => setData(prev => ({...prev, enabled: checked as boolean}))} />
					<p className="text-white">Habilitar nuevo tipo de notificación</p>
				</div>
				<Button className="w-32 self-end bg-black text-white" size="sm" variant="blueBtn" onClick={handleSubmit} disabled={isLoading}>
					Agregar
				</Button>
			</div>
		</>
	);

	return (
		<Dialog open={openDialog} onOpenChange={handleDismiss} modal>
			<DialogContent className="bg-neutral-700 sm:max-w-5xl">
				<DialogHeader>
					<DialogTitle className="border-b border-border pb-2 font-medium text-white">Tipos de notificaciones</DialogTitle>
				</DialogHeader>
				<div className="container relative space-y-5 px-0.5 py-2">
					<div
						className={`${
							!isDeleteNotificationFocused && "translate-y-20 scale-0"
						} absolute bottom-14 right-0 z-[9999] flex max-w-xs items-center gap-3 rounded-md bg-slate-800 p-2 transition-transform`}
					>
						<span className="text-3xl text-amber-400">⚠️</span>
						<div className="flex max-h-[136px] flex-col items-center gap-1">
							<p className="text-sm text-white">
								Al eliminar el tipo de notificacion, borrara el registro y seran desactivadas las notificaciones del tipo:"
								<span className="text-sm text-amber-600">
									{notificationsSettingsList?.find(setting => setting.id === selectedNotificationSetting?.id)?.label ??
										selectedNotificationSetting?.notification_type}
								</span>
								"
							</p>
							<div className="flex gap-3 self-end">
								<Button className="h-7 w-16 text-white" variant="outline" onClick={() => setIsDeleteNotificationFocused(false)}>
									No
								</Button>
								<Button
									disabled={!selectedNotificationSetting || isLoading}
									className="h-7 w-16 text-white"
									variant="destructive"
									onClick={() => {
										selectedNotificationSetting?.id && onDelete?.(selectedNotificationSetting?.id);
										notificationTypeRef.current?.clearValue();
										setSelectedNotificationSetting(undefined);
										setIsDeleteNotificationFocused(false);
									}}
								>
									Si
								</Button>
							</div>
						</div>
					</div>
					{!showNew ? typesList() : newNotificationForm()}
					<div className="flex gap-2">
						{!showNew && (
							<div className="relative flex w-1/2 flex-col gap-3 rounded-md border p-2">
								<span className="absolute -top-3 left-3 bg-neutral-700 px-2 text-sm text-white">Eventos</span>
								<Select
									isDisabled={!selectedNotificationSetting?.notification_type || isLoading}
									placeholder="Seleccionar eventos"
									options={eventsOptions}
									onChange={value => handleSelectEvent(value, false)}
									value={eventsOptions.filter(event => selectedNotificationSetting?.events?.some(ev => ev === event.value))}
									isMulti
								/>
								<Button
									className="self-start bg-black text-white"
									size="sm"
									variant="outline"
									onClick={() =>
										selectedNotificationSetting &&
										onEdit?.({
											...selectedNotificationSetting,
											audience: selectedNotificationSetting.events?.length ? selectedNotificationSetting.events : null,
										})
									}
									disabled={!selectedNotificationSetting?.notification_type || isLoading}
								>
									Aplicar eventos
								</Button>
							</div>
						)}
						{!showNew && (
							<div className="flex w-1/2 items-end justify-end gap-2">
								<Button className="w-fit bg-black text-white" size="sm" variant="outline" onClick={() => setShowNew(true)}>
									<PlusSmall className="mr-1 h-6 w-6" />
									Nuevo tipo de notificación
								</Button>
								<Button
									disabled={!selectedNotificationSetting || isDeleteNotificationFocused || isLoading}
									className="w-56 text-white"
									size="sm"
									variant="destructive"
									onClick={() => setIsDeleteNotificationFocused(prev => !prev)}
								>
									Eliminar tipo de notificación
								</Button>
							</div>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
