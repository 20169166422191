import {useState, useEffect} from "react";
import {auth} from "../../../firebase";
import {Modal} from "../../../components/primitives/Modal";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {coinProductsActions} from "../../../_store/features/products/coin-products-slice";
import {Spinner} from "../../../components/primitives/icons/Spinner";
import {IFormInputs} from "../CoinEdit";

interface CoinGoogleModalProps {
	onDismiss: () => void;
	onSuccess: () => void;
	open: boolean;
	data?: IFormInputs;
}

export function CoinGoogleModal({open, onDismiss, data, onSuccess}: CoinGoogleModalProps) {
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(state => state.coinProducts.loading);
	const [done, SetDone] = useState(false);
	const error = useAppSelector(state => state.coinProducts.error);
	const [isSubmitted, SetIsSubmitted] = useState(false);

	useEffect(() => {
		if (!done || !isSubmitted) return;
		dispatch(coinProductsActions.setSuccess(false));
		handleSuccess();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [done]);

	useEffect(() => {
		if (!isLoading && isSubmitted) {
			if (error) {
				alert("ERROR: " + error);
				handleDismiss();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	async function handleSubmit() {
		if (!auth.currentUser) return;
		if (data) {
			SetIsSubmitted(true);
			dispatch(coinProductsActions.insertCoinGoogleProducts({productid: data?.id}));
			dispatch(coinProductsActions.editCoinProducts({...data, productid: data.id})).then(() => {
				SetDone(true);
			});
		}
	}

	function handleDismiss() {
		SetDone(false);
		onDismiss?.();
	}

	function handleSuccess() {
		SetDone(false);
		onSuccess?.();
	}

	return (
		<Modal title="Sincronizar con Google" open={open} onDismiss={handleDismiss} big>
			<div className="mb-2 text-xl text-white">Datos Producto:</div>
			<div className="container px-0.5 pr-4 md:w-full">
				<div className="mb-6">
					<div className="mb-3 flex w-full">
						<div className="w-1/3 font-bold text-white">Nombre:</div>
						<div className="w-2/3 text-white">{data?.name}</div>
					</div>
					<div className="mb-3 flex w-full">
						<div className="w-1/3 font-bold text-white">Desc:</div>
						<div className="w-2/3 text-white">{data?.description}</div>
					</div>
					<div className="mb-3 flex w-full">
						<div className="w-1/3 font-bold text-white">Precio:</div>
						<div className="w-2/3 text-white">{data ? "$" + (data?.google_price / 100).toFixed(2) : "$0.00"}</div>
					</div>
					{/* <div className="ml-3 w-1/2">
						<p className="my-4 text-white">Tipo: {data.signed ? "Firmado" : "Público"}</p>
					</div> */}
				</div>

				<div className="flex">
					<button
						className="col-start-2 mr-4 flex items-center justify-center rounded bg-neutral-50 px-4 py-2 uppercase text-black transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
						type="submit"
						onClick={handleSubmit}
						disabled={isLoading}
					>
						{isLoading ? <Spinner /> : "Sincronizar"}
					</button>
					<button
						className="col-start-2 flex items-center justify-center rounded bg-neutral-50 px-4 py-2 uppercase text-black transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
						type="reset"
						onClick={handleDismiss}
					>
						Cancelar
					</button>
				</div>
			</div>
		</Modal>
	);
}
