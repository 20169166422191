import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, ChartOptions, LinearScale} from "chart.js";
import "chartjs-adapter-date-fns";
import {addDays, differenceInDays, format, isSameDay, parse} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useDashboardByNameQuery} from "../../../../../_store/features/dashboard/hooks";
import {useAppDispatch} from "../../../../../_store/hooks";
import {oneMonth} from "../../../../../../utils/barGraphTransformData";
import {Spinner} from "../../../../../components/primitives/icons";
import {DateRange} from "react-day-picker";
import {es} from "date-fns/locale";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

export const options: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			onClick: () => {},
		},
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					if (tooltipItems[0].label.includes("T")) {
						return format(new Date(tooltipItems[0].label), "EE, dd MMMM", {locale: es});
					}
				},
			},
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d", {locale: es}).toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
	},
};

interface SerieIncomeAndProfitProps {
	dates: DateRange | undefined;
}

interface MonthlyData {
	[key: string]: {
		date: string;
		count: number;
	};
}

export default function FreeSubscriptionsChart({dates}: SerieIncomeAndProfitProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; count: number}[]>>(null);
	const {data: statistics, isLoading} = useDashboardByNameQuery("freeSubscriptionsByUsers");
	const [showByMonths, setShowByMonths] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const durationInMilliseconds = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(durationInMilliseconds > oneMonth);
	}, [dates, dispatch]);

	const totalFreeSubscriptions = useMemo(() => statistics?.reduce((acc, curr) => acc + curr.count, 0) ?? 0, [statistics]);

	const data: ChartData<"bar", {date: string; count: number}[]> = useMemo(() => {
		const startDate = new Date(dates?.from ?? 0);
		const endDate = new Date(dates?.to ?? Date.now());
		const diff_days = differenceInDays(endDate, startDate) + 1;

		//Transforms the data returned from big query
		const statisticsData =
			statistics?.map(({date, count}) => ({
				date: new Date(date).toISOString(),
				count: count,
			})) ?? [];

		// Group elements by day in statisticsData
		const statisticsDataGrouped = statisticsData.reduce((acc: {date: string; count: number}[], curr) => {
			const existingDayData = acc.find(el => isSameDay(new Date(el.date), new Date(curr.date)));
			if (existingDayData) {
				existingDayData.count += curr.count;
			} else {
				acc.push(curr);
			}
			return acc;
		}, []);

		//Creates a template data to be filled by the statistics
		const fillerData = Array(diff_days)
			.fill(0)
			.map((_, idx) => ({date: addDays(endDate, -idx).toISOString(), count: 0}));

		//Combined data from statistics and filled.
		const charData = fillerData
			.map(fillEl => {
				const el = statisticsDataGrouped.find(statEl => {
					return isSameDay(new Date(fillEl.date), new Date(statEl.date));
				});

				if (el) return el;

				return fillEl;
			})
			.reverse();

		const monthlyProfitData = statisticsDataGrouped.reduce((acc: MonthlyData, dataPoint) => {
			const date = new Date(dataPoint.date);
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const monthString = month < 10 ? `0${month}` : `${month}`;
			const key = `${year}-${monthString}`;

			if (!acc[key]) {
				acc[key] = {
					date: `${year}-${monthString}`,
					count: 0,
				};
			}

			acc[key].count += dataPoint.count;

			return acc;
		}, {});

		const currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1;
			const monthString = month < 10 ? `0${month}` : `${month}`;
			const key = `${year}-${monthString}`;

			if (!monthlyProfitData[key]) {
				monthlyProfitData[key] = {
					date: `${year}-${monthString}`,
					count: 0,
				};
			}

			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		const monthlyDataArray = Object.values(monthlyProfitData);

		monthlyDataArray.sort((a, b) => {
			const dateA = parse(a.date, "yyyy-MM", new Date());
			const dateB = parse(b.date, "yyyy-MM", new Date());

			return dateA.getTime() - dateB.getTime();
		});

		const selectedData = showByMonths ? monthlyDataArray : charData;

		return {
			datasets: [
				{
					label: "Suscripciones free asignadas",

					data: selectedData,
					backgroundColor: "#ff6384",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "count",
					},
				},
			],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, statistics, showByMonths]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Suscripciones free.</h3>
					<h3 className="text-base font-semibold leading-none tracking-tight">
						Total de suscripciones asignadas: {totalFreeSubscriptions}
					</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Bar
							height={350}
							ref={chartRef}
							options={{
								...options,
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date: Date = new Date(this.getLabelForValue(tickValue as any));
												const monthlyDate = new Date(date.getFullYear(), date.getMonth() + 1);

												// Format the date based on the available information
												const formattedDate = showByMonths
													? format(monthlyDate, "MMM - yyyy", {locale: es}).toLowerCase()
													: format(date, "MMM d", {locale: es}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
										stacked: true,
									},
									y: {
										ticks: {
											precision: 1,
										},
									},
								},
							}}
							data={data}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
