import {createSlice} from "@reduxjs/toolkit";
import {vendorStateInterface} from "./types";
import {deleteCustomer, fetchCustomer, fetchCustomers, associateCustomer, updateCustomer} from "./thunks";

const initialState: vendorStateInterface = {
	customers: [],
	loading: false,
	error: null,
	totalResults: 0,
	customer: null,
};

const vendorSlice = createSlice({
	name: "vendor",
	initialState,
	reducers: {
		resetToInitialState: () => initialState,
	},
	extraReducers(builder) {
		builder.addCase(fetchCustomers.pending, state => {
			state.loading = true;
		});
		builder.addCase(fetchCustomers.fulfilled, (state, action) => {
			state.loading = false;
			state.customers = action.payload?.results || [];
			state.totalResults = action.payload?.total || 0;
		});
		builder.addCase(fetchCustomers.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message || "An error occurred";
		});

		builder.addCase(fetchCustomer.pending, state => {
			state.loading = true;
			state.customer = null;
		});
		builder.addCase(fetchCustomer.fulfilled, (state, action) => {
			state.customer = action.payload ?? null;
			state.loading = false;
		});
		builder.addCase(fetchCustomer.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message || "An error occurred";
		});
	},
});

export const vendorActions = {
	...vendorSlice.actions,
	fetchCustomers,
	fetchCustomer,
	deleteCustomer,
	associateCustomer,
	updateCustomer,
};

export default vendorSlice;
