import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../../../../../components/primitives/Select";
import {MEMBERSHIP_TYPES} from "../../../../../constants";

interface MembershipTypeSelectProps {
	selectedMembership: string;
	onMembershipChange: (v: string) => void;
}

export default function MembershipTypeSelect({selectedMembership, onMembershipChange}: MembershipTypeSelectProps) {
	return (
		<Select value={selectedMembership} onValueChange={onMembershipChange}>
			<SelectTrigger className="w-[180px]">
				<SelectValue placeholder="Selecciona un tipo de membresia" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Membresias</SelectLabel>
					{MEMBERSHIP_TYPES.map(membership => (
						<SelectItem value={membership.value.toString()}>{membership.label}</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}
