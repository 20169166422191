import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTable} from "../../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {MEMBERSHIP_TYPES, RENTALS_TYPES} from "../../../constants";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {DateRange} from "react-day-picker";
import {endOfToday, formatISO9075, subMonths} from "date-fns";
import {dashboardActions} from "../../../_store/features/dashboard/dashboard-slice";
import {useDashboardByNameQuery} from "../../../_store/features/dashboard/hooks";
import {DatePickerWithRange} from "../../../components/blocks/DateRangePicker";
import Select, {ActionMeta, SingleValue} from "react-select";
import {selectStyles} from "../../../../utils/SelectStyles";
import {seriesActions} from "../../../_store/features/series/series-slice";
import {Button} from "../../../components/primitives/Button";
import {useNavigate} from "react-router-dom";
import {coinProductsActions} from "../../../_store/features/products/coin-products-slice";
import {formattedDayAndHour} from "../../../../utils/formattedDayAndHour";
import {SelectOptionType} from "../../../../utils/globalTypes";
import {CorporateProfitResult} from "../../../data-access/dashboard/types";
import DownloadButton from "../../../components/blocks/DownlaodButton";

const columnHelper = createColumnHelper<CorporateProfitResult>();

const selectPurchases: SelectOptionType[] = [
	{label: "Todos", value: "all"},
	{label: "Suscripciones", value: "subscriptions"},
	{label: "Rentas", value: "rentals"},
	{label: "Membresías", value: "memberships"},
	{label: "Coins", value: "coins"},
	{label: "Cupos", value: "subscriptions-slots"},
];

const selectMembershipType: SelectOptionType[] = [
	{label: "Todas", value: "all"},
	{label: "Miembro", value: "1"},
	{label: "Miembro Vip", value: "2"},
	{label: "Upgrade", value: "3"},
];

const selectRentalsType: SelectOptionType[] = [
	{label: "Todas", value: "all"},
	{label: RENTALS_TYPES[0].label, value: RENTALS_TYPES[0].value.toString()},
	{label: RENTALS_TYPES[1].label, value: RENTALS_TYPES[1].value.toString()},
	{label: RENTALS_TYPES[2].label, value: RENTALS_TYPES[2].value.toString()},
];

const selectSubscriptionsType: SelectOptionType[] = [
	{label: "Todas", value: "all"},
	{label: "Mensual", value: "0"},
	{label: "Anual", value: "1"},
	{label: "Mensual (Renovación)", value: "2"},
	{label: "Anual (Renovación)", value: "3"},
	{label: "Mensual (Sin renovación)", value: "4"},
	{label: "Anual (Sin Renovación)", value: "5"},
	{label: "Todas (Renovación)", value: "6"},
	{label: "Todas (Sin renovación)", value: "7"},
];

const selectStore: SelectOptionType[] = [
	{label: "Todas", value: "all"},
	{label: "Google", value: "google"},
	{label: "Stripe", value: "stripe"},
	{label: "Apple", value: "apple"},
	{label: "Prospay", value: "prospay"},
];

function PurchasesDetail() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const series = useAppSelector(state => state.series.results);
	const coins = useAppSelector(state => state.coinProducts.results);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const selectInputRefs = useRef<any[]>([]);
	const {isLoading: tableLoading, data: tableData} = useDashboardByNameQuery("profitCorporate");
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [serieId, setSerieId] = useState<string | undefined>();
	const [coinId, setCoinId] = useState<string | undefined>();
	const [onlyGet, setOnlyGet] = useState<"memberships" | "coins" | "subscriptions" | "subscriptions-slots" | "rentals" | "all">("all");
	const [membershipType, setMembershipType] = useState<string>();
	const [rentalsType, setRentalsType] = useState<string>();
	const [subscriptionType, setSubscriptionType] = useState<number>();
	const [isRenewal, setIsRenewal] = useState<boolean | undefined>();

	const [storePlatform, setStorePlatform] = useState<"stripe" | "apple" | "google" | undefined>();

	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = endOfToday();
		const from = subMonths(to, 1);
		return {
			from,
			to,
		};
	});

	const seriesOptions: SelectOptionType[] = useMemo(() => {
		const options = series.map(serie => ({label: serie.title, value: serie.id}));
		options.unshift({label: "Todo", value: ""});
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [series.length]);

	const coinsOptions: SelectOptionType[] = useMemo(() => {
		const options = coins.map(coin => ({label: coin.name, value: coin.id}));
		options.unshift({label: "Todo", value: ""});
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coins.length]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page: 0, page_size: 99999999}}));
		dispatch(coinProductsActions.getCoinProducts({page: 0, page_size: 99999999}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		if (!pagination.pageSize) return;
		dispatch(
			dashboardActions.getProfitCorporateTransactions({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				seriesid: serieId,
				only_get: onlyGet,
				membership_type: membershipType,
				rental_type: rentalsType,
				subscription_type: subscriptionType,
				product_coins_id: coinId,
				platform: storePlatform,
				subscription_renewal: isRenewal,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, pagination, membershipType, subscriptionType, storePlatform, rentalsType]);

	const handleDateRangeChange = useCallback(
		(s: number, e: number, selectedDate?: DateRange) => {
			setPagination({pageIndex: 0, pageSize: pagination.pageSize});
			setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
		},
		[pagination.pageSize],
	);

	const resetSelectors = useCallback(() => {
		setCoinId(undefined);
		setOnlyGet("all");
		setSerieId(undefined);
		setMembershipType(undefined);
		setRentalsType(undefined);
		setIsRenewal(undefined);
	}, []);

	function handleSelectContent(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			if (newValue.value !== "") {
				setSerieId(newValue.value);
			} else {
				setSerieId(undefined);
				selectInputRefs.current[2].clearValue();
			}
			setPagination(prev => {
				return {
					...prev,
					pageIndex: 0,
				};
			});
		}
	}

	function handleSelectPurchase(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			selectInputRefs.current.forEach((ref, idx) => {
				if (idx === 0) return;
				ref.clearValue();
			});
			setCoinId(undefined);
			setSerieId(undefined);
			setMembershipType(undefined);
			setRentalsType(undefined);
			setSubscriptionType(undefined);
			setIsRenewal(undefined);
			if (newValue.value !== "all") {
				setOnlyGet(newValue.value as "memberships" | "coins" | "subscriptions" | "subscriptions-slots");
			} else {
				resetSelectors();
				selectInputRefs.current.forEach(ref => {
					ref.clearValue();
				});
			}
			setPagination(prev => {
				return {
					...prev,
					pageIndex: 0,
				};
			});
		}
	}

	function handleSelectStore(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			if (newValue.value !== "all") {
				setStorePlatform(newValue.value as "apple" | "stripe" | "google");
			} else {
				setStorePlatform(undefined);
			}
			setPagination(prev => {
				return {
					...prev,
					pageIndex: 0,
				};
			});
		}
	}

	function handleSelectPurchaseType(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (!newValue) return;
		if (newValue.value !== "all") {
			if (onlyGet === "subscriptions" || onlyGet === "subscriptions-slots") {
				setIsRenewal(undefined);
				switch (Number(newValue.value)) {
					case 2:
						setIsRenewal(true);
						setSubscriptionType(0);
						break;
					case 3:
						setIsRenewal(true);
						setSubscriptionType(1);
						break;
					case 4:
						setIsRenewal(false);
						setSubscriptionType(0);
						break;
					case 5:
						setIsRenewal(false);
						setSubscriptionType(1);
						break;
					case 6:
						setIsRenewal(true);
						setSubscriptionType(undefined);
						break;
					case 7:
						setIsRenewal(false);
						setSubscriptionType(undefined);
						break;
					default:
						setSubscriptionType(Number(newValue.value));
						break;
				}
			}
			if (onlyGet === "rentals") {
				setRentalsType(newValue.value);
			}
			if (onlyGet === "memberships") {
				setMembershipType(newValue.value);
			}
			if (onlyGet === "coins") {
				setCoinId(newValue.value);
			}
		} else {
			setSubscriptionType(undefined);
			setIsRenewal(undefined);
			setMembershipType(undefined);
			setRentalsType(undefined);
			selectInputRefs.current.forEach((ref, idx) => {
				if (idx === 0) return;
				ref.clearValue();
			});
		}
		!newValue.value && onlyGet === "coins" && setCoinId(undefined);
		setPagination(prev => {
			return {
				...prev,
				pageIndex: 0,
			};
		});
	}

	const columns = useMemo(() => {
		let colWidth = Math.floor(tableRef?.getBoundingClientRect().width ?? 0);

		return [
			columnHelper.accessor("user_name", {
				id: "Usuario",
				header: ({column}) => <DataTableColumnHeader title="Usuario" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.user_name}</span>
					</div>
				),
				size: colWidth * 0.13,
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha",
				header: ({column}) => <DataTableColumnHeader title="Fecha" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: colWidth * 0.14,
			}),
			columnHelper.accessor("membership_type", {
				id: "Compra",
				header: ({column}) => <DataTableColumnHeader title="Compra" column={column} />,
				cell: info => {
					const {original} = info.row;
					const getPurchaseName = () => {
						if (original?.rental_type) {
							const rentalType = RENTALS_TYPES.find(type => type.value === info.row.original.rental_type);
							return rentalType?.label;
						}
						if (original?.product_subscription_name) {
							return original.product_subscription_name;
						}
						if (original?.product_series_name) {
							const membershipType = MEMBERSHIP_TYPES.find(type => type.value === info.row.original.membership_type);
							return membershipType?.label;
						}
						if (original?.rental_type !== undefined) {
							const rentaltype = RENTALS_TYPES.find(type => type.value === info.row.original.rental_type);
							return rentaltype?.label;
						}
						return original.product_coins_name;
					};

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{getPurchaseName()}</span>
						</div>
					);
				},
				size: colWidth * 0.18,
			}),
			columnHelper.accessor("renewed", {
				id: "Renovación",
				header: ({column}) => <DataTableColumnHeader title="Renovable" column={column} />,
				cell: info => {
					const {original} = info.row;

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{original.renewed && "Renovación"}</span>
						</div>
					);
				},
				size: colWidth * 0.08,
			}),
			columnHelper.accessor("price", {
				id: "Precio",
				header: ({column}) => <DataTableColumnHeader title="Precio" column={column} />,
				cell: info => {
					const price = ((info.row.original.price ?? info.row.original.price_slot ?? 0) / 100).toFixed(2);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {price}</span>
						</div>
					);
				},
				size: colWidth * 0.07,
			}),
			columnHelper.accessor("fee", {
				id: "Fee",
				header: ({column}) => <DataTableColumnHeader title="Fee" column={column} />,
				cell: info => {
					const fee = ((info.row.original?.fee ?? 0) / 100).toFixed(2);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {fee}</span>
						</div>
					);
				},
				size: colWidth * 0.08,
			}),
			columnHelper.accessor("price", {
				id: "Ingreso Neto",
				header: ({column}) => <DataTableColumnHeader title="Ingreso Neto" column={column} />,
				cell: info => {
					const netWorth = (((info.row.original.price ?? info.row.original.price_slot ?? 0) - (info.row.original?.fee ?? 0)) / 100).toFixed(
						2,
					);
					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {netWorth}</span>
						</div>
					);
				},
				size: colWidth * 0.11,
			}),
			columnHelper.accessor("platform", {
				id: "Tienda",
				header: ({column}) => <DataTableColumnHeader title="Tienda" column={column} />,
				cell: info => {
					const platform = info.row.original.platform;
					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{platform}</span>
						</div>
					);
				},
				size: colWidth * 0.07,
			}),
			columnHelper.accessor("title", {
				id: "Título",
				header: ({column}) => <DataTableColumnHeader title="Serie" column={column} />,
				cell: info => {
					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.title || "-"}</span>
						</div>
					);
				},
				size: colWidth * 0.13,
			}),
		];
	}, [tableRef]);

	const purchaseTypeOptions = useMemo(() => {
		switch (onlyGet) {
			case "coins":
				return coinsOptions;
			case "memberships":
				return selectMembershipType;
			case "subscriptions":
				return selectSubscriptionsType;
			case "rentals":
				return selectRentalsType;
			case "subscriptions-slots":
				return selectSubscriptionsType;
			default:
				return undefined;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onlyGet]);

	return (
		<div className="flex h-screen flex-col overflow-hidden">
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Compras Realizadas</h2>
				<DownloadButton text="Retensión de usuarios" link="/export_retention_percent_data" />
			</div>
			<div className="flex h-full flex-col">
				<div className="mb-1 mt-3 flex flex-row justify-between">
					<div className="flex items-center">
						<DatePickerWithRange
							onDateRangeChange={handleDateRangeChange}
							disabled={{before: new Date("2023-10-01T00:00:00-05:00"), after: new Date()}}
							date={dates}
							className="left-0 w-80"
						/>
						<Select
							ref={ref => {
								selectInputRefs.current[0] = ref;
							}}
							className="basic-select mx-4 min-w-[150px] text-sm"
							styles={selectStyles}
							options={selectStore}
							onChange={handleSelectStore}
							placeholder="Tienda"
						/>
					</div>
					<div className="mb-1 mt-3 flex flex-row">
						<Select
							ref={ref => {
								selectInputRefs.current[0] = ref;
							}}
							className="basic-select mx-4 w-full min-w-[200px] text-sm"
							styles={selectStyles}
							options={selectPurchases}
							onChange={handleSelectPurchase}
							placeholder="Compra"
						/>

						<Select
							ref={ref => {
								selectInputRefs.current[1] = ref;
							}}
							className="basic-select mx-4 w-full min-w-[200px] text-sm"
							styles={selectStyles}
							options={purchaseTypeOptions}
							onChange={handleSelectPurchaseType}
							placeholder="Tipo de compra"
							isDisabled={!purchaseTypeOptions}
						/>

						<Select
							ref={ref => {
								selectInputRefs.current[2] = ref;
							}}
							className="basic-select mx-4 w-full min-w-[210px] text-sm"
							styles={selectStyles}
							isSearchable={true}
							options={seriesOptions}
							classNamePrefix="select"
							onChange={handleSelectContent}
							placeholder="Contenido"
							isDisabled={onlyGet === "subscriptions" || onlyGet === "subscriptions-slots" || onlyGet === "coins" || onlyGet === "all"}
						/>
					</div>
				</div>

				<div className="flex h-full grow-0 flex-col px-8 pb-4">
					<div className="flex flex-row">
						<h3 className="p-2 font-semibold">Total de resultados: {tableData?.totalResults}</h3>
						<h3 className="p-2 font-semibold">
							Total de ganancias: ${tableData?._total_amount ? (tableData?._total_amount * 0.01).toFixed(2) : 0}
						</h3>
						<h3 className="p-2 font-semibold">Total de usuarios que han comprado: {tableData?.usersByPayments ?? 0}</h3>
					</div>
					<DataTable
						ref={ref => setTableRef(ref)}
						columns={columns}
						dataset={tableData?.results || []}
						pageCount={Math.ceil((tableData?.totalResults || 0) / pagination.pageSize)}
						pagination={pagination}
						onPaginationChange={setPagination}
						withDynamicPageSize
						rowHeight={45}
						showPagination={false}
						loading={tableLoading}
					/>
				</div>

				<div className="flex flex-row justify-end px-4 pb-4">
					<Button
						className="mr-2 w-32"
						size="sm"
						variant="outline"
						onClick={() => {
							navigate(-1);
						}}
					>
						Cancelar
					</Button>
				</div>
			</div>
		</div>
	);
}

export default PurchasesDetail;
