import {Combobox, Transition} from "@headlessui/react";
import {useState, Fragment} from "react";
import {Serie} from "../../../../../../_store/features/series/series-slice";
import {Chevron, PlusSmall} from "../../../../../../components/primitives/icons";

interface SeriesListComboboxProps {
	data: Serie[];
	onAdd: (data: Serie) => void;
}
export const SeriesListCombobox = ({data, onAdd}: SeriesListComboboxProps) => {
	const [selectedSerie, setSelectedSerie] = useState<Serie | null>(null);
	const [query, setQuery] = useState("");

	const filteredPeople =
		query === "" ? data : data.filter(serie => serie.title.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")));

	return (
		<div className="flex">
			<Combobox value={selectedSerie} onChange={setSelectedSerie} nullable>
				<div className="relative">
					<div className="relative w-full cursor-default overflow-hidden rounded-lg rounded-r-none border-2 border-gray-300 bg-white text-left focus:outline-none sm:text-sm">
						<Combobox.Input
							className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 outline-none focus:ring-0"
							displayValue={(serie: Serie) => serie?.title}
							onChange={event => setQuery(event.target.value)}
							placeholder="Inserta titulo de una serie"
						/>
						<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
							<Chevron className="h-5 w-5 text-gray-400" aria-hidden="true" />
						</Combobox.Button>
					</div>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
						afterLeave={() => setQuery("")}
					>
						<Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{filteredPeople.length === 0 && query !== "" ? (
								<div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div>
							) : (
								filteredPeople.map(person => (
									<Combobox.Option
										key={person.id}
										className={({active}) =>
											`relative cursor-default select-none px-4 py-2 ${active ? "bg-teal-600 text-white" : "text-gray-900"}`
										}
										value={person}
									>
										{({selected}) => (
											<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{person.title}</span>
										)}
									</Combobox.Option>
								))
							)}
						</Combobox.Options>
					</Transition>
				</div>
			</Combobox>
			<button
				className="rounded-r-lg bg-gray-300 px-2 hover:bg-gray-200 active:bg-gray-100"
				onClick={() => {
					if (!selectedSerie) {
						return;
					}
					onAdd(selectedSerie);
					setSelectedSerie(null);
					setQuery("");
				}}
			>
				<PlusSmall />
			</button>
		</div>
	);
};
