import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "../../hooks";
import {auth} from "../../../firebase";
import {getPartnerGeneralMuxIncomeAndProfit} from "../../../data-access/dashboard-partner/statistics";
import {
	getPartnerGeneralIncomeAndProfitParams,
	GetPartnerGeneralIncomeAndProfitResponseWithColors,
} from "../../../data-access/dashboard-partner/types";
import {RootState} from "../../store";
import {CONTENT, GROUPING} from "../../../constants";

type RequestState = "pending" | "fulfilled" | "rejected";
export type DashboardPartnerQueries = "getPartnerGeneralIncomeAndProfit";

export type DataByName = {
	getPartnerGeneralIncomeAndProfit: GetPartnerGeneralIncomeAndProfitResponseWithColors;
};

export interface dashboardPartnerInterface {
	loading: boolean;
	selectedSerieId: string | undefined;
	selectedContentId: string | undefined;
	statusByName: Record<DashboardPartnerQueries, RequestState | undefined>;
	dataByName: {
		[k in keyof DataByName]: DataByName[k] | undefined;
	};
	range: {
		from: string;
		to: string;
	};
	grouping: GROUPING;
}

const initialState: dashboardPartnerInterface = {
	loading: false,
	selectedSerieId: undefined,
	selectedContentId: undefined,
	statusByName: {
		getPartnerGeneralIncomeAndProfit: undefined,
	},
	dataByName: {
		getPartnerGeneralIncomeAndProfit: undefined,
	},
	range: {
		from: "",
		to: "",
	},
	grouping: 1,
};

const getPartnerGeneralIncomeAndProfit = createAppAsyncThunk(
	"dashboard/get-general-mux-income",
	async (params: getPartnerGeneralIncomeAndProfitParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const res = await getPartnerGeneralMuxIncomeAndProfit(token, {
				...params,
				content_type: CONTENT.CAPITULO.toString(),
				subscription_required: true,
			});
			const orderedData = res.data.series.sort((a, b) => b.total_series_time_seconds - a.total_series_time_seconds);

			const usedColors = new Set<string>();

			const serieWithColor = orderedData.map(serie => {
				let color;
				do {
					color = `rgba(${serie.isPartner ? Math.floor(Math.random() * 200) : Math.floor(Math.random() * 10 + 240)}, ${
						serie.isPartner ? Math.floor(Math.random() * 200) : Math.floor(Math.random() * 10 + 240)
					}, ${serie.isPartner ? Math.floor(Math.random() * 200) : Math.floor(Math.random() * 10 + 240)}, 1)`;
				} while (usedColors.has(color));
				usedColors.add(color);
				return {...serie, color: color};
			});

			return {
				data: {...res.data, series: serieWithColor},
				range: {from: params.initial_date, to: params.final_date},
				grouping: params.grouping,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const dashboardPartnerSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setSelectedSerieId(state, action: PayloadAction<string | undefined>) {
			state.selectedSerieId = action.payload;
		},
		setSelectedContentId(state, action: PayloadAction<string | undefined>) {
			state.selectedContentId = action.payload;
		},
		setRange(state, action: PayloadAction<{from: string; to: string}>) {
			state.range = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getPartnerGeneralIncomeAndProfit.pending, (state, action) => {
				state.statusByName["getPartnerGeneralIncomeAndProfit"] = "pending";
			})
			.addCase(getPartnerGeneralIncomeAndProfit.fulfilled, (state, action) => {
				state.statusByName["getPartnerGeneralIncomeAndProfit"] = "fulfilled";
				state.dataByName["getPartnerGeneralIncomeAndProfit"] = action.payload?.data ?? undefined;
				state.range = action.payload?.range ?? {from: "", to: ""};
				state.grouping = action.payload?.grouping ?? 1;
			})
			.addCase(getPartnerGeneralIncomeAndProfit.rejected, (state, action) => {
				state.statusByName["getPartnerGeneralIncomeAndProfit"] = "rejected";
			});
	},
});

export const selectedPartnerSeries = createSelector(
	(state: RootState) => state.user.userInfo?.get_partner_series,
	(state: RootState) => state.dashboardPartner.dataByName.getPartnerGeneralIncomeAndProfit?.series,
	(partnerSeries, seriesDetails) =>
		partnerSeries
			?.filter(el => el.active)
			.map(el => {
				const serieData = seriesDetails?.find(serie => serie.seriesid === el.serieid);
				return {...el, ...serieData};
			}),
);

export const dashboardPartnerActions = {
	...dashboardPartnerSlice.actions,
	getPartnerGeneralIncomeAndProfit,
};

export default dashboardPartnerSlice;
