import React, {memo} from "react";

const POSITIONS = {
	top: "-top-10 left-1/2 -translate-x-1/2 before:top-full before:-translate-x-1/2",
	bottom: "-bottom-10 left-1/2 -translate-x-1/2 before:bottom-full before:-translate-x-1/2 before:rotate-180",
};

export type TooltipProps = {
	children: React.ReactNode;
	text: string;
	placement?: keyof typeof POSITIONS;
};

const Tooltip: React.FC<TooltipProps> = memo(props => {
	return (
		<span className="group relative">
			{props.text !== "" && (
				<span
					className={`pointer-events-none absolute z-10 box-border whitespace-nowrap rounded bg-gray-600 px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2  before:border-4 before:border-transparent before:border-t-gray-600 before:content-[''] group-hover:opacity-100 ${
						POSITIONS[props.placement!]
					}`}
				>
					{props.text}
				</span>
			)}

			{props.children}
		</span>
	);
});

Tooltip.displayName = "Tooltip";
Tooltip.defaultProps = {placement: "top"};

export default Tooltip;
