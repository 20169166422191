import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ListPlus} from "../../../../../components/primitives/icons";
import {Spinner} from "../../../../../components/primitives/icons/Spinner";
import {insertCredit, insertCreditCategory, orderCreditsRankingData} from "../../../../../data-access/series/credits";
import {auth} from "../../../../../firebase";
import {seriesActions} from "../../../../../_store/features/series/series-slice";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import Category from "./subcomponents/Category";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Button} from "../../../../../components/primitives/Button";
import {toast} from "react-hot-toast";
import {Label} from "../../../../../components/primitives/Label";
import {StaffSelect} from "../../../../../components/blocks/StaffSelect";
import {Staff as StaffType} from "../../../../../data-access/series/staff";
import Staff from "./subcomponents/Staff";
import Creatable from "react-select/creatable";
import {selectStyles} from "../../../../products/SelectStyles";
import {crewActions} from "../../../../../_store/features/crew/crew-slice";

type CreditCategoryType = {
	staffId: string;
	categoryName: string;
	categoryId: string;
	categoryRanking: number;
	categoryStaff: {
		creditId: string;
		staffFullName: string;
		staffRanking: number;
	}[];
}[];

export function CreditSection() {
	let {id} = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const serieData = useAppSelector(state => state.series.results.find(el => el.id === id));
	const categories = useAppSelector(state => state.crew.staffProfessionsResults);
	const isActionLoading = useAppSelector(state => state.series.actionLoading);
	const [creditSections, setCreditSections] = useState<CreditCategoryType>([]);
	const [newSectionActive, setNewSectionActive] = useState(false);
	const [newCategoryName, setNewCategoryName] = useState("");
	const [sectionRank, setSectionRank] = useState(0);
	const [newCategorySelectedStaff, setNewCategorySelectedStaff] = useState<StaffType[]>([]);
	const [professions, setProfessions] = useState([{label: "", value: ""}]);
	const [isCreatingCategory, setIsCreatingCategory] = useState(false);

	useEffect(() => {
		if (!serieData) {
			return navigate("/series/list");
		}
		setProfessions([{label: "", value: ""}]);

		const {get_credits_serieid} = serieData;

		let creditCategories: CreditCategoryType = [];
		if (get_credits_serieid) {
			get_credits_serieid.forEach(credit => {
				const creditCategoryElement = creditCategories.find(el => el.categoryId === credit.creditcategory_id);

				if (creditCategoryElement) {
					creditCategoryElement.categoryStaff.push({
						staffFullName: credit.credit_fullname,
						creditId: credit.credit_id,
						staffRanking: credit.staff_ranking,
					});
				} else {
					creditCategories.push({
						staffId: credit.creditstaff_id,
						categoryId: credit.creditcategory_id,
						categoryName: credit.credit_category,
						categoryRanking: credit.category_ranking,
						categoryStaff: [
							{
								creditId: credit.credit_id,
								staffFullName: credit.credit_fullname,
								staffRanking: credit.staff_ranking,
							},
						],
					});
				}
			});
		}

		if (serieData && serieData.get_credits_serieid) {
			setSectionRank(serieData.get_credits_serieid[serieData.get_credits_serieid.length - 1].category_ranking);
		} else {
			setSectionRank(0);
		}

		dispatch(crewActions.getStaffProfessionList({page: 0, page_size: 999999}));

		setCreditSections(
			creditCategories
				.sort((a, b) => a.categoryRanking - b.categoryRanking)
				.map(cat => {
					cat.categoryStaff.sort((a, b) => a.staffRanking - b.staffRanking);
					return cat;
				}),
		);
	}, [dispatch, navigate, serieData]);

	const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
		setCreditSections(prev => {
			const oldData = [...prev];
			oldData.splice(hoverIndex, 0, oldData.splice(dragIndex, 1)[0]);
			return oldData;
		});
	}, []);

	useEffect(() => {
		setProfessions(
			categories.map(category => ({
				label: category.profession || "",
				value: category.id || "",
			})),
		);
	}, [categories]);

	const dropCard = useCallback(() => {
		const orderMap = creditSections.map((section, idx) => ({id: section.categoryId, order: idx + 1}));

		auth.currentUser
			?.getIdToken()
			.then(token => {
				orderCreditsRankingData(token, {ordered_data: orderMap})
					.then(() => {
						dispatch(seriesActions.getSeriesCredit({GCPUser: auth.currentUser!, params: {searchby: "id", searchvalue: id}}));
						toast.success("Datos ordenados correctamente");
					})
					.catch(err => {
						toast.error(err.message);
					});
			})
			.catch(err => {
				console.log(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creditSections]);

	const handleAddNewCreditSection = () => {
		auth.currentUser?.getIdToken().then(token => {
			const rank = sectionRank + 1;
			insertCreditCategory(token, {category: newCategoryName, ranking: rank.toString()})
				.then(categoryRes => {
					toast.success("Categoría creada correctamente.");
					const bulkInsert = Promise.allSettled(
						newCategorySelectedStaff.map(staff =>
							insertCredit(token, {categoryid: categoryRes.data.id, seriesid: serieData?.id!, staffid: staff.id, ranking: 0})
								.then(() => {})
								.catch(() => {}),
						),
					);

					bulkInsert
						.then(() => {
							dispatch(seriesActions.getSeriesCredit({GCPUser: auth.currentUser!, params: {searchby: "id", searchvalue: id}}));
							setNewCategoryName("");
							setNewCategorySelectedStaff([]);
							setNewSectionActive(false);
						})
						.catch(err => console.log(err));
				})
				.catch(() => {
					toast.error("Error al Crear categoría.");
				});
		});
	};

	return (
		<>
			<div className="mt-6">
				<div className="flex justify-end rounded-t-md border border-b-0 border-border p-4">
					{isActionLoading ? (
						<div className="mx-auto mt-2 flex items-center justify-center">
							<Spinner />
						</div>
					) : (
						<>
							<Button type="button" onClick={() => setNewSectionActive(true)}>
								<ListPlus className="mr-1 h-6 w-6" />
								Nueva sección
							</Button>
							<Button
								type="button"
								onClick={() => {
									navigate("/crew/staff/new", {replace: false, state: {param: serieData?.id!}});
								}}
								variant={"outline"}
								className="ml-2"
							>
								<ListPlus className="mr-1 h-6 w-6" />
								Nuevo Staff
							</Button>
						</>
					)}
				</div>
				<div className="jsonContent rounded border border-border bg-accent p-6">
					<DndProvider backend={HTML5Backend}>
						<div className="w-full space-y-4">
							{newSectionActive && (
								<div className="ml-12 flex flex-col rounded-md border border-border bg-background p-4">
									<div className="grid grid-cols-3">
										<div className="h-full border-r border-border pr-4">
											<Label>Sección:</Label>
											<Creatable
												className="mt-2 w-full"
												styles={selectStyles}
												options={professions}
												isLoading={isCreatingCategory}
												onChange={selectedOption => {
													setNewCategoryName(selectedOption?.label ?? "");
												}}
												onCreateOption={async createdOption => {
													setIsCreatingCategory(true);
													setNewCategoryName(createdOption);
													await dispatch(crewActions.insertProfession({profession: createdOption})).then(res => {
														if (res.meta.requestStatus === "fulfilled") {
															dispatch(crewActions.getStaffProfessionList({page: 0, page_size: 99999999}));
															toast.success("Profesión creada Correctamente");
														}
														if (res.meta.requestStatus === "rejected") {
															if (res.meta.rejectedWithValue && typeof res.payload === "string") {
																toast.error(res.payload);
															}
														}
														setIsCreatingCategory(false);
													});
												}}
												value={professions.find(option => option.label === newCategoryName)}
												isClearable
												placeholder="Insertar nombre de la nueva sección..."
											/>
										</div>
										<div className="col-span-2 mt-8 pl-4">
											<StaffSelect
												onStaffSelect={staff => {
													setNewCategorySelectedStaff(prev => prev.concat(staff));
												}}
												selectedStaff={newCategorySelectedStaff}
												profession={newCategoryName}
											/>
											{newCategorySelectedStaff.map(staff => (
												<Staff
													data={{
														creditId: staff.id,
														staffFullName: staff.fullname,
													}}
													onStaffDelete={({creditId}) => {
														setNewCategorySelectedStaff(prev => prev.filter(staff => staff.id !== creditId));
													}}
													onStaffOrderUp={() => {}}
													onStaffOrderDown={() => {}}
												/>
											))}
										</div>
									</div>
									<div className="ml-auto mt-2 space-x-2">
										<Button onClick={handleAddNewCreditSection} disabled={newCategorySelectedStaff.length === 0}>
											Crear
										</Button>
										<Button
											variant="outline"
											onClick={() => {
												setNewSectionActive(false);
												setNewCategoryName("");
												setNewCategorySelectedStaff([]);
											}}
										>
											Cancelar
										</Button>
									</div>
								</div>
							)}
							{creditSections.map((creditSection, index) => (
								<Category
									key={creditSection.categoryId}
									id={creditSection.categoryId}
									onMoveCategory={moveCard}
									onDropCategory={dropCard}
									name={creditSection.categoryName}
									ranking={creditSection.categoryRanking}
									data={creditSection.categoryStaff}
									creditId={creditSection.staffId}
									index={index}
								/>
							))}
						</div>
					</DndProvider>
				</div>
			</div>
		</>
	);
}
