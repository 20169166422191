import {useMemo} from "react";
import {dashboardActions, selectedPartnerSeries} from "../../../../_store/features/dashboard/dashboard-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {PartnerWithoutRentalsDashboard} from "./without-rentals";
import {PartnerWithRentalsDashboard} from "./with-rentals";
import {Navigate} from "react-router-dom";

export function PartnerDashboard() {
	const series = useAppSelector(selectedPartnerSeries);
	const dispatch = useAppDispatch();
	const rentalProducts = useMemo(() => series?.filter(s => !s.pronyr_original), [series]);

	console.log("PartnerDashboard series", series);

	if (!rentalProducts?.length) return <PartnerWithoutRentalsDashboard />;

	if (series?.length === 1) {
		console.log("PartnerDashboard single series", rentalProducts[0]);
		dispatch(dashboardActions.setSelectedSerieId(rentalProducts[0].serieid));
		return <Navigate to={`partner/products/${rentalProducts[0].serieid}`} replace={false} />;
	}

	return <PartnerWithRentalsDashboard />;
}
