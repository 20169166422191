import {formatISO9075} from "date-fns";
import {programmedTaskActions} from "../../../_store/features/programmed-task/programmed-task";
import {useAppDispatch} from "../../../_store/hooks";
import {Button} from "../../primitives/Button";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../primitives/Dialog";
import toast from "react-hot-toast";
import {useState} from "react";
import {Spinner} from "../../primitives/icons";

interface ConfirmProgrammedTaskModalProps {
	open: boolean;
	contentName: string;
	contentId: string;
	scheduledTaskValue: {
		date: string;
		hour: string;
	};
	notificationType: string;
	url?: string;
	action?: () => void;
}

export default function ConfirmProgrammedTaskModal({
	open,
	contentId,
	contentName,
	notificationType,
	scheduledTaskValue,
	url = "/v2/send_custom_notification_task",
	action,
}: ConfirmProgrammedTaskModalProps) {
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);

	function sanitizeTaskId(taskId: string): string {
		const sanitized = taskId.replace(/[^A-Za-z0-9-_]/g, "");

		if (sanitized.length >= 1 && sanitized.length <= 500) {
			return sanitized;
		} else {
			return sanitized.substring(0, 500);
		}
	}

	function generateRandomString(length: number) {
		var result = "";
		var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for (var i = 0; i < 10; i++) {
			result += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return result;
	}

	const handleSetScheduledTask = () => {
		if (!notificationType) return toast.error("Debe seleccionar un tipo de notificación");
		if (scheduledTaskValue.date !== "" && scheduledTaskValue.hour !== "") {
			const formattedDate = formatISO9075(new Date(scheduledTaskValue.date), {format: "extended", representation: "date"});
			const name = sanitizeTaskId(contentName);
			setLoading(true);
			dispatch(
				programmedTaskActions.addNewTask({
					name: "programmed_task_of_" + (name || "no_content_name_task") + "_" + generateRandomString(10).toLowerCase(),
					url,
					scheduleTime: formattedDate + "T" + scheduledTaskValue.hour + ":00.000Z",
					body: {
						type: notificationType,
						name: contentName ?? "Contenido sin nombre",
						number: 0,
						content_id: contentId ?? "",
					},
				}),
			)
				.then(res => {
					if (res.meta.requestStatus === "fulfilled") return toast.success("Tarea programada correctamente");
					return toast.error("Error al programar la tarea");
				})
				.catch(err => {
					console.log(err);
					toast.error("Error al programar la tarea");
				})
				.finally(() => {
					dispatch(programmedTaskActions.listAllTask());
					action?.();
					setLoading(false);
				});
		}
	};

	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				action?.();
			}}
		>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="font-medium text-white">
						¿Desea que el usuario sea notificado en la fecha de notificación programada?
					</DialogTitle>
				</DialogHeader>
				<div className="mt-8 flex justify-end gap-5">
					<Button
						onClick={() => {
							action?.();
						}}
						className="w-16"
					>
						No
					</Button>
					<Button variant="secondary" className="w-16" onClick={handleSetScheduledTask}>
						{loading ? <Spinner /> : "Si"}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
