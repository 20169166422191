import {selectedInfluencerSeries} from "../../../../../_store/features/dashboard/dashboard-slice";
import {useAppSelector} from "../../../../../_store/hooks";
import {Camera, Film} from "../../../../primitives/icons";
import NavLink from "../../subcomponents/NavLink";

export function InfluencerNavigationSection() {
	const series = useAppSelector(selectedInfluencerSeries);

	return series && series?.length > 1 ? (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			<NavLink to="/influencer/series" label="Series" icon={<Film />} />
			<NavLink to="/influencer/contents" label="Contenidos" icon={<Camera />} />
		</div>
	) : null;
}
