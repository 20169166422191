import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {vendorActions} from "../../../_store/features/vendor/slice";
import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {Customer} from "../../../data-access/vendors";
import {CancelCircleFilled, CheckCircleFilled, EmailFilled, Pencil, PhoneFilled, Refresh, User} from "../../../components/primitives/icons";
import {Link} from "react-router-dom";
import {Button} from "../../../components/primitives/Button";
import {DataTable} from "../../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {Avatar, AvatarFallback, AvatarImage} from "@radix-ui/react-avatar";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../components/primitives/Select";
import {Input} from "../../../components/primitives/Input";
import useCurrentUserRole from "../../../components/hooks/useCurrentUserRole";
import Tooltip from "../../../components/primitives/ToolTip-toDeprecate";

const columnHelper = createColumnHelper<Customer>();

type Filter = Partial<Pick<Customer, "active" | "customerid" | "vendorid">>;

const searchOptions: {display: string; searchby: string | undefined; roles: string}[] = [
	{display: "Por ID del cliente", searchby: "customerid", roles: "vendor"},
	{display: "Por ID del vendedor", searchby: "vendorid", roles: "admin"},
];

const statusOptions = [
	{display: "Todos", value: undefined},
	{display: "Activos", value: true},
	{display: "Inactivos", value: false},
];

export default function CustomersList() {
	const {activeRole} = useCurrentUserRole();
	const {customers, loading, totalResults} = useAppSelector(state => state.vendor);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const [tableWidth, setTableWidth] = useState(0);
	const [filters, setFilters] = useState<Filter>({});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
	const dispatch = useAppDispatch();
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleResize = (entries: any) => {
			for (let entry of entries) {
				setTableWidth(entry.contentRect.width);
			}
		};

		const resizeObserver = new ResizeObserver(handleResize);
		if (ref.current) {
			resizeObserver.observe(ref.current);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const columns = useMemo(() => {
		const avatarSize = 60;
		const width = tableWidth - avatarSize;
		return [
			columnHelper.accessor("photo_url", {
				id: "Avatar",
				header: ({column}) => <DataTableColumnHeader title="Avatar" column={column} />,
				cell: info => {
					return (
						<Avatar>
							<AvatarImage src={info.row.original.photo_url} alt="User profile" />
							<AvatarFallback>{info.row.original?.email?.slice(0, 2)}</AvatarFallback>
						</Avatar>
					);
				},
				size: avatarSize,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("name", {
				id: "Nombre  / UUID",
				header: "Nombre / UUID",
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(width * 0.25),
			}),
			columnHelper.accessor("email", {
				id: "Email",
				header: "Email",
				cell: info => <div className="ml-2 w-full text-left">{info.getValue()}</div>,
				size: Math.floor(width * 0.25),
			}),
			columnHelper.accessor("phone", {
				id: "No. Teléfono",
				header: "No. Teléfono",
				cell: info => <div className="ml-2 w-full text-left">{info.getValue() || "N/A"}</div>,
				size: Math.floor(width * 0.2),
			}),
			columnHelper.accessor("email_verified", {
				id: "Estado",
				header: "Estado",
				cell: info => (
					<div className="flex w-full items-center">
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.disabled ? " text-red-700" : " text-green-700")
							}
						>
							<Tooltip
								text={info.cell.row.original.disabled ? "Deshabilitado" : "Activo"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								{info.cell.row.original.disabled ? <CancelCircleFilled /> : <CheckCircleFilled />}
							</Tooltip>
						</span>
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.email_verified ? " text-green-700" : " text-red-700")
							}
						>
							<Tooltip
								text={info.cell.row.original.email_verified ? "Verificado" : "Sin Verificar"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								<EmailFilled />
							</Tooltip>
						</span>
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.phone_verified ? " text-green-700" : " text-red-700")
							}
						>
							<Tooltip
								text={info.cell.row.original.phone_verified ? "Verificado" : "Sin Verificar"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								<PhoneFilled />
							</Tooltip>
						</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.15),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/vendor/customers/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: Math.floor(width * 0.15),
				enableResizing: false,
			}),
		];
	}, [tableWidth]);

	const onLoadCustomers = useCallback(() => {
		dispatch(vendorActions.fetchCustomers({page_size: page.pageSize, page: page.pageIndex, ...filters}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, page]);

	useEffect(() => {
		onLoadCustomers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onLoadCustomers]);

	const searchValues = useMemo(() => {
		return searchOptions.filter(option => option.roles.split("|").includes(activeRole as string));
	}, [activeRole]);

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.searchby === value) ?? searchOptions[0]);
	};

	const handleStatusFilter = (value: string) => {
		const status = statusOptions.find(el => el.display === value) ?? statusOptions[0];
		setSelectedStatus(status);

		if (status.value !== undefined) {
			setFilters({...filters, active: status.value});
		} else {
			const {active, ...rest} = filters;
			setFilters(rest);
		}
	};

	const handleOnSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (searchValue) {
			const filter = {[selectedSearchBy.searchby ?? "customerid"]: searchValue};
			setFilters(filter);
		} else {
			const {customerid, ...rest} = filters;
			setFilters(rest);
		}
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Lista de clientes</h2>
				<Button className="gap-2" asChild>
					<Link to="/vendor/customers/new">
						<User />
						Nuevo cliente
					</Link>
				</Button>
			</div>
			<div className="flex gap-8 px-6 pt-4 xl:gap-24">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={onLoadCustomers}>
						<Refresh className="h-4 w-4" />
					</Button>
					<form className="flex w-full" onSubmit={handleOnSearch}>
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>

						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.searchby}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchValues.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.searchby}`}>
											{sOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} type="submit">
							Buscar
						</Button>
					</form>

					<Select onValueChange={handleStatusFilter} value={selectedStatus.display}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{statusOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.display}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref}
					columns={columns}
					dataset={customers}
					pageCount={Math.ceil(totalResults / page.pageSize)}
					pagination={page}
					loading={loading}
					onPaginationChange={setPage}
					withDynamicPageSize
					rowHeight={53}
					showPagination={false}
					totalItems={totalResults}
				/>
			</div>
		</div>
	);
}
