import {useEffect, useState} from "react";
import Down from "../../../../../components/primitives/icons/Down";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../../../components/primitives/Table";
import {useDashboardByNameQuery} from "../../../../../_store/features/dashboard/hooks";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {dashboardActions} from "../../../../../_store/features/dashboard/dashboard-slice";
import {Skeleton} from "../../../../../components/primitives/Skeleton";
import {DateRange} from "react-day-picker";
import {formatISO9075} from "date-fns";
import {SeriesContentEarningsResponse} from "../../../../../data-access/dashboard/types";
import {serieContentsActions} from "../../../../../_store/features/serie-content/serie-content-slice";
import Select, {ActionMeta, MultiValue} from "react-select";
import {selectGlobalStyles} from "../../../../../../utils/selectGlobalStyles";
import {SelectOptionType} from "../../../../../../utils/globalTypes";
import {Link} from "react-router-dom";

interface TopViewsProps {
	serieid: string | undefined;
	dates: DateRange | undefined;
}

type SortBy = "total" | "stickers/u" | "stickers/coins" | "donaciones/u" | "donaciones/coins";

const selectMembershipType: SelectOptionType[] = [
	{label: "Capitulo", value: "0"},
	{label: "Contenido", value: "2"},
	{label: "Live", value: "4,5"},
];

const membershipListInitial = "0,2,4,5";
export interface SeriesContent {
	rectangular_image?: string;
	img_thumbnail?: string;
	id: string;
	title: string;
	stickers: number;
	donations: number;
	sticker_count: number;
	donation_count: number;
}

function sort(data: SeriesContentEarningsResponse[], sortBy: SortBy) {
	switch (sortBy) {
		case "stickers/u":
			return data.sort((a, b) => b.stickers_count - a.stickers_count);
		case "stickers/coins":
			return data.sort((a, b) => b.stickers - a.stickers);
		case "donaciones/u":
			return data.sort((a, b) => b.donations_count - a.donations_count);
		case "donaciones/coins":
			return data.sort((a, b) => b.donations - a.donations);
		default:
			return data.sort(
				(a, b) =>
					b.donations_count +
					b.donations +
					b.stickers_count +
					b.stickers -
					(a.donations_count + a.donations + a.stickers + a.stickers_count),
			);
	}
}

interface ContentsRowsProps {
	data: SeriesContentEarningsResponse[];
	sortBy: SortBy;
}
function ContentsRows({data, sortBy}: ContentsRowsProps) {
	const {results: content, loading} = useAppSelector(state => state.serieContent);

	return (
		<>
			{sort(data, sortBy).map(item => {
				const {donations, donations_count, stickers, stickers_count} = item;
				const total = donations + stickers;
				const chapter = content.find(c => c.id === item.contentid);
				return (
					<TableRow className="h-[97px] text-center odd:bg-accent/40" key={item.contentid}>
						<TableCell className="w-36 px-2">
							{loading ? (
								<div className="aspect-video h-16 animate-pulse rounded-md bg-muted object-cover" />
							) : (
								<img
									className="aspect-video max-h-16 rounded-sm object-cover
									before:absolute before:flex before:h-16 before:w-36 before:items-center before:justify-center before:border before:border-border before:bg-background before:text-xs before:font-medium before:content-['404']
									"
									src={content.find(c => c.id === item.contentid)?.rectangular_image}
									alt=""
								/>
							)}
						</TableCell>
						<TableCell className="text-left">
							<Link
								to={
									chapter
										? "/corporate/content-detail/" +
										  chapter.serie_title +
										  "/" +
										  chapter.serieid +
										  "/" +
										  chapter.title +
										  "/" +
										  chapter.id
										: ""
								}
							>
								{item.title}
							</Link>
						</TableCell>
						<TableCell>{total || 0}</TableCell>
						<TableCell>{stickers_count || 0}</TableCell>
						<TableCell>{stickers || 0}</TableCell>
						<TableCell>{donations_count || 0}</TableCell>
						<TableCell>{donations || 0}</TableCell>
					</TableRow>
				);
			})}
		</>
	);
}

function CoinEarningsTable({serieid, dates}: TopViewsProps) {
	const {data, isLoading} = useDashboardByNameQuery("serieContentEarning");
	const [sortBy, setSortBy] = useState<SortBy>("total");
	const [membershipList, setMembershipList] = useState<string>(membershipListInitial);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(serieContentsActions.getSerieContents(serieid!));
		dispatch(
			dashboardActions.getSerieEarnings({
				seriesid: serieid || "",
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				content_type: membershipList,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, serieid, dates, membershipList]);

	function handleOnChange(newValue: MultiValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue.length === 0) {
			setMembershipList(membershipListInitial);
		} else {
			setMembershipList(newValue.map(series => series.value).join(","));
		}
	}
	return (
		<div className="rounded-lg border bg-card">
			<div className="flex flex-row justify-between border-b border-border p-6">
				<div className="flex flex-col">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Capítulos</h3>
					<p className="text-sm text-muted-foreground">Recolección por coins</p>
				</div>
				<Select
					className="basic-multi-select mt-1 rounded"
					options={selectMembershipType}
					isMulti={true}
					styles={selectGlobalStyles}
					defaultValue={selectMembershipType}
					placeholder="Selecciona las series para ver sus estadísticas"
					onChange={handleOnChange}
				/>
			</div>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className="w-36"></TableHead>
						<TableHead>Título</TableHead>
						<TableHead className={sortBy === "total" ? "text-accent-foreground" : "text-muted-foreground"}>
							<button className="mx-auto flex items-center space-x-1 whitespace-nowrap" onClick={() => setSortBy("total")}>
								<span>Total</span>
								<Down className="h-3 w-3" />
							</button>
						</TableHead>
						<TableHead className={sortBy === "stickers/u" ? "text-accent-foreground" : "text-muted-foreground"}>
							<button className="mx-auto flex items-center space-x-1 whitespace-nowrap" onClick={() => setSortBy("stickers/u")}>
								<span>Stickers / u</span>
								<Down className="h-3 w-3" />
							</button>
						</TableHead>
						<TableHead className={sortBy === "stickers/coins" ? "text-accent-foreground" : "text-muted-foreground"}>
							<button className="mx-auto flex items-center space-x-1 whitespace-nowrap" onClick={() => setSortBy("stickers/coins")}>
								<span>Stickers / coins</span>
								<Down className="h-3 w-3" />
							</button>
						</TableHead>
						<TableHead className={sortBy === "donaciones/u" ? "text-accent-foreground" : "text-muted-foreground"}>
							<button className="mx-auto flex items-center space-x-1 whitespace-nowrap" onClick={() => setSortBy("donaciones/u")}>
								<span>Donaciones / u</span>
								<Down className="h-3 w-3" />
							</button>
						</TableHead>
						<TableHead className={sortBy === "donaciones/coins" ? "text-accent-foreground" : "text-muted-foreground"}>
							<button className="mx-auto flex items-center space-x-1 whitespace-nowrap" onClick={() => setSortBy("donaciones/coins")}>
								<span>Donaciones / coins</span>
								<Down className="h-3 w-3" />
							</button>
						</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{isLoading ? (
						<TableRow className="text-center odd:bg-accent/40">
							<TableCell colSpan={7}>
								<Skeleton className="h-20 w-full bg-slate-300" />
							</TableCell>
						</TableRow>
					) : (
						<ContentsRows data={Array.from(data ?? [])} sortBy={sortBy} />
					)}
				</TableBody>
			</Table>
		</div>
	);
}

export default CoinEarningsTable;
