import {useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {Button} from "../../../components/primitives/Button";
import {Spinner} from "../../../components/primitives/icons/Spinner";
import {useNavigate, useParams} from "react-router-dom";
import Select, {MultiValue, SingleValue} from "react-select";
import citiesList from "../../../../assets/us-cities.json";
import countriesList from "../../../../assets/countries.json";
import {selectGlobalStyles} from "../../../../utils/selectGlobalStyles";
import {SelectOptionType} from "../../../../utils/globalTypes";
import toast from "react-hot-toast";
import {advertisementsActions} from "../../../_store/features/advertisements/advertisements-slice";
import {DataTable} from "../../../components/blocks/DataTable";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {AddVisibilityDataInterface} from "../../../data-access/advertisers";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {Trash} from "../../../components/primitives/icons";

const columnHelper = createColumnHelper<AddVisibilityDataInterface>();

function AddVisibilityList() {
	const {id, is_video} = useParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const advertisements = useAppSelector(state => state.advertisements);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 5,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [disabledUsSelects, setDisabledUsSelect] = useState(true);
	const [country, setCountry] = useState<string>("");
	const [usState, setUsState] = useState<string>();
	const [usCityList, setCityList] = useState<string[]>([]);
	const [usCities, setUsCities] = useState<SelectOptionType[]>([]);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [locationToDelete, setLocationToDelete] = useState<string>();

	const countries: SelectOptionType[] = countriesList.map(country => ({
		value: country.code,
		label: country.name,
	}));

	interface StatesAndCities {
		[state: string]: string[];
	}

	const generateCityOptions = (selectedStates: string[], data: StatesAndCities): SelectOptionType[] => {
		let cityOptions: SelectOptionType[] = [];

		selectedStates.forEach(state => {
			const cities = data[state];

			if (cities) {
				const cityOptionsForState = cities.map(city => ({
					label: city,
					value: city,
				}));

				cityOptions = [...cityOptions, ...cityOptionsForState];
			}
		});

		return cityOptions;
	};

	const validateSelectedLocation = (location: string) => {
		if (advertisements.adsLocations.results.some(l => l.city === location)) {
			return true;
		}
		if (advertisements.adsLocations.results.some(l => l.region === location)) {
			return true;
		}
		if (advertisements.adsLocations.results.some(l => l.country === location)) {
			return true;
		}
		return false;
	};

	const statesArray: string[] = Array.from(new Set(Object.keys(citiesList)));

	const usStates: SelectOptionType[] = statesArray.map(state => ({
		value: state,
		label: state,
	}));

	useEffect(() => {
		if (!id) return;
		dispatch(advertisementsActions.getVisibilityRange({adid: id}));
		dispatch(advertisementsActions.getAdvertisementsLocations({page: 0, page_size: 999999}));
	}, [dispatch, id]);

	useEffect(() => {
		if (!locationToDelete) return;
		dispatch(advertisementsActions.deleteVisibilityRange({ad_location_id: locationToDelete, is_banner: is_video === "video" ? false : true}))
			.then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					toast.success("Localidad eliminada correctamente");
				} else {
					toast.error("Error al eliminar la localidad");
				}
			})
			.catch(() => {
				toast.error("Error al eliminar la localidad");
			})
			.finally(() => {
				setLocationToDelete(undefined);
				setLoadingDelete(false);
			});
	}, [dispatch, is_video, locationToDelete]);

	const handleCountryChange = (value: SingleValue<SelectOptionType>) => {
		if (!value) {
			setDisabledUsSelect(true);
			return;
		}
		if (value.value === "US") {
			setDisabledUsSelect(false);
		} else {
			setDisabledUsSelect(true);
		}
		setCountry(value.label);
	};

	const handleStateChange = (value: SingleValue<SelectOptionType>) => {
		if (!value) {
			setUsCities([]);
			setUsState(undefined);
			return;
		}

		setUsState(value.label);
		const citiesOptions = generateCityOptions([value.label], citiesList);
		setUsCities(citiesOptions);
	};

	const handleCityChange = (value: MultiValue<SelectOptionType>) => {
		if (value.length === 0) {
			setCityList([]);
			return;
		}

		setCityList(value.map(cities => cities.label));
	};

	const handleInsertRegion = () => {
		setIsLoading(true);

		if (!id) {
			return;
		}

		if (!country) {
			setIsLoading(false);
			return toast.error("Debes seleccionar al menos un país");
		}

		if (usCityList.length >= 1) {
			for (let i = 0; i < usCityList.length; i++) {
				if (!validateSelectedLocation(usCityList[i])) {
					toast.error(usCityList[i] + " no se encuentra disponible en mux.");
					continue;
				}
				dispatch(advertisementsActions.insertVisibilityRange({adid: id, country: country, region: usState, city: usCityList[i]}))
					.then(res => {
						if (res.meta.requestStatus === "fulfilled" && usCityList.length === i + 1) {
							toast.success("Ubicación insertada correctamente");
							setIsLoading(false);
						} else if (res.meta.requestStatus !== "fulfilled" && usCityList.length === i + 1) {
							toast.error("No se pudo agregar esta ubicación, revise que no exista una con el mismo nombre");
							setIsLoading(false);
						}
					})
					.catch(() => {
						toast.error("Ocurrió un error al insertar la ubicación");
						setIsLoading(false);
					});
			}

			return;
		}

		if (!validateSelectedLocation(country)) {
			toast.error(country + " no se encuentra disponible en mux.");
			setIsLoading(false);
			return;
		}

		if (usState && !validateSelectedLocation(usState)) {
			toast.error(usState + " no se encuentra disponible en mux.");
			setIsLoading(false);
			return;
		}

		dispatch(advertisementsActions.insertVisibilityRange({adid: id, country: country, region: usState}))
			.finally(() => {
				setIsLoading(false);
			})
			.then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					toast.success("Ubicación insertada correctamente");
				} else {
					toast.error("Ocurrió un error al insertar la ubicación");
				}
			})
			.catch(err => {
				toast.error("Ocurrió un error inesperado!");
			});
	};

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("country", {
				id: "País",
				header: ({column}) => <DataTableColumnHeader title="País" column={column} />,
				cell: info => (
					<div className="flex w-full flex-col justify-start">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.country}</span>
					</div>
				),
				size: Math.round(tableWidth * 0.33),
			}),
			columnHelper.accessor("city", {
				id: "Ciudad / Región",
				header: ({column}) => <DataTableColumnHeader title="Ciudad / Región" column={column} />,
				cell: info => {
					const text = info.row.original.city
						? info.row.original.city
						: info.row.original.region
						? info.row.original.region
						: "Todo el país";
					return (
						<div className="flex w-full flex-col justify-start">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{text}</span>
						</div>
					);
				},
				size: Math.round(tableWidth * 0.33),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="justify-left flex w-full gap-2">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setLoadingDelete(true);
								setLocationToDelete(info.row.original.id);
							}}
							disabled={loadingDelete}
						>
							{loadingDelete ? <Spinner /> : <Trash className="h-4 w-4" />}
						</Button>
					</div>
				),
				size: Math.round(tableWidth * 0.33),
				enableResizing: false,
			}),
		];
	}, [loadingDelete, tableRef]);

	return (
		<div className="flex h-screen flex-col">
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Editar Visibilidad del Anuncio</h2>
				<span className="text-base text-muted-foreground lg:text-lg">
					Permite editar en que regiones se debe mostrar el anuncio. Por defecto, el anuncio se muestra en todas las regiones.
				</span>
			</div>
			<div className="px-5">
				<div className="mt-6 border-t border-gray-100">
					<div className="divide-y divide-gray-100">
						<div className="flex flex-row justify-between px-4 py-6">
							<div className="flex-col ">
								<p className="text-sm font-medium leading-6 text-gray-900">Selecciona la región</p>
								<span className="text-xs font-bold text-destructive">*Los estados y ciudades solo aplican a USA por el momento</span>
							</div>
							<div className="flex flex-row items-center justify-between gap-x-2">
								<div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Select
										className="basic-select rounded sm:min-w-[250px]"
										options={countries}
										styles={selectGlobalStyles}
										placeholder="Países donde mostrar el anuncio"
										onChange={handleCountryChange}
									/>
								</div>
								<div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Select
										className="basic-select rounded sm:min-w-[250px]"
										options={usStates}
										styles={selectGlobalStyles}
										placeholder="Estados donde mostrar el anuncio"
										onChange={handleStateChange}
										isDisabled={disabledUsSelects}
									/>
								</div>
								<div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Select
										className="basic-multi-select rounded sm:min-w-[250px]"
										options={usCities}
										isMulti={true}
										styles={selectGlobalStyles}
										placeholder="Ciudades donde mostrar el anuncio"
										onChange={handleCityChange}
										isDisabled={disabledUsSelects}
									/>
								</div>
								<Button type="button" variant="blueBtn" disabled={isLoading} onClick={handleInsertRegion}>
									{isLoading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</div>

				<div className="flex h-full flex-col p-6 pt-4">
					<div className="mb-4 flex-col">
						<p className="text-sm font-medium leading-6 text-gray-900">Lista de regiones habilitadas</p>
					</div>
					<DataTable
						ref={ref => setTableRef(ref)}
						columns={columns}
						dataset={advertisements.adsVisibilityResults}
						pageCount={Math.round((advertisements.adsVisibilityResults?.length ?? 0) / page.pageSize)}
						pagination={page}
						loading={advertisements.loading}
						onPaginationChange={setPage}
						withDynamicPageSize
						rowHeight={50}
						showPagination={false}
					/>
				</div>

				<div className="flex justify-end gap-2 pb-4">
					<Button
						type="button"
						variant="secondary"
						onClick={() => {
							navigate(-1);
						}}
					>
						Salir
					</Button>
				</div>
			</div>
		</div>
	);
}

export default AddVisibilityList;
