/* eslint-disable jsx-a11y/aria-props */
import {useEffect, useState} from "react";
import {RadioGroup} from "@headlessui/react";
import toast from "react-hot-toast";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../../../../components/primitives/Sheet";
import {Subscription} from "../../../../../../data-access/subscriptions/subscriptions";
import {useAppDispatch, useAppSelector} from "../../../../../../_store/hooks";
import {subscriptionActions} from "../../../../../../_store/features/subscriptions/subscriptions-slice";
import {cn} from "../../../../../../../utils/classNames";
import {CheckCircle} from "../../../../../../components/primitives/icons";
import {Button} from "../../../../../../components/primitives/Button";
import {convertCurrencyToDouble} from "../../../../../../../utils/money";

interface NewSubscriptionSheetProps extends SheetProps {
	onRefresh: () => void;
}

export default function NewSubscriptionSheet(props: NewSubscriptionSheetProps) {
	const user = useAppSelector(state => state.vendor.customer);
	const [selected, setSelected] = useState<Subscription>();

	const subscriptions = useAppSelector(state => state.subscription.results);

	const dispatch = useAppDispatch();
	const {...rest} = props;

	useEffect(() => {
		dispatch(subscriptionActions.getSubscriptions({searchby: "description", searchvalue: "mensual"}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnCreateSubscription = async () => {
		if (!selected) {
			toast.success("Selecciona una suscripción");
			return;
		}

		if (!selected?.vendor_price) {
			toast.error("No se ha encontrado el precio de venta");
			return;
		}

		if (!user) {
			toast.error("No se ha encontrado el usuario");
			return;
		}

		if (
			window.confirm(`¿Estás seguro de vender la suscripción ${selected?.name} por ${convertCurrencyToDouble(selected?.vendor_price / 100)}?`)
		) {
			const thunk = await dispatch(subscriptionActions.createVendorSubscription({customerid: user?.id!, subscriptionproductid: selected?.id}));

			if (thunk.meta.requestStatus === "fulfilled") {
				props.onRefresh();
				props.onOpenChange?.(false);
			} else {
				toast.error("No se pudo asociar el vendedor");
			}
		}
	};

	return (
		<Sheet {...rest}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>Nueva suscripción</SheetTitle>
					<SheetDescription>
						Crea una nueva suscripción para <b>{user?.name}</b>
					</SheetDescription>
				</SheetHeader>

				<div className="mx-auto w-full max-w-md scroll-m-0">
					<RadioGroup value={selected} onChange={setSelected}>
						<RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
						<div className="grid grid-cols-2 gap-2">
							{subscriptions.map(subscription => (
								<RadioGroup.Option
									key={subscription.id}
									value={subscription}
									className={({active, checked}) => {
										return cn(
											"relative flex min-h-24 cursor-pointer rounded-lg bg-white px-5 py-4 shadow-md focus:outline-none",
											{
												"ring-2 ring-primary ring-offset-2 ring-offset-primary": active,
												"bg-primary text-white": checked,
											},
										);
									}}
								>
									{({active, checked}) => (
										<>
											<div className="flex w-full items-center justify-between">
												<div className="flex items-center pr-8">
													<div className="text-sm">
														<RadioGroup.Label as="p" className={cn("font-medium text-gray-900", {"text-white": checked})}>
															{subscription.name}
														</RadioGroup.Label>
														<RadioGroup.Description
															as="span"
															className={cn("inline text-gray-500", {"text-sky-100": checked})}
														>
															<span>{convertCurrencyToDouble(subscription.vendor_price / 100)} </span>
														</RadioGroup.Description>
													</div>
												</div>
												{checked && (
													<div className="absolute right-1 top-1 text-white">
														<CheckCircle className="b h-6 w-6" />
													</div>
												)}
											</div>
										</>
									)}
								</RadioGroup.Option>
							))}
						</div>
					</RadioGroup>
				</div>

				<Button className="mt-4 uppercase" onClick={handleOnCreateSubscription}>
					Vender suscripción
				</Button>
			</SheetContent>
		</Sheet>
	);
}
