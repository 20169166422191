import {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import ActionConfirmModal from "../../../../../../components/blocks/ActionConfirmModal";
import {Button} from "../../../../../../components/primitives/Button";
import {CancelCircleFilled, CheckCircleFilled, ListPlus, Pencil, Trash} from "../../../../../../components/primitives/icons";
import {createColumnHelper} from "@tanstack/react-table";
import ToolTip from "../../../../../../components/primitives/ToolTip-toDeprecate";
import {DataTable} from "../../../../../../components/blocks/DataTable";
import {Input} from "../../../../../../components/primitives/Input";
import {Switch} from "../../../../../../components/primitives/Switch";
import {Label} from "../../../../../../components/primitives/Label";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {
	createSponsorPaymentAction,
	editSponsorPaymentAction,
	getSponsorPaymentsAction,
	removeSponsorPaymentAction,
} from "../../../../../../_store/features/user-db/user-db-actions";
import {SponsorPaymentsData, SponsorPaymentsResponse} from "../../../../../../data-access/role/configuration/sponsor";

const columnHelper = createColumnHelper<SponsorPaymentsData>();

export default function PaymentsTable({id}: {id?: string}) {
	const dispatch = useAppDispatch();
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 0,
	});
	const [isProfit, setIsProfit] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [paymentAmount, setPaymentAmount] = useState<number>(1);
	const [modalType, setModalType] = useState<"new" | "edit" | "delete" | undefined>(undefined);
	const [paymentsData, setPaymentsData] = useState<SponsorPaymentsResponse | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedPayment, setSelectedPayment] = useState<string>("");

	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const navigate = useNavigate();

	const loadData = useCallback(() => {
		setIsLoading(true);
		dispatch(
			getSponsorPaymentsAction({
				searchby: "sponsor_id",
				searchvalue: id,
			}),
		)
			.then(res => {
				if (res.meta.requestStatus === "rejected" && res.payload) return toast.error("Ha ocurrido un error al listar los pagos");
				const allData = res.payload as SponsorPaymentsResponse;
				setPaymentsData(allData);
			})
			.finally(() => setIsLoading(false));
	}, [dispatch, id]);

	useEffect(() => {
		if (!id) return navigate(-1);
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, id]);

	const paymentsResults = useMemo(() => {
		const sortedData = paymentsData?.results.sort((a, b) => b.created_at.localeCompare(a.created_at));
		return sortedData;
	}, [paymentsData]);

	const handleModalBehavior = useCallback(() => setOpenModal(curr => !curr), []);

	const handleEditPayment = useCallback(
		(id: string) => {
			setSelectedPayment(id);
			const payment = paymentsResults?.find(item => item.id === id) ?? undefined;
			if (!payment) return;
			setPaymentAmount(payment.amount);
			setIsProfit(payment.is_profit);
			setModalType("edit");
			handleModalBehavior();
		},
		[paymentsResults, handleModalBehavior],
	);

	const resetStateValues = useCallback(() => {
		setIsProfit(false);
		setPaymentAmount(1);
		setModalType(undefined);
		setSelectedPayment("");
	}, []);

	const handleModalAction = useCallback(() => {
		switch (modalType) {
			case "new":
				if (!id || !paymentAmount || paymentAmount === 0) return toast.error("Monto de pago no válido");
				console.log(paymentAmount);

				dispatch(createSponsorPaymentAction({amount: paymentAmount, is_profit: isProfit, sponsor_id: id}))
					.then(res => {
						if (res.meta.requestStatus === "fulfilled") {
							toast.success("Pago creado exitosamente");
						}
					})
					.catch(() => toast.error("Ha ocurrido un error al crear el pago"))
					.finally(() => {
						resetStateValues();
						loadData();
					});
				break;
			case "delete":
				if (!selectedPayment) return;
				dispatch(removeSponsorPaymentAction(selectedPayment))
					.then(res => {
						if (res.meta.requestStatus === "fulfilled") {
							toast.success("Pago eliminado exitosamente");
						}
					})
					.catch(() => toast.error("Ha ocurrido un error al eliminar el pago"))
					.finally(() => {
						resetStateValues();
						loadData();
					});
				break;
			case "edit":
				if (!selectedPayment) return;
				dispatch(editSponsorPaymentAction({amount: paymentAmount, is_profit: isProfit, payment_id: selectedPayment}))
					.then(res => {
						if (res.meta.requestStatus === "fulfilled") {
							toast.success("Pago actualizado exitosamente");
						}
					})
					.catch(() => toast.error("Ha ocurrido un error al actualizar el pago"))
					.finally(() => {
						resetStateValues();
						loadData();
					});
				break;
			default:
				return;
		}
		handleModalBehavior();
	}, [modalType, selectedPayment, isProfit, paymentAmount, resetStateValues, handleModalBehavior, loadData, dispatch, id]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const AVATAR_COL_WIDTH = 60;

		if (tableWidth > 0) {
			tableWidth = tableWidth - AVATAR_COL_WIDTH;
		}

		return [
			columnHelper.accessor("id", {
				id: "id",
				header: "Id de Pago",
				cell: info => <span className="overflow-hidden text-ellipsis text-neutral-500">{info.row.original.id}</span>,
				size: Math.floor(tableWidth * 0.29),
			}),
			columnHelper.accessor("created_at", {
				id: "created_at",
				header: "Fecha de creacion",
				cell: info => (
					<span className="ml-2 overflow-hidden text-ellipsis text-neutral-500">
						{new Date(info.row.original.created_at).toLocaleDateString()}
					</span>
				),
				size: Math.floor(tableWidth * 0.18),
			}),
			columnHelper.accessor("updated_at", {
				id: "updated_at",
				header: "Fecha de actualizacion",
				cell: info => (
					<span className="ml-2 overflow-hidden text-ellipsis text-neutral-500">
						{new Date(info.row.original.updated_at).toLocaleDateString()}
					</span>
				),
				size: Math.floor(tableWidth * 0.18),
			}),
			columnHelper.accessor("amount", {
				id: "amount",
				header: "Monto",
				cell: info => <span className="ml-2 overflow-hidden text-ellipsis text-neutral-500">$ {info.row.original.amount}</span>,
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.accessor("is_profit", {
				id: "is_profit",
				header: "Es ingreso",
				cell: info => (
					<span
						className={
							"relative inline-block px-3 py-1 font-semibold leading-tight" +
							(!info.cell.row.original.is_profit ? " text-red-700" : " text-green-700")
						}
					>
						<ToolTip
							text={!info.cell.row.original.is_profit ? "No es ingreso" : "Ingreso"}
							placement={info.row.index === 0 ? "bottom" : "top"}
						>
							{!info.cell.row.original.is_profit ? <CancelCircleFilled /> : <CheckCircleFilled />}
						</ToolTip>
					</span>
				),
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.display({
				header: "Controles",
				cell: ({row}) => (
					<div className="flex w-full gap-2">
						<Button onClick={() => handleEditPayment(row.original.id)} disabled={false}>
							<Pencil className="h-5 w-5" />
						</Button>
						<Button
							className="bg-red-600 hover:bg-red-500"
							onClick={() => {
								setSelectedPayment(row.original.id);
								setModalType("delete");
								handleModalBehavior();
							}}
							disabled={false}
						>
							<Trash className="h-5 w-5" />
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.12),
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableRef, paymentsData]);

	return (
		<Fragment>
			<div className="mt-3 flex items-center justify-between border-gray-200 px-4 py-3 sm:px-6">
				<p className="text-base font-semibold leading-6 text-gray-900">Pagos Asociados:</p>
				<Button
					className="cursor-pointer gap-2"
					asChild
					onClick={() => {
						setModalType("new");
						handleModalBehavior();
					}}
				>
					<div>
						<ListPlus />
						Nuevo pago
					</div>
				</Button>
			</div>
			<div className="px-6">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					// @ts-ignore
					dataset={paymentsResults || []}
					pageCount={1}
					pagination={pagination}
					loading={isLoading}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={57}
					showPagination={false}
				/>
				<ConfirmationModal
					isProfit={isProfit}
					setIsProfit={setIsProfit}
					amountValue={paymentAmount}
					setAmountValue={setPaymentAmount}
					type={modalType}
					onAccept={handleModalAction}
					onCancel={() => {
						handleModalBehavior();
						resetStateValues();
					}}
					open={openModal}
				/>
			</div>
		</Fragment>
	);
}

interface ConfirmationModalProps {
	type: "edit" | "new" | "delete" | undefined;
	open: boolean;
	amountValue: number;
	isProfit: boolean;
	setAmountValue: (value: number) => void;
	setIsProfit: (value: boolean) => void;
	onCancel: () => void;
	onAccept: () => void;
}
const modalContentType = {
	delete: "Eliminar pago",
	new: "Nuevo pago",
	edit: "Editar pago",
};

const ConfirmationModal = ({open, type, amountValue, isProfit, setAmountValue, setIsProfit, onAccept, onCancel}: ConfirmationModalProps) => {
	const formSection = () => (
		<div className="mt-6 flex flex-col justify-start gap-4">
			<Input
				type="number"
				min={1}
				step={0.01}
				value={amountValue}
				className="bg-gray-500 text-white focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
				onChange={e => {
					setAmountValue(parseFloat(e.target.value));
				}}
				placeholder="Amount..."
			/>
			<div className="flex min-w-[150px] items-center gap-2 self-start text-sm text-gray-900 sm:mt-0">
				<Switch checked={isProfit} onCheckedChange={() => setIsProfit(!isProfit)} />
				<Label className="text-white">{isProfit ? "Cuenta" : "No cuenta"} como ingreso</Label>
			</div>
		</div>
	);
	return (
		<ActionConfirmModal
			open={open}
			description={
				type === "delete" ? (
					<span className="text-white">Al confirmar se eliminara este pago, este proceso no se puede revertir.</span>
				) : (
					formSection()
				)
			}
			onAccept={() => {
				onAccept();
			}}
			onCancel={() => {
				onCancel();
			}}
			title={modalContentType[type!]}
		/>
	);
};
