import Select, {SingleValue} from "react-select";
import {Button} from "../../../../components/primitives/Button";
import {Popover, PopoverContent, PopoverTrigger} from "../../../../components/primitives/Popover/popover";
import {ChevronRight} from "../../../../components/primitives/icons";
import {Fragment, useMemo, useState} from "react";
import ActionConfirmModal from "../../../../components/blocks/ActionConfirmModal";
import {SelectOptionType} from "../../../../../utils/globalTypes";
import {Content, GetContentsResponse} from "../../../../data-access/series/content";
import {useAppDispatch} from "../../../../_store/hooks";
import {serieContentsActions} from "../../../../_store/features/serie-content/serie-content-slice";
import {notificationsActions} from "../../../../_store/features/notify/notify-slice";
import toast from "react-hot-toast";
import {SendUserByViewsNotification} from "../../../../data-access/notify";

interface MarketingNotificationModalProps {
	series: SelectOptionType[] | undefined;
	open: boolean;
	setOpen: (val: boolean) => void;
	data: SendUserByViewsNotification;
	disabled?: boolean;
}

export default function MarketingNotificationModal({open, series, data, disabled, setOpen}: MarketingNotificationModalProps) {
	const dispatch = useAppDispatch();
	const [notificationMessage, setNotificationMessage] = useState<string>("");
	const [notificationTitle, setNotificationTitle] = useState<string>("");
	const [selectedSerie, setSelectedSerie] = useState<SelectOptionType | undefined>(undefined);
	const [selectedContent, setSelectedContent] = useState<SelectOptionType | undefined>(undefined);

	const [contents, setContents] = useState<Content[]>([]);

	const [isLoadingContents, setIsLoadingContents] = useState(false);

	const resetState = () => {
		setNotificationTitle("");
		setNotificationMessage("");
		setSelectedSerie(undefined);
		setSelectedContent(undefined);
		setOpen(false);
	};

	const handleSelectSerie = (newValue: SingleValue<SelectOptionType>) => {
		if (!newValue || newValue.value === "") {
			setSelectedSerie(undefined);
			return;
		}
		setIsLoadingContents(true);
		dispatch(serieContentsActions.listSeriesContent({searchby: "serieid", searchvalue: newValue.value}))
			.then(res => {
				if (res.meta.requestStatus === "rejected" || !res.payload) return;
				if (selectedSerie?.value !== newValue.value) {
					setSelectedContent(undefined);
				}
				setSelectedSerie(newValue);
				const data = res.payload as GetContentsResponse;
				setContents(data.results);
			})
			.finally(() => setIsLoadingContents(false));
	};

	const handleSelectContent = (newValue: SingleValue<SelectOptionType>) => {
		if (!newValue || newValue.value === "") {
			setSelectedContent(undefined);
			return;
		}
		setSelectedContent(newValue);
	};

	const contentsOptions: SelectOptionType[] = useMemo(() => {
		if (!contents || contents.length === 0) return [];
		const options = contents.map(serie => ({label: serie.title, value: serie.id}));
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSerie, contents.length]);

	const onSendNotification = () => {
		if (notificationMessage.length <= 3 || !selectedContent || notificationTitle.length <= 3) {
			toast.error("Debe asegurarse de escribir un mensaje valido y seleccionar un contenido");
			return;
		}
		dispatch(
			notificationsActions.sendUserByViewsNotificationsAction({
				...data,
				to_content_id: selectedContent.value,
				title: notificationTitle,
				message: notificationMessage,
				click_action: "123",
				web_link: "123",
			}),
		).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Notificación enviada");
				return;
			}
			toast.error("Error al enviar notificación");
		});
		resetState();
	};

	const isDisableContentSelector = !series || series.length === 0 || !selectedSerie;

	return (
		<Fragment>
			<Popover>
				<PopoverTrigger asChild>
					<Button className="h-9 bg-black text-white" size={"sm"} disabled={disabled} variant={"outline"}>
						Enviar notificaciones
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-fit p-0" align="end">
					<div className="rounded-lg bg-neutral-100 p-5">
						<div className="mb-2 border-l-4 border-orange-500 bg-orange-100 p-4 text-orange-700" role="alert">
							<p className="font-bold">¡Cuidado!</p>
							<p>Esta acción enviara una notificación a todos los usuarios filtrados.</p>
						</div>
						<h3 className="text-md mt-4 text-start leading-none tracking-tight">Contenido al cual redireccionará la notificación:</h3>
						<div className="my-2 flex max-w-md justify-between gap-3">
							<Select value={selectedSerie} options={series ?? []} className="w-1/2" onChange={handleSelectSerie} />
							<Select
								value={selectedContent}
								options={contentsOptions}
								className="w-1/2"
								isDisabled={isDisableContentSelector || isLoadingContents}
								isLoading={isLoadingContents}
								onChange={handleSelectContent}
							/>
						</div>
						<div className="max-w-2xl">
							<div className="mb-3">
								<label htmlFor="notification_title">Título*</label>
								<input
									name="notification_title"
									placeholder="Título de Notificación"
									className={`mt-1 w-full rounded px-3 py-2 ring-1 ring-neutral-800 `}
									onChange={e => setNotificationTitle(e.target.value)}
								/>
							</div>
						</div>
						<div className="max-w-2xl">
							<div className="mb-3">
								<label htmlFor="notification_message">Mensaje*</label>
								<textarea
									name="notification_message"
									rows={3}
									placeholder="Mensaje de Notificación"
									className={`mt-1 w-full rounded px-3 py-2 ring-1 ring-neutral-800 `}
									onChange={e => setNotificationMessage(e.target.value)}
								/>
							</div>
						</div>
						<div className="mt-2 flex w-full justify-end gap-4">
							<button
								className="col-start-2 flex items-center justify-center rounded bg-neutral-900 px-4 py-2 uppercase text-white transition-colors duration-300 hover:bg-neutral-700"
								onClick={() => setOpen(true)}
							>
								Enviar
								<ChevronRight />
							</button>
						</div>
					</div>
				</PopoverContent>
			</Popover>
			<ActionConfirmModal
				title="Enviar notificación"
				description={<span className="text-white">Al confirmar se enviara una notificación a los usuarios filtrados.</span>}
				open={open}
				onAccept={() => onSendNotification()}
				onCancel={() => resetState()}
			/>
		</Fragment>
	);
}
