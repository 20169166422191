import {DotLottiePlayer as LottiePlayer} from "@dotlottie/react-player";
import {cn} from "../../../../utils/classNames";

interface StickerRendererProps {
	url: string;
	className?: string;
}

export default function StickerRenderer(props: StickerRendererProps) {
	const {url, className} = props;

	if (url.endsWith(".json") || url.endsWith(".lottie")) {
		return <LottiePlayer autoplay loop src={url} className={cn("flex aspect-square justify-center", className)} />;
	}

	return <img src={url} className={cn("w-full object-contain", className)} alt="Thumbnail" />;
}
