import {Combobox} from "@headlessui/react";
import {useEffect, useMemo, useState, FocusEvent} from "react";
import {Spinner} from "../../../../components/primitives/icons/Spinner";
import {auth} from "../../../../firebase";
import {Video, videosActions} from "../../../../_store/features/videos/videos-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";

const initialVideoState: Video = {
	uploadid: "",
	assetid: "",
	playbackid: "",
	status: "",
	duration: 0,
	signed: false,
	title: "",
	author: "",
	upload_url: "",
	video_type: 0,
};

interface MuxVideoComboboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
	id?: string;
	name?: string;
	onChangeSelected: (selected: Video) => void;
	defaultValue: string;
	video_type?: number;
}
function MuxVideoCombobox({id, name, defaultValue, video_type = 0, onChangeSelected, className, placeholder}: MuxVideoComboboxProps) {
	const [selectedVideo, setSelectedVideo] = useState<Video>(initialVideoState);
	const [query, setQuery] = useState("");
	const dispatch = useAppDispatch();
	const muxState = useAppSelector(state => state.videos);

	const filteredVideos = useMemo(() => {
		if (!muxState.results) return [];
		if (query === "") setSelectedVideo(initialVideoState);

		return query === ""
			? muxState.results
			: muxState.results.filter((video: Video) => {
					if (!video.title) return false;
					return video.title.toLowerCase().includes(query.toLowerCase());
			  });
	}, [muxState.results, query]);

	const handleFocusOnMuxVIdeos = (e: FocusEvent<HTMLInputElement>) => {
		e.target.select();
		dispatch(
			videosActions.getVideosList({
				GCPUser: auth.currentUser!,
				params: {page_size: 99999999, video_type},
			}),
		);
	};

	useEffect(() => {
		if (!selectedVideo.playbackid) return;
		onChangeSelected?.({...selectedVideo, duration: parseInt(selectedVideo.duration.toFixed(0))});
	}, [onChangeSelected, selectedVideo]);

	const classNameMuxVideoCombobox =
		className || "mb-2 w-full rounded border-none bg-black px-3 py-2 text-sm leading-5 text-white outline-none focus:ring-0";

	return (
		<div className="relative">
			<Combobox value={selectedVideo} onChange={setSelectedVideo} defaultValue={initialVideoState}>
				<Combobox.Input
					id={id}
					name={name}
					onChange={event => setQuery(event.target.value)}
					className={classNameMuxVideoCombobox}
					onFocus={handleFocusOnMuxVIdeos}
					placeholder={placeholder || "Escribe el titulo"}
					displayValue={(video: any) => video.playbackid || query || defaultValue}
					autoComplete="off"
				/>
				<Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
					{filteredVideos.length === 0 && query !== "" && (
						<div className="relative cursor-default select-none px-4 py-2 text-gray-700">No hay video con ese titulo.</div>
					)}
					{muxState.loading ? (
						<div className="mx-auto flex items-center justify-center">
							<Spinner />
						</div>
					) : (
						filteredVideos.map((video: Video) => (
							<Combobox.Option
								key={video.uploadid}
								value={video}
								className={({active}) =>
									`relative cursor-default select-none overflow-hidden text-ellipsis whitespace-nowrap px-4 py-2 ${
										active ? "bg-neutral-600 text-white" : "text-gray-900"
									}`
								}
							>
								{video.title} - {video.duration}seg - {!video.playbackid ? "Sin Playback" : "Disponible"}
							</Combobox.Option>
						))
					)}
				</Combobox.Options>
			</Combobox>
		</div>
	);
}

export default MuxVideoCombobox;
