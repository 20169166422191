import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {auth} from "../../../firebase";
import {ListResponse} from "../../../../utils/globalTypes";
import {
	AnalysisFeedbackTriggersParams,
	AnalysisFeedbackTriggersResponse,
	ManageTriggerPayload,
	OverallFeedbackParams,
	OverallFeedbackResponse,
	RecentFeedbackResponse,
	RefreshResponse,
	ResponseFeedbackEvent,
} from "./types";

const apiBaseUrl = process.env.REACT_APP_APP_DOMAIN;

export interface FeedbackListParams {
	searchby: string; // "reason" | "userid" | "feedback";
	searchvalue: string;
	pageIndex: number;
	pageSize: number;
	sortBy: string; // "created_at" | "feedback";
	sortOrder: string; // "asc" | "desc";
}

export interface Feedback {
	id: string;
	userid: string;
	feedback: number;
	reason: string;
	created_at: string;
	updated_at: string;
	next_feedback_allowed_at: string | null;
	name: string | null;
	email: string | null;
}

const baseQuery = fetchBaseQuery({
	baseUrl: apiBaseUrl,
	async prepareHeaders(headers) {
		const token = await auth.currentUser?.getIdToken();
		if (token) {
			headers.set("Authorization", `Bearer ${token}`);
		}
		headers.set("Content-Type", "application/json");
		headers.set("Accept", "*/*");
		return headers;
	},
});

export const feedbackApi = createApi({
	reducerPath: "feedback",
	baseQuery,
	tagTypes: ["FEEDBACK_OVERVIEW", "FEEDBACK_TRIGGERS_ANALYSIS"],
	endpoints: builder => ({
		getFeedbackList: builder.query<ListResponse<Feedback>, Partial<FeedbackListParams>>({
			query: params => ({
				url: "/admin_get_feedbacks_list",
				params: {
					...params,
					page: params.pageIndex,
					page_size: params.pageSize,
				},
			}),
		}),
		getOverallFeedbackStats: builder.query<OverallFeedbackResponse, OverallFeedbackParams>({
			query: params => ({
				url: "/feedback_overview",
				params,
			}),
			transformResponse: (response: any) => {
				if (response.status !== "OK") {
					throw new Error(response.status);
				}
				return response;
			},

			providesTags: result => {
				if (result?.status === "OK") {
					return [
						{type: "FEEDBACK_OVERVIEW", id: `${result.start_date}:${result.end_date}:${result.group_by}`},
						{type: "FEEDBACK_OVERVIEW", id: "LIST"},
					];
				}
				return [{type: "FEEDBACK_OVERVIEW", id: "LIST"}];
			},
		}),
		getRecentsFeedbacks: builder.query<RecentFeedbackResponse, void>({
			query: () => ({
				url: "/admin_get_recents_feedbacks",
			}),
			transformResponse: (response: any) => {
				if (response.status !== "OK") {
					throw new Error(response.status);
				}
				return response;
			},
		}),
		getFeedbackEvents: builder.query<ResponseFeedbackEvent, void>({
			query: () => "/list_feedback_events",
		}),
		getFeedbackTriggersAnalysis: builder.query<AnalysisFeedbackTriggersResponse, AnalysisFeedbackTriggersParams>({
			query: params => ({
				url: "/admin_feedback_triggers_analysis",
				params,
			}),
			transformResponse: (response: any) => {
				if (response.status !== "OK") {
					throw new Error(response.status);
				}
				return response;
			},
			providesTags: (result, _, params) => {
				if (result?.status === "OK") {
					return [
						{type: "FEEDBACK_TRIGGERS_ANALYSIS", id: `${params.start_date}:${params.end_date}`},
						{type: "FEEDBACK_TRIGGERS_ANALYSIS", id: "OBJECT"},
					];
				}
				return [{type: "FEEDBACK_TRIGGERS_ANALYSIS", id: "OBJECT"}];
			},
		}),
		refreshView: builder.mutation<RefreshResponse, OverallFeedbackParams>({
			query: params => {
				return {
					url: "/admin_refresh_feedback_view",
					params,
					method: "PATCH",
				};
			},
			transformResponse: (response: any) => {
				if (response.status !== "OK") {
					throw new Error(response.status);
				}
				return response;
			},
			invalidatesTags: (_, _error, params) => {
				return [
					{type: "FEEDBACK_TRIGGERS_ANALYSIS", id: `${params.start_date}:${params.end_date}`},
					{type: "FEEDBACK_TRIGGERS_ANALYSIS", id: "OBJECT"},
					{type: "FEEDBACK_OVERVIEW", id: `${params.start_date}:${params.end_date}:${params.group_by}`},
					{type: "FEEDBACK_OVERVIEW", id: "LIST"},
				];
			},
		}),
		manageTrigger: builder.mutation<RefreshResponse, ManageTriggerPayload>({
			query: body => {
				return {
					url: "/admin_manage_feedback_trigger",
					body,
					method: "POST",
				};
			},
			transformResponse: (response: any) => {
				if (response.status !== "OK") {
					throw new Error(response.status);
				}
				return response;
			},
			invalidatesTags: (_, _error) => {
				return [{type: "FEEDBACK_TRIGGERS_ANALYSIS", id: "OBJECT"}];
			},
		}),
	}),
});

export const {
	useGetFeedbackListQuery,
	useLazyGetFeedbackListQuery,
	useGetOverallFeedbackStatsQuery,
	useLazyGetOverallFeedbackStatsQuery,
	useGetRecentsFeedbacksQuery,
	useLazyGetRecentsFeedbacksQuery,
	useGetFeedbackTriggersAnalysisQuery,
	useLazyGetFeedbackTriggersAnalysisQuery,
	useGetFeedbackEventsQuery,
	useLazyGetFeedbackEventsQuery,
	useRefreshViewMutation,
	useManageTriggerMutation,
} = feedbackApi;
