import {useEffect, useState, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {auth} from "../../firebase";
import {Refresh} from "../../components/primitives/icons/Refresh";
import {Pencil} from "../../components/primitives/icons";
import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {FormDataResult, editInitialState} from "../../_store/features/forms/customer-service-slice";
import {fetchContentFormData} from "../../_store/features/forms/customer-service-actions";
import MessageReplySheet from "./subcomponents/MessageReplySheet";
import {DataTable} from "../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import {Button} from "../../components/primitives/Button";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";

const searchOptions = [
	{display: "Nombre", searchby: "name"},
	{display: "Correo", searchby: "email"},
	{display: "Teléfono", searchby: "phone"},
	{display: "ID de Usuario", searchby: "userid"},
];

const statusOptions = [
	{display: "Todos", value: undefined},
	{display: "Pendiente", value: 0},
	{display: "Visto", value: 1},
	{display: "Resuelto", value: 2},
];

const columnHelper = createColumnHelper<FormDataResult>();

function Contacts() {
	const reduxFormData = useAppSelector(state => state.formData);
	const dispatch = useAppDispatch();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
	const [searchValue, setSearchValue] = useState("");
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const [isReplySheetOpen, setIsReplySheetOpen] = useState(false);
	const [selectedFormData, setSelectedFormData] = useState<FormDataResult>(() => {
		return editInitialState;
	});

	const handleSearch = () => {
		if (pagination.pageSize === 0) return;
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchContentFormData({
					idToken,
					page: pagination.pageIndex,
					page_size: pagination.pageSize,
					searchby: selectedSearchBy.searchby,
					searchvalue: searchValue,
					status: selectedStatus.value,
				}),
			);
		});
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("name", {
				id: "Nombre / UUID",
				header: ({column}) => <DataTableColumnHeader title="Nombre / UUID" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						{info.row.original.name ? (
							<span className="overflow-hidden text-ellipsis text-left text-sm">{info.row.original.name}</span>
						) : (
							<span className="overflow-hidden text-ellipsis text-left text-sm">Anónimo</span>
						)}
						<span className="overflow-hidden text-ellipsis text-left text-sm text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth / 7),
			}),
			columnHelper.accessor("email", {
				id: "Email",
				header: ({column}) => <DataTableColumnHeader title="Email" column={column} />,
				cell: info => <div className="w-full text-left text-sm">{info.getValue()}</div>,
				size: Math.floor(tableWidth / 7),
			}),
			columnHelper.accessor("phone", {
				id: "No. Teléfono",
				header: ({column}) => <DataTableColumnHeader title="No. Teléfono" column={column} />,
				cell: info => <div className="w-full text-left text-sm">{info.getValue()}</div>,
				size: Math.floor(tableWidth / 7),
			}),
			columnHelper.accessor("desired_response", {
				id: "Vía de contacto",
				header: ({column}) => <DataTableColumnHeader title="Vía de contacto" column={column} />,
				cell: info => <div className="w-full text-left text-sm">{info.getValue()}</div>,
				size: Math.floor(tableWidth / 7),
			}),
			columnHelper.accessor("message", {
				id: "Mensaje",
				header: ({column}) => <DataTableColumnHeader title="Mensaje" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left text-sm text-neutral-500">
							{new Date(info.row.original.audit_created).toLocaleString("en-US")}
						</span>
						<span className="overflow-hidden text-ellipsis text-left text-sm text-neutral-500">{info.row.original.message}</span>
					</div>
				),
				size: Math.floor(tableWidth / 7),
			}),
			columnHelper.accessor("status", {
				id: "Estado",
				header: ({column}) => <DataTableColumnHeader title="Estado" column={column} />,
				cell: info => (
					<div className="flex w-full gap-2">
						<span
							className={
								"relative inline-block justify-start text-left text-sm font-semibold leading-tight" +
								(info.cell.row.original.status === 0 ? " text-red-700" : " text-green-700")
							}
						>
							{(() => {
								switch (info.cell.row.original.status) {
									case 0:
										return "Pendiente";
									case 1:
										return "Visto";
									case 2:
										return "Resuelto";
									default:
										return "";
								}
							})()}
						</span>
					</div>
				),
				size: Math.floor(tableWidth / 7),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full gap-2">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setSelectedFormData(info.row.original);
								setIsReplySheetOpen(true);
							}}
							className="justify-start"
						>
							<Pencil className="h-4 w-4" />
						</Button>
					</div>
				),
				size: Math.floor(tableWidth / 7),
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const refresh = () => {
		setSelectedStatus(statusOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		setPagination(prev => ({
			...prev,
			pageIndex: 0,
		}));
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchContentFormData({
					idToken,
					page: 0,
					page_size: pagination.pageSize,
					searchby: searchOptions[0].searchby,
					searchvalue: "",
					status: statusOptions[0].value,
				}),
			);
		});
	};

	const handleStatusFilter = (value: string) => {
		const status = statusOptions.find(el => el.display === value) ?? statusOptions[0];
		setSelectedStatus(statusOptions.find(el => el.display === value) ?? statusOptions[0]);
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchContentFormData({
					idToken,
					page: pagination.pageIndex,
					page_size: pagination.pageSize,
					searchby: selectedSearchBy.searchby,
					searchvalue: searchValue,
					status: status.value,
				}),
			);
		});
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.searchby === value) ?? searchOptions[0]);
	};

	return (
		<>
			<div className="flex h-screen flex-col">
				<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
					<h2 className="text-2xl font-bold tracking-tight">Contactos</h2>
				</div>
				<div className="flex gap-2 px-6 pt-4">
					<div className="flex w-full gap-2">
						<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
							<Refresh className="h-4 w-4" />
						</Button>
						<div className="flex w-full">
							<Input
								className="h-8 rounded-r-none"
								type="text"
								onChange={e => setSearchValue(e.target.value)}
								value={searchValue}
								placeholder="Buscar..."
							/>
							<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.searchby}>
								<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
									<span>Buscar Por:</span>
									<SelectValue placeholder="" />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										{searchOptions.map((sOption, idx) => (
											<SelectItem key={idx} value={`${sOption.searchby}`}>
												{sOption.display}
											</SelectItem>
										))}
									</SelectGroup>
								</SelectContent>
							</Select>
							<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
								Buscar
							</Button>
						</div>

						<Select onValueChange={handleStatusFilter} value={selectedStatus.display}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
								<span>Mostrar:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{statusOptions.map((pOption, idx) => (
										<SelectItem key={idx} value={`${pOption.display}`}>
											{pOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
				</div>
				<div className="flex h-full flex-col p-6 pt-4">
					<DataTable
						ref={ref => setTableRef(ref)}
						columns={columns}
						dataset={reduxFormData.results as FormDataResult[]}
						pageCount={Math.ceil(reduxFormData.totalResults / reduxFormData.pageSize)}
						pagination={pagination}
						loading={reduxFormData.loading}
						onPaginationChange={setPagination}
						withDynamicPageSize
						rowHeight={55}
						showPagination={false}
					/>
				</div>
				<MessageReplySheet
					contactData={selectedFormData}
					open={isReplySheetOpen}
					onOpenChange={o => {
						setIsReplySheetOpen(o);
					}}
				/>
			</div>
		</>
	);
}

export default Contacts;
