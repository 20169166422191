import {useMemo, useRef} from "react";
import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, ChartOptions, LinearScale} from "chart.js";
import "chartjs-adapter-date-fns";
import {format, startOfMonth, getDaysInMonth, addDays} from "date-fns";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {es} from "date-fns/locale";
import {ReportResponse} from "../../../../../data-access/report/types";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

interface StreamingChartProps {
	source: ReportResponse;
}

const StreamingChart = ({source}: StreamingChartProps) => {
	const {report, creation_date} = source;
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; count: number}[]>>(null);

	const data: ChartData<"bar", {date: string; count: number}[]> = useMemo(() => {
		const creationDate = new Date(creation_date);
		creationDate.setTime(creationDate.getTime() + creationDate.getTimezoneOffset() * 60 * 1000);

		const initialDate = startOfMonth(creationDate);
		const rangeLenght = getDaysInMonth(initialDate);

		const selectedData: {date: string; count: number}[] = Array.from({length: rangeLenght}, (_, i) => ({
			date: addDays(initialDate, i).toLocaleDateString(),
			count: 0,
		}));

		const seriesDatasets = report.series
			.map(serie => {
				if (!serie.isPartner || serie.views.length === 0) return null;
				const serieSelectedData = selectedData.map(data => ({...data}));
				serie.views.map(view => {
					const date = new Date(view.year + "-" + view.month + "-" + view.day);
					const index = selectedData.findIndex(data => data.date === date.toLocaleDateString());
					if (index !== -1) {
						serieSelectedData[index].count += view.total_time_seconds / 3600;
						return null;
					}
					return null;
				});
				return {
					label: serie.title + " - $" + serie.partner_earnings_by_seconds?.toFixed(2),
					data: serieSelectedData,
					backgroundColor: serie.color,
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "count",
					},
				};
			})
			.filter(serie => serie !== null) as {
			label: string;
			data: {date: string; count: number}[];
			backgroundColor: string;
			borderRadius: number;
			parsing: {xAxisKey: string; yAxisKey: string};
		}[];

		return {
			labels: selectedData.map(data => data.date),
			datasets: seriesDatasets,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report.series]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="relative col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Horas reproducidas de los contenidos asociados</h3>
				</div>

				<div className="relative p-6 pt-0">
					<Bar
						height={350}
						ref={chartRef}
						options={{
							...options,
							scales: {
								x: {
									ticks: {
										callback(tickValue) {
											let value = this.getLabelForValue(tickValue as any);
											const date: Date = new Date(value);
											let formattedDate: string = format(date, "MMM d", {locale: es}).toLowerCase();
											return formattedDate;
										},
									},
									grid: {
										color: "transparent",
									},
									stacked: true,
								},
								y: {
									ticks: {
										precision: 1,
									},
									stacked: true,
								},
							},
						}}
						data={data}
					/>
				</div>
			</div>
		</div>
	);
};

export default StreamingChart;

const options: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					return tooltipItems[0].label + " - Horas de Streaming";
				},
				label(context) {
					let label = context.dataset.label || "";
					label = label.slice(0, label.indexOf("-") - 1);
					if (context.parsed.y !== null) {
						label += ": " + context.parsed.y.toFixed(2) + " horas";
					}
					return label;
				},
			},
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d", {locale: es}).toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
	},
};
