import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface VersionsListResponse {
	id?: string;
	app_version: number;
	release_notes: string;
	major_release: boolean;
	critical_release: boolean;
	link: string;
	audit_created?: string;
	audit_updated?: string;
}

export interface EditInsertVersionInfoParams {
	id?: string;
	app_version: number;
	release_notes: {};
	major_release: boolean;
	critical_release: boolean;
	link?: string;
}

export const newVersionInitialState: EditInsertVersionInfoParams = {
	id: "",
	app_version: 0,
	release_notes: "",
	major_release: true,
	critical_release: false,
	link: "",
};

export async function getVersionsList(token: string): Promise<AxiosResponse<VersionsListResponse[]>> {
	const idToken = `Bearer ${token}`;

	const response = await axiosHandler.get("/admin_get_app_version_info", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
	return response;
}

export async function editVersionInfo(token: string, data: EditInsertVersionInfoParams): Promise<AxiosResponse<VersionsListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_app_version_info", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function insertVersionInfo(token: string, data: EditInsertVersionInfoParams): Promise<AxiosResponse<VersionsListResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/insert_app_version_info", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface DeleteAppVersionParam {
	id: string;
}

export interface DeleteAppVersionResponse {
	result: string;
}

export async function deleteVersionInfo(token: string, data: DeleteAppVersionParam): Promise<AxiosResponse<DeleteAppVersionResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete(`/delete_app_version_info?id=${data.id}`, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
