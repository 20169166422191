import {useEffect, useState, useMemo, useCallback} from "react";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {Avatar, AvatarFallback, AvatarImage} from "../../../../components/primitives/Avatar";
import {Refresh} from "../../../../components/primitives/icons/Refresh";
import {BarsPlus} from "../../../../components/primitives/icons";
import {Link} from "react-router-dom";
import {Button} from "../../../../components/primitives/Button";
import {Input} from "../../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../../components/primitives/Select";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTable} from "../../../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {seriesActions} from "../../../../_store/features/series/series-slice";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {InfluencersBySeriesProfitResponse} from "../../../../data-access/dashboard/types";
import removeDuplicatesSeriesObjects, {seriesOptions} from "../../../../../utils/removeDuplicatedSeries";

const searchOptions: {display: string; searchby: "influencer_name" | "series_name" | "seriesid"}[] = [
	{display: "Nombre", searchby: "influencer_name"},
	{display: "Serie", searchby: "series_name"},
];

const seriesInitialState = [{display: "Todos", value: undefined}];

interface orderByType {
	display: string;
	value?: "profits" | "name";
}

const orderBy: orderByType[] = [
	{display: "Todos", value: undefined},
	{display: "Mayor Ganancia", value: "profits"},
	{display: "Alfabéticamente", value: "name"},
];

const columnHelper = createColumnHelper<InfluencersBySeriesProfitResponse>();

function InfluencerList() {
	const series = useAppSelector(state => state.series.results);
	const influencers = useDashboardByNameQuery("influencerProfitByContent");
	const dispatch = useAppDispatch();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [selectedSearchBy, setSelectedSearchBy] = useState<{display: string; searchby: "influencer_name" | "series_name" | "seriesid"} | undefined>(
		searchOptions[0],
	);
	const [seriesList, setSeriesList] = useState<seriesOptions[]>(seriesInitialState);
	const [selectedSerie, setSelectedSerie] = useState<seriesOptions>(seriesInitialState[0]);
	const [selectedOrder, setSelectedOrder] = useState(orderBy[0]);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [searchValue, setSearchValue] = useState<string>("");

	const handleSearch = (reset?: boolean) => {
		if (reset) {
			dispatch(
				dashboardActions.getInfluencerProfitByContent({
					page: 0,
					page_size: pagination.pageSize,
				}),
			);
			setPagination(prev => ({...prev, pageIndex: 0}));
			return;
		}
		const order = selectedOrder.value !== undefined ? (selectedOrder.value === "profits" ? "desc" : "asc") : undefined;
		dispatch(
			dashboardActions.getInfluencerProfitByContent({
				searchby: searchValue || selectedSerie.value ? selectedSearchBy?.searchby : undefined,
				searchvalue: selectedSerie.value || searchValue || undefined,
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				order,
				orderby: selectedOrder.value,
			}),
		);
	};

	useEffect(() => {
		if (!pagination.pageSize || pagination.pageSize === 0) return;
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, selectedSearchBy, selectedOrder, searchValue, selectedSerie]);

	useEffect(() => {
		setSeriesList(prev => {
			const data = prev.concat(
				series.map(serie => ({
					display: serie.title,
					value: serie.id,
				})),
			);
			return removeDuplicatesSeriesObjects(data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [series.length]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page_size: 999}}));
	}, [dispatch]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const AVATAR_COL_WIDTH = 60;
		if (tableWidth > 0) tableWidth = tableWidth - AVATAR_COL_WIDTH;

		return [
			columnHelper.accessor("photo_url", {
				id: "Avatar",
				header: ({column}) => <DataTableColumnHeader title="Avatar" column={column} />,
				cell: info => (
					<Avatar>
						<AvatarImage src={info.row.original?.photo_url ?? ""} alt="User profile" />
						<AvatarFallback>{info.row.original?.name?.slice(0, 2)}</AvatarFallback>
					</Avatar>
				),
				size: AVATAR_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("name", {
				id: "Nombre / UUID",
				header: "Nombre / UUID",
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
						<span className="overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.influencer_id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.accessor("series", {
				id: "Serie / Contenido",
				header: "Serie / Contenido",
				cell: info => (
					<div className="flex h-14 w-full flex-col justify-start">
						{info.row.original.series.slice(0, 3).map(serie => (
							<span className="overflow-hidden text-ellipsis text-left leading-none">{serie.series_name}</span>
						))}
					</div>
				),
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("total_membership_profit", {
				id: "Ganancia Total",
				header: "Ganancia Total",
				cell: info => {
					const {original} = info.row;
					const membershipProfit = original.total_membership_profit ? original.total_membership_profit : 0;
					const subscriptionProfit = original.total_subscription_profit ? original.total_subscription_profit : 0;
					const profit = (membershipProfit * 0.01 + subscriptionProfit * 0.01).toFixed(2);
					return <div className="w-full truncate text-left">$ {profit}</div>;
				},
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.accessor("personal_membership_profit", {
				id: "Ganancia Personal",
				header: "Ganancia Personal",
				cell: info => {
					const {original} = info.row;
					const membershipProfit = original.personal_membership_profit ? original.personal_membership_profit : 0;
					const subscriptionProfit = original.personal_subscription_profit ? original.personal_subscription_profit : 0;
					const profit = (membershipProfit * 0.01 + subscriptionProfit * 0.01).toFixed(2);
					return <div className="w-full truncate text-left">$ {profit}</div>;
				},
				size: Math.floor(tableWidth * 0.13),
			}),
			columnHelper.accessor("platform_membership_profit", {
				id: "Ganancia Plataforma",
				header: "Ganancia Plataforma",
				cell: info => {
					const {original} = info.row;
					const membershipProfit = original.platform_membership_profit ? original.platform_membership_profit : 0;
					const subscriptionProfit = original.platform_subscription_profit ? original.platform_subscription_profit : 0;
					const profit = (membershipProfit * 0.01 + subscriptionProfit * 0.01).toFixed(2);
					return <div className="w-full truncate text-left">$ {profit}</div>;
				},
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.display({
				header: "Detalles",
				cell: info => (
					<div className="flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/corporate/influencer/" + info.row.original.name + "/" + info.row.original.influencer_id}>
								<BarsPlus className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
				enableResizing: false,
			}),
		];
	}, [tableRef]);
	console.log(pagination);

	const refresh = useCallback(() => {
		setSelectedSearchBy(searchOptions[0]);
		setSelectedOrder(orderBy[0]);
		setSelectedSerie(seriesInitialState[0]);
		setSearchValue("");
		handleSearch(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOrderByFilter = (value: string) => {
		const orderOption = orderBy.find(el => el.display === value) ?? orderBy[0];
		setSelectedOrder(orderOption);
	};

	const handleSearchByFilter = (value: string) => {
		const search = searchOptions.find(el => el.searchby === value) ?? searchOptions[0];
		setSelectedSearchBy(search);
	};

	const handleSerieFilter = (value: string) => {
		const serie = seriesList.find(el => el.display === value) ?? seriesInitialState[0];
		setSelectedSerie(serie);
		setSelectedSearchBy(
			serie.value
				? {
						display: "Serie",
						searchby: "seriesid",
				  }
				: undefined,
		);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Listado de Asociados</h2>
			</div>
			<div className="flex w-full justify-between gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value as "influencer_name" | "series_name")}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy?.searchby}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.searchby}`}>
											{sOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={() => handleSearch()}>
							Buscar
						</Button>
					</div>
				</div>

				<div className="flex gap-5">
					<Select onValueChange={handleSerieFilter} value={selectedSerie.display}>
						<SelectTrigger className="h-8 w-full  max-w-2xl gap-1 whitespace-nowrap">
							<span>Serie:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent className="max-h-fit">
							<SelectGroup className="max-h-[360px] scrollbar-thin scrollbar-track-card scrollbar-thumb-gray-700">
								{seriesList.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.display}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>

					<Select onValueChange={handleOrderByFilter} value={selectedOrder.display}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{orderBy.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.display}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={influencers.data?.results || []}
					pageCount={influencers.data?.results ? Math.ceil(influencers.data.totalResults / influencers.data.pageSize) : 0}
					pagination={pagination}
					loading={influencers.isLoading}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={70}
					showPagination={false}
				/>
			</div>
		</div>
	);
}

export default InfluencerList;
