import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export type Setting = {
	id: string;
	key: string;
	value: string;
};

export async function getGlobalSetting(token: string): Promise<AxiosResponse<Setting[]>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_global_settings", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function deleteGlobalSetting(token: string, id: string) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/admin_delete_config", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params: {id},
	});
}

export async function upsertGlobalSetting(token: string, key: string, value: string) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch(
		"/admin_edit_config",
		{key, value},
		{
			headers: {
				Accept: "*/*",
				Authorization: idToken,
			},
		},
	);
}
