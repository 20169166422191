import {useCallback, useEffect, useMemo, useState} from "react";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import {Pie} from "react-chartjs-2";
import {FilterValue, getDimensions} from "../../../../data-access/mux";
import {auth} from "../../../../firebase";
import {MEMBERSHIP_TYPES} from "../../../../constants";
import {DatePickerWithRange} from "../../DateRangePicker";
import {addDays, endOfToday, roundToNearestMinutes, startOfDay, subDays} from "date-fns";
import {Spinner} from "../../../primitives/icons";
import {DateRange} from "react-day-picker";
import {zonedTimeToUtc} from "date-fns-tz";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ViewsProps {
	serieid?: string;
	contentid?: string;
	codeReferral?: string;
	title?: string;
	loading?: boolean;
}

const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "bottom" as const,
		},
	},
};

const COLORS = new Map([
	[0, "rgba(18, 18, 18, 0.31)"],
	[1, "rgba(0, 80, 150, 1)"],
	[2, "rgba(242, 116, 116, 1)"],
	[3, "rgba(242, 116, 116, 1)"],
]);

const MEMBERSHIP_TYPES_EXTENSIONS = [...MEMBERSHIP_TYPES, {value: 3, label: "Miembro VIP (descuento)"}];

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function MembershipUserViews(props: ViewsProps) {
	const {serieid, contentid, codeReferral, title = "Visualizaciones por usuarios", loading} = props;
	const [error, setError] = useState("");
	const [values, setValues] = useState<FilterValue[]>();
	const [timeframe, setTimeframe] = useState<string[]>(["30:day"]);
	const [innerLoading, setInnerLoading] = useState(false);
	const [date, setDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = startOfDay(subDays(to, 30));
		return {
			from: zonedTimeToUtc(from, timeZone),
			to: zonedTimeToUtc(to, timeZone),
		};
	});
	let serieidList: string[];

	if (serieid?.includes(",")) {
		serieidList = serieid?.split(",");
	}

	const data = useMemo(() => {
		const groupedFilterValues: FilterValue[] = [];
		let total_count = 0;

		values?.forEach(item => {
			if (item.value === "2" || item.value === "3") {
				total_count += item.total_count;
			} else {
				groupedFilterValues.push(item);
			}
		});

		groupedFilterValues.push({value: "2", total_count});

		return {
			labels: groupedFilterValues?.map(item => MEMBERSHIP_TYPES_EXTENSIONS.find(el => el.value === Number(item.value))?.label),
			datasets: [
				{
					label: "# de visualizaciones",
					data: groupedFilterValues?.map(item => item.total_count),
					backgroundColor: groupedFilterValues?.map(item => COLORS.get(Number(item.value))),
					borderColor: "white",
					borderWidth: 1,
				},
			],
		};
	}, [values]);

	useEffect(() => {
		async function getAsyncMembershipUserViews() {
			try {
				setInnerLoading(true);
				setError("");
				const token = await auth.currentUser?.getIdToken();

				if (!token) {
					return setError("Invalid token");
				}

				let filters: string[] = [];

				if (serieidList) {
					serieidList.forEach(element => {
						filters.push(`video_series:${element}`);
					});
				} else {
					filters.push(`video_series:${serieid}`);
				}

				if (contentid) filters.push(`video_id:${contentid}`);
				if (codeReferral) filters.push(`custom_3:${codeReferral}`);

				const {
					data: {data},
				} = await getDimensions(token, "custom_1", {dimension_id: "custom_1", timeframe, filters});
				const filteredData = data.filter(record => {
					const value = record.value;
					return typeof value === "number" || (typeof value === "string" && !isNaN(parseFloat(value)));
				});
				setValues(filteredData);
				setInnerLoading(false);
			} catch (err) {
				setError("Error cargando las estadísticas " + err);
				setInnerLoading(false);
			}
		}

		const debounceAsyncMembershipUserViewsRequest = setTimeout(getAsyncMembershipUserViews, 500);
		return () => clearTimeout(debounceAsyncMembershipUserViewsRequest);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeReferral, contentid, serieid, timeframe]);

	const handleDateRangeChange = useCallback((start: number, end?: number, selectedDate?: DateRange) => {
		const _timeframe = [`${start}`, `${end}`];
		setTimeframe(_timeframe);
		setDate(selectedDate);
	}, []);

	return (
		<div className="flex flex-col items-center space-y-2 rounded-lg border border-border bg-card p-6 shadow-sm">
			{!!error && <div className="rounded-sm bg-red-500 p-2 text-red-700">{error}</div>}
			<h3 className="mb-4 text-lg font-semibold leading-none tracking-tight">{title}</h3>
			{loading || innerLoading ? (
				<div className="flex h-[350px] w-[300px] items-center justify-center">
					<Spinner />
				</div>
			) : (
				<>
					<DatePickerWithRange
						onDateRangeChange={handleDateRangeChange}
						disabled={{before: addDays(new Date(), -31), after: new Date()}}
						date={date}
					/>
					<div>
						<Pie data={data} options={options} />
					</div>
				</>
			)}
		</div>
	);
}

export default MembershipUserViews;
