import React, {FC, useCallback, useEffect} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {captchaVisibility} from "../../../../utils/captchaVisibility";

export interface ReCaptchaComponentInterface {
	setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
	action: string;
}

export const ReCaptchaComponent: FC<ReCaptchaComponentInterface> = props => {
	const {executeRecaptcha} = useGoogleReCaptcha();
	const {setToken, action} = props;
	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log("Execute recaptcha not yet available");
			return;
		}
		try {
			captchaVisibility(true);
			const token = await executeRecaptcha(action);
			setToken(token);
			// console.log(token);
		} catch (err) {
			console.log(err);
		}
	}, [action, executeRecaptcha, setToken]);
	useEffect(() => {
		handleReCaptchaVerify().catch(error => console.log(error));
	}, [handleReCaptchaVerify]);
	return null;
};
