import {ReactNode} from "react";
import {NavLink as RouterNavLink} from "react-router-dom";
import {cn} from "../../../../../../utils/classNames";

interface NavLinkProps {
	to: string;
	label: string;
	icon?: ReactNode;
	className?: string;
}
function NavLink({to, label, icon, className}: NavLinkProps) {
	return (
		<RouterNavLink to={to}>
			{({isActive}) => (
				<div
					className={cn(
						"mx-4 flex items-center gap-4 rounded-md px-2 py-3 pl-5 hover:bg-navigation-foreground/20",
						{
							"bg-navigation-foreground text-navigation hover:bg-navigation-foreground/90": isActive,
						},
						className,
					)}
				>
					{icon}
					<span>{label}</span>
				</div>
			)}
		</RouterNavLink>
	);
}

export default NavLink;
