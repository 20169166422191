import {Image, Refresh, Spinner, Upload} from "../../../../../../components/primitives/icons";
import {AspectRatio} from "@radix-ui/react-aspect-ratio";
import {Button} from "../../../../../../components/primitives/Button";
import {InsertContentType} from "../../../../../../data-access/series/content";

interface PostImageProps {
	src: string;
	loading: boolean;
}

type ImageFieldsTypes = keyof Pick<InsertContentType, "lowres_image" | "square_image" | "rectangular_image" | "preview_image">;

interface ImageSelectorProps extends PostImageProps {
	onOpenGallery: () => void;
	onUploadImage: (file: File, field: ImageFieldsTypes) => void;
}

const PostImage = ({src, loading}: PostImageProps) => {
	return !src ? (
		<div className="flex h-full w-full items-center justify-center bg-neutral-300">{loading && <Spinner />}</div>
	) : (
		<img src={src} className="h-full w-full bg-neutral-300 object-contain" alt="Thumbnail" />
	);
};

const ImageSelector = ({src, loading, onUploadImage, onOpenGallery}: ImageSelectorProps) => {
	const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const [file] = e.target.files as any;
		onUploadImage(file, "lowres_image");
	};

	return (
		<>
			<AspectRatio ratio={1} className="p-1">
				<div className="relative h-full w-full">
					<PostImage src={src} loading={loading} />

					{!loading &&
						(!src ? (
							<div className="absolute inset-0 flex flex-col items-center justify-center space-y-2 bg-black bg-opacity-30">
								<Button variant="ghost" type="button" onClick={onOpenGallery} className="min-w-[200px]">
									<span className="mr-2 w-full text-center">Seleccionar en la biblioteca</span>
									<Image className="h-6 w-6" />
								</Button>
								<label
									htmlFor="file-upload"
									className="inline-flex h-10 min-w-[200px] cursor-pointer items-center justify-center rounded-md px-4 py-2 text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground"
								>
									<span className="mr-2 w-full text-center">Subir desde tu computadora</span>
									<Upload className="h-6 w-6" />
								</label>
								<input id="file-upload" type="file" className="hidden" hidden onChange={handleUploadFile} />
							</div>
						) : (
							<div className="absolute right-2 top-2 flex items-center justify-center bg-black bg-opacity-30">
								<Button variant="link" type="button" onClick={onOpenGallery}>
									<Image className="h-6 w-6" stroke="white" />
								</Button>
								<label
									htmlFor="file-upload"
									className="inline-flex h-10 cursor-pointer items-center justify-center rounded-md px-4 py-2 text-sm font-medium text-primary underline-offset-4 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:underline"
								>
									<Refresh className="h-6 w-6" stroke="white" />
								</label>
								<input id="file-upload" type="file" className="hidden" hidden onChange={handleUploadFile} />
							</div>
						))}
				</div>
			</AspectRatio>
		</>
	);
};

export default ImageSelector;
