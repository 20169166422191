import {dashboardActions} from "../../../_store/features/dashboard/dashboard-slice";
import {useDashboardByNameQuery} from "../../../_store/features/dashboard/hooks";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../../../components/primitives/Select";

export function PartnerSerieContentSelect() {
	const selectedSerieContent = useAppSelector(state => state.dashboard.selectedContentId);
	const {data: partnerSerieContent} = useDashboardByNameQuery("topSeriesContents");
	const dispatch = useAppDispatch();

	return (
		<Select
			value={selectedSerieContent}
			onValueChange={val => {
				dispatch(dashboardActions.setSelectedContentId(val));
			}}
		>
			<SelectTrigger className="w-[300px]">
				<span className="overflow-hidden text-ellipsis whitespace-nowrap">
					<SelectValue placeholder="Selecciona un contenido..." />
				</span>
			</SelectTrigger>
			<SelectContent className="w-[300px]">
				<SelectGroup>
					<SelectLabel>Contenidos</SelectLabel>
					{partnerSerieContent?.map(el => {
						if (!el.contentid) return null;

						return (
							<SelectItem key={el.contentid + el.title} value={el.contentid}>
								{el.title}
							</SelectItem>
						);
					})}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}
