import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

interface GetUserRentalListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Rental[];
}

export interface GetUserRentalListParams {
	page_size?: number;
	page?: number;
	userid: string;
}

export interface Rental {
	series_rentalid: string;
	active: boolean;
	created_at: string;
	first_use_date: string | null;
	termination_date: string;
	seriesid: string;
	rental_type: number;
	rental_name: string;
	rental_description: string;
	series_title: string;
	series_description: string;
	img_banner_web_mobile: string;
	img_banner_web_desktop: string;
}

export async function getUserRentals(token: string, params: GetUserRentalListParams): Promise<AxiosResponse<GetUserRentalListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_series_rentals", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const RentalBodySchema = z.object({
	active: z.boolean(),
	series_rentalid: z.string(),
	first_use_date: z.string().min(1).nullable(),
	termination_date: z.string().min(1).nullable(),
	userid: z.string(),
});
export type RentalBody = z.infer<typeof RentalBodySchema>;

export async function updateUserRental(token: string, body: RentalBody): Promise<AxiosResponse<Rental>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/admin_update_user_rentals", body, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface ProductRental {
	sr_id: string;
	sr_products_rentalid: string;
	sr_seriesid: string;
	pr_id: string;
	pr_name: string;
	pr_description: string;
	pr_stripe_price: number;
	pr_stripe_productid: string;
	pr_stripe_priceid: string;
	pr_google_price: number;
	pr_google_id: string;
	pr_apple_price: number;
	pr_apple_id: any;
	pr_active: boolean;
	pr_rental_type: number;
	pr_created_at: string;
	pr_updated_at: string;
	pr_stripe_price_initial: number;
	pr_google_price_initial: number;
	pr_apple_price_initial: number;
	pr_stripe_fee_pct: number;
	pr_google_fee_pct: number;
	pr_apple_fee_pct: number;
	pr_stripe_fee_cnt: number;
	pr_google_fee_cnt: number;
	pr_apple_fee_cnt: number;
	pr_author: string;
	s_id: string;
	s_title: string;
	s_slug: string;
	s_description: string;
	s_ranking: number;
	s_published: boolean;
	s_total_likes: number;
	s_total_favorite: number;
	s_video_mux_id: string;
	s_img_banner_web_desktop: string;
	s_img_banner_web_mobile: string;
	s_img_logo: string;
	s_img_thumbnail: string;
	s_audit_created: string;
	s_audit_updated: any;
	s_position_order: number;
	s_img_square_thumbnail: string;
	s_tags: string;
	s_membership_title: string;
	s_link: string;
	s_production_cost: number;
	s_total_dislikes: number;
	s_release_date?: string;
	s_whitelist_release_date?: string;
	s_display_release_date: boolean;
	s_pronyr_original: boolean;
	s_horizontal_mobile_banner: string;
	s_account?: string;
	s_horizontal_tv_banner: string;
}

interface GetProductRentalListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: ProductRental[];
}

export interface GetProductRentalListParams {
	page_size?: number;
	page?: number;
}

export async function getListProductsRental(token: string, params: GetProductRentalListParams): Promise<AxiosResponse<GetProductRentalListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_products_rentals", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function createUserRental(token: string, body: RentalBody): Promise<AxiosResponse<Rental>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/admin_create_user_rentals", body, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
