import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {es} from "date-fns/locale";
import {format} from "date-fns";
import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {Refresh} from "../../../../../components/primitives/icons/Refresh";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {DataTable} from "../../../../../components/blocks/DataTable";
import {Button} from "../../../../../components/primitives/Button";
import {Input} from "../../../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../../../components/primitives/Select";
import {Comment as CommentIcon} from "../../../../../components/primitives/icons";
import {Subjects} from "../../../../../data-access/fetch-form-data";
import {formDbActions} from "../../../../../_store/features/forms/customer-service-slice";
import MessageReplySheet from "./subcomponents/MessageReplySheet";

const searchOptions = [
	{display: "ID", searchby: "id"},
	{display: "Por Contenido", searchby: "content"},
];

type SearchByType = "id" | "content" | "subject_id";

interface ISearchProps {
	type?: "reset" | "fetch";
}

const columnHelper = createColumnHelper<Subjects>();

export default function UserChatTab() {
	const {id} = useParams<{id: string}>();
	const dispatch = useAppDispatch();
	const dbSubjectChats = useAppSelector(({formData}) => formData);
	const currentUser = useAppSelector(({dbuser: {results}}) => results.find(user => user.id === id));
	const [chatsLoading, setChatsLoading] = useState(false);

	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState<string | undefined>();
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	/*MODAL DETAILS*/
	const [isReplySheetOpen, setIsReplySheetOpen] = useState(false);
	const [selectedCommentData, setSelectedCommentData] = useState<Subjects>();

	const handleSearch = ({type}: ISearchProps) => {
		if (!id) return;
		setChatsLoading(true);
		const params = {
			author_id: id,
			searchby: searchValue ? (selectedSearchBy.searchby as SearchByType) : undefined,
			searchvalue: type === "fetch" && searchValue !== "" ? searchValue : undefined,
			page: type === "fetch" ? page.pageIndex : 0,
			page_size: page.pageSize,
		};
		dispatch(formDbActions.getUserSubjectChatsAction({...params})).finally(() => setChatsLoading(false));
	};

	useEffect(() => {
		if (!page.pageSize) return;
		handleSearch({type: "fetch"});
		return () => {
			cleanState();
			dispatch(formDbActions.resetState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, dispatch, id]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const AVATAR_COL_WIDTH = 60;

		if (tableWidth > 0) {
			tableWidth = tableWidth - AVATAR_COL_WIDTH;
		}
		return [
			columnHelper.accessor("updated_at", {
				id: "Fecha de modificación",
				header: "Fecha de modificación",
				cell: ({row: {original}}) => {
					if (!original.created_at)
						return (
							<div className="flex w-full flex-col justify-start">
								<span className="ml-2 overflow-hidden text-ellipsis text-left">-</span>
							</div>
						);
					const date = new Date(original.updated_at || original.created_at);
					const formattedDate = format(date, "dd/MMM/yyyy - h:mm:ss aaa", {locale: es});
					return (
						<div className="flex w-full flex-col justify-start">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedDate}</span>
							<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{original.id}</span>
						</div>
					);
				},
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("content", {
				id: "Último comentario",
				header: "Último comentario",
				cell: ({row: {original}}) => (
					<div className="flex w-full justify-start">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{original.content}</span>
					</div>
				),

				size: Math.floor(tableWidth * 0.18),
			}),
			columnHelper.accessor("admin_response", {
				id: "Última respuesta",
				header: "Última respuesta",
				cell: ({row: {original}}) => (
					<div className="flex w-full justify-start">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{original?.admin_response ?? "Sin respuesta"}</span>
					</div>
				),

				size: Math.floor(tableWidth * 0.18),
			}),
			columnHelper.accessor("review", {
				id: "Calificación",
				header: "Calificación",
				cell: ({row: {original}}) => (
					<div className={`ml-2 w-full text-left ${original.review && "text-2xl"} `}>
						{original.review ? (original.review.trim() !== "positive" ? "👎" : "👍") : "Sin calificar"}
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
			}),
			columnHelper.accessor("review_message", {
				id: "Mensaje de calificación",
				header: "Mensaje de calificación",
				cell: ({row: {original}}) => (
					<div className="ml-2 overflow-hidden text-ellipsis text-left">{original?.review_message || "Sin mensaje"}</div>
				),
				size: Math.floor(tableWidth * 0.19),
			}),
			columnHelper.accessor("status", {
				id: "Estado",
				header: "Estado",
				cell: ({row: {original}}) => {
					const status: () => string = () => {
						switch (original.status) {
							case "closed":
								return "Cerrado";
							case "customer_reply":
								return "Mensaje de usuario";
							case "open":
								return "Iniciado";
							case "pending":
								return "Mensaje de soporte";
							default:
								return "Sin definir";
						}
					};
					return <span className="inline-block w-full px-3 py-1  text-left font-semibold leading-tight">{status()}</span>;
				},
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-2">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setSelectedCommentData(info.row.original);
								setIsReplySheetOpen(true);
							}}
						>
							<CommentIcon className="h-4 w-4" />
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.07),
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableRef]);

	const cleanState = () => {
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
	};

	const refresh = () => {
		cleanState();
		if (!id || !page.pageSize) return;
		handleSearch({type: "reset"});
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.display === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-[94vh] flex-col">
			<div className="mx-6 flex items-center border-b border-border pb-2 pt-2">
				<h2 className="text-2xl font-bold tracking-tight">Conversaciones con el usuario:</h2>
				<h2 className="ml-3 text-2xl font-extrabold tracking-tight">{currentUser?.name ?? "Anónimo"}</h2>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.display}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.display}`}>
											{sOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={() => handleSearch({type: "fetch"})}>
							Buscar
						</Button>
					</div>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={dbSubjectChats.results as Subjects[]}
					pageCount={Math.ceil(dbSubjectChats.totalResults / dbSubjectChats.pageSize)}
					pagination={page}
					loading={chatsLoading}
					onPaginationChange={setPage}
					rowHeight={55}
					withDynamicPageSize
					showPagination={false}
				/>
			</div>
			<MessageReplySheet
				contactData={selectedCommentData as Subjects}
				open={isReplySheetOpen}
				onOpenChange={o => {
					setSelectedCommentData(undefined);
					setIsReplySheetOpen(o);
				}}
				onSubmit={() => handleSearch({type: "fetch"})}
			/>
		</div>
	);
}
