import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: process.env.REACT_APP_APP_DOMAIN});

export interface CreateUserSubscription {
	active: boolean;
	termination_date: string;
	subscription_renewal: boolean;
	stripe_subscriptionid?: string;
	productid: string;
	userid?: string;
	subscription_type?: number;
}

export async function createSubscription(token: string, data: CreateUserSubscription): Promise<AxiosResponse<CreateUserSubscription>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/admin_create_user_subscription", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function updateSubscription(token: string, data: CreateUserSubscription): Promise<AxiosResponse<CreateUserSubscription>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.patch("/admin_update_user_subscription", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface Subscription {
	id: string;
	name: string;
	active: boolean;
	author: string;
	apple_id: string;
	google_id: string;
	created_at: string;
	updated_at: string;
	apple_price: number;
	apple_price_initial: number;
	description: string;
	google_price: number;
	google_price_initial: number;
	stripe_price: number;
	stripe_price_initial: number;
	stripe_priceid: string;
	subscription_type: number;
	stripe_productid: string;
	vendor_cnt: number;
	vendor_fee_pct: number;
	vendor_price: number;
}

export interface ListProductSubscriptionResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Subscription[];
}

export interface ListProductSubscriptionsParams {
	page_size?: number;
	page?: number;
	searchby?: "stripe_productid" | "stripe_priceid" | "description";
	searchvalue?: string;
}

export async function getProductSubscriptions(
	token: string,
	params: ListProductSubscriptionsParams,
): Promise<AxiosResponse<ListProductSubscriptionResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/admin_list_products_subscription", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetUserSubscriptionsParams {
	userid?: string;
	totalResults?: number;
	page_size?: number;
	page?: number;
	get_monthly_subscriptions?: boolean;
}

export interface Slots {
	audit_accepted: string | null;
	audit_created: string;
	audit_updated: string;
	buddy_id: string | null;
	id: string;
	invitation_hash: string | null;
	limited_time: boolean;
	slot: string | null;
	state: string;
	subscriber_id: string;
	time_lock: boolean;
}

export interface UserSubscription {
	active: boolean;
	updated_at?: string;
	termination_date: string;
	subscription_renewal: boolean;
	stripe_subscriptionid?: string;
	prospay_subscriptionid?: string;
	name: string;
	subscription_type: number;
	last_payment_platform: string;
	id: string;
	slots?: Slots[] | [null];
}

export interface GetUserSubscriptionsResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: UserSubscription[];
}

export async function getUserSubscription(token: string, params: GetUserSubscriptionsParams): Promise<AxiosResponse<GetUserSubscriptionsResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/admin_get_user_subscription", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const BuddyPlanBodySchema = z.object(
	{
		id: z.string().nonempty("El campo ID es requerido."),
		subscriber_id: z.string().nonempty("El campo ID de suscriptor es requerido."),
		buddy_id: z.string().nullable(),
		limited_time: z.boolean({message: "El campo Límite de tiempo es requerido."}),
		state: z.string().nonempty("El campo Estado es requerido."),
		audit_accepted: z.string().nullable(),
	},
	{required_error: "Este campo es requerido."},
);
export type BuddyPlanBodyType = z.infer<typeof BuddyPlanBodySchema>;

export async function manageAdminBuddyPlanData(token: string, data: BuddyPlanBodyType): Promise<AxiosResponse<Slots>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.patch("/admin_set_buddy_plan", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface VendorSubscription {
	customerid: string;
	subscriptionproductid?: string;
	coinproductsid?: string;
}

export async function postVendorSubscription(token: string, data: VendorSubscription): Promise<AxiosResponse<CreateUserSubscription>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/vendor_payment_smart", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
