import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	EditStickerBodyType,
	editStickerData,
	getObjectSticker,
	GetStickerParams,
	getStickersList,
	GetStickersListParams,
	InsertStickerBodyType,
	insertStickerData,
	Sticker,
} from "../../../data-access/stickers";

export interface stickersInterface {
	loading: boolean;
	results: Sticker[];
	totalResults: number;
	current: number;
	pageSize: number;
	success: boolean;
	error: string;
}

const initialState: stickersInterface = {
	loading: false,
	results: [],
	totalResults: 0,
	current: 0,
	pageSize: 0,
	success: false,
	error: "",
};

/*GENERAL*/
const getStickers = createAsyncThunk("stickers/list", async (params: GetStickersListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getStickersList(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const insertStickers = createAsyncThunk("stickers/insert", async (data: InsertStickerBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await insertStickerData(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem inserting sticker, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editStickers = createAsyncThunk("stickers/edit", async (data: EditStickerBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editStickerData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getSticker = createAsyncThunk("stickers/get", async (params: GetStickerParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getObjectSticker(token, params);
		return {
			item: response.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const stickersSlice = createSlice({
	name: "stickers",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getStickers.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(getStickers.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getStickers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(getSticker.pending, state => {
				state.loading = true;
				state.error = "";
				//state.results = [];
			})
			.addCase(getSticker.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [action.payload?.item!];
				state.success = true;
			})
			.addCase(getSticker.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(insertStickers.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(insertStickers.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertStickers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(editStickers.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(editStickers.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editStickers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});
export const stickersActions = {
	...stickersSlice.actions,
	getStickers,
	getSticker,
	insertStickers,
	editStickers,
};

export default stickersSlice;
