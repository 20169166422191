import {PropsWithChildren, ReactNode, useRef, useEffect} from "react";
import {Disclosure, Transition} from "@headlessui/react";
import {useLocation} from "react-router-dom";
import {Chevron} from "../../../../primitives/icons";
import {cn} from "../../../../../../utils/classNames";

interface NavGroupProps extends PropsWithChildren {
	title: string;
	route: string;
	icon?: ReactNode;
}

function NavGroup({title, children, icon, route}: NavGroupProps) {
	const {pathname} = useLocation();
	const isChildRouteActive = pathname === route || pathname.startsWith(route);

	return (
		<Disclosure
			defaultOpen={isChildRouteActive}
			as={"div"}
			className={cn("mx-2 my-2 overflow-hidden", {"rounded-md bg-navigation-foreground/5": isChildRouteActive})}
		>
			<Disclosure.Button className={cn("flex w-full items-center gap-4 py-3 pl-5 pr-4", {"bg-navigation-foreground/10": isChildRouteActive})}>
				{icon}
				<span>{title}</span>
				<span className="ml-auto h-3  w-3">
					<Chevron className="ui-open:rotate-180" />
				</span>
			</Disclosure.Button>
			<Transition
				enter="transition duration-200 ease-out"
				enterFrom="transform scale-95 opacity-0"
				enterTo="transform scale-100 opacity-100"
				leave="transition duration-300 ease-out"
				leaveFrom="transform scale-100 opacity-100"
				leaveTo="transform scale-95 opacity-0"
			>
				<Disclosure.Panel className={"py-4"}>{panelProps => DisclosureContent({...panelProps, children, route})}</Disclosure.Panel>
			</Transition>
		</Disclosure>
	);
}

interface DisclosureContentProps extends PropsWithChildren {
	route: string;
	close: () => void;
}
const DisclosureContent = (props: DisclosureContentProps) => {
	const {children, route, ...rest} = props;
	const {pathname} = useLocation();
	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handler = (e: MouseEvent) => {
			if (contentRef.current && !contentRef.current.contains(e.target as Node) && !pathname.startsWith(route)) {
				rest.close();
			}
		};
		window.addEventListener("click", handler);

		return () => {
			window.removeEventListener("click", handler);
		};
	}, [pathname, rest, route]);

	return <div ref={contentRef}>{children}</div>;
};

export default NavGroup;
