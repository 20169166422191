import {useEffect, useState} from "react";
import {useAppDispatch} from "../../../_store/hooks";
import {commentsActions} from "../../../_store/features/comments/comments-slice";
import {Spinner} from "../../primitives/icons";
import {COMMENTS_TYPES} from "../../../constants/comments";
import TextComment from "./subcomponents/TextComment";
import LimitedComment from "./subcomponents/LimitedComment";
import StickerComment from "./subcomponents/StickerComment";

interface CommentThreadProps {
	commentData: {id: string; path: string};
}

export default function CommentThread({commentData}: CommentThreadProps) {
	const [commentBranchData, setCommentBranchData] = useState<any[]>();
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!commentData) {
			return;
		}
		setLoading(true);
		dispatch(commentsActions.getCommentBranch(commentData))
			.unwrap()
			.then(res => {
				setLoading(false);

				if (!res) return setCommentBranchData([]);

				setCommentBranchData(res.sort((a, b) => (a.path?.length ?? 0) - (b.path?.length ?? 0)));
			})
			.catch(() => {});
	}, [commentData, dispatch]);

	if (loading)
		return (
			<div className="flex items-center justify-center gap-2">
				<Spinner className="h-4 w-4 animate-spin" />
				<span className="text-sm text-muted-foreground">Cargando...</span>
			</div>
		);

	return (
		<div>
			{commentBranchData?.map((comment, index) => {
				if (comment.content.type === COMMENTS_TYPES.TEXT) {
					return <TextComment comment={comment} isSelected={comment.id === commentData.id} key={index} />;
				}

				if (comment.content.type === COMMENTS_TYPES.STICKER) {
					return <StickerComment comment={comment} isSelected={comment.id === commentData.id} key={index} />;
				}

				return <LimitedComment comment={comment} isSelected={comment.id === commentData.id} key={index} />;
			})}
		</div>
	);
}
