import axios from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface GetVideosParamsList {
	page?: number;
	page_size: number;
	searchby?: undefined | "title" | "uploadid" | "playbackid" | "signed" | "sponsor_id";
	searchvalue?: string;
	signed?: boolean;
	duration?: number;
	video_type?: number;
}

export async function getVideos(token: string, params: GetVideosParamsList) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_video_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function deleteDBVideo(token: string, data: {uploadid: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_video_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export interface InsertVideoDataBody {
	title: string;
	uploadid: string;
	video_type?: number;
	sponsor_id?: string;
}

export interface EditVideoPrintDataBody {
	prints: number;
	uploadid: string;
}

export async function insertVideo(token: string, data: InsertVideoDataBody) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_video_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function editVideo(token: string, data: InsertVideoDataBody) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_video_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function editVideoPrint(token: string, data: EditVideoPrintDataBody) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_video_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
