import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../../_store/hooks";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {crewActions} from "../../../../_store/features/crew/crew-slice";
import {toast} from "react-hot-toast";
import {Input} from "../../../../components/primitives/Input";
import {Button} from "../../../../components/primitives/Button";
import {Spinner} from "../../../../components/primitives/icons";
import {InsertStaffBodySchema, InsertStaffBodyType} from "../../../../data-access/series/staff";
import {useEffect, useState} from "react";

const initialState: InsertStaffBodyType = {
	fullname: "",
};

function StaffNew() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [serieId, setSerieId] = useState();
	const {
		register,
		handleSubmit,
		reset,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<InsertStaffBodyType>({
		resolver: zodResolver(InsertStaffBodySchema),
		defaultValues: initialState,
	});

	const onSubmit: SubmitHandler<InsertStaffBodyType> = data => {
		return dispatch(crewActions.insertStaff(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Staff Creado Correctamente");
				reset();
				if (serieId) {
					setTimeout(() => {
						navigate("/series/edit/" + serieId, {state: {param: "credits"}});
					}, 3000);
				} else {
					setTimeout(() => {
						navigate(-1);
					}, 3000);
				}
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	useEffect(() => {
		if (location.state !== null) {
			setSerieId(location.state.param);
		}
	}, [location.state]);

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Nuevo Staff</h2>
				<span className="text-base text-muted-foreground lg:text-lg">
					Permite crear staff que se mostraran en los créditos de las series en la plataforma.
				</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-border">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Nombre Completo<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar nombre del staff..." {...register("fullname")} />
									{errors?.fullname?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.fullname?.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="outline"
							onClick={() => {
								reset();
								if (serieId) {
									navigate("/series/edit/" + serieId, {state: {param: "credits"}});
									setSerieId(undefined);
								} else {
									navigate(-1);
									setSerieId(undefined);
								}
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Crear Staff
						</Button>
					</div>
				</form>
			</div>
		</section>
	);
}

export default StaffNew;
