import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface Cast {
	id: string;
	name: string;
	images: string[];
	descript: string;
	series_involved?: SeriesInvolved[];
}

export interface dbCast {
	id: string;
	name: string;
	images: string;
	descript: string;
	series_involved?: SeriesInvolved[];
}

export interface SeriesInvolved {
	series_id: string;
	series_name: string;
}

export interface GetCastParams {
	page?: number;
	page_size?: number;
	searchby?: undefined | "name" | "description";
	searchvalue?: string;
}

interface GetCastResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: dbCast[];
}

export async function listCastMembers(token: string, params?: GetCastParams): Promise<AxiosResponse<GetCastResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_castmember_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const InsertCastMemberBodySchema = z.object({
	name: z.string(),
	description: z.string(),
	images: z.string().array(),
});

export type InsertCastMemberBodyType = z.infer<typeof InsertCastMemberBodySchema>;

export async function insertCastMember(token: string, data: InsertCastMemberBodyType): Promise<AxiosResponse<dbCast>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post(
		"/insert_castmember_data",
		{...data, images: data.images.join(",")},
		{
			headers: {
				Accept: "*/*",
				Authorization: idToken,
			},
		},
	);
}

export const UpdateCastMemberBodySchema = z.object({
	id: z.string().nonempty(),
	name: z.string(),
	descript: z.string(),
	images: z.string().array(),
});

export type UpdateCastMemberBodyType = z.infer<typeof UpdateCastMemberBodySchema>;

export async function updateCastMember(token: string, data: UpdateCastMemberBodyType): Promise<AxiosResponse<dbCast>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch(
		"/edit_castmember_data",
		{...data, images: data.images.join(",")},
		{
			headers: {
				Accept: "*/*",
				Authorization: idToken,
			},
		},
	);
}

interface DeleteCastMemberData {
	id: string;
}
export async function deleteCastMember(token: string, data: DeleteCastMemberData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_castmember_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

interface InsertCastData {
	seriesid: string;
	castmemberid: string;
	ranking: number;
	image: string;
}

export async function insertCast(token: string, data: InsertCastData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_cast_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

interface DeleteCastData {
	castmemberid: string;
}
export async function deleteCast(token: string, data: DeleteCastData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_cast_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export async function uploadCastImage(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_cast_image", data, {
		headers: {
			Authorization: idToken,
		},
	});
}

interface OrderCasts {
	ordered_data: {
		id: string;
		order: number;
	}[];
}
export async function orderCasts(token: string, data: OrderCasts) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.put("/order_cast_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
