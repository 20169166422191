import {useState} from "react";
import {Skeleton} from "../../../primitives/Skeleton";
import {TooltipProvider, Tooltip, TooltipContent, TooltipTrigger} from "../../../primitives/ToolTip";
import {ArrowDown, DollarSign, QuestionMarkCircle} from "../../../primitives/icons";

interface CardProps {
	title: string;
	titleSeparator?: boolean;
	amount?: number;
	tooltip?: string;
	loading?: boolean;
	isMoney?: boolean;
	beforeIcon?: JSX.Element;
	customValues?: {
		amount?: number;
		text?: string;
		icon?: JSX.Element;
	}[];
	className?: string;
	warningChildren?: JSX.Element;
	expanse?: boolean;
	onExpanse?: (expanded: boolean) => void;
}
export const Card = ({
	title,
	beforeIcon,
	amount,
	tooltip,
	loading,
	className,
	isMoney = true,
	customValues,
	titleSeparator,
	warningChildren,
	expanse,
	onExpanse,
}: CardProps) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<div
			className={`${className} relative flex flex-col justify-between overflow-hidden rounded-lg border bg-card text-card-foreground shadow-sm`}
		>
			<div className={`flex ${titleSeparator && "border-b"} flex-row items-center justify-between space-y-0 p-6 pb-2`}>
				<div className="flex items-center gap-2">
					<h3 className="truncate text-base font-medium tracking-tight">{title}</h3>
					{tooltip && (
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger asChild>
									<div>
										<QuestionMarkCircle className="h-4 w-4" />
									</div>
								</TooltipTrigger>
								<TooltipContent>
									<p className="w-96">{tooltip}</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}
				</div>
				{warningChildren ? warningChildren : isMoney && <DollarSign />}
				{expanse && !loading && (
					<div className={`absolute bottom-4 right-4 ${expanded ? "rotate-180" : "rotate-0"} transition`}>
						<button
							className="flex w-full items-center justify-center py-2 text-sm font-medium text-primary"
							onClick={() => {
								setExpanded(expanded => !expanded);
								onExpanse?.(!expanded);
							}}
						>
							<ArrowDown className="h-4 w-4" />
						</button>
					</div>
				)}
			</div>
			<div className={`${customValues ? "p-3" : "p-6"} pt-0`}>
				{loading ? (
					<Skeleton className="mt-2 h-8 w-full bg-slate-200" />
				) : !customValues ? (
					<div className="mt-2 flex items-center text-2xl font-bold">
						{beforeIcon}
						{isMoney ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(amount ?? 0) : amount}
					</div>
				) : (
					customValues.map(val => (
						<div className="mt-2 flex flex-wrap items-center justify-between">
							<div className="flex items-center gap-2">
								{val.icon}
								<p className="text-md truncate">{val.text}</p>
							</div>
							<p className="text-md font-bold">{val.amount}</p>
						</div>
					))
				)}
			</div>
		</div>
	);
};
