import {useState} from "react";
import {ChevronLeft, ChevronRight} from "../../primitives/icons";
import {Cast} from "../../../data-access/series/cast";
import {Separator} from "../../primitives/Separator";
import {ScrollArea} from "../../primitives/ScrollArea";
import {Button} from "../../primitives/Button";

interface CastCardProps {
	selectedCastData: Cast;
	onAddCast: (cast: Cast) => void;
}

export default function CastCard(props: CastCardProps) {
	const {selectedCastData, onAddCast} = props;
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	return (
		<div className="flex max-h-[345px] max-w-sm flex-col border-l border-border bg-background p-4">
			<div className="flex flex-shrink-0">
				<div className="flex flex-shrink-0 overflow-hidden">
					<button
						className="flex items-center justify-center rounded rounded-r-none border border-border bg-accent px-0.5 text-muted-foreground disabled:cursor-not-allowed disabled:opacity-60 enabled:hover:bg-background"
						disabled={selectedCastData.images.length === 1 || selectedCastData.images.length === 0}
						onClick={() => {
							setSelectedImageIndex(prev => (prev > 0 ? prev - 1 : selectedCastData.images.length - 1));
						}}
					>
						<ChevronLeft className="h-4 w-4" />
					</button>
					<img
						src={selectedCastData.images[selectedImageIndex]}
						className="h-32 w-32 flex-shrink-0 bg-accent object-contain"
						alt="Actor Profile"
					/>
					<button
						className="flex items-center justify-center rounded rounded-l-none border border-border bg-accent px-0.5 text-muted-foreground disabled:cursor-not-allowed disabled:opacity-60 enabled:hover:bg-background"
						disabled={selectedCastData.images.length === 1 || selectedCastData.images.length === 0}
						onClick={() => {
							setSelectedImageIndex(prev => (prev < selectedCastData.images.length - 1 ? prev + 1 : 0));
						}}
					>
						<ChevronRight className="h-4 w-4" />
					</button>
				</div>
				<div className="flex items-center overflow-hidden px-4">
					<span className="text-xl font-bold">{selectedCastData.name}</span>
				</div>
			</div>
			<Separator className="mb-2 mt-4" />
			<span className="text-sm font-semibold">Descripción:</span>
			<ScrollArea className="mb-2 h-full rounded-md border p-2 text-sm">{selectedCastData.descript}</ScrollArea>
			<Button
				disabled={!selectedCastData}
				size="sm"
				className="py-1.5"
				onClick={() => onAddCast({...selectedCastData, images: [selectedCastData.images[selectedImageIndex]]})}
			>
				Añadir
			</Button>
		</div>
	);
}
