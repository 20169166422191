import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({
	baseURL: `${process.env.REACT_APP_EMAIL_API}`,
	headers: {
		Accept: "*/*",
		MAIL_SERVER_API_KEY: process.env.REACT_APP_EMAIL_API_KEY,
	},
});

export type EmailSendInformationType = {
	[key: string]: string[];
};

export async function getEmailSendInformation(searchvalue: Object): Promise<AxiosResponse<EmailSendInformationType>> {
	return await axiosHandler.post("/get_email_activity", searchvalue, {});
}
