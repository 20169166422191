import {signOut} from "firebase/auth";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {auth} from "../../../../../firebase";
import {Avatar, AvatarFallback, AvatarImage} from "../../../../primitives/Avatar";
import {SignOut} from "../../../../primitives/icons";

export default function UserProfileLink() {
	const userInfo = useAppSelector(state => state.user.userInfo);
	const dispatch = useAppDispatch();

	const handleSignOut = () => {
		signOut(auth);
		dispatch({type: "reset"});
	};

	return (
		<Link to="/profile" className="mt-auto">
			<div className="flex w-full items-center gap-4 border-t border-navigation-foreground/10 bg-navigation py-3 pl-5 pr-4 text-navigation-foreground">
				<Avatar className="h-8 w-8">
					<AvatarImage src={userInfo?.photo_url + "?" + Date.now()} alt="User Profile" />
					<AvatarFallback className="bg-navigation text-xs text-navigation-foreground">{userInfo?.email?.slice(0, 2)}</AvatarFallback>
				</Avatar>
				<span className="w-full overflow-hidden text-ellipsis whitespace-nowrap">{userInfo?.email}</span>
				<div onClick={handleSignOut} className="cursor-pointer hover:text-neutral-500">
					<SignOut />
				</div>
			</div>
		</Link>
	);
}
