import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	EditAssociatesParams,
	GetAssociatesListParams,
	InsertAssociatesParams,
	editAssociates,
	getAssociatesList,
	getVinculatedUsersList,
	insertAssociates,
} from "../../../data-access/associates/associates";

export interface associatesResponseInterface {
	loading: boolean;
	totalResults: number;
	pageSize: number;
	current: number;
	results: AssociatesResult[];
	vinculatedLoading: boolean;
	vinculatedTotalResults: number;
	vinculatedPageSize: number;
	vinculatedCurrent: number;
	vinculatedResults: AssociatesResult[];
}

export interface AssociatesResult {
	id: string;
	userid: string;
	seriesid: string;
	active: boolean;
	audit_createdtime: string;
	audit_update: string;
	title: string;
	email: string;
	name: string;
	phone: string;
	photo_url: string;
	serie_title: string;
	serie_id: string;
}

const initialState: associatesResponseInterface = {
	loading: false,
	results: [],
	totalResults: 0,
	pageSize: 0,
	current: 0,
	vinculatedLoading: false,
	vinculatedResults: [],
	vinculatedTotalResults: 0,
	vinculatedPageSize: 0,
	vinculatedCurrent: 0,
};

const getUserAssociatesSeries = createAsyncThunk("associates/list", async (params: GetAssociatesListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token) return;

		const response = await getAssociatesList(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching associates");
	}
});

const getUserVinculatedSeries = createAsyncThunk("vinculated/list", async (params: GetAssociatesListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token) return;

		const response = await getVinculatedUsersList(token, params);

		return {
			vinculatedTotalResults: response.data.totalResults,
			vinculatedPageSize: response.data.pageSize,
			vinculatedCurrent: response.data.current,
			vinculatedResults: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching associates");
	}
});

const insertUsersAssociatesSeries = createAsyncThunk("associates/insert", async (params: InsertAssociatesParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await insertAssociates(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching associates");
	}
});

const editUsersAssociatesSeries = createAsyncThunk("associates/insert", async (params: EditAssociatesParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await editAssociates(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching associates");
	}
});

const associatesSlice = createSlice({
	name: "associates",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getUserAssociatesSeries.pending, state => {
				state.loading = true;
			})
			.addCase(getUserAssociatesSeries.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...action.payload?.results!];
				state.current = action.payload?.current!;
				state.pageSize = action.payload?.pageSize!;
				state.totalResults = action.payload?.totalResults!;
			})
			.addCase(getUserAssociatesSeries.rejected, (state, action) => {});
		builder
			.addCase(getUserVinculatedSeries.pending, state => {
				state.vinculatedLoading = true;
			})
			.addCase(getUserVinculatedSeries.fulfilled, (state, action) => {
				state.vinculatedLoading = false;
				state.vinculatedResults = [...action.payload?.vinculatedResults!];
				state.vinculatedCurrent = action.payload?.vinculatedCurrent!;
				state.vinculatedPageSize = action.payload?.vinculatedPageSize!;
				state.vinculatedTotalResults = action.payload?.vinculatedTotalResults!;
			})
			.addCase(getUserVinculatedSeries.rejected, (state, action) => {});
	},
});
export const associatesActions = {
	...associatesSlice.actions,
	getUserAssociatesSeries,
	insertUsersAssociatesSeries,
	editUsersAssociatesSeries,
	getUserVinculatedSeries,
};

export default associatesSlice;
