import {useEffect, useState} from "react";
import {cn} from "../../../../utils/classNames";
import {Input} from "../Input";

interface HourPickerProps {
	className?: string | undefined;
	hourSelected: string;
	onTimeChange?: (time: string) => void;
}
const HourPicker = ({className, hourSelected, onTimeChange}: HourPickerProps) => {
	const [hour, setHour] = useState(hourSelected);

	useEffect(() => {
		onTimeChange && onTimeChange?.(hour);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hour]);

	return (
		<div className={cn("flex w-full flex-row", className)}>
			<div className="h-6 w-auto items-center justify-center rounded-sm ">
				<Input
					type="time"
					placeholder="hr"
					value={hour}
					onChange={e => setHour(e.target.value)}
					className={cn("flex h-10 w-full cursor-pointer items-center justify-center", className)}
					step={60}
					max="23:58"
				/>
			</div>
		</div>
	);
};

export default HourPicker;
