import {IconProps} from "./type";

export const Percentage = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="-3 0 19 19" {...props}>
			<path
				d="M2.865 8.14a2.553 2.553 0 1 1 2.552-2.552 2.555 2.555 0 0 1-2.552 2.553zm0-1.582a.97.97 0 1 0-.97-.97.97.97 0 0 0 .97.97zm7.942-1.991L3.914 14.886a1.03 1.03 0 0 1-1.712-1.143l6.893-10.32a1.03 1.03 0 0 1 1.712 1.144zm1.88 8.215a2.553 2.553 0 1 1-2.552-2.552 2.555 2.555 0 0 1 2.553 2.552zm-1.582 0a.97.97 0 1 0-.97.97.97.97 0 0 0 .97-.97z"
				fill="currentColor"
			/>
		</svg>
	);
};
