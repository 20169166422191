import {PropsWithChildren, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../../../components/primitives/Table";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {Skeleton} from "../../../../../components/primitives/Skeleton";
import {DateRange} from "react-day-picker";
import {SeriesRanking, seriesRankingActions} from "../../../../../_store/features/series/series-ranking-slice";
import {ArrowRightLong, Eye, HandThumbUpOutline, HeartOutline} from "../../../../../components/primitives/icons";
import {Link} from "react-router-dom";
import {Button} from "../../../../../components/primitives/Button";
import {formatISO9075} from "date-fns";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../../../components/primitives/ToolTip";

type SortBy = "views" | "likes" | "follows";

export interface SeriesContent {
	rectangular_image?: string;
	img_thumbnail?: string;
	id: string;
	title: string;
	stickers: number;
	donations: number;
	sticker_count: number;
	donation_count: number;
}

function sort(data: SeriesRanking[], sortBy: SortBy) {
	switch (sortBy) {
		case "views":
			return data.sort((a, b) => b.views - a.views);
		case "likes":
			return data.sort((a, b) => b.likes - a.likes);
		case "follows":
			return data.sort((a, b) => b.follows - a.follows);
		default:
			return data.sort((a, b) => b.likes + b.follows + (a.likes + a.follows));
	}
}

interface LinkComponentProps {
	contentId?: string;
	onClickContent?: (contentId: string) => void;
}

function LinkComponent({children, onClickContent, contentId}: PropsWithChildren<LinkComponentProps>) {
	if (typeof onClickContent === "function" && contentId) {
		return (
			<button onClick={() => onClickContent(contentId)} className="truncate whitespace-nowrap text-left md:w-auto">
				{children}
			</button>
		);
	}
	return <>{children}</>;
}

interface ContentsRowsProps {
	data: SeriesRanking[];
	sortBy: SortBy;
}
function ContentsRows({data, sortBy}: ContentsRowsProps) {
	return (
		<>
			{sort(data, sortBy).map(item => {
				const {likes, follows, name, views, photo_url} = item;
				return (
					<TableRow className="h-[97px] text-center odd:bg-accent/40" key={item.id}>
						<TableCell className="w-28 px-2">
							<LinkComponent contentId={item.id}>
								<img
									className="aspect-video max-h-16 w-28 rounded-sm object-contain
									before:absolute before:flex before:h-16 before:w-28 before:items-center before:justify-center before:border before:border-border before:bg-background before:text-xs before:font-medium before:content-['404']
								"
									src={photo_url || ""}
									alt=""
								/>
							</LinkComponent>
						</TableCell>
						<TableCell className="text-left">
							<LinkComponent>{name}</LinkComponent>
						</TableCell>
						<TableCell>{views || 0}</TableCell>
						<TableCell>{likes || 0}</TableCell>
						<TableCell>{follows || 0}</TableCell>
					</TableRow>
				);
			})}
		</>
	);
}

interface SeriesRankingProps {
	dates: DateRange | undefined;
}

const TooltipComponent = ({text, children}: {text: string; children: React.ReactNode}) => (
	<Tooltip>
		<TooltipTrigger asChild>
			<div>{children}</div>
		</TooltipTrigger>
		<TooltipContent side="top">
			<p>{text}</p>
		</TooltipContent>
	</Tooltip>
);

function SeriesRankingResume({dates}: SeriesRankingProps) {
	const {results: data, loading: isLoading} = useAppSelector(state => state.seriesRanking);
	const [sortBy, setSortBy] = useState<SortBy>("views");
	const dispatch = useAppDispatch();

	useEffect(() => {
		getRankinResume(dates);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates]);

	async function getRankinResume(dates: DateRange | undefined) {
		dispatch(
			seriesRankingActions.getSeriesListRanking({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				page_size: 5,
			}),
		);
	}

	return (
		<div className="mr-3 flex w-full flex-col rounded-lg border bg-card p-3">
			<div className="flex w-full items-center justify-center border-b border-border p-6">
				<h3 className="text-center text-lg font-semibold leading-none tracking-tight">Ranking de Series</h3>
			</div>
			<TooltipProvider>
				<Table className="border-b">
					<TableHeader>
						<TableRow>
							<TableHead className="w-36"></TableHead>
							<TableHead>Título</TableHead>
							<TableHead className={sortBy === "views" ? "text-accent-foreground" : "text-muted-foreground"}>
								<Button
									className="mx-auto flex items-center space-x-1 whitespace-nowrap"
									variant="ghost"
									onClick={() => setSortBy("views")}
								>
									<TooltipComponent text="Views">
										<Eye className="mx-auto" width={24} height={24} />
									</TooltipComponent>
								</Button>
							</TableHead>
							<TableHead className={sortBy === "likes" ? "text-accent-foreground" : "text-muted-foreground"}>
								<Button
									className="mx-auto flex items-center space-x-1 whitespace-nowrap"
									variant="ghost"
									onClick={() => setSortBy("likes")}
								>
									<TooltipComponent text="Likes">
										<HandThumbUpOutline className="mx-auto" width={24} height={24} />
									</TooltipComponent>
								</Button>
							</TableHead>
							<TableHead className={sortBy === "follows" ? "text-accent-foreground" : "text-muted-foreground"}>
								<Button
									className="mx-auto flex items-center space-x-1 whitespace-nowrap"
									variant="ghost"
									onClick={() => setSortBy("follows")}
								>
									<TooltipComponent text="Follows">
										<HeartOutline className="mx-auto" width={24} height={24} />
									</TooltipComponent>
								</Button>
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{isLoading ? (
							<TableRow className="text-center odd:bg-accent/40">
								<TableCell colSpan={7}>
									<Skeleton className="h-20 w-full bg-slate-300" />
								</TableCell>
							</TableRow>
						) : (
							<ContentsRows data={data.slice()} sortBy={sortBy} />
						)}
					</TableBody>
				</Table>
			</TooltipProvider>
			<Link to={"corporate/ranked-series"} className="flex items-center justify-end gap-2 p-5">
				<p className="text-md truncate text-blue-500 hover:underline">Ver todo el ranking</p>
				<ArrowRightLong className="fill-blue-500" width={26} height={26} />
			</Link>
		</div>
	);
}

export default SeriesRankingResume;
