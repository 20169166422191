import {User} from "@firebase/auth";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	getVideos,
	GetVideosParamsList,
	InsertVideoDataBody,
	editVideo,
	editVideoPrint,
	deleteDBVideo,
	EditVideoPrintDataBody,
} from "../../../data-access/videos/videos";
import {refreshVideo, refreshUpload, deleteVideo} from "../../../data-access/videos/mux-videos";

export type Video = {
	uploadid: string;
	assetid: string;
	playbackid: string;
	status: string;
	duration: number;
	signed: boolean;
	title: string;
	author: string;
	upload_url: string;
	video_type: number;
	sponsor_id?: string;
	prints?: number;
	name?: string;
};

export interface videosInterface {
	loading: boolean;
	loadingAction: boolean;
	actionLoading: boolean;
	totalResults: number;
	pageSize: number;
	current: number;
	results: Video[];
	newVideo: any;
}

const initialState: videosInterface = {
	loading: false,
	loadingAction: false,
	actionLoading: false,
	totalResults: 0,
	pageSize: 0,
	current: 0,
	results: [],
	newVideo: {},
};

const getVideosList = createAsyncThunk("videos/list", async ({GCPUser, params}: {GCPUser: User; params: GetVideosParamsList}, thunkAPI) => {
	try {
		const token = await GCPUser.getIdToken();

		const response = await getVideos(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.page_size,
			current: response.data.page,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching series");
	}
});

const refreshVideoData = createAsyncThunk("videos/refresh", async ({GCPUser, item}: {GCPUser: User; item: Video}, thunkAPI) => {
	try {
		const token = await GCPUser.getIdToken();

		if (item.assetid) {
			await refreshVideo(token, {asset_id: item.assetid});
		} else {
			const info = await refreshUpload(token, {upload_id: item.uploadid});
			if (info.data.result.status === "asset_created" && info.data.result.asset_id) {
				await refreshVideo(token, {asset_id: info.data.result.asset_id});
			}
		}
		return;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching videos");
	}
});

const editVideoData = createAsyncThunk("videos/edit", async ({GCPUser, params}: {GCPUser: User; params: InsertVideoDataBody}, thunkAPI) => {
	try {
		const token = await GCPUser.getIdToken();

		await editVideo(token, params);

		return;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem editing videos");
	}
});

const editVideoPrintData = createAsyncThunk(
	"videos/edit-print",
	async ({GCPUser, params}: {GCPUser: User; params: EditVideoPrintDataBody}, thunkAPI) => {
		try {
			const token = await GCPUser.getIdToken();

			const res = await editVideoPrint(token, params);

			if (!res.data) {
				return thunkAPI.rejectWithValue("Problem fetching contents");
			}

			return {
				item: res.data,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem editing videos");
		}
	},
);

const deleteVideoData = createAsyncThunk("videos/delete", async ({GCPUser, item}: {GCPUser: User; item: Video}, thunkAPI) => {
	try {
		const token = await GCPUser.getIdToken();

		if (item.assetid) {
			await deleteVideo(token, {asset_id: item.assetid});
		} else {
			const uploadInfo = await refreshUpload(token, {upload_id: item.uploadid});
			if (uploadInfo.data.result.status === "asset_created" && uploadInfo.data.result.asset_id) {
				await deleteVideo(token, {asset_id: uploadInfo.data.result.asset_id});
			} else deleteDBVideo(token, {uploadid: item.uploadid});
		}
		return;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem delete video");
	}
});

const videosSlice = createSlice({
	name: "videos",
	initialState,
	reducers: {
		setActionLoading(state, action: PayloadAction<boolean>) {
			state.actionLoading = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getVideosList.pending, state => {
				state.loading = true;
			})
			.addCase(getVideosList.fulfilled, (state, action) => {
				state.loading = false;
				state.current = action.payload.current;
				state.pageSize = action.payload.pageSize;
				state.totalResults = action.payload.totalResults;
				state.results = [...action.payload.results];
			})
			.addCase(getVideosList.rejected, (state, action) => {});
		builder
			.addCase(refreshVideoData.pending, state => {
				state.loadingAction = true;
			})
			.addCase(refreshVideoData.fulfilled, (state, action) => {
				state.loadingAction = false;
			})
			.addCase(refreshVideoData.rejected, (state, action) => {
				state.loadingAction = false;
			});
		builder
			.addCase(editVideoData.pending, state => {
				state.loadingAction = true;
			})
			.addCase(editVideoData.fulfilled, (state, action) => {
				state.loadingAction = false;
			})
			.addCase(editVideoData.rejected, (state, action) => {
				state.loadingAction = false;
			});
		builder
			.addCase(editVideoPrintData.pending, state => {
				state.loadingAction = true;
			})
			.addCase(editVideoPrintData.fulfilled, (state, action) => {
				state.loadingAction = false;
				state.results = state.results.map(el => {
					if (el.uploadid === action.payload?.item.uploadid!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editVideoPrintData.rejected, (state, action) => {
				state.loadingAction = false;
			});
		builder
			.addCase(deleteVideoData.pending, state => {
				state.loadingAction = true;
			})
			.addCase(deleteVideoData.fulfilled, (state, action) => {
				state.loadingAction = false;
			})
			.addCase(deleteVideoData.rejected, (state, action) => {
				state.loadingAction = false;
			});
	},
});

export const videosActions = {...videosSlice.actions, getVideosList, refreshVideoData, editVideoData, editVideoPrintData, deleteVideoData};

export default videosSlice;
