import {createSlice} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "../../hooks";
import {auth} from "../../../firebase";
import {generatePartnerReport, getPartnerReport} from "../../../data-access/report";
import {GROUPING} from "../../../constants";
import {ReportResponse} from "../../../data-access/report/types";
import {endOfMonth, format, startOfMonth} from "date-fns";

export interface ReportsInterface {
	loading: boolean;
	data: ReportResponse | null | undefined;
	currentDate: string | undefined;
	generatingReport: boolean;
}

const initialState: ReportsInterface = {
	loading: true,
	data: null,
	currentDate: undefined,
	generatingReport: false,
};

interface LoadReportParams {
	date: string;
	partner: string | undefined;
}

const loadReportsByMonth = createAppAsyncThunk("partner-reports/load-reports", async (params: LoadReportParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const {data} = await getPartnerReport(token, {
			initial_date: params.date,
			final_date: params.date,
			grouping: GROUPING.DAILY,
			partner: params.partner,
		});

		if (Object.keys(data).length === 0) return {data: null, currentDate: params.date};

		const orderedData = data.report.series.sort((a, b) => b.total_series_time_seconds - a.total_series_time_seconds);

		const usedColors = new Set<string>();

		const serieWithColor = orderedData.map(serie => {
			let color;
			do {
				color = `rgba(${serie.isPartner ? Math.floor(Math.random() * 200) : Math.floor(Math.random() * 10 + 240)}, ${
					serie.isPartner ? Math.floor(Math.random() * 200) : Math.floor(Math.random() * 10 + 240)
				}, ${serie.isPartner ? Math.floor(Math.random() * 200) : Math.floor(Math.random() * 10 + 240)}, 1)`;
			} while (usedColors.has(color));
			usedColors.add(color);
			return {...serie, color: color};
		});

		return {
			data: {
				...data,
				report: {
					...data.report,
					series: serieWithColor,
				},
			},
			currentDate: params.date,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

interface GenReportParams {
	partner: string | undefined;
	force: boolean | undefined;
}

const generateReport = createAppAsyncThunk("partner-reports/generate-report", async (params: GenReportParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token || !params.partner) return;

		const {currentDate} = thunkAPI.getState().reports;

		if (!currentDate) return;

		const initialDate = startOfMonth(Date.parse(currentDate));
		const final_date = endOfMonth(initialDate);

		const {data} = await generatePartnerReport(token, {
			initial_date: format(initialDate, "yyyy-MM-dd HH:mm:ss"),
			final_date: format(final_date, "yyyy-MM-dd HH:mm:ss"),
			force: params.force,
			partner_id: params.partner,
		});

		if (data.result === "ok") {
			thunkAPI.dispatch(loadReportsByMonth({date: format(initialDate, "yyyy-MM-dd HH:mm:ss"), partner: params.partner}));
			return;
		}

		return thunkAPI.rejectWithValue("Problem generating report");
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const partnerReportsSlice = createSlice({
	name: "partner-reports",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(loadReportsByMonth.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(loadReportsByMonth.fulfilled, (state, action) => {
			state.loading = false;
			state.data = action.payload?.data;
			state.currentDate = action.payload?.currentDate;
		});
		builder.addCase(loadReportsByMonth.rejected, (state, action) => {
			state.loading = false;
		});

		builder.addCase(generateReport.pending, state => {
			state.generatingReport = true;
		});
		builder.addCase(generateReport.fulfilled, state => {
			state.generatingReport = false;
		});
		builder.addCase(generateReport.rejected, state => {
			state.generatingReport = false;
		});
	},
});

export const partnerReportsActions = {
	...partnerReportsSlice.actions,
	loadReportsByMonth,
	generateReport,
};

export default partnerReportsSlice;
