import {Transition} from "@headlessui/react";
import {useEffect, useMemo, useRef, useState} from "react";
import {toast} from "react-hot-toast";
import {useParams} from "react-router-dom";
import {deepEqual} from "../../../../../../utils/deepEqual";
import {Serie, seriesActions} from "../../../../../_store/features/series/series-slice";
import {
	getInfluencerConfig,
	getInfluencerLink,
	insertInfluencerConfig,
	insertInfluencerLink,
	updateInfluencerConfig,
	updateInfluencerLink,
	updateInfluencerSubscriptionProfits,
} from "../../../../../_store/features/user-db/user-db-actions";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {Button} from "../../../../../components/primitives/Button";
import {Input} from "../../../../../components/primitives/Input";
import {Label} from "../../../../../components/primitives/Label";
import {Switch} from "../../../../../components/primitives/Switch";
import {Percentage, Save} from "../../../../../components/primitives/icons";
import {Spinner} from "../../../../../components/primitives/icons/Spinner";
import {InfluencerLinkType, SerieInfluencerType} from "../../../../../data-access/role/configuration/influencer";
import {SeriesListCombobox} from "./subcomponents/SerieListCombobox";

function InfluencerConfigurations() {
	const {id} = useParams();
	const dispatch = useAppDispatch();
	const series = useAppSelector(state => state.series);
	const [seriesInfluencer, setSeriesInfluencer] = useState<SerieInfluencerType[]>([]);
	const [seriesInfluencerLink, setSeriesInfluencerLink] = useState<InfluencerLinkType>({
		influencer_id: id!,
		link: "",
		referral_code: "",
		annual_subscription_commission: 0,
		month_subscription_commission: 0,
	});
	const initLinkSuccessful = useRef(false);
	const [addingInfluencerActionLoading, setAddingInfluencerActionLoading] = useState(false);
	const [modifyInfluencerActionLoading, setModifyInfluencerActionLoading] = useState(false);
	const [modifyInfluencerSubsLoading, setModifyInfluencerSubsLoading] = useState(false);
	const [linkAndReferralCodeUpdating, setLinkAndReferralCodeUpdating] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!id) return;
		setLoading(true);
		dispatch(seriesActions.getSeriesList({}));
		dispatch(getInfluencerConfig({searchby: "influencer_id", searchvalue: id})).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				if (!res.payload || typeof res.payload === "string") return;
				setLoading(false);
				setSeriesInfluencer(res.payload.results);
			}
		});
		dispatch(getInfluencerLink({searchby: "influencer_id", searchvalue: id})).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				setLoading(false);
				if (!res.payload || typeof res.payload === "string" || !res.payload.results.length) return;
				setSeriesInfluencerLink(res.payload.results[0]);
				initLinkSuccessful.current = true;
			}
		});
	}, [dispatch, id]);

	const handleAddSerieMembership = (data: Serie) => {
		setAddingInfluencerActionLoading(true);
		dispatch(insertInfluencerConfig({active: false, profit_percentage: 0, influencer_id: id!, serie_id: data.id})).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				if (!res.payload || typeof res.payload === "string") return;
				const data = res.payload;
				setAddingInfluencerActionLoading(false);
				setSeriesInfluencer(prev => [...prev, data]);

				toast.success("Añadida relación correctamente.");
			}
		});
	};

	const handleModifySerieMembership = (data: SerieInfluencerType) => {
		if (modifyInfluencerActionLoading) return;
		setModifyInfluencerActionLoading(true);
		dispatch(updateInfluencerConfig(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				if (!res.payload || typeof res.payload === "string") return;
				const resData = res.payload;
				setModifyInfluencerActionLoading(false);
				setSeriesInfluencer(prev => prev.map(el => (el.serie_id === resData.serie_id ? resData : el)));

				toast.success("Añadida relación correctamente.");
			}
		});
	};

	const handleLinkAndReferralCodeSave = () => {
		if (linkAndReferralCodeUpdating) return;
		if (!seriesInfluencerLink.link || !seriesInfluencerLink.referral_code) {
			return toast.error("Para guardar los cambios debe llenar ambos campos.");
		}
		setLinkAndReferralCodeUpdating(true);

		if (!initLinkSuccessful.current) {
			return dispatch(insertInfluencerLink(seriesInfluencerLink)).then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					if (!res.payload || typeof res.payload === "string") return;
					setLinkAndReferralCodeUpdating(false);
					setSeriesInfluencerLink(res.payload);

					toast.success("Añadida relación correctamente.");
				}
			});
		}

		return dispatch(updateInfluencerLink(seriesInfluencerLink)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				if (!res.payload || typeof res.payload === "string") return;
				setLinkAndReferralCodeUpdating(false);
				setSeriesInfluencerLink(res.payload);

				toast.success("Añadida relación correctamente.");
			}
		});
	};

	const handleSetSubscription = (data: InfluencerLinkType) => {
		if (modifyInfluencerSubsLoading) return;
		setModifyInfluencerSubsLoading(true);
		dispatch(
			updateInfluencerSubscriptionProfits({
				influencer_id: data.influencer_id,
				annual_subscription_commission: data.annual_subscription_commission,
				month_subscription_commission: data.month_subscription_commission,
			}),
		).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				if (!res.payload || typeof res.payload === "string") return;
				setModifyInfluencerSubsLoading(false);
				toast.success("Porcentaje de beneficios por suscripciones actualizados correctamente.");
			}
		});
	};

	const remainingSeries = useMemo(() => {
		if (!seriesInfluencer?.length) return series.results;

		return series.results.filter(serie => !seriesInfluencer.some(el => el.serie_id === serie.id));
	}, [series.results, seriesInfluencer]);

	if (loading)
		return (
			<div className="mx-auto mt-4 flex items-center justify-center">
				<Spinner />
			</div>
		);

	return (
		<>
			<div className="px-4 py-5 sm:px-6">
				<h3 className="text-base font-semibold leading-6 text-gray-900">Configuración de usuario</h3>
				<p className="mt-1 max-w-2xl text-sm text-gray-500">
					Actualiza las series a las que pertenece el influencer y la distribución de ganancias.
				</p>
			</div>
			<div className="flex items-center gap-2 border-t border-gray-200 px-4 py-3 sm:px-6">
				<div>
					<Label htmlFor="link">Link:</Label>
					<Input
						id="link"
						placeholder="https://pronyr.com"
						className="max-w-xs"
						value={seriesInfluencerLink.link}
						onChange={e => {
							setSeriesInfluencerLink(prev => ({...prev, link: e.target.value}));
						}}
					/>
				</div>
				<div>
					<Label htmlFor="referral_code">Código de Referido:</Label>
					<Input
						id="referral_code"
						placeholder="XXXXXXXX"
						className="max-w-xs"
						value={seriesInfluencerLink.referral_code}
						onChange={e => {
							setSeriesInfluencerLink(prev => ({...prev, referral_code: e.target.value}));
						}}
					/>
				</div>
				<Button className="ml-auto flex items-center gap-2" onClick={handleLinkAndReferralCodeSave}>
					{linkAndReferralCodeUpdating && <Spinner className="h-4 w-4 animate-spin" />}
					<Save className="h-5 w-5" />
					<div className="text-sm font-medium leading-none">Guardar</div>
				</Button>
			</div>
			<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
				<SubscriptionItem data={seriesInfluencerLink} onSetSubscription={handleSetSubscription} loading={modifyInfluencerSubsLoading} />
			</div>
			<div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
				<p className="text-base font-semibold leading-6 text-gray-900">Añadir nueva serie:</p>
				{addingInfluencerActionLoading ? (
					<Spinner />
				) : !remainingSeries.length ? (
					<span className="text-sm italic text-gray-600">Todas han sido añadidas</span>
				) : (
					<SeriesListCombobox data={remainingSeries} onAdd={handleAddSerieMembership} />
				)}
			</div>
			<div className="relative border-t border-gray-200">
				<Transition
					show={modifyInfluencerActionLoading}
					enter="transition-opacity duration-75"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="absolute left-1/2 top-6 flex items-center justify-center rounded-full bg-white shadow-xl ring ring-gray-200">
						<Spinner className="h-6 w-6 animate-spin" />
					</div>
				</Transition>
				<dl>
					{seriesInfluencer.map(el => (
						<Item
							key={el.serie_id}
							data={{...series.results.find(serie => el.serie_id === serie.id)!, influencer: el}}
							onSetMembership={handleModifySerieMembership}
						/>
					))}
				</dl>
			</div>
		</>
	);
}

interface ItemProps {
	data: Serie & {influencer: SerieInfluencerType};
	onSetMembership: (data: SerieInfluencerType) => void;
}
const Item = ({data, onSetMembership}: ItemProps) => {
	const [influencer, setInfluencer] = useState<SerieInfluencerType>(data.influencer);

	const handleSetInfluencer = () => {
		onSetMembership(influencer);
	};

	useEffect(() => {
		setInfluencer(data.influencer);
	}, [data.influencer]);

	return (
		<div className="flex items-center bg-gray-50 px-4 py-5 odd:bg-gray-50 sm:gap-4 sm:px-6" key={data.id}>
			<img src={data.img_logo} alt={`Logo de la serie ${data.title}`} className="h-20 w-20 rounded bg-neutral-900 object-contain p-2" />
			<dt className="text-lg font-semibold text-foreground">{data.title}</dt>
			<div className="ml-auto mt-1 flex min-w-[150px] items-center gap-2 text-sm text-gray-900 sm:mt-0">
				<Switch
					id={`${data.title}-${data.id}`}
					checked={influencer.active}
					onCheckedChange={c => {
						setInfluencer(prev => ({...prev, active: c}));
					}}
				/>
				<Label htmlFor={`${data.title}-${data.id}`}>{influencer.active ? "Activado" : "Desactivado"}</Label>
			</div>
			<div className="ml-5 flex items-center">
				<Label htmlFor={`${data.title}-${data.id}-dis`} className="mr-2">
					Distribución:
				</Label>
				<div className="flex rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background">
					<Input
						id={`${data.title}-${data.id}-dis`}
						type="number"
						min={0}
						max={100}
						step={0.1}
						value={influencer.profit_percentage}
						className="rounded-r-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
						onChange={e => {
							setInfluencer(prev => ({...prev, profit_percentage: parseFloat(e.target.value)}));
						}}
						placeholder="Distribución..."
					/>
					<div className="flex items-center justify-center rounded-r-md bg-primary px-2 ">
						<Percentage className="h-6 w-6 flex-shrink-0 text-background" />
					</div>
				</div>
			</div>
			<Button
				onClick={handleSetInfluencer}
				disabled={deepEqual(
					{active: influencer.active, profit_percentage: influencer.profit_percentage},
					{active: data.influencer.active, profit_percentage: data.influencer.profit_percentage},
				)}
			>
				<Save className="h-5 w-5" />
			</Button>
		</div>
	);
};

interface SubscriptionItemProps {
	data: InfluencerLinkType;
	onSetSubscription: (data: InfluencerLinkType) => void;
	loading: boolean;
}
const SubscriptionItem = ({data, onSetSubscription, loading}: SubscriptionItemProps) => {
	const [influencer, setInfluencer] = useState<InfluencerLinkType>(data);

	const handleSetSubscription = () => {
		onSetSubscription(influencer);
	};

	useEffect(() => {
		setInfluencer(data);
	}, [data]);

	return (
		<div className="flex flex-col bg-gray-50 odd:bg-gray-50 sm:gap-4">
			<div className="flex flex-row items-center justify-between">
				<h3 className="text-lg font-semibold text-foreground">Suscripción Anual:</h3>
				<div className="ml-5 flex items-center">
					<Label htmlFor={`${data.annual_subscription_commission}-dis`} className="mr-2">
						Distribución:
					</Label>
					<div className="flex rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background">
						<Input
							id={`${data.annual_subscription_commission}-dis`}
							type="number"
							min={0}
							max={100}
							step={0.1}
							value={influencer.annual_subscription_commission}
							className="rounded-r-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
							onChange={e => {
								setInfluencer(prev => ({...prev, annual_subscription_commission: parseFloat(e.target.value)}));
							}}
							placeholder="Distribución por suscripción anual..."
						/>
						<div className="flex items-center justify-center rounded-r-md bg-primary px-2 ">
							<Percentage className="h-6 w-6 flex-shrink-0 text-background" />
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-row items-center justify-between">
				<h3 className="text-lg font-semibold text-foreground">Suscripción Mensual:</h3>
				<div className="ml-5 flex items-center">
					<Label htmlFor={`${data.annual_subscription_commission}-dis`} className="mr-2">
						Distribución:
					</Label>
					<div className="flex rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background">
						<Input
							id={`${data.month_subscription_commission}-dis`}
							type="number"
							min={0}
							max={100}
							step={0.1}
							value={influencer.month_subscription_commission}
							className="rounded-r-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
							onChange={e => {
								setInfluencer(prev => ({...prev, month_subscription_commission: parseFloat(e.target.value)}));
							}}
							placeholder="Distribución por suscripción mensual..."
						/>
						<div className="flex items-center justify-center rounded-r-md bg-primary px-2 ">
							<Percentage className="h-6 w-6 flex-shrink-0 text-background" />
						</div>
					</div>
				</div>
			</div>

			<Button className="ml-auto flex items-center gap-2" onClick={handleSetSubscription}>
				{loading && <Spinner className="h-4 w-4 animate-spin" />}
				<Save className="h-5 w-5" />
				<div className="text-sm font-medium leading-none">Guardar</div>
			</Button>
		</div>
	);
};

export default InfluencerConfigurations;
