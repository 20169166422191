import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {coinProductsActions} from "../../_store/features/products/coin-products-slice";
import {CoinStripeModal} from "./subcomponents/CoinStripeModal";
import {CoinGoogleModal} from "./subcomponents/CoinGoogleModal";
import {Input} from "../../components/primitives/Input";
import {Textarea} from "../../components/primitives/Textarea";
import {Button} from "../../components/primitives/Button";
import Select from "react-select";
import {selectStyles} from "./SelectStyles";
import {toast} from "react-hot-toast";

export interface IFormInputs {
	id: string;
	name: string;
	description: string;
	coins: number;
	stripe_price: number;
	google_price: number;
	apple_price: number;
	active: boolean;
	status?: {label: string; value: boolean};
}
const initialState: IFormInputs = {
	id: "",
	name: "",
	description: "",
	coins: 0,
	stripe_price: 1,
	google_price: 1,
	apple_price: 1,
	active: false,
	status: {
		label: "",
		value: false,
	},
};

interface SelectOption {
	label: string;
	value: boolean;
}

const selectStatus: SelectOption[] = [
	{label: "Activo", value: true},
	{label: "Inactivo", value: false},
];

export function CoinProductsEdit() {
	let {id} = useParams();
	const coinProductData = useAppSelector(state => state.coinProducts.results.find(el => el.id === id));
	const [productData, setProductData] = useState(initialState);

	const [selectedStatus, setSelectedStatus] = useState<SelectOption | null>(null);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(state => state.coinProducts.loading);
	const isSuccess = useAppSelector(state => state.coinProducts.success);
	const error = useAppSelector(state => state.coinProducts.error);
	const [isSubmitted, SetIsSubmitted] = useState(false);

	const [blockedSection, setBlockedSection] = useState({updData: false, updStripe: false, updGoogle: false, updApple: false});

	/*MODAL*/
	const [isModalStripe, setIsModalStripe] = useState(false);
	const [isModalGoogle, setIsModalGoogle] = useState(false);

	const {
		register,
		formState: {errors},
		handleSubmit,
		reset,
		control,
	} = useForm<IFormInputs>({
		defaultValues: initialState,
	});

	const handleUpdateSync = () => {
		if (!coinProductData) {
			return navigate("/products/coin-list");
		} else {
			if (coinProductData.stripe_productid || coinProductData.google_id || coinProductData.apple_id) {
				setBlockedSection(existingValues => ({
					...existingValues,
					updData: false,
					updStripe: coinProductData.stripe_productid ? false : true,
					updGoogle: coinProductData.google_id ? false : true,
					updApple: coinProductData.apple_id ? false : true,
				}));
			} else {
				setBlockedSection(existingValues => ({
					...existingValues,
					updData: true,
					updStripe: true,
					updGoogle: true,
					updApple: true,
				}));
			}
		}
	};

	useEffect(() => {
		handleUpdateSync();
		coinProductData && setProductData(coinProductData);
		reset(coinProductData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coinProductData]);

	useEffect(() => {
		if (!isSuccess || !isSubmitted) return;
		dispatch(coinProductsActions.setSuccess(false));
		toast.success("Producto editado correctamente");
		navigate("/products/coin-list");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	useEffect(() => {
		if (!isLoading && isSubmitted) {
			if (error) {
				toast.error("ERROR: " + error);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const onSubmit: SubmitHandler<IFormInputs> = data => {
		//const { a, ...noA } = myObject;
		data.active = data.status?.value!;
		SetIsSubmitted(true);
		dispatch(coinProductsActions.editCoinProducts({...data, productid: data.id}));
	};

	useEffect(() => {
		if (productData?.active !== null) {
			const activeStatus = productData.active ? selectStatus[0] : selectStatus[1];
			setSelectedStatus(activeStatus);
		}
	}, [productData]);

	return (
		<>
			<div className="px-5 py-4">
				<div className="flex items-center justify-between border-b border-b-border bg-background pb-5">
					<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Editar Producto de Coins</h2>
				</div>

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Nombre<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mt-1 flex w-full max-w-2xl justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
							<Input
								type="text"
								placeholder="Nombre del producto"
								{...register("name", {required: "Campo requerido"})}
								disabled={!blockedSection.updData}
							/>
							{errors.name && <span className="text-red-500">{errors.name.message}</span>}
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Descripción<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mt-1 flex w-full max-w-2xl justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
							<Textarea rows={3} placeholder="Descripción del producto" {...register("description", {required: "Campo requerido"})} />
							{errors?.description?.message && (
								<span className="text-sm font-medium text-destructive">{errors?.description?.message}</span>
							)}
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Monedas<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mt-1 flex w-full max-w-2xl justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
							<Input
								type="number"
								{...register("coins", {
									required: "Campo requerido",
									min: {
										value: 1,
										message: "Valor fuera de rango",
									},
								})}
							/>
							{errors.coins && <span className="text-red-500">{errors.coins.message}</span>}
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />
					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Estado<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="mb-3 w-full max-w-2xl">
							<Controller
								name="status"
								control={control}
								render={({field}) => (
									<Select
										className="mt-1 w-full rounded ring-1"
										styles={selectStyles}
										{...field}
										options={selectStatus as any}
										value={selectedStatus}
										onChange={selectedOption => {
											setSelectedStatus(selectedOption);
											field.onChange(selectedOption);
										}}
									/>
								)}
							/>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />

					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Stripe (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("stripe_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											stripe_price: Number(e.target.value),
										});
									}}
								/>
								{errors.stripe_price && <span className="mr-2 w-full text-red-500">{errors.stripe_price.message}</span>}
							</div>
							<Button
								type="button"
								variant="default"
								className={`col-start-2 flex rounded py-2 uppercase text-white ${
									blockedSection.updStripe ? "bg-green-700 transition-colors duration-300 hover:bg-green-500" : "bg-red-700"
								}`}
								onClick={() => {
									setIsModalStripe(true);
								}}
							>
								Sincronizar
							</Button>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />

					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Google (valor en centavos)
							<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("google_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											google_price: Number(e.target.value),
										});
									}}
								/>
								{errors.google_price && <span className="text-red-500">{errors.google_price.message}</span>}
							</div>
							<Button
								type="button"
								variant="default"
								className={`col-start-2 flex rounded py-2 uppercase text-white ${
									blockedSection.updGoogle ? "bg-green-700 transition-colors duration-300 hover:bg-green-500" : "bg-red-700"
								}`}
								onClick={() => {
									setIsModalGoogle(true);
								}}
							>
								Sincronizar
							</Button>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-border" />

					<div className="flex w-full flex-row justify-between px-4 py-6">
						<div className="text-sm font-medium leading-6 text-gray-900">
							Precio Apple (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
						</div>
						<div className="flex w-full max-w-2xl">
							<div className="mr-2 mt-1 flex w-full justify-end text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("apple_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
									onChange={e => {
										setProductData({
											...productData,
											apple_price: Number(e.target.value),
										});
									}}
								/>
								{errors.apple_price && <span className="text-red-500">{errors.apple_price.message}</span>}
							</div>
							<Button
								type="button"
								variant="default"
								className={`col-start-2 flex rounded py-2 uppercase text-white ${
									blockedSection.updApple ? "bg-green-700 transition-colors duration-300 hover:bg-green-500" : "bg-red-700"
								}`}
							>
								Sincronizar
							</Button>
						</div>
					</div>
					<div className="mt-4 flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								navigate("/products/coin-list");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading}>
							{isLoading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Editar producto
						</Button>
					</div>
				</form>
			</div>
			<CoinStripeModal
				onDismiss={() => {
					setIsModalStripe(false);
				}}
				onSuccess={() => {
					setIsModalStripe(false);
					setBlockedSection(existingValues => ({
						...existingValues,
						updStripe: false,
					}));
					toast.success("Sincronizado con Stripe Correctamente");
				}}
				open={isModalStripe}
				data={productData}
			/>
			<CoinGoogleModal
				onDismiss={() => {
					setIsModalGoogle(false);
				}}
				open={isModalGoogle}
				data={productData}
				onSuccess={() => {
					setIsModalGoogle(false);
					setBlockedSection(existingValues => ({
						...existingValues,
						updGoogle: false,
					}));
					toast.success("Sincronizado con Google Correctamente");
				}}
			/>
		</>
	);
}
