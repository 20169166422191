import {useState, useEffect, FormEvent} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {execPasswordResetData} from "../../_store/features/user-db/user-db-actions";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {Close, Lock} from "../../components/primitives/icons";

interface FormFields {
	passwordnew: HTMLInputElement;
	passwordconfirm: HTMLInputElement;
}

function ResetPassword() {
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const reduxDbUsers = useAppSelector(state => state.dbuser);
	const [searchParams] = useSearchParams();
	const hash = searchParams.get("hash");
	const navigate = useNavigate();

	useEffect(() => {
		(hash === null || hash === undefined) && navigate("/sign-in");
	});

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const {passwordnew, passwordconfirm} = e.currentTarget.elements as unknown as FormFields;

		if (passwordnew.value === "") {
			return setError("Inserte la password");
		}

		if (passwordnew.value.length < 6) {
			return setError("Password debe contener 6 characters o más.");
		}

		if (passwordnew.value !== passwordconfirm.value) {
			return setError("Las contraseñas no coinciden");
		}

		dispatch(execPasswordResetData(hash as string, passwordnew.value, true));
		setLoading(true);

		e.currentTarget.reset();
	};

	useEffect(() => {
		if (reduxDbUsers.editing === false && loading === true) {
			if (reduxDbUsers.success === true) {
				setError("");
				setSuccess(true);
			} else {
				setError("Error en la solicitud vuelva a intentarlo más tarde");
				setLoading(false);
			}
			setLoading(false);
		}
	}, [reduxDbUsers, loading]);

	return (
		<div className="flex h-screen w-screen items-center justify-center bg-neutral-200">
			<div className="grid grid-cols-2 overflow-hidden rounded-md bg-white">
				<div className="flex flex-col p-10">
					<form className="mt-10 flex h-full flex-col" onSubmit={handleSubmit}>
						<p className="mb-4">
							Si no ha solicitado cambiar su contraseña,<br></br>
							ignore el mensaje. Si ha sido usted, ingrese sus <br></br>
							nuevas credenciales de la platafroma.
						</p>
						{error !== "" && success !== true && (
							<div className="flex w-full flex-grow-0 items-center justify-between rounded border border-red-400 bg-red-200 px-4 py-2 text-sm">
								<span>{error}</span>
								<button onClick={() => setError("")}>
									<Close className="h-4 w-4" />
								</button>
							</div>
						)}
						{!success ? (
							<>
								<div className="mb-4 flex items-center overflow-hidden rounded-[4px] border border-solid border-neutral-400 focus-within:outline">
									<div className="flex aspect-square h-10 w-10 flex-shrink-0 items-center justify-center border-r border-neutral-400 bg-neutral-300">
										<Lock className="h-4 w-4" />
									</div>
									<input
										className="form-control w-full px-3 py-2 focus:outline-none"
										type="password"
										name="passwordnew"
										placeholder="Contraseña"
										required
									/>
								</div>
								<div className="mb-4 flex items-center overflow-hidden rounded-[4px] border border-solid border-neutral-400 focus-within:outline">
									<div className="flex aspect-square h-10 w-10 flex-shrink-0 items-center justify-center border-r border-neutral-400 bg-neutral-300">
										<Lock className="h-4 w-4" />
									</div>
									<input
										className="form-control w-full px-3 py-2 focus:outline-none"
										type="password"
										name="passwordconfirm"
										placeholder="Confirmar la contraseña"
										required
									/>
								</div>
							</>
						) : (
							<>
								<p>
									Password recuperada con exito, vuelva al login<br></br>para iniciar sesión.
								</p>
							</>
						)}
						<div className="mt-auto grid grid-cols-2 place-items-center">
							{!success ? (
								<>
									<div className="place-self-start">
										<button
											className="w-48 rounded border border-indigo-800 bg-indigo-600 px-6 py-2 text-white transition-colors duration-300 hover:bg-indigo-400"
											type="submit"
										>
											{reduxDbUsers.editing === true && loading ? "Loading" : "Nueva Contraseña"}
										</button>
									</div>
									<div>
										<Link to="/sign-in" className="underline" type="button">
											Regresar al login
										</Link>
									</div>
								</>
							) : (
								<>
									<Link
										to="/sign-in"
										className="w-48 rounded border border-indigo-800 bg-indigo-600 px-6 py-2 text-white transition-colors duration-300 hover:bg-indigo-400"
										type="submit"
									>
										Regresar al login
									</Link>
								</>
							)}
						</div>
					</form>
				</div>
				<div className="flex flex-col items-center justify-center bg-neutral-900">
					<img src="/logo-big.png" alt="PRONYR Logo" className="mb-4 mt-24 w-32" />
					<p className="mb-24 text-white">v2.1.0</p>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
