import {ChartOptions} from "chart.js";
import {useMemo} from "react";
import {Pie} from "react-chartjs-2";
import {GetUsersVerificationData} from "../../../../data-access/dashboard/types";
import {Spinner} from "../../../../components/primitives/icons";
import {VERIFICATIONS_TYPES} from "../../../../constants";

interface VerificationsChartProps {
	statistics: GetUsersVerificationData[];
	loading?: boolean;
	onClick: () => void;
}

const options: ChartOptions<"pie"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: "top" as const,
		},
	},
};

export default function VerificationsChart({statistics, loading, onClick}: VerificationsChartProps) {
	const data = useMemo(() => {
		const dataToShow = statistics ? statistics.slice().sort((a, b) => a.type - b.type) : [];
		return {
			labels: VERIFICATIONS_TYPES.map(({label}) => label),
			datasets: [
				{
					label: "Cantidad de usuarios",
					data: VERIFICATIONS_TYPES.map(item => {
						const retrieveItem = dataToShow.find(el => el.type === item.value);
						return retrieveItem?.users || 0;
					}),
					backgroundColor: ["rgba(0, 172, 7, 1)", "rgba(242, 116, 116, 1)", "rgba(0, 80, 150, 1)", "rgba(18, 18, 18, 0.31)"],
					borderColor: "white",
					borderWidth: 1,
				},
			],
		};
	}, [statistics]);

	const handleOnClick = () => {
		onClick?.();
	};

	return (
		<div className="w-1/2 cursor-pointer rounded-md border" onClick={handleOnClick}>
			<h3 className="my-4 text-center text-xl font-semibold leading-none tracking-tight">Usuarios verificados</h3>
			<div className="p-6 pt-0">
				{loading ? (
					<div className="flex w-full items-center justify-center">
						<Spinner />
					</div>
				) : (
					<Pie height={350} options={options} data={data} />
				)}
			</div>
		</div>
	);
}
