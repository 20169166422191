import {Button} from "../../../components/primitives/Button";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../components/primitives/Sheet";
import {useForm, SubmitHandler} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useAppDispatch} from "../../../_store/hooks";
import {AssociateCustomerData, AssociateCustomerDataSchema} from "../../../data-access/vendors";
import {useGetVendorListQuery} from "../../../_store/features/vendor/api";
import Select, {SingleValue} from "react-select";
import {useEffect, useMemo} from "react";
import {vendorActions} from "../../../_store/features/vendor/slice";
import {auth} from "../../../firebase";
import toast from "react-hot-toast";
import {LinkCustomer} from "../../../components/primitives/icons";

interface AssignVendorSheetProps extends SheetProps {
	customerid: string;
	customername: string;
	onRefresh: () => void;
}

export default function AssignVendorSheet(props: AssignVendorSheetProps) {
	const {...rest} = props;
	const dispatch = useAppDispatch();

	const {handleSubmit, setValue} = useForm<AssociateCustomerData>({
		resolver: zodResolver(AssociateCustomerDataSchema),
		defaultValues: {customerid: props.customerid, vendorid: ""},
	});

	const {data} = useGetVendorListQuery({page: 0, page_size: 999999, admin: true, role: "vendor"});
	const vendors = useMemo(
		() =>
			data?.results.map(vendor => ({
				value: vendor.id,
				label: vendor.name,
			})) || [],
		[data?.results],
	);

	const handleOnVendorChange = (selected: SingleValue<{value: string; label: string}>) => {
		setValue("vendorid", selected?.value || "");
	};

	const onSubmit: SubmitHandler<AssociateCustomerData> = async data => {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			toast.error("No token found");
			return;
		}
		const thunk = await dispatch(vendorActions.associateCustomer(data));

		if (thunk.meta.requestStatus === "fulfilled") {
			props.onOpenChange?.(false);
			props.onRefresh();
		} else {
			toast.error("No se pudo asociar el vendedor");
		}
	};

	useEffect(() => {
		if (vendors.length > 0) {
			setValue("vendorid", vendors[0].value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendors]);

	return (
		<Sheet {...rest}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>Asignar vendedor</SheetTitle>
					<SheetDescription>
						Asignar un vendedor al usuario <b>{props.customername}</b>:
					</SheetDescription>
				</SheetHeader>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div>
						<label className="mb-2 block text-sm font-medium text-gray-600">Tipo de contenido:</label>
						<Select
							className="basic-single mb-4 text-sm"
							classNamePrefix="select"
							isClearable={false}
							isSearchable={false}
							placeholder="Tipo de contenido"
							name="userid"
							options={vendors}
							defaultValue={vendors[0]}
							backspaceRemovesValue={false}
							onChange={handleOnVendorChange}
						/>
					</div>
					<Button type="submit" className="mt-4">
						<LinkCustomer className="mr-2 h-4 w-4 text-white" />
						<span>Asociar</span>
					</Button>
				</form>
			</SheetContent>
		</Sheet>
	);
}
