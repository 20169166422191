import {Toaster as RHTToaster, DefaultToastOptions} from "react-hot-toast";
import {cn} from "../../../../utils/classNames";

const styles: DefaultToastOptions = {
	className: cn("bg-background text-foreground"),
	success: {},
	error: {
		style: {},
	},
};

export default function Toaster() {
	return <RHTToaster position="top-right" reverseOrder={false} toastOptions={styles} />;
}
