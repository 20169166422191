import {useRef} from "react";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../primitives/ToolTip";
import {cn} from "../../../../../utils/classNames";
import {format, parseISO} from "date-fns";
import {Comment} from "../../../../data-access/comments/comments";

const proxyTextComment = (text: string) => {
	if (text === "roku_parentid") {
		return "Comentario de la comunidad de Roku";
	}
	return text;
};

export default function TextComment({comment, isSelected}: {comment: Comment; isSelected: boolean}) {
	const nestingLevel = useRef(comment.path?.split(".").length ?? 0).current;

	return (
		<div
			key={comment.id}
			className={cn({
				"md xs:pl-xl text-14 before:border-tone-4 xs:before:left-[16.5px] relative": nestingLevel > 0,
			})}
			style={{paddingLeft: nestingLevel * 24}}
		>
			<div
				className={cn(
					"relative m-1 rounded-md border border-border bg-accent p-4",
					[
						"before:absolute",
						"before:left-[-12px]",
						"before:top-[-6px]",
						"before:h-[50%]",
						"before:w-[12px]",
						"before:border-y-0",
						"before:border-l-[1px]",
						"before:border-r-[0px]",
						"before:border-b-[1px]",
						"before:rounded-bl-md",
						"before:border-solid",
						"before:border-l-2",
						"before:border-b-2",
						"before:content-['']",
					],
					{
						"border-foreground/50 before:border-foreground/50": isSelected,
					},
				)}
			>
				<div className="flex items-baseline gap-2">
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<div className="w-fit text-sm font-semibold">{comment.author}</div>
							</TooltipTrigger>
							<TooltipContent side="bottom">
								<p>
									<span className="font-semibold">Autor ID:</span> {comment.author_id}
								</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
					<div className={cn("text-xs text-muted-foreground", {hidden: nestingLevel === 0})}>
						{format(parseISO(comment.created_at! + "Z"), "dd/MM/yy hh:mm bb")}
					</div>
				</div>
				<div className="mt-2 text-sm">{proxyTextComment((comment.content as any).payload)}</div>
				<div className="mt-2 text-xs text-muted-foreground">
					<span>ID Comentario:</span> {comment.id}
				</div>
			</div>
		</div>
	);
}
