import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import "chartjs-adapter-date-fns";
import {addDays, differenceInDays, differenceInMonths, endOfDay, format} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useAppDispatch} from "../../../../_store/hooks";
import {DateRange} from "react-day-picker";
import {oneMonth, transformedData} from "../../../../../utils/barGraphTransformData";
import {Spinner} from "../../../../components/primitives/icons";
import {GetAppInstallationsDataResponse} from "../../../../data-access/dashboard/types";
import {es} from "date-fns/locale";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

interface AppInstallationsChartProps {
	data: GetAppInstallationsDataResponse;
	dates: DateRange | undefined;
	isLoading?: boolean;
}

export default function AppInstallationsChart({dates, data: installationsData, isLoading}: AppInstallationsChartProps) {
	const startDate = new Date(dates?.from ?? 0);
	const endDate = new Date(dates?.to ?? Date.now());
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; views: number}[]>>(null);
	const [showByMonths, setShowByMonths] = useState(false);
	const diff_days = differenceInDays(endDate, startDate) + 1;
	const diff_months = differenceInMonths(endDate, startDate) + 1;

	const dispatch = useAppDispatch();

	useEffect(() => {
		const durationInMilliseconds = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(durationInMilliseconds > oneMonth);
	}, [dates, dispatch]);

	const fillerData = Array(diff_days)
		.fill(0)
		.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), views: 0}));

	const monthArray = Array(diff_months)
		.fill(0)
		.map((_, idx) => ({date: format(addDays(endOfDay(endDate), -idx * 30), "yyyy-MM-dd", {locale: es}), views: 0}));

	const data: ChartData<"bar", {date: string; views: number}[]> = useMemo(() => {
		const googleData = installationsData?.installations_by_day.map(item => ({date: item.date, views: item.play_store_count})) ?? [];
		const appStoreData = installationsData?.installations_by_day.map(item => ({date: item.date, views: item.app_store_count})) ?? [];

		return {
			datasets: [
				{
					label: "Google Play",
					data: transformedData(fillerData, googleData, showByMonths, monthArray),
					backgroundColor: "#FF6384",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "views",
					},
				},
				{
					label: "Apple Store",
					data: transformedData(fillerData, appStoreData, showByMonths, monthArray),
					backgroundColor: "#36A2EB",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "views",
					},
				},
			],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, showByMonths, installationsData?.installations_by_day]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Instalaciones de la App.</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Bar
							height={350}
							ref={chartRef}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								plugins: {
									tooltip: {
										callbacks: {
											title(tooltipItems) {
												const date = new Date(tooltipItems[0].label);

												// Format the date based on the available information
												const formattedDate = showByMonths
													? format(date, "MMM - yyyy", {locale: es}).toLowerCase()
													: format(date, "MMM d", {locale: es}).toLowerCase();

												return formattedDate;
											},
										},
									},
								},
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));

												// Format the date based on the available information
												const formattedDate = showByMonths
													? format(date, "MMM - yyyy", {locale: es}).toLowerCase()
													: format(date, "MMM d", {locale: es}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
										stacked: true,
									},
									y: {
										ticks: {
											precision: 0,
										},
										stacked: true,
									},
								},
							}}
							data={data}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
