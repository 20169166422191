import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {selectedRentalSerieSelector} from "../series/series-slice";
import {SelectOptionType} from "../../../../utils/globalTypes";

export const getUnselectedRentals = createSelector(
	(state: RootState) => selectedRentalSerieSelector(state),
	(state: RootState) => state.rentals,
	(series, {productRentals, rentals}): SelectOptionType[] => {
		const productRentalsIds = new Map(Object.entries(productRentals).map(([_, value]) => [value.sr_seriesid, value.sr_id]));

		return series
			.filter(serie => !rentals.some(rental => rental.seriesid === serie.id))
			.map(s => ({
				label: s.title,
				value: productRentalsIds.get(s.id) || "",
			}));
	},
);
