import {forwardRef} from "react";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import {VariantProps, cva} from "class-variance-authority";

import {cn} from "../../../../utils/classNames";

const toggleVariants = cva(
	"inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors data-[state=on]:bg-accent data-[state=on]:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ring-offset-background hover:bg-muted hover:text-muted-foreground",
	{
		variants: {
			variant: {
				default: "bg-transparent",
				outline: "bg-transparent border border-input hover:bg-accent hover:text-accent-foreground",
			},
			size: {
				default: "h-10 px-3",
				sm: "h-9 px-2.5",
				lg: "h-11 px-5",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);

const Toggle = forwardRef<
	React.ElementRef<typeof TogglePrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
>(({className, variant, size, ...props}, ref) => (
	<TogglePrimitive.Root ref={ref} className={cn(toggleVariants({variant, size, className}))} {...props} />
));

export {Toggle, toggleVariants};
