export const REGEX_TO_MATCH_LOGO_URL_W_H = /w(\d+)_h(\d+)/;

interface pictureManageInterface {
	url: string;
	size: "100" | "200" | "400";
}

export const picturesManage = ({url, size}: pictureManageInterface) => {
	let index: number = 0;
	// Randomized necessary for image caching prevention refer to issue:
	// https://github.com/facebook/react-native/issues/12606
	// Added Date.now()
	if (url.includes("googleusercontent")) {
		index = url.lastIndexOf("=");
		return `${url.slice(0, index + 1)}s${size}-c?date=${Date.now()}`;
	} else if (url.includes("facebook")) {
		return url + "?widht=" + size + "&height=" + size + "&date=" + Date.now();
	} else if (url.includes("googleapis")) {
		index = url.lastIndexOf(".jpg");
		return url.slice(0, index) + "w" + size + url.slice(index) + "?date=" + Date.now();
	} else {
		return url + "?date=" + Date.now();
	}
};

export const getUrlwithSize = ({url, size}: pictureManageInterface) => {
	let index: number = 0;
	index = url.lastIndexOf(".jpg");
	if (index === -1) index = url.lastIndexOf(".png");
	if (index === -1) index = url.lastIndexOf(".jpeg");
	if (index === -1) return url;
	return url.slice(0, index) + "-w" + size + url.slice(index);
};

export const getAspectRatioFromSerieURL = (url: string | undefined, defaultAspectRatio: string) => {
	const logoDimensions = url?.match(REGEX_TO_MATCH_LOGO_URL_W_H);

	return logoDimensions ? {aspectRatio: `${logoDimensions[1]}/${logoDimensions[2]}`} : {aspectRatio: defaultAspectRatio};
};
