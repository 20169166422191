import axios from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export async function uploadImageFile(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_admin_image_file", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function forceUploadImageFileToUser(token: string, userid: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/administrative_upload_image/" + userid, data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
