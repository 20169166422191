import React from "react";
import {ReportResponse} from "../../../../../data-access/report/types";

interface CostTableProps {
	source: ReportResponse;
}

const CostTable = ({source}: CostTableProps) => {
	const {
		report: {costs_details},
	} = source;

	return (
		<div className="mt-8 flow-root">
			<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<div className="flex flex-col space-y-1.5 py-6">
						<h3 className="text-lg font-semibold leading-none tracking-tight">Fee/Gastos de la plataforma</h3>
					</div>
					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
									Descripción
								</th>
								<th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
									Cantidad
								</th>
								<th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
									Precio
								</th>
								<th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
									Total
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200 bg-white">
							{costs_details.map((cost, idx) => (
								<tr key={idx}>
									<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{cost.description}</td>
									<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{cost.units}</td>
									<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">${cost.cost.toFixed(2)}</td>
									<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">${(cost.units * cost.cost).toFixed(2)}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default CostTable;
