import {useEffect, useState, useMemo} from "react";
import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {auth} from "../../../firebase";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {Badge} from "../../../components/primitives/Badge";
import {Pencil, Refresh} from "../../../components/primitives/icons";
import {Input} from "../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../components/primitives/Select";
import {DataTable} from "../../../components/blocks/DataTable";
import {Button} from "../../../components/primitives/Button";
import {Link} from "react-router-dom";
import {Stop} from "../../../components/primitives/icons/Stop";
import {CONTENT} from "../../../constants";
import {Content} from "../../../data-access/series/content";
import {serieContentsActions} from "../../../_store/features/serie-content/serie-content-slice";
import RecordingButton from "../../../components/primitives/RecordingAnimation";
import {toast} from "react-hot-toast";
import ActionConfirmModal from "../../../components/blocks/ActionConfirmModal";
import {formatDurationTime} from "../../../../utils/formatDurationTime";

const searchOptions = [
	{name: "Serie", value: "series_title"},
	{name: "Título del Live", value: "content_title"},
	{name: "Upload ID", value: "uploadid"},
	{name: "Playback ID", value: "playbackid"},
];

const publishedOptions = [
	{name: "Todos", value: undefined},
	{name: "Activo", value: true},
	{name: "Inactivo", value: false},
];

const columnHelper = createColumnHelper<Content>();

function LiveList() {
	const dispatch = useAppDispatch();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const {results: content, loading} = useAppSelector(state => state.serieContent);
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedPublished, setSelectedPublished] = useState(publishedOptions[0]);
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [idToStop, setIdToStop] = useState({
		contentId: "",
		serieId: "",
	});
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [livesStreams, setLivesStreams] = useState<Content[]>([]);
	const livesFiltered = content
		.filter(el => el.content_type === CONTENT.STREAM || el.content_type === CONTENT.LIVE_STREAM)
		.sort((a, b) => {
			if (a.content_type === CONTENT.LIVE_STREAM && b.content_type === CONTENT.STREAM) {
				return -1; // a debe aparecer antes de b
			} else if (a.content_type === CONTENT.STREAM && b.content_type === CONTENT.LIVE_STREAM) {
				return 1; // a debe aparecer después de b
			} else {
				return b.order - a.order; // ordenar por order en caso contrario
			}
		});

	const handleSearch = () => {
		if (!auth.currentUser) return;

		const params: Record<string, any> = {};
		params.published = selectedPublished.value;

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		if (!searchValue && !selectedPublished) return;

		dispatch(
			serieContentsActions.listSeriesContent({
				page: 0,
				page_size: 999999,
				content_type: "4,5",
				...params,
			}),
		).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				setLivesStreams((res.payload as {results: []}).results);
			} else {
				toast.error("Error buscando contenido");
			}
		});
	};

	const stopLiveStream = async (contentId: string, serieId: string) => {
		try {
			const toastId = toast.loading("Deteniendo Live, por favor espere...");
			const stopResult = await dispatch(serieContentsActions.stopLiveVideo({contentId}));

			if (stopResult.payload === 200) {
				await checkLiveStreamStatus(contentId, serieId, toastId);
			} else {
				toast.dismiss(toastId);
				toast.error("Error deteniendo live");
			}
		} catch (error) {
			toast.error("Error deteniendo live");
			console.error("Ocurrió un error:", error);
		}
	};

	const checkLiveStreamStatus = async (contentId: string, serieId: string, toastId: string) => {
		let counter = 0;

		const intervalId = setInterval(async () => {
			counter++;

			if (counter > 5) {
				clearInterval(intervalId);
				toast.dismiss(toastId);
				toast.error("No se pudo detener la transmisión!");
				return;
			}

			const listResult = await dispatch(
				serieContentsActions.listSeriesContent({
					page: 0,
					page_size: 999999,
					content_type: "4,5",
					searchby: "serieid",
					searchvalue: serieId,
				}),
			);

			if (listResult.meta.requestStatus === "fulfilled") {
				const liveStream = (listResult.payload as {results: Content[]}).results.find(el => el.id === contentId);

				if (!liveStream) {
					clearInterval(intervalId);
					toast.dismiss(toastId);
					toast.error("Error deteniendo transmisión");
					return;
				} else if (liveStream.content_type === CONTENT.STREAM) {
					clearInterval(intervalId);
					toast.dismiss(toastId);
					toast.success("Transmisión detenida correctamente!");
					refresh();
					return;
				}
			} else {
				clearInterval(intervalId);
				toast.dismiss(toastId);
				toast.error("Error buscando contenido");
				return;
			}
		}, 2000);
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		dispatch(serieContentsActions.getAllSeriesContents());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination.pageSize]);

	useEffect(() => {
		if (!content) return;
		setLivesStreams(livesFiltered);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("title", {
				id: "Título / Breve descripción",
				header: ({column}) => <DataTableColumnHeader title="Título / Breve descripción" column={column} />,
				cell: info => {
					const isBroadcasting = info.row.original.content_type === CONTENT.LIVE_STREAM;
					return (
						<div className="flex flex-row items-center">
							{isBroadcasting && (
								<div className="mr-2">
									<RecordingButton />
								</div>
							)}
							<div className="flex flex-col">
								<span className="overflow-hidden text-ellipsis text-left">{info.row.original.title}</span>
								<span className="overflow-hidden text-ellipsis text-left text-neutral-500">
									{info.row.original.short_description}
								</span>
							</div>
						</div>
					);
				},
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("serie_title", {
				id: "Serie",
				header: ({column}) => <DataTableColumnHeader title="Serie" column={column} />,
				cell: info => (
					<div className="flex w-full flex-col">
						<span className="overflow-hidden text-ellipsis text-left">{info.row.original.serie_title}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.125),
			}),
			columnHelper.accessor("duration", {
				id: "Duración",
				header: ({column}) => <DataTableColumnHeader title="Duración" column={column} />,
				cell: info => {
					const durationInSeconds = Number(info.row.original.duration);
					const formattedDuration = formatDurationTime(durationInSeconds);

					return (
						<div className="flex w-full flex-col">
							<span className="overflow-hidden text-ellipsis text-left">{formattedDuration}</span>
						</div>
					);
				},
				size: Math.floor(tableWidth * 0.1),
			}),
			columnHelper.accessor("long_description", {
				id: "Descripción",
				header: ({column}) => <DataTableColumnHeader title="Descripción" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden truncate text-ellipsis text-left">{info.row.original.long_description}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.25),
			}),
			columnHelper.accessor("published", {
				id: "Estado",
				header: ({column}) => <DataTableColumnHeader title="Estado" column={column} />,
				cell: info =>
					info.getValue() ? (
						<div className="flex flex-1 justify-start">
							<Badge className="max-h-[20px]" variant={"success"}>
								Activo
							</Badge>
						</div>
					) : (
						<div className="flex flex-1 justify-start">
							<Badge className="max-h-[20px]" variant={"destructive"}>
								Inactivo
							</Badge>
						</div>
					),
				size: Math.floor(tableWidth * 0.15),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full justify-start gap-3">
						<Button
							size={"sm"}
							variant={"outline"}
							asChild
							onClick={() => {
								dispatch(serieContentsActions.setEditing({data: info.row.original, editing: true}));
							}}
						>
							<Link to={"/multimedia/lives-streams/edit/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>

						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setIdToStop({
									contentId: info.row.original.id,
									serieId: info.row.original.serieid,
								});
								setDeleteConfirmationModalOpen(true);
							}}
							disabled={info.row.original.content_type === CONTENT.STREAM}
						>
							<Stop className="h-4 w-4" />
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.15),
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableRef]);

	const handleSignedFilter = (label: string) => {
		const isPublished = publishedOptions.find(el => el.name === label) ?? publishedOptions[0];
		setSelectedPublished(isPublished);
		if (label === "Todos") {
			dispatch(serieContentsActions.getAllSeriesContents());
			return setLivesStreams(livesFiltered);
		}
		setLivesStreams(
			content
				.filter(el => (el.content_type === CONTENT.STREAM || el.content_type === CONTENT.LIVE_STREAM) && el.published === isPublished.value)
				.sort((a, b) => b.order - a.order),
		);
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	const refresh = () => {
		setSelectedPublished(publishedOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		resetStates();

		dispatch(serieContentsActions.getAllSeriesContents());
		setLivesStreams(livesFiltered);
	};

	const resetStates = () => {
		setSearchValue("");
		setIdToStop({
			contentId: "",
			serieId: "",
		});
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Listado de Lives</h2>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>

					<Select onValueChange={handleSignedFilter} value={selectedPublished.name}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{publishedOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.name}`}>
										{sOption.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={livesStreams}
					pageCount={Math.ceil(livesStreams.length / pagination.pageSize)}
					pagination={pagination}
					loading={loading}
					onPaginationChange={setPagination}
					rowHeight={55}
					withDynamicPageSize
					showPagination={false}
					manualPagination={false}
				/>
			</div>
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					resetStates();
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={async () => {
					stopLiveStream(idToStop.contentId, idToStop.serieId);
					resetStates();
					setDeleteConfirmationModalOpen(false);
				}}
				title={"Detener trasmisión en directo"}
				description={<span className="text-white">Al confirmar detendrás la trasmisión, este proceso no se puede revertir.</span>}
			/>
		</div>
	);
}

export default LiveList;
