import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../../../../../components/primitives/Select";
import {PREMIERE_TYPES} from "../../../../../constants";

interface PremiereTypeSelectProps {
	selectedPremiereType: string;
	onPremiereTypeChange: (v: string) => void;
}

export default function PremiereTypeSelect({selectedPremiereType, onPremiereTypeChange}: PremiereTypeSelectProps) {
	return (
		<Select value={selectedPremiereType} onValueChange={onPremiereTypeChange}>
			<SelectTrigger className="w-[180px]">
				<SelectValue placeholder="Selecciona un tipo de estreno" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Tipos de Estreno</SelectLabel>
					{PREMIERE_TYPES.map(premiereType => (
						<SelectItem value={premiereType.value.toString()}>{premiereType.label}</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}
