import ProcessContacts from "../subcomponents/ProcessContacts";
import ContactFilter from "./subcomponents/ContactFilter";

export default function OdooManagment() {
	return (
		<div className="flex h-screen flex-col px-6">
			<div className="flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Odoo Gestión de Contactos</h2>
				<ProcessContacts />
			</div>
			<ContactFilter />
		</div>
	);
}
