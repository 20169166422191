import {useEffect, useMemo, useRef, useState} from "react";
import {Feedback, useGetFeedbackListQuery} from "../../_store/features/feedback/api";
import {createColumnHelper, PaginationState, Updater} from "@tanstack/react-table";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import {DataTable} from "../../components/blocks/DataTable";
import {Button} from "../../components/primitives/Button";
import {Refresh, ArrowUp, Star} from "../../components/primitives/icons";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";
import {cn} from "../../../utils/classNames";

const columnHelper = createColumnHelper<Feedback>();

const StarReview = ({rating}: {rating: number}) => {
	const stars = Array.from({length: 5}, (_, i) => {
		return <Star className={cn("h-5 w-5 text-gray-300", {"text-orange-300": i < rating})} />;
	});

	return <div className="mt-1 flex gap-0.5">{stars}</div>;
};

interface Option {
	display: string;
	value: string;
}

const searchOptions: Option[] = [
	{display: "Por Comentario", value: "reason"},
	{display: "Por Correo", value: "email"},
];

const orderOptions: Option[] = [
	{display: "Fecha de Creación", value: "created_at"},
	{display: "Feedback", value: "feedback"},
];

export default function FeedbackList() {
	const [searchValue, setSearchValue] = useState("");
	const [searchBy, setSearchBy] = useState(searchOptions[0]);
	const [sortBy, setSortBy] = useState(orderOptions[0]);
	const [ascOrder, setAscOrder] = useState(false);

	const [params, setParams] = useState({
		searchby: "",
		searchvalue: searchValue,
		sortBy: sortBy.value,
		sortOrder: ascOrder ? "asc" : "desc",
		pageIndex: 0,
		pageSize: 10,
	});

	const [tableWidth, setTableWidth] = useState(0);
	const ref = useRef<HTMLDivElement | null>(null);

	const {data, isLoading} = useGetFeedbackListQuery(params);

	useEffect(() => {
		const handleResize = (entries: any) => {
			for (let entry of entries) {
				setTableWidth(entry.contentRect.width);
			}
		};

		const resizeObserver = new ResizeObserver(handleResize);
		if (ref.current) {
			resizeObserver.observe(ref.current);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const handleOnPaginationChange = (updaterOrValue: Updater<PaginationState>) => {
		setParams(prevParams => ({
			...prevParams,
			...(typeof updaterOrValue === "function" ? updaterOrValue(prevParams as PaginationState) : updaterOrValue),
		}));
	};

	const handleOnSearchChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
		if (value === "") {
			setParams(prevParams => ({...prevParams, searchvalue: ""}));
		}
		setSearchValue(value);
	};

	const handleOnSearchByChange = (value: string) => {
		setSearchBy(searchOptions.find(option => option.value === value) ?? searchOptions[0]);
	};

	const handleOnOrderByChange = (value: string) => {
		setSortBy(orderOptions.find(option => option.value === value) ?? orderOptions[0]);
		setParams(prevParams => ({...prevParams, sortBy: value, pageIndex: 0}));
	};

	const toggleOrder = () => {
		setAscOrder(prevOrder => !prevOrder);
		setParams(prevParams => ({...prevParams, sortOrder: ascOrder ? "desc" : "asc", pageIndex: 0}));
	};

	const handleOnSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setParams(prevParams => ({...prevParams, searchby: searchBy.value, searchvalue: searchValue, pageIndex: 0}));
	};

	const handleReset = () => {
		setSearchValue("");
		setAscOrder(false);
		setSearchBy(searchOptions[0]);
		setSortBy(orderOptions[0]);
		setParams({
			searchby: "",
			searchvalue: "",
			sortBy: "created_at",
			sortOrder: "desc",
			pageIndex: 0,
			pageSize: params.pageSize,
		});
	};

	const columns = useMemo(() => {
		const REVIEW = 118;
		const width = tableWidth - REVIEW - 20;
		return [
			columnHelper.accessor("name", {
				id: "Usuario",
				header: ({column}) => <DataTableColumnHeader title="Usuario" column={column} />,
				cell: info => {
					return (
						<div>
							<div>{info.row.original.name}</div>
							<div>{info.row.original.email}</div>
						</div>
					);
				},
				size: width * 0.25,
				enableSorting: false,
				enableResizing: false,
			}),
			columnHelper.accessor("feedback", {
				id: "Feedback",
				header: ({column}) => <DataTableColumnHeader title="Feedback" column={column} />,
				cell: info => {
					return <StarReview rating={info.row.original.feedback / 20.0} />;
				},
				size: REVIEW,
				enableSorting: false,
				enableResizing: false,
			}),
			columnHelper.accessor("reason", {
				id: "Comentario",
				header: ({column}) => <DataTableColumnHeader title="Comentario" column={column} className="w-full" />,
				cell: info => {
					return <div className="w-full pl-4 text-left">{info.row.original.reason || "N/A"}</div>;
				},
				size: width * 0.45,
				enableSorting: false,
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha",
				header: ({column}) => <DataTableColumnHeader title="Fecha" column={column} />,
				cell: info => {
					const formatedDate = new Date(info.row.original.created_at).toLocaleDateString("es-ES", {
						year: "numeric",
						month: "long",
						day: "numeric",
					});
					return <div className="w-full text-left">{formatedDate}</div>;
				},
				size: width * 0.15,
				enableSorting: false,
				enableResizing: false,
			}),
			columnHelper.accessor("next_feedback_allowed_at", {
				id: "Fecha de próxima revisión",
				header: ({column}) => <DataTableColumnHeader title="Fecha de próxima revisión" column={column} />,
				cell: info => {
					const formatedDate = info.row.original.next_feedback_allowed_at
						? new Date(info.row.original.next_feedback_allowed_at).toLocaleDateString("es-ES", {
								year: "numeric",
								month: "long",
								day: "numeric",
						  })
						: "N/A";
					return <div className="w-full text-left">{formatedDate}</div>;
				},
				size: width * 0.15,
				enableSorting: false,
				enableResizing: false,
			}),
		];
	}, [tableWidth]);

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Reviews</h2>
			</div>
			<div className="flex gap-8 px-6 pt-4 xl:gap-24">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={handleReset}>
						<Refresh className="h-4 w-4" />
					</Button>
					<form className="flex w-full" onSubmit={handleOnSearch}>
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={handleOnSearchChange}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleOnSearchByChange} value={searchBy.value}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.value}`}>
											{sOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} type="submit">
							Buscar
						</Button>
					</form>

					<Select onValueChange={handleOnOrderByChange} value={sortBy.value}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Ordenar por:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{orderOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.value}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>

					<Button className="h-8" size={"sm"} variant={"outline"} onClick={toggleOrder}>
						{ascOrder ? <ArrowUp className="h-4 w-4" /> : <ArrowUp className="h-4 w-4 rotate-180 transform" />}
					</Button>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref}
					columns={columns}
					dataset={data?.results ?? []}
					pageCount={Math.ceil((data?.totalResults ?? 0) / (data?.pageSize ?? 1))}
					pagination={{pageIndex: params.pageIndex, pageSize: params.pageSize}}
					loading={isLoading}
					onPaginationChange={handleOnPaginationChange}
					rowHeight={55}
					withDynamicPageSize
					showPagination={false}
				/>
			</div>
		</div>
	);
}
