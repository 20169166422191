import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {selectGlobalStyles} from "../../../../../utils/selectGlobalStyles";
import {DatePickerWithRange} from "../../../../components/blocks/DateRangePicker";
import {Card} from "../../../../components/blocks/Stats/Card";
import Select, {MultiValue} from "react-select";
import {endOfToday, formatISO9075, roundToNearestMinutes, subMonths} from "date-fns";
import {DateRange} from "react-day-picker";
import {useAppDispatch} from "../../../../_store/hooks";
import CostEarnGraph from "./_subcomponents/CostEarnGraph";
import {getPartnerConfig} from "../../../../_store/features/user-db/user-db-actions";
import {GetPartnerConfigResponse, SeriePartnershipType} from "../../../../data-access/role/configuration/partner";
import {SelectOptionType} from "../../../../../utils/globalTypes";

function PartnerDetail() {
	const {id, name} = useParams<{id: string; name: string}>();
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [partnerData, setPartnerData] = useState<SeriePartnershipType | undefined>();
	const [listContents, setListContents] = useState<SelectOptionType[]>();
	const [seriesidList, setSeriesIdList] = useState<string>("");
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subMonths(to, 1);
		return {
			from,
			to,
		};
	});

	useEffect(() => {
		if (!id) return;
		setIsLoading(true);
		dispatch(
			getPartnerConfig({
				searchby: "partner_id",
				searchvalue: id,
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				active: true,
				series_id_list: seriesidList || partnerData?.series?.map(serie => serie?.series_id).join(","),
			}),
		)
			.then(res => {
				if (res.meta.requestStatus === "fulfilled" && res.payload) {
					const {results} = res.payload as GetPartnerConfigResponse;
					const partner = results.find(item => item.id === id);
					if (!partner) return;
					setPartnerData(partner);
					if (partner.series && !listContents) {
						setListContents(
							partner?.series?.map(serie => ({
								label: serie?.series_name || "",
								value: serie?.series_id || "",
							})),
						);
					}
				}
			})
			.finally(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, id, seriesidList, dates]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	const handleContentChange = (value: MultiValue<SelectOptionType>) => {
		if (value.length === 0) {
			setSeriesIdList("");
			return;
		}
		setSeriesIdList(value.map(series => series.value).join(","));
	};

	const totalProductionCost = useMemo(() => {
		const activeSeries = partnerData?.series?.filter(i => i?.active);
		return (activeSeries?.reduce((acc, item) => acc + (item?.production_cost ?? 0), 0) ?? 0) * 0.01;
	}, [partnerData]);

	const totalPartnerProfit = useMemo(() => {
		const activeSeries = partnerData?.series?.filter(i => i?.active);
		const totalProductionCost = activeSeries?.reduce((acc, curr) => acc + (curr?.production_cost ?? 0), 0) ?? 0;
		const total_profit = activeSeries?.reduce((acc, curr) => acc + (curr?.series_profit ?? 0) + (curr?.rental_series_profit ?? 0), 0) ?? 0;
		const isProfit = totalProductionCost < total_profit;
		if (!isProfit) return 0;
		if (activeSeries?.length! < 2) {
			const personalProfit =
				((total_profit - totalProductionCost) * (activeSeries?.reduce((acc, curr) => acc + (curr?.profit_percentage ?? 0), 0) ?? 0)) / 100;
			return parseFloat((personalProfit * 0.01).toFixed(2));
		}

		const profitBySerie: {personalProfit?: number; percentage?: number; production_cost?: number}[] =
			activeSeries?.map(item => ({
				personalProfit: (item?.series_profit ?? 0) + (item?.rental_series_profit ?? 0),
				percentage: item?.profit_percentage,
				production_cost: item?.production_cost,
			})) ?? [];
		const totalPartnerProfit = profitBySerie?.reduce(
			(acc, item) => acc + ((item.personalProfit ?? 0) - (item?.production_cost ?? 0)) * ((item?.percentage ?? 0) * 0.01),
			0,
		);
		return totalPartnerProfit * 0.01;
	}, [partnerData]);

	const totalGrossProfit = useMemo(() => {
		const activeSeries = partnerData?.series?.filter(serie => serie?.active);
		const totalProfit = activeSeries?.reduce((acc, serie) => acc + (serie?.series_profit ?? 0) + (serie?.rental_series_profit ?? 0), 0) ?? 0;

		return totalProfit * 0.01;
	}, [partnerData]);

	const platformProfit = useMemo(() => {
		const activeSeries = partnerData?.series?.filter(i => i?.active);
		const total_profit = activeSeries?.reduce((acc, curr) => acc + (curr?.series_profit ?? 0) + (curr?.rental_series_profit ?? 0), 0) ?? 0;
		const totalProductionCost = activeSeries?.reduce((acc, curr) => acc + (curr?.production_cost ?? 0), 0) ?? 0;
		const isProfit = totalProductionCost < total_profit;
		if (!isProfit) return 0;
		if (activeSeries?.length! < 2) {
			const personalProfit =
				((total_profit - totalProductionCost) * (activeSeries?.reduce((acc, curr) => acc + (curr?.profit_percentage ?? 0), 0) ?? 0)) / 100;
			const platformProfit = total_profit - totalProductionCost - personalProfit;
			return parseFloat((platformProfit * 0.01).toFixed(2));
		}

		const profitBySerie: {personalProfit?: number; percentage?: number; production_cost?: number}[] =
			activeSeries?.map(item => ({
				personalProfit: (item?.series_profit ?? 0) + (item?.rental_series_profit ?? 0),
				percentage: item?.profit_percentage,
				production_cost: item?.production_cost,
			})) ?? [];
		const totalPartnerProfit = profitBySerie?.reduce(
			(acc, item) => acc + ((item.personalProfit ?? 0) - (item?.production_cost ?? 0)) * ((item?.percentage ?? 0) * 0.01),
			0,
		);
		const totalPlatformProfit = total_profit - totalProductionCost - totalPartnerProfit;

		return totalPlatformProfit * 0.01;
	}, [partnerData]);

	return (
		<div className="flex h-screen flex-col">
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Partner - {name}</h2>
				{partnerData && (
					<Select
						className="basic-multi-select mt-1 rounded"
						options={listContents}
						defaultValue={listContents}
						isMulti={true}
						styles={selectGlobalStyles}
						placeholder="Mostrando datos de todas las series"
						onChange={handleContentChange}
						isLoading={isLoading}
					/>
				)}
			</div>
			<div className="flex flex-col gap-6 px-8 py-6">
				<div className="flex w-80 flex-row">
					<DatePickerWithRange
						onDateRangeChange={handleDateRangeChange}
						disabled={{before: new Date('2023-10-01T00:00:00-05:00'), after: new Date()}}
						date={dates}
					/>
				</div>
				<div className="col-span-7 grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-4">
					<Card title="Costo de Producción" amount={totalProductionCost} loading={isLoading} />
					<Card title="Total de ingresos Bruto" amount={totalGrossProfit || 0} loading={isLoading} />
					<Card title="Total de ganancia Partner" amount={totalPartnerProfit} loading={isLoading} />
					<Card title="Total de ganancias Plataforma" amount={platformProfit} loading={isLoading} />
				</div>
				<CostEarnGraph
					partnerPercentage={(totalPartnerProfit / (totalPartnerProfit + platformProfit)) * 100}
					platformPercentage={(platformProfit / (totalPartnerProfit + platformProfit)) * 100}
					partnerProfit={totalPartnerProfit}
					platformProfit={platformProfit}
					totalGrossProfit={totalGrossProfit}
					totalProductionCost={totalProductionCost}
					loading={isLoading}
				/>
			</div>
		</div>
	);
}

export default PartnerDetail;
