import {Button} from "../../primitives/Button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../../primitives/Command/command";
import {Popover, PopoverContent, PopoverTrigger} from "../../primitives/Popover/popover";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";

import {addMilliseconds, isAfter} from "date-fns";
import {crewActions} from "../../../_store/features/crew/crew-slice";
import {PlusSmall, Spinner} from "../../primitives/icons";
import {Cast} from "../../../data-access/series/cast";
import {useState} from "react";
import CastCard from "./CastCard";

const DEBOUNCE_TIME = 1000;

interface ProfessionSelectProps {
	onCastSelect: (profession: Cast) => void;
	selectedCast?: Cast[];
	disabled?: boolean;
}

export function CastSelect({onCastSelect, selectedCast, disabled}: ProfessionSelectProps) {
	const isCastLoading = useAppSelector(state => state.crew.castsLoading);
	const castLastFetch = useAppSelector(state => state.crew.castLastFetch);
	const cast = useAppSelector(state => state.crew.castResults);
	const [selectedCastData, setSelectedCastData] = useState<Cast>();
	const dispatch = useAppDispatch();

	const handleOpen = () => {
		if (!isCastLoading && isAfter(Date.now(), addMilliseconds(castLastFetch ?? 0, DEBOUNCE_TIME))) {
			dispatch(crewActions.getCastsList({params: {page: 0, page_size: 99999999}}));
		}
	};

	const handleCastSelect = (data: Cast) => {
		if (!selectedCastData) return;

		onCastSelect?.(data);
		setSelectedCastData(undefined);
	};
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button variant="outline" onClick={handleOpen}>
					<PlusSmall className="mr-2 h-5 w-5" />
					Añadir actor
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-fit overflow-hidden bg-transparent p-0" align="start">
				<div className="flex flex-col md:flex-row">
					<Command className="h-[345px] md:w-[200px]">
						<CommandInput placeholder={"Filtrar..."} />
						<CommandList>
							{isCastLoading && (
								<CommandItem className="flex items-center justify-center">
									<Spinner />
								</CommandItem>
							)}
							<CommandEmpty>No hay actores.</CommandEmpty>
							<CommandGroup>
								{cast
									.filter(p => !selectedCast?.some(sp => sp.id === p.id))
									.map(cast => {
										return (
											<CommandItem key={cast.id} onSelect={() => setSelectedCastData(cast)} disabled={disabled}>
												<div className="flex w-full flex-col">
													<div>{cast.name}</div>
													<div className="truncate text-xs text-muted-foreground">{cast.id}</div>
												</div>
											</CommandItem>
										);
									})}
							</CommandGroup>
						</CommandList>
					</Command>
					{selectedCastData && <CastCard key={selectedCastData.id} selectedCastData={selectedCastData} onAddCast={handleCastSelect} />}
				</div>
			</PopoverContent>
		</Popover>
	);
}
