import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface FetchFormDataParams {
	idToken: string;
	page?: number;
	page_size?: number;
	searchby?: string;
	searchvalue?: string;
	sponsor?: boolean;
	status?: number;
}

export const fetchFormData = async (params: FetchFormDataParams) => {
	const {idToken, page, page_size, searchby, searchvalue, sponsor, status} = params;
	return await axiosHandler.get("/admin_list_content_form_data", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${idToken}`,
		},
		params: {
			page,
			searchby,
			searchvalue,
			sponsor,
			status,
			page_size,
		},
	});
};

export const EditFormBodySchema = z.object(
	{
		id: z.string(),
		message: z.string().nonempty("El texto del mensaje no puede estar vacio."),
		name: z.string().optional(),
		email: z.string().nonempty("El email no puede estar vacio."),
		phone: z.string().optional(),
		sponsor: z.boolean(),
		customer_service_note: z.string().optional(),
		status: z.number().optional(),
		desired_response: z.string().optional(),
	},
	{required_error: "Este campo es requerido."},
);
export type EditFormBodyType = z.infer<typeof EditFormBodySchema>;

export interface EditFormDataResponse {
	id: string;
	name: null | string;
	email: string;
	phone: string;
	status: number;
	message: string;
	sponsor: boolean;
	audit_created: string;
	audit_updated: null | string;
	customer_service_note: null | string;
	desired_response: null | string;
}

export async function editFormData(token: string, data: EditFormBodyType): Promise<AxiosResponse<EditFormDataResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/update_content_form_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface Subjects {
	id: string;
	author: string;
	content: string;
	author_id: string;
	email: string;
	phone: string;
	status: "open" | "customer_reply" | "pending" | "closed";
	type: number;
	avatar: string;
	subject_id: string;
	review: "negative" | "positive";
	review_message: string;
	created_at: string;
	updated_at: string;
	admin_response?: string;
}

export interface GetAllChatSubjectsResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Subjects[];
}

export interface GetAllChatSubjectsParams {
	page_size?: number;
	page?: number;
	searchby?: "id" | "author_id" | "content" | "subject_id";
	searchvalue?: string;
	type?: number;
	status?: "open" | "customer_reply" | "pending" | "closed";
}

export async function getAllChatSubjects(token: string, params: GetAllChatSubjectsParams): Promise<AxiosResponse<GetAllChatSubjectsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_chat_list", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetSubjectChatsResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Subjects[];
}

export interface GetSubjectChatsParams {
	author_id: string;
	page_size?: number;
	page?: number;
	searchby?: "id" | "content" | "subject_id";
	searchvalue?: string;
	type?: number;
	status?: "open" | "customer_reply" | "pending" | "closed";
}

export async function getUserSubjectChats(token: string, params: GetSubjectChatsParams): Promise<AxiosResponse<GetSubjectChatsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_user_chat_list", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface CreateSubjectChatBodyRequest {
	content: string;
	subject_id: string;
}

export async function createSubjectChat(token: string, data: CreateSubjectChatBodyRequest): Promise<AxiosResponse<Subjects>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/admin_create_chat_response", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface GetChatsBySubjectIdParams {
	subject_id: string;
}

export interface GetChatsBySubjectIdResponse {
	results: Subjects[];
}

export async function getChatsBySubjectId(token: string, params: GetChatsBySubjectIdParams): Promise<AxiosResponse<GetChatsBySubjectIdResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_subject_id_comments", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface UpdateChatStatusBodyRequest {
	subject_id: string;
	status: string;
}

export interface UpdateChatStatusResponse {
	message: string;
}

export async function updateChatStatus(token: string, body: UpdateChatStatusBodyRequest): Promise<AxiosResponse<UpdateChatStatusResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/admin_update_chat_status", body, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
