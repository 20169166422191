import {zodResolver} from "@hookform/resolvers/zod";
import {useEffect, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-hot-toast";
import {advertisementsActions} from "../../../_store/features/advertisements/advertisements-slice";
import {useAppDispatch} from "../../../_store/hooks";
import {Button} from "../../../components/primitives/Button";
import {Input} from "../../../components/primitives/Input";
import {Image as ImageIcon} from "../../../components/primitives/icons";
import {Spinner} from "../../../components/primitives/icons/Spinner";
import {InsertAdvertisementBodySchema, InsertAdvertisementBodyType, uploadPublicityImage} from "../../../data-access/advertisers";
import {auth} from "../../../firebase";
import {useNavigate} from "react-router-dom";
import {PUBLICITY_TYPES} from "../../../constants";
import PublicityTypeSelect from "./subcomponents/PublicityTypeSelect";
import SponsorSelect from "./subcomponents/SponsorSelect";
import MultimediaImagePickerModal from "../../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../../_store/features/gallery/gallery-slice";
import {FOLDERS_NAMES_ADS} from "../../../../utils/folderNames";

type ImageFieldsTypes = keyof Pick<InsertAdvertisementBodyType, "img_web" | "img_mobile">;

const initialState: InsertAdvertisementBodyType = {
	text: "",
	sponsor: "",
	published: false,
	order: 0,
	img_mobile: "",
	img_web: "",
	type_publicity: PUBLICITY_TYPES[0].value,
};

function AdvertisersNew() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [fieldLoading, setFieldLoading] = useState<ImageFieldsTypes[]>([]);
	const [selectType, setSelectType] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [type, setType] = useState<ImageFieldsTypes>("img_web");

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		control,
		getValues,
		formState: {errors, isLoading, isSubmitting, isSubmitSuccessful},
	} = useForm<InsertAdvertisementBodyType>({
		resolver: zodResolver(InsertAdvertisementBodySchema),
		defaultValues: initialState,
	});

	useEffect(() => {
		reset(initialState);
	}, [isSubmitSuccessful, reset]);

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);

		body.append("file", file);
		body.append("type", type);
		body.append("publicityid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadPublicityImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleInsertAdvertisement: SubmitHandler<InsertAdvertisementBodyType> = data => {
		return dispatch(advertisementsActions.insertAdvertisement(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Anuncio Creado Correctamente");
				setTimeout(() => {
					if (selectType === 0) navigate("/advertisements/list");
					else navigate("/advertisements/sponsor-list");
				}, 2000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Nuevo Anuncio</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite crear anuncios que se mostraran en toda la plataforma.</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(handleInsertAdvertisement)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-gray-100">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Texto del Anuncio<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar texto del anuncio..." {...register("text")} />
									{errors?.text?.message && <span className="text-sm font-medium text-destructive">{errors?.text?.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Nombre del Anunciante<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar nombre del anunciante..." {...register("sponsor")} />
									{errors?.sponsor?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.sponsor.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Tipo de Anuncio</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Controller
										name="type_publicity"
										control={control}
										render={({field}) => (
											<PublicityTypeSelect
												selectedPublicityType={field.value.toString()}
												onPublicityTypeChange={v => {
													setSelectType(parseInt(v));
													field.onChange(parseInt(v));
												}}
											/>
										)}
									/>
									{errors?.type_publicity?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.type_publicity.message}</span>
									)}
								</dd>
							</div>
							{selectType === 0 ? (
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Orden</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input
											type="number"
											placeholder="Orden del anuncio..."
											min={0}
											defaultValue={0}
											{...register("order", {min: 0, valueAsNumber: true})}
										/>
										{errors?.order?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.order.message}</span>
										)}
									</dd>
								</div>
							) : (
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Sponsor</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Controller
											name="sponsor_id"
											control={control}
											render={({field}) => (
												<SponsorSelect
													selectedSponsor={field?.value}
													onSponsorChange={v => {
														//setSelectType(parseInt(v));
														field.onChange(v);
													}}
												/>
											)}
										/>
										{errors?.sponsor_id?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.sponsor_id.message}</span>
										)}
									</dd>
								</div>
							)}
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Web</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("img_web") ? (
												fieldLoading.includes("img_web") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("img_web")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "img_web");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("img_web");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.img_web?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.img_web.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Celulares</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("img_mobile") ? (
												fieldLoading.includes("img_mobile") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("img_mobile")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												name="img_mobile"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "img_mobile");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("img_mobile");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.img_mobile?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.img_mobile?.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								navigate("/advertisements/list");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Crear Anuncio
						</Button>
					</div>
				</form>
			</div>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={4}
				folderNames={FOLDERS_NAMES_ADS}
				onSuccess={url => {
					setValue(type, url);
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
			/>
		</section>
	);
}

export default AdvertisersNew;
