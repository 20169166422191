import {Modal} from "../../../../../../components/primitives/Modal";
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {cn} from "../../../../../../../utils/classNames";
import {useState} from "react";
import {FilePlus, Trash} from "../../../../../../components/primitives/icons";
import {Button} from "../../../../../../components/primitives/Button";
import {exclusiveRoomActions} from "../../../../../../_store/features/exclusive-room/exclusive-room-slice";
import {toast} from "react-hot-toast";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {useParams} from "react-router-dom";
import {PostType} from "../../../../../../data-access/exclusive-room/types";

interface CreateSurveysModalProps {
	onDismiss: () => void;
	open: boolean;
	title?: string;
}

const CreateSurveysModal = ({title, onDismiss, open}: CreateSurveysModalProps) => {
	return (
		<Modal open={open} onDismiss={onDismiss} title={title || "Crear Encuesta"} big>
			<CreateSurveysForm onDismiss={onDismiss} />
		</Modal>
	);
};

export default CreateSurveysModal;

interface CreateSurveysFormProps {
	onDismiss: () => void;
}

const QuestionSchema = z.object({
	id: z.number(),
	text: z.string(),
	votes: z.number(),
});

const SurveySchema = z.object({
	title: z.string().nonempty("El título es requerido"),
	questions: z.array(QuestionSchema).min(2, "Debes agregar al menos 2 respuestas"),
	description: z.string(),
});

type SurveySchemaType = z.infer<typeof SurveySchema>;

const CreateSurveysForm = ({onDismiss}: CreateSurveysFormProps) => {
	const {
		control,
		register,
		handleSubmit,
		formState: {errors},
	} = useForm<SurveySchemaType>({resolver: zodResolver(SurveySchema)});
	const {fields, append, remove} = useFieldArray({control, name: "questions"});
	const [newQuestion, setNewQuestion] = useState("");
	const {id: seriesid} = useParams();
	const dispatch = useAppDispatch();

	const onSubmit: SubmitHandler<SurveySchemaType> = data => {
		dispatch(
			exclusiveRoomActions.createExclusiveRoomPost({
				published: true,
				seriesid: seriesid!,
				content: {
					type: 100,
					payload: {
						subtype: PostType.POLL,
						content: {
							title: data.title,
							text: data.description,
							questions: data.questions.map(q => ({id: q.id, text: q.text, votes: q.votes})),
						},
						reactions: [],
					},
				},
			}),
		).then(thunk => {
			if (thunk.meta.requestStatus === "rejected") {
				toast.error("Error al crear la publicación");
				return;
			}
			toast.success("Publicación creada con éxito");
			dispatch(exclusiveRoomActions.getExclusiveRoomListBySerie({id: seriesid!}));
			onDismiss();
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="container flex w-[800px] scroll-py-2 flex-col space-y-4 px-1 pb-0.5">
			<div className="flex flex-col">
				<input
					className={cn(
						"form-control mb-2 flex-1 rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
						{"border-red-500": !!errors.title},
					)}
					type="text"
					placeholder="Título/Pregunta de la encuesta"
					{...register("title")}
				/>
			</div>

			<div className="flex flex-col">
				<input
					className={cn(
						"form-control mb-2 flex-1  rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
						{"border-red-500": !!errors.description},
					)}
					type="text"
					placeholder="Descripción de la encuesta"
					{...register("description")}
				/>
			</div>

			<div className="flex w-full items-center">
				<div className="h-[1px] flex-1 bg-gray-500" />
				<span className="px-4 text-sm font-semibold text-white">Respuestas</span>
				<div className="h-[1px] flex-1 bg-gray-500" />
			</div>

			{errors.questions && <div className="text-sm text-red-500">{errors.questions.message}</div>}

			{fields.map((field, index) => (
				<div className="flex flex-row items-center gap-2">
					<input
						key={field.id}
						{...register(`questions.${index}.text`)}
						className={cn(
							"form-control mb-2 flex-1  rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
							{"border-red-500": !!errors.questions?.[index]},
						)}
					/>
					<button type="button" onClick={() => remove(index)} className="p-1 text-white">
						<Trash className="h-4 w-4" />
					</button>
				</div>
			))}

			<div className="flex items-center gap-2">
				<input
					type="text"
					value={newQuestion}
					onChange={e => setNewQuestion(e.target.value)}
					className={cn(
						"form-control flex-1  rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
					)}
				/>
				<button
					type="button"
					onClick={() => {
						if (!newQuestion.length) return;
						append({id: fields.length + 1, text: newQuestion, votes: 0});
						setNewQuestion("");
					}}
					className="flex items-center justify-center space-x-2 rounded-sm border-2 bg-white px-4 py-1 text-center text-sm tracking-wider text-black transition-all duration-300 hover:bg-white hover:text-black"
				>
					Agregar respuesta
				</button>
			</div>

			<Button
				variant="outline"
				type="submit"
				className="flex w-full items-center justify-center space-x-2 rounded-sm border-2 bg-white px-4 py-3 text-center tracking-wider text-black transition-all duration-300 hover:bg-white hover:text-black"
			>
				<span className="text-center">Crear publicación</span>
				<FilePlus className="h-6 w-6" />
			</Button>
		</form>
	);
};
