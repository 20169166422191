import {useEffect, useMemo, useState} from "react";
import {Line} from "react-chartjs-2";
import {DateRange} from "react-day-picker";
import {es} from "date-fns/locale";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from "chart.js";
import {addDays, differenceInDays, differenceInMonths, endOfDay, format, formatISO9075, isSameDay, isSameMonth} from "date-fns";
import {oneMonth} from "../../../../../../utils/barGraphTransformData";
import {useDashboardByNameQuery} from "../../../../../_store/features/dashboard/hooks";
import {useAppDispatch} from "../../../../../_store/hooks";
import {dashboardActions} from "../../../../../_store/features/dashboard/dashboard-slice";
import {Spinner} from "../../../../../components/primitives/icons";

interface ReproductionTimeDataChartProps {
	dates?: DateRange | undefined;
}

interface dataInterface {
	date: string;
	streamingHours: number;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function ReproductionTimeDataChart({dates}: ReproductionTimeDataChartProps) {
	const dispatch = useAppDispatch();
	const {isLoading, data: statistics} = useDashboardByNameQuery("getReproductionTime");
	const [showByMonths, setShowByMonths] = useState(false);
	const startDate = new Date(dates?.from ?? 0);
	const endDate = new Date(dates?.to ?? Date.now());
	const diff_days = differenceInDays(endDate, startDate) + 1;
	const diff_months = differenceInMonths(endDate, startDate) + 1;

	useEffect(() => {
		dispatch(
			dashboardActions.getReproductionTime({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
			}),
		);
		const duration = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(duration > oneMonth);
	}, [dates, dispatch]);

	function chartData(dataArray: dataInterface[], groupedArray: dataInterface[], showMonthly?: boolean) {
		return dataArray
			.map(fillEl => {
				const el = groupedArray.find(statEl => {
					return showMonthly
						? isSameMonth(new Date(fillEl.date), new Date(statEl.date))
						: isSameDay(new Date(fillEl.date), new Date(statEl.date));
				});

				if (el) return {...el, date: fillEl.date};

				return fillEl;
			})
			.reverse();
	}

	const data = useMemo(
		() => {
			const monthArray = Array(diff_months)
				.fill(0)
				.map((_, idx) => ({date: format(addDays(endOfDay(endDate), -idx * 31), "yyyy-MM-dd", {locale: es}), streamingHours: 0}));

			const daysArray = Array(diff_days)
				.fill(0)
				.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), streamingHours: 0}));

			const streamingHours = statistics?.reproduction_time?.map(item => ({date: item.date, streamingHours: item.hours})) ?? [];

			// MonthlyData
			const monthlyStreamingHours = chartData(monthArray, streamingHours, true);

			// DailyData
			const dailyStreamingHours = chartData(daysArray, streamingHours);

			// Data to show
			const streamingHoursData = showByMonths ? monthlyStreamingHours : dailyStreamingHours;

			return {
				datasets: [
					{
						label: "Horas de streaming",
						data: streamingHoursData,
						fill: true,
						borderColor: "rgba(242, 116, 116, 1)",
						backgroundColor: "rgba(242, 116, 116, 0.5)",
						parsing: {
							xAxisKey: "date",
							yAxisKey: "streamingHours",
						},
					},
				],
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[statistics?.reproduction_time, dates, showByMonths],
	);

	const totalStreamingHours = useMemo(() => {
		const hoursData: number = data.datasets[0].data.reduce((acc, curr) => acc + curr.streamingHours, 0);
		if (hoursData < 1000) return hoursData;
		if (hoursData < 1000000) return (hoursData / 1000).toFixed(1) + "K";
		return (hoursData / 1000000).toFixed(1) + "M";
	}, [data]);

	return (
		<div className="flex w-full py-6">
			<div className="flex w-full flex-col rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Total de horas de streaming</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="flex flex-col px-6 pb-6">
						<div className="flex flex-col items-start">
							<p className="mb-1 text-left text-xl">Horas de Streaming</p>
							<p className="text-left text-2xl font-semibold">{totalStreamingHours}</p>
						</div>
						<div className="flex w-full pt-0">
							<Line
								height={350}
								id="streaming-hours"
								options={{
									responsive: true,
									maintainAspectRatio: false,
									plugins: {
										tooltip: {
											callbacks: {
												title(tooltipItems) {
													const date = new Date(tooltipItems[0].label);
													if (showByMonths) {
														return format(date, "MMM - yyyy", {locale: es}).toLowerCase();
													}
													return format(date, "EE, dd MMMM", {locale: es}).toLowerCase();
												},
											},
										},
									},
									scales: {
										x: {
											ticks: {
												callback(tickstreamingHours) {
													const date = new Date(this.getLabelForValue(tickstreamingHours as any));

													const formattedDate = showByMonths
														? format(date, "MMM - yyyy", {locale: es}).toLowerCase()
														: format(date, "MMM d", {locale: es}).toLowerCase();

													return formattedDate;
												},
											},
											grid: {
												color: "#8d8d8da1",
											},
										},
										y: {
											beginAtZero: true,
											stacked: true,
											position: "right",
											ticks: {
												callback(tickValue) {
													const hoursValue = tickValue as number;
													if (hoursValue < 1000) return hoursValue;
													if (hoursValue < 1000000) return hoursValue / 1000 + "K";
													return hoursValue / 1000000 + "M";
												},
											},
										},
									},
								}}
								data={data}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
