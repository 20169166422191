import {Link} from "react-router-dom";
import {useDashboardPartnerByNameQuery} from "../../../../../../_store/features/dashboard-partner/hooks";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../../../../components/primitives/ToolTip";
import {Card} from "../../../../../../components/blocks/Stats/Card";
import {Warning} from "../../../../../../components/primitives/icons";
import {useAppSelector} from "../../../../../../_store/hooks";
import {useState} from "react";

interface IncomeCardsProps {
	showRentalsPanel?: boolean;
}

export default function IncomeCards({showRentalsPanel = false}: IncomeCardsProps) {
	const statistic = useDashboardPartnerByNameQuery("getPartnerGeneralIncomeAndProfit");
	const userInfo = useAppSelector(state => state.user.userInfo);
	const [personalEarningsPanel, setPersonalEarningsPanel] = useState(false);
	const [contentEarningsPanel, setContentEarningsPanel] = useState(false);

	return (
		<div>
			<div className="grid grid-flow-row grid-cols-3 gap-3 lg:grid-cols-5">
				{showRentalsPanel ? (
					<Card
						title="Ingreso Personales Total"
						amount={statistic.data?.total_partner_earnings ?? 0}
						tooltip="Ganancias personales del período seleccionado, este corresponde a un valor estimado basado en el consumo de los contenidos."
						loading={statistic.isLoading}
						warningChildren={warningChild}
					/>
				) : null}
				<Card
					title="Ingreso Personales"
					amount={statistic.data?.total_partner_earnings_by_seconds_percent ?? 1}
					tooltip="Ganancias personales del período seleccionado, este corresponde a un valor estimado basado en el consumo de los contenidos."
					loading={statistic.isLoading}
					warningChildren={warningChild}
					expanse
					onExpanse={expanded => setPersonalEarningsPanel(expanded)}
				/>
				{showRentalsPanel ? (
					<Card
						title="Ingresos Rentas"
						amount={statistic.data?.total_partner_rentals_earnings ?? 0}
						tooltip="Ingresos de la plataforma por concepto de subscripción correspondiente al período seleccionado."
						loading={statistic.isLoading}
						warningChildren={warningChild}
					/>
				) : null}
				<Card
					title="Ingresos de los Contenidos"
					amount={
						((statistic.data?.total_partner_time ?? 1) / (statistic.data?.total_time_seconds ? statistic.data?.total_time_seconds : 1)) *
						(statistic.data?.total_earnings ?? 0)
					}
					tooltip="Ingreso generado por la(s) serie según el porciento de consumo de los contenidos."
					loading={statistic.isLoading}
					warningChildren={warningChild}
					expanse
					onExpanse={expanded => setContentEarningsPanel(expanded)}
				/>
				<Card
					title="Ingresos Totales"
					amount={statistic.data?.total_earnings ?? 0}
					tooltip="Ingresos de la plataforma por concepto de subscripción correspondiente al período seleccionado."
					loading={statistic.isLoading}
					warningChildren={warningChild}
				/>
			</div>
			<div className={`${personalEarningsPanel ? "max-h-[100vh]" : "h-0 opacity-0"} easy-in-out transition duration-500`}>
				<table className="mt-4 w-full max-w-6xl table-auto border-separate text-right">
					<thead className="bg-slate-100">
						<tr className="text-right">
							<th className="text-left">Nombre</th>
							<th>Ingreso del contenido</th>
							<th>Parte Acordada</th>
							<th>Ganancia personal</th>
						</tr>
					</thead>
					<tbody>
						{userInfo?.get_partner_series?.map((serie, index) => (
							<tr key={index} className="text-right">
								<td className="border-b border-slate-200 text-left">{serie.title}</td>
								<td className="border-b border-slate-200">
									$
									{(
										((statistic?.data?.series.find(s => s.seriesid === serie.serieid)?.partner_time_seconds_percent ?? 0) / 100) *
										(statistic.data?.total_earnings ?? 0)
									).toFixed(2) ?? "0"}
								</td>
								<td className="border-b border-slate-200">{serie.profit}%</td>
								<td className="border-b border-slate-200">
									$
									{(
										((statistic.data?.series.find(s => s.seriesid === serie.serieid)?.partner_time_seconds_percent ?? 0) *
											serie.profit *
											(statistic.data?.total_earnings ?? 0)) /
										100 /
										100
									)?.toFixed(2) ?? 0}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className={`${contentEarningsPanel ? "max-h-[100vh]" : "h-0 opacity-0"} easy-in-out transition duration-500`}>
				<table className="mt-4 w-full max-w-6xl table-auto border-separate text-right">
					<thead className="bg-slate-100">
						<tr className="text-right">
							<th className="text-left">Nombre</th>
							<th>Tiempo de Streaming</th>
							<th>Parte del Total</th>
							<th>Ganancia / Consumo</th>
						</tr>
					</thead>
					<tbody>
						{userInfo?.get_partner_series?.map((serie, index) => (
							<tr key={index}>
								<td className="border-b border-slate-200 text-left">{serie.title}</td>
								<td className="border-b border-slate-200">
									{(
										(statistic.data?.series.find(s => s.seriesid === serie.serieid)?.total_series_time_seconds ?? 0) / 36000
									).toFixed(2)}{" "}
									horas
								</td>
								<td className="border-b border-slate-200">
									{statistic.data?.series.find(s => s.seriesid === serie.serieid)?.partner_time_seconds_percent?.toFixed(1)}%
								</td>
								<td className="border-b border-slate-200">
									$
									{(
										((statistic.data?.series.find(s => s.seriesid === serie.serieid)?.total_series_time_seconds ?? 0) /
											(statistic.data?.total_time_seconds ? statistic.data?.total_time_seconds : 1)) *
										(statistic.data?.total_earnings ?? 0)
									)?.toFixed(2) ?? 0}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

const warningChild = (
	<Link to={"/partner/reports"}>
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<div>
						<Warning color="red" className="h-5 w-5" />
					</div>
				</TooltipTrigger>
				<TooltipContent>
					<p className="w-96">Estos valores son estimados, los datos reales pueden ser consultados desde la sección de reportes</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	</Link>
);
