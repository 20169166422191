import {useEffect, useRef, useState} from "react";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../primitives/ToolTip";
import {cn} from "../../../../../utils/classNames";
import {format, parseISO} from "date-fns";
import {Sticker, getObjectSticker} from "../../../../data-access/stickers";
import {auth} from "../../../../firebase";
import {COMMENTS_TYPES} from "../../../../constants/comments";

export default function StickerComment({comment, isSelected}: {comment: any; isSelected: boolean}) {
	const nestingLevel = useRef(comment.path?.split(".").length ?? 0).current;
	const [stickerData, setStickerData] = useState<Sticker | null>(null);

	const handleGetStickerData = (id: string) => {
		auth.currentUser
			?.getIdToken()
			.then(token => {
				getObjectSticker(token!, {id})
					.then(res => {
						setStickerData(res.data);
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		if (comment.content.type === COMMENTS_TYPES.STICKER) {
			handleGetStickerData(comment.content.payload);
		}
	}, [comment.content.payload, comment.content.type]);

	return (
		<div
			key={comment.id}
			className={cn({
				"md xs:pl-xl text-14 before:border-tone-4 xs:before:left-[16.5px] relative": nestingLevel > 0,
			})}
			style={{paddingLeft: nestingLevel * 24}}
		>
			<div
				className={cn(
					"relative m-1 rounded-md border border-border bg-accent p-4",
					[
						"before:absolute",
						"before:left-[-12px]",
						"before:top-[-6px]",
						"before:h-[50%]",
						"before:w-[12px]",
						"before:border-y-0",
						"before:border-l-[1px]",
						"before:border-r-[0px]",
						"before:border-b-[1px]",
						"before:rounded-bl-md",
						"before:border-solid",
						"before:border-l-2",
						"before:border-b-2",
						"before:content-['']",
					],
					{
						"border-foreground/50 before:border-foreground/50": isSelected,
					},
				)}
			>
				<div className="flex items-baseline gap-2">
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<div className="w-fit text-sm font-semibold">{comment.author}</div>
							</TooltipTrigger>
							<TooltipContent side="bottom">
								<p>
									<span className="font-semibold">Autor ID:</span> {comment.author_id}
								</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
					<div className={cn("text-xs text-muted-foreground", {hidden: nestingLevel === 0})}>
						{format(parseISO(comment.created_at! + "Z"), "dd/MM/yy hh:mm bb")}
					</div>
				</div>
				<div className="mt-2 h-24 w-24 text-sm">{stickerData?.url && <img src={stickerData?.url} alt={stickerData.description} />}</div>
				<div className="mt-2 text-xs text-muted-foreground">
					<span>ID Comentario:</span> {comment.id}
				</div>
			</div>
		</div>
	);
}
