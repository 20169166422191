export interface seriesOptions {
	display: string;
	value: string | undefined;
}

export default function removeDuplicatesSeriesObjects(arr: seriesOptions[]) {
	const baseObject: any = {};
	return arr.filter(item => {
		if (baseObject[String(item.value)]) return false;
		baseObject[String(item.value)] = true;
		return baseObject[String(item.value)];
	});
}
