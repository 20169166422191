import {Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {Data} from "../../../../_store/features/feedback/types";
import {useMemo} from "react";

const backgroundColor = ["rgba(220, 38, 38, 0.8)", "rgba(22, 163, 74, 0.8)"];

const hoverBackgroundColor = ["rgba(220, 38, 38, 1)", "rgba(22, 163, 74, 1)"];

interface DevicesProps {
	data: Data | undefined;
}

export function SatisfactionFeedback({data}: DevicesProps) {
	const prepareData = useMemo(() => {
		if (!data) return {positive: 0, negative: 0, percent_positive: 0, percent_negative: 0, total: 0};

		const item = data.currentRange.data.reduce(
			(acc, item) => {
				return {positive: acc.positive + item.positive, negative: acc.negative + item.negative};
			},
			{positive: 0, negative: 0},
		);

		const total = item.positive + item.negative;

		return {
			positive: item.positive,
			percent_positive: total > 0 ? Math.round((item.positive / (item.positive + item.negative)) * 100) : 0,
			negative: item.negative,
			percent_negative: total > 0 ? Math.round((item.negative / (item.positive + item.negative)) * 100) : 0,
			total,
		};
	}, [data]);

	return (
		<div className="shadow-xs flex-col items-center justify-center rounded-lg border border-gray-200 bg-white">
			<div className="border border-gray-200 p-2 font-bold">Feedback Positivo vs. Negativo</div>
			<div className="grid grid-cols-1 items-center gap-4 px-2 py-6 min-[1000px]:grid-cols-2">
				<div className="col-span-1 px-2">
					<SatisfactionChart {...prepareData} />
				</div>

				<div>
					<div className="flex items-center space-x-2 text-sm">
						<div className="h-2 w-2 rounded-full bg-green-600" />
						<span className="font-black">{prepareData.positive}</span>
						<span className="text-xs text-gray-500">({prepareData.percent_positive}%)</span>
						<span className="text-xs text-gray-500">Positivos</span>
					</div>

					<div className="flex items-center space-x-2 text-sm">
						<div className="h-2 w-2 rounded-full bg-red-600" />
						<span className="font-black">{prepareData.negative}</span>
						<span className="text-xs text-gray-500">({prepareData.percent_negative}%)</span>
						<span className="text-xs text-gray-500">Negativos</span>
					</div>
				</div>
			</div>
		</div>
	);
}

ChartJS.register(ArcElement, Tooltip, Legend);

interface SatisfactionChartProps {
	positive: number;
	negative: number;
	total: number;
}

const options: ChartOptions<"doughnut"> = {
	plugins: {
		tooltip: {
			enabled: true,
		},
		legend: {
			display: false,
		},
	},
	backgroundColor: "transparent",
	color: "transparent",
	cutout: "0%",
};

const SatisfactionChart = ({negative, positive}: SatisfactionChartProps) => {
	const data = useMemo(
		() => ({
			labels: ["Negativos", "Positivos"],
			datasets: [
				{
					data: [negative, positive],
					backgroundColor,
					hoverBackgroundColor,
					borderWidth: 1,
				},
			],
		}),
		[negative, positive],
	);

	// Chart options

	return (
		<div className="mx-auto h-full lg:w-[80px] xl:w-[100px] 2xl:w-[120px]">
			<div className="relative aspect-square ">
				<Doughnut data={data} options={options} />
			</div>
		</div>
	);
};

export default SatisfactionFeedback;
