import {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import type {Identifier, XYCoord} from "dnd-core";
import {cn} from "../../../../../../../utils/classNames";
import {DragIndicator, Trash} from "../../../../../../components/primitives/icons";
import {Button} from "../../../../../../components/primitives/Button";
import {auth} from "../../../../../../firebase";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {seriesActions} from "../../../../../../_store/features/series/series-slice";
import {useParams} from "react-router-dom";
import {deleteCast} from "../../../../../../data-access/series/cast";
import {toast} from "react-hot-toast";

interface CastProps {
	id: string;
	name: string;
	data: {
		cast_member_id: string;
		cast_id: string;
		cast_name: string;
		cast_img: string;
		cast_descript: string;
		cast_ranking: number;
	};
	ranking: number;
	index: number;
	onMoveCategory: (dragIndex: number, hoverIndex: number) => void;
	onDropCategory: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
	index: number;
	id: string;
	type: string;
}

export default function Cast(props: CastProps) {
	const {id, name, data, index, onMoveCategory, onDropCategory} = props;
	const {id: serieId} = useParams();

	const ref = useRef<HTMLDivElement>(null);
	const dispatch = useAppDispatch();
	const [{handlerId}, drop] = useDrop<DragItem, void, {handlerId: Identifier | null}>({
		accept: "cast",
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			onMoveCategory(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
		drop(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Time to actually perform the action
			onDropCategory(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{isDragging}, drag] = useDrag({
		type: "cast",
		item: () => {
			return {id, index};
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	drag(drop(ref));

	const handleCastDelete = () => {
		auth.currentUser?.getIdToken().then(token => {
			deleteCast(token, {castmemberid: data.cast_member_id} as any)
				.then(() => {
					const promise = dispatch(
						seriesActions.getSeriesCast({GCPUser: auth.currentUser!, params: {searchby: "id", searchvalue: serieId}}),
					);
					toast.promise(promise, {
						loading: "Eliminando actor del elenco.",
						error: "Error al eliminar actor del elenco.",
						success: "Actor eliminado correctamente.",
					});
				})
				.catch(err => {
					console.error(err);
					toast.error(err?.message);
				});
		});
	};

	return (
		<div
			ref={ref}
			data-handler-id={handlerId}
			className={cn(
				"group relative grid grid-cols-3 overflow-hidden rounded-md border border-transparent p-4 pl-12 transition-all hover:border-border",
				{
					"border-border bg-background hover:border-transparent": isDragging,
				},
			)}
		>
			<div
				className={cn(
					"absolute bottom-0 left-0 top-0 flex items-center justify-center border-r border-border bg-background px-2 opacity-0 transition-all group-hover:opacity-100",
					{
						"opacity-100": isDragging,
					},
				)}
			>
				<DragIndicator className="h-4 w-4" cursor={"grab"} />
			</div>
			<div className="border-r border-border pr-4 text-end font-bold">{name}</div>
			<div className="col-span-2 pr-20">
				<div className="col-span-2 flex gap-4 pl-4">
					<img src={data.cast_img} className="h-24 w-24 flex-shrink-0 bg-neutral-300 object-contain" alt="Actor Profile" />
					<span className="text-left">{data.cast_descript}</span>
				</div>
			</div>
			<div className="absolute right-0 top-0 opacity-0 transition-opacity group-hover:opacity-100">
				<Button size="sm" variant="outline" className="px-2.5" onClick={handleCastDelete}>
					<Trash className="h-4 w-4" />
				</Button>
			</div>
		</div>
	);
}
