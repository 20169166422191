/**
 * Function that generates an hsl color from a string.
 * @param { string } str - string which will be used to generate a color.
 * @returns { string }
 */
export function stringToColor(str: string): string {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	var h = hash % 360;
	return "hsl(" + h + ",60.95%,50.49%)";
}
