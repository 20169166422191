import {useEffect, useState} from "react";
import {DatePickerWithRange} from "../../components/blocks/DateRangePicker";
import {DateRange} from "react-day-picker";
import {roundToNearestMinutes, startOfDay, startOfToday, subDays} from "date-fns";
import OverviewCharts from "./components/overview-charts";
import {AverageRating, DevicesRating, RecentsFeedback, RefreshView, SatisfactionFeedback, TriggersAnalysis} from "./components/ui";
import {useGetOverallFeedbackStatsQuery} from "../../_store/features/feedback/api";
import {Period} from "./types";
import toast from "react-hot-toast";

export default function FeedbackOverview() {
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(startOfToday());
		const from = startOfDay(subDays(Date.now(), 7));
		return {
			from,
			to,
		};
	});
	const [period, setPeriod] = useState<Period>("day");
	const {data: api, isError} = useGetOverallFeedbackStatsQuery({
		start_date: dates?.from?.toISOString().split("T")[0],
		end_date: dates?.to?.toISOString().split("T")[0],
		group_by: period,
	});

	useEffect(() => {
		if (isError) {
			toast.error("Error al cargar los datos");
		}
	}, [isError]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		if (!selectedDate?.to) {
			return toast.error("La fecha final es requerida");
		}

		if (selectedDate) {
			setSelectedDate(selectedDate);
			return;
		}
	};

	return (
		<div className="flex flex-col">
			<div className="sticky top-0 z-10 mx-6 flex items-center justify-between border-b border-border bg-background pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Análisis de reseñas</h2>

				<div className="flex items-center space-x-2">
					<RefreshView period={period} dates={dates} />
					<DatePickerWithRange
						onDateRangeChange={handleDateRangeChange}
						disabled={{before: new Date('2023-10-01T00:00:00-05:00'), after: new Date()}}
						date={dates}
					/>
				</div>
			</div>
			<div className="flex flex-col gap-4 px-6 py-4">
				<OverviewCharts dates={dates} data={api?.data} period={period} setPeriod={setPeriod} />

				<div className="grid grid-flow-row grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
					<AverageRating data={api?.data} />
					<DevicesRating data={api?.data} />
					<SatisfactionFeedback data={api?.data} />
				</div>

				<div className="grid grid-flow-row grid-cols-1 gap-4 pb-4 md:grid-cols-2 lg:grid-cols-3">
					<RecentsFeedback />
					<TriggersAnalysis dates={dates} />
				</div>
			</div>
		</div>
	);
}
