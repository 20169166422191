import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Period} from "../../types";
import {cn} from "../../../../../utils/classNames";
import {DateRange} from "react-day-picker";

interface PeriodSelectorProps {
	dates: DateRange | undefined;
	setPeriod: Dispatch<SetStateAction<Period>>;
	period: Period;
}

const ONE_DAY = 1000 * 60 * 60 * 24;
const ONE_WEEK = ONE_DAY * 7;
const ONE_MONTH = ONE_DAY * 31;

export function PeriodSelector({dates, period, setPeriod}: PeriodSelectorProps) {
	const [availablePeriods, setAvailablePeriods] = useState<Period[]>([]);

	useEffect(() => {
		if (dates) {
			const {from, to} = dates;

			if (from && to) {
				const nextDayTo = new Date(to?.getTime() + ONE_DAY);
				const diff = nextDayTo.getTime() - from.getTime();

				if (diff >= ONE_MONTH) {
					setAvailablePeriods(["day", "week", "month"]);
				} else if (diff >= ONE_WEEK) {
					if (period === "month") {
						setPeriod("week");
					}
					setAvailablePeriods(["day", "week"]);
				} else if (diff >= ONE_DAY) {
					if (period !== "day") {
						setPeriod("day");
					}
					setAvailablePeriods(["day"]);
				}
			} else {
				setAvailablePeriods([]);
			}
		}
	}, [dates, period, setPeriod]);

	return (
		<div className="flex items-center p-2">
			<div className="flex flex-1 justify-center space-x-4 lg:flex-col-reverse lg:space-x-0 lg:space-y-2">
				<button
					onClick={() => setPeriod("day")}
					className={cn(
						"w-16 rounded-md border border-gray-300 p-2 text-xs font-medium disabled:bg-gray-200 lg:mt-2",
						period === "day" ? "bg-white" : "bg-white/50 text-gray-400",
					)}
					disabled={!availablePeriods.includes("day")}
				>
					Día
				</button>
				<button
					onClick={() => setPeriod("week")}
					className={cn(
						"w-16 rounded-md border border-gray-300 p-2 text-xs font-medium disabled:bg-gray-200",
						period === "week" ? "bg-white" : "bg-white/50 text-gray-400",
					)}
					disabled={!availablePeriods.includes("week")}
				>
					Semana
				</button>
				<button
					onClick={() => setPeriod("month")}
					className={cn(
						"w-16 rounded-md border border-gray-300 p-2 text-xs font-medium disabled:bg-gray-200",
						period === "month" ? "bg-white" : "bg-white/50 text-gray-400",
					)}
					disabled={!availablePeriods.includes("month")}
				>
					Mes
				</button>
			</div>
		</div>
	);
}
