import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {PublicityRange} from "../../../data-access/banners/banners-config";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {bannersActions} from "../../../_store/features/banners-add-rotation/banners-configuration-slice";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {Button} from "../../../components/primitives/Button";
import {ListPlus, Pencil, Refresh, Trash} from "../../../components/primitives/icons";
import {Input} from "../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../components/primitives/Select";
import {DataTable} from "../../../components/blocks/DataTable";
import ActionConfirmModal from "../../../components/blocks/ActionConfirmModal";
import toast from "react-hot-toast";

const searchOptions = [
	{name: "ID de la Configuración", value: "id"},
	{name: "ID del Contenido", value: "content_id"},
];

const columnHelper = createColumnHelper<PublicityRange>();

function BannersPublicityRangeList() {
	const dispatch = useAppDispatch();
	const bannersRanges = useAppSelector(state => state.banners);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [deleteID, setDeleteID] = useState("");
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const handleSearch = () => {
		const params: Record<string, any> = {};

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		if (page.pageSize === 0) return;

		dispatch(
			bannersActions.getBannersPublicityRange({
				page_size: page.pageSize,
				page: page.pageIndex,
				...params,
			}),
		);
	};

	useEffect(() => {
		if (!page.pageSize) return;

		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("id", {
				id: "ID",
				header: ({column}) => <DataTableColumnHeader title="ID" column={column} />,
				cell: info => <div className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.getValue()}</div>,
				size: Math.round(tableWidth * 0.23),
			}),
			columnHelper.accessor("content_id", {
				id: "Contenido/ ID del contenido",
				header: ({column}) => <DataTableColumnHeader title="Contenido / ID del contenido" column={column} />,
				cell: info => {
					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.title}</span>
							<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.content_id}</span>
						</div>
					);
				},
				size: Math.round(tableWidth * 0.23),
			}),
			columnHelper.accessor("start_time", {
				id: "Inicio",
				header: ({column}) => <DataTableColumnHeader title="Inicio" column={column} />,
				cell: info => <div className="ml-2 flex w-full text-left">{info.getValue()}</div>,
				size: Math.round(tableWidth * 0.1),
			}),
			columnHelper.accessor("rotation_interval", {
				id: "Intervalo",
				header: ({column}) => <DataTableColumnHeader title="Intervalo" column={column} />,
				cell: info => <div className="ml-2 flex w-full text-left">{info.getValue()}</div>,
				size: Math.round(tableWidth * 0.1),
			}),
			columnHelper.accessor("end_time", {
				id: "Final",
				header: ({column}) => <DataTableColumnHeader title="Final" column={column} />,
				cell: info => <div className="ml-2 flex w-full text-left">{info.getValue()}</div>,
				size: Math.round(tableWidth * 0.1),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="justify-left flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/advertisements/range-configuration/edit/" + info.row.original.id + "/" + info.row.original.content_id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setDeleteID(info.row.original.id);
								setDeleteConfirmationModalOpen(true);
							}}
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
				size: Math.round(tableWidth * 0.23),
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableRef]);

	const refresh = () => {
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		setPage({
			...page,
			pageIndex: 0,
		});
		dispatch(
			bannersActions.getBannersPublicityRange({
				page_size: page.pageSize,
				page: page.pageIndex,
			}),
		);
	};
	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Lista de Intervalos de visibilidad</h2>
				<Button className="gap-2" asChild>
					<Link to="/advertisements/range-configuration/new">
						<ListPlus />
						Nuevo Intervalo
					</Link>
				</Button>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={bannersRanges.results}
					pageCount={Math.ceil(bannersRanges.totalResults / bannersRanges.pageSize)}
					pagination={page}
					loading={bannersRanges.loading}
					onPaginationChange={setPage}
					withDynamicPageSize
					rowHeight={53}
					showPagination={false}
				/>
			</div>
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={() => {
					dispatch(bannersActions.deleteBannersPublicityRange(deleteID)).then(res => {
						if (res.meta.requestStatus === "fulfilled") {
							toast.success("Configuración de visibilidad eliminada correctamente.");
						}
						if (res.meta.requestStatus === "rejected") {
							if (res.meta.rejectedWithValue && typeof res.payload === "string") {
								toast.error(res.payload);
							}
						}
					});
					setDeleteConfirmationModalOpen(false);
					setDeleteID("");
				}}
				title={"Eliminar configuración de visibilidad"}
				description={
					<span className="text-white">
						Al confirmar eliminaras la configuración de visibilidad de anuncios en este contenido, este proceso no se puede revertir.
					</span>
				}
			/>
		</div>
	);
}

export default BannersPublicityRangeList;
