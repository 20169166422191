import {useMemo} from "react";
import {Pie} from "react-chartjs-2";
import {Spinner} from "../../../../components/primitives/icons";
import {SeriesReproductionTime} from "../../../../data-access/mux";

interface ReproductionPieChartProps {
	statistics: SeriesReproductionTime[];
	loading?: boolean;
	onClick?: () => void;
}

export default function ReproductionPieChart({statistics, loading, onClick}: ReproductionPieChartProps) {
	const totalTimeInHours = useMemo(() => {
		return statistics.reduce((acc, {total_time_seconds}) => acc + total_time_seconds, 0) / 3600;
	}, [statistics]);
	const data = useMemo(() => {
		const dataChart = statistics.map(({total_time_seconds}) => (total_time_seconds / 3600).toFixed(2));
		const randomColorsBySeries = () => {
			const usedColors = new Set<string>();
			return statistics.map(() => {
				let color;
				do {
					color = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`;
				} while (usedColors.has(color));
				usedColors.add(color);
				return color;
			});
		};
		const labels = statistics.map(({title}, idx) => {
			const value = (Number(dataChart[idx]) / totalTimeInHours) * 100;
			return `${title} \t\t\t - \t\t\t ${value.toFixed(2)}%`;
		});

		return {
			labels,
			datasets: [
				{
					label: "Tiempo de reproducción",
					data: dataChart,
					backgroundColor: randomColorsBySeries(),
					borderColor: "white",
					borderWidth: 1,
				},
			],
		};
	}, [statistics, totalTimeInHours]);

	return (
		<div className="h-full w-full cursor-pointer rounded-md border">
			<div className="my-10 flex w-full items-center justify-between pr-8">
				<h3 className="ml-8 text-start text-2xl font-semibold leading-none tracking-tight">Tiempo de reproducción de los contenidos</h3>
				<h3 className="text-lg font-semibold leading-none tracking-tight">
					Total: {totalTimeInHours.toFixed(2)} <span className="text-base font-light">hrs</span>
				</h3>
			</div>
			<div className="p-6 pt-0">
				{loading ? (
					<div className="flex w-full items-center justify-center">
						<Spinner />
					</div>
				) : (
					<Pie
						height={440}
						options={{
							responsive: true,
							maintainAspectRatio: false,
							plugins: {
								legend: {
									position: "right" as const,
									align: "start",
									fullSize: true,
									rtl: true,
									labels: {
										boxHeight: 15,
										boxWidth: 25,
										font: {size: 16},
									},
								},
							},
						}}
						data={data}
					/>
				)}
			</div>
		</div>
	);
}
