import {useEffect, useMemo, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {Separator} from "../../../components/primitives/Separator";
import {EditShortsDataBodySchema, EditShortsDataBodyType, uploadShortsImage} from "../../../data-access/shorts/shorts";
import {zodResolver} from "@hookform/resolvers/zod";
import {SubmitHandler, useForm, Controller} from "react-hook-form";
import {shortsActions} from "../../../_store/features/shorts/shorts-slice";
import {toast} from "react-hot-toast";
import {auth} from "../../../firebase";
import {Input} from "../../../components/primitives/Input";
import {Checkbox} from "../../../components/primitives/Checkbox";
import MuxVideoCombobox from "../../series/Edit/subcomponents/MuxVideoCombobox";
import {Textarea} from "../../../components/primitives/Textarea";
import {DayPicker} from "../../../components/blocks/DatePicker";
import {Spinner} from "../../../components/primitives/icons";
import {Button} from "../../../components/primitives/Button";
import {Image as ImageIcon} from "../../../components/primitives/icons";
import MultimediaImagePickerModal from "../../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../../_store/features/gallery/gallery-slice";
import Select, {MultiValue} from "react-select";
import {selectGlobalStyles} from "../../../../utils/selectGlobalStyles";
import {FOLDERS_NAMES_SHORTS} from "../../../../utils/folderNames";
import {tagsActions} from "../../../_store/features/shorts/tags-slice";
import {AddNewTagModal} from "./subcomponents/AddNewTagModal";
import {SelectOptionType} from "../../../../utils/globalTypes";
import TagsSelector from "../../../components/blocks/TagsSelector/TagsSelector";
import {TagsSelectorList} from "../../../components/blocks/TagsSelector/TagsSelector.interface";
import NotificatioModal from "../../../components/blocks/SendCustomNotificationModal/SendCustomNotificationModal";

const initialState: EditShortsDataBodyType = {
	id: "",
	title: "",
	slugs: "",
	short_description: "",
	long_description: "",
	ranking: 0,
	published: false,
	video_id: "",
	order: 0,
	lowres_image: "",
	link: null,
	estreno: false,
	duration: 0,
	rectangular_image: "",
	release_date: "",
	total_likes: 0,
};

type ImageFieldsTypes = keyof Pick<EditShortsDataBodyType, "lowres_image" | "rectangular_image">;

interface SelectOption {
	label: string;
	value: boolean;
}

const selectStatus: SelectOption[] = [
	{label: "Sí", value: true},
	{label: "No", value: false},
];

function ShortEdit() {
	let {id} = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const shortsData = useAppSelector(state => state.shorts.results.find(el => el.id === id));
	const tags = useAppSelector(state => state.tags.results);
	const [openDialog, setOpenDialog] = useState(false);
	const [type, setType] = useState<ImageFieldsTypes>("lowres_image");
	const [selectedStatus, setSelectedStatus] = useState<SelectOption | null>();
	const [selectedShortTags, setSelectedShortTags] = useState<SelectOptionType[] | null>([]);
	const [multiSelection, setMultiSelection] = useState<SelectOptionType[] | null>(null);
	const [data, setData] = useState<EditShortsDataBodyType>();
	const [isLoading, setIsLoading] = useState(false);

	const [isNewTagModalOpen, setNewTagModalOpen] = useState(false);
	const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		control,
		formState: {errors, isSubmitting},
	} = useForm<EditShortsDataBodyType>({
		resolver: zodResolver(EditShortsDataBodySchema),
		defaultValues: initialState,
	});
	const [fieldLoading, setFieldLoading] = useState<string[]>([]);

	useEffect(() => {
		if (!shortsData) {
			return navigate("/short_videos/list");
		}
		setSelectedStatus(selectStatus.find(el => el.value === shortsData.new) ?? selectStatus[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, shortsData]);

	useEffect(() => {
		reset({...shortsData, estreno: shortsData?.new ?? false});
	}, [reset, shortsData]);

	const tagsInitialSelection = useMemo(() => {
		if (!tags) return [];
		const allTags: TagsSelectorList[] = tags
			.filter(tag => (shortsData?.tags ?? []).some(shortTag => shortTag.id === tag.id))
			.map(i => ({tag_id: i.id, series_tag_id: "", seriesid: ""}));
		return allTags;
	}, [tags, shortsData]);

	const selectedTags = useMemo(() => {
		if (!selectedShortTags?.length && selectedShortTags !== null) return false;
		return selectedShortTags ? (selectedShortTags as SelectOptionType[]).map(tag => tag.value).join(",") : null;
	}, [selectedShortTags]);

	const handleSendData = () => {
		if (!shortsData || !data) return;
		setIsLoading(true);
		dispatch(shortsActions.updateShort(data))
			.then(async res => {
				if (res.meta.requestStatus === "fulfilled") {
					selectedTags !== false &&
						(await dispatch(
							tagsActions.insertShortVideosTags({
								short_videos_id: shortsData?.id,
								tag_ids: selectedTags as null | string,
							}),
						));
					toast.success("Short actualizado correctamente");
					resetStates();
					setTimeout(() => {
						navigate("/short_videos/list");
					}, 3000);
				}
				if (res.meta.requestStatus === "rejected") {
					if (res.meta.rejectedWithValue && typeof res.payload === "string") {
						toast.error(res.payload);
					}
				}
			})
			.catch(err => {
				console.log(err);
				toast.error("Error al actualizar el short");
			})
			.finally(() => {
				setIsNotificationModalOpen(false);
				setIsLoading(false);
			});
	};

	const onSubmit: SubmitHandler<EditShortsDataBodyType> = formData => {
		setData(formData);
		if (formData.estreno && formData.published) return setIsNotificationModalOpen(true);
		handleSendData();
	};

	const resetStates = () => {
		setSelectedShortTags([]);
		setMultiSelection([]);
	};

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);

		body.append("file", file);
		body.append("type", type);
		body.append("short_videosid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadShortsImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	function handleOnChange(newValue: MultiValue<SelectOptionType>) {
		if (newValue.length === 0) {
			setSelectedShortTags(null);
			return;
		}
		setSelectedShortTags([...newValue]);
	}

	return (
		<>
			<div>
				<div className="space-y-0.5 px-6 pt-4">
					<h2 className="text-2xl font-bold tracking-tight">Editar Short</h2>
					<p className="text-muted-foreground">Edita los datos de un Short de la plataforma.</p>
				</div>
				<div className="mx-6">
					<Separator className="mb-4 mt-2" />
				</div>

				<div className="px-4">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="mt-6">
							<dl className="divide-y divide-border">
								<div className="px-4 py-6 pt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Titulo<span className="text-xs font-bold text-destructive">*</span>
									</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="text" placeholder="Insertar texto del anuncio..." {...register("title")} />
										{errors?.title?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.title?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Slug<span className="text-xs font-bold text-destructive">*</span>
									</dt>
									<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="text" placeholder="Insertar nombre del anunciante..." {...register("slugs")} />
										{errors?.slugs?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.slugs.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Público</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="items-top flex space-x-2">
											<Controller
												name="published"
												control={control}
												render={({field}) => (
													<Checkbox id="terms1" checked={field.value} onCheckedChange={value => field.onChange(value)} />
												)}
											></Controller>
											<div className="grid gap-1.5 leading-none">
												<label
													htmlFor="terms1"
													className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
												>
													Marcar el short como público.
												</label>
												<p className="text-sm text-muted-foreground">
													Al marcar el short como público, se comenzara a mostrar en los espacios determinados de las
													plataformas.
												</p>
											</div>
										</div>
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Video ID</dt>
									<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<MuxVideoCombobox
											id="video_content_id"
											name="video_id"
											defaultValue={getValues("video_id") ?? ""}
											onChangeSelected={selected => {
												setValue("video_id", selected.playbackid);
											}}
											video_type={3}
										/>
										{errors?.video_id?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.video_id.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Descripción corta</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="text" placeholder="Insertar descripción corta del short..." {...register("short_description")} />
										{errors?.short_description?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.short_description?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Descripción larga</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Textarea rows={5} placeholder="Descripción del short.." {...register("long_description")} />
										{errors?.long_description?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.long_description?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Link</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="text" placeholder="Insertar el link del short..." {...register("link")} />
										{errors?.link?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.link?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Ranking</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="number" placeholder="Orden de la serie..." {...register("ranking", {valueAsNumber: true})} />
										{errors?.ranking?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.ranking?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Total de likes</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="number" placeholder="Total de likes..." {...register("total_likes", {valueAsNumber: true})} />
										{errors?.total_likes?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.total_likes?.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Orden</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Input type="number" placeholder="Orden de la serie..." {...register("order", {valueAsNumber: true})} />
										{errors?.order?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.order?.message}</span>
										)}
									</dd>
								</div>

								<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Fecha de release</dt>
									<dd className="mt-1 w-full justify-start self-start">
										{shortsData?.release_date && (
											<Controller
												name="release_date"
												control={control}
												render={({field}) => (
													<DayPicker
														selectedDay={new Date(shortsData?.release_date)}
														{...field}
														onDayChange={date => {
															const dateFormatted = date?.toISOString();
															field.onChange(dateFormatted);
														}}
														className="min-w-[150px] justify-start self-start"
													/>
												)}
											/>
										)}
										{errors?.release_date?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.release_date?.message}</span>
										)}
									</dd>
								</div>

								<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Estreno</dt>
									<Controller
										name="estreno"
										control={control}
										render={({field}) => (
											<Select
												className="mt-1 w-full rounded ring-1"
												styles={selectGlobalStyles}
												{...field}
												options={selectStatus as any}
												value={selectedStatus}
												onChange={selectedOption => {
													setSelectedStatus(selectedOption);
													field.onChange(selectedOption?.value);
												}}
											/>
										)}
									/>
								</div>

								<TagsSelector
									initialSelection={tagsInitialSelection}
									multiSelection={multiSelection}
									onSelectionChange={handleOnChange}
									placeholder="Selecciona las tags para agregarlas a los shorts"
								/>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Short</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="grid w-full max-w-sm items-center gap-1.5">
											<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
												{!getValues("rectangular_image") ? (
													fieldLoading.includes("rectangular_image") ? (
														<Spinner />
													) : (
														<ImageIcon className="h-6 w-6 text-slate-400" />
													)
												) : (
													<img src={getValues("rectangular_image")} className="w-full object-contain" alt="Thumbnail" />
												)}
											</div>
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "rectangular_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="gap-2 whitespace-nowrap"
												onClick={() => {
													handleSelectImage("rectangular_image");
												}}
											>
												<ImageIcon className="h-4 w-4" />
												Seleccionar de multimedia
											</Button>
										</div>
										{errors?.rectangular_image?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.rectangular_image.message}</span>
										)}
									</dd>
								</div>

								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Thumbnail</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<div className="grid w-full max-w-sm items-center gap-1.5">
											<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
												{!getValues("lowres_image") ? (
													fieldLoading.includes("lowres_image") ? (
														<Spinner />
													) : (
														<ImageIcon className="h-6 w-6 text-slate-400" />
													)
												) : (
													<img src={getValues("lowres_image")} className="w-full object-contain" alt="Thumbnail" />
												)}
											</div>
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "lowres_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="gap-2 whitespace-nowrap"
												onClick={() => {
													handleSelectImage("lowres_image");
												}}
											>
												<ImageIcon className="h-4 w-4" />
												Seleccionar de multimedia
											</Button>
										</div>
										{errors?.lowres_image?.message && (
											<span className="text-sm font-medium text-destructive">{errors?.lowres_image.message}</span>
										)}
									</dd>
								</div>
							</dl>
						</div>
						<div className="flex justify-end gap-2 pb-4">
							<Button size={"sm"} variant="secondary" asChild>
								<Link to={"/short_videos/list/"}>Cancelar</Link>
							</Button>

							<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
								{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
								Guardar cambios
							</Button>
						</div>
					</form>
					<MultimediaImagePickerModal
						open={openDialog}
						onDismiss={() => {
							setOpenDialog(false);
							dispatch(galleryActions.resetToInitial());
						}}
						tabIndex={10}
						folderNames={FOLDERS_NAMES_SHORTS}
						onSuccess={url => {
							setValue(type, url);
							setOpenDialog(false);
							dispatch(galleryActions.resetToInitial());
						}}
					/>
					<AddNewTagModal
						open={isNewTagModalOpen}
						onDismiss={() => {
							setNewTagModalOpen(false);
						}}
						onSuccess={() => {
							setNewTagModalOpen(false);
							toast.success("Tag agregado correctamente");
						}}
					/>
					<NotificatioModal
						open={isNotificationModalOpen}
						action={handleSendData}
						messageData={data ? {id: data.id, name: data.title} : undefined}
						forcedType="new_short_notification"
					/>
				</div>
			</div>
		</>
	);
}

export default ShortEdit;
