import {useMemo} from "react";
import {Pie} from "react-chartjs-2";
import {ReportResponse} from "./../../../../../data-access/report/types";
import React from "react";

interface PerformanceChartProps {
	source: ReportResponse;
}

export default function PerformanceChart({source}: PerformanceChartProps) {
	const {report} = source;
	const data = useMemo(() => {
		const dataChart = report.series.map(({total_series_time_seconds}) => (total_series_time_seconds / 3600).toFixed(2));

		const labels = report.series.map((serie, idx) => {
			const value = (Number(dataChart[idx]) / (report.total_time_seconds / 3600)) * 100;
			return `${serie.title} \t\t\t - \t\t\t ${value.toFixed(2)}%`;
		});

		return {
			labels,
			datasets: [
				{
					label: "Tiempo de reproducción en el período",
					data: dataChart,
					backgroundColor: report.series.map(serie => serie.color ?? "#000000"),
					borderColor: "white",
					borderWidth: 2,
				},
			],
		};
	}, [report]);

	return (
		<div className="h-full w-full cursor-pointer rounded-md border">
			<div className="my-10 flex w-full items-center justify-between pr-8">
				<h3 className="ml-8 text-start text-2xl font-semibold leading-none tracking-tight">Horas de reproducción de los contenidos</h3>
				<h3 className="text-lg font-semibold leading-none tracking-tight">
					Total: {((report?.total_time_seconds ?? 0) / 3600).toFixed(2)} <span className="text-base font-light">hrs</span>
				</h3>
			</div>
			<div className="p-6 pt-0">
				<Pie
					height={440}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							legend: {
								position: "right" as const,
								align: "start",
								fullSize: true,
								rtl: true,
								labels: {
									boxHeight: 15,
									boxWidth: 25,
									font: {size: 16},
									filter(item) {
										item.text.includes("$") && (item.fontColor = "black");
										return true;
									},
								},
							},
						},
					}}
					data={data}
				/>
			</div>
		</div>
	);
}
