import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

/*INSERT GOOGLE*/
export interface InsertCoinProductGoogleBody {
	productid: string;
}
interface InsertCoinProductGoogleResponse {
	id: string;
	name: string;
	description: string;
	price: number;
	coins: number;
	stripe_price: number;
	google_price: number;
	vendor_price: number;
	apple_price: number;
	stripe_productid: string;
	google_id: string;
	apple_id: string;
	active: boolean;
	audit_created: string;
	audit_updated: string;
}

export async function insertCoinProductGoogleData(
	token: string,
	data: InsertCoinProductGoogleBody,
): Promise<AxiosResponse<InsertCoinProductGoogleResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/create_google_product_coins", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
