import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface appStatusInterface {
	loading: boolean;
}

const initialState: appStatusInterface = {
	loading: true,
};

const appStatusSlice = createSlice({
	name: "appStatus",
	initialState,
	reducers: {
		setAppLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
	},
});

export const appStatusActions = {
	...appStatusSlice.actions,
};

export default appStatusSlice;
