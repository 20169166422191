import {Variant} from "../../../../data-access/series/content";
import MuxVideoCombobox from "./MuxVideoCombobox";
import {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {serieContentsActions} from "../../../../_store/features/serie-content/serie-content-slice";
import {useAppDispatch} from "../../../../_store/hooks";

interface VariantsProps {
	data: Variant[];
}

interface VariantsData {
	primary: Variant;
	secondary: Variant;
}

const defaultVariant: Variant = {
	id: "",
	description: "",
	clicks: 0,
	likes: 0,
	video_id: "",
	duration: "",
	order: 0,
};

const initialData: VariantsData = {
	primary: {...defaultVariant},
	secondary: {...defaultVariant},
};

const Variants = ({data}: VariantsProps) => {
	const [dataState, setData] = useState<VariantsData>(initialData);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const primary = data.find(v => v.order === 1);
		const secondary = data.find(v => v.order === 2);

		if (!primary || !secondary) return;

		setData({
			primary,
			secondary,
		});
	}, [data]);

	const handleUpdate = async (variant: "primary" | "secondary") => {
		try {
			dispatch(serieContentsActions.editSerieContentVariant(dataState[variant])).then(thunk => {
				if (thunk.type === "serieContents/editVariant/fulfilled") {
					toast.success(`Final actualizado ${variant === "primary" ? "1" : "2"} correctamente`);
				}
			});
		} catch (e) {
			toast.error("No se pudo actualizar el final");
		}
	};

	return (
		<div className="mb-3 grid gap-4 sm:grid-cols-2">
			<div className="">
				<div>
					<label className="text-white" htmlFor="final_primary_video">
						Video Final 1
					</label>
					<MuxVideoCombobox
						id="final_primary_video"
						name="video_id"
						defaultValue={dataState.primary.video_id}
						onChangeSelected={selected => {
							const videoDuration = `${selected.duration}`;
							setData(prev => ({
								...prev,
								primary: {...prev.primary, duration: videoDuration, video_id: selected.playbackid},
							}));
						}}
					/>
				</div>

				<div>
					<label className="text-white" htmlFor="final_primary_duration">
						Duración en Segundos (Final 1)
					</label>
					<input
						id="final_primary_duration"
						className="form-control w-full rounded bg-black px-3 py-2 text-white"
						type="number"
						min="0"
						placeholder="Duración en Segundos (Final 1)"
						name="duration"
						value={dataState.primary.duration}
						onChange={e => {
							setData(prev => ({...prev, primary: {...prev.primary, duration: e.target.value}}));
						}}
					/>
				</div>

				<div>
					<label className="text-white" htmlFor="final_primary_duration">
						Total de clicks (Final 1)
					</label>
					<input
						id="final_primary_duration"
						className="form-control w-full rounded bg-black px-3 py-2 text-white"
						type="number"
						min="0"
						placeholder="Total de clicks (Final 1)"
						name="clicks"
						value={dataState.primary.clicks}
						onChange={e => {
							setData(prev => ({...prev, primary: {...prev.primary, clicks: parseInt(e.target.value)}}));
						}}
					/>
				</div>

				<div className="my-3 flex flex-col">
					<label className="text-white" htmlFor="final_primary_description">
						Descripción Final 1
					</label>
					<textarea
						className="rounded bg-black p-2 text-white"
						id="final_primary_description"
						rows={3}
						placeholder="Descripción Final 1"
						name="final_primary_description"
						value={dataState.primary.description}
						onChange={e => {
							setData(prev => ({...prev, primary: {...prev.primary, description: e.target.value}}));
						}}
					/>
				</div>

				{dataState.primary.id && (
					<button
						className="w-full rounded bg-neutral-50 px-4 py-2 uppercase text-black transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
						type="button"
						onClick={() => handleUpdate("primary")}
					>
						Actualizar Final 1
					</button>
				)}
			</div>

			<div className="">
				<div>
					<label className="text-white" htmlFor="final_secondary_video">
						Video Final 2
					</label>
					<MuxVideoCombobox
						id="final_secondary_"
						name="video_id_alternative"
						defaultValue={dataState.secondary.video_id}
						onChangeSelected={selected => {
							const videoDuration = `${selected.duration}`;
							setData(prev => ({
								...prev,
								secondary: {...prev.secondary, duration: videoDuration, video_id: selected.playbackid},
							}));
						}}
					/>
				</div>

				<div>
					<label className="text-white" htmlFor="final_secondary_duration">
						Duración en Segundos (Final 2)
					</label>
					<input
						id="final_secondary_duration"
						className="form-control w-full rounded bg-black px-3 py-2 text-white"
						type="number"
						min="0"
						placeholder="Duración en Segundos (Final 2)"
						name="final_secondary_duration"
						value={dataState.secondary.duration}
						onChange={e => {
							setData(prev => ({...prev, secondary: {...prev.secondary, duration: e.target.value}}));
						}}
					/>
				</div>

				<div>
					<label className="text-white" htmlFor="final_primary_duration">
						Total de clicks (Final 2)
					</label>
					<input
						id="final_primary_duration"
						className="form-control w-full rounded bg-black px-3 py-2 text-white"
						type="number"
						min="0"
						placeholder="Total de clicks (Final 2)"
						name="clicks"
						value={dataState.secondary.clicks}
						onChange={e => {
							setData(prev => ({...prev, secondary: {...prev.secondary, clicks: parseInt(e.target.value)}}));
						}}
					/>
				</div>

				<div className="my-3 flex flex-col">
					<label className="text-white" htmlFor="final_secondary_description">
						Descripción Final 2
					</label>
					<textarea
						className="rounded bg-black p-2 text-white"
						id="final_secondary_description"
						rows={3}
						placeholder="Descripción Final 2"
						name="final_secondary_description"
						value={dataState.secondary.description}
						onChange={e => {
							setData(prev => ({...prev, secondary: {...prev.secondary, description: e.target.value}}));
						}}
					/>
				</div>

				{dataState.secondary.id && (
					<button
						className="w-full rounded bg-neutral-50 px-4 py-2 uppercase text-black transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
						type="button"
						onClick={() => handleUpdate("secondary")}
					>
						Actualizar Final 2
					</button>
				)}
			</div>
		</div>
	);
};

export default Variants;
