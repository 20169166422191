import {endOfToday, formatISO9075, roundToNearestMinutes, subMonths} from "date-fns";
import {DatePickerWithRange} from "../../../components/blocks/DateRangePicker";
import Select, {SingleValue} from "react-select";
import {selectGlobalStyles} from "../../../../utils/selectGlobalStyles";
import ReproductionPieChart from "./_subcomponents/ReproductionPieChart";
import {Card} from "../../../components/blocks/Stats/Card";
import {useAppDispatch} from "../../../_store/hooks";
import {useEffect, useMemo, useState} from "react";
import {useDashboardByNameQuery} from "../../../_store/features/dashboard/hooks";
import {dashboardActions} from "../../../_store/features/dashboard/dashboard-slice";
import {DateRange} from "react-day-picker";
import {seriesActions} from "../../../_store/features/series/series-slice";
import {Spinner} from "../../../components/primitives/icons";

const PAYMENT_TYPES_OPTIONS = [
	{label: "Todos los pagos", value: undefined},
	{label: "Suscripciones", value: 0},
	{label: "Membresías", value: 1},
	{label: "Coins", value: 2},
	{label: "Rentas", value: 3},
];

export const CONTENT_TYPES_OPTIONS = [
	{label: "Todos los Contenidos", value: undefined},
	{label: "Capitulos", value: "0,6"}, // The chapter type is 0 and the decide type is 6
	{label: "Capitulos free", value: false},
	{label: "Capitulos suscriptores", value: true},
	{label: "Contenidos extras", value: "2,4,5"}, // The content type is 2, the stream type is 4 and the live stream type is 5
];

export default function Performance() {
	const dispatch = useAppDispatch();
	const muxDataViewPercentData = useDashboardByNameQuery("getMuxDataViewsPercents");
	const costAndEarnings = useDashboardByNameQuery("getCostAndEarnings");

	const [selectedContentType, setSelectedContentType] = useState<string | undefined>();
	const [selectedPaymentType, setSelectedPaymentType] = useState<number | undefined>();
	const [selectedPremiumChapters, setSelectedPremiumChapters] = useState<boolean | undefined>();

	const [isLoading, setIsLoading] = useState(false);
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subMonths(to, 1);
		return {
			from,
			to,
		};
	});

	useEffect(() => {
		setIsLoading(true);
		Promise.all([
			dispatch(seriesActions.getSeriesList({params: {published: true, page: 0, page_size: 99999}})),
			dispatch(
				dashboardActions.getMuxDataViewsPercentsAction({
					content_type: selectedContentType,
					subscription_required: selectedPremiumChapters,
					initial_date: formatISO9075(dates?.from!),
					final_date: formatISO9075(dates?.to!),
				}),
			),
			dispatch(
				dashboardActions.getCostAndEarningsDataAction({
					initial_date: formatISO9075(dates?.from!),
					final_date: formatISO9075(dates?.to!),
				}),
			),
		]).finally(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedContentType, dates, selectedPremiumChapters]);

	const handleContentType = (value: SingleValue<{label: string; value: string | boolean | undefined}>) => {
		if (!value) return;
		if (typeof value.value === "boolean") {
			setSelectedContentType("0,6");
			setSelectedPremiumChapters(value.value);
			return;
		}
		setSelectedPremiumChapters(undefined);
		setSelectedContentType(value.value);
	};

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	const handlePaymentType = (value: SingleValue<{label: string; value: number | undefined}>) => {
		if (!value) return;
		setSelectedPaymentType(value.value);
	};

	const cardValues = useMemo(() => {
		if (!costAndEarnings.data) return {totalEarnings: 0, totalCosts: 0, totalPlatformEarnings: 0};
		const {coin_earnings, platform_costs, rent_earnings, series_earnings, subscription_earnings} = costAndEarnings.data;
		let totalEarnings = 0;
		let totalPlatformEarnings = platform_costs;
		switch (selectedPaymentType) {
			case 0:
				totalEarnings = Number(subscription_earnings);
				break;
			case 1:
				totalEarnings = Number(series_earnings);
				break;
			case 2:
				totalEarnings = Number(coin_earnings);
				break;
			case 3:
				totalEarnings = Number(rent_earnings);
				break;
			default:
				totalEarnings = Number(subscription_earnings) + Number(series_earnings) + Number(coin_earnings) + Number(rent_earnings);
				break;
		}

		return {totalEarnings: totalEarnings * 0.01, totalPlatformEarnings};
	}, [costAndEarnings.data, selectedPaymentType]);

	const {totalEarnings, totalPlatformEarnings} = cardValues;

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Performance/Tiempo de Reproducción</h2>
			</div>
			<div className="flex gap-6">
				<div className="flex min-w-[1000px] flex-col gap-6 py-6 pl-6">
					<div className="flex w-full items-center justify-between">
						<DatePickerWithRange
							onDateRangeChange={handleDateRangeChange}
							disabled={{before: new Date('2023-10-01T00:00:00-05:00'), after: new Date()}}
							date={dates}
						/>
						<div className="flex gap-6">
							<Select
								className="basic-multi-select min-w-[288px] rounded"
								options={CONTENT_TYPES_OPTIONS}
								defaultValue={CONTENT_TYPES_OPTIONS[0]}
								isSearchable={false}
								isMulti={false}
								styles={selectGlobalStyles}
								onChange={handleContentType}
								isLoading={isLoading}
							/>
						</div>
					</div>
					{muxDataViewPercentData.isLoading ? (
						<div className="flex w-full justify-center">
							<div className="rounded-full bg-slate-100 p-1 shadow-lg">
								<Spinner className="h-6 w-6 animate-spin" />
							</div>
						</div>
					) : (
						<div className="flex w-full flex-col gap-6">
							<ReproductionPieChart statistics={muxDataViewPercentData.data?.series ?? []} loading={isLoading} />
						</div>
					)}
				</div>
				<hr className="via-99% h-[90vh] w-px border-none bg-gradient-to-b from-slate-300 via-slate-300 to-transparent " />
				<div className="flex flex-col gap-6 py-6">
					<Select
						className="basic-multi-select w-72 rounded"
						options={PAYMENT_TYPES_OPTIONS}
						defaultValue={PAYMENT_TYPES_OPTIONS[0]}
						isMulti={false}
						styles={selectGlobalStyles}
						placeholder="Todos los tipos de pago"
						onChange={handlePaymentType}
						isLoading={isLoading}
					/>

					<div className="flex flex-col items-stretch justify-start gap-6">
						<Card title="Total de ingresos bruto" amount={totalEarnings} />
						{selectedPaymentType === PAYMENT_TYPES_OPTIONS[0].value && (
							<>
								<Card title="Total de gastos" amount={totalPlatformEarnings} />
								<Card title="Total de ingresos neto" amount={totalEarnings - totalPlatformEarnings} />
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
