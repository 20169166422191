import {createAppAsyncThunk} from "../../hooks";
import {auth} from "../../../firebase";
import {createPost, getExclusiveRoomPosts, updatePostService} from "../../../data-access/exclusive-room/exclusive-room";
import {CreatePostBody, GetPostsListParams, Post} from "../../../data-access/exclusive-room/types";

export const getExclusiveRoomListBySerie = createAppAsyncThunk("exclusive-room/list", async (params: GetPostsListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		// const response = await getExclusiveRoomList(token);
		const response = await getExclusiveRoomPosts(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

export const createExclusiveRoomPost = createAppAsyncThunk("exclusive-room/create", async (data: CreatePostBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await createPost(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

export const updatePost = createAppAsyncThunk("exclusive-room/update", async (data: Post, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await updatePostService(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});
