import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {useEffect} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {crewActions} from "../../../../_store/features/crew/crew-slice";
import {toast} from "react-hot-toast";
import {Input} from "../../../../components/primitives/Input";
import {Button} from "../../../../components/primitives/Button";
import {Spinner} from "../../../../components/primitives/icons";
import {UpdateStaffProfessionBodySchema, UpdateStaffProfessionBodyType} from "../../../../data-access/series/staff";

const initialState: UpdateStaffProfessionBodyType = {
	id: "",
	profession: "",
};

function ProfessionEdit() {
	const {id} = useParams();
	const staffProfessionData = useAppSelector(state => state.crew.staffProfessionsResults.find(el => el.id === id));

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		reset,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<UpdateStaffProfessionBodyType>({
		resolver: zodResolver(UpdateStaffProfessionBodySchema),
		defaultValues: initialState,
	});

	const onSubmit: SubmitHandler<UpdateStaffProfessionBodyType> = data => {
		return dispatch(crewActions.updateProfession(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Profesion editada correctamente");
				setTimeout(() => {
					navigate("/crew/professions");
				}, 3000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	useEffect(() => {
		if (!staffProfessionData) {
			return navigate("/crew/professions");
		}

		reset(staffProfessionData);
	}, [staffProfessionData, navigate, reset]);

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Editar porfesion</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite editar profesiones que se encuentran en la plataforma.</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-border">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Nombre<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar nombre de la profesión..." {...register("profession")} />
									{errors?.profession?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.profession?.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								reset();
								navigate("/crew/professions");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Editar profesión
						</Button>
					</div>
				</form>
			</div>
		</section>
	);
}

export default ProfessionEdit;
