import {useMemo} from "react";
import {ROLES} from "../../../../data-access/fetch-db-users";

const ROLE_DESCRIPTIONS: Record<ROLES, {description?: string; allowed: string[]; notAllowed: string[]}> = {
	admin: {
		allowed: [
			"Editar datos personales",
			"Modificar su contraseña",
			"Editar cualquier información de los usuarios del sistema",
			"Acceso total dentro de la administración",
		],
		notAllowed: [],
	},
	master_editor: {
		allowed: [
			"Editar datos personales",
			"Modificar su contraseña",
			"Gestionar rol de Editor",
			"Gestionar contenidos",
			"Gestionar anuncios",
			"Gestionar multimedias",
			"Editar usuarios, partner, influencer",
			"Crear productos de venta",
			"Ver estadísticas generales",
		],
		notAllowed: ["Crear roles iguales que el suyo ni superior"],
	},
	corporate: {
		allowed: [
			"Editar datos personales",
			"Modificar su contraseña",
			"Acceder a sus estadísticas",
			"Ver la información de los Influencers, Partners y Sponsors",
		],
		notAllowed: ["Realizar otra acción dentro de la plataforma"],
	},
	editor: {
		allowed: [
			"Editar datos personales",
			"Modificar su contraseña",
			"Añadir, editar y publicar contenido",
			"Añadir, editar y publicar anuncio",
			"Añadir, editar y publicar multimedia",
		],
		notAllowed: [
			"Añadir ningún rol dentro de la administración",
			"Modificar información de ningún usuario",
			"Eliminar información del sistema",
			"No puede editar ninguna información relacionada con dinero en la plataforma",
		],
	},
	partner: {
		allowed: ["Editar datos personales", "Modificar su contraseña", "Ver sus estadísticas", "Chequear sus pagos"],
		notAllowed: [
			"Crear roles dentro de la administración",
			"Editar información de otros usuarios",
			"Eliminar información del sistema",
			"Manipular datos de su serie ni cambiar su sociedad con la misma",
			"Ver estadísticas ni ganancias de otro influencer",
		],
	},
	sponsor: {
		allowed: ["Editar sus datos personales", "Modificar su contraseña", "Ver sus estadísticas"],
		notAllowed: [
			"Crear roles dentro de la administración",
			"Editar información de ningún usuario",
			"Eliminar información del sistema",
			"Manipular datos de ninguna serie ni cambiar su sociedad con la misma",
			"Ver estadísticas de otros roles",
			"Ver información relacionada con costos de la aplicación",
		],
	},
	influencer: {
		allowed: ["Editar datos personales", "Modificar su contraseña", "Ver sus estadísticas", "Recuperar su link"],
		notAllowed: [
			"Editar información de ningún usuario",
			"Crear roles en la administración",
			"Eliminar información del sistema",
			"Manipular datos de su serie ni cambiar su sociedad con la misma",
			"Ver estadísticas ni ganancias de otro influencer",
		],
	},
	customer_service: {
		allowed: [
			"Editar datos personales",
			"Modificar su contraseña",
			"Acceder a los comentarios, reportados y eliminados",
			"Gestionar los usuarios simples de la plataforma",
			"Responder a un comentario del chat de los contenidos",
		],
		notAllowed: ["Acceder a los usuarios administrativos", "Eliminar información del sistema"],
	},
	marketing: {
		allowed: ["Editar datos personales", "Modificar su contraseña", "Acceder a sus estadísticas"],
		notAllowed: ["Realizar otra acción dentro de la plataforma"],
	},
	customerservice_l1: {
		allowed: [
			"Editar datos personales",
			"Modificar su contraseña",
			"Acceder a los comentarios, reportados y eliminados",
			"Gestionar los usuarios de la plataforma con limitada edición",
			"Responder a un comentario del chat de los contenidos",
		],
		notAllowed: ["Editar información editar información de usuarios", "Editar información de la plataforma"],
	},
	vendor: {
		allowed: [
			"Editar datos personales",
			"Modificar su contraseña",
			"Ver sus estadísticas",
			"Chequear sus pagos",
			"Ver productos a sus usuarios asociados",
		],
		notAllowed: ["Realizar otra acción dentro de la plataforma"],
	},
};

interface RoleDescriptionProps {
	role: ROLES | null;
}

export default function RoleDescription(props: RoleDescriptionProps) {
	const {role} = props;

	const data = useMemo(() => {
		if (!role) {
			return null;
		}

		return ROLE_DESCRIPTIONS[role];
	}, [role]);

	if (!data) return null;

	return (
		<>
			{!!data.allowed.length && (
				<div className="mt-2 rounded-md border-2 border-dashed border-success/30 bg-success/5 p-5">
					<div className="text-lg font-semibold">Este rol puede:</div>
					<ul className="ml-6 list-disc [&>li]:mt-2">
						{data?.allowed.map((str, idx) => (
							<li key={idx}>{str}</li>
						))}
					</ul>
				</div>
			)}
			{!!data.notAllowed.length && (
				<div className="mt-2 rounded-md border-2 border-dashed border-destructive/30 bg-destructive/5 p-5">
					<div className="text-lg font-semibold">Este rol NO puede:</div>
					<ul className="ml-6 list-disc [&>li]:mt-2">
						{data?.notAllowed.map((str, idx) => (
							<li key={idx}>{str}</li>
						))}
					</ul>
				</div>
			)}
		</>
	);
}
