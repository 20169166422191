import axios, {AxiosResponse} from "axios";
import {dbUser} from "../_store/features/user-db/user-db-slice";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface GetCustomersListParams {
	page: number;
	page_size: number;
	vendorid: string;
	initial_creation_date: string;
	final_creation_date: string;
	active: boolean;
	customerid: string;
}

export type Customer = dbUser & {
	vendorid: string;
	customerid: string;
	active?: boolean;
};

interface CustomersListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Customer[];
}

export async function getCustomersList(token: string, params?: Partial<GetCustomersListParams>): Promise<AxiosResponse<CustomersListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_db_vendor_customers", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface DeleteCustomerData {
	id: string;
	customerid: string;
	vendorid?: string | null;
}

export async function deleteCustomerData(token: string, data: DeleteCustomerData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_db_vendor_customer", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export const AssociateCustomerDataSchema = z.object({
	customerid: z.string(),
	vendorid: z.string(),
});
export type AssociateCustomerData = z.infer<typeof AssociateCustomerDataSchema>;

export async function associateCustomerData(token: string, data: AssociateCustomerData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/vendor_add_customer", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface UpdateCustomerData {
	id: string;
	active?: boolean;
	customerid?: string | null;
	vendorid?: string;
}

export async function updateCustomerData(token: string, data: UpdateCustomerData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/update_db_vendor_customer", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
