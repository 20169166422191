import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface SerieRentalBySerie {
	sr_id: string;
	sr_products_rentalid: string;
	sr_seriesid: string;
	pr_id: string;
	pr_name: string;
	pr_description: string;
	pr_stripe_price: number;
	pr_stripe_productid: string;
	pr_stripe_priceid: string;
	pr_google_price: number;
	pr_google_id: string;
	pr_apple_price: number;
	pr_apple_id: string;
	pr_active: boolean;
	pr_rental_type: number;
	pr_created_at: string;
	pr_updated_at: string;
	pr_stripe_price_initial: number;
	pr_google_price_initial: number;
	pr_apple_price_initial: number;
	pr_stripe_fee_pct: number;
	pr_google_fee_pct: number;
	pr_apple_fee_pct: number;
	pr_stripe_fee_cnt: number;
	pr_google_fee_cnt: number;
	pr_apple_fee_cnt: number;
	pr_author: string;
	s_id: string;
	s_title: string;
	s_slug: string;
	s_description: string;
	s_ranking: number;
	s_published: true;
	s_total_likes: number;
	s_total_favorite: number;
	s_video_mux_id: string;
	s_img_banner_web_desktop: string;
	s_img_banner_web_mobile: string;
	s_img_logo: string;
	s_img_thumbnail: string;
	s_audit_created: string;
	s_audit_updated: string;
	s_position_order: number;
	s_img_square_thumbnail: string;
	s_tags: string;
	s_membership_title: string;
	s_link: string;
	s_production_cost: number;
	s_total_dislikes: number;
	s_release_date: string;
	s_whitelist_release_date: string;
	s_display_release_date: string;
	s_pronyr_original: string;
	s_horizontal_mobile_banner: string;
	s_account: string;
	s_horizontal_tv_banner: string;
}

/*LIST*/
export interface GetSerieRentalProductListParams {
	page?: number;
	page_size?: number;
	searchby?: string;
	searchvalue?: string;
	active?: boolean;
}
export interface GetSerieProductRentalListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: SerieRentalBySerie[];
}
export async function getSerieRentalsProductsBySeriesList(
	token: string,
	params?: GetSerieRentalProductListParams,
): Promise<AxiosResponse<GetSerieProductRentalListResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/admin_list_products_rentals", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface SerieRentalProductBodyRequest {
	productid: string;
	stripe_fee_pct: number;
	google_fee_pct: number;
	apple_fee_pct: number;
	stripe_fee_cnt: number;
	google_fee_cnt: number;
	apple_fee_cnt: number;
	stripe_price_initial: number;
	google_price_initial: number;
	apple_price_initial: number;
}

export interface SerieRentalProductResponse {
	id: string;
	name: string;
	active: boolean;
	author: string;
	apple_id: string;
	google_id: string;
	created_at: string;
	updated_at: string;
	apple_price: number;
	apple_price_initial: number;
	description: string;
	google_price: number;
	google_price_initial: number;
	stripe_price: number;
	stripe_price_initial: number;
	stripe_priceid: string;
	subscription_type: number;
	stripe_productid: string;
}

export async function updateSerieRentalsProduct(
	token: string,
	body: SerieRentalProductBodyRequest,
): Promise<AxiosResponse<SerieRentalProductResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.patch("/update_database_product_rental", body, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface UpdateSeriesRentalParams {
	seriesid: string;
	products_rentalid: string;
}

export interface UpdateDatabaseSeriesRentalResponse {
	id: string;
	products_rentalid: string;
	seriesid: string;
}

export async function updateSerieRentalsAssociatedProduct(
	token: string,
	body: UpdateSeriesRentalParams,
): Promise<AxiosResponse<UpdateDatabaseSeriesRentalResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.patch("/update_database_series_rental", body, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface ProductRental {
	active: boolean;
	apple_fee_cnt: number;
	apple_fee_pct: number;
	apple_id: string | null;
	apple_price: number;
	apple_price_initial: number;
	author: string;
	created_at: string;
	description: string;
	google_fee_cnt: number;
	google_fee_pct: number;
	google_id: string | null;
	google_price: number;
	google_price_initial: number;
	id: string;
	name: string;
	rental_type: number;
	stripe_fee_cnt: number;
	stripe_fee_pct: number;
	stripe_price: number;
	stripe_price_initial: number;
	stripe_priceid: string;
	stripe_productid: string;
	updated_at: string;
}

export interface ProductRentalTypeResponse {
	results: ProductRental[];
	current: number;
	error: string;
	loading: boolean;
	pageSize: string;
	success: boolean;
	totalResults: number;
	validateProduct: boolean;
}

export async function getSerieRentalsProductsList(
	token: string,
	params: GetSerieRentalProductListParams,
): Promise<AxiosResponse<ProductRentalTypeResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/admin_list_rentals_types", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
