import {ProductRental, Rental} from "../../../data-access/series/user-rentals";

export const StatusEnum = {
	LOADING: "Loading",
	IDLE: "Idle",
	ADDING_RENTAL: "AddingRental",
	MODIFY_RENTAL: "ModifyRental",
} as const;

type StatusEnumAsType = typeof StatusEnum;
export type Status = StatusEnumAsType[keyof StatusEnumAsType];

export interface RentalManagementState {
	rentals: Rental[];
	currentRental: Rental | null;
	status: Status;
	error: string | null;
	productRentals: ProductRental[];
}
