import {Button} from "../../../components/primitives/Button";
import {ScrollArea} from "../../../components/primitives/ScrollArea";
import {Sheet, SheetClose, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../components/primitives/Sheet";
import CommentThread from "../../../components/blocks/CommentThread";
import {Textarea} from "../../../components/primitives/Textarea";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {CustomerServiceCommentResponseBody, CustomerServiceCommentResponseBodySchema} from "../../../data-access/comments/comments";
import {zodResolver} from "@hookform/resolvers/zod";
import {useAppDispatch} from "../../../_store/hooks";
import {commentsActions} from "../../../_store/features/comments/comments-slice";
import {useEffect} from "react";
import {toast} from "react-hot-toast";
import UserSelect from "./UserSelect";

interface ReplySheetProps extends SheetProps {
	commentData?: {id: string; path: string};
}

export default function ReplySheet(props: ReplySheetProps) {
	const {commentData, ...rest} = props;
	const dispatch = useAppDispatch();
	const {
		register,
		formState: {errors},
		handleSubmit,
		setValue,
		reset,
		control,
	} = useForm<CustomerServiceCommentResponseBody>({
		resolver: zodResolver(CustomerServiceCommentResponseBodySchema),
		defaultValues: {parentid: "", content: {type: 0, payload: ""}},
	});

	useEffect(() => {
		setValue("parentid", commentData?.id ?? "");
	}, [commentData?.id, setValue]);

	const handleInsertReply: SubmitHandler<CustomerServiceCommentResponseBody> = async data => {
		const res = await dispatch(commentsActions.customerServiceCommentResponse(data));
		if (res.meta.requestStatus === "fulfilled") {
			toast.success("Comentario insertado correctamente.", {position: "bottom-left"});
			reset();
		}
		if (res.meta.requestStatus === "rejected") {
			toast.error("Error al insertar el comentario.", {position: "bottom-left"});
		}
	};

	return (
		<Sheet {...rest}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>Hilo del comentario</SheetTitle>
					<SheetDescription>Responde a un comentario realizado por un usuario.</SheetDescription>
				</SheetHeader>
				<ScrollArea className="h-full w-full rounded-md border p-4">{commentData && <CommentThread commentData={commentData} />}</ScrollArea>
				<form onSubmit={handleSubmit(handleInsertReply)}>
					<Controller
						name="authorid"
						control={control}
						render={({field}) => <UserSelect isDisabled={false} onChange={userid => field.onChange(userid)} />}
					/>
					<div>
						<Textarea placeholder="Insertar respuesta a comentario..." rows={5} {...register("content.payload")} />
						{errors?.content?.payload?.message && (
							<span className="text-sm font-medium text-destructive">{errors?.content?.payload?.message}</span>
						)}
					</div>
					<SheetClose asChild>
						<Button type="submit" className="mt-4">
							Enviar
						</Button>
					</SheetClose>
				</form>
			</SheetContent>
		</Sheet>
	);
}
