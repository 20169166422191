import axios from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface AdContent {
	id?: string;
	contentid: string;
	videoid?: string;
	offset?: number;
	title?: string;
	description?: string;
	skippable_offset?: number;
	creative_type?: "linear" | "nonlinear";
	click_through?: string;
	duration?: number;
	video_title?: string;
	video_duration?: number;
}

export async function createAdContent(token: string, data: AdContent) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/admin_create_ad_content", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function listAdContents(token: string, params: {contentid: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_ad_content", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function deleteAdContent(token: string, data: {id: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/admin_delete_ad_content", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export async function editAdContent(token: string, data: AdContent) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/admin_edit_ad_content", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
