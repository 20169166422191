import {useMemo, useRef} from "react";
import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, ChartOptions, LinearScale} from "chart.js";
import "chartjs-adapter-date-fns";
import {addDays, format} from "date-fns";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {es} from "date-fns/locale";
import {useDashboardByNameQuery} from "../../../../../_store/features/dashboard/hooks";
import {Spinner} from "../../../../../components/primitives/icons";
import {differenceInCalendarDays} from "date-fns/esm/fp";
import {DateRange} from "react-day-picker";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

interface RentalsChartProps {
	dates: DateRange | undefined;
}

const RentalsChart = ({dates}: RentalsChartProps) => {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; count: number}[]>>(null);
	const {data: statistics, isLoading} = useDashboardByNameQuery("rentalsIncomeEarning");

	const data: ChartData<"bar", {date: string; count: number}[]> = useMemo(() => {
		if (!statistics || !dates?.from || !dates?.to) return {datasets: []};

		const from = dates.from.setTime(dates.from.getTime() + dates.from.getTimezoneOffset() * 60 * 1000);
		const to = dates.to.setTime(dates.to.getTime() + dates.to.getTimezoneOffset() * 60 * 1000);

		const rangeLenght = differenceInCalendarDays(from, to);
		const initialDate = from;
		const selectedData: {date: string; count: number; earn: number}[] = Array.from({length: rangeLenght}, (_, i) => ({
			date: addDays(initialDate!, i).toLocaleDateString(),
			count: 0,
			earn: 0,
		}));

		for (const stat of statistics.daily) {
			const date = new Date(stat.year + "-" + stat.month + "-" + stat.day);
			const index = selectedData.findIndex(data => data.date === date.toLocaleDateString());
			if (index !== -1) {
				selectedData[index].count += stat.rentals;
				selectedData[index].earn += stat.earnings;
			}
		}

		const datasets = [
			{
				label: "Rentas - " + statistics.totalEarnings.toLocaleString("en-US", {style: "currency", currency: "USD"}),
				data: selectedData,
				backgroundColor: "#3498db",
				borderRadius: 4,
				parsing: {
					xAxisKey: "date",
					yAxisKey: "earn",
				},
			},
		] as {
			label: string;
			data: {date: string; count: number; earn: number}[];
			backgroundColor: string;
			borderRadius: number;
			parsing: {xAxisKey: string; yAxisKey: string};
		}[];

		return {
			labels: selectedData.map(data => data.date),
			datasets: datasets,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statistics]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="relative col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Rentas en el periodo</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<>
						<div className="relative p-6 pt-0">
							<Bar
								height={350}
								ref={chartRef}
								options={{
									...options,
									scales: {
										x: {
											ticks: {
												callback(tickValue) {
													let value = this.getLabelForValue(tickValue as any);
													const date: Date = new Date(value);

													return format(date, "MMM d", {locale: es}).toLowerCase();
												},
											},
											grid: {
												color: "transparent",
											},
											stacked: true,
										},
										y: {
											ticks: {
												precision: 1,
											},
											stacked: true,
										},
									},
								}}
								data={data}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default RentalsChart;

const options: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					const raw = tooltipItems[0].raw as {date: string; count: number; earn: number};
					return "Número de rentas: " + raw.count;
				},
				label(context) {
					return "Subtotal: " + context.parsed.y.toLocaleString("en-US", {style: "currency", currency: "USD"});
				},
			},
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d", {locale: es}).toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
	},
};
