import {useCallback, useEffect, useMemo, useState} from "react";
import Select from "react-select";
import toast from "react-hot-toast";

// Redux
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {tagsActions} from "../../../_store/features/shorts/tags-slice";

// Components
import {Skeleton} from "../../primitives/Skeleton";
import {Button} from "../../primitives/Button";
import {ListPlus} from "../../primitives/icons";
import {AddNewTagModal} from "../../../pages/shorts/Edit/subcomponents/AddNewTagModal";

// Interfaces
import TagsSelectorProps from "./TagsSelector.interface";

// Styles
import {selectGlobalStyles} from "../../../../utils/selectGlobalStyles";
import {SelectOptionType} from "../../../../utils/globalTypes";

export default function TagsSelector({initialSelection, multiSelection, placeholder, onSelectionChange}: TagsSelectorProps) {
	const dispatch = useAppDispatch();
	const {loading, results: tags} = useAppSelector(state => state.tags);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	useEffect(() => {
		dispatch(tagsActions.listTags({params: {page_size: 99999}}));
		return () => {
			dispatch(tagsActions.resetToInitial());
		};
	}, [dispatch]);

	const value = useMemo(() => {
		const matchingTags: SelectOptionType[] = tags
			?.filter(tag => (initialSelection || []).some(serieTag => serieTag.tag_id === tag.id))
			.map(tag => ({
				label: tag.tag,
				value: tag.id,
			}));
		return matchingTags;
	}, [initialSelection, tags]);

	const selectOptions = useMemo(() => (tags ? tags.map(tag => ({label: tag.tag, value: tag.id})) : []), [tags]);

	const handleModalOpen = useCallback(() => setIsModalOpen(prev => !prev), []);
	const onSuccessfulTagCreation = useCallback(() => {
		setIsModalOpen(false);
		toast.success("Tag agregado correctamente");
	}, []);

	if (loading) return <Skeleton className="mt-1 h-10 my-6 w-full rounded bg-slate-300 ring-1" />;

	return (
		<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
			<dt className="text-sm font-medium leading-6 text-gray-900">Tags</dt>
			<Select
				className="basic-multi-select mt-1 w-full rounded"
				isSearchable={true}
				isMulti={true}
				styles={selectGlobalStyles}
				options={selectOptions}
				defaultValue={value}
				onChange={onSelectionChange}
				placeholder={placeholder}
			/>
			<Button className="mr-2 mt-1 max-w-[100px] gap-2" type="button" variant={"outline"} onClick={handleModalOpen}>
				<ListPlus />
				Tags
			</Button>
			<AddNewTagModal open={isModalOpen} onDismiss={handleModalOpen} onSuccess={onSuccessfulTagCreation} />
		</div>
	);
}
