import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AdContent, createAdContent, deleteAdContent, listAdContents, editAdContent} from "../../../data-access/videos/video-ads";
import {auth} from "../../../firebase";

export interface adContentsInterface {
	loading: boolean;
	results: AdContent[];
	newContent: AdContent | null;
	editingContent: AdContent | null;
	isEditingContent: boolean;
}

const initialState: adContentsInterface = {
	loading: false,
	results: [],
	newContent: null,
	editingContent: null,
	isEditingContent: false,
};

const getAdContents = createAsyncThunk("adContents/list", async (contentid: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await listAdContents(token, {contentid});

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results.sort((a: AdContent, b: AdContent) => a.offset! - b.offset!),
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const insertAdContent = createAsyncThunk("adContents/insert", async (data: AdContent, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await createAdContent(token, data);

		await thunkAPI.dispatch(adContentsActions.getAdContents(data.contentid));
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const removeAdContent = createAsyncThunk("adContents/delete", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await deleteAdContent(token, {id});

		return id;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const updateAdContent = createAsyncThunk("adContents/edit", async (data: AdContent, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await editAdContent(token, data);

		await thunkAPI.dispatch(adContentsActions.getAdContents(data.contentid));
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const adContentsSlice = createSlice({
	name: "adContents",
	initialState,
	reducers: {
		setResults(state, action: PayloadAction<AdContent[]>) {
			state.results = action.payload;
		},
		setEditing(state, action: PayloadAction<{data: AdContent | null; editing: boolean}>) {
			state.editingContent = action.payload.data;
			state.isEditingContent = action.payload.editing;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getAdContents.pending, state => {
				state.loading = true;
			})
			.addCase(getAdContents.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
			})
			.addCase(getAdContents.rejected, (state, action) => {});
		builder
			.addCase(updateAdContent.pending, state => {
				state.loading = true;
			})
			.addCase(updateAdContent.fulfilled, (state, action) => {
				state.loading = false;
				state.isEditingContent = false;
				state.editingContent = null;
			})
			.addCase(updateAdContent.rejected, (state, action) => {});
		builder
			.addCase(insertAdContent.pending, state => {
				state.loading = true;
			})
			.addCase(insertAdContent.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(insertAdContent.rejected, (state, action) => {});

		builder
			.addCase(removeAdContent.pending, state => {
				state.loading = true;
			})
			.addCase(removeAdContent.fulfilled, (state, action) => {
				state.loading = false;
				state.results = state.results.filter(el => el.id !== action.payload);
			})
			.addCase(removeAdContent.rejected, (state, action) => {});
	},
});

export const adContentsActions = {
	...adContentsSlice.actions,
	getAdContents,
	insertAdContent,
	removeAdContent,
	updateAdContent,
};

export default adContentsSlice;
