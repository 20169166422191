import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {DataTable} from "../../components/blocks/DataTable";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/primitives/Avatar";
import {Button} from "../../components/primitives/Button";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";
import {ListPlus, Pencil} from "../../components/primitives/icons";
import {Refresh} from "../../components/primitives/icons/Refresh";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import useCurrentUserRole from "../../components/hooks/useCurrentUserRole";
import {AssociatesResult, associatesActions} from "../../_store/features/associates/associates-slice";

const searchOptions = [
	{display: "Por Nombre", searchby: "name"},
	{display: "Por ID de Usuario", searchby: "id"},
];

const columnHelper = createColumnHelper<AssociatesResult>();

function VinculatedList() {
	const {activeRole: userRole} = useCurrentUserRole();
	const vinculatedUsers = useAppSelector(state => state.associates);
	const dispatch = useAppDispatch();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [searchValue, setSearchValue] = useState("");
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const isAuthorized = useCallback(() => {
		switch (userRole) {
			case "admin":
				return true;
			case "master_editor":
				return true;
			case "corporate":
				return true;
			default:
				return false;
		}
	}, [userRole]);

	const handleSearch = () => {
		if (pagination.pageSize === 0) return;

		dispatch(
			associatesActions.getUserVinculatedSeries({
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				searchby: selectedSearchBy.searchby,
				searchvalue: searchValue,
			}),
		);
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const AVATAR_COL_WIDTH = 60;

		if (tableWidth > 0) {
			tableWidth = tableWidth - AVATAR_COL_WIDTH;
		}

		return [
			columnHelper.accessor("photo_url", {
				id: "Avatar",
				header: ({column}) => <DataTableColumnHeader title="Avatar" column={column} />,
				cell: info => {
					return (
						<Avatar>
							<AvatarImage src={info.row.original.photo_url} alt="User profile" />
							<AvatarFallback>{info.row.original?.email?.slice(0, 2)}</AvatarFallback>
						</Avatar>
					);
				},
				size: AVATAR_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("name", {
				id: "Nombre  / UUID",
				header: "Nombre / UUID",
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.accessor("serie_title", {
				id: "Serie / ID",
				header: "Serie / ID",
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.serie_title}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.serie_id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.accessor("email", {
				id: "Email  / Teléfono",
				header: "Email / Teléfono",
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.email}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.phone}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild disabled={!isAuthorized()}>
							<Link to={isAuthorized() ? "/users/edit/" + info.row.original.id : ""}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
				enableResizing: false,
			}),
		];
	}, [isAuthorized, tableRef]);

	const refresh = () => {
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(
			associatesActions.getUserVinculatedSeries({
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
			}),
		);
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.searchby === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<div className="flex items-center gap-5">
					<h2 className="text-2xl font-bold tracking-tight">Lista de Usuarios vinculados a una serie</h2>
					<div className="mx-4 flex flex-row items-start">
						<span className="text-ellipsis text-center text-2xl font-bold">Total: </span>
						<span className="ml-2 text-ellipsis text-center text-2xl font-bold">{vinculatedUsers.vinculatedTotalResults}</span>
					</div>
				</div>
				{userRole !== "corporate" && (
					<Button className="gap-2" asChild>
						<Link to="/users/new">
							<ListPlus />
							Nuevo usuario
						</Link>
					</Button>
				)}
			</div>
			<div className="flex gap-8 px-6 pt-4 xl:gap-24">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.searchby}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.searchby}`}>
											{sOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={vinculatedUsers.vinculatedResults}
					pageCount={Math.ceil(vinculatedUsers.vinculatedTotalResults / vinculatedUsers.vinculatedPageSize)}
					pagination={pagination}
					loading={vinculatedUsers.vinculatedLoading}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={57}
					showPagination={false}
				/>
			</div>
		</div>
	);
}

export default VinculatedList;
