import {
	associateCustomerData,
	AssociateCustomerData,
	deleteCustomerData,
	DeleteCustomerData,
	getCustomersList,
	GetCustomersListParams,
	updateCustomerData,
	UpdateCustomerData,
} from "../../../data-access/vendors";
import {auth} from "../../../firebase";
import {createAppAsyncThunk} from "../../hooks";

export const fetchCustomers = createAppAsyncThunk("vendor/fetchCustomers", async (params: Partial<GetCustomersListParams>, thunkApi) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkApi.rejectWithValue("No token found");
		}

		const response = await getCustomersList(token, params);
		return {results: response.data.results, total: response.data.totalResults};
	} catch (error: any) {
		console.log(error);
		thunkApi.rejectWithValue(error.message);
	}
});

export const fetchCustomer = createAppAsyncThunk("vendor/fetchACustomer", async (id: string, thunkApi) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkApi.rejectWithValue("No token found");
		}

		const response = await getCustomersList(token, {customerid: id});
		return response.data.results[0];
	} catch (error: any) {
		console.log(error);
		thunkApi.rejectWithValue(error.message);
	}
});

export const deleteCustomer = createAppAsyncThunk("vendor/deleteCustomers", async (data: DeleteCustomerData, thunkApi) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkApi.rejectWithValue("No token found");
		}

		await deleteCustomerData(token, data);
		return true;
	} catch (error: any) {
		console.log(error);
		thunkApi.rejectWithValue(error.message);
	}
});

export const associateCustomer = createAppAsyncThunk("vendor/associateCustomer", async (data: AssociateCustomerData, thunkApi) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkApi.rejectWithValue("No token found");
		}

		await associateCustomerData(token, data);
		return true;
	} catch (error: any) {
		console.log(error);
		thunkApi.rejectWithValue(error.message);
	}
});

export const updateCustomer = createAppAsyncThunk("vendor/updateCustomer", async (data: UpdateCustomerData, thunkApi) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) {
			return thunkApi.rejectWithValue("No token found");
		}

		await updateCustomerData(token, data);
		return true;
	} catch (error: any) {
		console.log(error);
		thunkApi.rejectWithValue(error.message);
	}
});
