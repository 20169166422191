import {Skeleton} from "../../../components/primitives/Skeleton";

export default function Loader() {
	return (
		<>
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Series</h2>
			</div>
			<div className="container m-auto grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] items-center justify-center gap-4 px-8 py-6">
				{Array(9)
					.fill(0)
					?.map((el, idx) => (
						<div key={idx} className="max-w-[250px] overflow-hidden rounded-md border border-border bg-card shadow-sm">
							<Skeleton className="aspect-video w-full" />
							<div className="w-60 scroll-m-20 border-b border-border p-4 text-lg">
								<Skeleton className="h-4">{el.title}</Skeleton>
							</div>
							<div className="space-y-2 p-6 py-4">
								<Skeleton className="h-4 w-1/2" />
								<Skeleton className="h-4 w-1/2" />
							</div>
							<Skeleton className="h-10 w-full border-t border-border" />
						</div>
					))}
			</div>
		</>
	);
}
