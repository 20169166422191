import {Tab} from "@headlessui/react";
import {createColumnHelper, flexRender, getCoreRowModel, Row, useReactTable} from "@tanstack/react-table";
import {FC, useEffect, useMemo, useState} from "react";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {Content} from "../../../../../data-access/series/content";
import {CONTENT, CONTENT_TYPES, MEMBERSHIP_TYPES} from "../../../../../constants";
import {AdContentModal} from "../../subcomponents/AdContentModal";
import {NewContentModal} from "../../subcomponents/NewContentModal";
import {CheckCircle, DragIndicator, Pencil, PlusSmall, Spinner, Trash, VideoCamera} from "../../../../../components/primitives/icons";
import {serieContentsActions} from "../../../../../_store/features/serie-content/serie-content-slice";
import {Button} from "../../../../../components/primitives/Button";
import {formatDurationTime} from "../../../../../../utils/formatDurationTime";
import {Badge} from "../../../../../components/primitives/Badge";
import ActionConfirmModal from "../../../../../components/blocks/ActionConfirmModal";
import {toast} from "react-hot-toast";
import LiveStream from "../../../../../components/primitives/icons/LiveStream";

const columnHelper = createColumnHelper<Content>();

export default function ContentSections() {
	const [isOpen, setIsOpen] = useState(false);
	const [elementDelete, setElementDelete] = useState<number>();
	const [selectedContent, setSelectedContent] = useState("");
	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const isContentLoading = useAppSelector(state => state.serieContent.loading);
	const contentData = useAppSelector(state => state.serieContent.results);
	const isSuccessDelete = useAppSelector(state => state.serieContent.success);
	const {id} = useParams();
	const [contentTypeToDisplay, setContentTypeToDisplay] = useState(CONTENT_TYPES[0].value);

	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [idToDelete, setIdToDelete] = useState("");

	const reorderRow = (draggedRowIndex: number, targetRowIndex: number) => {
		let oldData: Content[] = [];
		if (contentTypeToDisplay === 5) {
			oldData = Array.from(
				contentData.filter(content => content.content_type === CONTENT.LIVE_STREAM || content.content_type === CONTENT.STREAM),
			);
		} else {
			oldData = Array.from(contentData.filter(content => content.content_type === contentTypeToDisplay));
		}

		if (targetRowIndex !== -1) oldData.splice(targetRowIndex, 0, oldData.splice(draggedRowIndex, 1)[0]);
		const orderContentData = oldData.map((content, index) => ({...content, order: index + 1}));
		dispatch(serieContentsActions.orderSerieContent(orderContentData));
	};

	useEffect(() => {
		dispatch(serieContentsActions.getSerieContents(id!));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentTypeToDisplay]);

	useEffect(() => {
		if (!isSuccessDelete) return;
		if (elementDelete) {
			reorderRow(elementDelete, -1);
			dispatch(serieContentsActions.setSuccess(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessDelete]);

	const columns = useMemo(
		() => [
			columnHelper.accessor("order", {
				header: contentTypeToDisplay === 3 ? "Orden" : "Capítulo",
				id: contentTypeToDisplay === 3 ? "Orden" : "Capítulo",
				cell: info => info.getValue(),
				size: 64,
			}),
			columnHelper.accessor("title", {
				header: contentTypeToDisplay === 3 ? "Título / Descripción" : "Título / Id",
				cell: info => (
					<div className="flex flex-col">
						<p className="overflow-hidden text-ellipsis text-center">{info.row.original.title}</p>
						{contentTypeToDisplay === 3 ? (
							<p className="overflow-hidden text-ellipsis text-center text-neutral-500">{info.row.original.short_description}</p>
						) : (
							<p className="overflow-hidden text-ellipsis text-center text-neutral-500">{info.row.original.id}</p>
						)}
					</div>
				),
				size: contentTypeToDisplay === 3 ? 275 : 350,
			}),
			columnHelper.accessor("published", {
				header: "Estado",
				cell: info =>
					info.getValue() ? (
						<div className="flex flex-1 justify-center">
							<Badge variant={"success"}>Activo</Badge>
						</div>
					) : (
						<div className="flex flex-1 justify-center">
							<Badge variant={"destructive"}>Inactivo</Badge>
						</div>
					),
				size: 75,
				enableResizing: false,
				enableColumnFilter: false,
			}),
			columnHelper.accessor("duration", {
				header: contentTypeToDisplay === 3 ? "Duración" : "",
				cell: info => {
					const durationInSeconds = Number(info.row.original.duration);
					const formattedDuration = formatDurationTime(durationInSeconds);
					return (
						<div className="flex w-full flex-col">
							{contentTypeToDisplay === 3 && <p className="overflow-hidden text-ellipsis text-center">{formattedDuration}</p>}
						</div>
					);
				},
				size: contentTypeToDisplay === 3 ? 75 : 0,
				enableResizing: false,
				enableColumnFilter: false,
			}),
			columnHelper.accessor("new", {
				header: "Estreno",
				cell: info => <span className="mx-auto flex w-fit">{info.getValue() ? <CheckCircle /> : ""}</span>,
				size: 85,
				enableResizing: false,
				enableColumnFilter: false,
			}),
			columnHelper.accessor("membership_type", {
				header: "Membresía",
				cell: info => MEMBERSHIP_TYPES.find(el => el.value === info.getValue())?.label,
				size: 85,
				enableResizing: false,
				enableColumnFilter: false,
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full justify-center gap-2">
						{info.row.original.premiere_type === 1 && (
							<Button
								className="rounded bg-neutral-200 p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
								onClick={() => {
									navigate(`/live-premiere/${info.row.original.id}`);
								}}
								variant="ghost"
							>
								<LiveStream className="h-4 w-4" />
							</Button>
						)}
						{contentTypeToDisplay !== 3 ? (
							<Button
								className="rounded bg-neutral-200 p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
								onClick={() => {
									dispatch(serieContentsActions.setEditing({data: info.row.original, editing: true}));
									setIsOpen(true);
								}}
								variant="ghost"
							>
								<Pencil className="h-4 w-4" />
							</Button>
						) : (
							<Button
								className="rounded bg-neutral-200 p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
								variant="ghost"
								onClick={() => {
									dispatch(serieContentsActions.setEditing({data: info.row.original, editing: true}));
									navigate(`/multimedia/lives-streams/edit/${info.row.original.id}`, {replace: false, state: {param: id}});
								}}
							>
								<Pencil className="h-4 w-4" />
							</Button>
						)}

						{contentTypeToDisplay !== 3 && (
							<Button
								className="rounded bg-neutral-200 p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
								onClick={() => {
									setSelectedContent(info.row.original.id);
								}}
								variant="ghost"
							>
								<VideoCamera className="h-4 w-4" />
							</Button>
						)}

						<Button
							className="rounded bg-neutral-200 p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
							onClick={() => {
								setElementDelete(info.row.index);
								setIdToDelete(info.row.original.id);
								setDeleteConfirmationModalOpen(true);
							}}
							variant="ghost"
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
			}),
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[contentTypeToDisplay],
	);

	const filteredContentData = useMemo(() => {
		if (contentTypeToDisplay === 5) {
			return Array.from(contentData).filter(el => el.content_type === CONTENT.LIVE_STREAM || el.content_type === CONTENT.STREAM);
		} else {
			return Array.from(contentData).filter(el => el.content_type === contentTypeToDisplay);
		}
	}, [contentData, contentTypeToDisplay]);

	const table = useReactTable({
		data: filteredContentData,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getRowId: row => row.id,
	});

	const handleDeleteContent = async (id: string) => {
		try {
			await dispatch(serieContentsActions.deleteSerieContent(id));
			toast.success("Contenido eliminado satisfactoriamente!");
		} catch (error) {
			toast.error("Error deteniendo live");
			console.error("Ocurrió un error:", error);
		}
	};

	return (
		<>
			<div className="flex flex-col">
				<div className="flex items-center justify-end">
					{isContentLoading && <Spinner />}
					<button
						className="col-start-2 ml-4 flex items-center justify-center rounded bg-neutral-900 px-4 py-2 text-white transition-colors duration-300 hover:bg-neutral-700"
						type="button"
						onClick={() => navigate("new-content")}
					>
						<PlusSmall />
						<span>Nuevo contenido</span>
					</button>
				</div>
				<div className="w-full max-w-md px-2 sm:px-0">
					<Tab.Group selectedIndex={contentTypeToDisplay} onChange={setContentTypeToDisplay}>
						<Tab.List className="flex space-x-1 rounded-t-xl bg-neutral-900 p-1">
							{CONTENT_TYPES.map(type => (
								<Tab
									key={type.value}
									hidden={type.value === CONTENT.SHORT || type.value === CONTENT.STREAM}
									className={({selected}) =>
										`w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-black focus:outline-none 
															 ${selected ? "bg-white shadow" : "text-white hover:bg-white/[0.12] hover:text-white"}
															`
									}
								>
									<div>{type.label}</div>
								</Tab>
							))}
						</Tab.List>
					</Tab.Group>
				</div>
				<DndProvider backend={HTML5Backend}>
					<table className="overflow-hidden rounded-tr bg-neutral-50">
						<thead>
							{table.getHeaderGroups().map(headerGroup => (
								<tr key={headerGroup.id} className="bg-neutral-900 text-white">
									<th />
									{headerGroup.headers.map(header => (
										<th className="px-4 py-2" key={header.id} colSpan={header.colSpan}>
											{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map(row => (
								<DraggableRow key={row.id} row={row} reorderRow={reorderRow} />
							))}
						</tbody>
					</table>
					{!filteredContentData.length && <div className="text-center text-xl font-light italic text-neutral-400">No hay datos</div>}
				</DndProvider>
			</div>
			<NewContentModal modalTitle="Editar Contenido" onDismiss={() => setIsOpen(false)} open={isOpen} />
			<AdContentModal onDismiss={() => setSelectedContent("")} contentId={selectedContent} />
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setIdToDelete("");
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={async () => {
					handleDeleteContent(idToDelete);
					setDeleteConfirmationModalOpen(false);
					setIdToDelete("");
				}}
				title={"Eliminar contenido de la serie"}
				description={<span className="text-white">Al confirmar eliminarás el contenido, este proceso no se puede revertir.</span>}
			/>
		</>
	);
}

const DraggableRow: FC<{
	row: Row<Content>;
	reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
}> = ({row, reorderRow}) => {
	const [, dropRef] = useDrop({
		accept: "row",
		drop: (draggedRow: Row<Content>) => reorderRow(draggedRow.index, row.index),
	});

	const [{isDragging}, dragRef, previewRef] = useDrag({
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		item: () => row,
		type: "row",
	});

	return (
		<tr
			ref={previewRef} //previewRef could go here
			style={{opacity: isDragging ? 0.5 : 1}}
			className="overflow-hidden bg-neutral-100 odd:bg-neutral-200"
		>
			<td ref={dropRef} className="align-middle">
				<Button variant="ghost" ref={dragRef} className="align-middle">
					<DragIndicator />
				</Button>
			</td>
			{row.getVisibleCells().map(cell => (
				<td key={cell.id} className="py-2 text-center">
					{flexRender(cell.column.columnDef.cell, cell.getContext())}
				</td>
			))}
		</tr>
	);
};
