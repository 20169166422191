import {useMemo} from "react";
import {Pie} from "react-chartjs-2";
import {Spinner} from "../../../../../../components/primitives/icons";
import {useDashboardPartnerByNameQuery} from "../../../../../../_store/features/dashboard-partner/hooks";

export default function PerformanceChart() {
	const {isLoading: loading, data: statistics} = useDashboardPartnerByNameQuery("getPartnerGeneralIncomeAndProfit");

	const data = useMemo(() => {
		if (!statistics) return {datasets: []};
		const dataChart = statistics.series.map(({total_series_time_seconds}) => (total_series_time_seconds / 3600).toFixed(2));

		const labels = statistics.series.map((serie, idx) => {
			const value = (Number(dataChart[idx]) / (statistics.total_time_seconds / 3600)) * 100;
			return `${serie.title} \t\t\t - \t\t\t ${value.toFixed(2)}%`;
		});

		return {
			labels,
			datasets: [
				{
					label: "Tiempo de reproducción en el período",
					data: dataChart,
					backgroundColor: statistics.series.map(serie => serie.color),
					borderColor: "white",
					borderWidth: 2,
				},
			],
		};
	}, [statistics]);

	return (
		<div className="h-full w-full cursor-pointer rounded-md border">
			<div className="my-10 flex w-full items-center justify-between pr-8">
				<h3 className="ml-8 text-start text-2xl font-semibold leading-none tracking-tight">Horas de reproducción de los contenidos</h3>
				<h3 className="text-lg font-semibold leading-none tracking-tight">
					Total: {((statistics?.total_time_seconds ?? 0) / 3600).toFixed(2)} <span className="text-base font-light">hrs</span>
				</h3>
			</div>
			<div className="p-6 pt-0">
				{loading ? (
					<div className="flex w-full items-center justify-center">
						<Spinner />
					</div>
				) : (
					<Pie
						height={440}
						options={{
							responsive: true,
							maintainAspectRatio: false,
							plugins: {
								legend: {
									position: "right" as const,
									align: "start",
									fullSize: true,
									rtl: true,
									labels: {
										boxHeight: 15,
										boxWidth: 25,
										font: {size: 16},
										filter(item) {
											item.text.includes("$") && (item.fontColor = "black");
											return true;
										},
									},
								},
							},
						}}
						data={data}
					/>
				)}
			</div>
		</div>
	);
}
