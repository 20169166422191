import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {DataTable} from "../../components/blocks/DataTable";
import {Button} from "../../components/primitives/Button";
import {Apple, CancelCircleFilled, CheckCircleFilled, Google, Pencil, Stripe} from "../../components/primitives/icons";
import {Refresh} from "../../components/primitives/icons/Refresh";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";
import Tooltip from "../../components/primitives/ToolTip-toDeprecate";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import {ProductRental} from "../../data-access/products/serie-rental-products";
import {serieRentalProductsActions} from "../../_store/features/products/rental-products-slice";

const searchOptions = [{name: "Nombre", value: "name"}];

const statusOptions = [
	{name: "Todos", value: undefined},
	{name: "Activos", value: true},
	{name: "No Activos", value: false},
];

const columnHelper = createColumnHelper<ProductRental>();

export function RentalList() {
	const dispatch = useAppDispatch();
	const rentalProducts = useAppSelector(state => state.serieRentalProducts);

	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const handleSearch = () => {
		const params: Record<string, any> = {};
		params.active = selectedStatus.value;

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		if (!searchValue && !selectedStatus) return;

		if (page.pageSize === 0) return;
		dispatch(
			serieRentalProductsActions.getSerieRentalProducts({
				page_size: page.pageSize,
				page: page.pageIndex,
				...params,
			}),
		);
	};

	useEffect(() => {
		if (!page.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("name", {
				id: "Nombre",
				header: ({column}) => <DataTableColumnHeader title="Nombre" column={column} />,
				cell: info => <div className="w-full text-left">{info.getValue()}</div>,
				size: Math.round(tableWidth * 0.2),
			}),
			columnHelper.accessor("stripe_price", {
				id: "Precio Stripe",
				header: ({column}) => <DataTableColumnHeader title="Precio Stripe" column={column} />,
				cell: info => <div className="w-full text-left">${(info.getValue() / 100).toFixed(2)}</div>,
				size: Math.round(tableWidth * 0.15),
			}),
			columnHelper.accessor("google_price", {
				id: "Precio Google",
				header: ({column}) => <DataTableColumnHeader title="Precio Google" column={column} />,
				cell: info => <div className="w-full text-left">${(info.getValue() / 100).toFixed(2)}</div>,
				size: Math.round(tableWidth * 0.15),
			}),
			columnHelper.accessor("apple_price", {
				id: "Precio Apple",
				header: ({column}) => <DataTableColumnHeader title="Precio Apple" column={column} />,
				cell: info => <div className="w-full text-left">${(info.getValue() / 100).toFixed(2)}</div>,
				size: Math.round(tableWidth * 0.15),
			}),
			columnHelper.accessor("active", {
				id: "Estado",
				header: ({column}) => <DataTableColumnHeader title="Estado" column={column} />,
				cell: info => (
					<div className="flex w-full">
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.active ? " text-green-700" : " text-red-700")
							}
						>
							<Tooltip
								text={info.cell.row.original.active ? "Activo" : "Deshabilitado"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								{info.cell.row.original.active ? <CheckCircleFilled /> : <CancelCircleFilled />}
							</Tooltip>
						</span>
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.stripe_productid ? " text-green-700" : " text-red-700")
							}
						>
							<Tooltip
								text={info.cell.row.original.stripe_productid ? "Sincronizado" : "Sin Sincronizar"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								<Stripe />
							</Tooltip>
						</span>
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.google_id ? " text-green-700" : " text-red-700")
							}
						>
							<Tooltip
								text={info.cell.row.original.google_id ? "Sincronizado" : "Sin Sincronizar"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								<Google />
							</Tooltip>
						</span>
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.apple_id ? " text-green-700" : " text-red-700")
							}
						>
							<Tooltip
								text={info.cell.row.original.apple_id ? "Sincronizado" : "Sin Sincronizar"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								<Apple />
							</Tooltip>
						</span>
					</div>
				),
				size: Math.round(tableWidth * 0.2),
				enableSorting: false,
			}),
			columnHelper.display({
				header: "Controles",
				size: Math.round(tableWidth * 0.12),
				cell: info => (
					<div className="justify-left flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/products/serie-rental-edit/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const refresh = () => {
		setSelectedStatus(statusOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(
			serieRentalProductsActions.getSerieRentalProducts({
				page_size: page.pageSize,
				page: page.pageIndex,
			}),
		);
	};

	const handleStatusFilter = (value: string) => {
		const status = statusOptions.find(el => el.name === value) ?? statusOptions[0];
		setSelectedStatus(status);
		dispatch(
			serieRentalProductsActions.getSerieRentalProducts({
				page_size: page.pageSize,
				page: page.pageIndex,
				active: status.value,
			}),
		);
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Productos de Series</h2>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>

					<Select onValueChange={handleStatusFilter} value={selectedStatus.name}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{statusOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={sOption.name}>
										{sOption.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={rentalProducts.results as ProductRental[]}
					pageCount={Math.ceil(rentalProducts.totalResults / rentalProducts.pageSize)}
					pagination={page}
					loading={rentalProducts.loading}
					onPaginationChange={setPage}
					withDynamicPageSize
					rowHeight={53}
					showPagination={false}
				/>
			</div>
		</div>
	);
}
