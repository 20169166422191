import {zodResolver} from "@hookform/resolvers/zod";
import {useEffect, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {stickersActions} from "../../_store/features/stickers/stickers-slice";
import {useAppDispatch} from "../../_store/hooks";
import SeriesSelect from "../../components/blocks/SeriesSelect";
import {Button} from "../../components/primitives/Button";
import {Checkbox} from "../../components/primitives/Checkbox";
import {Input} from "../../components/primitives/Input";
import {Textarea} from "../../components/primitives/Textarea";
import {Image as ImageIcon} from "../../components/primitives/icons";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {InsertStickerBodySchema, InsertStickerBodyType, uploadStickersImage} from "../../data-access/stickers";
import {auth} from "../../firebase";
import {useNavigate} from "react-router-dom";
import MultimediaImagePickerModal from "../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../_store/features/gallery/gallery-slice";
import {FOLDERS_NAMES_STICKERS} from "../../../utils/folderNames";
import Select from "react-select";
import {selectGlobalStyles} from "../../../utils/selectGlobalStyles";
import {seriesActions} from "../../_store/features/series/series-slice";
import {STICKERS_TYPES, STICKER_RENDER_TYPES} from "../../constants/stickers";
import StickerRenderer from "../../components/blocks/StickerRenderer";

type ImageFieldsTypes = keyof Pick<InsertStickerBodyType, "extra_url" | "url">;

interface SelectOption {
	label: string;
	value: number;
}

const selectVisibilityStatus: SelectOption[] = [
	{label: "Global", value: 1},
	{label: "Serie", value: 2},
];

const selectType: SelectOption[] = [
	{label: "Estático", value: STICKER_RENDER_TYPES.STATIC},
	{label: "Animado", value: STICKER_RENDER_TYPES.ANIMATED},
];

export function StickersNew() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isImageUploading, setIsImageUploading] = useState(false);
	const [isExtraImageUploading, setIsExtraImageUploading] = useState(false);
	const [selectedVisibilityStatus, setSelectedVisibilityStatus] = useState<SelectOption | null>(selectVisibilityStatus[0]);
	const [selectedType, setSelectedType] = useState<SelectOption | null>(selectType[0]);
	const [imageType, setImageType] = useState<ImageFieldsTypes>("url");
	const [openDialog, setOpenDialog] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		control,
		clearErrors,
		setError,
		getValues,
		formState: {errors, isLoading, isSubmitting, isSubmitSuccessful},
	} = useForm<InsertStickerBodyType>({
		resolver: zodResolver(InsertStickerBodySchema),
		defaultValues: {
			active: false,
			sticker_type: 1,
			extra_url: null,
			render_type: selectType[0].value,
		},
	});

	useEffect(() => {
		reset();
		isSubmitSuccessful && navigate(-1);
	}, [isSubmitSuccessful, navigate, reset]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page: 0, page_size: 99999999}}));
	}, [dispatch]);

	const onSubmit: SubmitHandler<InsertStickerBodyType> = data => {
		if (selectedVisibilityStatus?.value === STICKERS_TYPES.SERIES && !data.seriesid) {
			setError("seriesid", {
				type: "manual",
				message: "Este campo no puede estar vacío.",
			});
		} else {
			return dispatch(stickersActions.insertStickers(data)).then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					toast.success("Sticker Creado Correctamente");
					setTimeout(() => {
						navigate(-1);
					}, 3000);
				}
				if (res.meta.requestStatus === "rejected") {
					if (res.meta.rejectedWithValue && typeof res.payload === "string") {
						toast.error(res.payload);
					}
				}
			});
		}
	};

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();

		if (type === "url") setIsImageUploading(true);
		if (type === "extra_url") setIsExtraImageUploading(true);

		body.append("file", file);
		body.append("stickerid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadStickersImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						clearErrors(imageType);
						setIsImageUploading(false);
						setIsExtraImageUploading(false);
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setImageType(type);
		setOpenDialog(true);
	};

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Nuevo Sticker</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite crear stickers asociados a una serie.</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-gray-100">
							<div className="ml-2 justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Visibilidad de sticker</dt>
								<Controller
									name="sticker_type"
									control={control}
									render={({field}) => (
										<Select
											className="mt-1 w-full rounded ring-1"
											styles={selectGlobalStyles}
											{...field}
											options={selectVisibilityStatus}
											value={selectedVisibilityStatus}
											onChange={selectedOption => {
												setSelectedVisibilityStatus(selectedOption);
												field.onChange(selectedOption?.value);
											}}
										/>
									)}
								/>
							</div>
							{selectedVisibilityStatus?.value === 2 && (
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">
										Serie<span className="text-xs font-bold text-destructive">*</span>
									</dt>
									<dd className="mt-1 sm:col-span-2 sm:mt-0">
										<Controller
											name="seriesid"
											control={control}
											render={({field}) => (
												<SeriesSelect
													onChange={newValue => {
														field.onChange(newValue?.value);
													}}
												/>
											)}
										/>
										{errors?.seriesid && (
											<span className="text-sm font-medium text-destructive">{errors?.seriesid?.message}</span>
										)}
									</dd>
								</div>
							)}
							<div className="ml-2 justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Tipo de sticker</dt>
								<Controller
									name="render_type"
									control={control}
									render={({field}) => (
										<Select
											className="mt-1 w-full rounded ring-1"
											styles={selectGlobalStyles}
											{...field}
											options={selectType}
											value={selectedType}
											onChange={selectedOption => {
												setSelectedType(selectedOption);
												field.onChange(Number(selectedOption?.value));
											}}
										/>
									)}
								/>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Costo de Sticker<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										placeholder="Insertar valor a pagar por el sticker..."
										min={0}
										{...register("coin", {min: 0, valueAsNumber: true})}
									/>
									{errors?.coin?.message && <span className="text-sm font-medium text-destructive">{errors?.coin.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Descripción</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Textarea placeholder="Insertar descripción del sticker..." {...register("description")} />
									{errors?.description?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.description.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Publico</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="items-top flex space-x-2">
										<Controller
											name="active"
											control={control}
											render={({field}) => (
												<Checkbox id="terms1" checked={field.value} onCheckedChange={c => field.onChange(c)} />
											)}
										></Controller>
										<div className="grid gap-1.5 leading-none">
											<label
												htmlFor="terms1"
												className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
											>
												Marcar sticker como activo.
											</label>
											<p className="text-sm text-muted-foreground">
												Al marcar el sticker como activo, se comenzara a mostrar en los chats y barras de sticker de las
												plataformas.
											</p>
										</div>
									</div>
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Web</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("url") ? (
												isImageUploading ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<StickerRenderer url={getValues("url")} />
											)}
										</div>
										<Input
											type="file"
											accept={selectedType?.value === STICKER_RENDER_TYPES.ANIMATED ? ".lottie,.json" : "image/*"}
											onChange={e => {
												const [file] = e.target.files as any;
												handleUploadImage(file, "url");
											}}
											placeholder="Elige un Archivo"
										/>
										<Button
											variant="outline"
											type="button"
											className="gap-2 whitespace-nowrap"
											onClick={() => {
												handleSelectImage("url");
											}}
										>
											<ImageIcon className="h-4 w-4" />
											Seleccionar de multimedia
										</Button>
									</div>
									{errors?.url?.message && <span className="text-sm font-medium text-destructive">{errors?.url.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt>
									<span className="text-sm font-medium leading-6 text-gray-900">Extra Imagen</span>
									<p className="mt-1 max-w-md text-sm leading-6 text-gray-500">
										Imagen extra a utilizar en el sticker, usualmente es utilizado para la animación de pantalla completa en los
										stickers animados.
									</p>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("extra_url") ? (
												isExtraImageUploading ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<StickerRenderer url={getValues("extra_url")!} />
											)}
										</div>
										<Input
											type="file"
											accept={selectedType?.value === STICKER_RENDER_TYPES.ANIMATED ? ".lottie,.json" : "image/*"}
											onChange={e => {
												const [file] = e.target.files as any;
												handleUploadImage(file, "extra_url");
											}}
											placeholder="Elige un Archivo"
										/>
										<Button
											variant="outline"
											type="button"
											className="gap-2 whitespace-nowrap"
											onClick={() => {
												handleSelectImage("extra_url");
											}}
										>
											<ImageIcon className="h-4 w-4" />
											Seleccionar de multimedia
										</Button>
									</div>
									{errors?.extra_url?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.extra_url.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								reset();
								navigate(-1);
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Crear Sticker
						</Button>
					</div>
				</form>
				<MultimediaImagePickerModal
					open={openDialog}
					onDismiss={() => {
						setOpenDialog(false);
						navigate(-1);
						dispatch(galleryActions.resetToInitial());
					}}
					acceptExtensions={
						selectedType?.value === STICKER_RENDER_TYPES.ANIMATED ? [".lottie", ".json"] : [".png", ".jpeg", ".jpg", ".gif"]
					}
					tabIndex={9}
					folderNames={FOLDERS_NAMES_STICKERS}
					onSuccess={url => {
						setValue(imageType, url);
						clearErrors(imageType);
						setOpenDialog(false);
						navigate(-1);
						dispatch(galleryActions.resetToInitial());
					}}
				/>
			</div>
		</section>
	);
}
