import {zodResolver} from "@hookform/resolvers/zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {EditSeriesDataBodySchema, EditSeriesDataBodyType, uploadSeriesImage} from "../../../../../data-access/series/series";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Input} from "../../../../../components/primitives/Input";
import {Button} from "../../../../../components/primitives/Button";
import {Spinner} from "../../../../../components/primitives/icons";
import {toast} from "react-hot-toast";
import {seriesActions} from "../../../../../_store/features/series/series-slice";
import {auth} from "../../../../../firebase";
import {Image as ImageIcon} from "../../../../../components/primitives/icons";
import MultimediaImagePickerModal from "../../../../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../../../../_store/features/gallery/gallery-slice";
import {FOLDERS_NAMES} from "../../../../../../utils/folderNames";
import {associatesActions} from "../../../../../_store/features/associates/associates-slice";
import {seriesInitialState} from "../details";

type ImageFieldsTypes = keyof Pick<
	EditSeriesDataBodyType,
	| "img_banner_web_desktop"
	| "img_banner_web_mobile"
	| "img_logo"
	| "img_square_thumbnail"
	| "img_thumbnail"
	| "horizontal_mobile_banner"
	| "horizontal_tv_banner"
>;

export default function SeriesImages() {
	let {id} = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const serieData = useAppSelector(state => state.series.results.find(el => el.id === id));
	const [openDialog, setOpenDialog] = useState(false);
	const [type, setType] = useState<ImageFieldsTypes>("img_logo");
	const {
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<EditSeriesDataBodyType>({
		resolver: zodResolver(EditSeriesDataBodySchema),
		defaultValues: seriesInitialState,
	});
	const [fieldLoading, setFieldLoading] = useState<string[]>([]);

	useEffect(() => {
		reset(serieData);
	}, [reset, serieData]);

	const onSubmit: SubmitHandler<EditSeriesDataBodyType> = data => {
		return dispatch(seriesActions.updateSerie(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Serie actualizada correctamente");
				setTimeout(() => {
					navigate("/series/list");
				}, 3000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);

		body.append("file", file);
		body.append("type", type);
		body.append("serieid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadSeriesImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	useEffect(() => {
		dispatch(
			associatesActions.getUserVinculatedSeries({
				page: 0,
				page_size: 100,
			}),
		);
	}, [dispatch]);

	return (
		<div className="px-4">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mt-6">
					<dl className="divide-y divide-border">
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Logo</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_logo") ? (
											fieldLoading.includes("img_logo") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_logo")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_logo");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_logo");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_logo?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_logo.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Thumbnail</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_thumbnail") ? (
											fieldLoading.includes("img_thumbnail") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_thumbnail")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_thumbnail");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_thumbnail");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_thumbnail?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_thumbnail.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Thumbnail Square</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_square_thumbnail") ? (
											fieldLoading.includes("img_square_thumbnail") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_square_thumbnail")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_square_thumbnail");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_square_thumbnail");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_square_thumbnail?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_square_thumbnail.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Web Desktop</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_banner_web_desktop") ? (
											fieldLoading.includes("img_banner_web_desktop") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_banner_web_desktop")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_banner_web_desktop");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_banner_web_desktop");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_banner_web_desktop?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_banner_web_desktop.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Web Mobile</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_banner_web_mobile") ? (
											fieldLoading.includes("img_banner_web_mobile") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_banner_web_mobile")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_banner_web_mobile");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_banner_web_mobile");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_banner_web_mobile?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_banner_web_mobile.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Horizontal mobile banner</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("horizontal_mobile_banner") ? (
											fieldLoading.includes("horizontal_mobile_banner") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("horizontal_mobile_banner")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>

									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "horizontal_mobile_banner");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("horizontal_mobile_banner");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.horizontal_mobile_banner?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.horizontal_mobile_banner.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">TV banner</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("horizontal_tv_banner") ? (
											fieldLoading.includes("horizontal_tv_banner") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("horizontal_tv_banner")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>

									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "horizontal_tv_banner");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("horizontal_tv_banner");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.horizontal_tv_banner?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.horizontal_tv_banner.message}</span>
								)}
							</dd>
						</div>
					</dl>
				</div>
				<div className="flex justify-end gap-2 pb-4">
					<Button
						type="button"
						variant="secondary"
						onClick={() => {
							navigate("/series/list");
							dispatch(galleryActions.resetToInitial());
						}}
					>
						Cancelar
					</Button>
					<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
						{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
						Guardar cambios
					</Button>
				</div>
			</form>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={5}
				folderNames={FOLDERS_NAMES}
				onSuccess={url => {
					setValue(type, url);
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
			/>
		</div>
	);
}
