import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createExclusiveRoomPost, getExclusiveRoomListBySerie, updatePost} from "./exclusive-room-thunks";
import {exclusiveRoomInterface} from "./types";
import {Post} from "../../../data-access/exclusive-room/types";

const initialState: exclusiveRoomInterface = {
	loading: false,
	results: [],
	activePost: null,
	isEditing: false,
};

const exclusiveRoomSlice = createSlice({
	name: "exclusive-room",
	initialState,
	reducers: {
		setActivePost: (state, action: PayloadAction<string>) => {
			const post = state.results.find(post => post.id === action.payload) || null;
			state.activePost = post;
			state.isEditing = post !== null;
		},
		clearActivePost: state => {
			state.isEditing = false;
			state.activePost = null;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getExclusiveRoomListBySerie.pending, state => {
				state.loading = true;
			})
			.addCase(getExclusiveRoomListBySerie.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload as Post[];
			})
			.addCase(getExclusiveRoomListBySerie.rejected, state => {
				state.loading = false;
			});

		builder.addCase(updatePost.fulfilled, (state, action) => {
			state.results = state.results.map(post => {
				if (post.id === action.payload.id) {
					return action.payload;
				}
				return post;
			});
		});
	},
});

export const exclusiveRoomActions = {
	...exclusiveRoomSlice.actions,
	getExclusiveRoomListBySerie,
	createExclusiveRoomPost,
	updatePost,
};

export default exclusiveRoomSlice;
