import {IconProps} from "./type";

export const Donations = (props: IconProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="h-6 w-6" {...props}>
			<path d="M16.4817 3.45729C16.2899 3.37569 16.1503 3.21249 16.1099 3.02169L15.635 0.783691L14.0264 2.51889C13.8895 2.66649 13.6807 2.74569 13.4694 2.73009L10.9424 2.54289L12.3318 4.43289C12.4635 4.61169 12.4818 4.84329 12.38 5.03769L11.3781 6.95649L13.6951 6.47049C13.7433 6.46089 13.7929 6.45489 13.8425 6.45489C14.0056 6.45489 14.1647 6.51009 14.2874 6.61329L16.046 8.08329L16.1973 5.95809C16.2129 5.74329 16.3525 5.55009 16.5626 5.45409L18.7817 4.43529L16.4804 3.45729H16.4817Z" />
			<path d="M3.5053 5.95807L3.65664 8.08327L5.41523 6.61327C5.5757 6.48007 5.79617 6.42607 6.00752 6.47047L8.32448 6.95647L7.32255 5.03647C7.22079 4.84207 7.23906 4.61047 7.37082 4.43167L8.76021 2.54167L6.23321 2.72887C6.02187 2.74447 5.81444 2.66527 5.67615 2.51647L4.06889 0.782471L3.59402 3.02047C3.55357 3.21127 3.41398 3.37447 3.22221 3.45607L0.920898 4.43527L3.14002 5.45407C3.35006 5.55007 3.48965 5.74447 3.5053 5.95927V5.95807Z" />
			<path d="M6.18474 1.938C7.29626 1.4472 8.53823 1.1712 9.85196 1.1712C11.1657 1.1712 12.4077 1.4484 13.5192 1.9392L14.3685 1.0224C13.0156 0.3708 11.4814 0 9.85196 0C8.22252 0 6.68832 0.3708 5.33545 1.0212L6.18474 1.938Z" />
			<path d="M18.7492 5.26807L17.6116 5.79007C18.093 6.77047 18.3643 7.85527 18.3643 8.99887C18.3643 13.3165 14.5458 16.8277 9.85316 16.8277C5.16052 16.8277 1.34067 13.3177 1.34067 9.00007C1.34067 7.85647 1.61202 6.77047 2.09342 5.79127L0.955813 5.26927C0.388313 6.40687 0.0673828 7.66807 0.0673828 9.00007C0.0673828 13.9633 4.45604 18.0001 9.85185 18.0001C15.2477 18.0001 19.6363 13.9633 19.6363 9.00007C19.6363 7.66807 19.3154 6.40687 18.7492 5.26807Z" />
			<path d="M14.8758 10.5037C14.2261 12.4777 12.2405 13.9225 9.8805 13.9381C7.52048 13.9549 5.51141 12.5377 4.8291 10.5733L14.8758 10.5037Z" />
		</svg>
	);
};
