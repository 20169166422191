import {Portal} from "@headlessui/react";
import {useEffect, useMemo, useState} from "react";
import {Close} from "../../../../../../components/primitives/icons";
import {DayPicker} from "../../../../../../components/blocks/DatePicker";
import Select, {SingleValue} from "react-select";
import {subscriptionInterface} from "../../../../../../_store/features/subscriptions/subscriptions-slice";
import {UserSubscription} from "../../../../../../data-access/subscriptions/subscriptions";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../../../../components/primitives/Dialog";
import {useParams} from "react-router-dom";
import {addMonths, addYears} from "date-fns";
import {Button} from "../../../../../../components/primitives/Button";
import {Checkbox} from "../../../../../../components/primitives/Checkbox";

export type ExtendedUserSubscription = UserSubscription & {isNew?: boolean; userid?: string; productid?: string};

interface EditSubscriptionModalProps {
	onDismiss: () => void;
	onSubmit: (data: ExtendedUserSubscription) => void;
	open: boolean;
	isNew: boolean;
	subscription: ExtendedUserSubscription;
	activeSubscriptions: subscriptionInterface;
	loadingSubmit?: boolean;
}

const today = new Date();

export const subscriptionsInitialState: ExtendedUserSubscription = {
	isNew: true,
	active: false,
	termination_date: addMonths(today, 1).toISOString(),
	subscription_renewal: false,
	subscription_type: 0,
	last_payment_platform: "admin",
	id: "",
	name: "",
	productid: "",
	userid: "",
};

export function EditSubscriptionModal({
	open,
	isNew,
	subscription,
	activeSubscriptions,
	onDismiss,
	onSubmit,
	loadingSubmit = false,
}: EditSubscriptionModalProps) {
	const {id: userid} = useParams();

	const [data, setData] = useState<ExtendedUserSubscription>(
		isNew ? {...subscriptionsInitialState, userid: userid as string, isNew} : {...subscription, isNew},
	);
	const [error, setError] = useState("");

	useEffect(() => {
		if (!subscription || isNew) return;
		setData({...subscription});

		return () => {
			resetStates();
		};
	}, [isNew, subscription, open]);

	const handleSelectTerminationDate = (date: Date | undefined) => {
		const dateFormatted = date?.toISOString() ?? "";

		setData(prev => ({
			...prev,
			termination_date: dateFormatted,
		}));
	};

	const handleTypeChange = (newValue: SingleValue<{label: string; value: number}>) => {
		if (!newValue) return;

		setData(prev => ({
			...prev,
			termination_date: newValue.value === 0 ? addMonths(today, 1).toISOString() : addYears(today, 1).toISOString(),
			subscription_type: newValue.value,
		}));
	};

	const subscriptionsOptions = useMemo(() => {
		return activeSubscriptions.results.map(el => ({
			label: el.name,
			value: el.subscription_type,
		}));
	}, [activeSubscriptions.results]);

	const resetStates = () => {
		setData(subscriptionsInitialState);
		setError("");
	};

	const handleDismiss = () => {
		resetStates();
		onDismiss();
	};

	const handleSubmit = () => {
		if (!data) return;
		onSubmit(data);
		resetStates();
	};

	return (
		<Dialog open={open} onOpenChange={handleDismiss}>
			<DialogContent className="bg-neutral-700">
				<DialogHeader>
					<DialogTitle className="border-b border-border pb-2 font-medium text-white">{"Editar Suscripción"}</DialogTitle>
				</DialogHeader>
				{error && (
					<Portal>
						<div className="items fixed right-0 top-0 z-[700] mx-8 mt-4 flex items-center justify-between rounded bg-red-700 px-6 py-3 text-white shadow">
							{error}
							<button className="ml-2" onClick={() => setError("")} title="Cerrar">
								<Close />
							</button>
						</div>
					</Portal>
				)}
				<div className="container space-y-5 px-0.5 py-5">
					<div className="mb-3 flex w-full items-center">
						<div className="mr-3 flex w-full flex-row justify-between">
							<label className="self-center text-white" htmlFor="exampleFormControlInput1">
								Tipo *
							</label>
							<Select
								options={subscriptionsOptions}
								onChange={handleTypeChange}
								defaultValue={subscriptionsOptions.find(el => el.value === data.subscription_type)}
								placeholder="Selecciona una suscripción"
								isLoading={activeSubscriptions.loading}
							/>
						</div>
					</div>

					<div className="mb-3 flex w-full items-center">
						<div className="mr-3 flex w-full flex-row justify-between">
							<label className="self-center text-white" htmlFor="exampleFormControlInput1">
								Fecha de terminación *
							</label>
							<DayPicker
								selectedDay={new Date(data.termination_date)}
								onDayChange={handleSelectTerminationDate}
								className="min-w-[150px] justify-start self-start"
								color="white"
								disabled={loadingSubmit}
							/>
						</div>
					</div>

					<div className="flex max-h-10 w-56 flex-row items-center space-x-3 space-y-0 rounded-md border p-3 text-white">
						<Checkbox checked={data.active} onCheckedChange={checked => setData(prev => ({...prev, active: checked as boolean}))} />
						<div className="space-y-1 leading-none">
							<div className="text-sm">Suscripción activa</div>
						</div>
					</div>

					<div className="flex max-h-10 w-56 flex-row items-center space-x-3 space-y-0 rounded-md border p-3 text-white">
						<Checkbox
							checked={data.subscription_renewal}
							onCheckedChange={checked => setData(prev => ({...prev, subscription_renewal: checked as boolean}))}
						/>
						<div className="space-y-1 leading-none">
							<div className="text-sm">Autorrenovación activa</div>
						</div>
					</div>
				</div>

				<div className="flex flex-row justify-end">
					<Button className="mr-2 w-32 text-white" size="sm" variant="outline" onClick={handleDismiss}>
						Cancelar
					</Button>
					<Button className="w-32 bg-black text-white" size="sm" variant="outline" onClick={handleSubmit} disabled={loadingSubmit}>
						Completar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
