import {useMemo} from "react";
import {selectedPartnerSeries} from "../../../../../_store/features/dashboard/dashboard-slice";
import {useAppSelector} from "../../../../../_store/hooks";
import {Camera, Film, Report} from "../../../../primitives/icons";
import NavLink from "../../subcomponents/NavLink";

export function PartnerNavigationSection() {
	const series = useAppSelector(selectedPartnerSeries);
	const existsRentalContent = useMemo(() => series?.some(s => !s.pronyr_original), [series]);

	// TO-DO Temporary disabled incrementing lenght condition until the PartnerDashboard is approved
	return (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			{series && series?.length > 10000 ? (
				<>
					<NavLink to="/partner/series" label="Series" icon={<Film />} />
					<NavLink to="/partner/contents" label="Contenidos" icon={<Camera />} />
				</>
			) : null}
			{series && existsRentalContent && series?.length > 1 ? <NavLink to="/partner/products" label="Audiovisuales" icon={<Film />} /> : null}
			<NavLink to="/partner/reports" label="Reportes" icon={<Report />} />
		</div>
	);
}
