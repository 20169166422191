import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	createSubjectChat,
	CreateSubjectChatBodyRequest,
	EditFormBodyType,
	editFormData,
	getAllChatSubjects,
	GetAllChatSubjectsParams,
	getChatsBySubjectId,
	GetChatsBySubjectIdParams,
	GetSubjectChatsParams,
	getUserSubjectChats,
	Subjects,
	updateChatStatus,
	UpdateChatStatusBodyRequest,
} from "../../../data-access/fetch-form-data";
import {auth} from "../../../firebase";

export interface FormDataResult {
	id: string;
	name: null | string;
	email: string;
	phone: string;
	message: string;
	audit_created: string;
	audit_updated: null | string;
	sponsor: boolean;
	customer_service_note: null | string;
	status: number;
	desired_response: null | string;
}

export const editInitialState: FormDataResult = {
	id: "",
	name: null,
	email: "",
	phone: "",
	message: "",
	audit_created: "",
	audit_updated: null,
	sponsor: false,
	customer_service_note: null,
	status: 0,
	desired_response: null,
};

export interface DBForms {
	loading: boolean;
	editing: boolean;
	error: string | undefined;
	success: boolean;
	totalResults: number;
	pageSize: number;
	current: number;
	results: FormDataResult[] | Subjects[];
}

const initialState: DBForms = {
	loading: false,
	editing: false,
	error: undefined,
	success: false,
	totalResults: 0,
	pageSize: 0,
	current: 0,
	results: [],
};

const updateFormData = createAsyncThunk("contact/edit", async (data: EditFormBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editFormData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getAllChatSubjectsAction = createAsyncThunk("customer-service/get-all-subjects", async (params: GetAllChatSubjectsParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await getAllChatSubjects(token, params);

		return res.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching subjects");
	}
});

const getUserSubjectChatsAction = createAsyncThunk("customer-service/get-user-subject-chats", async (params: GetSubjectChatsParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await getUserSubjectChats(token, params);

		return res.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching subject chats");
	}
});

const createSubjectChatAction = createAsyncThunk(
	"customer-service/create-subject-chat-response",
	async (data: CreateSubjectChatBodyRequest, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;
			const res = await createSubjectChat(token, data);

			return res.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem creating subject chat response");
		}
	},
);

const getChatsBySubjectIdAction = createAsyncThunk("customer-service/get-chat-by-subject-id", async (params: GetChatsBySubjectIdParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;
		const res = await getChatsBySubjectId(token, params);

		return res.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching chats");
	}
});

const updateChatStatusAction = createAsyncThunk("customer-service/update-chat-status", async (data: UpdateChatStatusBodyRequest, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;
		const res = await updateChatStatus(token, data);

		return res.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem updating chat");
	}
});

const customerServiceSlice = createSlice({
	name: "dbuser",
	initialState,
	reducers: {
		loadFormData(state, action: PayloadAction<DBForms>) {
			state.totalResults = action.payload.totalResults;
			state.pageSize = action.payload.pageSize;
			state.current = action.payload.current;
			state.results = action.payload.results;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			if (state.loading !== action.payload) state.loading = action.payload;
		},
		setEditing(state, action: PayloadAction<boolean>) {
			if (state.editing !== action.payload) state.editing = action.payload;
		},
		setSuccess(state, action: PayloadAction<boolean>) {
			if (state.success !== action.payload) state.success = action.payload;
		},
		setError(state, action: PayloadAction<string | undefined>) {
			if (state.error !== action.payload) state.error = action.payload;
		},
		resetState() {
			return {...initialState};
		},
	},
	extraReducers(builder) {
		builder
			.addCase(updateFormData.pending, state => {
				state.loading = true;
			})
			.addCase(updateFormData.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				}) as FormDataResult[];
			})
			.addCase(updateFormData.rejected, (state, action) => {});
		builder
			.addCase(getAllChatSubjectsAction.pending, state => {
				state.loading = true;
			})
			.addCase(getAllChatSubjectsAction.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.totalResults = action.payload?.totalResults ?? 0;
				state.pageSize = action.payload?.pageSize ?? 0;
				state.results = action.payload?.results ?? [];
			})
			.addCase(getAllChatSubjectsAction.rejected, () => ({...initialState}));
		builder
			.addCase(getUserSubjectChatsAction.pending, state => {
				state.loading = true;
			})
			.addCase(getUserSubjectChatsAction.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.totalResults = action.payload?.totalResults ?? 0;
				state.pageSize = action.payload?.pageSize ?? 0;
				state.results = action.payload?.results ?? [];
			})
			.addCase(getUserSubjectChatsAction.rejected, () => ({...initialState}));
	},
});

export const formDbActions = {
	...customerServiceSlice.actions,
	updateFormData,
	getAllChatSubjectsAction,
	getUserSubjectChatsAction,
	createSubjectChatAction,
	getChatsBySubjectIdAction,
	updateChatStatusAction,
};

export default customerServiceSlice;
