import {ChangeEvent, useRef, useState} from "react";

interface FileUploadProps {
	name?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	accept?: string;
	title?: string;
	dark?: boolean;
}
export function FileUpload({name, accept, onChange, title = "Seleccionar Foto", dark = false}: FileUploadProps) {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [fileName, setFileName] = useState("");

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		setFileName(e.target.files[0].name);
		onChange?.(e);
	};

	return (
		<div className="flex items-center overflow-hidden whitespace-nowrap">
			<input
				type="file"
				name={name}
				className="h-0 w-0 overflow-hidden opacity-0"
				ref={fileInputRef}
				onChange={handleFileChange}
				accept={accept}
				title="File upload"
			/>
			<button
				type="button"
				className={`rounded border-2 px-2 py-1 text-sm font-bold transition-all duration-300 ${
					dark ? "bg-foreground text-background hover:bg-background hover:text-foreground" : "hover:bg-foreground hover:text-background"
				}`}
				onClick={() => {
					if (!fileInputRef.current) return;
					fileInputRef.current.click();
				}}
			>
				{title}
			</button>
			<span className={`ml-5 overflow-hidden text-ellipsis ${dark ? "text-white" : "text-foreground"} whitespace-nowrap`}>
				{!fileName ? "Archivo no seleccionado" : fileName}
			</span>
		</div>
	);
}
