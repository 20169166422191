import {Button} from "../../../../../../components/primitives/Button";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../../../../components/primitives/Sheet";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useAppDispatch, useAppSelector} from "../../../../../../_store/hooks";
import {RentalBody, RentalBodySchema} from "../../../../../../data-access/series/user-rentals";
import {useParams} from "react-router-dom";
import {Switch} from "../../../../../../components/primitives/Switch";
import {DayPicker} from "../../../../../../components/blocks/DatePicker";
import {format, parseISO, toDate} from "date-fns";
import {rentalActions} from "../../../../../../_store/features/rentals/slice";
import toast from "react-hot-toast";
import {useState} from "react";

interface CreateSheetProps extends SheetProps {}

export default function EditRentalSheet(props: CreateSheetProps) {
	const {...rest} = props;
	const {id} = useParams<{id: string}>();
	const dispatch = useAppDispatch();
	const currentRental = useAppSelector(state => state.rentals.currentRental);
	const [editing, setEditing] = useState(false);
	const {
		handleSubmit,
		setValue,
		control,
		formState: {errors},
	} = useForm<RentalBody>({
		resolver: zodResolver(RentalBodySchema),
		defaultValues: {
			active: currentRental?.active || false,
			first_use_date: currentRental?.first_use_date || null,
			termination_date: currentRental?.termination_date || null,
			series_rentalid: currentRental?.series_rentalid || "",
			userid: id,
		},
	});

	const handleInsertReply: SubmitHandler<RentalBody> = async data => {
		setEditing(true);
		const res = await dispatch(rentalActions.updateRental(data));
		if (res.meta.requestStatus === "fulfilled") {
			toast.success("Renta actualizada correctamente.", {position: "bottom-left"});
			dispatch(rentalActions.clearCurrentRental());
		}
		if (res.meta.requestStatus === "rejected") {
			toast.error("Error al modificar la renta.", {position: "bottom-left"});
		}
		setEditing(false);
	};

	return (
		<Sheet {...rest}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>Editar Renta</SheetTitle>
					<SheetDescription>Editar un producto de renta a un usuario</SheetDescription>
				</SheetHeader>

				{JSON.stringify(errors)}

				<div>
					<span className="font-bold">Fecha de creación: </span>
					<span>{!!currentRental?.created_at ? format(parseISO(currentRental?.created_at! + "Z"), "dd/MM/yy hh:mm bb") : "-"}</span>
				</div>

				<div>
					<span className="font-bold">Descripción: </span>
					<span>{currentRental?.rental_description || "-"}</span>
				</div>

				<div>
					<span className="font-bold">Producto: </span>
					<span>{currentRental?.series_title || "-"}</span>
				</div>

				<form onSubmit={handleSubmit(handleInsertReply)} className="flex flex-col space-y-4">
					<div>
						<label className="mb-2 block text-sm font-medium text-gray-600">Activa:</label>
						<Controller
							name="active"
							control={control}
							rules={{required: true}}
							render={({field}) => <Switch checked={field.value} onCheckedChange={c => field.onChange(c)} />}
						/>
					</div>

					<div>
						<label className="mb-2 block text-sm font-medium text-gray-600">Fecha de primer Uso:</label>

						<Controller
							name="first_use_date"
							control={control}
							rules={{required: true}}
							render={({field}) => (
								<DayPicker
									placeholder="Fecha"
									id="first_use_date"
									onDayChange={date => {
										const dateFormatted = date?.toISOString() ?? null;
										setValue("first_use_date", dateFormatted);
									}}
									selectedDay={field?.value ? toDate(Date.parse(field?.value)) : undefined}
									className="justify-start self-start"
									formatDate="dd/MM/yyyy"
									autoConfirm
									clearable
								/>
							)}
						/>
					</div>

					<div>
						<label className="mb-2 block text-sm font-medium text-gray-600">Fecha de terminación:</label>
						<Controller
							name="termination_date"
							control={control}
							render={({field}) => (
								<DayPicker
									placeholder="Fecha"
									id="termination_date"
									onDayChange={date => {
										const dateFormatted = date?.toISOString() ?? null;
										setValue("termination_date", dateFormatted);
									}}
									selectedDay={field?.value ? toDate(Date.parse(field?.value)) : undefined}
									className="justify-start self-start"
									formatDate="dd/MM/yyyy"
									autoConfirm
									clearable
								/>
							)}
						/>
					</div>

					<Button type="submit" className="mt-4" disabled={editing}>
						Editar
					</Button>
				</form>
			</SheetContent>
		</Sheet>
	);
}
