import {useNavigate} from "react-router-dom";
import {Button} from "../../components/primitives/Button";
import {ArrowLeftLong} from "../../components/primitives/icons";
import {InformationCircle} from "../../components/primitives/icons/InformationCircle";

export function NotFoundApp() {
	const navigate = useNavigate();

	return (
		<section className="bg-card">
			<div className="container mx-auto flex min-h-screen items-center px-6 py-12">
				<div className="mx-auto flex max-w-lg flex-col items-center text-center">
					<p className="mb-4 rounded-full bg-card-foreground p-3 text-sm font-medium text-secondary">
						<InformationCircle />
					</p>
					<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">Esta pagina no existe.</h1>
					<p className="leading-7 [&:not(:first-child)]:mt-6">La página que buscas no existe. Aquí hay algunos enlaces útiles:</p>
					<div className="mt-6 flex w-full shrink-0 items-center gap-x-3 sm:w-auto">
						<Button
							variant="outline"
							size="lg"
							className="gap-2"
							onClick={() => {
								navigate(-1);
							}}
						>
							<ArrowLeftLong />
							<span>Atrás</span>
						</Button>
						<Button
							size="lg"
							onClick={() => {
								navigate("/");
							}}
						>
							Ir al Dashboard
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
}
