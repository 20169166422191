import {Outlet} from "react-router";
import {useAppSelector} from "../../_store/hooks";
import Navigation from "../../components/blocks/Navigation";
import {Spinner} from "../../components/primitives/icons";

function Layout() {
	const appLoading = useAppSelector(state => state.appStatus.loading);

	return (
		<main className="ml-64">
			<Navigation />
			<div>
				{appLoading ? (
					<div className="flex h-screen w-full items-center justify-center">
						<Spinner />
					</div>
				) : (
					<Outlet />
				)}
			</div>
		</main>
	);
}

export default Layout;
