import {SubmitHandler, useForm} from "react-hook-form";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../../components/primitives/Sheet";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {Input} from "../../../../components/primitives/Input";
import {upsertGlobalSetting} from "../../../../data-access/settings";
import {auth} from "../../../../firebase";
import toast from "react-hot-toast";
import {useEffect} from "react";
import {Textarea} from "../../../../components/primitives/Textarea";

interface CreateSheetProps extends SheetProps {
	defaultValues?: NewItemType | null;
	onUpdate: () => void;
}

const NewItemSheetSchema = z.object({
	key: z.string().min(1, "La propiedad es requerida"),
	value: z.string().min(1, "El valor es requerido"),
});

type NewItemType = z.infer<typeof NewItemSheetSchema>;

const defaultValues: NewItemType = {
	key: "",
	value: "",
};

const NewItemSheet = (props: CreateSheetProps) => {
	const {onUpdate, ...rest} = props;

	const {
		register,
		handleSubmit,
		reset,
		formState: {isLoading, errors, isSubmitting},
	} = useForm<NewItemType>({
		resolver: zodResolver(NewItemSheetSchema),
		defaultValues: props.defaultValues || defaultValues,
	});

	useEffect(() => {
		reset(props.defaultValues || defaultValues);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.defaultValues]);

	const onSubmit: SubmitHandler<NewItemType> = async data => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token) {
				toast.error("No se pudo obtener el token de autenticación");
			}

			await upsertGlobalSetting(token!, data.key, data.value);
			reset();
			onUpdate();
		} catch (error) {
			toast.error("Ocurrió un error al guardar el item");
		}
	};

	const title = props.defaultValues ? "Editar item" : "Agregar item";
	const description = props.defaultValues
		? "Edita un item de la lista de opciones globales."
		: "Agrega un nuevo item a la lista de opciones globales.";

	return (
		<Sheet {...rest}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>{title} </SheetTitle>
					<SheetDescription>{description}</SheetDescription>
				</SheetHeader>

				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-3">
					<div>
						<label htmlFor="key" className="block text-sm font-medium text-gray-700">
							Propiedad
						</label>
						<Input
							type="text"
							{...register("key")}
							id="key"
							className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
						/>
						{errors.key && <span className="text-xs text-red-500">{errors.key.message}</span>}
					</div>

					<div>
						<label htmlFor="value" className="block text-sm font-medium text-gray-700">
							Valor
						</label>
						<Textarea
							{...register("value")}
							id="value"
							className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
						/>
						{errors.value && <span className="text-xs text-red-500">{errors.value.message}</span>}
					</div>

					<button
						type="submit"
						className="hover:bg-primary-dark inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
					>
						{isLoading || isSubmitting ? "Guardando..." : "Guardar"}
					</button>
				</form>
			</SheetContent>
		</Sheet>
	);
};

export default NewItemSheet;
