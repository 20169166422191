import {addDays, isSameDay, isSameMonth} from "date-fns";
type chartData = {date: string; views: number};

export const oneMonth = 2678400000;

export const transformedData = (daysArray: chartData[], viewData: chartData[], showByMonth: boolean, monthArray: chartData[]) => {
	const dataGrouped = viewData
		.reduce((acc: {date: string; views: number}[], curr) => {
			const existingDayData = acc.find(el => isSameDay(new Date(el.date), new Date(curr.date)));
			if (existingDayData) {
				existingDayData.views += curr.views;
			} else {
				acc.push(curr);
			}
			return acc;
		}, [])
		.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

	const dailyData = daysArray
		.map(fillEl => {
			let item = dataGrouped.find(statEl => isSameDay(new Date(fillEl.date), new Date(statEl.date)));
			const formattedDate = new Date(fillEl.date);
			if (showByMonth) {
				return {
					views: item?.views ?? fillEl.views,
					date: new Date(formattedDate.getFullYear(), formattedDate.getMonth() + 1, formattedDate.getDay()).toISOString(),
				};
			}
			return {views: item?.views ?? fillEl.views, date: fillEl.date.toString()};
		})
		.reverse();

	const monthlyData = monthArray
		.map(fillEl => {
			const el = dataGrouped.find(statEl => isSameMonth(new Date(fillEl.date), new Date(statEl.date)));
			if (el) return {...el, date: fillEl.date};

			return fillEl;
		})
		.reverse();

	if (showByMonth) {
		return monthlyData;
	}
	return dailyData;
};

export function groupArrayByDay(array: chartData[]): chartData[] {
	return array.reduce((acc: {date: string; views: number}[], curr) => {
		const existingDayData = acc.find(el => isSameDay(new Date(el.date), new Date(curr.date)));
		if (existingDayData) {
			existingDayData.views += curr.views;
		} else {
			acc.push(curr);
		}
		return acc;
	}, []);
}

export function combineData(fillerData: chartData[], dailyLikes: chartData[]) {
	return fillerData
		.map(fillEl => {
			const el = dailyLikes.find(statEl => isSameDay(new Date(fillEl.date), new Date(statEl.date)));
			if (el) return {...el, date: fillEl.date};

			return fillEl;
		})
		.reverse();
}

export const getGroupedByDay = (data: {date: string; profit: number}[]) =>
	data.reduce((acc: {date: string; profit: number}[], curr) => {
		const existingDayData = acc.find(el => isSameDay(new Date(el.date), new Date(curr.date)));
		if (existingDayData) {
			existingDayData.profit += curr.profit;
		} else {
			acc.push(curr);
		}
		return acc;
	}, []);

export const getChartData = (data: {date: string; profit: number}[], diffDays: number, endDate: Date) => {
	const fillerData = Array(diffDays)
		.fill(0)
		.map((_, idx) => ({date: addDays(endDate, -idx).toISOString(), profit: 0}));

	return fillerData
		.map(fillEl => {
			const el = data.find(statEl => isSameDay(new Date(fillEl.date), new Date(statEl.date)));
			if (el) return {...el, date: fillEl.date};
			return fillEl;
		})
		.reverse();
};

interface MonthlyData {
	[key: string]: {
		date: string;
		profit: number;
	};
}

export const getMonthlyProfitData = (data: {date: string; profit: number}[]) =>
	data.reduce((acc: MonthlyData, dataPoint) => {
		const date = new Date(dataPoint.date);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const monthString = month < 10 ? `0${month}` : `${month}`;
		const key = `${year}-${monthString}`;

		if (!acc[key]) {
			acc[key] = {
				date: `${year}-${monthString}`,
				profit: 0,
			};
		}

		acc[key].profit += dataPoint.profit;

		return acc;
	}, {});

interface dataInterface {
	date: string;
	views: number;
}

export const getViewsChartData = (dataArray: dataInterface[], groupedArray: dataInterface[], showMonthly?: boolean) => {
	return dataArray
		.map(fillEl => {
			const el = groupedArray.find(statEl => {
				return showMonthly
					? isSameMonth(new Date(fillEl.date), new Date(statEl.date))
					: isSameDay(new Date(fillEl.date), new Date(statEl.date));
			});

			if (el) return {...el, date: fillEl.date};

			return fillEl;
		})
		.reverse();
};
