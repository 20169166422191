import {User, Comment} from "../../../../primitives/icons";
import NavGroup from "../../subcomponents/NavGroup";
import NavLink from "../../subcomponents/NavLink";

export function CustomerServiceNavigationSection() {
	return (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			<NavGroup title="Comentarios" route="/comments" icon={<Comment />}>
				<NavLink to="/comments/list" label="Listar Comentarios" />
				<NavLink to="/comments/reported" label="Reportados" />
			</NavGroup>
			<NavGroup title="Usuarios" route="/users" icon={<User />}>
				<NavLink to="/users/list" label="Listar Usuarios" />
				<NavLink to="/customer-service/user-chat" label="Chat de Usuario" />
				<NavLink to="/users/internals/list" label="Listar Usuarios Internos" />
			</NavGroup>
		</div>
	);
}
