import {Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {Data} from "../../../../_store/features/feedback/types";
import {useMemo} from "react";
import {TooltipEvents} from "./tooltip-events";

const backgroundColor = ["rgba(220, 38, 38, 0.8)", "rgba(79, 70, 229, 0.8)", "rgba(22, 163, 74, 0.8)"];

const hoverBackgroundColor = ["rgba(220, 38, 38, 1)", "rgba(79, 70, 229, 1)", "rgba(22, 163, 74, 1)"];

interface DevicesProps {
	data: Data | undefined;
}

export function DevicesRating({data}: DevicesProps) {
	const prepareData = useMemo(() => {
		if (!data) return {ios: 0, percent_ios: 0, android: 0, percent_android: 0, web: 0, percent_web: 0};

		const total = data.currentRange.devices.ios + data.currentRange.devices.android + data.currentRange.devices.web;

		return {
			ios: data.currentRange.devices.ios,
			percent_ios: total > 0 ? Math.round((data.currentRange.devices.ios / total) * 100) : 0,
			android: data.currentRange.devices.android,
			percent_android: total > 0 ? Math.round((data.currentRange.devices.android / total) * 100) : 0,
			web: data.currentRange.devices.web,
			percent_web: total > 0 ? Math.round((data.currentRange.devices.web / total) * 100) : 0,
		};
	}, [data]);

	return (
		<div className="shadow-xs flex-col items-center justify-center rounded-lg border border-gray-200 bg-white">
			<div className="flex items-center justify-between border border-gray-200 p-2 font-bold">
				<span>Interacciones por dispositivos</span>
				<TooltipEvents />
			</div>
			<div className="grid grid-cols-1 items-center gap-4 px-2 py-6 min-[1000px]:grid-cols-2">
				<div className="col-span-1 px-2">
					<DeviceChart {...prepareData} />
				</div>

				<div>
					<div className="flex items-center space-x-2 text-sm">
						<div className="h-2 w-2 rounded-full bg-red-600" />
						<span className="font-black">{prepareData.percent_ios}%</span>
						<span className="text-xs text-gray-500">IOS</span>
					</div>

					<div className="flex items-center space-x-2 text-sm">
						<div className="h-2 w-2 rounded-full bg-indigo-600" />
						<span className="font-black">{prepareData.percent_android}%</span>
						<span className="text-xs text-gray-500">ANDROID</span>
					</div>

					<div className="flex items-center space-x-2 text-sm">
						<div className="h-2 w-2 rounded-full bg-green-600" />
						<span className="font-black">{prepareData.percent_web}%</span>
						<span className="text-xs text-gray-500">WEB</span>
					</div>
				</div>
			</div>
		</div>
	);
}

ChartJS.register(ArcElement, Tooltip, Legend);

interface DeviceChartProps {
	ios: number;
	android: number;
	web: number;
}

const options: ChartOptions<"doughnut"> = {
	plugins: {
		tooltip: {
			enabled: true,
		},
		legend: {
			display: false,
		},
	},
	backgroundColor: "transparent",
	color: "transparent",
	cutout: "75%",
};

const DeviceChart = ({ios, android, web}: DeviceChartProps) => {
	const data = useMemo(
		() => ({
			labels: ["iOS", "Android", "Web"],
			datasets: [
				{
					data: [ios, android, web],
					backgroundColor,
					hoverBackgroundColor,
					borderWidth: 1,
				},
			],
		}),
		[ios, android, web],
	);

	// Chart options

	return (
		<div className="mx-auto h-full lg:w-[80px] xl:w-[100px] 2xl:w-[120px]">
			<div className="relative aspect-square ">
				<Doughnut data={data} options={options} />
				<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-center text-4xl font-bold lg:text-xl">
					<span>{ios + android + web}</span>
					<div className="text-[10px] leading-none text-gray-400">Interacciones</div>
				</div>
			</div>
		</div>
	);
};

export default DeviceChart;
