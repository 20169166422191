import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	CoinProduct,
	EditCoinProductBody,
	editCoinProductData,
	GetCoinProductListParams,
	getCoinProductsList,
	InsertCoinProductBody,
	insertCoinProductData,
} from "../../../data-access/products/coin-products";
import {InsertCoinProductStripeBody, insertCoinProductStripeData} from "../../../data-access/products/coin-stripe";
import {InsertCoinProductGoogleBody, insertCoinProductGoogleData} from "../../../data-access/products/coin-google";

export interface coinProductsInterface {
	loading: boolean;
	results: CoinProduct[];
	totalResults: number;
	current: number;
	pageSize: number;
	success: boolean;
	error: string;
}

const initialState: coinProductsInterface = {
	loading: false,
	results: [],
	totalResults: 0,
	current: 0,
	pageSize: 0,
	success: false,
	error: "",
};

/*GENERAL*/
const getCoinProducts = createAsyncThunk("coinProducts/coin-list", async (params: GetCoinProductListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getCoinProductsList(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});
const insertCoinProducts = createAsyncThunk("coinProducts/insert", async (data: InsertCoinProductBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await insertCoinProductData(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem inserting coin product, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});
const editCoinProducts = createAsyncThunk("coinProducts/edit", async (data: EditCoinProductBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editCoinProductData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

/*STRIPE*/
const insertCoinStripeProducts = createAsyncThunk("coinProducts/stripe-insert", async (data: InsertCoinProductStripeBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;
		const res = await insertCoinProductStripeData(token, data);
		if (!res.data) return thunkAPI.rejectWithValue("Problem sincro stripe product, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

/*GOOGLE*/
const insertCoinGoogleProducts = createAsyncThunk("coinProducts/google-insert", async (data: InsertCoinProductGoogleBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;
		const res = await insertCoinProductGoogleData(token, data);
		if (!res.data) return thunkAPI.rejectWithValue("Problem sincro google product, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const coinProductsSlice = createSlice({
	name: "coinProducts",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getCoinProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(getCoinProducts.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getCoinProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(insertCoinProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(insertCoinProducts.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertCoinProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(editCoinProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(editCoinProducts.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editCoinProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(insertCoinStripeProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(insertCoinStripeProducts.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertCoinStripeProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(insertCoinGoogleProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(insertCoinGoogleProducts.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertCoinGoogleProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});
export const coinProductsActions = {
	...coinProductsSlice.actions,
	getCoinProducts,
	editCoinProducts,
	insertCoinProducts,
	insertCoinStripeProducts,
	insertCoinGoogleProducts,
};

export const activeCoinProducts = createSelector(
	(state: {coinProducts: coinProductsInterface}) => state.coinProducts,
	state => state.results.filter(el => el.active).sort((a, b) => a.coins - b.coins),
);

export default coinProductsSlice;
