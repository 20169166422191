import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../primitives/Dialog";
import {Button} from "../../primitives/Button";
import {Spinner} from "../../primitives/icons";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {notificationsActions} from "../../../_store/features/notify/notify-slice";
import toast from "react-hot-toast";
import {useEffect, useMemo, useRef, useState} from "react";
import {
	CustomNotification,
	NotificationSettings,
	NotificationSettingsListResult,
	SendCustomNotificationBody,
} from "../../../data-access/notifications/notifications";
import Select, {SingleValue} from "react-select";
import {PRESET_NOTIFICATIONS_TYPES} from "../../../constants/notifications";

interface SendCustomNotificationModalProps {
	action?: () => void;
	open: boolean;
	forcedType?: string;
	messageData?: {name: string; id: string};
	modalTitle?: string;
	modalMessage?: string;
}

export default function SendCustomNotificationModal({open, forcedType, messageData, action}: SendCustomNotificationModalProps) {
	const dispatch = useAppDispatch();
	const {results} = useAppSelector(state => state.notifications);
	const [loadingState, setLoadingStates] = useState({
		submit: false,
		types: false,
	});
	const [notificationSettings, setNotificationSettings] = useState<NotificationSettings[]>([]);
	const [selectedNofificationData, setSelectedNofificationData] = useState<SendCustomNotificationBody>({
		type: "",
		name: undefined,
		number: undefined,
	});
	const selectInputRefs = useRef<any[]>([]);

	const fetchNotifications = () => dispatch(notificationsActions.getAllCustomNotifications(undefined));
	const fetchNotificationsSettings = () => {
		dispatch(notificationsActions.getAllNotificationsSettings())
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") return;
				setNotificationSettings((res.payload as NotificationSettingsListResult).results);
			})
			.catch(err => console.log(err));
	};

	useEffect(() => {
		if (forcedType) setSelectedNofificationData(prev => ({...prev, type: forcedType}));
		fetchNotificationsSettings();
		fetchNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forcedType]);

	const clearSelectValues = () => {
		selectInputRefs.current.forEach(item => item.clearValue());
		setSelectedNofificationData(prev => ({...prev, type: forcedType ?? "", number: undefined}));
	};

	const handleSendNotification = () => {
		if (!selectedNofificationData.type || selectedNofificationData.type.trim() === "") {
			return toast.error("Por favor seleccione el tipo de notificación");
		}
		setLoadingStates(prev => ({...prev, submit: true}));
		dispatch(
			notificationsActions.sendCustomNotifications({
				type: selectedNofificationData.type,
				name: messageData?.name,
				number: selectedNofificationData.number,
				content_id: messageData?.id,
			}),
		)
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") {
					toast.error("Error al enviar la notificación");
					return;
				}
				toast.success("Notificación enviada correctamente");
			})
			.catch(err => {
				console.log(err);
				toast.error("Error al enviar la notificación");
			})
			.finally(() => {
				action?.();
				setLoadingStates(prev => ({...prev, submit: false}));
				clearSelectValues();
			});
	};

	const notificationTypesOptions: {label: string; value: string}[] = useMemo(() => {
		if (!notificationSettings) return [];
		const notificationsWithPresetLabels = notificationSettings.map(item => {
			const preset = PRESET_NOTIFICATIONS_TYPES.find(el => el.value === item.notification_type);
			return {value: item.notification_type, label: preset?.label ?? item.notification_type};
		});

		return notificationsWithPresetLabels;
	}, [notificationSettings]);

	const handleSelectType = (
		newValue: SingleValue<{
			value: string;
			label: string;
		}>,
	) => {
		if (!newValue?.value) return;
		setSelectedNofificationData(prev => ({...prev, type: newValue.value}));
	};

	const messagesOptions = useMemo(() => {
		const selectedType = (results as CustomNotification[]).find(item => item.type === selectedNofificationData.type);
		if (!selectedType) return [];
		return selectedType.text.map(item => ({value: item, label: item}));
	}, [results, selectedNofificationData.type]);

	const handleSelectMessage = (
		newValue: SingleValue<{
			value: string;
			label: string;
		}>,
	) => {
		if (!newValue?.value) return;
		const selectedType = (results as CustomNotification[]).find(item => item.type === selectedNofificationData.type);
		const idx = selectedType?.text.findIndex(item => item === newValue.value);
		setSelectedNofificationData(prev => ({...prev, number: idx}));
	};

	const handleDismiss = () => {
		action?.();
		clearSelectValues();
	};

	return (
		<Dialog open={open} onOpenChange={handleDismiss}>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="font-medium text-white">Enviar notificación</DialogTitle>
				</DialogHeader>
				<div className="flex flex-col px-2 py-5 text-white">¿Desea notificar a los usuarios sobre el estreno del short?</div>
				<div className="flex items-center">
					<div className="flex flex-col whitespace-nowrap px-2 py-5 text-white">Seleccione el tipo de notificación: </div>
					<div className="flex w-full justify-around">
						<Select
							ref={ref => {
								selectInputRefs.current[0] = ref;
							}}
							placeholder="Seleccionar tipo"
							isDisabled={loadingState.types || !!forcedType}
							defaultValue={notificationTypesOptions.find(item => item.value === selectedNofificationData.type)}
							isLoading={loadingState.types}
							className="w-80"
							options={notificationTypesOptions}
							onChange={handleSelectType}
						/>
						<Select
							ref={ref => {
								selectInputRefs.current[1] = ref;
							}}
							placeholder="Selecciona el mensaje"
							className="w-60"
							isDisabled={!selectedNofificationData.type}
							options={messagesOptions}
							noOptionsMessage={() => "No hay mensajes disponibles"}
							onChange={handleSelectMessage}
						/>
					</div>
				</div>
				{loadingState.submit ? (
					<Spinner color="white" className="ml-auto h-6 w-6 animate-spin" />
				) : (
					<div className="flex flex-row justify-end">
						<Button className="mr-2 w-32 text-white" size="sm" variant="outline" onClick={handleDismiss}>
							No
						</Button>
						<Button
							disabled={!selectedNofificationData.type}
							className="w-32 bg-black text-white"
							size="sm"
							variant="outline"
							onClick={handleSendNotification}
						>
							Si
						</Button>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
}
