import axios from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface CreateVideoUploadType {
	cors_origin: string;
	playback_policy: "signed" | "public";
	mp4_support?: boolean;
}

export async function createDirectUpload(token: string, params: CreateVideoUploadType) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/create_direct_upload_link", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params: {...params, mp4_support: params.mp4_support !== undefined ? params.mp4_support : true},
	});
}

export async function cancelDirectUpload(token: string, params: {upload_id: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/cancel_direct_upload_element", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function refreshUpload(token: string, params: {upload_id: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_direct_upload_element", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function refreshVideo(token: string, params: {asset_id: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_asset_element", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function deleteVideo(token: string, params: {asset_id: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_asset_element", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
