import {Fragment, useState} from "react";
import {Menu, Transition} from "@headlessui/react";
import {Camera, EllipsisVertical, UserFilled} from "../../components/primitives/icons";
import EditAdminUserModal from "./subcomponents/EditAdminModal";
import {useAppSelector} from "../../_store/hooks";
import AvatarImagePicker from "./subcomponents/AvatarImagePicker";

export default function Profile() {
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [imagePickerOpen, setImagePickerOpen] = useState(false);
	const userAuth = useAppSelector(state => state.user);

	if (!userAuth.userInfo) {
		return <span>No User</span>;
	}

	return (
		<div className="mx-8 mt-8 rounded-b-lg bg-neutral-50 shadow">
			<div
				style={{backgroundImage: "radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,209,67,1) 0%, rgba(255,145,83,1) 90% )"}}
				className={"h-52 rounded-t-lg"}
			></div>
			<div className="relative">
				<div className="relative ml-8 w-fit -translate-y-12">
					{!userAuth.userInfo?.photo_url ? (
						<UserFilled className="h-40 w-40 flex-shrink-0 rounded-full bg-gray-400 ring-4 ring-white" />
					) : (
						<img
							src={userAuth.userInfo?.photo_url + "?" + Date.now()}
							className="h-40 w-40 flex-shrink-0 rounded-full bg-gray-400 ring-4 ring-white"
							alt="Profile"
						/>
					)}
					<button
						className="absolute bottom-0 right-0 rounded-full bg-neutral-600 p-2 text-white shadow shadow-white transition-colors duration-300 hover:bg-neutral-500 active:bg-neutral-800"
						onClick={() => {
							setImagePickerOpen(true);
						}}
					>
						<Camera />
					</button>
				</div>
				<Menu as="div" className="absolute right-5 top-5 flex">
					<Menu.Button className="ml-auto rounded p-1 text-neutral-700 transition-colors hover:bg-neutral-200">
						<EllipsisVertical className="h-8 w-8" />
					</Menu.Button>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items className="absolute right-0 top-full mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="p-1">
								<Menu.Item>
									{({active}) => (
										<button
											className={`${
												active ? "bg-blue-100 text-black" : "text-gray-900"
											} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
											onClick={() => {
												setEditModalOpen(true);
											}}
										>
											Editar Información
										</button>
									)}
								</Menu.Item>
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
				<div className="px-8">
					<div className="mb-6 flex items-center">
						<div className="text-3xl font-bold text-neutral-900">{userAuth.userInfo?.name}</div>
						<div className="ml-4 rounded bg-blue-200 px-2 py-1 text-sm font-bold uppercase text-blue-900">Admin</div>
					</div>
					<div className="flex items-baseline pb-4">
						<div className="mr-2 text-sm font-semibold uppercase text-neutral-400">Datos</div>
						<div className="h-[1px] w-full bg-neutral-300" />
					</div>
					<div className="flex items-center gap-4 pb-8">
						<div className="text-sm font-medium text-neutral-600">
							<span className="mr-2 rounded bg-neutral-200 px-1 py-1">Email:</span>
							{userAuth.userInfo?.email}
						</div>
						<div className="h-1 w-1 rounded-full bg-neutral-300" />
						<div className="text-sm font-medium text-neutral-600">
							<span className="mr-2 rounded bg-neutral-200 px-1 py-1">Phone:</span>
							{!userAuth.userInfo?.phone ? "No Phone" : userAuth.userInfo?.phone}
						</div>
					</div>
				</div>
			</div>
			<EditAdminUserModal
				open={editModalOpen}
				onDismiss={() => {
					setEditModalOpen(false);
				}}
			/>
			<AvatarImagePicker
				open={imagePickerOpen}
				onDismiss={() => {
					setImagePickerOpen(false);
				}}
			/>
		</div>
	);
}
