import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAppSelector, useAppDispatch} from "../../../../_store/hooks";
import {SubmitHandler, useForm} from "react-hook-form";
import {notificationsActions} from "../../../../_store/features/notify/notify-slice";
import {Spinner} from "../../../../components/primitives/icons/Spinner";

interface IFormInputs {
	message: string;
	userid: string;
	title?: string;
}
const initialState: IFormInputs = {
	message: "",
	userid: "",
	title: undefined,
};

function UserNotifications() {
	let {id} = useParams();
	const initialUser = useAppSelector(state => state.dbuser.results.find(el => el.id === id));
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const isLoading = useAppSelector(state => state.notifications.loading);
	const isSuccess = useAppSelector(state => state.notifications.success);
	const error = useAppSelector(state => state.notifications.error);
	const [isSubmitted, SetIsSubmitted] = useState(false);

	useEffect(() => {
		if (!isSuccess || !isSubmitted) return;
		dispatch(notificationsActions.setSuccess(false));
		setValue("message", "");
		alert("NOTIFICACIÓN A USUARIO ENVIADA CORRECTAMENTE.");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);

	useEffect(() => {
		if (!isLoading && isSubmitted) {
			if (error) {
				alert("ERROR: " + error);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const onSubmit: SubmitHandler<IFormInputs> = data => {
		if (
			window.confirm(
				"Esta seguro que deseas enviar esta notificación al usuario " + initialUser?.name + "?\nMensaje a enviar:\n" + data.message,
			)
		) {
			if (initialUser) {
				data.userid = initialUser.id;
				SetIsSubmitted(true);
				dispatch(notificationsActions.userNotifications(data));
			}
		}
	};

	const {
		register,
		formState: {errors},
		handleSubmit,
		setValue,
	} = useForm<IFormInputs>({
		defaultValues: initialState,
	});

	return (
		<>
			<div className="px-4 py-5 sm:px-6">
				<h3 className="mb-2 text-base font-semibold leading-6 text-gray-900">Notificación a Usuario</h3>
				<div className="mb-3 border-l-4 border-orange-500 bg-orange-100 p-4 text-orange-700" role="alert">
					<p className="font-bold">¡Cuidado!</p>
					<p>Esta acción enviara una notificación al usuario de la plataforma.</p>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="border-b border-t border-gray-200">
						<div className="my-3 max-w-2xl">
							<div className="mb-3">
								<label className="font-bold">Usuario:</label>
								<div>{initialUser?.name}</div>
							</div>
							<div>
								<label htmlFor="message" className="font-bold">
									Mensaje*:
								</label>
								<textarea
									{...register("message", {required: "Campo requerido"})}
									rows={3}
									placeholder="Mensaje de Notificación"
									className={`mt-1 w-full rounded px-3 py-2 ring-1 ring-neutral-800 ${
										errors.message ? "ring-red-500 focus:outline-none" : ""
									}`}
								/>
								{errors.message && <span className="text-red-500">{errors.message.message}</span>}
							</div>
						</div>
					</div>
					<div>
						<div className="flex items-center justify-end space-x-2 rounded-b border-t border-gray-200 p-6">
							<button
								type="submit"
								disabled={isLoading}
								className="rounded-lg bg-neutral-900 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none disabled:cursor-not-allowed disabled:bg-neutral-700 disabled:text-neutral-400 hover:bg-neutral-700"
							>
								{isLoading ? <Spinner /> : "Enviar Notificación"}
							</button>
							<button
								type="button"
								className="rounded-lg bg-neutral-200 px-5 py-2.5 text-sm font-medium text-neutral-900 focus:z-10 focus:outline-none focus:ring-4"
								onClick={() => {
									navigate(-1);
								}}
							>
								Cancelar
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default UserNotifications;
