import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {dashboardPartnerActions, selectedPartnerSeries} from "../../../../_store/features/dashboard-partner/dashboard-partner-slice";
import {formatISO9075, startOfMonth, startOfToday} from "date-fns";
import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {DatePickerWithRange} from "../../../../components/blocks/DateRangePicker";
import {DateRange} from "react-day-picker";
import IncomeCards from "./_subcomponents/IncomeCards";
import selectGroupingRange from "../../../../../utils/selectGroupingRange";
import StreamingChart from "./_subcomponents/StreamingChart";
import PerformanceChart from "./_subcomponents/PerformanceChart";
import {useDashboardPartnerByNameQuery} from "../../../../_store/features/dashboard-partner/hooks";
import RentalsChart from "./_subcomponents/RentalsChart";

export function PartnerWithRentalsDashboard() {
	const partnerSeries = useAppSelector(selectedPartnerSeries);
	const {isLoading} = useDashboardPartnerByNameQuery("getPartnerGeneralIncomeAndProfit");
	const dispatch = useAppDispatch();
	const [dates, setSelectedDate] = useState<DateRange>(() => {
		const to = startOfToday();
		const from = startOfMonth(Date.now());
		return {
			from,
			to,
		};
	});

	useEffect(() => {
		dispatch(dashboardPartnerActions.setRange({from: formatISO9075(dates?.from!), to: formatISO9075(dates?.to!)}));
		dispatch(
			dashboardPartnerActions.getPartnerGeneralIncomeAndProfit(
				selectGroupingRange(startOfMonth(Date.now()).getTime(), startOfToday().getTime()),
			),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (partnerSeries?.length === 1) {
			dispatch(dashboardPartnerActions.setSelectedSerieId(partnerSeries[0].serieid));
		}
	}, [dispatch, partnerSeries, partnerSeries?.length]);

	const handleDateRangeChange = (s: number, e: number) => {
		const start = s * 1000;
		const end = e * 1000;
		setSelectedDate({from: new Date(start), to: new Date(end)});
		dispatch(dashboardPartnerActions.getPartnerGeneralIncomeAndProfit(selectGroupingRange(start, end)));
	};

	if (!partnerSeries || partnerSeries.length === 0)
		return (
			<div className="mx-auto my-10 flex h-full max-w-lg flex-col items-center justify-center text-center">
				<p className="mb-4 rounded-full bg-card-foreground p-3 text-sm font-medium text-secondary">
					<InformationCircle />
				</p>
				<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">No hay series.</h1>
				<p className="leading-7 [&:not(:first-child)]:mt-6">
					No eres partner de ninguna serie, por favor contacta con un administrador o espera a ser añadido a una.
				</p>
			</div>
		);

	return (
		<>
			<section className="sticky top-0 z-10 flex items-center justify-between border-b border-b-border bg-background p-6 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Información General Rentas</h2>
				<DatePickerWithRange onDateRangeChange={handleDateRangeChange} date={dates} className={`${isLoading && "pointer-events-none"}`} />
			</section>
			<section className="flex flex-col p-6 py-4">
				<IncomeCards showRentalsPanel />
				<RentalsChart />
				<StreamingChart />
				<PerformanceChart />
			</section>
		</>
	);
}
