import {BarElement, CategoryScale, ChartOptions, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import {useMemo} from "react";
import {Bar} from "react-chartjs-2";
import {Data} from "../../../../_store/features/feedback/types";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

interface StartsChartProps {
	data: Data | undefined;
}

const options: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	bar: {
		datasets: {
			barPercentage: 0.1,
		},
	},

	scales: {
		x: {
			stacked: true,
		},
		y: {
			stacked: true,
		},
	},
	plugins: {
		legend: {
			align: "end",
			position: "bottom",
			labels: {
				usePointStyle: true,
			},
		},
		colors: {},
	},
};

export function StartsChart({data}: StartsChartProps) {
	const preparedData: ChartData<"bar", number[], string> = useMemo(() => {
		if (!data) return {labels: [], datasets: [{data: []}]};

		const labels = data.currentRange.data.map(item => item.label);

		return {
			labels,
			datasets: [
				{
					label: "1 estrella",
					data: data.currentRange.data.map(item => item.distribution[0]),
					backgroundColor: "rgba(255, 99, 132, 0.5)",
					borderColor: "rgba(255, 99, 132, 1)",
					borderRadius: 4,
				},
				{
					label: "2 estrellas",
					data: data.currentRange.data.map(item => item.distribution[1]),
					backgroundColor: "rgba(254, 170, 8, 0.5)",
					borderColor: "rgba(254, 170, 8, 1)",
					borderRadius: 4,
				},
				{
					label: "3 estrellas",
					data: data.currentRange.data.map(item => item.distribution[2]),
					backgroundColor: "rgba(250, 200, 88, 0.5)",
					borderColor: "rgba(250, 200, 88, 1)",
					borderRadius: 4,
				},
				{
					label: "4 estrellas",
					data: data.currentRange.data.map(item => item.distribution[3]),
					backgroundColor: "rgba(124, 152, 254, 0.5)",
					borderColor: "rgba(124, 152, 254, 1)",
					borderRadius: 4,
				},
				{
					label: "5 estrellas",
					data: data.currentRange.data.map(item => item.distribution[4]),
					backgroundColor: "rgba(56, 101, 252, 0.5)",
					borderColor: "rgba(56, 101, 252, 1)",
					borderRadius: 4,
				},
			],
		};
	}, [data]);

	return (
		<div>
			<Bar height={500} options={options} data={preparedData} />
		</div>
	);
}
