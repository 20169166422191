import {zodResolver} from "@hookform/resolvers/zod";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {EditSeriesDataBodySchema, EditSeriesDataBodyType} from "../../../../../data-access/series/series";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {Input} from "../../../../../components/primitives/Input";
import {Button} from "../../../../../components/primitives/Button";
import {ListPlus, PlusSmall, Spinner} from "../../../../../components/primitives/icons";
import {Checkbox} from "../../../../../components/primitives/Checkbox";
import MuxVideoCombobox from "../../subcomponents/MuxVideoCombobox";
import {Textarea} from "../../../../../components/primitives/Textarea";
import {toast} from "react-hot-toast";
import {seriesActions} from "../../../../../_store/features/series/series-slice";
import {auth} from "../../../../../firebase";
import {galleryActions} from "../../../../../_store/features/gallery/gallery-slice";
import {DayPicker} from "../../../../../components/blocks/DatePicker";
import HourPicker from "../../../../../components/primitives/HourPicker/HourPicker";
import {convertToStringHour} from "../../../../../../utils/convertToStringHour";
import Select, {MultiValue, SingleValue} from "react-select";
import {SelectOptionType} from "../../../../../../utils/globalTypes";
import {selectGlobalStyles} from "../../../../../../utils/selectGlobalStyles";
import {fetchDbUsersData} from "../../../../../_store/features/user-db/user-db-actions";
import {useDebouncedValue} from "../../../../../components/hooks/useDebounce";
import {associatesActions} from "../../../../../_store/features/associates/associates-slice";
import {serieRentalProductsActions} from "../../../../../_store/features/products/rental-products-slice";
import {GetSerieProductRentalListResponse, updateSerieRentalsAssociatedProduct} from "../../../../../data-access/products/serie-rental-products";
import TagsSelector from "../../../../../components/blocks/TagsSelector/TagsSelector";
import {programmedTaskActions} from "../../../../../_store/features/programmed-task/programmed-task";
import ConfirmProgrammedTaskModal from "../../../../../components/blocks/ConfirmProgrammedTaskModal/ConfirmProgrammedTaskModal";
import {formattedDayAndHour} from "../../../../../../utils/formattedDayAndHour";
import {getTime} from "date-fns";
import TaskScheduler from "../../subcomponents/TaskScheduler";
import {notificationsActions} from "../../../../../_store/features/notify/notify-slice";
import {NotificationSettings, NotificationSettingsListResult} from "../../../../../data-access/notifications/notifications";
import {PRESET_NOTIFICATIONS_TYPES} from "../../../../../constants/notifications";
import {addDisplayEmojiToCustomNotification} from "../../../../../../utils/validators";

export const seriesInitialState: EditSeriesDataBodyType = {
	id: "",
	title: "",
	slug: "",
	description: "",
	ranking: 0,
	published: false,
	total_likes: 0,
	total_favorite: 0,
	total_dislikes: 0,
	video_mux_id: "",
	img_banner_web_desktop: "",
	img_banner_web_mobile: "",
	img_logo: "",
	img_thumbnail: "",
	position_order: 0,
	img_square_thumbnail: "",
	tags: "",
	membership_title: "",
	production_cost: "",
	account: null,
	link: null,
	release_date: null,
	whitelist_release_date: null,
	display_release_date: false,
	pronyr_original: false,
	horizontal_mobile_banner: "",
	horizontal_tv_banner: "",
	short_description: null,
};

//TODO: get rental product associated to the series and list rental products [BACKEND]

export default function Details() {
	let {id} = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const serieData = useAppSelector(state => state.series.results.find(el => el.id === id));
	const rentalProducts = useAppSelector(state => state.serieRentalProducts.results);
	const users = useAppSelector(state => state.dbuser);
	const vinculated = useAppSelector(state => state.associates);
	const usersLoading = useAppSelector(state => state.dbuser.loading);
	const programedTasksList = useAppSelector(state => state.programmedTask.task);
	const [usersList, setUsersList] = useState<SelectOptionType[]>([]);
	const [rentalProductsLoading, setRentalProductsLoading] = useState<boolean>(true);
	const [vinculatedUser, setVinculatedUser] = useState<SelectOptionType | null>(null);
	const [rentalProductsList, setRentalProductsList] = useState<SelectOptionType[]>([]);
	const [rentalProduct, setRentalProduct] = useState<SelectOptionType | null>(null);
	const [isOriginalChecked, setIsOriginalChecked] = useState<boolean>(serieData?.pronyr_original ?? false);
	const [search, setSearch] = useState<string>("");
	const debouncedSearchValue = useDebouncedValue(search);
	const [hourInMiami, setHourInMiami] = useState<string>("");
	const [whiteListHourInMiami, setWhiteListHourInMiami] = useState<string>("");
	const [hour, setHour] = useState<string>("00:00:00.000Z");
	const [page, setPage] = useState({
		page: 0,
		page_size: 10,
	});
	const [whiteListHour, setWhiteListHour] = useState<string>("00:00:00.000Z");
	const [selectedSerieTags, setSelectedSerieTags] = useState<SelectOptionType[] | null>([]);
	const [showScheduledTask, setShowScheduledTask] = useState(false);
	const [scheduledTaskValue, setScheduledTaskValue] = useState({date: "", hour: ""});
	const [showScheduleNotificationModal, setShowScheduleNotificationModal] = useState(false);
	const [disableProgramTask, setDisableProgramTask] = useState(true);
	const [notificationSettings, setNotificationSettings] = useState<{label: string; value: string}[]>([]);
	const [selectedNotificationType, setSelectedNotificationType] = useState("");

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		control,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<EditSeriesDataBodyType>({
		resolver: zodResolver(EditSeriesDataBodySchema),
		defaultValues: seriesInitialState,
	});

	const fetchRentalData = async () => {
		try {
			const res = await dispatch(
				serieRentalProductsActions.getSerieRentalProductsBySerie({
					page_size: 10,
					page: 0,
				}),
			);
			if (res.meta.requestStatus === "fulfilled") {
				const cat = res.payload as GetSerieProductRentalListResponse;
				const findAssociatedRentalProduct = cat.results.find(s => s.s_id === serieData?.id);
				findAssociatedRentalProduct &&
					setRentalProduct({
						label: findAssociatedRentalProduct.pr_name,
						value: findAssociatedRentalProduct.pr_id,
					});
				await dispatch(
					serieRentalProductsActions.getSerieRentalProducts({
						page_size: 10,
						page: 0,
					}),
				);
			}
		} catch (e) {
			console.log("🚀 ~ fetchRentalData ~ e:", e);
		} finally {
			setRentalProductsLoading(false);
		}
	};

	useEffect(() => {
		fetchRentalData();
		dispatch(
			associatesActions.getUserVinculatedSeries({
				page: 0,
				page_size: 100,
			}),
		);
		dispatch(programmedTaskActions.listAllTask());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		reset(serieData);
		dispatch(notificationsActions.getAllNotificationsSettings()).then(res => {
			if (res.meta.requestStatus !== "fulfilled") return;
			const notificationsList = (res.payload as NotificationSettingsListResult).results;
			const parsedNotifications: (NotificationSettings & {active?: boolean})[] = notificationsList.map(notification => {
				const presetSetting = PRESET_NOTIFICATIONS_TYPES.find(({value}) => value === notification.notification_type);
				return {
					...notification,
					label: presetSetting?.label ?? notification.label,
					display: presetSetting?.display ?? notification.display,
				};
			});
			const notificationsWithEmoji = addDisplayEmojiToCustomNotification(parsedNotifications.filter(n => n.enabled && n.display === "push"));
			const notifications = notificationsWithEmoji.map(({label, notification_type}) => ({label, value: notification_type}));
			setNotificationSettings(notifications);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reset, serieData]);

	const selectedTags = useMemo(() => {
		if (!selectedSerieTags?.length && selectedSerieTags !== null) return false;
		return selectedSerieTags ? (selectedSerieTags as SelectOptionType[]).map(tag => tag.value).join(",") : null;
	}, [selectedSerieTags]);

	const onSubmit: SubmitHandler<EditSeriesDataBodyType> = data => {
		if (data.release_date) {
			const date = new Date(`${data.release_date.split("T")[0]}T${hour}Z`);
			data.release_date = date.toISOString();
		}

		if (data.whitelist_release_date) {
			const date = new Date(`${data.whitelist_release_date.split("T")[0]}T${whiteListHour}Z`);
			data.whitelist_release_date = date.toISOString();
		}

		return dispatch(seriesActions.updateSerie(data)).then(async res => {
			if (res.meta.requestStatus === "fulfilled") {
				if (!data.pronyr_original) {
					const token = await auth.currentUser?.getIdToken();
					token &&
						rentalProduct?.value &&
						(await updateSerieRentalsAssociatedProduct(token, {seriesid: id!, products_rentalid: rentalProduct?.value}));
				}

				selectedTags !== false &&
					(await dispatch(
						seriesActions.insertSerieTagsAction({
							seriesid: id || data.id,
							tag_ids: selectedTags as null | string,
						}),
					));
				toast.success("Serie actualizada correctamente");
				setTimeout(() => {
					navigate("/series/list");
				}, 3000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	useEffect(() => {
		const release = getValues("release_date");
		const whiteList = getValues("whitelist_release_date");

		if (release) {
			const date = new Date(`${release.split("T")[0]}T${hour}Z`);
			const dateMiami = date.toLocaleString("en-US", {timeZone: "America/New_York"});
			setHourInMiami(dateMiami);
		}

		if (whiteList) {
			const date = new Date(`${whiteList.split("T")[0]}T${whiteListHour}Z`);
			const dateMiami = date.toLocaleString("en-US", {timeZone: "America/New_York"});
			setWhiteListHourInMiami(dateMiami);
		}
	}, [getValues, hour, whiteListHour]);

	const handleSelectHour = (date: string) => {
		setHour(date);
	};

	const handleSelectWhiteListHour = (date: string) => {
		setWhiteListHour(date);
	};

	function handleSelectUser(newValue: SingleValue<SelectOptionType>) {
		if (newValue) {
			setVinculatedUser(newValue);
		}
	}

	function handleSelectProductRental(newValue: SingleValue<SelectOptionType>) {
		if (newValue) {
			setRentalProduct(newValue);
		}
	}

	const handleFindUsers = () => {
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: page.page,
					searchby: "name",
					searchvalue: debouncedSearchValue,
					page_size: page.page_size,
					admin: false,
					role: undefined,
				}),
			);
		});
	};

	const handleOnScrollEnd = () => {
		if (usersList.length !== users.totalResults) {
			setPage(prev => ({...prev, page: prev.page + 1}));
		}
	};

	useEffect(() => {
		setPage({page: 0, page_size: 10});
		setUsersList([]);

		handleFindUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchValue]);

	useEffect(() => {
		if (debouncedSearchValue !== "") return;

		handleFindUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.page]);

	useMemo(() => {
		if (users.results) {
			setUsersList(prev => {
				return [
					...prev,
					...users.results.map(user => {
						return {
							label: user.name,
							value: user.id,
						};
					}),
				];
			});
		}
	}, [users.results]);

	useMemo(() => {
		if (rentalProducts) {
			setRentalProductsList(prev => {
				return [
					...rentalProducts.map(product => {
						return {
							label: product.name,
							value: product.id,
						};
					}),
				];
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rentalProducts.length]);

	useMemo(() => {
		if (!vinculated.vinculatedResults.length) return;
		const findUser = vinculated.vinculatedResults.find(user => user.id === serieData?.account);
		if (!findUser) return;
		setVinculatedUser({
			label: findUser.name,
			value: findUser.id,
		});
	}, [serieData?.account, vinculated.vinculatedResults]);

	function handleTagsChange(newValue: MultiValue<SelectOptionType>) {
		if (newValue.length === 0) {
			setSelectedSerieTags(null);
			return;
		}
		setSelectedSerieTags([...newValue]);
	}
	const serieProgrammedTask = useMemo(
		() => programedTasksList?.find(task => task.task.includes(getValues("title"))),
		[programedTasksList, getValues],
	);

	const programmedTaskDate = useMemo(() => {
		if (scheduledTaskValue.date !== "") return new Date(scheduledTaskValue.date);
		if (serieProgrammedTask) return new Date(serieProgrammedTask.dateObject);
		if (getValues("release_date")) return new Date(getValues("release_date")!);
		return new Date();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scheduledTaskValue.date, serieProgrammedTask]);

	const programTask = () => {
		if (!getValues("published")) return toast.error("La serie debe estar publicada para programar una tarea");
		setShowScheduleNotificationModal(true);
	};

	useEffect(() => {
		const currentDate =
			scheduledTaskValue.date !== ""
				? new Date(`${scheduledTaskValue}T${scheduledTaskValue.hour}:00.000Z`)
				: new Date(`${serieProgrammedTask?.dateObject.split("T")[0]}T${scheduledTaskValue.hour}:00.000Z`);
		const previousDate = getTime(new Date(serieProgrammedTask?.dateObject ?? ""));

		if (getTime(currentDate) !== previousDate) return setDisableProgramTask(false);
		setDisableProgramTask(true);
	}, [scheduledTaskValue, serieProgrammedTask, programedTasksList]);

	const programmedNotificationHourInMiami = useMemo(() => {
		if (scheduledTaskValue.hour !== "") {
			const date =
				scheduledTaskValue.date !== ""
					? scheduledTaskValue.date.split("T")[0]
					: serieProgrammedTask?.dateObject
					? serieProgrammedTask?.dateObject.split("T")[0]
					: "";
			if (date === "") return formattedDayAndHour(`${new Date().toISOString().split("T")[0]}T${scheduledTaskValue.hour}:00.000Z`);
			return formattedDayAndHour(`${date}T${scheduledTaskValue.hour}:00.000Z`);
		}
		if (serieProgrammedTask) return formattedDayAndHour(serieProgrammedTask.dateObject);
		return formattedDayAndHour(new Date().toISOString());
	}, [scheduledTaskValue, serieProgrammedTask]);

	return (
		<div className="px-4">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mt-6">
					<dl className="divide-y divide-border">
						<div className="px-4 py-6 pt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Titulo<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="text" placeholder="Insertar texto del anuncio..." {...register("title")} />
								{errors?.title?.message && <span className="text-sm font-medium text-destructive">{errors?.title?.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Slug<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="text" placeholder="Insertar nombre del anunciante..." {...register("slug")} />
								{errors?.slug?.message && <span className="text-sm font-medium text-destructive">{errors?.slug.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Público</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="items-top flex space-x-2">
									<Controller
										name="published"
										control={control}
										render={({field}) => <Checkbox id="terms1" checked={field.value} onCheckedChange={c => field.onChange(c)} />}
									></Controller>
									<div className="grid gap-1.5 leading-none">
										<label
											htmlFor="terms1"
											className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
										>
											Marcar serie como pública.
										</label>
										<p className="text-sm text-muted-foreground">
											Al marcar la serie como pública, se comenzara a mostrar en los espacios determinados de las plataformas.
										</p>
									</div>
								</div>
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Proveedor de la serie</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="items-top flex space-x-2">
									<Controller
										name="pronyr_original"
										control={control}
										render={({field}) => (
											<Checkbox
												id="pronyr_originals"
												checked={field.value}
												onCheckedChange={c => {
													field.onChange(c);
													setIsOriginalChecked(!field.value);
												}}
											/>
										)}
									></Controller>
									<div className="grid gap-1.5 leading-none">
										<label
											htmlFor="pronyr_originals"
											className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
										>
											Pronyr Original.
										</label>
										<p className="text-sm text-muted-foreground">
											Al marcar el checkbox, la serie se considera Pronyr Original. De lo contrario será tomada como Pronyr
											Renta.
										</p>
									</div>
								</div>
							</dd>
						</div>

						{!isOriginalChecked && (
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Precio de renta</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="mt-1 flex flex-row items-center text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										{!rentalProductsLoading && (
											<Select
												className="basic-multi-select mr-2 min-w-[200px] rounded"
												styles={selectGlobalStyles}
												options={rentalProductsList}
												defaultValue={rentalProduct}
												onChange={selectedOption => {
													handleSelectProductRental(selectedOption);
												}}
												isLoading={rentalProductsLoading}
												placeholder="Selecciona un producto para la serie..."
											/>
										)}
									</div>
								</dd>
							</div>
						)}

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Costo de Producción</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="number" placeholder="Costo de Producción de la serie..." {...register("production_cost")} />
								{errors?.production_cost?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.production_cost?.message}</span>
								)}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Total de likes</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="number" placeholder="Total de likes" {...register("total_likes")} />
								{errors?.total_likes?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.total_likes?.message}</span>
								)}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Video ID</dt>
							<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<MuxVideoCombobox
									id="video_content_id"
									name="video_id"
									defaultValue={getValues("video_mux_id") ?? ""}
									onChangeSelected={selected => {
										setValue("video_mux_id", selected.playbackid);
									}}
								/>
								{errors?.video_mux_id?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.video_mux_id.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Descripción</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Textarea rows={5} placeholder="Descripción de la serie..." {...register("description")} />
								{errors?.description?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.description?.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Descripción corta</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Textarea rows={5} placeholder="Descripción corta de la serie..." {...register("short_description")} />
								{errors?.short_description?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.short_description?.message}</span>
								)}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Usuario vinculado</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="mt-1 flex flex-row items-center text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Controller
										name="account"
										control={control}
										render={({field}) => (
											<Select
												className="basic-multi-select mr-2 min-w-[200px] rounded"
												styles={selectGlobalStyles}
												options={usersList}
												onInputChange={e => setSearch(e)}
												defaultValue={vinculatedUser}
												onChange={selectedOption => {
													handleSelectUser(selectedOption);
													field.onChange(selectedOption?.value);
												}}
												onMenuScrollToBottom={handleOnScrollEnd}
												placeholder="Vincula un usuario"
												isLoading={vinculated.vinculatedLoading || usersLoading}
											/>
										)}
									/>
									<Button className="ml-2 w-auto min-w-fit gap-2" asChild>
										<Link to="/users/new">
											<ListPlus />
											Nuevo usuario
										</Link>
									</Button>
								</div>
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Mostrar fecha de release</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="items-top flex space-x-2">
									<Controller
										name="display_release_date"
										control={control}
										render={({field}) => (
											<Checkbox id="display_release_date" checked={field.value} onCheckedChange={c => field.onChange(c)} />
										)}
									></Controller>
									<div className="grid gap-1.5 leading-none">
										<label
											htmlFor="display_release_date"
											className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
										>
											Mostrar cuenta atrás de le fecha de release.
										</label>
										<p className="text-sm text-muted-foreground">
											Al marcar el checkbox, el usuario podrá ver el tiempo que falta para el release.
										</p>
									</div>
								</div>
							</dd>
						</div>

						<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Fecha de release</dt>
							<dd className="mt-1 justify-start self-start sm:col-span-2 sm:mt-0">
								<div className="flex flex-row">
									<Controller
										name="release_date"
										control={control}
										render={({field}) => (
											<DayPicker
												selectedDay={serieData?.release_date ? new Date(serieData?.release_date) : undefined}
												{...field}
												onDayChange={date => {
													const dateFormatted = date?.toISOString();
													field.onChange(dateFormatted);
												}}
												className="min-w-[150px] justify-start self-start"
											/>
										)}
									/>
									<p className="mx-2 self-center text-xl">-</p>
									<HourPicker
										hourSelected={convertToStringHour(serieData?.release_date)}
										onTimeChange={date => handleSelectHour(date)}
									/>
									{hourInMiami && (
										<p className="mx-2 flex w-auto self-start whitespace-nowrap text-xs text-gray-400">{hourInMiami} - Miami</p>
									)}
								</div>
								{errors?.release_date?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.release_date?.message}</span>
								)}
							</dd>
						</div>
						<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="flex flex-row items-center text-sm font-medium leading-6 text-gray-900">Fecha de notificación</dt>
							<dd className="mt-1 w-full justify-start self-start sm:col-span-2 sm:mt-0">
								<div className="flex flex-row">
									{!serieProgrammedTask && !showScheduledTask ? (
										<Button variant="outline" onClick={() => setShowScheduledTask(true)}>
											<PlusSmall />
											Agregar
										</Button>
									) : (
										<div className="flex flex-col gap-2">
											<TaskScheduler
												programmedTaskDate={programmedTaskDate}
												setScheduledTaskValue={setScheduledTaskValue}
												scheduledTaskValue={scheduledTaskValue}
												serieProgrammedTask={serieProgrammedTask}
												disableProgramTask={disableProgramTask}
												programTask={programTask}
											/>
											<Select
												className="w-full"
												placeholder="Seleccionar tipo de notificación..."
												options={notificationSettings}
												onChange={newValue => {
													if (!newValue) return;
													setSelectedNotificationType(newValue.value);
												}}
											/>
										</div>
									)}
									{!(!serieProgrammedTask && !showScheduledTask) && programmedNotificationHourInMiami && (
										<>
											<p className="ml-auto mr-2 flex w-auto self-start whitespace-nowrap text-xs text-gray-400">
												{programmedNotificationHourInMiami} - Miami
											</p>
										</>
									)}
								</div>
							</dd>
						</div>

						<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="flex flex-row items-center text-sm font-medium leading-6 text-gray-900">
								Fecha de release <p className="ml-2 text-xs font-bold text-destructive">*Whitelist</p>
							</dt>
							<dd className="mt-1 justify-start self-start sm:col-span-2 sm:mt-0">
								<div className="flex flex-row">
									<Controller
										name="whitelist_release_date"
										control={control}
										render={({field}) => (
											<DayPicker
												selectedDay={
													serieData?.whitelist_release_date ? new Date(serieData?.whitelist_release_date) : undefined
												}
												{...field}
												onDayChange={date => {
													const dateFormatted = date?.toISOString();
													field.onChange(dateFormatted);
												}}
												className="min-w-[150px] justify-start self-start"
											/>
										)}
									/>
									<p className="mx-2 self-center text-xl">-</p>
									<HourPicker
										hourSelected={convertToStringHour(serieData?.whitelist_release_date)}
										onTimeChange={date => handleSelectWhiteListHour(date)}
									/>
									{whiteListHourInMiami && (
										<p className="mx-2 flex w-auto self-start whitespace-nowrap text-xs text-gray-400">
											{whiteListHourInMiami} - Miami
										</p>
									)}
								</div>
								{errors?.release_date?.message && (
									<span className="mx-2 flex w-auto self-start whitespace-nowrap text-xs text-gray-400">
										{errors?.release_date?.message}
									</span>
								)}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Titulo en Membresía<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="text" placeholder="Titulo en Membresía..." {...register("membership_title")} />
								{errors?.membership_title?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.membership_title?.message}</span>
								)}
							</dd>
						</div>

						<TagsSelector
							initialSelection={serieData?.tags_list ?? []}
							multiSelection={selectedSerieTags}
							onSelectionChange={handleTagsChange}
							placeholder="Selecciona las tags de las series"
						/>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Palabras Clave</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Textarea placeholder="Palabras claves de la serie" rows={3} {...register("tags")} />
								<span className="text-sm text-muted-foreground">*Adicione palabras clave separada por comas</span>
								{errors?.tags?.message && <span className="text-sm font-medium text-destructive">{errors?.tags?.message}</span>}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Orden</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="number" placeholder="Orden de la serie..." {...register("position_order", {valueAsNumber: true})} />
								{errors?.position_order?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.position_order?.message}</span>
								)}
							</dd>
						</div>
					</dl>
				</div>
				<div className="flex justify-end gap-2 pb-4">
					<Button
						type="button"
						variant="secondary"
						onClick={() => {
							navigate("/series/list");
							dispatch(galleryActions.resetToInitial());
						}}
					>
						Cancelar
					</Button>
					<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
						{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
						Guardar cambios
					</Button>
				</div>
			</form>
			<ConfirmProgrammedTaskModal
				open={showScheduleNotificationModal}
				contentName={getValues("title") || serieData?.title || ""}
				// @ts-ignore
				scheduledTaskValue={{...scheduledTaskValue, date: scheduledTaskValue.date || serieProgrammedTask?.dateObject || programmedTaskDate}}
				contentId={getValues("id") || serieData?.id || ""}
				notificationType={selectedNotificationType}
				action={() => {
					setShowScheduleNotificationModal(false);
				}}
			/>
		</div>
	);
}
