import {Modal} from "../../../../../../components/primitives/Modal";
import {PostType, PostTypeOrdinal} from "../../../../../../data-access/exclusive-room/types";
import {PostsTypeLabels} from "../../../../../../constants/posts";
import {FilePlus} from "../../../../../../components/primitives/icons";
import {Button} from "../../../../../../components/primitives/Button";
import {useEffect, useState} from "react";
import {InsertContentType} from "../../../../../../data-access/series/content";
import {galleryActions} from "../../../../../../_store/features/gallery/gallery-slice";
import MultimediaImagePickerModal from "../../../../../../components/blocks/MultimediaImagePickerModal";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {FOLDERS} from "../../../../../../data-access/multimedia/gallery";
import MuxVideoCombobox from "../../../subcomponents/MuxVideoCombobox";
import {Video} from "../../../../../../_store/features/videos/videos-slice";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-hot-toast";
import {cn} from "../../../../../../../utils/classNames";
import {exclusiveRoomActions} from "../../../../../../_store/features/exclusive-room/exclusive-room-slice";
import {useParams} from "react-router-dom";
import ImageSelector from "./image-selector";
import {auth} from "../../../../../../firebase";
import {uploadExclusiveRoomImage} from "../../../../../../data-access/comments/comments";

interface NewPostModalProps {
	onDismiss: () => void;
	open: boolean;
	modalTitle?: string;
	type: PostTypeOrdinal;
}

type ImageFieldsTypes = keyof Pick<InsertContentType, "lowres_image" | "square_image" | "rectangular_image" | "preview_image">;

const initialState = {
	title: "",
	description: "",
	preview_image: "",
	video_id: "",
	published: false,
};

const FOLDERS_NAMES: {name: string; value: (typeof FOLDERS)[number]}[] = [{name: "Fotos de Sala Exclusiva", value: "exclusive_image"}];

const NewPostSchema = z.object({
	title: z.string().nonempty("El título es requerido"),
	description: z.string().nonempty("La descripción es requerida"),
	preview_image: z.string().optional(),
	video_id: z.string().optional(),
	published: z.boolean(),
});

type NewPostSchemaType = z.infer<typeof NewPostSchema>;

const NewPostModal = ({modalTitle, onDismiss, open, type: postType}: NewPostModalProps) => {
	const {
		register,
		handleSubmit,
		formState: {errors},
		setValue,
	} = useForm<NewPostSchemaType>({resolver: zodResolver(NewPostSchema)});
	const {id: seriesid} = useParams();

	const [data, setData] = useState(initialState);
	const [openDialog, setOpenDialog] = useState(false);
	const [type, setType] = useState<ImageFieldsTypes>("lowres_image");
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			toast.error("Hay errores en el formulario");
		}
	}, [errors]);

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	const handleSelectVideo = (video: Video) => {
		setData(prev => ({
			...prev,
			video_id: video.playbackid,
		}));
		setValue("video_id", video.playbackid);
	};

	const onSubmit: SubmitHandler<NewPostSchemaType> = data => {
		dispatch(
			exclusiveRoomActions.createExclusiveRoomPost({
				published: data.published,
				seriesid: seriesid!,
				content: {
					type: 100,
					payload: {
						subtype: postType,
						content: {
							title: data.title,
							text: data.description,
							image_url: data.preview_image,
							video_url: data.video_id && `https://stream.mux.com/${data.video_id}.m3u8`,
						},
						reactions: [],
					},
				},
			}),
		).then(thunk => {
			if (thunk.meta.requestStatus === "rejected") {
				toast.error("Error al crear la publicación");
				return;
			}
			toast.success("Publicación creada con éxito");
			dispatch(exclusiveRoomActions.getExclusiveRoomListBySerie({id: seriesid!}));
			onDismiss();
		});
	};

	const handleUploadImage = (file: File, _type: ImageFieldsTypes) => {
		setLoading(true);
		const body = new FormData();

		body.append("file", file);
		body.append("exclusive_room_imageid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadExclusiveRoomImage(token, body)
					.then(res => {
						setData({...data, preview_image: res.data.result});
						setValue("preview_image", res.data.result);
					})
					.catch(err => {
						console.log({err});
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(err => {
				setLoading(false);
				console.log(err);
			});
	};

	return (
		<Modal open={open} onDismiss={onDismiss} title={`Crear ${PostsTypeLabels[postType]}  `}>
			<form className="container flex scroll-py-2 flex-col space-y-4 px-1 pb-0.5 md:w-[380px]" onSubmit={handleSubmit(onSubmit)}>
				<div className="md:px-8">
					{postType === PostType.IMAGE && (
						<ImageSelector
							src={data.preview_image}
							loading={loading}
							onOpenGallery={() => {
								handleSelectImage("preview_image");
							}}
							onUploadImage={handleUploadImage}
						/>
					)}
				</div>
				{postType === PostType.VIDEO && (
					<MuxVideoCombobox
						className={cn(
							"form-control w-full flex-1 rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
							{"border-red-500": errors.video_id},
						)}
						id="video_content_id"
						name="video_id"
						defaultValue={data.video_id}
						onChangeSelected={handleSelectVideo}
						video_type={5}
						placeholder="Título del video"
					/>
				)}
				<div className="flex flex-col">
					<input
						className={cn(
							"form-control flex-1 rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
							{"border-red-500": !!errors.title},
						)}
						type="text"
						placeholder="Título de la publicación"
						{...register("title")}
					/>
				</div>
				<div className="flex flex-col">
					<textarea
						className={cn(
							"form-control h-96 flex-1 rounded border border-gray-500 bg-black p-1 text-white focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 focus:ring-opacity-0",
							{"border-red-500": !!errors.description},
						)}
						rows={5}
						placeholder="Descripción de la publicación"
						{...register("description")}
					/>
				</div>
				<div className="form-check">
					<input id="published-content" type="checkbox" className="form-check-input" {...register("published")} />
					<label className="ml-2 text-sm text-white" htmlFor="published-content">
						Activar en la sala exclusiva
					</label>
				</div>
				<Button
					variant="outline"
					type="submit"
					className="flex w-full items-center justify-center space-x-2 rounded-sm border-2 bg-white px-4 py-3 text-center tracking-wider text-black transition-all duration-300 hover:bg-white hover:text-black"
				>
					<span className="text-center">Crear publicación</span>
					<FilePlus className="h-6 w-6" />
				</Button>
			</form>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={FOLDERS.indexOf("exclusive_image")}
				folderNames={FOLDERS_NAMES}
				onSuccess={url => {
					setData({...data, [type]: url});
					setValue("preview_image", url);
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				key={`exclusive_image-${type}-picker-modal-${openDialog}`}
			/>
		</Modal>
	);
};

export default NewPostModal;
