import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	GetSerieRentalProductListParams,
	getSerieRentalsProductsBySeriesList,
	getSerieRentalsProductsList,
	ProductRental,
	SerieRentalBySerie,
	SerieRentalProductBodyRequest,
	updateSerieRentalsProduct,
} from "../../../data-access/products/serie-rental-products";

export interface serieRentalProductsInterface {
	loading: boolean;
	results: ProductRental[];
	catalog: SerieRentalBySerie[];
	validateProduct: boolean;
	totalResults: number;
	current: number;
	pageSize: number;
	success: boolean;
	error: string;
}

const initialState: serieRentalProductsInterface = {
	loading: false,
	results: [],
	catalog: [],
	validateProduct: true,
	totalResults: 0,
	current: 0,
	pageSize: 0,
	success: false,
	error: "",
};

/*LIST*/
const getSerieRentalProductsBySerie = createAsyncThunk(
	"serieRentalProducts/catalog-rental-list",
	async (params: GetSerieRentalProductListParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getSerieRentalsProductsBySeriesList(token, params);

			return {
				totalResults: response.data.totalResults,
				pageSize: response.data.pageSize,
				current: response.data.current,
				results: response.data.results,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching products rentals");
		}
	},
);
const getSerieRentalProducts = createAsyncThunk(
	"serieRentalProducts/serie-rental-list",
	async (params: GetSerieRentalProductListParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getSerieRentalsProductsList(token, params);

			return {
				totalResults: response.data.totalResults,
				pageSize: response.data.pageSize,
				current: response.data.current,
				results: response.data.results,
			};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching products rentals");
		}
	},
);

/*UPDATE*/
const updateSerieRentalProducts = createAsyncThunk(
	"serieRentalProducts/update-serie-rental",
	async (body: SerieRentalProductBodyRequest, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await updateSerieRentalsProduct(token, body);

			return {item: response.data};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem updating products rentals");
		}
	},
);

const serieRentalProductsSlice = createSlice({
	name: "serieRentalProducts",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getSerieRentalProductsBySerie.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(getSerieRentalProductsBySerie.fulfilled, (state, action) => {
				state.loading = false;
				state.catalog = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getSerieRentalProductsBySerie.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(getSerieRentalProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(getSerieRentalProducts.fulfilled, (state, action) => {
				state.loading = false;
				state.results = (action.payload?.results as ProductRental[]) ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = Number(action.payload?.pageSize!);
			})
			.addCase(getSerieRentalProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(updateSerieRentalProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(updateSerieRentalProducts.fulfilled, (state, action) => {
				state.loading = false;
				// @ts-ignore
				state.results = state.results.map((el: ProductRental) => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(updateSerieRentalProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});
export const serieRentalProductsActions = {
	...serieRentalProductsSlice.actions,
	getSerieRentalProductsBySerie,
	getSerieRentalProducts,
	updateSerieRentalProducts,
};

export default serieRentalProductsSlice;
