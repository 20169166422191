import {useMemo} from "react";
import {GetGeneralIncomeAndProfitResponseV2} from "../../../../data-access/dashboard-corporate/types";

interface CostEarnGraphicProps {
	statistics: GetGeneralIncomeAndProfitResponseV2 & {range_profit: number; total_profit: number};
}

function CostEarnGraphic({statistics}: CostEarnGraphicProps) {
	const production_cost = statistics?.production_cost ?? 0;
	const totalGrossProfit = (statistics?.range_profit_memberships ?? 0) + (statistics?.range_profit_rentals ?? 0);
	const totalMembershipsAndRentalsFee = (statistics?.range_fee_memberships ?? 0) + (statistics?.range_fee_rentals ?? 0);

	const earnings = useMemo(
		() => (totalGrossProfit > production_cost ? totalGrossProfit - production_cost : 0),
		[totalGrossProfit, production_cost],
	);

	const barWidth = production_cost + (earnings - totalMembershipsAndRentalsFee);
	const notHaveData = useMemo(() => !totalGrossProfit && !production_cost, [totalGrossProfit, production_cost]);
	const productionCostPercentage = useMemo(() => (production_cost / barWidth) * 100, [barWidth, production_cost]);

	const totalProfitPercentage = useMemo(() => {
		if (production_cost > totalGrossProfit) {
			const lossPercentage = (totalGrossProfit / production_cost) * 100;
			return lossPercentage;
		}
		const percentage = ((earnings - totalMembershipsAndRentalsFee) / barWidth) * 100;
		return percentage;
	}, [totalMembershipsAndRentalsFee, totalGrossProfit, earnings, barWidth, production_cost]);

	const isEarning = earnings > 0;

	if (statistics.total_profit && !isEarning) {
		return (
			<div className="relative flex flex-col p-6 pt-0">
				<div className="flex w-full flex-row justify-between">
					<div className="mb-10 flex items-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className="mt-1 h-3 w-8 rounded-sm bg-lime-600" />
							<p className="text-center text-sm font-medium tracking-tight">{`Ingresos $${
								(totalGrossProfit * 0.01).toFixed(2) ?? 0
							}`}</p>
						</div>
					</div>
					<div className="mb-10 flex items-center justify-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className="h-3 w-8 rounded-sm bg-red-600" />
							<p className="text-center text-sm font-medium tracking-tight">{`Costo de producción $${
								(production_cost * 0.01).toFixed(2) ?? 0
							}`}</p>
						</div>
					</div>
				</div>
				<div className="flex flex-row pt-0">
					<div
						className={`h-14 w-[${totalProfitPercentage}%] min-w-[80px] ${
							notHaveData && "hidden"
						} rounded-es-lg rounded-ss-lg bg-lime-600 p-5`}
						style={{width: `${totalProfitPercentage}%`}}
					/>
					<div
						className={`h-14 min-w-[80px] w-[${notHaveData ? 100 : 100 - totalProfitPercentage}%] ${
							notHaveData ? "rounded-lg" : "rounded-ee-lg rounded-se-lg"
						} bg-red-600 p-5`}
						style={{width: `${notHaveData ? 100 : 100 - totalProfitPercentage}%`}}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className="relative flex flex-col p-6 pt-0">
			{earnings === 0 ? (
				<div className="flex w-full flex-col">
					<div className="mb-10 flex items-center justify-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className="h-3 w-8 rounded-sm bg-red-600" />
							<p className="text-center text-sm font-medium tracking-tight">{`Costo de producción $${production_cost}`}</p>
						</div>
					</div>
					<div className={`h-14 w-[100%] rounded-lg bg-red-600 p-5`} />
				</div>
			) : (
				<div className="flex w-full flex-col">
					<div className="flex w-full flex-row justify-between">
						<div className="mb-10 flex items-center">
							<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
								<div className="mt-1 h-3 w-8 rounded-sm bg-lime-600" />
								<p className="text-center text-sm font-medium tracking-tight">{`Costo de producción $${
									(production_cost / 100).toFixed(2) ?? 0
								}`}</p>
							</div>
						</div>
						<div className="mb-10 flex items-center justify-center">
							<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
								<div className="h-3 w-8 rounded-sm bg-blue-900" />
								<p className="text-center text-sm font-medium tracking-tight">{`Ganancia $${
									((earnings - totalMembershipsAndRentalsFee) * 0.01).toFixed(2) ?? 0
								}`}</p>
							</div>
						</div>
					</div>
					<div className="flex w-full flex-row pt-0">
						<div
							className={`h-14 w-[${productionCostPercentage}%] rounded-es-lg rounded-ss-lg bg-lime-600 p-5`}
							style={{width: `${productionCostPercentage}%`}}
						/>

						<div
							className={`h-14 w-[${totalProfitPercentage}%] rounded-ee-lg rounded-se-lg bg-blue-900 p-5`}
							style={{width: `${totalProfitPercentage}%`}}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default CostEarnGraphic;
