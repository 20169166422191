import axios, {AxiosResponse} from "axios";
import {Serie} from "../../_store/features/series/series-slice";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export type SerieWithMembership = Serie & SeriesDataInteractionData;

interface GetUserMembershipListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: SerieWithMembership[];
}

export interface GetUserMembershipListParams {
	page_size?: number;
	page?: number;
	userid: string;
}

export async function getUserMemberships(token: string, params: GetUserMembershipListParams): Promise<AxiosResponse<GetUserMembershipListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_series_membership", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

interface SeriesDataInteractionData {
	seriesid: string;
	userid: string;
	like?: boolean;
	membership?: number;
	favorite?: boolean;
}

export interface SeriesDataInteractionResponse {
	userid: string;
	seriesid: string;
	membership: number;
	like: boolean;
	favorite: boolean;
	audit_createdtime: string;
	audit_updated: string;
}

export async function setSeriesDataInteraction(
	token: string,
	data: SeriesDataInteractionData,
): Promise<AxiosResponse<SeriesDataInteractionResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/admin_set_series_user_data", data, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
