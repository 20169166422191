import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {adminDeleteProgrammedTask, adminListProgrammedTask, adminPostNewTask, PostNewTaskBody, Task} from "../../../data-access/programmed-task";

export interface programmedTaskInterface {
	loading: boolean;
	task: Task[];
	success: boolean;
	error: string;
}

const initialState: programmedTaskInterface = {
	loading: false,
	task: [],
	success: false,
	error: "",
};

const listAllTask = createAsyncThunk("programmed-task/list", async (_, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await adminListProgrammedTask(token);

		return res.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching Programmed tasks");
	}
});

const deleteTask = createAsyncThunk("programmed-task/delete", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await adminDeleteProgrammedTask(id, token);

		return res.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem deleting Programmed tasks");
	}
});

const addNewTask = createAsyncThunk("programmed-task/add-new", async (data: PostNewTaskBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await adminPostNewTask(token, data);

		return res.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem posting Programmed tasks");
	}
});

const programmedTaskSlice = createSlice({
	name: "Programmed",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(listAllTask.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(listAllTask.fulfilled, (state, action) => {
				state.loading = false;
				state.task = action.payload?.tasks ?? action.payload?.task ?? [];
				state.success = true;
			})
			.addCase(listAllTask.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(deleteTask.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(deleteTask.fulfilled, (state, action) => {
				state.loading = false;
				state.task = state.task.filter(el => el.id !== action.payload?.task.split(" ")[0]);
				state.success = true;
			})
			.addCase(deleteTask.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});

export const programmedTaskActions = {
	...programmedTaskSlice.actions,
	listAllTask,
	deleteTask,
	addNewTask,
};

export default programmedTaskSlice;
