import {Transition} from "@headlessui/react";
import {useEffect, useMemo, useState} from "react";
import {toast} from "react-hot-toast";
import {useParams} from "react-router-dom";
import {deepEqual} from "../../../../../../utils/deepEqual";
import {Serie, seriesActions} from "../../../../../_store/features/series/series-slice";
import {getPartnerConfig, insertPartnerConfig, updatePartnerConfig} from "../../../../../_store/features/user-db/user-db-actions";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {Button} from "../../../../../components/primitives/Button";
import {Input} from "../../../../../components/primitives/Input";
import {Label} from "../../../../../components/primitives/Label";
import {Switch} from "../../../../../components/primitives/Switch";
import {Percentage, Save} from "../../../../../components/primitives/icons";
import {Spinner} from "../../../../../components/primitives/icons/Spinner";
import {PartnerRequestBody, SeriePartnershipType} from "../../../../../data-access/role/configuration/partner";
import {SeriesListCombobox} from "./subcomponents/SerieListCombobox";

function PartnerConfigurations() {
	const {id} = useParams();
	const dispatch = useAppDispatch();
	const series = useAppSelector(state => state.series);
	const [seriesPartner, setSeriesPartner] = useState<SeriePartnershipType[]>([]);
	const [loading, setLoading] = useState(false);
	const [addingPartnershipActionLoading, setAddingPartnershipActionLoading] = useState(false);
	const [modifyPartnershipActionLoading, setModifyPartnershipActionLoading] = useState(false);

	useEffect(() => {
		if (!id) return;
		setLoading(true);
		dispatch(seriesActions.getSeriesList({params: {page_size: 9999}}));
		dispatch(getPartnerConfig({searchby: "partner_id", searchvalue: id}))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") return;
				if (!res.payload || typeof res.payload === "string") return;
				setSeriesPartner(res.payload.results);
			})
			.catch(err => console.log("🚀 ~ useEffect ~ err:", err))
			.finally(() => setLoading(false));
	}, [dispatch, id]);
	console.log(seriesPartner);

	const handleAddSerieMembership = (data: Serie) => {
		setAddingPartnershipActionLoading(true);
		dispatch(insertPartnerConfig({active: false, profit_percentage: 0, partner_id: id!, serie_id: data.id}))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") return;
				if (!res.payload || typeof res.payload === "string") return;
				const data = res.payload;
				if (!seriesPartner.length) {
					dispatch(getPartnerConfig({searchby: "partner_id", searchvalue: id})).then(res => {
						if (res.meta.requestStatus !== "fulfilled") return;
						if (!res.payload || typeof res.payload === "string") return;
						setSeriesPartner(res.payload.results);
					});
				} else {
					setSeriesPartner(prev => {
						// @ts-ignore
						const partner = {...prev[0], series: prev[0].series?.concat(data)};
						return [partner];
					});
				}

				toast.success("Añadida relación correctamente.");
			})
			.catch(err => console.log("🚀 ~ handleAddSerieMembership ~ err:", err))
			.finally(() => setAddingPartnershipActionLoading(false));
	};

	const handleModifySerieMembership = (data: PartnerRequestBody) => {
		if (modifyPartnershipActionLoading) return;
		setModifyPartnershipActionLoading(true);
		dispatch(updatePartnerConfig(data))
			.then(res => {
				if (res.meta.requestStatus !== "fulfilled") return;
				if (!res.payload || typeof res.payload === "string") return;
				const resData = res.payload;
				setSeriesPartner(prev => {
					const item = prev.find(el => el.id === resData.partner_id);
					const data = item?.series?.map(s => (s?.series_id === resData.serie_id ? {...s, ...resData} : s));
					return [{...item, series: data}] as unknown as SeriePartnershipType[];
				});
				toast.success("Añadida relación correctamente.");
			})
			.catch(err => console.log("🚀 ~ handleModifySerieMembership ~ err:", err))
			.finally(() => setModifyPartnershipActionLoading(false));
	};

	const remainingSeries = useMemo(() => {
		if (!series.results) return [];
		if (!seriesPartner[0]?.series?.length) return series.results;
		// @ts-ignore
		return series.results.filter(serie => !seriesPartner[0]?.series?.some(el => (el?.series_id ?? el?.serie_id) === serie.id));
	}, [series.results, seriesPartner]);

	if (loading)
		return (
			<div className="mx-auto mt-4 flex items-center justify-center">
				<Spinner />
			</div>
		);

	return (
		<>
			<div className="px-4 py-5 sm:px-6">
				<h3 className="text-base font-semibold leading-6 text-gray-900">Configuración de usuario</h3>
				<p className="mt-1 max-w-2xl text-sm text-gray-500">
					Actualiza las series a las que pertenece el partner y la distribución de ganancias.
				</p>
			</div>
			<div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
				<p className="text-base font-semibold leading-6 text-gray-900">Añadir nueva serie:</p>
				{addingPartnershipActionLoading ? (
					<Spinner />
				) : !remainingSeries.length ? (
					<span className="text-sm italic text-gray-600">No hay series para añadir.</span>
				) : (
					<SeriesListCombobox data={remainingSeries} onAdd={handleAddSerieMembership} />
				)}
			</div>
			<div className="relative border-t border-gray-200">
				<Transition
					show={modifyPartnershipActionLoading}
					enter="transition-opacity duration-75"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="absolute left-1/2 top-6 flex items-center justify-center rounded-full bg-white shadow-xl ring ring-gray-200">
						<Spinner className="h-6 w-6 animate-spin" />
					</div>
				</Transition>
				<dl>
					{seriesPartner[0]?.series?.map(el => {
						// @ts-ignore
						const serieData = series.results.find(serie => (el?.series_id ?? el?.serie_id) === serie.id);
						return (
							<Item
								key={el?.series_id}
								// @ts-ignore
								data={{...serieData, ...el}}
								partnershipId={seriesPartner[0].id as string}
								onSetMembership={handleModifySerieMembership}
							/>
						);
					})}
				</dl>
			</div>
		</>
	);
}

interface ItemProps {
	data: Serie & SeriePartnershipType;
	partnershipId: string;
	onSetMembership: (data: PartnerRequestBody) => void;
}
const Item = ({data, partnershipId, onSetMembership}: ItemProps) => {
	const [serie, setSerie] = useState(data);

	const handlesetSerie = () => {
		onSetMembership({
			partner_id: partnershipId,
			serie_id: serie.id,
			profit_percentage: serie.profit_percentage,
			active: serie.active,
		});
	};

	return (
		<div className="flex items-center bg-gray-50 px-4 py-5 odd:bg-gray-50 sm:gap-4 sm:px-6" key={data.id}>
			<img src={data.img_logo} alt={`Logo de la serie ${data.title}`} className="h-20 w-20 rounded bg-neutral-900 object-contain p-2" />
			<dt className="text-lg font-semibold text-foreground">{data.title}</dt>
			<div className="ml-auto mt-1 flex min-w-[150px] items-center gap-2 text-sm text-gray-900 sm:mt-0">
				<Switch
					id={`${data.title}-${data.id}`}
					checked={serie.active}
					onCheckedChange={c => {
						setSerie(prev => ({...prev, active: c}));
					}}
				/>
				<Label htmlFor={`${data.title}-${data.id}`}>{serie.active ? "Activado" : "Desactivado"}</Label>
			</div>
			<div className="ml-5 flex items-center">
				<Label htmlFor={`${data.title}-${data.id}-dis`} className="mr-2">
					Distribución:
				</Label>
				<div className="flex rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background">
					<Input
						id={`${data.title}-${data.id}-dis`}
						type="number"
						min={0}
						max={100}
						step={0.1}
						value={serie.profit_percentage}
						className="rounded-r-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
						onChange={e => {
							setSerie(prev => ({...prev, profit_percentage: parseFloat(e.target.value)}));
						}}
						placeholder="Distribución..."
					/>
					<div className="flex items-center justify-center rounded-r-md bg-primary px-2 ">
						<Percentage className="h-6 w-6 flex-shrink-0 text-background" />
					</div>
				</div>
			</div>
			<Button
				onClick={handlesetSerie}
				disabled={deepEqual(
					{active: serie.active, profit_percentage: serie.profit_percentage},
					{active: data.active, profit_percentage: data.profit_percentage},
				)}
			>
				<Save className="h-5 w-5" />
			</Button>
		</div>
	);
};

export default PartnerConfigurations;
