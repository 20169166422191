import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import UserData from "./Tabs/UserData";
import useUserRole from "../../../components/hooks/useUserRole";
import {auth} from "../../../firebase";
import {fetchDbUsersData} from "../../../_store/features/user-db/user-db-actions";
import Memberships from "./Tabs/Memberships";

export default function UserInteralEdit() {
	let {id} = useParams();
	const dispatch = useAppDispatch();
	const user = useAppSelector(state => state.dbuser.results.find(el => el.id === id));
	const selectedUserRole = useUserRole(user);
	const navigate = useNavigate();

	useEffect(() => {
		if (!user && id) {
			auth.currentUser?.getIdToken().then(idToken => {
				dispatch(
					fetchDbUsersData({
						idToken,
						page: 0,
						page_size: 1,
						searchvalue: id,
						searchby: "id",
						admin: false,
						role: undefined,
					}),
				);
			});
		}
	}, [dispatch, id, navigate, selectedUserRole, user]);

	if (!id) {
		return <div>No Params Id</div>;
	}

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<div className="flex items-center gap-5">
					<h2 className="text-2xl font-bold tracking-tight">Editar usuario</h2>
				</div>
			</div>
			<UserData notify={false} internal />
			<Memberships />
		</div>
	);
}
