import axios, {AxiosResponse} from "axios";
import {GROUPING} from "../../constants";
import {ReportResponse} from "./types";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

interface GetPartnerReportParams {
	grouping?: GROUPING;
	initial_date: string;
	final_date?: string;
	partner?: string;
}

interface PostGenerateReportBody {
	initial_date: string;
	final_date: string;
	partner_id: string;
	force: boolean | undefined;
}

export async function getPartnerReport(token: string, params?: GetPartnerReportParams): Promise<AxiosResponse<ReportResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/get_partner_mux_view_percents_report", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function generatePartnerReport(token: string, body: PostGenerateReportBody): Promise<AxiosResponse> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/generate_partner_mux_view_percents_report", body, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
