import {useCallback, useEffect, useState} from "react";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../primitives/Dialog";
import {Button} from "../../primitives/Button";
import {EditInsertVersionInfoParams, newVersionInitialState} from "../../../data-access/versions/versions";
import {ListPlus, Pencil, Trash} from "../../primitives/icons";
import {Checkbox} from "../../primitives/Checkbox";

interface EditInsertVersionModalProps {
	open?: boolean;
	isEdit: boolean;
	versionInfo: EditInsertVersionInfoParams;
	releaseTypes?: ReleaseTypes;
	onDismiss?: () => void;
	onSuccess: (e: EditInsertVersionInfoParams) => void;
}

interface Notes {
	id: number;
	note: string;
}

interface ReleaseTypes {
	major_release: boolean;
	critical_release: boolean;
}

function EditInsertVersionModal({onDismiss, onSuccess, open, isEdit, versionInfo}: EditInsertVersionModalProps) {
	const [versionDetails, setVersionDetails] = useState<EditInsertVersionInfoParams>(versionInfo);
	const [notes, setNotes] = useState<Notes[]>([]);
	const handleSave = useCallback(() => {
		const release_notes: Record<string, string> = {};
		notes.forEach((note, index) => {
			release_notes[`nota_${index + 1}`] = note.note;
		});

		const updatedVersionDetails = {
			...versionDetails,
			release_notes: release_notes,
		};

		onSuccess(updatedVersionDetails);
		resetStates();
	}, [notes, onSuccess, versionDetails]);

	const [currentFeature, setCurrentFeature] = useState("");
	const [editableId, setEditableId] = useState(0);

	useEffect(() => {
		setVersionDetails(versionInfo);
		if (typeof versionInfo.release_notes === "string" && versionInfo.release_notes !== "") {
			const parsedNotes = JSON.parse(versionInfo.release_notes);
			const resp = Object.values(parsedNotes);
			resp.map((note, index) => {
				const newNote: Notes = {
					id: Date.now() + index,
					note: note as string,
				};

				setNotes(prevNotes => [...prevNotes, newNote]);

				return null; // Return a value to satisfy ESLint
			});
		}
	}, [versionInfo]);

	const handleAddNote = () => {
		if (currentFeature.trim() !== "") {
			if (editableId > 0) {
				setNotes(prevNotes => {
					const updatedNotes = prevNotes.map(feature => (feature.id === editableId ? {...feature, note: currentFeature} : feature));
					return updatedNotes;
				});
			} else {
				const newNote: Notes = {
					id: Date.now(),
					note: currentFeature,
				};

				setNotes(prevNotes => [...prevNotes, newNote]);
			}
			setCurrentFeature("");
			setEditableId(0);
		}
	};

	const handleEditNote = (id: number, content: string) => {
		setCurrentFeature(content);
		setEditableId(id);
	};

	const handleDeleteNote = (id: number) => {
		setNotes(prevNotes => prevNotes.filter(note => note.id !== id));
	};

	const handleCheckMajor = () => {
		setVersionDetails(prevVersionDetails => ({
			...prevVersionDetails,
			major_release: true,
			critical_release: false,
		}));
	};

	const handleCheckCritical = () => {
		setVersionDetails(prevVersionDetails => ({
			...prevVersionDetails,
			major_release: false,
			critical_release: true,
		}));
	};

	const resetStates = () => {
		setNotes([]);
		setCurrentFeature("");
		setEditableId(0);
		setVersionDetails(newVersionInitialState);
	};

	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				onDismiss?.();
				resetStates();
			}}
		>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="border-b border-border pb-2 font-medium text-white">
						{isEdit ? "Editar versión" : "Crear nueva versión"}
					</DialogTitle>
				</DialogHeader>

				<div>
					<label className="text-white" htmlFor="app_version">
						Versión de la aplicación
					</label>
					<input
						id="app_version"
						className="mt-2 w-full rounded bg-black px-3 py-2 text-white"
						type="number"
						placeholder="Número de la versión"
						name="version_number"
						value={versionDetails.app_version > 0 ? versionDetails.app_version : ""}
						onChange={e => {
							setVersionDetails({
								...versionDetails,
								app_version: Number(e.target.value),
							});
						}}
					/>
				</div>
				<div>
					<label className="text-white" htmlFor="release_notes">
						Notas de la versión
					</label>
					<div className="mt-2 flex flex-row">
						<input
							className="w-full rounded bg-black px-3 py-2 text-white"
							type="text"
							placeholder="Escribe una nota"
							value={currentFeature}
							onChange={e => setCurrentFeature(e.target.value)}
						/>
						<Button className="ml-2 self-center" onClick={handleAddNote}>
							{!editableId ? <ListPlus /> : <Pencil />}
						</Button>
					</div>
					<div className="my-2 flex h-48 max-h-48 w-full flex-1 overflow-auto rounded-md border border-border p-4 text-white">
						<ul className="w-full flex-col">
							{notes.map(feature => (
								<li key={feature.id} className="flex w-full justify-between">
									{feature.note}
									<div className="flex flex-row">
										<Button
											variant={"outline"}
											size={"sm"}
											className="mb-2 text-white"
											onClick={() => handleEditNote(feature.id, feature.note)}
										>
											<Pencil className="h-4 w-4" />
										</Button>
										<Button
											variant={"outline"}
											size={"sm"}
											className="mb-2 ml-2 text-white"
											onClick={() => handleDeleteNote(feature.id)}
										>
											<Trash className="h-4 w-4" />
										</Button>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>

				<div className="flex flex-row justify-between">
					<div className="flex w-full flex-row">
						<div className="flex max-h-10 w-56 flex-row items-center space-x-3 space-y-0 rounded-md border p-3 text-white">
							<Checkbox checked={versionDetails.major_release} onCheckedChange={handleCheckMajor} />
							<div className="space-y-1 leading-none">
								<div className="text-sm">Major release</div>
							</div>
						</div>

						<div className="mx-4 flex max-h-10 w-60 flex-row items-center space-x-3 space-y-0 rounded-md border p-3 text-white">
							<Checkbox checked={versionDetails.critical_release} onCheckedChange={handleCheckCritical} />
							<div className="space-y-1 leading-none">
								<div className="text-sm">Critical release</div>
							</div>
						</div>

						<div className="conent-center flex w-full flex-row items-center space-x-3 space-y-0">
							<label className="text-white" htmlFor="link">
								Link:
							</label>
							<div className="mt-2 flex w-full flex-row">
								<input
									className="w-full rounded bg-black px-3 py-2 text-white"
									type="text"
									placeholder="Escribe el link"
									value={versionDetails.link}
									onChange={e => setVersionDetails({...versionDetails, link: e.target.value})}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-row justify-end">
					<Button
						className="mr-2 w-32 text-white"
						size="sm"
						variant="outline"
						onClick={() => {
							onDismiss?.();
						}}
					>
						Cancelar
					</Button>
					<Button className="w-32 bg-black text-white" size="sm" variant="outline" onClick={handleSave}>
						Guardar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default EditInsertVersionModal;
