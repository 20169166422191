import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	Content,
	deleteContent,
	editContent,
	editContentVariant,
	GetContentsParams,
	getSerieContent,
	insertContent,
	InsertContentType,
	orderContent,
	StopLiveParams,
	stopLiveStream,
	Variant,
} from "../../../data-access/series/content";
import {auth} from "../../../firebase";
import {RootState} from "../../store";
import {AxiosError} from "axios";

export interface serieContentsInterface {
	loading: boolean;
	results: Content[];
	success: boolean;
	newContent: Content | null;
	editingContent: Content | null;
	isEditingContent: boolean;
	totalResults: number;
}

const initialState: serieContentsInterface = {
	loading: false,
	results: [],
	newContent: null,
	editingContent: null,
	isEditingContent: false,
	success: false,
	totalResults: 0,
};

const getSerieContents = createAsyncThunk("serieContents/list", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getSerieContent(token, {searchby: "serieid", searchvalue: id, page_size: 1000});

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results.sort((a, b) => a.order - b.order),
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

//TODO: Use this method instead the one above
const listSeriesContent = createAsyncThunk("seriesContents/list", async (params: GetContentsParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getSerieContent(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results.sort((a, b) => b.order - a.order),
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getAllSeriesContents = createAsyncThunk("serieContents/listAll", async (_, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getSerieContent(token, {page_size: 9999999});

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const insertSerieContent = createAsyncThunk("serieContents/insert", async (data: InsertContentType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await insertContent(token, data);

		await thunkAPI.dispatch(serieContentsActions.getSerieContents(data.seriesid));
	} catch (error: any) {
		if (error instanceof AxiosError) {
			return thunkAPI.rejectWithValue(error.response?.data.error.message);
		}
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const orderSerieContent = createAsyncThunk("serieContents/order", async (data: Content[], thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		thunkAPI.dispatch(serieContentsActions.setResults(data));
		await orderContent(token, {ordered_data: data});
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const deleteSerieContent = createAsyncThunk("serieContents/delete", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await deleteContent(token, {contentid: id});

		return id;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editSerieContent = createAsyncThunk("serieContents/edit", async (data: InsertContentType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await editContent(token, data);

		await thunkAPI.dispatch(serieContentsActions.getSerieContents(data.seriesid));
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editSerieContentVariant = createAsyncThunk("serieContents/editVariant", async (data: Variant, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await editContentVariant(token, data);

		const state = thunkAPI.getState() as RootState;

		if (state.serieContent?.isEditingContent) {
			await thunkAPI.dispatch(serieContentsActions.getSerieContents(state.serieContent?.editingContent?.serieid!!));
		}
	} catch (error) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents (editing variant)");
	}
});

const stopLiveVideo = createAsyncThunk("liveVideo/stop", async (data: StopLiveParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await stopLiveStream(token, data);

		return response.status;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const serieContentsSlice = createSlice({
	name: "serieContents",
	initialState,
	reducers: {
		setResults(state, action: PayloadAction<Content[]>) {
			state.results = action.payload;
		},
		setEditing(state, action: PayloadAction<{data: Content | null; editing: boolean}>) {
			state.editingContent = action.payload.data;
			state.isEditingContent = action.payload.editing;
		},
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getSerieContents.pending, state => {
				state.loading = true;
			})
			.addCase(getSerieContents.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults ?? 0;
			})
			.addCase(getSerieContents.rejected, (state, action) => {});
		builder
			.addCase(getAllSeriesContents.pending, state => {
				state.loading = true;
			})
			.addCase(getAllSeriesContents.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults ?? 0;
			})
			.addCase(getAllSeriesContents.rejected, (state, action) => {});
		builder
			.addCase(editSerieContent.pending, state => {
				state.loading = true;
			})
			.addCase(editSerieContent.fulfilled, (state, action) => {
				state.loading = false;
				state.isEditingContent = false;
				state.editingContent = null;
			})
			.addCase(editSerieContent.rejected, (state, action) => {});
		builder
			.addCase(insertSerieContent.pending, state => {
				state.loading = true;
			})
			.addCase(insertSerieContent.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(insertSerieContent.rejected, (state, action) => {});

		builder
			.addCase(deleteSerieContent.pending, state => {
				state.loading = true;
			})
			.addCase(deleteSerieContent.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.filter(el => el.id !== action.payload);
			})
			.addCase(deleteSerieContent.rejected, (state, action) => {
				state.success = false;
			});
		builder.addCase(listSeriesContent.pending, state => {
			state.loading = true;
		});
		builder.addCase(listSeriesContent.fulfilled, (state, action) => {
			state.loading = false;
			state.results = action.payload?.results ?? [];
		});
		builder.addCase(listSeriesContent.rejected, (state, action) => {
			state.loading = false;
		});
		builder.addCase(editSerieContentVariant.pending, state => {
			state.loading = true;
		});
		builder.addCase(editSerieContentVariant.fulfilled, (state, action) => {
			state.loading = false;
		});
		builder.addCase(editSerieContentVariant.rejected, (state, action) => {
			state.loading = false;
		});
	},
});

export const serieContentsActions = {
	...serieContentsSlice.actions,
	editSerieContentVariant,
	getSerieContents,
	getAllSeriesContents,
	insertSerieContent,
	orderSerieContent,
	deleteSerieContent,
	editSerieContent,
	listSeriesContent,
	stopLiveVideo,
};

export const serieActiveContentSelector = createSelector(
	(state: RootState) => state.serieContent,
	(data: serieContentsInterface) => ({results: data.results.filter(content => content.published), loading: data.loading}),
);

export default serieContentsSlice;
