import {useEffect, useMemo, useState} from "react";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {getInfluencerConfig, getInfluencerLink} from "../../../../_store/features/user-db/user-db-actions";
import {useNavigate, useParams} from "react-router-dom";
import {GetInfluencerConfigResponse, GetInfluencerLinkResponse} from "../../../../data-access/role/configuration/influencer";
import {Spinner} from "../../../../components/primitives/icons";
import {ViewsTimeSeries} from "../../../../components/blocks/Stats";
import {InfluencerChartIncome} from "./_subcomponents/InfluencerChartIncome";
import Select, {MultiValue} from "react-select";
import {selectGlobalStyles} from "../../../../../utils/selectGlobalStyles";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {GetInfluencerAndPartnerTransactionsResponse} from "../../../../data-access/dashboard/types";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {MEMBERSHIP_TYPES, SUBSCRIPTIONS_TYPES} from "../../../../constants";
import {Skeleton} from "../../../../components/primitives/Skeleton";
import {DataTable} from "../../../../components/blocks/DataTable";
import {endOfToday, formatISO9075, roundToNearestMinutes, subMonths} from "date-fns";
import {DateRange} from "react-day-picker";
import {formattedDayAndHour} from "../../../../../utils/formattedDayAndHour";
import {SelectOptionType} from "../../../../../utils/globalTypes";

const columnHelper = createColumnHelper<GetInfluencerAndPartnerTransactionsResponse>();

function InfluencerDetail() {
	const {id, name} = useParams<{id: string; name: string}>();
	const dispatch = useAppDispatch();
	const navigator = useNavigate();
	const series = useAppSelector(({series}) => series.results);
	const [influencerData, setInfluencerData] = useState<GetInfluencerLinkResponse>();
	const [loadingInfluencerData, setLoadingInfluencerData] = useState<boolean>(true);
	const [influencerSeries, setInfluencerSeries] = useState<GetInfluencerConfigResponse>();
	const [seriesidList, setSeriesIdList] = useState<string>("");
	const [listContents, setListContents] = useState<SelectOptionType[]>();
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const {isLoading: tableLoading, data} = useDashboardByNameQuery("latestTransactions");
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const [noSelection, setNoSelection] = useState(false);
	const [noSeries, setNoSeries] = useState(false);
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = subMonths(to, 1);
		return {
			from,
			to,
		};
	});

	useEffect(() => {
		if (!id) {
			reset();
			return navigator(-1);
		}

		Promise.all([
			dispatch(getInfluencerConfig({searchby: "influencer_id", searchvalue: id})),
			dispatch(getInfluencerLink({searchby: "influencer_id", searchvalue: id})),
		]).then(([configRes, linkRes]) => {
			const {meta: configMeta} = configRes;
			const {meta: linkMeta} = linkRes;

			if (configMeta.requestStatus === "fulfilled" && configRes.payload) {
				setInfluencerSeries(configRes.payload as GetInfluencerConfigResponse);
			} else {
				setNoSeries(true);
			}

			if (linkMeta.requestStatus === "fulfilled" && linkRes.payload) {
				setInfluencerData(linkRes.payload as GetInfluencerLinkResponse);
			} else {
				setNoSeries(true);
			}
		});
		setLoadingInfluencerData(false);

		return () => {
			reset();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (seriesidList === "" || !id) {
			return;
		}
		dispatch(
			dashboardActions.getInfluencerAndPartnerTransactions({
				seriesid_list: seriesidList,
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				influencer_id: id,
				all: true,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seriesidList, dates]);

	useEffect(() => {
		if (influencerSeries?.results && influencerSeries?.results.length > 0) {
			setListContents(
				influencerSeries.results.map(content => {
					const title = series.find(s => s.id === content.serie_id)?.title;
					return {
						label: title || "",
						value: content.serie_id || "",
					};
				}),
			);
			if (seriesidList === "") {
				setSeriesIdList(influencerSeries.results.map(series => series.serie_id).join(","));
			}
		} else if (influencerSeries?.results?.length === 0) {
			setNoSeries(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [influencerSeries?.results]);

	const handleContentChange = (value: MultiValue<SelectOptionType>) => {
		if (value.length === 0) {
			setNoSelection(true);
			setSeriesIdList("");
			return;
		}
		setNoSelection(false);
		setSeriesIdList(value.map(series => series.value).join(","));
	};

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 9);

		return [
			columnHelper.accessor("name", {
				id: "Usuario",
				header: ({column}) => <DataTableColumnHeader title="Usuario" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha",
				header: ({column}) => <DataTableColumnHeader title="Fecha" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("membership_type", {
				id: "Compra",
				header: ({column}) => <DataTableColumnHeader title="Compra" column={column} />,
				cell: info => {
					const {original} = info.row;
					const getPurchaseName = () => {
						const isSubscriptionType = original?.subscription_type;
						if (isSubscriptionType !== undefined) {
							const subscriptionType = SUBSCRIPTIONS_TYPES.find(type => type.value === info.row.original.subscription_type);
							return `Suscripción ${subscriptionType?.label}`;
						}
						const membershipType = MEMBERSHIP_TYPES.find(type => type.value === info.row.original.membership_type);
						return membershipType?.label;
					};

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{getPurchaseName()}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("renewed", {
				id: "Renovación",
				header: ({column}) => <DataTableColumnHeader title="Renovable" column={column} />,
				cell: info => {
					const {original} = info.row;

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{original.renewed && "Renovación"}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("price", {
				id: "Precio",
				header: ({column}) => <DataTableColumnHeader title="Precio" column={column} />,
				cell: info => {
					const price = (info.row.original.price / 100).toFixed(2);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {price}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("fee", {
				id: "Fees",
				header: ({column}) => <DataTableColumnHeader title="Fees" column={column} />,
				cell: info => {
					const fee = (info.row.original.fee / 100).toFixed(2);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {fee}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("fee", {
				id: "Ingreso neto",
				header: ({column}) => <DataTableColumnHeader title="Ingreso neto" column={column} />,
				cell: info => {
					const price = info.row.original.price / 100;
					const fee = info.row.original.fee / 100;
					const net_total = (price - fee).toFixed(2);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {net_total}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("influencer_profit", {
				id: "Ganancias",
				header: ({column}) => <DataTableColumnHeader title="Ganancias Asociado" column={column} />,
				cell: info => {
					const earnings = (info.row.original.influencer_profit / 100).toFixed(2);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {earnings}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("platform", {
				id: "Tienda",
				header: ({column}) => <DataTableColumnHeader title="Tienda" column={column} />,
				cell: info => {
					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.platform}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("title", {
				id: "Título",
				header: ({column}) => <DataTableColumnHeader title="Serie" column={column} />,
				cell: info => {
					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.title || "-"}</span>
						</div>
					);
				},
				size: colWidth,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const reset = () => {
		setSeriesIdList("");
		setNoSelection(false);
		setNoSeries(false);
		setInfluencerData(undefined);
		setInfluencerSeries(undefined);
		setSeriesIdList("");
		setListContents([]);
		setLoadingInfluencerData(true);
	};

	if (loadingInfluencerData)
		return (
			<div className="flex h-screen w-full items-center justify-center">
				<Spinner />
			</div>
		);

	if (noSeries)
		return (
			<div className="flex h-screen items-center justify-center">
				<div className="mx-auto mt-5 flex h-full max-w-lg flex-col items-center justify-center text-center">
					<p className="mb-4 rounded-full bg-card-foreground p-3 text-sm font-medium text-secondary">
						<InformationCircle />
					</p>
					<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">No hay series.</h1>
					<p className="leading-7 [&:not(:first-child)]:mt-6">
						El Asociado <b>{name}</b> no pertenece a ninguna serie, por favor contacta con un administrador o espera a ser añadido a una.
					</p>
				</div>
			</div>
		);

	return (
		<div className="flex h-screen flex-col">
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Asociado - {name}</h2>
				<Select
					className="basic-multi-select mt-1 rounded"
					options={listContents}
					isMulti={true}
					styles={selectGlobalStyles}
					defaultValue={!loadingInfluencerData ? listContents : []}
					placeholder="Selecciona las series para ver sus estadísticas"
					onChange={handleContentChange}
					isLoading={listContents === undefined}
				/>
			</div>

			{noSelection ? (
				<div className="mx-auto flex h-full max-w-lg flex-col items-center justify-center text-center">
					<p className="mb-4 rounded-full bg-card-foreground p-3 text-sm font-medium text-secondary">
						<InformationCircle />
					</p>
					<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">No hay series seleccionadas.</h1>
					<p className="leading-7 [&:not(:first-child)]:mt-6">Debes seleccionar al menos una serie para ver sus estadísticas.</p>
				</div>
			) : (
				<div>
					<div className="flex flex-col">
						<div className="px-8 py-6">
							<InfluencerChartIncome
								userid={id}
								currentSeriesId={seriesidList}
								influencerReferralCode={influencerData?.results[0]?.referral_code || ""}
								influencerReferUsersCount={influencerData?.results[0]?.count_users || 0}
								dates={dates}
								setSelectedDate={setSelectedDate}
							/>
						</div>
					</div>
					<div className="mt-4 flex items-center justify-between px-8">
						<h3 className="text-lg font-semibold leading-none tracking-tight">Compras registradas: {data?.length}</h3>
					</div>
					{tableLoading ? (
						<Skeleton className="mx-7 my-7 h-[400px] bg-slate-300" />
					) : (
						<div className="flex grow-0 flex-col  p-8 pt-4">
							<DataTable
								ref={ref => setTableRef(ref)}
								columns={columns}
								dataset={data!}
								pageCount={Math.round((data?.length ?? 0) / pagination.pageSize)}
								pagination={pagination}
								onPaginationChange={setPagination}
								manualPagination={false}
								showPagination={false}
							/>
						</div>
					)}
					<div className="mb-4">
						<ViewsTimeSeries
							serieid={seriesidList}
							loading={loadingInfluencerData}
							title="Vistas de las series"
							codeReferral={influencerData?.results[0]?.referral_code || ""}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default InfluencerDetail;
