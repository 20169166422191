import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {stickersActions} from "../../_store/features/stickers/stickers-slice";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {DataTable} from "../../components/blocks/DataTable";
import {Badge} from "../../components/primitives/Badge";
import {Button} from "../../components/primitives/Button";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import {ListPlus, Pencil} from "../../components/primitives/icons";
import {Refresh} from "../../components/primitives/icons/Refresh";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";
import {Sticker} from "../../data-access/stickers";
import {STICKERS_TYPES} from "../../constants/stickers";
import StickerRenderer from "../../components/blocks/StickerRenderer";

const searchOptions = [{name: "Serie", value: "title"}];

const statusOptions = [
	{name: "Todos", value: undefined},
	{name: "Activos", value: true},
	{name: "Inactivos", value: false},
];

const columnHelper = createColumnHelper<Sticker>();

export function GlobalStickersList() {
	const dispatch = useAppDispatch();
	const stickers = useAppSelector(state => state.stickers);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const handleSearch = () => {
		const params: Record<string, any> = {};
		params.sticker_type = STICKERS_TYPES.GLOBAL;
		params.active = selectedStatus.value;

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		dispatch(
			stickersActions.getStickers({
				page_size: page.pageSize,
				page: page.pageIndex,
				...params,
			}),
		);
	};

	useEffect(() => {
		if (!page.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("url", {
				id: "Imagen",
				header: ({column}) => <DataTableColumnHeader title="Imagen" column={column} />,
				cell: info => <StickerRenderer url={info.getValue()} className="mx-auto h-14" />,
				size: Math.round(tableWidth * 0.25),
			}),
			columnHelper.accessor("coin", {
				id: "Monedas / Id",
				header: ({column}) => <DataTableColumnHeader title="Monedas / Id" column={column} />,
				cell: info => (
					<div className="flex w-full flex-col justify-start">
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-xl">{info.row.original.coin}</span>
						<span className="ml-2 overflow-hidden truncate text-ellipsis text-left text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.round(tableWidth * 0.25),
			}),
			columnHelper.accessor("active", {
				id: "Estado",
				header: ({column}) => <DataTableColumnHeader title="Estado" column={column} />,
				cell: info => (
					<div className="flex w-full">
						{info.getValue() ? (
							<Badge variant={"success"} className="ml-2 flex max-h-[20px] text-left">
								Activos
							</Badge>
						) : (
							<Badge variant={"destructive"} className="ml-2 flex max-h-[20px] text-left">
								Inactivos
							</Badge>
						)}
					</div>
				),
				size: Math.round(tableWidth * 0.25),
			}),
			columnHelper.display({
				header: "Controles",
				size: Math.round(tableWidth * 0.23),
				cell: info => (
					<div className="ml-2 flex w-full justify-start gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/stickers/edit/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const refresh = () => {
		setSelectedStatus(statusOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(
			stickersActions.getStickers({
				page_size: page.pageSize,
				page: page.pageIndex,
				sticker_type: STICKERS_TYPES.GLOBAL,
			}),
		);
	};

	const handleStatusFilter = (value: string) => {
		const status = statusOptions.find(el => el.name === value) ?? statusOptions[0];
		setSelectedStatus(status);

		dispatch(
			stickersActions.getStickers({
				page_size: page.pageSize,
				page: page.pageIndex,
				active: status.value,
				sticker_type: STICKERS_TYPES.GLOBAL,
			}),
		);
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Stickers Globales</h2>
				<Button className="gap-2" asChild>
					<Link to="/stickers/new">
						<ListPlus />
						Insertar Sticker
					</Link>
				</Button>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>

					<Select onValueChange={handleStatusFilter} value={selectedStatus.name}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{statusOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={sOption.name}>
										{sOption.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-2/5 grow flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={stickers.results}
					pageCount={Math.ceil(stickers.totalResults / stickers.pageSize)}
					pagination={page}
					loading={stickers.loading}
					onPaginationChange={setPage}
					withDynamicPageSize
					rowHeight={80}
					showPagination={false}
				/>
			</div>
		</div>
	);
}
