import {IconProps} from "./type";
export const Contact = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlSpace="preserve"
			fill="#fff"
			viewBox="0 0 64 64"
			stroke="currentColor"
			className="h-6 w-6"
			{...props}
		>
			<path d="M63 40.22c0-5.947-3.907-10.995-9.288-12.722v-6.015c0-10.57-10.164-19.842-21.752-19.842s-21.752 9.272-21.752 19.842v6.044C4.869 29.28 1 34.303 1 40.22c0 7.368 5.994 13.363 13.362 13.363h3.674a3.51 3.51 0 0 0 3.506-3.506v-19.71a3.504 3.504 0 0 0-3.336-3.493v-5.137c0-6.015 5.882-12.743 13.754-12.743 7.87 0 13.75 6.728 13.75 12.743v5.146c-1.813.133-3.252 1.636-3.252 3.484v19.71a3.51 3.51 0 0 0 3.506 3.506h2.673l-.005 4.56h-5.655a3.325 3.325 0 0 0-3.208-2.492H37.67c-1.835 0-3.328 1.493-3.328 3.328s1.493 3.328 3.328 3.328h2.098a3.325 3.325 0 0 0 3.106-2.165h6.756a1 1 0 0 0 1-.999l.006-5.61C57.538 53.017 63 47.25 63 40.22zM39.769 60.307H37.67c-.733 0-1.328-.596-1.328-1.328s.595-1.328 1.328-1.328h2.098c.732 0 1.328.596 1.328 1.328s-.596 1.328-1.328 1.328zM13.362 51.54C7.564 51.03 3 46.149 3 40.22S7.564 29.41 13.362 28.9v22.64zm6.18-21.173v19.71c0 .83-.676 1.506-1.506 1.506h-2.674V28.857h2.674c.83 0 1.506.678 1.506 1.51zM31.96 6.994c-8.245 0-15.754 7.027-15.754 14.743v5.12h-1.844c-.735 0-1.45.076-2.154.19v-5.564c0-9.505 9.23-17.842 19.752-17.842s19.752 8.337 19.752 17.842v5.552a13.333 13.333 0 0 0-2.074-.178H47.71v-5.12c0-7.715-7.507-14.743-15.75-14.743zm12.498 43.083v-19.71c0-.832.676-1.51 1.506-1.51h2.674v22.726h-2.674c-.83 0-1.506-.675-1.506-1.506zm6.18 1.463V28.9C56.437 29.41 61 34.293 61 40.22s-4.563 10.811-10.362 11.32z" />
		</svg>
	);
};
