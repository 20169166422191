import axios, {AxiosResponse} from "axios";
import {associatesResponseInterface} from "../../_store/features/associates/associates-slice";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface GetAssociatesListParams {
	page_size?: number;
	page?: number;
	searchby?: string;
	searchvalue?: string;
	active?: boolean;
}

export async function getAssociatesList(token: string, params?: GetAssociatesListParams): Promise<AxiosResponse<associatesResponseInterface>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_user_associate_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getVinculatedUsersList(token: string, params?: GetAssociatesListParams): Promise<AxiosResponse<associatesResponseInterface>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_series_account_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface EditAssociatesParams {
	id: string;
	active: boolean;
}

export interface AssociatesResponse {
	id: string;
	userid: string;
	seriesid: string;
	active: boolean;
	audit_createdtime: string;
	audit_update: string;
}

export async function editAssociates(token: string, params: EditAssociatesParams): Promise<AxiosResponse<AssociatesResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_user_associate_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface InsertAssociatesParams {
	userid: string;
	seriesid: string;
	active: boolean;
}

export async function insertAssociates(token: string, params: InsertAssociatesParams): Promise<AxiosResponse<AssociatesResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_user_associate_data", params, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
