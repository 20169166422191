import {forwardRef} from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import {VariantProps, cva} from "class-variance-authority";

import {cn} from "../../../../utils/classNames";

const labelVariants = cva("text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70");

const Label = forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({className, ...props}, ref) => <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />);

export {Label};
