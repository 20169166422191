import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RentalManagementState, StatusEnum} from "./types";
import {loadProductRentals, loadRentalsByUserId, updateRental, createRental} from "./thunks";
import {ProductRental, Rental} from "../../../data-access/series/user-rentals";

const initialState: RentalManagementState = {
	currentRental: null,
	rentals: [],
	status: StatusEnum.IDLE,
	error: null,
	productRentals: [],
};

const rentalSlice = createSlice({
	name: "rental",
	initialState,
	reducers: {
		selectCurrentRental: (state, action: PayloadAction<Rental | null>) => {
			state.status = StatusEnum.MODIFY_RENTAL;
			state.currentRental = action.payload;
		},
		clearCurrentRental: state => {
			state.status = StatusEnum.IDLE;
			state.currentRental = null;
		},
		resetToInitial: state => {
			state = initialState;
		},
	},
	extraReducers(builder) {
		builder.addCase(loadRentalsByUserId.pending, state => {
			state.error = null;
			state.status = StatusEnum.LOADING;
		});
		builder.addCase(loadRentalsByUserId.fulfilled, (state, action) => {
			state.status = StatusEnum.IDLE;
			state.rentals = (action.payload as Rental[]) || [];
		});
		builder.addCase(loadRentalsByUserId.rejected, state => {
			state.status = StatusEnum.IDLE;
			state.error = "Error al cargar las rentas. Intente más tarde.";
		});

		builder.addCase(loadProductRentals.fulfilled, (state, action) => {
			state.productRentals = (action.payload as ProductRental[]) || [];
		});

		builder.addCase(updateRental.pending, state => {
			state.error = null;
			state.status = StatusEnum.LOADING;
		});
		builder.addCase(updateRental.fulfilled, (state, action) => {
			state.status = StatusEnum.IDLE;
			state.rentals = state.rentals.map(rental => {
				if (rental.seriesid === action.payload?.seriesid) {
					return action.payload!;
				}
				return rental;
			});
		});
		builder.addCase(updateRental.rejected, state => {
			state.status = StatusEnum.IDLE;
			state.error = "Error al modificar la renta. Intente más tarde.";
		});
	},
});

export const rentalActions = {...rentalSlice.actions, loadRentalsByUserId, updateRental, loadProductRentals, createRental};

export default rentalSlice;
