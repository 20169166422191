import {Transition} from "@headlessui/react";
import {Fragment, useEffect, useMemo, useState} from "react";
import {toast} from "react-hot-toast";
import {useParams} from "react-router-dom";
import {deepEqual} from "../../../../../../utils/deepEqual";
import {Serie, seriesActions} from "../../../../../_store/features/series/series-slice";
import {getSponsorConfig, insertSponsorConfig, updateSponsorConfig} from "../../../../../_store/features/user-db/user-db-actions";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {Button} from "../../../../../components/primitives/Button";
import {Label} from "../../../../../components/primitives/Label";
import {Switch} from "../../../../../components/primitives/Switch";
import {Save} from "../../../../../components/primitives/icons";
import {Spinner} from "../../../../../components/primitives/icons/Spinner";
import {GetSponsorConfigResponse, SerieSponsorshipType} from "../../../../../data-access/role/configuration/sponsor";
import {SeriesListCombobox} from "./subcomponents/SerieListCombobox";
import {advertisementsActions} from "../../../../../_store/features/advertisements/advertisements-slice";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../../../components/primitives/Table";
import {Input} from "../../../../../components/primitives/Input";
import {videosActions} from "../../../../../_store/features/videos/videos-slice";
import {auth} from "../../../../../firebase";
import PaymentsTable from "./subcomponents/PaymentsTable";

function SponsorConfigurations() {
	const {id} = useParams();
	const dispatch = useAppDispatch();
	const series = useAppSelector(state => state.series);
	const [seriesSponsor, setSeriesSponsor] = useState<SerieSponsorshipType[]>([]);
	const [loading, setLoading] = useState(false);
	const [addingSponsorshipActionLoading, setAddingSponsorshipActionLoading] = useState(false);
	const [modifySponsorshipActionLoading, setModifySponsorshipActionLoading] = useState(false);

	const publicityBanner = useAppSelector(state => state.advertisements);
	const publicityVideos = useAppSelector(state => state.videos);
	const [loadingPublicity, setLoadingPublicity] = useState(false);
	const [pubPrint, setpubPrint] = useState<any>({pub: "", prints: 0});

	useEffect(() => {
		if (!id) return;
		setLoading(true);
		setLoadingPublicity(true);

		Promise.all([
			dispatch(getSponsorConfig({searchby: "sponsor_id", searchvalue: id, page_size: 9999})),
			dispatch(
				videosActions.getVideosList({
					GCPUser: auth.currentUser!,
					params: {page_size: 1000, searchby: "sponsor_id", searchvalue: id},
				}),
			),
			dispatch(
				seriesActions.getSeriesList({
					params: {
						page_size: 9999,
					},
				}),
			),
			dispatch(
				advertisementsActions.getAdvertisements({
					page_size: 1000,
					page: 0,
					searchby: "sponsor_id",
					searchvalue: id,
				}),
			),
			dispatch(getSponsorConfig({searchby: "sponsor_id", searchvalue: id})),
		])
			.then(res => {
				const isRejectedRequest = [...res].some(
					res => res.meta.requestStatus === "rejected" || !res.payload || typeof res.payload === "string",
				);
				if (isRejectedRequest) return;
				if (res[0].payload) setSeriesSponsor((res[0].payload as GetSponsorConfigResponse)?.results ?? []);
			})
			.finally(() => {
				setLoading(false);
				setLoadingPublicity(false);
			});
	}, [dispatch, id]);

	const handleAddSerieMembership = (data: Serie) => {
		setAddingSponsorshipActionLoading(true);
		dispatch(insertSponsorConfig({active: false, sponsor_id: id!, serie_id: data.id})).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				if (!res.payload || typeof res.payload === "string") return;
				const data = res.payload;
				setAddingSponsorshipActionLoading(false);
				setSeriesSponsor(prev => [...prev, data]);

				toast.success("Añadida relación correctamente.");
			}
		});
	};

	const handleModifySerieMembership = (data: SerieSponsorshipType) => {
		if (modifySponsorshipActionLoading) return;
		setModifySponsorshipActionLoading(true);
		dispatch(updateSponsorConfig(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				if (!res.payload || typeof res.payload === "string") return;
				const resData = res.payload;
				setModifySponsorshipActionLoading(false);
				setSeriesSponsor(prev => prev.map(el => (el.serie_id === resData.serie_id ? resData : el)));

				toast.success("Añadida relación correctamente.");
			}
		});
	};

	const handleAddPrintsBanner = (id: string, prints: number) => {
		dispatch(advertisementsActions.editAdvertisementPrint({id, prints})).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Impresiones Agregadas Correctamente");
				setpubPrint({pub: "", prints: 0});
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleAddPrintsVideos = (id: string, prints: number) => {
		dispatch(videosActions.editVideoPrintData({GCPUser: auth.currentUser!, params: {uploadid: id, prints}})).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Impresiones Agregadas Correctamente");
				setpubPrint({pub: "", prints: 0});
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const remainingSeries = useMemo(() => {
		if (!seriesSponsor?.length) return series.results;

		return series.results.filter(serie => !seriesSponsor.some(el => el.serie_id === serie.id));
	}, [series.results, seriesSponsor]);

	if (loading || loadingPublicity)
		return (
			<div className="mx-auto mt-4 flex items-center justify-center">
				<Spinner />
			</div>
		);

	return (
		<>
			<div className="px-4 py-5 sm:px-6">
				<h3 className="text-base font-semibold leading-6 text-gray-900">Configuración de usuario</h3>
				<p className="mt-1 max-w-2xl text-sm text-gray-500">
					Actualiza las series a las que pertenece el sponsor y se encuentra activa su campaña.
				</p>
			</div>
			<PaymentsTable id={id} />
			<div className="mt-8 flex items-center justify-between border-gray-200 px-4 py-3 sm:px-6">
				<p className="text-base font-semibold leading-6 text-gray-900">Añadir nueva serie:</p>
				{addingSponsorshipActionLoading ? (
					<Spinner />
				) : !remainingSeries.length ? (
					<span className="text-sm italic text-gray-600">Todas han sido añadidas</span>
				) : (
					<SeriesListCombobox data={remainingSeries} onAdd={handleAddSerieMembership} />
				)}
			</div>
			<div className="relative border-t border-gray-200">
				<Transition
					show={modifySponsorshipActionLoading}
					enter="transition-opacity duration-75"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="absolute left-1/2 top-6 flex items-center justify-center rounded-full bg-white shadow-xl ring ring-gray-200">
						<Spinner className="h-6 w-6 animate-spin" />
					</div>
				</Transition>
				<dl>
					{seriesSponsor.map(el => (
						<Item
							key={el.serie_id}
							data={{...series.results.find(serie => el.serie_id === serie.id)!, sponsorship: el}}
							onSetMembership={handleModifySerieMembership}
						/>
					))}
				</dl>
			</div>

			<div className="mt-8 flex items-center justify-between border-gray-200 px-4 py-3 sm:px-6">
				<p className="text-base font-semibold leading-6 text-gray-900">Publicidades Asociadas:</p>
			</div>
			<div className="px-3">
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>Miniatura</TableHead>
							<TableHead>Título</TableHead>
							<TableHead>Tipo</TableHead>
							<TableHead>Impresiones</TableHead>
							<TableHead>Agregar</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{publicityBanner.results.map(pub => (
							<TableRow className="odd:bg-accent/40" key={pub.id}>
								<TableCell className="px-2">
									<img className="aspect-video max-h-16 rounded-sm object-cover" src={pub.img_web} alt="" />
								</TableCell>
								<TableCell className="text-left">{pub.text}</TableCell>
								<TableCell>Banner</TableCell>
								<TableCell>{pub.prints}</TableCell>
								<TableCell>
									<div className="flex items-center">
										<div className="flex rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background">
											<Input
												id={`${pub.id}`}
												name={`${pub.id}`}
												type="number"
												min={100}
												max={10000}
												step={100}
												value={pubPrint.pub === pub.id ? pubPrint.prints : "0"}
												className="rounded-r-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
												onChange={e => {
													setpubPrint({pub: pub.id, prints: parseInt(e.target.value)});
												}}
												placeholder="Prints..."
											/>
										</div>
										<Button
											onClick={() => {
												handleAddPrintsBanner(pub.id, pub.prints! + pubPrint.prints);
											}}
											disabled={pubPrint.pub !== pub.id || !pubPrint.prints}
										>
											<Save className="h-5 w-5" />
										</Button>
									</div>
								</TableCell>
							</TableRow>
						))}
						{publicityVideos.results.map(pub => (
							<TableRow className="odd:bg-accent/40" key={pub.uploadid}>
								<TableCell className="px-2">
									<img
										className="aspect-video max-h-16 rounded-sm object-cover"
										src={"https://image.mux.com/" + pub.playbackid + "/thumbnail.jpg"}
										alt=""
									/>
								</TableCell>
								<TableCell className="text-left">{pub.title}</TableCell>
								<TableCell>Video</TableCell>
								<TableCell>{pub.prints}</TableCell>
								<TableCell>
									<div className="flex items-center">
										<div className="flex rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background">
											<Input
												id={`${pub.uploadid}`}
												name={`${pub.uploadid}`}
												type="number"
												min={100}
												max={10000}
												step={100}
												value={pubPrint.pub === pub.uploadid ? pubPrint.prints : "0"}
												className="rounded-r-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
												onChange={e => {
													setpubPrint({pub: pub.uploadid, prints: parseInt(e.target.value)});
												}}
												placeholder="Prints..."
											/>
										</div>
										<Button
											onClick={() => {
												handleAddPrintsVideos(pub.uploadid, pub.prints! + pubPrint.prints);
											}}
											disabled={pubPrint.pub !== pub.uploadid || !pubPrint.prints}
										>
											<Save className="h-5 w-5" />
										</Button>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		</>
	);
}

interface ItemProps {
	data: Serie & {sponsorship: SerieSponsorshipType};
	onSetMembership: (data: SerieSponsorshipType) => void;
}
const Item = ({data, onSetMembership}: ItemProps) => {
	const [sponsorship, setSponsorship] = useState<SerieSponsorshipType>(data.sponsorship);

	const handleSetSponsorship = () => {
		onSetMembership(sponsorship);
	};

	return (
		<div className="flex items-center bg-gray-50 px-4 py-5 odd:bg-gray-50 sm:gap-4 sm:px-6" key={data.id}>
			<img src={data.img_logo} alt={`Logo de la serie ${data.title}`} className="h-20 w-20 rounded bg-neutral-900 object-contain p-2" />
			<dt className="text-lg font-semibold text-foreground">{data.title}</dt>
			<div className="ml-auto mt-1 flex min-w-[150px] items-center gap-2 text-sm text-gray-900 sm:mt-0">
				<Switch
					id={`${data.title}-${data.id}`}
					checked={sponsorship.active}
					onCheckedChange={c => {
						setSponsorship(prev => ({...prev, active: c}));
					}}
				/>
				<Label htmlFor={`${data.title}-${data.id}`}>{sponsorship.active ? "Activado" : "Desactivado"}</Label>
			</div>
			<Button onClick={handleSetSponsorship} disabled={deepEqual({active: sponsorship.active}, {active: data.sponsorship.active})}>
				<Save className="h-5 w-5" />
			</Button>
		</div>
	);
};

export default SponsorConfigurations;
