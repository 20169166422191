import {PayloadAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	AllReportsForCommentResponse,
	EditReportedCommentBody,
	GetReportedCommentsListParams,
	ReportedCommentsResult,
	editReportedCommentData,
	getAllReportsList,
	getCommentsReportedList,
} from "../../../data-access/comments/reported-comments";

export interface reportedCommentsInterface {
	totalResults: number;
	pageSize: number;
	current: number;
	loading: boolean;
	error: string;
	success: boolean;
	results: ReportedCommentsResult[];
	totalReportsResults: number;
	reportsPageSize: number;
	reportsCurrent: number;
	reportsLoading: boolean;
	reportsError: string;
	reportsSuccess: boolean;
	reportsResult: AllReportsForCommentResponse[];
}

const initialState: reportedCommentsInterface = {
	loading: false,
	results: [],
	totalResults: 0,
	pageSize: 0,
	current: 0,
	success: false,
	error: "",
	reportsLoading: false,
	reportsResult: [],
	totalReportsResults: 0,
	reportsPageSize: 0,
	reportsCurrent: 0,
	reportsSuccess: false,
	reportsError: "",
};

const getCommentsReported = createAsyncThunk("comments/reported-list", async (params: GetReportedCommentsListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getCommentsReportedList(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editReportedComments = createAsyncThunk("reportedComments/edit", async (data: EditReportedCommentBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editReportedCommentData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getReportsForComment = createAsyncThunk("comments/get-reports-for-comment", async (params: GetReportedCommentsListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getAllReportsList(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const reportedCommentsSlice = createSlice({
	name: "comments",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getCommentsReported.pending, state => {
				state.loading = true;
			})
			.addCase(getCommentsReported.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...action.payload?.results!];
				state.current = action.payload?.current!;
				state.pageSize = action.payload?.pageSize!;
				state.totalResults = action.payload?.totalResults!;
			})
			.addCase(getCommentsReported.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(editReportedComments.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(editReportedComments.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						if (action.payload) el.status = action.payload?.item.status;
					}
					return el;
				});
			})
			.addCase(editReportedComments.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(getReportsForComment.pending, state => {
				state.reportsLoading = true;
			})
			.addCase(getReportsForComment.fulfilled, (state, action) => {
				state.reportsLoading = false;
				state.reportsResult = [...action.payload?.results!];
				state.reportsCurrent = action.payload?.current!;
				state.reportsPageSize = action.payload?.pageSize!;
				state.totalReportsResults = action.payload?.totalResults!;
			})
			.addCase(getReportsForComment.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});
export const reportedCommentsActions = {
	...reportedCommentsSlice.actions,
	getCommentsReported,
	editReportedComments,
	getReportsForComment,
};

export default reportedCommentsSlice;
