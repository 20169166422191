import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	InsertTagsParams,
	InsertTagsResponse,
	InsertTagsToVideoParams,
	TagsResult,
	deleteTags,
	editTags,
	getTags,
	getTagsParams,
	insertTags,
	insertTagsToVideo,
} from "../../../data-access/shorts/tags";

export interface tagsInterface {
	loading: boolean;
	totalResults: number;
	pageSize: number;
	current: number;
	results: TagsResult[];
}

const initialState: tagsInterface = {
	loading: false,
	totalResults: 0,
	pageSize: 0,
	current: 0,
	results: [],
};

const createTags = createAsyncThunk("short_videos/insert_tags", async (params: InsertTagsParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await insertTags(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem creating tags");
	}
});

const listTags = createAsyncThunk("short_videos/list_tags", async ({params}: {params?: getTagsParams}, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getTags(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching tags");
	}
});

const deleteTag = createAsyncThunk("short_videos/delete_tags", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await deleteTags(token, {id});

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem deleting tags");
	}
});

const updateTags = createAsyncThunk("short_videos/update_tags", async (params: InsertTagsResponse, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await editTags(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem updating tags");
	}
});

const insertShortVideosTags = createAsyncThunk("short_videos/insert_short_videos_tags", async (params: InsertTagsToVideoParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await insertTagsToVideo(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem creating tags");
	}
});

const tagsSlice = createSlice({
	name: "tags",
	initialState,
	reducers: {
		resetToInitial: state => {
			state = initialState;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(listTags.pending, state => {
				state.loading = true;
			})
			.addCase(listTags.fulfilled, (state, action) => {
				if (action.payload) {
					state.loading = false;
					state.current = action.payload.current;
					state.pageSize = action.payload.pageSize;
					state.totalResults = action.payload.totalResults;
					state.results = [...action.payload.results];
				}
			})
			.addCase(listTags.rejected, (state, action) => {});
	},
});

export const tagsActions = {...tagsSlice.actions, createTags, listTags, deleteTag, updateTags, insertShortVideosTags};

export default tagsSlice;
