import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "../../../../../components/primitives/icons/Spinner";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import Category from "./subcomponents/Cast";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {CastSelect} from "../../../../../components/blocks/CastSelect";
import {Cast, insertCast, orderCasts} from "../../../../../data-access/series/cast";
import {auth} from "../../../../../firebase";
import {seriesActions} from "../../../../../_store/features/series/series-slice";
import {toast} from "react-hot-toast";
import {Button} from "../../../../../components/primitives/Button";
import {ListPlus} from "../../../../../components/primitives/icons";

export function CastSection() {
	let {id} = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const serieData = useAppSelector(state => state.series.results.find(el => el.id === id));
	const isActionLoading = useAppSelector(state => state.series.actionLoading);
	const [latestRank, setLatestRank] = useState(0);
	const [casts, setCasts] = useState<
		{
			cast_member_id: string;
			cast_id: string;
			cast_name: string;
			cast_img: string;
			cast_descript: string;
			cast_ranking: number;
		}[]
	>([]);

	useEffect(() => {
		if (!serieData) {
			return navigate("/series/list");
		}

		if (!serieData.get_cast_serieid) return setCasts([]);
		setCasts([...serieData.get_cast_serieid]?.sort((a, b) => a.cast_ranking - b.cast_ranking));
		setLatestRank(serieData.get_cast_serieid[serieData.get_cast_serieid.length - 1].cast_ranking);
	}, [navigate, serieData]);

	const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
		setCasts(prev => {
			const oldData = [...prev];
			oldData.splice(hoverIndex, 0, oldData.splice(dragIndex, 1)[0]);
			return oldData;
		});
	}, []);

	const dropCard = useCallback(() => {
		const orderMap = casts.map((cast, idx) => ({id: cast.cast_id, order: idx + 1}));
		auth.currentUser
			?.getIdToken()
			.then(token => {
				orderCasts(token, {ordered_data: orderMap})
					.then(() => {
						dispatch(seriesActions.getSeriesCast({GCPUser: auth.currentUser!, params: {searchby: "id", searchvalue: id}}));
						toast.success("Datos ordenados correctamente");
					})
					.catch(err => {
						toast.error(err.message);
					});
			})
			.catch(err => {
				console.log(err);
			});
	}, [casts, dispatch, id]);

	const handleCastSelect = (cast: Cast) => {
		const rank = latestRank + 1;
		auth.currentUser
			?.getIdToken()
			.then(token => {
				const promise = insertCast(token, {
					seriesid: serieData?.id!,
					castmemberid: cast.id,
					image: cast.images[0] ?? "",
					ranking: rank,
				}).then(() => {
					setLatestRank(rank);
					dispatch(seriesActions.getSeriesCast({GCPUser: auth.currentUser!, params: {searchby: "id", searchvalue: id}}));
				});
				toast.promise(promise, {loading: "Insertando actor", error: "Error al añadir actor", success: "Actor añadido correctamente."});
			})
			.catch(err => {
				console.log(err);
			});
	};

	return (
		<>
			<div className="mt-6">
				<div className="flex justify-end rounded-t-md border border-b-0 border-border p-4">
					{isActionLoading ? (
						<div className="mx-auto mt-2 flex items-center justify-center">
							<Spinner />
						</div>
					) : (
						<Button
							type="button"
							onClick={() => {
								navigate("/crew/cast/new", {replace: false, state: {param: serieData?.id!}});
							}}
						>
							<ListPlus className="mr-1 h-6 w-6" />
							Nuevo actor
						</Button>
					)}
				</div>
				<div className="jsonContent rounded border border-border bg-accent p-6">
					<CastSelect
						onCastSelect={handleCastSelect}
						selectedCast={casts.map(el => ({
							descript: el.cast_descript,
							id: el.cast_member_id,
							images: [el.cast_img],
							name: el.cast_name,
						}))}
					/>
					{isActionLoading && (
						<div className="mx-auto mt-2 flex items-center justify-center">
							<Spinner />
						</div>
					)}
					<DndProvider backend={HTML5Backend}>
						<div className="w-full space-y-4">
							{casts.map((creditSection, index) => (
								<Category
									key={creditSection.cast_id}
									id={creditSection.cast_id}
									onMoveCategory={moveCard}
									onDropCategory={dropCard}
									name={creditSection.cast_name}
									ranking={creditSection.cast_ranking}
									data={creditSection}
									index={index}
								/>
							))}
						</div>
					</DndProvider>
				</div>
			</div>
		</>
	);
}
