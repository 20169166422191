import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	deleteVersionInfo,
	EditInsertVersionInfoParams,
	editVersionInfo,
	getVersionsList,
	insertVersionInfo,
	VersionsListResponse,
} from "../../../data-access/versions/versions";

export interface versionsInterface {
	loading: boolean;
	results: VersionsListResponse[];
	success: boolean;
	error: string;
}

export const initialState: versionsInterface = {
	loading: false,
	results: [],
	success: false,
	error: "",
};

const getVersions = createAsyncThunk("versions/list", async () => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getVersionsList(token);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return [];
	}
});

const insertVersions = createAsyncThunk("versions/insert", async (data: EditInsertVersionInfoParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await insertVersionInfo(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem inserting version, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("No se pudo insertar la version, intenta nuevamente.");
	}
});

const deleteVersions = createAsyncThunk("versions/delete", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await deleteVersionInfo(token, {id});

		return id;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching versions");
	}
});

const editVersions = createAsyncThunk("versions/edit", async (data: EditInsertVersionInfoParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await editVersionInfo(token, data);

		if (!response.data) {
			return thunkAPI.rejectWithValue("Problem fetching versions");
		}
		return {item: response.data};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching versions");
	}
});

const versionsSlice = createSlice({
	name: "versions",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getVersions.pending, state => {
				state.loading = true;
			})
			.addCase(getVersions.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload ?? [];
			})
			.addCase(getVersions.rejected, (state, action) => {});
		builder
			.addCase(editVersions.pending, state => {
				state.loading = true;
			})
			.addCase(editVersions.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editVersions.rejected, (state, action) => {});
		builder
			.addCase(insertVersions.pending, state => {
				state.loading = true;
			})
			.addCase(insertVersions.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertVersions.rejected, (state, action) => {});
		builder
			.addCase(deleteVersions.pending, state => {
				state.loading = true;
			})
			.addCase(deleteVersions.fulfilled, (state, action) => {
				state.loading = false;
				state.results = state.results.filter(el => el.id !== action.payload);
			})
			.addCase(deleteVersions.rejected, (state, action) => {});
	},
});

export const versionsActions = {
	...versionsSlice.actions,
	getVersions,
	insertVersions,
	deleteVersions,
	editVersions,
};

export default versionsSlice;
