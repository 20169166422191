import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

interface AdminNotifyViaEmailBody {
	recipient: string;
	subject: string;
	notification: string;
	send_by_console?: boolean;
}

export async function adminNotifyViaEmail(token: string, data: AdminNotifyViaEmailBody) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/admin_notify_user", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface Notification {
	message: string;
}

/*GLOBAL NOTIFICATION*/
export interface InsertGlobalNotificationBody {
	message: string;
}
interface InsertGlobalNotificationResponse {
	message: string;
}
export async function adminGlobalNotificationData(
	token: string,
	data: InsertGlobalNotificationBody,
): Promise<AxiosResponse<InsertGlobalNotificationResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/send_global_notification", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*USER NOTIFICATION*/
export interface InsertUserNotificationBody {
	message: string;
	userid: string;
	title?: string;
}
interface InsertUserNotificationResponse {
	message: string;
}
export async function adminUserNotificationData(
	token: string,
	data: InsertUserNotificationBody,
): Promise<AxiosResponse<InsertUserNotificationResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/send_user_notification", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*LIVE CHAPTER NOTIFICATION*/
export interface LiveChapterNotificationBody {
	seriesid: string;
	contentid: string;
	title: string;
}
interface LiveChapterNotificationResponse {
	message: string;
}
export async function sendLiveChapterNotification(
	token: string,
	data: LiveChapterNotificationBody,
): Promise<AxiosResponse<LiveChapterNotificationResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/send_live_chapter_notification", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface SendUserByViewsNotification {
	message?: string;
	serie_id?: string;
	title?: string;
	not_serie_id?: string;
	content_id_list?: string;
	not_content_id_list?: string;
	email_verified: boolean;
	phone_verified: boolean;
	initial_date?: string;
	final_date?: string;
	to_content_id?: string;
	click_action?: string;
	web_link?: string;
}

export async function sendUserByViewsNotification(token: string, data: SendUserByViewsNotification): Promise<AxiosResponse<{message: string}>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/send_view_notification", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface SendMarketingNotificationParams {
	message: string;
	title: string;
	views?: boolean;
	all?: boolean;
	admins?: boolean;
	seriesid?: string;
	contentid?: string;
	muxvideo_id?: string;
	membership_purchase?: boolean;
	membership?: number;
	initial_date?: string;
	final_date?: string;
	series_like?: boolean;
	series_dislike?: boolean;
	content_like?: boolean;
	content_dislike?: boolean;
	validated_phone?: boolean;
	validated_email?: boolean;
}

export interface SendMarketingNotificationResponse {
	name: string;
	email: string;
	phone: string;
	userid: string;
}

export async function sendMarketingNotification(token: string, data: SendMarketingNotificationParams): Promise<AxiosResponse<{message: string}>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/send_marketing_notification", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
