import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import Select from "react-select";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {seriesActions} from "../../_store/features/series/series-slice";
import {serieProductsActions} from "../../_store/features/products/serie-products-slice";
import {Input} from "../../components/primitives/Input";
import {Textarea} from "../../components/primitives/Textarea";
import {Button} from "../../components/primitives/Button";
import {selectStyles} from "./SelectStyles";
import toast from "react-hot-toast";

interface IFormInputs {
	name: string;
	description: string;
	stripe_price: number;
	stripe_price_initial: number;
	coins_price: number;
	coins_price_initial: number;
	google_price: number;
	google_price_initial: number;
	apple_price: number;
	apple_price_initial: number;
	active: boolean;
	serieid: string;
	serie: {label: string; value: string};
	membership: {label: string; value: number};
	membership_type: number;
}
const initialState: IFormInputs = {
	name: "",
	description: "",
	serieid: "",
	serie: {
		label: "",
		value: "",
	},
	membership: {
		label: "",
		value: 0,
	},
	membership_type: 1,
	stripe_price: 1,
	stripe_price_initial: 1,
	coins_price: 1,
	coins_price_initial: 1,
	google_price: 1,
	google_price_initial: 1,
	apple_price: 1,
	apple_price_initial: 1,
	active: false,
};

interface SelectOption {
	label: string;
	value: number;
}

const selectMemberships: SelectOption[] = [
	{label: "Simple", value: 1},
	{label: "Vip", value: 2},
	{label: "Descuento", value: 3},
];

export function SerieProductsNew() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const series = useAppSelector(state => state.series);
	const isLoading = useAppSelector(state => state.serieProducts.loading);
	const [selectedSeries, setSelectedSeries] = useState([{label: "", value: ""}]);

	const {
		register,
		setError,
		formState: {errors},
		handleSubmit,
		control,
	} = useForm<IFormInputs>({
		defaultValues: initialState,
	});

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page_size: 150}}));
	}, [dispatch]);

	useEffect(() => {
		if (series.results.length) {
			const options = series.results.map(serie => ({
				label: serie.title,
				value: serie.id,
			}));
			setSelectedSeries(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [series]);

	const onSubmit: SubmitHandler<IFormInputs> = data => {
		if (data.serie.value) {
			data.serieid = data.serie.value;
			data.membership_type = data.membership.value;
			dispatch(serieProductsActions.insertSerieProducts(data)).then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					dispatch(serieProductsActions.setSuccess(false));
					toast.success("Producto insertado correctamente!");
					navigate(-1);
				} else {
					toast.error("ERROR: " + res.payload);
				}
			});
		} else {
			setError("serie", {
				type: "required",
				message: "Campo requerido",
			});
		}
	};

	return (
		<div className="px-5 py-4">
			<div className="border-b border-b-border bg-background px-5 pb-5">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Insertar Producto de Serie</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite crear productos de series para toda la plataforma.</span>
			</div>

			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<dl className="divide-y divide-border">
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Nombre<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="text" placeholder="Nombre del producto" {...register("name", {required: "Campo requerido"})} />
								{errors.name && <span className="text-red-500">{errors.name.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Descripción<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Textarea
									rows={3}
									placeholder="Descripción del producto..."
									{...register("description", {required: "Campo requerido"})}
								/>
								{errors?.description && <span className="text-sm font-medium text-destructive">{errors?.description?.message}</span>}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Serie<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="mb-3 basis-1/2 pr-2">
									<Controller
										name="serie"
										control={control}
										render={({field}) => (
											<Select
												className="mt-1 w-full rounded text-stone-500 ring-1"
												styles={selectStyles}
												{...field}
												options={selectedSeries}
											/>
										)}
									/>
									{errors.serie && <span className="text-red-500">{errors.serie.message}</span>}
								</div>
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Membresía<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="mb-3 basis-1/2 pr-2">
									<Controller
										name="membership"
										control={control}
										render={({field}) => (
											<Select
												className="mt-1 w-full rounded ring-1"
												styles={selectStyles}
												{...field}
												options={selectMemberships as any}
											/>
										)}
									/>
								</div>
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Precio en Coins<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("coins_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
								/>
								{errors.coins_price && <span className="text-red-500">{errors.coins_price.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Precio Inicial en Coins<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("coins_price_initial", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
								/>
								{errors.coins_price_initial && <span className="text-red-500">{errors.coins_price_initial.message}</span>}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Precio Stripe (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("stripe_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
								/>
								{errors.stripe_price && <span className="text-red-500">{errors.stripe_price.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Precio Inicial Stripe (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("stripe_price_initial", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
								/>
								{errors.stripe_price_initial && <span className="text-red-500">{errors.stripe_price_initial.message}</span>}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Precio Google (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("google_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
								/>
								{errors.google_price && <span className="text-red-500">{errors.google_price.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Precio Inicial Google (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("google_price_initial", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
								/>
								{errors.google_price_initial && <span className="text-red-500">{errors.google_price_initial.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Precio Apple (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("apple_price", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
								/>
								{errors.apple_price && <span className="text-red-500">{errors.apple_price.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Precio Inicial Apple (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input
									type="number"
									{...register("apple_price_initial", {
										required: "Campo requerido",
										min: {
											value: 1,
											message: "Valor fuera de rango",
										},
									})}
								/>
								{errors.apple_price_initial && <span className="text-red-500">{errors.apple_price_initial.message}</span>}
							</dd>
						</div>
					</dl>

					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								navigate("/products/serie-list");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading}>
							{isLoading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Crear producto
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
}
