import {Link} from "react-router-dom";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../../../components/primitives/Table";
import {ArrowRightLong, Donations, Eye, GiftBox, HandThumbUpOutline, HeartOutline} from "../../../../../components/primitives/icons";
import {ChatBubbleOvalLeftEllipsis} from "../../../../../components/primitives/icons/ChatBubbleOvalLeftEllipsis";
import {RankedContent} from "../../../../../data-access/series/content";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import React, {useEffect, useState} from "react";
import {contentRankingActions} from "../../../../../_store/features/content-ad/content-ranking-slice";
import {formatISO9075} from "date-fns";
import {DateRange} from "react-day-picker";
import {Button} from "../../../../../components/primitives/Button";
import {Skeleton} from "../../../../../components/primitives/Skeleton";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../../../components/primitives/ToolTip";
import {SingleValue} from "react-select";
import {SelectOptionType} from "../../../../../../utils/globalTypes";

type SortBy = "views" | "likes" | "follows" | "comments" | "donations" | "stickers";

interface ContentRankingResumeProps {
	dates: DateRange | undefined;
	fullWidth?: boolean;
	serieId?: string;
	onSelectItem?: (value: SingleValue<SelectOptionType>) => void;
}

interface ContentsRowsProps {
	data: RankedContent[];
	sortBy: SortBy | undefined;
	onSelectItem?: (value: SingleValue<SelectOptionType>) => void;
}

function sort(data: RankedContent[], sortBy: SortBy) {
	switch (sortBy) {
		case "views":
			return data.sort((a, b) => b.views - a.views);
		case "likes":
			return data.sort((a, b) => b.likes - a.likes);
		case "follows":
			return data.sort((a, b) => b.follows - a.follows);
		case "comments":
			return data.sort((a, b) => b.comments - a.comments);
		case "donations":
			return data.sort((a, b) => b.donations - a.donations);
		case "stickers":
			return data.sort((a, b) => b.stickers - a.stickers);
		default:
			return data.sort((a, b) => b.likes + b.follows + (a.likes + a.follows));
	}
}

function ContentsRows({data, sortBy, onSelectItem}: ContentsRowsProps) {
	const dataToRender = sortBy ? sort(data, sortBy) : data;
	return (
		<>
			{dataToRender.map(item => {
				const {likes, follows, name, views, comments, donations, photo_url, stickers} = item;
				return (
					<TableRow className="h-[97px] text-center odd:bg-accent/40" key={item.id}>
						<TableCell className="w-24 px-2">
							<img
								className="aspect-video max-h-16 w-24 rounded-sm object-cover
								before:absolute before:flex before:h-16 before:w-24 before:items-center before:justify-center before:border before:border-border before:bg-background before:text-xs before:font-medium before:content-['404']
							"
								src={photo_url || ""}
								alt=""
							/>
						</TableCell>
						<TableCell className="px-0 md:w-80">
							{onSelectItem ? (
								<Link
									to={
										"/corporate/content-detail/" +
										item.series_name +
										"/" +
										item.series_id +
										"/" +
										item.name +
										"/" +
										item.id +
										"#root"
									}
									replace
									onClick={() => {
										const top = document.querySelector("#root");
										top?.scrollIntoView({behavior: "smooth"});
										onSelectItem({label: item.name, value: item.id!});
									}}
								>
									<div className="w-36 truncate whitespace-nowrap text-left md:w-auto">{name}</div>
								</Link>
							) : (
								<div className="w-36 truncate whitespace-nowrap text-left md:w-auto">{name}</div>
							)}
						</TableCell>
						<TableCell>{views || 0}</TableCell>
						<TableCell>{likes || 0}</TableCell>
						<TableCell>{follows || 0}</TableCell>
						<TableCell>{comments || 0}</TableCell>
						<TableCell>{donations || 0}</TableCell>
						<TableCell>{stickers || 0}</TableCell>
					</TableRow>
				);
			})}
		</>
	);
}

const TooltipComponent = ({text, children}: {text: string; children: React.ReactNode}) => (
	<Tooltip>
		<TooltipTrigger asChild>
			<div>{children}</div>
		</TooltipTrigger>
		<TooltipContent side="top">
			<p>{text}</p>
		</TooltipContent>
	</Tooltip>
);

function ContentRankingResume({dates, fullWidth, serieId, onSelectItem}: ContentRankingResumeProps) {
	const {loading, results} = useAppSelector(state => state.contentsRanking);
	const dispatch = useAppDispatch();
	const [sortBy, setSortBy] = useState<SortBy | undefined>(undefined);

	useEffect(() => {
		getContentRankingResume(dates);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, serieId]);

	function getContentRankingResume(dates: DateRange | undefined) {
		dispatch(
			contentRankingActions.getRankedContents({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				serie_id: serieId,
				page_size: 5,
			}),
		);
	}

	return (
		<div className={`mr-3 flex w-full flex-col rounded-lg border bg-card p-3 ${!fullWidth && "2xl:max-w-[55%]"}`}>
			<TooltipProvider>
				<div className="flex w-full items-center justify-center border-b border-border p-6">
					<h3 className="text-center text-lg font-semibold leading-none tracking-tight">Ranking de Capítulos</h3>
				</div>
				<Table className="border-b">
					<TableHeader>
						<TableRow>
							<TableHead className="w-28"></TableHead>
							<TableHead>Título</TableHead>
							<TableHead className={`mx-auto ${sortBy === "views" ? "text-accent-foreground" : "text-muted-foreground"} px-2`}>
								<Button
									className="mx-auto flex items-center whitespace-nowrap p-0"
									variant="ghost"
									onClick={() => setSortBy("views")}
								>
									<TooltipComponent text="Views">
										<Eye className="mx-auto" width={24} height={24} />
									</TooltipComponent>
								</Button>
							</TableHead>
							<TableHead className={`mx-auto ${sortBy === "likes" ? "text-accent-foreground" : "text-muted-foreground"} px-2`}>
								<Button
									className="mx-auto flex items-center whitespace-nowrap p-0"
									variant="ghost"
									onClick={() => setSortBy("likes")}
								>
									<TooltipComponent text="Likes">
										<HandThumbUpOutline className="mx-auto" width={24} height={24} />
									</TooltipComponent>
								</Button>
							</TableHead>
							<TableHead className={`mx-auto ${sortBy === "follows" ? "text-accent-foreground" : "text-muted-foreground"} px-2`}>
								<Button
									className="mx-auto flex items-center whitespace-nowrap p-0"
									variant="ghost"
									onClick={() => setSortBy("follows")}
								>
									<TooltipComponent text="Follows">
										<HeartOutline className="mx-auto" width={24} height={24} />
									</TooltipComponent>
								</Button>
							</TableHead>
							<TableHead className={`mx-auto ${sortBy === "comments" ? "text-accent-foreground" : "text-muted-foreground"} px-2`}>
								<Button
									className="mx-auto flex items-center whitespace-nowrap p-0"
									variant="ghost"
									onClick={() => setSortBy("comments")}
								>
									<TooltipComponent text="Comments">
										<ChatBubbleOvalLeftEllipsis className="mx-auto" width={24} height={24} />
									</TooltipComponent>
								</Button>
							</TableHead>
							<TableHead className={`mx-auto ${sortBy === "donations" ? "text-accent-foreground" : "text-muted-foreground"} px-2`}>
								<Button
									className="mx-auto flex items-center whitespace-nowrap p-0"
									variant="ghost"
									onClick={() => setSortBy("donations")}
								>
									<TooltipComponent text="Donations">
										<Donations className="mx-auto" width={24} height={24} />
									</TooltipComponent>
								</Button>
							</TableHead>
							<TableHead className={`mx-auto ${sortBy === "stickers" ? "text-accent-foreground" : "text-muted-foreground"} px-2`}>
								<Button
									className="mx-auto flex items-center whitespace-nowrap p-0"
									variant="ghost"
									onClick={() => setSortBy("stickers")}
								>
									<TooltipComponent text="Stickers">
										<GiftBox className="mx-auto" width={24} height={24} />
									</TooltipComponent>
								</Button>
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{loading ? (
							<TableRow className="text-center odd:bg-accent/40">
								<TableCell colSpan={8}>
									<Skeleton className="h-20 w-full bg-slate-300" />
								</TableCell>
							</TableRow>
						) : (
							<ContentsRows data={results.slice()} sortBy={sortBy} onSelectItem={onSelectItem} />
						)}
					</TableBody>
				</Table>
				<Link to={"/corporate/ranked-contents"} className="flex items-center justify-end gap-2 p-5">
					<p className="text-md truncate text-blue-500 hover:underline">Ver todo el ranking</p>
					<ArrowRightLong className="fill-blue-500" width={26} height={26} />
				</Link>
			</TooltipProvider>
		</div>
	);
}

export default ContentRankingResume;
