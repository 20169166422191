import {FormEvent, useEffect, useState} from "react";
import {validateEmail} from "../../../../utils/validators";
import {UserFilled} from "../../../components/primitives/icons";
import {Spinner} from "../../../components/primitives/icons/Spinner";
import {Modal} from "../../../components/primitives/Modal";
import {userAuthActions} from "../../../_store/features/user-auth/user-auth-slice";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";

interface EditAdminUserModalProps {
	open: boolean;
	onDismiss: () => void;
}
export default function EditAdminUserModal({open, onDismiss}: EditAdminUserModalProps) {
	const userInfo = useAppSelector(state => state.user.userInfo);
	const userError = useAppSelector(state => state.user.error);
	const userSuccess = useAppSelector(state => state.user.success);
	const dispatch = useAppDispatch();
	const [userData, setUserData] = useState({name: userInfo?.name, phone: userInfo?.phone, email: userInfo?.email});
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleUserDataChange = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setError("");
		setSuccess(false);

		const formData = new FormData(e.currentTarget);
		const data = Object.fromEntries(formData) as {name: string; phone: string; email: string};

		if (!data.name) {
			return setError("User Name cannot be empty");
		}

		if (!validateEmail(data.email)) {
			return setError("Please insert a valid email");
		}

		const updatedData = {
			...data,
			email_verified: userInfo?.email_verified,
			password: userInfo?.password_hash,
			disabled: userInfo?.disabled,
			phone_verified: userInfo?.phone_verified,
			coin: userInfo?.coin,
			provider: userInfo?.provider,
			referral_code: userInfo?.get_influencer_data?.referral_code,
		};

		setLoading(true);
		dispatch(userAuthActions.updateOwnProfile({...updatedData}));
	};

	useEffect(() => {
		if (!success) return;

		setError("");
		setLoading(false);
		setSuccess(false);
		onDismiss?.();
	}, [onDismiss, success]);

	useEffect(() => {
		if (userError) {
			setError(userError);
			setLoading(false);
		}
	}, [userError]);

	useEffect(() => {
		if (userSuccess) {
			setSuccess(true);
		}
	}, [userSuccess]);

	return (
		<Modal title="Editar" open={open} onDismiss={onDismiss}>
			<div className="px-4">
				{error && <div className="mb-4 rounded-md bg-red-600 px-4 py-2 text-white">{error}</div>}
				<div className="relative mx-auto w-fit">
					{!userInfo?.photo_url ? (
						<UserFilled className="m-1 h-40 w-40 flex-shrink-0 rounded-full bg-gray-400 ring-4 ring-white" />
					) : (
						<img
							src={userInfo?.photo_url + "?" + Date.now()}
							className="m-1 mx-auto h-40 w-40 flex-shrink-0 rounded-full bg-gray-400 ring-4 ring-white"
							alt="Profile"
						/>
					)}
				</div>
				<form onSubmit={handleUserDataChange}>
					<div className="mb-4 px-1">
						<label htmlFor="name" className="text-white">
							Nombre
						</label>
						<input
							type="text"
							name="name"
							className="w-full rounded bg-black px-3 py-2 text-white"
							placeholder="Nombre de usuario"
							required={true}
							defaultValue={userInfo?.name}
							onChange={e => {
								setUserData(prev => ({...prev, [e.target.name]: e.target.value}));
							}}
						/>
					</div>
					<div className="mb-4 px-1">
						<label htmlFor="phone" className="text-white">
							Teléfono
						</label>
						<input
							type="tel"
							name="phone"
							className="w-full rounded bg-black px-3 py-2 text-white"
							placeholder="Teléfono"
							defaultValue={userInfo?.phone}
							onChange={e => {
								setUserData(prev => ({...prev, [e.target.name]: e.target.value}));
							}}
						/>
					</div>
					<div className="mb-4 px-1">
						<label htmlFor="phone" className="text-white">
							Email
						</label>
						<input
							type="email"
							name="email"
							className="w-full rounded bg-black px-3 py-2 text-white disabled:cursor-not-allowed disabled:bg-neutral-700"
							placeholder="Email"
							required={true}
							defaultValue={userInfo?.email}
							onChange={e => {
								setUserData(prev => ({...prev, [e.target.name]: e.target.value}));
							}}
							onKeyDownCapture={e => {
								if (e.code === "Space") {
									e.preventDefault();
								}
							}}
						/>
					</div>
					<button
						className="text-md flex w-full items-center justify-center rounded-xl border-2 bg-white bg-opacity-10 px-4 py-3 font-bold uppercase tracking-wider text-white transition-colors duration-300 disabled:cursor-not-allowed disabled:border-neutral-800 disabled:bg-neutral-700 disabled:text-neutral-300 hover:bg-white hover:text-black disabled:hover:border-neutral-800 disabled:hover:bg-neutral-700 disabled:hover:text-neutral-300"
						type="submit"
						disabled={
							loading ||
							success ||
							(userInfo?.name === userData.name && userInfo?.phone === userData.phone && userInfo?.email === userData.email)
						}
					>
						{loading ? <Spinner color="red" /> : "Guardar Cambios"}
					</button>
				</form>
			</div>
		</Modal>
	);
}
