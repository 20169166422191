import {FOLDERS} from "../app/data-access/multimedia/gallery";

export const FOLDERS_NAMES: {name: string; value: (typeof FOLDERS)[number]}[] = [
	{name: "Series Banners", value: "serie/banners"},
	{name: "Series Logos", value: "serie/logos"},
	{name: "Series Thumbnails", value: "serie/thumbnails"},
];

export const FOLDERS_NAMES_STICKERS: {name: string; value: (typeof FOLDERS)[number]}[] = [{name: "Stickers", value: "stickers"}];

export const FOLDERS_NAMES_ADS: {name: string; value: (typeof FOLDERS)[number]}[] = [{name: "Anuncios", value: "publicity"}];

export const FOLDERS_NAMES_SHORTS: {name: string; value: (typeof FOLDERS)[number]}[] = [{name: "Shorts", value: "short_videos"}];

export const FOLDERS_NAMES_CONTENT: {name: string; value: (typeof FOLDERS)[number]}[] = [
	{name: "Contenido", value: "content"},
	{name: "Series Banners", value: "serie/banners"},
	{name: "Series Thumbnails", value: "serie/thumbnails"},
];
