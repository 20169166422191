import {useLocation, useNavigate} from "react-router-dom";
import {InsertCastMemberBodySchema, InsertCastMemberBodyType, uploadCastImage} from "../../../../data-access/series/cast";
import {useAppDispatch} from "../../../../_store/hooks";
import {useEffect, useRef, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {crewActions} from "../../../../_store/features/crew/crew-slice";
import {FOLDERS} from "../../../../data-access/multimedia/gallery";
import {toast} from "react-hot-toast";
import {auth} from "../../../../firebase";
import {Input} from "../../../../components/primitives/Input";
import {Textarea} from "../../../../components/primitives/Textarea";
import {Button} from "../../../../components/primitives/Button";
import MultimediaImagePickerModal from "../../../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../../../_store/features/gallery/gallery-slice";
import {ArrowUpTray, CancelCircleFilled, Spinner} from "../../../../components/primitives/icons";
import {Image as ImageIcon} from "../../../../components/primitives/icons";

const initialState: InsertCastMemberBodyType = {
	name: "",
	description: "",
	images: [],
};

type ImageFieldsTypes = keyof Pick<InsertCastMemberBodyType, "images">;

const FOLDERS_NAMES: {name: string; value: (typeof FOLDERS)[number]}[] = [{name: "Imágenes de Actores", value: "cast"}];

function CastNew() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [fieldLoading, setFieldLoading] = useState<ImageFieldsTypes[]>([]);
	const [serieId, setSerieId] = useState();
	const [openDialog, setOpenDialog] = useState(false);
	const [type, setType] = useState<ImageFieldsTypes>("images");
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		watch,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<InsertCastMemberBodyType>({
		resolver: zodResolver(InsertCastMemberBodySchema),
		defaultValues: initialState,
	});
	const watchImages = watch("images");
	const uploadImageInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (location.state !== null) {
			setSerieId(location.state.param);
		}
	}, [location.state]);

	const onSubmit: SubmitHandler<InsertCastMemberBodyType> = data => {
		return dispatch(crewActions.insertCast(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Actor Creado Correctamente");
				reset();

				if (serieId) {
					setTimeout(() => {
						navigate("/series/edit/" + serieId, {state: {param: "cast"}});
					}, 3000);
				} else {
					setTimeout(() => {
						navigate(-1);
					}, 3000);
				}
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);

		body.append("file", file);
		body.append("type", type);
		body.append("castid", crypto.randomUUID());
		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadCastImage(token, body)
					.then(res => {
						const imgArray = getValues("images");
						imgArray.push(res.data.result);
						setValue(type, imgArray);
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Nuevo Actor</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite crear actores que se mostraran en toda la plataforma.</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-border">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Nombre<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar nombre del actor..." {...register("name")} />
									{errors?.name?.message && <span className="text-sm font-medium text-destructive">{errors?.name?.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Descripción</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Textarea rows={3} placeholder="Descripción del actor..." {...register("description")} />
									{errors?.description?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.description?.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Imágenes</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full items-center gap-1.5">
										<div className="flex flex-shrink-0 gap-2 overflow-auto py-2.5">
											{watchImages.length === 0 && (
												<div className="relative flex aspect-square h-48 w-48 items-center justify-center rounded-md border border-border bg-slate-100 p-2">
													{fieldLoading.includes("images") ? <Spinner /> : <ImageIcon className="h-6 w-6 text-slate-400" />}
												</div>
											)}
											{watchImages.map((img, idx) => {
												return (
													<div
														key={idx}
														className="relative flex aspect-square h-48 w-48 items-center justify-center rounded-md border border-border bg-slate-100 p-2"
													>
														{!getValues("images") ? (
															fieldLoading.includes("images") ? (
																<Spinner />
															) : (
																<ImageIcon className="h-6 w-6 text-slate-400" />
															)
														) : (
															<img src={img} className="aspect-square h-48 w-48 object-contain" alt="Thumbnail" />
														)}
														<Button
															type="button"
															variant={"link"}
															onClick={() => {
																const imageArray = getValues("images");
																setValue(
																	"images",
																	imageArray.filter(el => el !== img),
																);
															}}
															className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 text-destructive"
														>
															<CancelCircleFilled />
														</Button>
													</div>
												);
											})}
										</div>
										<div className="flex w-full gap-4">
											<Button
												type="button"
												variant="outline"
												className="gap-2 whitespace-nowrap"
												onClick={() => {
													uploadImageInputRef.current?.click();
												}}
											>
												{fieldLoading.includes("images") ? (
													<Spinner className="h-4 w-4 animate-spin" />
												) : (
													<ArrowUpTray className="h-4 w-4" />
												)}
												Subir Archivo
											</Button>
											<Input
												ref={uploadImageInputRef}
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "images");
												}}
												placeholder="Elige un Archivo"
												className="hidden opacity-0"
												tabIndex={-1}
												hidden
											/>
											<Button
												variant="outline"
												type="button"
												className="gap-2 whitespace-nowrap"
												onClick={() => {
													handleSelectImage("images");
												}}
											>
												<ImageIcon className="h-4 w-4" />
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.images?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.images.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="outline"
							onClick={() => {
								reset();
								if (serieId) {
									navigate("/series/edit/" + serieId, {state: {param: "cast"}});
									setSerieId(undefined);
								} else {
									navigate(-1);
									setSerieId(undefined);
								}
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Crear Actor
						</Button>
					</div>
				</form>
			</div>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					navigate(-1);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={0}
				folderNames={FOLDERS_NAMES}
				onSuccess={url => {
					const imgArray = getValues("images");
					imgArray.push(url);
					setValue(type, imgArray);
					setOpenDialog(false);
					navigate(-1);
					dispatch(galleryActions.resetToInitial());
				}}
			/>
		</section>
	);
}

export default CastNew;
