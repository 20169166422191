import {DateRange} from "react-day-picker";
import {useGetFeedbackTriggersAnalysisQuery, useManageTriggerMutation} from "../../../../_store/features/feedback/api";
import {useEffect, useMemo} from "react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../../components/primitives/Table";
import {Skeleton} from "../../../../components/primitives/Skeleton";
import {Switch} from "../../../../components/primitives/Switch";
import {Spinner} from "../../../../components/primitives/icons";
import toast from "react-hot-toast";

interface TriggersAnalysisProps {
	dates: DateRange | undefined;
}

export function TriggersAnalysis({dates}: TriggersAnalysisProps) {
	const [updateTrigger, {isLoading: isLoadingTrigger, isError, originalArgs}] = useManageTriggerMutation();

	const {data, isLoading} = useGetFeedbackTriggersAnalysisQuery({
		end_date: dates?.to?.toISOString().split("T")[0],
		start_date: dates?.from?.toISOString().split("T")[0],
	});

	const triggers =
		data?.status === "OK"
			? data.data.activeTriggers.reduce((acc: {[key: string]: boolean}, curr) => {
					acc[curr.key] = curr.active;
					return acc;
			  }, {})
			: {};

	useEffect(() => {
		if (isError) toast.error("Error al actualizar la reseña.");
	}, [isError]);

	const prepareData = useMemo(() => {
		if (!data) return [];
		return [
			{
				label: "OPEN_APP_CONTINUOUS_WEEKS",
				description: "La aplicación se abre en semanas continuas",
				value: data.data.range[0]["open_app_continuous_weeks"] || 0,
				key: "open_app_continuous_weeks",
			},
			{label: "WATCH_CHAPTER", description: "Se ha visto un capítulo", value: data.data.range[0]["watch_chapter"] || 0, key: "watch_chapter"},
			{label: "DONATE_COINS", description: "Se han donado coins", value: data.data.range[0]["donate_coins"] || 0, key: "donate_coins"},
			{
				label: "LIKE_CONTENT",
				description: "Se ha dado like a un contenido",
				value: data.data.range[0]["like_content"] || 0,
				key: "like_content",
			},
			{
				label: "SHARE_CONTENT",
				description: "Se ha compartido un contenido",
				value: data.data.range[0]["share_content"] || 0,
				key: "share_content",
			},
			{
				label: "OPEN_APP_MANY_TIMES",
				description: "La aplicación se abre muchas veces",
				value: data.data.range[0]["open_app_many_times"] || 0,
				key: "open_app_many_times",
			},
		];
	}, [data]);

	return (
		<div className="col-span-2 flex flex-col">
			<div className="shadow-xs col-span-1 flex-col items-center justify-center rounded-lg border border-gray-200 bg-white">
				<div className="border border-gray-200 p-2 font-bold">Resumen de triggers</div>

				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>Trigger</TableHead>
							<TableHead>Descripción</TableHead>
							<TableHead>Cantidad</TableHead>
							<TableHead />
						</TableRow>
					</TableHeader>
					<TableBody>
						{isLoading ? (
							<TableRow className="text-center odd:bg-accent/40">
								<TableCell colSpan={7}>
									<Skeleton className="h-20 w-full bg-slate-300" />
								</TableCell>
							</TableRow>
						) : (
							prepareData.map(item => (
								<TableRow className="py-10 text-center odd:bg-accent/40" key={`${item.key}`}>
									<TableCell className="text-left">{item.label}</TableCell>
									<TableCell className="text-left">{item.description}</TableCell>
									<TableCell className="text-left">{item.value}</TableCell>
									<TableCell className="w-30 flex">
										<div className="w-8">{isLoadingTrigger && originalArgs?.trigger === item.label && <Spinner />}</div>
										<div className="flex-1 justify-center">
											<Switch
												defaultChecked={Object.hasOwn(triggers, item.label)}
												onCheckedChange={active => updateTrigger({trigger: item.label, active})}
												checked={Object.hasOwn(triggers, item.label)}
											/>
										</div>
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</div>
		</div>
	);
}
