import {FetchFormDataParams, fetchFormData} from "../../../data-access/fetch-form-data";
import {AppThunk} from "../../store";
import {formDbActions} from "./customer-service-slice";

export const fetchContentFormData = (params: FetchFormDataParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(formDbActions.setLoading(true));
			const response = await fetchFormData(params);
			dispatch(formDbActions.loadFormData(response.data));
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(formDbActions.setLoading(false));
		}
	};
};
