import {useState, useEffect, useCallback} from "react";
import {Modal} from "../../../../components/primitives/Modal";
import MuxVideoCombobox from "./MuxVideoCombobox";
import {AdContent} from "../../../../data-access/videos/video-ads";
import {Pencil, PlusSmall, Trash} from "../../../../components/primitives/icons";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {adContentsActions} from "../../../../_store/features/content-ad/content-ad-slice";
import {Spinner} from "../../../../components/primitives/icons/Spinner";
import {toast} from "react-hot-toast";

interface AdContentModalProps {
	onDismiss: () => void;
	contentId: string;
}

const initialState: AdContent = {
	contentid: "",
	videoid: "",
	offset: 0,
	duration: 0,
	title: "",
	description: "",
	skippable_offset: 0,
	click_through: "string",
	creative_type: "linear",
	video_duration: 0,
	video_title: "",
};

export function AdContentModal({contentId, onDismiss}: AdContentModalProps) {
	const dispatch = useAppDispatch();
	const isAdContentLoading = useAppSelector(state => state.adContent.loading);
	const adContentData = useAppSelector(state => state.adContent.results);
	const [editSection, setEditSection] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [data, setData] = useState<AdContent>({...initialState, contentid: contentId});

	useEffect(() => {
		if (contentId) dispatch(adContentsActions.getAdContents(contentId));
	}, [contentId, dispatch]);

	const handleDismiss = useCallback(() => {
		onDismiss();
		setEditSection(false);
		setData({...initialState, contentid: contentId});
	}, [contentId, onDismiss]);

	const handleAction = () => {
		if (editSection) {
			if (data.videoid !== "") {
				if (data.offset === 0) {
					toast.error("El tiempo de inicio no puede ser 0");
					return;
				}
				if (isEditing) {
					dispatch(adContentsActions.updateAdContent(data));
				} else {
					dispatch(adContentsActions.insertAdContent({...data, contentid: contentId}));
				}
				setIsEditing(false);
				setEditSection(false);
				setData({...initialState, contentid: contentId});
			}
		} else {
			setEditSection(true);
		}
	};

	return (
		<Modal title="Anuncios" open={contentId !== ""} onDismiss={handleDismiss} big>
			<div className="max-w-2xl">
				{isAdContentLoading ? (
					<Spinner />
				) : adContentData.length > 0 ? (
					<table className="w-full table-fixed border-collapse border border-white text-white ">
						<thead>
							<tr>
								<th className="border border-white">Video</th>
								<th className="border border-white">Duración</th>
								<th className="border border-white">Inicio</th>
								<th className="border border-white">Controles</th>
							</tr>
						</thead>
						{adContentData.map(ad => {
							return (
								<tr>
									<td className="border border-white p-2 text-center">{ad?.video_title}</td>
									<td className="border border-white p-2 text-center">{ad?.video_duration?.toFixed(2)}s</td>
									<td className="border border-white p-2 text-center">{ad?.offset?.toFixed(2)}s</td>
									<td className="flex items-center justify-center gap-2 border border-white">
										<button
											className="flex items-center justify-center gap-2 rounded p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
											onClick={() => {
												setIsEditing(true);
												setEditSection(true);
												setData({...ad});
											}}
										>
											<Pencil className="h-4 w-4" />
										</button>
										<button
											className="flex items-center justify-center gap-2 rounded p-2 transition-colors duration-300 hover:bg-neutral-900 hover:text-neutral-100"
											onClick={() => {
												dispatch(adContentsActions.removeAdContent(ad.id!));
											}}
										>
											<Trash className="h-4 w-4" />
										</button>
									</td>
								</tr>
							);
						})}
					</table>
				) : (
					<p className="italic text-white">No hay anuncios relacionados con este contenido</p>
				)}

				{editSection && (
					<div className="my-3">
						<div className="mb-3 flex">
							<div className="mr-2 flex w-full flex-col">
								<div>
									<label className="text-white" htmlFor="video_content_id">
										Video ID *
									</label>
									<MuxVideoCombobox
										id="video_content_id"
										name="video_id"
										defaultValue={data.videoid!}
										video_type={2}
										onChangeSelected={selected => {
											const videoDuration = `${selected.duration}`;
											setData(prev => ({
												...prev,
												videoid: selected.uploadid,
												video_duration: Number(videoDuration),
												video_title: selected.title,
											}));
										}}
									/>
								</div>
								<div>
									<label className="text-white" htmlFor="video_duration">
										Duración (segundos)
									</label>
									<input
										id="video_duration"
										className="form-control w-full rounded bg-black px-3 py-2 text-white"
										type="number"
										min="0"
										placeholder="Duración en Segundos"
										name="duration"
										readOnly
										value={data.video_duration}
									/>
								</div>
								<div>
									<div>
										<label className="text-white" htmlFor="offset">
											Inicio (tiempo de inicio del anuncio en seg.)
										</label>
										<input
											id="offset"
											className="form-control w-full rounded bg-black px-3 py-2 text-white"
											type="number"
											min="0"
											placeholder="Duración en Segundos"
											name="offset"
											value={data.offset}
											onChange={e => {
												setData(prev => ({...prev, [e.target.name]: Number(e.target.value)}));
											}}
										/>
									</div>
								</div>
							</div>
							<video className="my-2 h-44 w-80 rounded bg-neutral-300" />
						</div>
					</div>
				)}

				<button
					className="col-start-2 mt-4 flex items-center justify-center rounded bg-neutral-50 px-4 py-2 text-black transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
					type="button"
					onClick={handleAction}
				>
					<PlusSmall />
					{editSection ? "Guardar" : "Agregar anuncio"}
				</button>
			</div>
		</Modal>
	);
}
